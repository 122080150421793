import { AppConstants } from 'app/app.constants';

function RoleEnumUserClass() {}

RoleEnumUserClass.prototype.ROLE_ENUM = Object.freeze({
  Admin: 'Admin',
    TelemetryUser: 'TelemetryUser',
  Standard: 'Standard',
    FreeTier: 'FreeTier',
});

RoleEnumUserClass.prototype.getUsers = function getUsers() {
    return this.ROLE_ENUM;
};

RoleEnumUserClass.prototype.isAdministrador = function isAdministrador() {
    const usuario = JSON.parse(
        localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO)
    );
  return usuario.role == this.ROLE_ENUM.Admin;
};

RoleEnumUserClass.prototype.isRoleFreetier = function isRoleFreetier() {
    const usuarioLogado = JSON.parse(
        localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO)
  );
    return (
        usuarioLogado != null &&
        usuarioLogado.role == this.ROLE_ENUM.FreeTier
    );
};

RoleEnumUserClass.prototype.isAuthenticated = function isAuthenticated() {
    const usuarioLogado = JSON.parse(
        localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO)
    );
    return usuarioLogado !== undefined && usuarioLogado !== null;
};

const _roleEnumUserClass = new RoleEnumUserClass();

export { _roleEnumUserClass };
