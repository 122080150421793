import {
  merge as observableMerge,
  fromEvent as observableFromEvent,
  Observable,
  Subject,
} from 'rxjs';

import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
} from '@angular/core';
import { User } from 'app/shared/models/user';
import {
  FormControlName,
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { GenericValidator } from 'app/utils/generic-form-validator';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'app/core/services/user.service';
import { CustomValidators } from 'ng2-validation';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from 'app/core/services/authorization.service';
import { UserPermissions, UserResources } from 'app/shared/models/role';
import { ValidateId } from 'app/shared/validators/ValidateId';
@Component({
  selector: 'app-form-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, AfterViewInit {
  @Input() show: boolean;
  @Input() showModalSubject: Subject<boolean>;
  @Input() user: User;
  @Output() userChange: EventEmitter<User> = new EventEmitter<User>();
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];

  public registrarForm: UntypedFormGroup;
  public validationMessages: { [key: string]: { [key: string]: string } };
  public genericValidator: GenericValidator;
  public displayMessage: { [key: string]: string } = {};
  public errors: any[] = [];
  public selectedPermisao;
  public disableButton;
  public mostrarDivSenha: boolean;
  public telefoneFormat;
  public senhaInvalidaIgual = false;
  public senhaInvalidaMinLength = false;

  constructor(
    public fb: UntypedFormBuilder,
    public translateService: TranslateService,
    public toastrService: ToastrService,
    public usuarioService: UserService,
  ) {
    this.validationMessages = {
      Nome: {
        required: '',
        minlength: '',
        maxlength: '',
      },
      email: {
        required: '',
        email: '',
      },
      Identificacao: {
        required: '',
        cpf: '',
        cnpj: '',
      },
      Telefone: {
        required: '',
        maxlength: '',
        minlength: '',
      },
      Senha: {
        minlength: '',
      },
      SenhaConfirmacao: {
        minlength: '',
        equalTo: '',
      },
      Pais: {
        required: '',
        minlength: '',
        maxlength: '',
      },
      Cidade: {
        required: '',
        minlength: '',
        maxlength: '',
      },
      Estado: {
        required: '',
        minlength: '',
        maxlength: '',
      },
    };

    this.populateValidateObj();

    this.genericValidator = new GenericValidator(this.validationMessages);
    this.mostrarDivSenha = false;
  }

  populateValidateObj() {
    this.translateService.get('global.name').subscribe((res: string) => {
      this.validationMessages.Nome.required = res;
    });
    this.translateService.get('global.name').subscribe((res: string) => {
      this.validationMessages.Nome.maxlength = res;
    });
    this.translateService.get('global.name').subscribe((res: string) => {
      this.validationMessages.Nome.minlength = res;
    });

    this.translateService.get('global.mail').subscribe((res: string) => {
      this.validationMessages.email.required = res;
    });
    this.translateService.get('global.mail').subscribe((res: string) => {
      this.validationMessages.email.email = res;
    });
    this.translateService
      .get('global.identification')
      .subscribe((res: string) => {
        this.validationMessages.Identificacao.required = res;
      });
    this.translateService
      .get('global.identification')
      .subscribe((res: string) => {
        this.validationMessages.Identificacao.cpf = res;
      });
    this.translateService
      .get('global.identification')
      .subscribe((res: string) => {
        this.validationMessages.Identificacao.cnpj = res;
      });

    this.translateService.get('global.password').subscribe((res: string) => {
      this.validationMessages.Senha.minlength = res;
    });

    this.translateService
      .get('global.confirme-password')
      .subscribe((res: string) => {
        this.validationMessages.SenhaConfirmacao.minlength = res;
      });
    this.translateService
      .get('global.confirme-password')
      .subscribe((res: string) => {
        this.validationMessages.SenhaConfirmacao.equalTo = res;
      });

    this.translateService.get('global.phone').subscribe((res: string) => {
      this.validationMessages.phoneNumber.required = res;
    });

    this.translateService.get('global.country').subscribe((res: string) => {
      this.validationMessages.country.required = res;
    });

    this.translateService.get('global.city').subscribe((res: string) => {
      this.validationMessages.city.required = res;
    });

    this.translateService.get('global.state').subscribe((res: string) => {
      this.validationMessages.stateOrProvince.required = res;
    });
  }

  ngOnInit() {
    let senha = new UntypedFormControl('', [
      Validators.maxLength(16),
      Validators.minLength(6),
    ]);
    let senhaConfirmacao = new UntypedFormControl('', [
      Validators.maxLength(16),
      Validators.minLength(6),
    ]);

    let email = new UntypedFormControl('', [
      Validators.required,
      CustomValidators.email,
    ]);
    this.registrarForm = this.fb.group({
      Nome: [
        '',
        [
          Validators.required,
          Validators.maxLength(150),
          Validators.minLength(2),
        ],
      ],
      Identificacao: ['', [Validators.required, ValidateId.ValidadeCPFCNPJ]],
      email: email,
      Senha: senha,
      SenhaConfirmacao: senhaConfirmacao,
      Pais: ['', [Validators.required]],
      Cidade: ['', [Validators.required]],
      Estado: ['', [Validators.required]],
      Telefone: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(11),
        ],
      ],
    });
    this.preencherForm();
    this.disableButton = false;
  }

  preencherForm() {
    var telefone = this.user.phoneNumber
      ? this.removeMask(this.user.phoneNumber)
      : '';

    var pais = '';
    var cidade = '';
    var estado = '';

    if (this.user.address) {
      pais = this.user.address.country ? this.user.address.country : '';
      cidade = this.user.address.city ? this.user.address.city : '';
      estado = this.user.address.stateOrProvince
        ? this.user.address.stateOrProvince
        : '';
    }

    this.registrarForm.patchValue({
      Nome: this.user['name'],
      email: this.user['email'],
      Identificacao: this.user['CpfCnpj'],
      Pais: pais,
      Cidade: cidade,
      Estado: estado,
      Telefone: telefone,
    });
  }

  isCPF(): boolean {
    const identificacao = this.registrarForm.value.Identificacao;
    return identificacao == null
      ? true
      : identificacao.length < 12
      ? true
      : false;
  }

  getIdentificacaoMask(): string {
    return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
  }

  isCelular(): boolean {
    return this.registrarForm.value.phoneNumber.length == 11 ? true : false;
  }
  getTelefoneMask(): string {
    return this.isCelular() ? '(00) 00000-0000' : '(00) 0000-00009';
  }

  removeMask(telefone) {
    var telefoneSemMask = telefone.replace('(', '');
    telefoneSemMask = telefoneSemMask.replace(')', '');
    telefoneSemMask = telefoneSemMask.replace('-', '');
    telefoneSemMask = telefoneSemMask.replace(' ', '');

    return telefoneSemMask;
  }

  mostrarSenha() {
    this.mostrarDivSenha = true;
  }

  popularTelefone(telefoneForm) {
    if (!telefoneForm.includes('-') || !telefoneForm.includes('(')) {
      if (telefoneForm.length == 10) {
        return (
          '(' +
          telefoneForm.substr(0, 2) +
          ') ' +
          telefoneForm.substr(2, 4) +
          '-' +
          telefoneForm.substr(6, 4)
        );
      } else {
        return (
          '(' +
          telefoneForm.substr(0, 2) +
          ') ' +
          telefoneForm.substr(2, 5) +
          '-' +
          telefoneForm.substr(7, 4)
        );
      }
    } else {
      telefoneForm = telefoneForm
        .replace('(', '')
        .replace(')', '')
        .replace('-', '');

      if (telefoneForm.length == 10) {
        return (
          '(' +
          telefoneForm.substr(0, 2) +
          ') ' +
          telefoneForm.substr(2, 4) +
          '-' +
          telefoneForm.substr(6, 4)
        );
      } else {
        return (
          '(' +
          telefoneForm.substr(0, 2) +
          ') ' +
          telefoneForm.substr(2, 5) +
          '-' +
          telefoneForm.substr(7, 4)
        );
      }
    }
  }

  ngAfterViewInit() {
    let controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) =>
        observableFromEvent(formControl.nativeElement, 'blur'),
    );

    observableMerge(...controlBlurs).subscribe((value) => {
      this.displayMessage = this.genericValidator.processMessages(
        this.registrarForm,
      );
    });
  }

  get(getObservable: Observable<User[] | User>) {
    getObservable.subscribe(
      (data) => {
        return this.usuarioService.changeData(<Array<Object>>data);
      },
      (error) => {
        this.translateService
          .get('error.500.get-users')
          .subscribe((res: string) => {
            this.toastrService.error(res);
          });
      },
    );
  }
  loadUsuarios() {
    this.get(this.usuarioService.getUsuarios());
  }
  loadUsuario() {
    this.get(this.usuarioService.getUsuario());
  }

  montarObjUsuario() {
    throw new Error('Method not implemented.');
  }

  editUsuario() {
    this.senhaInvalidaIgual = false;
    this.senhaInvalidaMinLength = false;

    this.displayMessage = this.genericValidator.processMessages(
      this.registrarForm,
    );

    if (this.validarSenha()) {
      if (this.registrarForm.valid && this.registrarForm.dirty) {
        let usuario = this.montarObjUsuario() as unknown;
        this.disableButton = true;
        this.usuarioService.patchUser(usuario as User).subscribe(
          (result) => {
            this.onSaveComplete(result);
          },
          (error) => {
            this.onError(error);
          },
        );
      }
    }
  }

  validarSenha() {
    if (this.registrarForm.controls.Senha.value != '') {
      if (this.registrarForm.controls.Senha.value.length < 6) {
        this.senhaInvalidaMinLength = true;
        return false;
      }

      if (
        this.registrarForm.controls.Senha.value !=
        this.registrarForm.controls.SenhaConfirmacao.value
      ) {
        this.senhaInvalidaIgual = true;
        return false;
      }
    }

    return true;
  }

  onSaveComplete(response: any) {
    this.errors = [];
    this.disableButton = true;
    const rbac = new AuthorizationService().gethandler();
    if (rbac.permits(UserPermissions.READ, UserResources.USER_ADMINISTRATION)) {
      this.loadUsuarios();
    } else {
      this.loadUsuario();
    }

    this.showModalSubject.next(false);

    this.translateService.get('global.success').subscribe((res: string) => {
      this.toastrService.success(res);
    });
    this.userChange.emit();
  }

  onError(fail: any) {
    this.disableButton = false;
    this.errors = [fail.error];

    this.translateService
      .get('error.500.error-occurred')
      .subscribe((res: string) => {
        this.toastrService.error(this.errors[0], res);
      });
  }

  public hideModal(): void {
    this.userChange.emit();
    this.showModalSubject.next(false);
  }
  verifyMaxLength(event) {
    if (event.target.value.length === 16) {
      this.translateService.get('global.password').subscribe((res: string) => {
        this.toastrService.warning(res);
      });
    }
  }
}
