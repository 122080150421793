import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { isCPF, isCNPJ } from 'validation-br';
import { ValidateId } from 'app/shared/validators/ValidateId';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/shared/models/user';
import { AppConstants } from 'app/app.constants';
import { Md5 } from 'ts-md5';
import { UserService } from 'app/core/services/user.service';
import { GenericValidator } from 'app/utils/generic-form-validator';
import { AuthorizationService } from 'app/core/services/authorization.service';
import { UserPermissions, UserResources } from 'app/shared/models/role';
import { Observable } from 'rxjs';
import { PostalCodeService } from 'app/core/services/postalCode.service';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { Router } from '@angular/router';
import { AnalyticsService } from 'app/core/services/analytics.service';

type AddressFormGroup = FormGroup<{
  country: AbstractControl<any, any>;
  city: AbstractControl<any, any>;
  stateOrProvince: AbstractControl<any, any>;
  postalCode: AbstractControl<any, any>;
}>;

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit, AfterViewInit {
  @Input() user: User;
  @Output() userChange: EventEmitter<User> = new EventEmitter<User>();

  profileImage: string | ArrayBuffer = './assets/images/default/avatar.png';
  uploadImageForm!: UntypedFormGroup;
  imageLoaded: boolean = false;
  loggedUser: User;
  formattedPhone: string;
  public outsideBrazil = false;
  public countries: Array<string> = AppConstants.COUNTRY_CODES;
  public userData: User;
  public formData;
  public isPasswordVisible = false;
  public isConfirmPasswordVisible = false;
  public myForm: FormGroup<{
    id: AbstractControl<string>;
    name: AbstractControl<string>;
    phoneNumber: AbstractControl<string>;
    nationalIdNumber: AbstractControl<string>;
    company: AbstractControl<string>;
    country: AbstractControl<string>;
    city: AbstractControl<string>;
    stateOrProvince: AbstractControl<string>;
    postalCode: AbstractControl<string>;
    addressFormGroup: AddressFormGroup;
  }>;
  public myFormPassword: FormGroup<{
    password: AbstractControl<string>;
    confirmPassword: AbstractControl<string>;
  }>;
  public validationMessages: { [key: string]: { [key: string]: string } };
  public displayMessage: { [key: string]: string } = {};
  private urlBlobStorageProfile =
    this.userService.azureBlobStorage + 'profile-images/';
  public genericValidator: GenericValidator;
  public invalidPasswordEqual = false;
  public invalidPasswordMinLength = false;
  public errors: any[] = [];
  public disableButton: boolean = false;
  public emailControl: UntypedFormControl;
  constructor(
    private readonly router: Router,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private cepService: PostalCodeService,
    private translateService: TranslateService,
    private userService: UserService,
    public fb: UntypedFormBuilder,
    public alertService: AlertService,
    private readonly loadingAgresSpinner: AgresSpinnerService,
    private readonly analyticsService: AnalyticsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.validationMessages = {
      name: {
        required: '',
      },
      id: {
        required: '',
        email: '',
      },
      nationalIdNumber: {
        required: '',
        cpf: '',
        cnpj: '',
      },
      company: {
        required: '',
        minlength: '',
      },
      phoneNumber: {},
      password: {
        minlength: '',
      },
      SenhaConfirmacao: {
        minlength: '',
        equalTo: '',
      },
      country: {
        required: '',
      },
      city: {
        required: '',
      },
      stateOrProvince: {
        required: '',
      },
      postalCode: {
        required: '',
      },
    };
    this.populateValidateObj();
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  populateValidateObj() {
    this.translateService
      .get('global.validate.fields')
      .subscribe((res: string) => {
        this.validationMessages.nationalIdNumber.required = res;
      });
    this.translateService
      .get('global.validate.error-document-data')
      .subscribe((res: string) => {
        this.validationMessages.phoneNumber.required = res;
      });
    this.translateService
      .get('global.validate.error-document-data')
      .subscribe((res: string) => {
        this.validationMessages.company.required = res;
      });
    this.translateService
      .get('global.validate.identification-required')
      .subscribe((res: string) => {
        this.validationMessages.country.required = res;
      });
    this.translateService
      .get('global.validate.identification-required')
      .subscribe((res: string) => {
        this.validationMessages.city.required = res;
      });
    this.translateService
      .get('global.validate.identification-required')
      .subscribe((res: string) => {
        this.validationMessages.stateOrProvince.required = res;
      });
    this.translateService
      .get('global.validate.error-document-data')
      .subscribe((res: string) => {
        this.validationMessages.nationalIdNumber.cpf = res;
      });
    this.translateService
      .get('global.validate.error-document-data')
      .subscribe((res: string) => {
        this.validationMessages.nationalIdNumber.cnpj = res;
      });
    this.translateService
      .get('global.validate.identification-required')
      .subscribe((res: string) => {
        this.validationMessages.postalCode.required = res;
      });
  }

  ngAfterViewInit(): void {
    this.translateService
      .get('global.loading.user-data-spinner')
      .subscribe((res) => {
        this.loadingAgresSpinner.toShow(res);
      });
    this.cdr.detectChanges();
  }
  ngOnInit() {
    this.loggedUser = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    this.getProfileImage();

    this.myForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      id: ['', [Validators.required]],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(21),
        ],
      ],
      nationalIdNumber: [''],
      company: ['', Validators.required],
      addressFormGroup: this.formBuilder.group({
        postalCode: [''],
        city: ['', Validators.required],
        stateOrProvince: ['', Validators.required],
        country: ['', Validators.required],
      }),
    });

    this.myForm.get('addressFormGroup').valueChanges.subscribe((data) => {
      this.outsideBrazil = data.country !== 'BRA';
    });

    this.myFormPassword = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(21),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(21),
        ],
      ],
    });

    this.loadUser();

    this.analyticsService.sendPageView('/my-profile/');
  }

  getProfileImage() {
    const md5 = new Md5();
    md5.appendStr(this.loggedUser.id);
    const userMd5 = md5.end().toString();
    this.userService
      .getProfileImage(this.urlBlobStorageProfile + userMd5)
      .subscribe((response: Blob) => {
        this.profileImage = URL.createObjectURL(response);
      });
  }

  loadUser() {
    this.userService.getUsuario().subscribe(
      (userDataLoaded) => {
        this.userData = userDataLoaded;
        this.loadingAgresSpinner.toHide();
        this.patchFormValues();
      },
      (error) => {
        this.translateService
          .get('error.500.get-users')
          .subscribe((res: string) => {
            this.alertService.error(res);
          });
      },
    );
  }

  formatPhoneNumber() {
    let phoneNumber = this.myForm.get('phoneNumber').value || '';

    if (this.outsideBrazil) {
      this.myForm
        .get('phoneNumber')
        .setValue(phoneNumber, { emitEvent: false });
      return;
    }

    const cleaned = phoneNumber.replace(/\D/g, '');

    if (cleaned.length === 0) {
      this.myForm.get('phoneNumber').setValue('', { emitEvent: false });
      return;
    }

    if (cleaned.length < 11) {
      this.myForm
        .get('phoneNumber')
        .setValue(phoneNumber, { emitEvent: false });
      return;
    }

    if (cleaned.length === 11 && phoneNumber.indexOf('-') === -1) {
      phoneNumber = `(+55) ${cleaned.slice(0, 2)} ${cleaned.slice(
        2,
        3,
      )} ${cleaned.slice(3, 7)}-${cleaned.slice(7)}`;
      this.myForm
        .get('phoneNumber')
        .setValue(phoneNumber, { emitEvent: false });
    }
  }

  isCelphone(): boolean {
    if (this.myForm && this.myForm.value && this.myForm.value.phoneNumber) {
      return this.myForm.value.phoneNumber.length === 11;
    }
    return false;
  }

  identificationConditional() {
    const checkIdentification = <FormControl>(
      this.myForm.get('nationalIdNumber')
    );
    const phone = <FormControl>this.myForm.get('phoneNumber');

    if (this.outsideBrazil) {
      checkIdentification.setValidators(null);
      phone.setValidators(null);
    } else {
      if (!phone.value.startsWith('(+55)')) {
        phone.setValue(`(+55) ` + phone.value);
      }
      checkIdentification.setValidators([
        Validators.required,
        ValidateId.ValidadeCPFCNPJ,
      ]);
      phone.setValidators([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(15),
      ]);
    }

    checkIdentification.updateValueAndValidity();
    phone.updateValueAndValidity();
  }

  patchFormValues() {
    this.myForm.patchValue({
      name: this.userData.name,
      id: this.userData?.id,
      phoneNumber: this.userData?.phoneNumber,
      nationalIdNumber: this.userData?.nationalIdNumber,
      company: this.userData?.company,
      addressFormGroup: {
        postalCode: this.userData?.address?.postalCode,
        city: this.userData?.address?.city,
        stateOrProvince: this.userData?.address?.stateOrProvince,
        country: this.userData?.address?.country,
      },
    });
  }

  loadUsers() {
    this.get(this.userService.getUsuarios());
  }

  uploadImageToBlobService(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput?.files;

    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    const formData = new FormData();
    formData.append(file.name, file);

    const isFileSizeValid = file.size <= 1000000;
    const isFileTypeValid = ['image/png', 'image/jpeg', 'image/jpg'].includes(
      file.type,
    );

    if (!isFileSizeValid) {
      this.translateService
        .get([
          'global.validate.file-upload-size',
          'global.validate.exceeded-size-upload-file',
        ])
        .subscribe((response) => {
          this.alertService.success(
            response['global.validate.file-upload-size'],
          );
        });
      return;
    }

    if (!isFileTypeValid) {
      this.translateService
        .get([
          'global.validate.extension-upload-file',
          'global.validate.invalid-extension-file-upload',
        ])
        .subscribe((response) => {
          this.alertService.success(
            response['global.validate.invalid-extension-file-upload'],
          );
        });
      return;
    }

    // Exibir imagem carregada em base64 (opcional)
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.profileImage = e.target.result;
      this.changeDetector.detectChanges();
    };
    reader.readAsDataURL(file);

    const md5 = new Md5();
    md5.appendStr(this.loggedUser.id);
    const userMd5 = md5.end().toString();

    this.userService.getAzurePresignUrl(userMd5).subscribe(
      ({ presignedUrl }) => {
        if (presignedUrl) {
          this.userService.uploadImageProfile(file, presignedUrl).subscribe(
            () => {
              this.translateService
                .get('global.alert.send-file-upload-success')
                .subscribe((msg) => {
                  this.alertService.success(msg);
                });
            },
            (error) => {
              this.handleErrorAlert('global.validate.error-generic');
            },
          );
        }
      },
      (error) => {
        this.handlePermissionError(error);
      },
    );
  }

  // Método auxiliar para exibir erro genérico
  private handleErrorAlert(translationKey: string) {
    this.translateService
      .get(translationKey)
      .subscribe((msg) => this.alertService.error(msg));
  }

  // Método auxiliar para tratar erro de permissão
  private handlePermissionError(error: any) {
    this.translateService
      .get('global.validate.error-permission')
      .subscribe((permissionMsg: string) => {
        const errorMessage =
          error.error?.text === permissionMsg
            ? permissionMsg
            : 'global.validate.error-generic';
        this.handleErrorAlert(errorMessage);
      });
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  confirmTogglePassword() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  checkCep(valorCep: Event) {
    this.cepService
      .searchCEP((valorCep.target as HTMLInputElement).value)
      .subscribe(
        (dados) => {
          this.fillformWithDataCep(
            dados,
            this.myForm.controls.addressFormGroup,
          );
        },
        (error) => {
          if (error) {
            this.translateService
              .get('global.validate.error-cep')
              .subscribe((response) => {
                this.alertService.error(response);
              });
          }
        },
      );
  }

  fillformWithDataCep(dados: any, form: any) {
    Object.keys(dados).forEach((item) => {
      if (item === 'erro') {
        this.translateService
          .get(['global.validate.error-cep'])
          .subscribe((response) => {
            this.alertService.error(response);
          });
      } else {
        form.setValue({
          postalCode: dados.cep,
          city: dados.city,
          stateOrProvince: dados.uf,
          country: dados.country || 'Brasil',
        });
      }
    });
  }

  isCPF(): boolean {
    const identification = this.myForm?.value.nationalIdNumber;
    return identification == null || identification.length < 12;
  }

  validateCEP(value: string): boolean {
    const isValid =
      typeof value === 'string' &&
      new RegExp(/(\d{5}-\d{3})|\d{8}/).test(value);

    if (!isValid) {
      this.disableButton = true;
      this.translateService
        .get('global.validate.error-cep-not-salved')
        .subscribe((response) => {
          this.alertService.error(response);
        });
    }

    return isValid;
  }

  validaCep(value: string): boolean {
    let isValid: boolean = false;
    if (this.outsideBrazil) {
      isValid = true;
    } else {
      isValid = this.validateCEP(value);
    }

    if (isValid) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
      this.translateService
        .get('global.validate.error-cep-not-salved')
        .subscribe((response) => {
          this.alertService.warning(response);
        });
    }
    return isValid;
  }

  getIdentificationMask(): string {
    return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
  }

  isCpfCnpjValid(value: string): boolean {
    if (this.outsideBrazil) {
      return true;
    }
    if (typeof value === 'string') {
      if (value.length === 11) {
        return isCPF(value);
      } else if (value.length === 14) {
        return isCNPJ(value);
      }
      return false;
    }
  }

  maskCpfCnpj(value: string): string {
    if (typeof value === 'string') {
      if (value.length === 11) {
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (value.length === 14) {
        return value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5',
        );
      }
    }
    return value;
  }

  onError(fail: any) {
    this.errors = [fail.error];

    this.translateService
      .get('error.500.error-occurred')
      .subscribe((response) => {
        this.alertService.error(this.errors[0], response);
      });
  }

  get(getObservable: Observable<User[] | User>) {
    getObservable.subscribe(
      (data) => {
        return this.userService.changeData(<Array<Object>>data);
      },
      (error) => {
        console.error(error);

        this.translateService
          .get('error.500.get-users')
          .subscribe((response) => {
            this.alertService.error(response);
          });
      },
    );
  }

  onSaveComplete(response: any) {
    this.errors = [];

    const rbac = new AuthorizationService().gethandler();
    if (rbac.permits(UserPermissions.READ, UserResources.USER_ADMINISTRATION)) {
      this.loadUsers();
    } else {
      this.loadUser();
    }

    this.userChange.emit();
  }

  mountUserObject() {
    const formData = this.myForm.value;

    const objectUser = {
      name: formData.name,
      nationalIdNumber: formData.nationalIdNumber,
      id: formData.id,
      phoneNumber: formData.phoneNumber,
      company: formData.company,
      address: {
        postalCode: formData.addressFormGroup.postalCode,
        city: formData.addressFormGroup.city,
        stateOrProvince: formData.addressFormGroup.stateOrProvince,
        country: formData.addressFormGroup.country,
      },
      role: this.userData.role,
    };

    this.myForm.patchValue(objectUser);
    return objectUser;
  }

  validatePassword() {
    this.invalidPasswordEqual = false;
    this.invalidPasswordMinLength = false;

    const passwordControl = this.myFormPassword.get('password');
    const confirmPasswordControl = this.myFormPassword.get('confirmPassword');

    const password = passwordControl.value;
    const confirmPassword = confirmPasswordControl.value;

    if (password === '') {
      this.invalidPasswordMinLength = true;

      this.translateService
        .get('global.validate.fields')
        .subscribe((response) => {
          this.alertService.error(response);
        });
      return false;
    }

    if (password && password.length < 6) {
      this.invalidPasswordMinLength = true;

      this.translateService
        .get('global.validate.password-minlength')
        .subscribe((response) => {
          this.alertService.error(response);
        });

      return false;
    }

    if (password !== confirmPassword) {
      this.invalidPasswordEqual = true;

      this.translateService
        .get('global.validate.password-wrong')
        .subscribe((response) => {
          this.alertService.error(response);
        });

      return false;
    }
    return true;
  }

  onSubmitNewPassword() {
    this.invalidPasswordEqual = false;
    this.invalidPasswordMinLength = false;
    this.disableButton = true;

    if (this.myFormPassword.valid && this.validatePassword()) {
      this.userService
        .patchUser({ password: this.myFormPassword.value.password }, [
          'password',
        ])
        .subscribe(
          (result) => {
            this.translateService
              .get('global.alert.success-text')
              .subscribe((response) => {
                this.alertService.success(response);
              });

            this.onSaveComplete(result);
            this.clearFormPassword();
          },
          (error) => {
            this.translateService
              .get('global.validate.password-error-changed')
              .subscribe((response) => {
                this.alertService.error(response);
              });
          },
          () => {
            setTimeout(() => {}, TimeoutEnum.Medium);
          },
        );
    }
  }

  onSubmit() {
    const dirtyForm = this.myForm.dirty;
    this.displayMessage = this.genericValidator.processMessages(this.myForm);

    if (dirtyForm) {
      const mountedUserObject = this.mountUserObject();
      const cpfCnpjValid = this.isCpfCnpjValid(
        mountedUserObject.nationalIdNumber,
      );
      const cepValidado = this.validaCep(mountedUserObject.address.postalCode);
      if (cepValidado) {
        if (cpfCnpjValid) {
          this.disableButton = true;
          this.userService.patchUser(mountedUserObject).subscribe(
            (result) => {
              this.translateService
                .get('global.success')
                .subscribe((response) => {
                  this.alertService.success(response);
                });
              this.onSaveComplete(result);
            },

            (error) => {
              this.translateService
                .get('global.error')
                .subscribe((response) => {
                  this.alertService.error(response);
                });
            },
          );
        }
      }
      if (!cpfCnpjValid) {
        this.translateService
          .get('global.validate.identification-required')
          .subscribe((response) => {
            this.alertService.error(response);
          });
      }
    } else {
      this.translateService
        .get('global.validate.identification-required')
        .subscribe((response) => {
          this.alertService.error(response);
        });
    }

    setTimeout(() => {
      this.disableButton = false;
    }, TimeoutEnum.Medium);
  }

  clearFormUserData() {
    this.disableButton = true;

    this.myForm.patchValue({
      name: '',
      company: '',
      nationalIdNumber: '',
      phoneNumber: '',
      addressFormGroup: {
        postalCode: '',
        stateOrProvince: '',
        city: '',
        country: '',
      },
    });

    setTimeout(() => {
      this.disableButton = false;
    }, TimeoutEnum.Medium);
  }

  clearFormPassword() {
    this.disableButton = false;
    this.myFormPassword.patchValue({
      password: '',
      confirmPassword: '',
    });
    setTimeout(() => {
      this.disableButton = false;
    }, TimeoutEnum.Medium);
  }

  linkToDeleteAccountPage() {
    this.router.navigate(['/manage/delete-account']);
  }
}
