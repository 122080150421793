<app-alert></app-alert>

<div class="panel">
  <div class="panel__return" *ngIf="!(fieldName === null || fieldName === undefined || fieldName === '')">
    <app-button
      type="button"
      color="primary"
      icon="agres-return"
      title="{{ 'global.back' | translate }}"
      (btnClick)="back()"></app-button>
  </div>
  <div class="panel__group" *ngIf="!listFilesIsEmpty">
    <div class="panel__search">
      <div class="field">
        <div class="field__wrap">
          <input
            id="search"
            type="search"
            aria-label="search"
            placeholder="{{ 'global.search' | translate }}"
            (keyup)="atualizarBusca($event)"
            class="field__input" />
          <span class="field__view">
            <app-agres-icons iconsClass="agres-search"></app-agres-icons>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="panel__group">
    <div class="panel__group--buttons hidden__mobile">
      <app-button
        *ngIf="!(fieldName || listFilesIsEmpty)"
        type="button"
        color="danger"
        icon="agres-delete"
        title="{{ 'global.delete_all' | translate }}"
        (btnClick)="confirmDeleteAllFiles()"
        [attr.disabled]=""
        [disabled]="deletingFiles ? 'disabled' : null">
      </app-button>
      <app-button
        *ngIf="fieldName === null || fieldName === undefined || fieldName === ''"
        (btnClick)="openModal()"
        type="button"
        icon="agres-upload"
        color="primary"
        title="{{ 'global.upload' | translate }}"></app-button>
    </div>
  </div>
</div>

<div class="row message-not-nvg" *ngIf="listFile.length <= 0">
  <h3>{{ 'archive.not-nvg' | translate }}</h3>
</div>
<div class="gestaoNavegacao">
  <div *ngIf="!listFilesIsEmpty">
    <div class="panel__tasks-by-field" *ngIf="fieldName">
      <h1>{{ ('tasks.field-tasks' | translate) + ' ' + fieldName }}</h1>
    </div>
    <div class="cardList">
      <ng-container *ngFor="let nvg of listFile">
        <ng-template #cardImage>
          <app-gestao-arquivo-card-image
            [workId]="nvg.id"
            [statusChangeEventEmitter]="nvg.statusChangeEventEmitter"></app-gestao-arquivo-card-image>
        </ng-template>

        <ng-template #defaultCardImage>
          <img [src]="'./assets/images/default/no-photo.jpg'" class="defaultCardImage" alt="no-photo" />
        </ng-template>

        <app-card
          [id]="nvg.id"
          [title]="nvg.fileName"
          [cardImage]="getCardImage(nvg.status, defaultCardImage, defaultCardImage, cardImage)"
          [imageRaster]="nvg.imgRasterThumb"
          [backgroundImage]="nvg.backgroundThumbnail"
          [content]="getContentTranslations(nvg)"
          [menuItems]="getContentTranslationsMenu()"
          [statusTranslationPrefix]="'global.'"
          [status]="nvg.status"
          [openMenu]="isOpen(nvg.id)"
          [imagemPathEquipamento]="getImagemPathEquipamento(nvg.equipment)"
          [cardNotificationGFreeTier]="cardNotificationGFreeTier"
          (cardChanged)="handleChange($event)"
          [onCardClicked]="nvg.expired ? null : onCardClicked.bind(this, nvg.id)">
        </app-card>

        <ng-template #cardNotificationGFreeTier>
          <div class="navigation" *ngIf="isFreeTier && nvg.status === CONST_STATUS.COMPLETED">
            <p *ngIf="nvg.labelTimeStamp && !nvg.expired; else expired">
              {{ 'global.alert.ndvi-2' | translate }}:
              <span class="navigation__status--warning">
                {{ 'archive.file-available' | translate }} {{ nvg.labelTimeStamp }}
              </span>
            </p>
            <ng-template #expired>
              <p *ngIf="nvg.expired">
                <span class="navigation__status--danger">
                  {{ 'tasks.expired' | translate }}
                </span>
              </p>
            </ng-template>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <app-pagination
    *ngIf="countUserNVGFiles > 0 && !isLoading"
    #pagination
    (pageChanged)="pageChanged($event)"
    [collectionSize]="countUserNVGFiles"
    [pageSize]="16"
    [firstLastButtons]="true"
    [startingPage]="section + 1"
    [maxSize]="100">
  </app-pagination>
</div>

<!-- FOOTER PARA MOBILE -->
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="dashboard()">
      <i class="agres-app"></i>
      <p>{{ 'sidebar.title-dashboard' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info" *ngIf="!(listFilesIsEmpty || fieldName)">
    <button
      type="button"
      (click)="confirmDeleteAllFiles()"
      [attr.disabled]=""
      [disabled]="deletingFiles ? 'disabled' : null">
      <i class="agres-delete"></i>
      <p>{{ 'global.delete_all' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info" *ngIf="!fieldName">
    <button type="button" (click)="openModal()">
      <i class="agres-upload"></i>
      <p>{{ 'global.upload' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>

<app-modal [showSubject]="showModalSubject">
  <div class="content">
    <div class="row dropzone-div">
      <div class="dropzone__card">
        <div class="card__body">
          <div
            id="plus-icon"
            *ngIf="!blockUploadNvgFreeTier"
            ng2FileDrop
            [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
            (fileOver)="fileOverBase($event)"
            (onFileDrop)="onDrop($event)"
            [uploader]="uploader"
            class="dropzone"
            (click)="fileInput.click()">
            <i class="agres-upload span-icon"></i>
            <br />
            <h1>{{ 'global.upload-file-title' | translate }}</h1>
            <input
              type="file"
              #fileInput
              ng2FileSelect
              [uploader]="uploader"
              style="display: none"
              multiple
              (onFileSelected)="onDrop($event)" />
            <small class="span-text-info">{{ 'global.upload-file' | translate }}</small>
          </div>
        </div>
      </div>
      <div
        *ngIf="blockUploadNvgFreeTier"
        ng2FileDrop
        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
        class="dropzone"
        style="cursor: auto">
        <span class="agres-upload span-icon"></span>
        <span class="span-text">
          {{ 'tasks.limit-upload' | translate }}
          <strong>{{ qtdMaximaNvgsFreeTier }}</strong>
          {{ 'tasks.limit-upload-two' | translate }}
        </span>
        <span class="span-text">
          {{ 'global.alert.delete-upload-tasks' | translate }}
        </span>
      </div>
    </div>
    <div class="row" *ngIf="isFreeTier === true">
      <div class="col-md-12 text-center" style="font-size: 18px">
        {{ 'tasks.limit-upload' | translate }}
        <strong>{{ qtdMaximaNvgsFreeTier }}</strong>
        {{ 'tasks.limit-upload-two' | translate }}
      </div>
    </div>
  </div>
</app-modal>

<app-confirmar-modal
  *ngIf="mostrarModalConfirmacao"
  [(modal)]="mostrarModalConfirmacao"
  [mensagem]="mensagemModalConfirmacao"
  [tipo]="tipoModalConfirmacao"
  [titulo]="tituloModalConfirmacao"
  [conteudo]="idArquivoSelecionadoExcluir"
  [confirmButtonText]="modalTranslateButtonConfirmText"
  [cancelButtonText]="modalTranslateButtonCancelText"
  (modalChange)="mostrarModalConfirmacao = $event">
</app-confirmar-modal>

<app-confirmar-modal
  *ngIf="mostrarModalCompatibilidade"
  [(modal)]="mostrarModalCompatibilidade"
  [mensagem]="mensagemModalCompatibilidade"
  [tipo]="tipoModalCompatibilidade"
  [titulo]="tituloModalCompatibilidade"
  [conteudo]="idArquivoSelecionadoCompatibilidade"
  [confirmButtonText]="modalTranslateButtonConfirmText"
  [cancelButtonText]="modalTranslateButtonCancelText"
  (modalChange)="mostrarModalCompatibilidade = $event"></app-confirmar-modal>
