<div class="panel">
  <div class="panel__group">
    <div class="panel__search">
      <app-button
        type="button"
        color="return"
        icon="agres-return"
        (btnClick)="backField()"
        title="{{ 'global.back' | translate }}">
      </app-button>
    </div>
  </div>
</div>
<form [formGroup]="fieldOperationFormGroup" novalidate (ngSubmit)="submitForm()">
  <div class="row-grid">
    <ng-container>
      <label for="soilPreparation" class="card-check">
        <input
          #soilPreparation
          class="card-check__input"
          type="checkbox"
          (click)="selectOperationType($event)"
          id="soilPreparation" />
        <div class="card-check__operation">
          <div class="card-check__operation--cover">
            <img
              class="card-check__operation--cover--image"
              src="../../../../../../assets/images/operation/preparo-solo.png" />
            <div class="card-check__operation-cover-checkbox">
              <svg class="card-check__operation-cover-checkbox--svg" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </svg>
            </div>
          </div>
          <div class="card-check__operation--header">
            <p class="card-check__operation--header--subtitle">{{ 'tasks.type.soil-preparation' | translate }}</p>
          </div>
        </div>
      </label>
      <label for="planting" class="card-check">
        <input
          #planting
          class="card-check__input"
          type="checkbox"
          (click)="selectOperationType($event)"
          id="planting" />
        <div class="card-check__operation">
          <div class="card-check__operation--cover">
            <img
              class="card-check__operation--cover--image"
              src="../../../../../../assets/images/operation/plantio.png" />
            <span class="card-check__operation-cover-checkbox">
              <svg class="card-check__operation-cover-checkbox--svg" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg
            ></span>
          </div>
          <div class="card-check__operation--header">
            <p class="card-check__operation--header--subtitle">{{ 'tasks.type.planting' | translate }}</p>
          </div>
        </div>
      </label>
      <label for="fertilizing" class="card-check">
        <input
          #fertilizing
          class="card-check__input"
          type="checkbox"
          (click)="selectOperationType($event)"
          id="fertilizing" />
        <div class="card-check__operation">
          <div class="card-check__operation--cover">
            <img
              class="card-check__operation--cover--image"
              src="../../../../../../assets/images/operation/adubacao.png" /><span
              class="card-check__operation-cover-checkbox">
              <svg class="card-check__operation-cover-checkbox--svg" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg
            ></span>
          </div>
          <div class="card-check__operation--header">
            <p class="card-check__operation--header--subtitle">{{ 'tasks.type.fertilizing' | translate }}</p>
          </div>
        </div>
      </label>
      <label for="pulverization" class="card-check">
        <input
          #pulverization
          class="card-check__input"
          type="checkbox"
          (click)="selectOperationType($event)"
          id="pulverization" />
        <div class="card-check__operation">
          <div class="card-check__operation--cover">
            <img
              class="card-check__operation--cover--image"
              src="../../../../../../assets/images/operation/pulverizacao.png" /><span
              class="card-check__operation-cover-checkbox">
              <svg class="card-check__operation-cover-checkbox--svg" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg
            ></span>
          </div>
          <div class="card-check__operation--header">
            <p class="card-check__operation--header--subtitle">{{ 'tasks.type.pulverization' | translate }}</p>
          </div>
        </div>
      </label>
      <label for="harvest" class="card-check">
        <input #harvest class="card-check__input" type="checkbox" (click)="selectOperationType($event)" id="harvest" />
        <div class="card-check__operation">
          <div class="card-check__operation--cover">
            <img
              class="card-check__operation--cover--image"
              src="../../../../../../assets/images/operation/colheita.png" /><span
              class="card-check__operation-cover-checkbox">
              <svg class="card-check__operation-cover-checkbox--svg" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg
            ></span>
          </div>
          <div class="card-check__operation--header">
            <p class="card-check__operation--header--subtitle">{{ 'tasks.type.harvest' | translate }}</p>
          </div>
        </div>
      </label>
      <label for="other" class="card-check">
        <input #other class="card-check__input" type="checkbox" (click)="selectOperationType($event)" id="other" />
        <div class="card-check__operation">
          <div class="card-check__operation--cover">
            <img
              class="card-check__operation--cover--image"
              src="../../../../../../assets/images/operation/outro.png" /><span
              class="card-check__operation-cover-checkbox">
              <svg class="card-check__operation-cover-checkbox--svg" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg
            ></span>
          </div>
          <div class="card-check__operation--header">
            <p class="card-check__operation--header--subtitle">{{ 'tasks.type.other' | translate }}</p>
          </div>
        </div>
      </label>
    </ng-container>
  </div>
  <ng-container formGroupName="crop">
    <div class="field__row">
      <div class="field">
        <div class="field__label">{{ 'tasks.operation-adversity' | translate }} *</div>
        <div class="field__wrap">
          <select
            name="adiversidade"
            id=""
            formControlName="adversity"
            class="field__input"
            [ngClass]="{ 'field__input--alert': displayMessage.adversity }"
            required>
            <option value="{{ 'operation.climate.sun' | translate }}">
              {{ 'tasks.climate.sun' | translate }}
            </option>
            <option value="{{ 'operation.climate.rain' | translate }}">
              {{ 'tasks.climate.chance_of_rain' | translate }}
            </option>
            <option value="{{ 'operation.climate.cloudy' | translate }}">
              {{ 'tasks.climate.cloudy' | translate }}
            </option>
            <option value="{{ 'operation.climate.storm' | translate }}">
              {{ 'tasks.climate.storm' | translate }}
            </option>
          </select>
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.adversity" [innerHTML]="displayMessage.adversity">
        </span>
      </div>
      <div class="field">
        <div class="field__label">{{ 'tasks.culture-name' | translate }} *</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.culture }"
            class="field__input"
            formControlName="name"
            class="field__input"
            value=""
            required="" />
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.culture" [innerHTML]="displayMessage.culture"> </span>
      </div>
      <div class="field">
        <div class="field__label">{{ 'tasks.phenological-phase' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.stage }"
            class="field__input"
            type="text"
            formControlName="stage"
            class="field__input"
            value="" />
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.stage" [innerHTML]="displayMessage.stage"> </span>
      </div>
    </div>
  </ng-container>
  <div class="field__row">
    <ng-container formGroupName="operator">
      <div class="field">
        <div class="field__label">{{ 'tasks.operator' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.Nome }"
            type="text"
            formControlName="name"
            class="field__input"
            value="" />
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.Nome" [innerHTML]="displayMessage.Nome"> </span>
      </div>
    </ng-container>
    <ng-container formGroupName="machine">
      <div class="field">
        <div class="field__label">{{ 'tasks.equipment-machine' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.machine }"
            type="text"
            formControlName="name"
            class="field__input"
            value="" />
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.machine" [innerHTML]="displayMessage.machine"> </span>
      </div>
      <div class="field">
        <div class="field__label">{{ 'tasks.machine-equipment-model' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.model }"
            type="text"
            formControlName="model"
            class="field__input"
            value="" />
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.model" [innerHTML]="displayMessage.model"> </span>
      </div>
    </ng-container>
  </div>
  <div class="field__row">
    <div class="field">
      <div class="field__label">{{ 'tasks.start-time' | translate }} *</div>
      <div class="field__wrap">
        <input
          [ngClass]="{ 'field__input--alert': displayMessage.startedAt }"
          type="datetime-local"
          formControlName="startedAt"
          class="field__input"
          value=""
          required />
      </div>
      <span class="field__input--alert" *ngIf="displayMessage.startedAt" [innerHTML]="displayMessage.startedAt"> </span>
    </div>
    <div class="field">
      <div class="field__label">{{ 'tasks.end-time' | translate }} *</div>
      <div class="field__wrap">
        <input
          [ngClass]="{ 'field__input--alert': displayMessage.finishedAt }"
          type="datetime-local"
          formControlName="finishedAt"
          class="field__input"
          value=""
          required />
      </div>
      <span class="field__input--alert" *ngIf="displayMessage.finishedAt" [innerHTML]="displayMessage.finishedAt">
      </span>
    </div>
  </div>
  <ng-container formGroupName="product">
    <div class="field__row">
      <div class="field">
        <div class="field__label">{{ 'tasks.name-commercial' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.productName }"
            type="text"
            formControlName="name"
            class="field__input"
            value="" />
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.productName" [innerHTML]="displayMessage.productName">
        </span>
      </div>
      <div class="field">
        <div class="field__label">{{ 'tasks.active-defensive-principle' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.activeIngredient }"
            type="text"
            formControlName="activeIngredient"
            class="field__input"
            value="" />
        </div>
        <span
          class="field__input--alert"
          *ngIf="displayMessage.activeIngredient"
          [innerHTML]="displayMessage.activeIngredient">
        </span>
      </div>
      <div class="field">
        <div class="field__label">{{ 'tasks.concentration' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.concentration }"
            type="text"
            formControlName="concentration"
            class="field__input"
            value="" />
        </div>
        <span
          class="field__input--alert"
          *ngIf="displayMessage.concentration"
          [innerHTML]="displayMessage.concentration">
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container formGroupName="supplier">
    <div class="field__row">
      <div class="field">
        <div class="field__label">{{ 'tasks.supplier-identification' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.supplierName }"
            type="text"
            formControlName="name"
            class="field__input"
            value="" />
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.supplierName" [innerHTML]="displayMessage.supplierName">
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container formGroupName="application">
    <div class="field__row">
      <div class="field">
        <div class="field__label">{{ 'tasks.product-dose' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.dosage }"
            type="text"
            formControlName="dosage"
            class="field__input"
            value="" />
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.dosage" [innerHTML]="displayMessage.dosage"> </span>
      </div>
      <div class="field">
        <div class="field__label">{{ 'tasks.used-quantity' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.rate }"
            type="text"
            formControlName="rate"
            class="field__input"
            value="" />
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.rate" [innerHTML]="displayMessage.rate"> </span>
      </div>
      <div class="field">
        <div class="field__label">{{ 'tasks.product-total-quantity' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.total }"
            type="text"
            formControlName="total"
            class="field__input"
            value="" />
        </div>
        <span class="field__input--alert" *ngIf="displayMessage.total" [innerHTML]="displayMessage.total"> </span>
      </div>
    </div>
    <div class="field__row">
      <div class="field">
        <div class="field__label">{{ 'tasks.grace-period' | translate }}</div>
        <div class="field__wrap">
          <input
            [ngClass]="{ 'field__input--alert': displayMessage.waitingPeriod }"
            type="text"
            formControlName="waitingPeriod"
            class="field__input"
            value="" />
        </div>
        <span
          class="field__input--alert"
          *ngIf="displayMessage.waitingPeriod"
          [innerHTML]="displayMessage.waitingPeriod">
        </span>
      </div>
    </div>
  </ng-container>
  <div class="field">
    <div class="field__label">{{ 'global.description' | translate }}</div>
    <div class="field__wrap">
      <textarea formControlName="description" id="" class="field__input" cols="30" rows="4"></textarea>
    </div>
  </div>
  <p>{{ 'tasks.confirm-data-before-saving' | translate }}</p>
  <div class="field__btns">
    <app-button
      [disabled]="disableButton || !fieldOperationFormGroup.valid"
      type="submit"
      color="primary"
      icon="agres-save"
      title="{{ 'global.save' | translate }}">
    </app-button>
    <app-button
      [disabled]="disableButton || !fieldOperationFormGroup.valid"
      color="return"
      type="reset"
      icon="agres-close"
      title="{{ 'global.clear' | translate }}">
    </app-button>
  </div>
</form>
