import { Component, OnInit } from '@angular/core';
import { Event, RouterEvent, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { filter } from 'rxjs/operators';
import { AppConstants } from './app.constants';
import { AnalyticsService } from './core/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    public router: Router,

  ) {
    this.setLanguage(translate);

    this.eventsRoutes(router);

    console.log('RUNNING ENVIRONMENT: ', environment.environmentName);
  }

  ngOnInit(): void {}

  setLanguage(translate) {
    const lang = localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG);

    if (lang) {
      translate.use(lang);
    } else {
      translate.setDefaultLang(AppConstants.LANGUAGES.PT_BR);
    }
  }

  eventsRoutes(router) {
    router.events
      .pipe(
        filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
      )
      .subscribe((e: NavigationEnd) => {
        this.checkDeviceIsMobile();
      });
  }

  checkDeviceIsMobile() {
    let isDeviceMobile = false;

    if ($('#spanDeviceIsDesktop').css('display') != 'none') {
      isDeviceMobile = true;
    }
  }
}
