import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-data-box',
  templateUrl: './data-box.component.html',
  styleUrls: ['./data-box.component.scss'],
})
export class DataBoxComponent {
  @Input() data: Array<[string, any]>;
  @Input() breakpoint = 20;
}
