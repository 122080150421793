import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

type Content = {
  [key: string]: string;
};

type Item = {
  [key: string]: string;
};

type Base64Image = string;

type EquipamentoLogo = {
  [key: string]: string;
};
interface MenuItem {
  label: string;
  callback: (id: string) => void;
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() id: string;
  @Input() content: Content;
  @Input() item: Item;
  @Input() cardImage: TemplateRef<any>;
  @Input() imageRaster: Base64Image;
  @Input() title: string;
  @Input() menuItems: Array<MenuItem>;
  @Input() backgroundImage: string | null;
  @Input() statusTranslationPrefix: string;
  @Input() status: string;
  @Input() openMenu = false;
  @Input() imagemPathEquipamento;
  @Input() cardNotificationGFreeTier: TemplateRef<any>;
  @Input() onCardClicked: ((callback: () => void) => void) | null;
  @Output() cardChanged = new EventEmitter();
  menuAtual = '';

  areaCoberta: string;
  data: string;
  operacao: string;

  constructor(
    private elementRef: ElementRef,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {

    if (this.content['Area Coberta']) {
      const areaCobertaText = this.content['Area Coberta'];
      const matches = areaCobertaText.match(/(\d+(\.\d+)?)\s*ha/);
      if (matches && matches.length > 0) {
        this.areaCoberta = matches[0];
      } else {
        this.areaCoberta = areaCobertaText;
      }
    }

    if (this.content['Data:']) {
      this.data = this.content['Data:'].replace('Data : ', '');
    }

    if (this.content['Operação']) {
      const operacaoText = this.content['Operação'];
      const matches = operacaoText.match(/:\s*(.*)/);
      if (matches && matches.length > 1) {
        this.operacao = matches[1].trim();
      } else {
        this.operacao = operacaoText;
      }
    }
    document.addEventListener('click', (event) => {
      if (!this.elementRef.nativeElement.contains(event.target)) {
        if (this.openMenu) {
          this.openMenu = false;
          this.cardChanged.emit(this.id);
        }
      }
    });
  }

  public getStatusClass(status: string): string {
    if (status === 'completed') {
      return 'cardTasks__content--info---description----statusSuccess';
    } else if (status === 'error') {
      return 'cardTasks__content--info---description----statusDanger';
    } else if (status === 'processing') {
      return 'cardTasks__content--info---description----statusWarning';
    } else {
      return '';
    }
  }

  onClickMenu(event: MouseEvent) {
    event.stopPropagation();
    if ((this.status === 'completed', 'error', 'processing')) {
      if (this.openMenu) {
        this.openMenu = false;
      } else {
        this.openMenu = true;
      }

      this.cardChanged.emit(this.id);
    }
  }

  disableButton(status: string): boolean {
    if (status === 'completed' && this.onCardClicked !== null) {
      return false;
    } else {
      return true;
    }
  }

  onCardClick() {
    if (this.status === 'completed') {
      this.onCardClicked(() => {});
    }
  }
}
