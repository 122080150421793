<div class="markers">
  <h1 class="markers--title">{{ 'tasks.title-markers' | translate }}</h1>
  <div *ngFor="let ma of marcadoresArrayL" class="markers--items">
    <div [ngClass]="{ 'col-md-offset-0 col-marcadores-children': ma.children }">
      <mat-slide-toggle color="primary" [checked]="ma.checked" (change)="toggleMarker(ma)">{{
        ma.title | translate
      }}</mat-slide-toggle>
    </div>
  </div>
</div>
