// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  environmentName: 'DEV',
  urlApi: 'https://isofarm-dev.azure-api.net/v1/',
  linkUrlSite: 'https://mango-rock-0b1b3db10.3.azurestaticapps.net/',
  apiKeyGoogleMaps: 'AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo',
  subscriptionKey: 'b2f10bb5accf463fa22a5992f723bfb5',
  azureBlobStorage: 'https://isofarmdev.blob.core.windows.net/',
  azureBlobStorageSupportFile:
    'https://isofarmdev.blob.core.windows.net/support-file/',
  azureBlobbStorageSupportFileSassToken:
    '?sp=racwdli&st=2025-02-16T00:23:44Z&se=2026-04-30T08:23:44Z&spr=https&sv=2022-11-02&sr=c&sig=ZhCD%2FGRGJyBY80PrF1Fhef8nA%2FxiJVW3%2FexlWIMLKlM%3D',
};
