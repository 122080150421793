<div class="sidebar_rigth__mapLegends" *ngIf="this.preferences[this.preferencesIndex]">
  <div class="mb-4">
    <ul
      class="listScale-m1"
      *ngFor="
          let legend of this.preferences[this.preferencesIndex] | keyvalue: valueAscOrder;
          let i = index;
        trackBy: trackByFn
      ">
      <li class="colorWindow-m1">
        <input
          [id]="legend.key"
          [name]="legend.key"
          type="number"
          [(ngModel)]="this.preferences[this.preferencesIndex][legend.key]"
          [ngModelOptions]="{ standalone: true }"
          class="legendText-m1 input-custom"
          (keyup)="watchInputsTx()" />
      </li>
    </ul>
    <p class="text-application-rate b map-label-1">{{ preferenceSubtitle | translate }} {{ preferenceSubtitleUnit }}</p>
    <p class="text-danger text-center msg-error-legend" *ngIf="showError">
      {{ 'tasks.error-values-legend' | translate }}
    </p>
  </div>
</div>
