import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { FileManagerService } from 'app/core/services/file-manager.service';
import { DeviceService } from 'app/modules/gestao-operacao/fleet-monitoring/device/device.service';
import { AgresSpinnerCanvasService } from 'app/shared/components/agres-spinner-canvas/agres-spinner-canvas.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { UserFilesManager } from 'app/utils/user-files-manager';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-send-field-files',
  templateUrl: './send-field-files.component.html',
  styleUrls: ['./send-field-files.component.scss'],
})
export class SendFieldFilesComponent implements OnInit {
  @Input() fileName: string;
  @Input() offCanvasDevices = new Subject<boolean>();
  @Input() filesToAssociate: Array<string>;
  @Input() fieldKey: string;
  deviceSelected: string;
  devices: Array<string>;
  userLogged: string;

  private cloudantManager: UserFilesManager;

  constructor(
    private readonly deviceService: DeviceService,
    public fileManagerService: FileManagerService,
    public translateService: TranslateService,
    public alertService: AlertService,
    public loadingAgresSpinnerCanvas: AgresSpinnerCanvasService,
    public httpClient: HttpClient,
  ) {
    this.cloudantManager = new UserFilesManager(fileManagerService, httpClient);
  }

  ngOnInit() {
    this.userLogged = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    ).id;
    this.translateService.get('global.loading.map-wait').subscribe((res) => {
      this.loadingAgresSpinnerCanvas.toShow(res);
    });
    this.getDevices();
  }

  sendFiles() {
    const filesWithFieldId = this.filesToAssociate.map(
      (fileName) => `${this.fieldKey}/${fileName}`,
    );
    this.cloudantManager
      .saveData(
        this.userLogged,
        filesWithFieldId,
        'devices',
        this.deviceSelected,
      )
      .subscribe(
        () => {
          this.feedBackToUser('global.alert.send-success', 'success');
          this.offCanvasDevices.next(false);
        },
        (error) => {
          this.feedBackToUser(error, 'error');
        },
      );
  }

  private feedBackToUser(message: string, type: string) {
    this.translateService.get(message).subscribe((res: string) => {
      switch (type) {
        case 'success':
          this.alertService.success(res);
          break;
        case 'error':
          this.alertService.error(res);
          break;
        case 'info':
          this.alertService.info(res);
          break;
        default:
          this.alertService.info(res);
          break;
      }
    });
  }

  onDeviceChange(event): void {
    this.deviceSelected = event.target.value;
  }
  async getDevices() {
    this.devices = await this.getAllDevices();
    this.loadingAgresSpinnerCanvas.toHide();
  }
  private getAllDevices() {
    return this.deviceService.getAllDeviceRealtime().toPromise<Array<string>>();
  }
}
