<form [formGroup]="registrarForm" novalidate (ngSubmit)="adicionarDevice()">
  <div class="modal__body">
    <div class="row">
      <div class="modal__body--form col-md-6" [ngClass]="{ 'has-error': displayMessage.IdIsoView }">
        <label class="modal__body--form---label">{{ 'devices.form-iso-id' | translate }}</label>
        <input type="text" class="input-field" formControlName="IdIsoView" />
        <span class="text-danger" *ngIf="displayMessage.IdIsoView">
          <p [innerHTML]="displayMessage.IdIsoView"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label">{{ 'global.mail' | translate }}</label>
        <ng-select formControlName="selectedUser" class="input-field-select">
          <ng-option *ngFor="let user of users" [value]="user.id">{{ user.name }}</ng-option>
        </ng-select>
      </div>
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label">{{ 'devices.authorize-use' | translate }}</label
        ><br />
        <ui-switch size="small" formControlName="AutorizoUso"></ui-switch>
      </div>
    </div>
  </div>
  <div class="modal__linha"></div>
  <div class="modal__footer">
    <button type="button" class="button button__danger" data-bs-dismiss="modal" (click)="hideModal()">
      {{ 'global.cancel' | translate }}
    </button>
    <button type="submit" class="button button__primary" [disabled]="disableButton" data-bs-dismiss="modal">
      {{ 'global.save' | translate }}
    </button>
  </div>
</form>
