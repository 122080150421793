<app-confirmar-modal
  *ngIf="mostrarModalConfirmacao"
  [(modal)]="mostrarModalConfirmacao"
  [mensagem]="mensagemModalConfirmacao"
  [tipo]="tipoModalConfirmacao"
  [titulo]="tituloModalConfirmacao"
  [conteudo]="idFileSelectedToDelete"
  [confirmButtonText]="modalTranslateButtonConfirmText"
  [cancelButtonText]="modalTranslateButtonCancelText"
  (modalChange)="mostrarModalConfirmacao = $event">
</app-confirmar-modal>
