import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { AppConstants } from '../../app.constants';
import * as convertUnitsLib from 'convert-units';
import { SystemNumberFormatPipe } from './systemNumberFormat.pipe';

@Pipe({
  name: 'convertUnit',
  pure: false,
})
export class ConvertUnit implements PipeTransform {
  public currentUnit;
  systemNumberFormatPipe: SystemNumberFormatPipe = new SystemNumberFormatPipe();
  public UNITS_TO_EXCLUDE = [
    'm2',
    'cm2',
    'mm2',
    'm/s',
    'kl',
    'm3',
    'kanna',
    'knot',
    'ft/s',
    'yd3',
    'ft3',
    'mt',
    'dl/s',
    'in2',
    'yd2',
    'ft2',
    'tsp',
    'qt',
    'km2',
    'Tbs',
    'ml',
    'glas',
    'krm',
    'tsk',
    'msk',
    'kkp',
    'dl',
    'mm3',
    'cm3',
    'cl',
    'dl',
    'mm',
  ];

  public UNSUPORTED_UNITS = ['l/ha', 'kg/ha', 'seeds/m', 'seeds/yd', 'gal/ac', 'lb/ac'];

  public SUBTITLE_UNITS_METRIC_TO_IMPERIAL = {
    m: 'yd',
    'km/h': 'm/h',
  };
  public SUBTITLE_UNITS_IMPERIAL_TO_METRIC = {
    yd: 'm',
    'm/h': 'km/h',
  };

  constructor(public changeDetector: ChangeDetectorRef) {
    this._updateCurrentUnits();
  }

  transform(value: number, unit: string): string {
    if (value <= 1 && unit === 'l/min')
      return `${this.systemNumberFormatPipe.transform(value, 3, this.currentUnit)} ${unit}`;
    this._updateCurrentUnits();
    const bestUnit = this.convertUnits(value, unit);

    return `${this.systemNumberFormatPipe.transform(bestUnit.val, 2, this.currentUnit)} ${bestUnit.unit}`;
  }

  use(unitSystem: string) {
    this.currentUnit = unitSystem;
    localStorage.setItem(AppConstants.KEYS_LOCAL_STORAGE.UNITS, this.currentUnit);
    this.changeDetector.markForCheck();
    return;
  }

  getCurrentUnitSystem() {
    return this.currentUnit;
  }

  transformUnit(unit: string): string {
    if (this.currentUnit === AppConstants.UNIT_SYSTEM_OPTIONS.IMPERIAL) {
      return this._convertUnitString(unit);
    } else {
      return unit;
    }
  }

  getUnitFromSISystem(unit: string, fromUnit: string): string {
    if (fromUnit === AppConstants.UNIT_SYSTEM_OPTIONS.IMPERIAL) {
      return this._convertUnitString(unit);
    } else {
      return unit;
    }
  }

  convertUnits(value, unit) {
    if (this.UNSUPORTED_UNITS.includes(unit)) {
      return this._convertUnsuportedUnits(value, unit, this.currentUnit);
    }

    try {
      const currentUnits = this._getAllUnits(unit, this.currentUnit);
      const convertedSystem = convertUnitsLib(value)
        .from(unit)
        .to(currentUnits[0].abbr);

      let bestUnit = convertUnitsLib(convertedSystem)
        .from(currentUnits[0].abbr)
        .toBest({ exclude: this.UNITS_TO_EXCLUDE });

      bestUnit = this.adjustSpecialUnits(bestUnit);

      return bestUnit;
    } catch {
      return { unit: '', val: 0 };
    }
  }

  private adjustSpecialUnits(unit) {
    switch (unit.unit) {
      case AppConstants.UNIT_TEMPERATURE_OPTIONS.CELSIUS:
      case AppConstants.UNIT_TEMPERATURE_OPTIONS.FAHRENHEIT:
        unit.unit = '°' + unit.unit;
        break;

      case AppConstants.UNIT_SPEED_OPTIONS.MILES_PER_HOUR:
        unit.unit = 'mph';
        break;

      default:
    }

    return unit;
  }

  convertAllValuesFromArrayToSameUnit(array: Array<number>, unit: string): Array<number> {
    return array.map((el) => this.convertValueToSupportedMapUnits(el, unit));
  }

  convertValueToSupportedMapUnits(value: number, unit: string): number {
    if (this.UNSUPORTED_UNITS.includes(unit)) {
      return +this._convertUnsuportedUnits(value, unit, this.currentUnit)['val'].toFixed(2);
    } else {
      try {
        const possibleUnitsToConvert = this._getAllUnits(unit, this.currentUnit);
        if (this._isAlreadyAtTheRightUnitSystem(possibleUnitsToConvert, unit)) {
          return value;
        } else {
          const conversion = this.SUBTITLE_UNITS_METRIC_TO_IMPERIAL[unit]
            ? this.SUBTITLE_UNITS_METRIC_TO_IMPERIAL[unit]
            : this.SUBTITLE_UNITS_IMPERIAL_TO_METRIC[unit];
          return +convertUnitsLib(value).from(unit).to(conversion).toFixed(2);
        }
      } catch {
        return value;
      }
    }
  }

  _isAlreadyAtTheRightUnitSystem(possibleUnitsToConvert: Array<any>, currentUnit: string): boolean {
    return possibleUnitsToConvert.map((u) => u.abbr).indexOf(currentUnit) >= 0;
  }

  _updateCurrentUnits() {
    const units = localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.UNITS);
    units ? this.use(units) : this.use(AppConstants.UNIT_SYSTEM_OPTIONS.METRIC);
  }

  _getAllUnits(unit, currentUnit) {
    const measureSupport = convertUnitsLib().describe(unit).measure;
    const currentUnits = convertUnitsLib()
      .list(measureSupport)
      .filter((un) => this._filterBySystem(un, currentUnit));
    return currentUnits;
  }

  convertToSIUnit(value: number, unit: string): any {
    if (this.UNSUPORTED_UNITS.includes(unit)) {
      return this._convertUnsuportedUnits(value, unit, AppConstants.UNIT_SYSTEM_OPTIONS.METRIC);
    } else {
      try {
        const possibleUnitsToConvert = this._getAllUnits(unit, AppConstants.UNIT_SYSTEM_OPTIONS.METRIC);
        if (this._isAlreadyAtTheRightUnitSystem(possibleUnitsToConvert, unit)) {
          return { unit, val: value };
        } else {
          const conversion = this.SUBTITLE_UNITS_IMPERIAL_TO_METRIC[unit];
          return {
            unit,
            val: +convertUnitsLib(value).from(unit).to(conversion).toFixed(2),
          };
        }
      } catch (error) {
        return { unit, val: 0 };
      }
    }
  }

  _convertUnitString(unit) {
    return (
      {
        ['l/ha']: 'gal/ac',
        ['kg/ha']: 'lb/ac',
        ['m']: 'yd',
        ['km/h']: 'm/h',
        ['seeds/m']: 'seeds/yd',
      }[unit] || ''
    );
  }

  _convertUnsuportedUnits(value, unit, currentUnit) {
    return (
      {
        [this.UNSUPORTED_UNITS[0]]: this._litresHectareGallonAcre(value, unit, currentUnit),
        [this.UNSUPORTED_UNITS[1]]: this._kilogramHectarePoundsAcre(value, unit, currentUnit),
        [this.UNSUPORTED_UNITS[2]]: this._seedsByMetersSeedsYard(value, unit, currentUnit),
        [this.UNSUPORTED_UNITS[3]]: this._seedsYardSeedsByMeters(value, unit, currentUnit),
        [this.UNSUPORTED_UNITS[4]]: this._gallonAcreLitresHectare(value, unit, currentUnit),
        [this.UNSUPORTED_UNITS[5]]: this._poundsAcreKilogramHectare(value, unit, currentUnit),
      }[unit] || ''
    );
  }

  _litresHectareGallonAcre(value, unit, currentUnit) {
    if (currentUnit === AppConstants.UNIT_SYSTEM_OPTIONS.IMPERIAL) {
      const gallonAcre = { unit: 'gal/ac', val: +value * 0.09 };
      return gallonAcre;
    } else {
      const lha = { unit: unit, val: value ? +value : 0 };
      return lha;
    }
  }

  _gallonAcreLitresHectare(value, unit, currentUnit) {
    if (currentUnit === AppConstants.UNIT_SYSTEM_OPTIONS.METRIC) {
      const gallonAcre = { unit: 'l/ha', val: +value / 0.09 };
      return gallonAcre;
    } else {
      const lha = { unit: unit, val: value ? +value : 0 };
      return lha;
    }
  }

  _seedsByMetersSeedsYard(value, unit, currentUnit) {
    if (currentUnit === AppConstants.UNIT_SYSTEM_OPTIONS.IMPERIAL) {
      const seedsByYd = { unit: 'seeds/yd', val: +value * 1.09361 };
      return seedsByYd;
    } else {
      const seedsByMeters = { unit: unit, val: value ? +value : 0 };
      return seedsByMeters;
    }
  }

  _seedsYardSeedsByMeters(value, unit, currentUnit) {
    if (currentUnit === AppConstants.UNIT_SYSTEM_OPTIONS.METRIC) {
      const seedsByM = { unit: 'seeds/m', val: +value * 0.9144 };
      return seedsByM;
    } else {
      const seedsByYd = { unit: unit, val: value ? +value : 0 };
      return seedsByYd;
    }
  }

  _kilogramHectarePoundsAcre(value, unit, currentUnit) {
    if (currentUnit === AppConstants.UNIT_SYSTEM_OPTIONS.IMPERIAL) {
      const poundsAcre = { unit: 'lb/ac', val: +value * 0.89 };
      return poundsAcre;
    } else {
      const kghaValue = { unit: unit, val: value ? +value : 0 };
      return kghaValue;
    }
  }

  _poundsAcreKilogramHectare(value, unit, currentUnit) {
    if (currentUnit === AppConstants.UNIT_SYSTEM_OPTIONS.METRIC) {
      const poundsAcre = { unit: 'kg/ha', val: +value / 0.89 };
      return poundsAcre;
    } else {
      const kghaValue = { unit: unit, val: value ? +value : 0 };
      return kghaValue;
    }
  }

  _filterBySystem(u, currentUnit) {
    return u.system === currentUnit;
  }
}
