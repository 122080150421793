import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ServiceBase } from './service.base';

@Injectable()
export class EmailService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  public dataSource = new BehaviorSubject<any>([]);
  currenteData = this.dataSource.asObservable();

  changeData(data: any[]) {
    this.dataSource.next(data);
  }

  sendEmail(params) {
    return this.http
      .post(this.UrlServiceV1 + 'email', params, this.obterAuthHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
}
