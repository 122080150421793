import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shadow',
  templateUrl: './shadow.component.html',
  styleUrls: ['./shadow.component.scss']
})
export class ShadowComponent {

  @Input() styleClass: string;

  constructor() { }
}
