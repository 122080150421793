<app-alert></app-alert>
<div class="container">
  <div class="delete-account-container">
    <h2 class="delete-account-title">{{ 'global.warning-message-delete-account' | translate }}</h2>
    <p class="delete-account-message">{{ 'global.message-delete-account' | translate }}</p>
  </div>
  <form #deleteForm="ngForm" class="form-horizontal" [formGroup]="myDeleteForm">
    <div class="field__row">
      <div class="field">
        <div class="field__label">{{ 'global.mail' | translate }}</div>
        <div class="field__wrap">
          <input
            class="field__input"
            type="email"
            id="email"
            formControlName="email"
            placeholder=""
            [value]=""
            [formControl]="" />
        </div>
      </div>
      <div class="field">
        <div class="field__label">{{ 'global.password' | translate }}</div>
        <div class="field__wrap">
          <input
            type="password"
            formControlName="password"
            class="field__input"
            value=""
            [type]="isConfirmPasswordVisible ? 'text' : 'password'"
            minlength="4"
            maxlength="16"
            autocomplete="off" />
          <span class="field__view">
            <app-agres-icons
              styleClass="field__view"
              [ngClass]="isConfirmPasswordVisible ? 'agres-eye' : 'agres-eye-off'"
              (click)="confirmTogglePassword()"></app-agres-icons>
          </span>
        </div>
      </div>
    </div>
    <div class="buttons-row">
      <app-button
        (btnClick)="backToProfile()"
        type="button"
        color="primary"
        icon="agres-close"
        title="Cancelar"></app-button>
      <app-button
        (btnClick)="onDeleteUser()"
        type="submit"
        color="danger"
        icon="agres-delete"
        title="Deletar"></app-button>
    </div>
  </form>
</div>
