export class Machine {
    oil_press = 0;
    bat_volt = 0;
    engine_type = 'Engine Type';
    engine_temp = 0;
    water_temp = 0;
    hourimeter = 0;
    oil_temp = 0;
    rpm = 0;
    fuel_lvl = 0;
}
