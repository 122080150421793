import { OpType, OperationType } from '../../enums/operation-type.enum';

export class DynamicMessageOperationTabModel {
  constructor(
    public implementWidth: number = 0,
    public numberOfSections: number = 0,
    public operationSleepTime: string = '',
    public operationActiveTime: string = '',
    public operationTotalTime: string = '',
    public travelDistance: number | string = 0,
    public instRate: number = 0,
    public sectionArr: string[] = [],
    public operationTimePercent: number = 0,
    public operationType: OperationType = OpType.SPRAYING,
  ) {}
}

export class StaticMessageOperationTabModel {
  constructor(
    public cropType: string = '',
    public cropName: string = '',
    public eventName: string = '',
    public numberOfNozzles: string = '',
    public nozzlesPerSection: number[] = [],
    public spacingPerNozzles: number | string = 0,
  ) {}
}
