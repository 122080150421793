export interface ValidatorError {
    property: string;
    message: string;
}

export class ValidationContract {
    public errors: Array<ValidatorError> = [];

    constructor() {
        this.errors = [];
    }

    isRequired = (value, property, message) => {
        if (!value || value.length <= 0)
            this.errors.push({ property: property, message: message });
    };

    hasMinLen = (value, min, property, message) => {
        if (!value || value.length < min)
            this.errors.push({ property: property, message: message });
    };

    hasMaxLen = (value, max, property, message) => {
        if (!value || value.length > max)
            this.errors.push({ property: property, message: message });
    };

    isGreaterThan = (value, comparer, property, message) => {
        if (value <= comparer)
            this.errors.push({ property: property, message: message });
    };

    IsGreaterOrEqualsThan = (value, comparer, property, message) => {
        if (value <= comparer)
            this.errors.push({ property: property, message: message });
    };

    isGreaterThanZero = (value, property, message) => {
        if (value <= 0)
            this.errors.push({ property: property, message: message });
    };

    isLowerThan = (value, comparer, property, message) => {
        if (value >= comparer)
            this.errors.push({ property: property, message: message });
    };

    IsLowerOrEqualsThan = (value, comparer, property, message) => {
        if (value > comparer)
            this.errors.push({ property: property, message: message });
    };

    isLowerThanZero = (value, property, message) => {
        if (value >= 0)
            this.errors.push({ property: property, message: message });
    };

    isFixedLen = (value, len, property, message) => {
        if (value.length !== len)
            this.errors.push({ property: property, message: message });
    };

    isEmail = (value, property, message) => {
        const reg = new RegExp(
            /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        );
        if (!reg.test(value))
            this.errors.push({ property: property, message: message });
    };

    getErrors = (): Array<ValidatorError> => {
        return this.errors;
    };

    getErrosByProperty(property) {
        return this.errors.find((x) => x.property === property);
    }

    existErroForProperty(property): boolean {
        return this.errors.find((x) => x.property === property) != null
            ? true
            : false;
    }

    clear = () => {
        this.errors = [];
    };

    isValid = () => {
        return this.errors.length === 0;
    };
}
