<div class="row">
  <div class="col-lg-12">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox">
        <div class="ibox-content">
          <div class="row">
            <div class="col-lg-6">
              <dl class="row mb-0">
                <div class="col-sm-4 text-sm-right">
                  <dt>{{ 'called.status' | translate }}:</dt>
                </div>
                <div class="col-sm-8 text-sm-left">
                  <dd class="mb-1">
                    <span class="label label-primary">{{ chamado.status }}</span>
                  </dd>
                </div>
              </dl>
              <dl class="row mb-0">
                <div class="col-sm-4 text-sm-right">
                  <dt>{{ 'global.number' | translate }}:</dt>
                </div>
                <div class="col-sm-8 text-sm-left">
                  <dd class="mb-1">{{ chamado.numero }}</dd>
                </div>
              </dl>
              <dl class="row mb-0">
                <div class="col-sm-4 text-sm-right">
                  <dt>{{ 'called.service' | translate }}:</dt>
                </div>
                <div class="col-sm-8 text-sm-left">
                  <dd class="mb-1">{{ chamado.servico }}</dd>
                </div>
              </dl>
              <dl class="row mb-0">
                <div class="col-sm-4 text-sm-right">
                  <dt>{{ 'called.category' | translate }}:</dt>
                </div>
                <div class="col-sm-8 text-sm-left">
                  <dd class="mb-1">
                    <a href="#" class="text-navy">{{ chamado.categoria }}</a>
                  </dd>
                </div>
              </dl>
              <dl class="row mb-0">
                <div class="col-sm-4 text-sm-right">
                  <dt>{{ 'global.description' | translate }}:</dt>
                </div>
                <div class="col-sm-8 text-sm-left">
                  <dd class="mb-1">
                    {{ chamado.descricao }}
                  </dd>
                </div>
              </dl>
            </div>
            <div class="col-lg-6">
              <div class="pull-right" *ngIf="isAdministrador()">
                <button class="btn btn-primary">
                  {{ 'global.cancel' | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="row m-t-sm">
            <div class="col-lg-12">
              <div class="panel blank-panel">
                <div class="panel-heading">
                  <div class="panel-options">
                    <ul class="nav nav-tabs">
                      <li>
                        <a class="nav-link active" href="#tab-1" data-toggle="tab">{{
                          'called.message' | translate
                        }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="panel-body">
                  <div class="tab-content">
                    <div class="tab-pane active" id="tab-1">
                      <div class="feed-activity-list" *ngFor="let mensagem of chamado.mensagens">
                        <div class="feed-element">
                          <a href="#" class="pull-left" *ngIf="mensagem.agres">
                            <img
                              alt="image"
                              class="rounded-circle"
                              src="../../../../assets/images/default/icone-agres.png"
                              style="width: 30px !important" />
                          </a>
                          <div class="media-body">
                            <p *ngIf="mensagem.agres" style="margin-bottom: 0 !important">
                              {{ 'called.posted-in' | translate }}
                            </p>
                            <p *ngIf="!mensagem.agres" style="margin-bottom: 0 !important">
                              <strong>{{ global.user }}</strong>
                              {{ 'global.posted-in' | translate }}
                            </p>
                            <small class="text-muted">{{ global.data }}</small>
                            <div class="well">
                              {{ global.message }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <textarea class="input-field"></textarea>
                      <button class="btn btn-primary pull-right" style="margin-top: 10px">
                        {{ 'global.send' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
