import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tab',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./tabs.component.scss'],

})
export class TabComponent {
  @Input() tabTitle: string;
  @Input() selected = false;
  @Input() active = true;
  @Output() tabClick = new EventEmitter();

  constructor(
    public translate: TranslateService,
  ) { }
}
