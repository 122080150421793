import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'numberFormat',
    pure: false,
})
export class NumberFormatPipe implements PipeTransform {
    constructor(public translate: TranslateService) {}

    transform(num: number | string, decimalPlaces: number): string {
        num = Number(num).toFixed(decimalPlaces);

        switch (this.translate.currentLang) {
            case 'en':
                break;

            case 'es':
                num = String(num).replace('.', ',');
                break;

            case 'ptBR':
                num = String(num).replace('.', ',');
                break;

            default:
                break;
        }

        return num;
    }
}
