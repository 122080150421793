import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from 'app/shared/components/alert/alert.service';

interface ViaCepAPI {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
  erro?: boolean;
}

interface Address {
  cep: string;
  city: string;
  uf: string;
  neighborhood: string;
  complement: string;
  street: string;
}

@Injectable({
  providedIn: 'root',
})
export class PostalCodeService {
  constructor(
    private readonly http: HttpClient,
    private translateService: TranslateService,
    public alertService: AlertService,
  ) {}

  searchCEP(cepValue: string): Observable<Address | null> {
    const url = `https://viacep.com.br/ws/${cepValue}/json/`;

    return this.http.get<ViaCepAPI>(url).pipe(
      map((data: ViaCepAPI) => this.mapToAddress(data)),
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse ||
          error.name === 'HttpErrorResponse' ||
          error.status === HttpStatusCode.BadRequest
        ) {
          this.translateService
            .get('global.validate.error-cep')
            .subscribe((res: string) => {
              this.alertService.error(res);
            });
          return of(null);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  private mapToAddress(data: ViaCepAPI): Address | null {
    if (
      data.cep &&
      data.localidade &&
      data.uf
    ) {
      return {
        cep: data.cep,
        city: data.localidade,
        uf: data.uf,
        neighborhood: data?.bairro,
        complement: data.complemento,
        street: data?.logradouro,
      };
    } else if (data.erro === true) {
      this.translateService
        .get('global.validate.error-cep-invalid')
        .subscribe((res: string) => {
          this.alertService.warning(res);
        });
      return null;
    } else {
      return null;
    }
  }
}
