import * as turf from '@turf/turf';

export interface Trails {
    color: string;
    paths: [{ lat: number; lng: number }];
    valueOperation?: any;
}
export interface TrailColorPreferences {
    trailColorScale: string;
    instNull: string;
}
export interface TrailSection {
    devSecLat: Array<number>;
    devSecLng: Array<number>;
}
export interface TrailSectionColor {
    devTrailRColor: Array<string>;
    devTrailLColor: Array<string>;
}

export class TrailClass {
    trailSection(
        index,
        secNumber,
        secWidthMeters,
        angleDegree,
        locationPoint
    ): TrailSection {
        const devSecLat: Array<number> = [];
        const devSecLng: Array<number> = [];
        secNumber = parseInt(secNumber);

        if (secNumber % 2 === 0) {
            for (let i = 0; i <= secNumber / 2; i++) {
                const destination = turf.destination(
                    locationPoint,
                    i * secWidthMeters,
                    angleDegree,
                    { units: 'meters' }
                );

                devSecLat[i] = destination.geometry.coordinates[1];
                devSecLng[i] = destination.geometry.coordinates[0];
            }
        } else {
            let discount = 0;
            for (let i = 0; i <= (secNumber + 1) / 2; i++) {
                if (i === 1) discount = secWidthMeters / 2;
                const destination = turf.destination(
                    locationPoint,
                    i * secWidthMeters - discount,
                    angleDegree,
                    { units: 'meters' }
                );

                devSecLat[i] = destination.geometry.coordinates[1];
                devSecLng[i] = destination.geometry.coordinates[0];
            }
        }
        return { devSecLat, devSecLng };
    }

    trailArray(
        deviceAngle,
        secNumber,
        devTRColor,
        devTLColor,
        devSRLatA,
        devSRLngA,
        devSRLatB,
        devSRLngB,
        devSLLatA,
        devSLLngA,
        devSLLatB,
        devSLLngB
    ): Array<Trails> {
        let trails: Array<any> = [];
        let iteratorEnd = 0;
        // Filtro para não gerar pontos irregulares como NaN e Undefined !!!
        if (
            devSRLatA.length > 0 &&
            devSRLatA[0] !== undefined &&
            devSRLngB.length > 0 &&
            devSLLatA.length > 0
        ) {
            if (secNumber % 2 !== 0) {
                iteratorEnd = secNumber / 2;
                devTLColor[0] = devTRColor[0];
            } else {
                iteratorEnd = secNumber / 2 - 1;
            }
            // add Right
            trails = this._addTrailsArray(
                trails,
                iteratorEnd,
                devTRColor,
                devSRLatA,
                devSRLngA,
                devSRLatB,
                devSRLngB
            );
            // add Left
            trails = this._addTrailsArray(
                trails,
                iteratorEnd,
                devTLColor,
                devSLLatA,
                devSLLngA,
                devSLLatB,
                devSLLngB
            );
        }
        return trails;
    }

    _addTrailsArray(
        trails,
        iteratorEnd,
        devColor,
        devSLatA,
        devSLngA,
        devSLatB,
        devSLngB
    ) {
        for (let i = 0; i <= iteratorEnd; i++) {
            trails.push({
                color: devColor[i],
                paths: [
                    { lat: +devSLatA[i], lng: +devSLngA[i] },
                    { lat: +devSLatA[i + 1], lng: +devSLngA[i + 1] },
                    { lat: +devSLatB[i + 1], lng: +devSLngB[i + 1] },
                    { lat: +devSLatB[i], lng: +devSLngB[i] },
                ],
            });
        }
        return trails;
    }

    trailUpdate(
        secNumber,
        devPathRB,
        devPathLB,
        devSecRLatB,
        devSecRLngB,
        devSecLLatB,
        devSecLLngB
    ) {
        const devPathRA = { lat: null, lng: null };
        const devPathLA = { lat: null, lng: null };
        const devSecRLatA: Array<any> = [];
        const devSecRLngA: Array<any> = [];
        const devSecLLatA: Array<any> = [];
        const devSecLLngA: Array<any> = [];

        devPathRA.lat = devPathRB.lat;
        devPathRA.lng = devPathRB.lng;
        const SecNumberOdd = (secNumber + 1) / 2;

        if (secNumber % 2 !== 0) {
            for (let i = 0; i <= SecNumberOdd; i++) {
                devSecRLatA[i] = devSecRLatB[i];
                devSecRLngA[i] = devSecRLngB[i];
            }
        } else {
            for (let i = 0; i <= secNumber / 2; i++) {
                devSecRLatA[i] = devSecRLatB[i];
                devSecRLngA[i] = devSecRLngB[i];
            }
        }

        devPathLA.lat = devPathLB.lat;
        devPathLA.lng = devPathLB.lng;

        if (secNumber % 2 !== 0) {
            for (let i = 0; i <= SecNumberOdd; i++) {
                devSecLLatA[i] = devSecLLatB[i];
                devSecLLngA[i] = devSecLLngB[i];
            }
        } else {
            for (let i = 0; i <= secNumber / 2; i++) {
                devSecLLatA[i] = devSecLLatB[i];
                devSecLLngA[i] = devSecLLngB[i];
            }
        }

        const deviceSection = {
            devPathRA,
            devPathLA,
            devSecRLatA,
            devSecRLngA,
            devSecLLatA,
            devSecLLngA,
        };

        return deviceSection;
    }

    _trailColorScaleByPreferences(preferences, direction, value, colors) {
        let trailColorScale;
        if (direction === 'desc') {
            if (value === '0') trailColorScale = colors[0];
            else if (value <= preferences.red) trailColorScale = colors[0];
            else if (value > preferences.red && value <= preferences.orange)
                trailColorScale = colors[1];
            else if (value > preferences.orange && value <= preferences.yellow)
                trailColorScale = colors[2];
            else if (
                value > preferences.yellow &&
                value <= preferences.lemonGreen
            )
                trailColorScale = colors[3];
            else if (value > preferences.lemonGreen)
                trailColorScale = colors[4];
        } else {
            if (value === '0') trailColorScale = colors[0];
            else if (value >= preferences.red) trailColorScale = colors[0];
            else if (value < preferences.red && value >= preferences.orange)
                trailColorScale = colors[1];
            else if (value < preferences.orange && value >= preferences.yellow)
                trailColorScale = colors[2];
            else if (
                value < preferences.yellow &&
                value >= preferences.lemonGreen
            )
                trailColorScale = colors[3];
            else if (value < preferences.lemonGreen)
                trailColorScale = colors[4];
        }
        return trailColorScale;
    }

    // DEFINE A COR DE CADA POLIGONO, DE ACORDO COM A PREFERENCIA DO MAPA.
    trailColorPref(
        trailHidden,
        workStatus,
        value,
        preferences,
        direction
    ): TrailColorPreferences {
        let trailColorScale: string;
        let instNull: string;

        const LEGEND_COLORS = {
            darkRed: '#FF0000',
            lightRed: '#F70C01',
            purple: '#9E00D1',
            blue: '#0052B4',
            orange: '#FA921F',
            yellow: '#FDFA31',
            lemonGreen: '#B2FD24',
            lightGreen: '#6DED38',
            darkGreen: '#009632',
            gray: 'gray',
        };

        if (trailHidden === 'false' || trailHidden === false) {
            if (workStatus === 'NOT_WORKING') {
                trailColorScale = 'gray';
                instNull = 'gray';
            } else {
                const colors = [
                    LEGEND_COLORS.darkRed,
                    LEGEND_COLORS.orange,
                    LEGEND_COLORS.yellow,
                    LEGEND_COLORS.lemonGreen,
                    LEGEND_COLORS.lightGreen,
                ];
                trailColorScale = this._trailColorScaleByPreferences(
                    preferences,
                    direction,
                    value,
                    colors
                );
                instNull = LEGEND_COLORS.gray;
            }
        } else if (trailHidden === 'pos_type') {
            if (workStatus === 'NOT_WORKING') {
                trailColorScale = LEGEND_COLORS.lightRed;
                instNull = LEGEND_COLORS.lightRed;
            } else {
                const colors = [
                    LEGEND_COLORS.lightRed,
                    LEGEND_COLORS.purple,
                    LEGEND_COLORS.blue,
                    LEGEND_COLORS.darkGreen,
                    LEGEND_COLORS.darkGreen,
                ];
                trailColorScale = this._trailColorScaleByPreferences(
                    preferences,
                    direction,
                    value,
                    colors
                );
                instNull = LEGEND_COLORS.lightRed;
            }
        } else if (trailHidden === 'rssi') {
            if (workStatus === 'NOT_WORKING') {
                trailColorScale = LEGEND_COLORS.lightRed;
                instNull = LEGEND_COLORS.lightRed;
            } else {
                const colors = [
                    LEGEND_COLORS.darkGreen,
                    LEGEND_COLORS.darkGreen,
                    LEGEND_COLORS.blue,
                    LEGEND_COLORS.purple,
                    LEGEND_COLORS.lightRed,
                ];
                trailColorScale = this._trailColorScaleByPreferences(
                    preferences,
                    direction,
                    value,
                    colors
                );
                instNull = LEGEND_COLORS.darkRed;
            }
        } else {
            instNull = 'gray';
            trailColorScale = 'gray';
        }

        const trailColorPref = { trailColorScale, instNull };

        return trailColorPref;
    }

    trailSectionColor(
        instNull,
        secNumber,
        secArr,
        secArrL,
        trailColorScale
    ): TrailSectionColor {
        const devTrailRColor: Array<string> = [];
        const devTrailLColor: Array<string> = [];

        if (secNumber % 2 !== 0) {
            secArrL = secArr.slice(0, (secNumber + 1) / 2).reverse();

            const secNumberSplit = secNumber - 1;
            for (let i = secNumberSplit / 2; i <= secNumberSplit; i++) {
                secArr[i] === '1'
                    ? (devTrailRColor[i - secNumberSplit / 2] = trailColorScale)
                    : (devTrailRColor[i - secNumberSplit / 2] = instNull);
            }
        } else {
            if (secNumber > 2)
                secArrL = secArr.slice(0, secNumber / 2).reverse();
            else secArrL = secArr;

            for (let i = secNumber / 2; i < secNumber; i++) {
                secArr[i] === '1'
                    ? (devTrailRColor[i - secNumber / 2] = trailColorScale)
                    : (devTrailRColor[i - secNumber / 2] = instNull);
            }
        }

        for (let i = 0; i <= secNumber / 2; i++) {
            secArrL[i] === '1'
                ? (devTrailLColor[i] = trailColorScale)
                : (devTrailLColor[i] = instNull);
        }

        const devTrailColor = {
            devTrailRColor,
            devTrailLColor,
        };

        return devTrailColor;
    }

    trailColor(secNumber, trailColorScale) {
        const devTrailRColor: Array<any> = [];
        const devTrailLColor: Array<any> = [];

        if (secNumber % 2 !== 0) {
            for (let i = 0; i <= secNumber / 2; i++) {
                devTrailLColor[i] = trailColorScale;
            }
            const secNumberOdd = (secNumber - 1) / 2;
            for (let i = secNumberOdd; i <= secNumber - 1; i++) {
                devTrailRColor[i - secNumberOdd] = trailColorScale;
            }
        } else {
            for (let i = 0; i <= secNumber / 2; i++) {
                devTrailLColor[i] = trailColorScale;
            }
            for (let i = secNumber / 2; i < secNumber; i++) {
                devTrailRColor[i - secNumber / 2] = trailColorScale;
            }
        }

        const devTrailColor = {
            devTrailRColor,
            devTrailLColor,
        };

        return devTrailColor;
    }
}
