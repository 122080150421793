<div class="column">
  <app-card-dashboard
    *ngFor="let card of cards"
    [title]="card.title | translate"
    [iconClass]="card.iconClass"
    [disabled]="card.disabled"
    [description]="card.description | translate"
    (linkClick)="navigateAndHandleClick(card.route)">
  </app-card-dashboard>
</div>
<ng-template #editUser>
  <app-form-atualizacao-cadastro
    [offCanvas]="showOffCanvas"
    [showOffCanvasSubject]="showOffCanvasSubject"
    [userId]="userId">
  </app-form-atualizacao-cadastro>
</ng-template>
<app-offcanvas
  size="medium"
  [show]="showOffCanvas"
  [bodyTemplate]="editUser"
  [showSubject]="showOffCanvasSubject"
  title="{{ 'profile.update' | translate }}">
</app-offcanvas>
