import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TranslateUtil } from 'app/utils/translate.utils';
import { AppConstants } from '../../../app.constants';

@Component({
    selector: 'app-politica-privacidade',
    templateUrl: 'politica-privacidade.template.html',
    styleUrls: ['politica-privacidade.component.scss'],
})
export class PoliticaPrivacidadeComponent {

    public languages_options: any;
    public translateUtils: TranslateUtil;

    constructor(
        public translate: TranslateService,
        public toastr: ToastrService,
        public router: Router
    )
    {
            this.translateUtils = new TranslateUtil();
    }

    changeLanguage(language) {
        if (language == null || language == undefined) {
            language = AppConstants.LANGUAGES.PT_BR;
        }

        this.translate.use(language);
    }

    ok() {
        this.router.navigateByUrl('/login');
    }

    voltar() {
        this.toastr.clear();
        this.router.navigateByUrl('/login');
    }


}
