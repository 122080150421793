<div class="nav">
  <ul class="nav nav__tabs">
    <li
      *ngFor="let tab of tabs"
      (click)="selectTab(tab)"
      [class.selected]="tab.selected"
      [class.inactive]="!tab.active">
      <span>{{ tab.tabTitle }}</span>
    </li>
  </ul>
  <ng-content></ng-content>
</div>
