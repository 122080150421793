import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from 'app/app-shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ConfirmacaoModalModule } from '../confirmacao-modal/confirmacao-modal..module';
import { CanalAtendimentoComponent } from './canal-atendimento/canal-atendimento.component';
import { ChamadoComponent } from './chamado/chamado.component';
import { ChamadosAgresComponent } from './chamados-agres/chamados-agres.component';
import { ChamadosFechadosComponent } from './chamados-fechados/chamados-fechados.component';
import { FaqComponent } from './faq/faq.component';
import { MeusChamadosComponent } from './meus-chamados/meus-chamados.component';
import { TutoriaisComponent } from './tutoriais/tutoriais.component';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DownloadFilePageComponent } from './download-file-page/download-file-page.component';
import { FormUploadFileComponent } from './download-file-page/form-upload-file/form-upload-file.component';
@NgModule({
  declarations: [
    TutoriaisComponent,
    CanalAtendimentoComponent,
    FaqComponent,
    MeusChamadosComponent,
    ChamadoComponent,
    ChamadosAgresComponent,
    ChamadosFechadosComponent,
    DownloadFilePageComponent,
    FormUploadFileComponent,
  ],
  imports: [
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,

    CommonModule,
    TranslateModule,
    PerfectScrollbarModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    PerfectScrollbarModule,
    BrowserModule,
    TooltipModule.forRoot(),
    ConfirmacaoModalModule,
    UiSwitchModule,
    BrowserAnimationsModule,
    AppSharedModule,
  ],
  exports: [],
})
export class AreaTrabalhoModule {}
