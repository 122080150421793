export enum UserPermissions {
  NAVIGATE = 0b0000_0001,
  CREATE = 0b0000_0010,
  READ = 0b0000_0100,
  UPDATE = 0b0000_1000,
  DELETE = 0b0001_0000,
  SEND_COMMAND = 0b0010_0000,
}

export enum UserRoleType {
  ADMIN = 0b0001,
  STANDARD = 0b0010,
  FREE_TIER = 0b0100,
  TELEMETRY_USER = 0b1000,
}

export enum UserResources {
  USER_ADMINISTRATION = 1,
  DEVICE_ADMINISTRATION,
  FIELD_ADMINISTRATION,
  FLEET_MONITORING,
  NAVIGATION_HISTORY,
  NAVIGATION_RESULT,
  WEATHER_FORECAST,
  PRESCRIPTION_MAPS,
  CUSTOMER_SUPPORT,
  USER_PROFILE,
}

export type CurrentUserPermissions = {
  canNavigate: boolean;
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
};

export class UserRole {
  type: UserRoleType = UserRoleType.FREE_TIER;
  authorization: { [key: string]: number } = {};

  constructor() {
    Object.keys(UserResources)
      .filter((value) => !Number.isNaN(parseInt(value)))
      .forEach((key) => {
        this.authorization[key] = 0;
      });
  }

  getResourcePermissions(resource: UserResources): CurrentUserPermissions {
    const permissions: number = this.authorization[resource];
    const currentUserPermissions: CurrentUserPermissions = {
      canNavigate: Boolean(permissions & UserPermissions.NAVIGATE),
      canCreate: Boolean(permissions & UserPermissions.CREATE),
      canRead: Boolean(permissions & UserPermissions.READ),
      canUpdate: Boolean(permissions & UserPermissions.UPDATE),
      canDelete: Boolean(permissions & UserPermissions.DELETE),
    };
    return currentUserPermissions;
  }

  getPermission(permission: UserPermissions, resource: UserResources): boolean {
    return (this.authorization[resource] & permission) > 0;
  }

  grant(permission: UserPermissions, resource: UserResources): void {
    this.authorization[resource] |= permission;
  }

  revoke(permission: UserPermissions, resource: UserResources): void {
    this.authorization[resource] &= ~permission;
  }
}
