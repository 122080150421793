import { Component, Input, OnInit, Output } from '@angular/core';
import { TarefasTalhaoFieldService } from 'app/core/services/operation-field.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmacaoModalService } from 'app/core/services/confirmacaoModal.service';
import { ExcelService } from 'app/core/services/excel.service';

@Component({
  selector: 'app-listagem-tarefa-talhao',
  templateUrl: './listagem-tarefa-talhao.component.html',
  styleUrls: ['./listagem-tarefa-talhao.component.scss'],
})
export class ListagemTarefaTalhaoComponent implements OnInit {
  OPERATION_IMG = {
    pulverization: './assets/images/operation/pulverizacao.png',
    'soil-preparation': './assets/images/operation/preparo-solo.png',
    harvest: './assets/images/operation/colheita.png',
    planting: './assets/images/operation/plantio.png',
    fertilizing: './assets/images/operation/adubacao.png',
    other: './assets/images/operation/outro.png',
  };

  public showSearchModalSubject: Subject<boolean> = new Subject();
  public showSearchModal: boolean;

  operations: [];
  operationId;
  operationsItens = [];
  originalsOperationsItens = [];

  operationsSelectedArray = [];
  rowsPerPage: number = 6;
  pagination: number[] = [0, this.rowsPerPage];
  pages: number[] = [];
  previousPages: number[] = [];
  pageAtual: number = 0;

  dataInicio: string = '';
  dataFinal: string = '';
  filteredData: any[] = [];

  valueInputSearch: string;
  arrayDeDatas: Object[];
  fieldName: string;

  operationIdToBeDeleted: string = '';
  STATUS_CONFIRMED_DELETE = 'confirmado';
  mostrarModalConfirmacao = false;
  mensagemModalConfirmacao = '';
  tipoModalConfirmacao = '';
  tituloModalConfirmacao = '';
  modalTranslateButtonConfirmText = '';
  modalTranslateButtonCancelText = '';
  exportAllSelectedOerations = '';
  exportAllOperations = '';
  updateOperationIdToBeDeletedSubject: Subject<string> = new Subject();
  fetchOperationData: Subject<boolean> = new Subject();
  selectedItens: boolean[] = [];
  hasSelectedItem: boolean;

  @Input() updateOperationIdToBeDeleted: Subject<string>;
  @Input() inputValue = '';
  @Output() reportDateNow = new Date().toLocaleDateString();
  @Input() operationFieldId;

  operation: string | null;

  private routeSub: Subscription;
  fieldId: string;
  public showModalSubject: Subject<boolean> = new Subject();
  public showModal: boolean;
  public namesFileExported: { [key: string]: { [key: string]: string } };

  constructor(
    public translateService: TranslateService,
    private fieldTaskService: TarefasTalhaoFieldService,
    public userTalhoesService: UserTalhaoListService,
    public confirmacaoModalService: ConfirmacaoModalService,
    public toastr: ToastrService,
    public router: Router,
    private route: ActivatedRoute,
    private excelService: ExcelService,
  ) {
    this.namesFileExported = {
      selectedExportedNameFile: {
        required: '',
      },
      allExportedNameFile: {
        required: '',
      },
    };
  }

  ngOnInit() {
    this.fieldId = this.route.snapshot.paramMap.get('fieldId');

    this.getAllTarefasTalhao();
    this.limitOfCaracters();
    this.updateSelectedItem(1, false);

    this.userTalhoesService
      .getTalhaoId(this.fieldId)
      .subscribe((dataTalhao) => {
        this.fieldName = dataTalhao.name;
      });

    this.confirmacaoModalService.changeData(null);

    this.confirmacaoModalService.currentData.subscribe((data) => {
      if (
        data != null &&
        data.conteudo.id != '' &&
        typeof data.conteudo.id == 'string'
      ) {
        if (data.message === this.STATUS_CONFIRMED_DELETE) {
          this.deleteTarefaTalhao(data.conteudo.id);
        }
        this.operationId = null;
      }
    });
    this.routeSub = this.route.params.subscribe((params) => {});
  }

  getAllTarefasTalhao(): void {
    this.fieldTaskService
      .getTarefaDetailsByFieldId(this.fieldId)
      .subscribe((operations) => {
        this.operationsItens = operations;
        this.originalsOperationsItens = operations;
        this.pages = new Array(Math.ceil(operations.length / this.rowsPerPage));
      });
  }

  translateConfirmationMessage() {
    this.translateService
      .get('global.alert.delete-title')
      .subscribe((operation: string) => {
        this.mostrarModalConfirmacao = true;
        this.mensagemModalConfirmacao = '';
        this.tituloModalConfirmacao = operation;
        this.tipoModalConfirmacao = 'warning';
        this.modalTranslateButtonConfirmText = 'global.delete';
        this.modalTranslateButtonCancelText = 'global.cancel';
      });
  }

  deleteTarefaTalhaoBtnClick(operation) {
    this.operation = operation;
    this.operationIdToBeDeleted = operation;
    this.tipoModalConfirmacao = 'warning';
    this.mostrarModalConfirmacao = true;
    this.translateConfirmationMessage();
  }

  deleteTarefaTalhao(operationId) {
    this.fieldTaskService.deleteTarefaTalhao(operationId).subscribe(
      (result) => {
        this.translateService
          .get('global.alert.success-delete')
          .subscribe((res: string) => {
            this.toastr.success(res, '', { timeOut: 5000 });
            this.fetchOperationData.next(true);
          });
        this.getAllTarefasTalhao();
      },
      (error) => {
        this.updateOperationIdToBeDeletedSubject.next('');
        let errorMessage: string = '';
        switch (error.status) {
          case 409:
            errorMessage = 'global.alert.no-data-found';
            break;

          default:
            errorMessage = 'global.validate.error-delete';
        }
        if (error.error !== undefined) {
          this.translateService
            .get(errorMessage)
            .subscribe((msgErro: string) => {
              this.toastr.error(msgErro, '', { timeOut: 10000 });
            });
        } else {
          this.translateService
            .get('global.validate.error-delete')
            .subscribe((msgErro: string) => {
              this.toastr.error(msgErro, '', { timeOut: 10000 });
            });
        }
      },
    );
  }

  timeStampToLocaleDateFormat(timestamp: number): string {
    return new Date(timestamp).toLocaleDateString();
  }

  openSearchModal() {
    this.showSearchModal = true;
    this.showSearchModalSubject.next(true);
  }
  closeSearchModal() {
    this.showSearchModal = false;
    this.showSearchModalSubject.next(false);
  }

  btnVisualizar(): void {
    // this.router.navigate(['/nova-funcionalidade']);
    alert('Estamos trabalhando nesta funcionalidade!');
  }

  verTarefaTalhaoById(operationFieldId: string): void {
    this.router.navigate([
      `/my-fields/gerenciamento-de-talhoes/detalhes-da-tarefa-talhao/${operationFieldId}`,
    ]);
  }
  editOperation(operationFieldId: string): void {
    this.router.navigate([
      `/my-fields/gerenciamento-de-talhoes/editar-operacoes/${operationFieldId}`,
    ]);
  }

  addOperation(): void {
    this.router.navigate([
      `/my-fields/gerenciamento-de-talhoes/criar-tarefa-talhao/${this.fieldId}`,
    ]);
  }

  backField(): void {
    this.router.navigate(['/my-fields']);
  }

  clickPagination(index: number, e: any): void {
    if (index >= 0 && index < this.pages.length) {
      if (e !== undefined) {
        e.preventDefault();
      }

      this.pagination = [
        index * this.rowsPerPage,
        index * this.rowsPerPage + this.rowsPerPage,
      ];

      this.pageAtual = index;
    }
  }

  searchOperations(event): void {
    this.valueInputSearch = event.target.value;
    if (this.valueInputSearch != undefined) {
      this.operationsItens = this.originalsOperationsItens.filter(
        (operationsItens: any) => {
          const parcialMath: boolean = operationsItens.crop.name
            .toLowerCase()
            .includes(this.valueInputSearch.toLocaleLowerCase());
          return parcialMath;
        },
      );
    } else {
      this.operationsItens = this.originalsOperationsItens;
    }
  }

  clearFilter(): void {
    this.operationsItens = this.originalsOperationsItens;
  }

  searchFilter(): void {
    if (this.dataInicio != '' && this.dataFinal != '') {
      var inputValueInicial: number = new Date(
        `${this.dataInicio}`,
      ).setUTCHours(21);
      var inputValueFinal: number = new Date(`${this.dataFinal}`).setUTCHours(
        21,
      );

      if (inputValueInicial > 0 && inputValueFinal > 0) {
        this.operationsItens = this.originalsOperationsItens.filter(
          (operationsItens) => {
            const startedAt: number = operationsItens.startedAt * 1000;
            const finishedAt: number = operationsItens.finishedAt * 1000;
            this.closeSearchModal();
            return (
              startedAt >= inputValueInicial && finishedAt <= inputValueFinal
            );
          },
        );
      }
    }
  }

  getOperationImagePath(operationType: string) {
    const imgPath = Object.entries(this.OPERATION_IMG).find((entry) => {
      return entry[0] == operationType;
    });
    if (imgPath && imgPath.length > 0) {
      return imgPath[1];
    }
  }

  limitOfCaracters() {
    const divTextDescription = $('h2#textDescription');
    divTextDescription.text(divTextDescription.text().substring(0, 120));
  }

  exportToExcellAllOperations() {
    this.translateService
      .get('tasks.all-operation-of')
      .subscribe((res: string) => {
        this.namesFileExported.allExportedNameFile.required = res;
      });

    this.exportDataToSpreadsheet(
      this.operationsItens,
      ` ${this.namesFileExported.allExportedNameFile.required}-${this.fieldName}`,
    );
  }

  exportAllSelected(): void {
    const filtered = this.operationsItens.filter((_e, i: number) => {
      return this.selectedItens[i];
    });

    this.translateService
      .get('tasks.all-selected-operation-of')
      .subscribe((res: string) => {
        this.namesFileExported.selectedExportedNameFile.required = res;
      });

    this.exportDataToSpreadsheet(
      filtered,
      ` ${this.namesFileExported.selectedExportedNameFile.required}-${this.fieldName}`,
    );
  }

  exportDataToSpreadsheet(
    data: { [key: string]: string | number }[],
    filename: string,
  ) {
    let flattedOperations = data.map((object) => {
      let objFlatten = this.flatten(object);
      delete objFlatten.fieldId;
      delete objFlatten._rev;
      delete objFlatten.id;
      delete objFlatten.rateUnit;
      delete objFlatten.totalUnit;

      if (typeof object.startedAt === 'number')
        objFlatten.startedAt = this.convertFirstTimeStampToStringDate(
          object.startedAt,
        );
      if (typeof object.createdAt === 'number')
        objFlatten.createdAt = this.convertFirstTimeStampToStringDate(
          object.createdAt,
        );
      if (typeof object.finishedAt === 'number')
        objFlatten.finishedAt = this.convertFirstTimeStampToStringDate(
          object.finishedAt,
        );

      return objFlatten;
    });

    const aoa = this.translateOperationsData(flattedOperations);

    this.excelService.exportAoAAsExcelFile(aoa, filename);
  }

  private convertFirstTimeStampToStringDate(unixTimestamp: number) {
    return new Date(unixTimestamp).toLocaleString();
  }

  flatten(obj, final: { [key: string]: string | number } = {}, baseKey = '') {
    for (const key of Object.keys(obj)) {
      const givenKey = !baseKey ? key : `${baseKey}.${key}`;
      if (key != 'id') {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.flatten(obj[key], final, givenKey);
        } else {
          final[givenKey] = obj[key];
        }
      }
    }

    return final;
  }

  translateOperationsData(flattedOperations): [][] {
    const tableHeader = [];
    const result = [];
    const typeOperationKey = 'type.operation';
    const translationMap = {
      'application.dosage': 'tasks.table-export.dosage',
      'application.rate': 'tasks.table-export.rate',
      'application.total': 'tasks.table-export.total',
      'application.waitingPeriod': 'tasks.table-export.grace-period',
      createdBy: 'tasks.table-export.create-by',
      'crop.adversity': 'tasks.table-export.adversity',
      'crop.name': 'tasks.table-export.culture-name',
      'crop.stage': 'tasks.table-export.culture-stage',
      startedAt: 'tasks.table-export.initial-date',
      finishedAt: 'tasks.table-export.final-date',
      'machine.name': 'tasks.table-export.machine-name',
      'operator.name': 'tasks.table-export.operator-name',
      'product.activeIngredient': 'tasks.table-export.activeIngredient',
      'product.name': 'tasks.product-name',
      'product.supplier.name': 'tasks.table-export.supplier-name',
      [typeOperationKey]: 'tasks.table-export.operation-type',
      description: 'tasks.table-export.description',
    };

    Object.keys(translationMap).map((name: string) => {
      this.translateService.get(translationMap[name]).subscribe((value) => {
        tableHeader.push(value);
      });
    });

    result.push(tableHeader);

    flattedOperations.forEach((operation) => {
      const tempArray = [];

      Object.keys(translationMap).forEach((name) => {
        if (name === typeOperationKey) {
          this.translateService
            .get('tasks.type.' + operation[name])
            .subscribe((value) => {
              tempArray.push(value);
            });
        } else {
          tempArray.push(operation[name]);
        }
      });
      result.push(tempArray);
    });
    return result;
  }

  updateSelectedItem(i: number, checked: boolean): void {
    this.selectedItens[i + this.pageAtual * this.rowsPerPage] = checked;
    this.hasSelectedItem = this.selectedItens.some((item) => item);
  }

  private formatNumericPrecision(json: any, precision: number) {
    Object.keys(json).forEach((k) => {
      const propIsObject =
        Object.keys(json[k]).length !== 0 && typeof json[k] === 'object';
      const propIsNotNull = json[k] !== null;

      if (propIsObject && propIsNotNull) {
        json[k] = this.formatNumericPrecision(json[k], precision);
      }

      const isNull = json[k] === null;
      const isNumber = !isNull && !isNaN(json[k]);
      const isNumericString = isNumber && typeof json[k] === 'string';
      const isFloat = isNumber && json[k].toString().indexOf('.') > -1;

      if (isFloat) {
        if (!isNumericString) {
          json[k] = json[k].toFixed(precision);
        }
      }
    });

    return json;
  }
}
