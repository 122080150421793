import { NgxEchartsModule } from 'ngx-echarts';
import { DeviceService } from 'app/core/services/device.service';
import { FleetMonitoringService } from 'app/core/services/fleet-monitoring.service';
import { LoginService } from 'app/core/services/login.service';
import {
  CircleProgressOptions,
  NgCircleProgressModule,
} from 'ng-circle-progress';
import { SidebarModule } from 'ng-sidebar';
import {
  CompleterService,
  LocalDataFactory,
  Ng2CompleterModule,
  RemoteDataFactory,
} from 'ng2-completer';
import { FileUploadModule } from 'ng2-file-upload';
import { Ng5SliderModule } from 'ng5-slider';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgxMaskModule } from 'ngx-mask';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';

import { OverlayModule } from '@angular/cdk/overlay';
import {
  CommonModule,
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeExtraPTBR from '@angular/common/locales/extra/pt';
import localeIt from '@angular/common/locales/it';
import localePtBR from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppSharedModule } from './app-shared.module';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { AuthGuard } from './core/guards/auth-guard.service';
import { RedirectAndShowToastRGuard } from './core/guards/redirect-and-show-toastr-guard.service';
import { ErrorCatchingInterceptor } from './core/interceptors/error-catching.interceptor';
import { AnalyticsService } from './core/services/analytics.service';
import { AuthorizationService } from './core/services/authorization.service';
import { CanalAtendimentoService } from './core/services/canal-atendimento.service';
import { ChangeLanguageService } from './core/services/changeLanguage.service';
import { ConfirmacaoModalService } from './core/services/confirmacaoModal.service';
import { EmailService } from './core/services/email.service';
import { ExcelService } from './core/services/excel.service';
import { FileManagerService } from './core/services/file-manager.service';
import { LoadingSpinnerService } from './core/services/loading-spinner.service';
import { AzureLoginService } from './core/services/login-azure.service';
import { NvgFilesService } from './core/services/nvg-files.service';
import { NvgHistService } from './core/services/nvg-hist.service';
import { NvgListFilesService } from './core/services/nvg-list-files.service';
import { NvgShapefileService } from './core/services/nvg-shapefile.service';
import { NvgStorageService } from './core/services/nvg-storage.service';
import { NvgTrabalhoService } from './core/services/nvg-trabalho.service';
import { ParametrizacaoService } from './core/services/parametrizacao.service';
import { PrevisaoTempoService } from './core/services/previsao-tempo.service';
import { ReportService } from './core/services/report.service';
import { UserService } from './core/services/user.service';
import { AdministrativoModule } from './modules/appviews/administrativo/administrativo.module';
import { AppviewsModule } from './modules/appviews/appviews.module';
import { LoginPageComponent } from './modules/appviews/auth/auth.component';
import { DashboardComponent } from './modules/appviews/dashboard/dashboard.component';
import { CriaTalhoesComponent } from './modules/appviews/my-fields/cria-talhoes/cria-talhoes.component';
import { EditarTalhoesComponent } from './modules/appviews/my-fields/editar-talhoes/editar-talhoes.component';
import { ListaTalhoesComponent } from './modules/appviews/my-fields/lista-talhoes/lista-talhoes.component';
import { MapaTalhaoComponent } from './modules/appviews/my-fields/mapa-talhao/mapa-talhao.component';
import { TutoriaisTalhoesComponent } from './modules/appviews/my-fields/tutoriais-talhoes/tutoriais-talhoes.component';
import { VisualizarTalhoesComponent } from './modules/appviews/my-fields/visualizar-talhoes/visualizar-talhoes.component';
import { ForgotPasswordComponent } from './modules/appviews/password/forgot.component';
import { ResetPasswordComponent } from './modules/appviews/password/reset.component';
import { PoliticaPrivacidadeComponent } from './modules/appviews/politica-privacidade/politica-privacidade.component';
import { RegisterComponent } from './modules/appviews/register/register.component';
import { TermosUsoComponent } from './modules/appviews/termos-uso/termos-uso.component';
import { AreaTrabalhoModule } from './modules/area-trabalho/area-trabalho.module';
import { ConfirmacaoModalModule } from './modules/confirmacao-modal/confirmacao-modal..module';
import { AcessoNegadoComponent } from './modules/error/acesso-negado/acesso-negado.component';
import { ErroServidorComponent } from './modules/error/erro-servidor/erro-servidor.component';
import { ManutencaoPageComponent } from './modules/error/manutencao-page/manutencao-page.component';
import { NaoEncontradaComponent } from './modules/error/nao-encontrada/nao-encontrada.component';
import { GestaoMapasModule } from './modules/gestao-mapas/gestao-mapas.module';
import { GestaoArquivoCardImageComponent } from './modules/gestao-operacao/gestao-arquivo-card-image/gestao-arquivo-card-image.component';
import { GestaoArquivoComponent } from './modules/gestao-operacao/gestao-arquivo/gestao-arquivo.component';
import { GestaoOperacaoModule } from './modules/gestao-operacao/gestao-operacao.module';
import { ManagementModule } from './modules/management/management.module';
import { SpinnerViewComponent } from './modules/spinner-view/spinner-view.component';
import { BadgeComponent } from './shared/components/badge/badge.component';
import { CardPrototypeComponent } from './shared/components/card-prototype/card-prototype.component';
import { CardComponent } from './shared/components/card/card.component';
import { CardDashboardComponent } from './shared/components/cards/card-dashboard/card-dashboard.component';
import { CardTalhoesComponent } from './shared/components/cards/card-talhoes/card-talhoes.component';
import { NvgCardListComponent } from './shared/components/cards/nvg-card-list/nvg-card-list.component';
import { DropdownComponent } from './shared/components/dropdown/dropdown.component';
import { AtualizacaoCadastroFormComponent } from './shared/components/forms/atualizacao-cadastro/atualizacao-cadastro.component';
import { IboxtoolsModule } from './shared/components/iboxtools/iboxtools.module';
import { InformativeBannerComponent } from './shared/components/informative-component/informative.component';
import { LayoutsModule } from './shared/components/layouts/layouts.module';
import { LinkComponent } from './shared/components/link/link.component';
import { LogoComponent } from './shared/components/logo/logo.component';
import { PaginationComponent } from './shared/components/pagination/pagination.component';
import { PrescriptionMapComponent } from './shared/components/prescription-map/prescription-map.component';
import { SelectMenuComponent } from './shared/components/select-menu/select-menu.component';
import { SelectSearchComponent } from './shared/components/select/select-search/select-search.component';
import { SelectWaterfallComponent } from './shared/components/select/select-waterfall/select-waterfall.component';
import { SelectComponent } from './shared/components/select/select.component';
import { ShadowComponent } from './shared/components/shadow/shadow.component';
import { SweetAlertComponent } from './shared/components/sweet-alert/sweet-alert.component';
import { TableComponent } from './shared/components/table/table.component';
import { TextAreaComponent } from './shared/components/text-area/text-area.component';
import { ToastrComponent } from './shared/components/Toastr/toastr.component';
import { TooltipComponent } from './shared/components/tooltip/tooltip.component';
import { DesignSystemComponent } from './shared/design-system/design-system.component';
import { TooltipDirective } from './shared/directives/tooltip.directive';
import { FilterTablePipeService } from './shared/pipes/filter-table.pipe';
import { BlobStorageFilesService } from './core/services/blob-storage-files.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  useBothWheelAxes: true,
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localePtBR, 'ptBR', localeExtraPTBR);
registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    AtualizacaoCadastroFormComponent,
    DashboardComponent,
    RegisterComponent,
    TermosUsoComponent,
    PoliticaPrivacidadeComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NaoEncontradaComponent,
    ErroServidorComponent,
    AcessoNegadoComponent,
    SpinnerViewComponent,
    CardDashboardComponent,
    LinkComponent,
    SweetAlertComponent,
    TooltipComponent,
    FilterTablePipeService,
    ManutencaoPageComponent,
    CardTalhoesComponent,
    MapaTalhaoComponent,
    ListaTalhoesComponent,
    TutoriaisTalhoesComponent,
    VisualizarTalhoesComponent,
    EditarTalhoesComponent,
    CriaTalhoesComponent,
    DesignSystemComponent,
    TooltipDirective,
    InformativeBannerComponent,
    ToastrComponent,
    LogoComponent,
    BadgeComponent,
    SelectMenuComponent,
    ShadowComponent,
    TextAreaComponent,
    CardComponent,
    CardPrototypeComponent,
    NvgCardListComponent,
    GestaoArquivoComponent,
    GestaoArquivoCardImageComponent,
    CardComponent,
    PaginationComponent,
    DropdownComponent,
    SelectComponent,
    SelectWaterfallComponent,
    SelectSearchComponent,
    TableComponent,
    PrescriptionMapComponent,
  ],
  imports: [
    Ng5SliderModule,
    IboxtoolsModule,
    Ng2CompleterModule,
    FileUploadModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    SidebarModule.forRoot(),
    NgCircleProgressModule.forRoot({}),
    NgxGaugeModule,
    NgSelectModule,
    BrowserModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    MatSliderModule,
    MatAutocompleteModule,
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    ManagementModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AreaTrabalhoModule,
    LayoutsModule,
    AppviewsModule,
    GestaoMapasModule,
    PerfectScrollbarModule,
    ColorPickerModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    RouterModule.forRoot(ROUTES),
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
    }),
    MatDialogModule,
    MatProgressSpinnerModule,
    OverlayModule,
    ConfirmacaoModalModule,
    AdministrativoModule,
    AppSharedModule,
    GestaoOperacaoModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'), // Lazy loading do ECharts
    }),
  ],
  providers: [
    CompleterService,
    LocalDataFactory,
    RemoteDataFactory,
    AuthGuard,
    { provide: LoginService, useClass: AzureLoginService },
    UserService,
    ExcelService,
    AnalyticsService,
    PrevisaoTempoService,
    DeviceService,
    FleetMonitoringService,
    FileManagerService,
    NvgFilesService,
    NvgStorageService,
    NvgListFilesService,
    NvgHistService,
    NvgTrabalhoService,
    DatePipe,
    EmailService,
    ReportService,
    { provide: LOCALE_ID, useValue: 'ptBR' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    LoadingSpinnerService,
    ConfirmacaoModalService,
    CanalAtendimentoService,
    ChangeLanguageService,
    FilterTablePipeService,
    ParametrizacaoService,
    NvgShapefileService,
    AuthorizationService,
    CircleProgressOptions,
    RedirectAndShowToastRGuard,
    BlobStorageFilesService,
  ],
  bootstrap: [AppComponent],
  exports: [MatSliderModule, BrowserModule, MatAutocompleteModule],
})
export class AppModule {}
