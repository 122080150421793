export class AgresSpinnerCanvas {
  id: string;
  type: AgresSpinnerCanvasType;
  message: string;
  autoClose: boolean;
  keepAfterRouteChange: boolean;
  fade: boolean;

  constructor(init?: Partial<AgresSpinnerCanvas>) {
    Object.assign(this, init);
  }
}

export enum AgresSpinnerCanvasType {
  Show,
  Hide,
}
