import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input() link: string;
  @Input() iconClass: string;
  @Input() title: string;
  @Input() styleClass: string;
  @Output() linkClick = new EventEmitter();
  @Input() isDisabled: boolean;

  public handleClick(event: any) {
    if (!this.link) event.preventDefault();
    return this.linkClick;
  }
}
