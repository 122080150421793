import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
    ViewChildren,
    ElementRef,
} from '@angular/core';
import { FormControlName } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmacaoModalService } from 'app/core/services/confirmacaoModal.service';
import { BehaviorSubject } from 'rxjs';
import swal from 'sweetalert2';

@Component({
    selector: 'app-confirmar-modal',
    templateUrl: './confirmacao-modal.component.html',
})
export class ConfirmacaoModalComponent implements OnInit {
    @Input() modal: boolean;

    @Input() conteudo: string;

    @Input() mensagem: string;
    @Input() tipo: string;
    @Input() titulo: string;

    @Input() confirmButtonText: string;
    @Input() cancelButtonText: string;

    @Output() modalChange: EventEmitter<boolean>;
    @ViewChildren(FormControlName, { read: ElementRef })
    formInputElements: ElementRef[];

    constructor(
        public confirmacaoModalService: ConfirmacaoModalService,
        public translateService: TranslateService
    ) {
        this.modalChange = new EventEmitter<boolean>();
    }

    ngOnInit() {
        if (!this.confirmButtonText)
            this.translateService
                .get('global.yes')
                .subscribe((res: string) => {
                    this.confirmButtonText = res;
                });
        else
            this.translateService
                .get(this.confirmButtonText)
                .subscribe((res: string) => {
                    this.confirmButtonText = res;
                });

        if (!this.cancelButtonText)
            this.translateService
                .get('global.cancel')
                .subscribe((res: string) => {
                    this.cancelButtonText = res;
                });
        else
            this.translateService
                .get(this.cancelButtonText)
                .subscribe((res: string) => {
                    this.cancelButtonText = res;
                });

        if (this.tipo != undefined) {
            switch (this.tipo) {
                case 'warning':
                    this.alertaWarning();
                    break;
                case 'success':
                    this.alertaSuccess();
                    break;
                case 'info':
                    this.alertaInfo();
                    break;
                case 'error':
                    this.alertaError();
                    break;
            }
        }
    }

    alertaWarning() {
        swal.fire({
            title: this.titulo,
            text: this.mensagem,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.confirmButtonText,
            cancelButtonText: this.cancelButtonText,
        }).then((willDelete) => {
            if (willDelete.value) {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'confirmado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            } else {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'cancelado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            }
        });
    }

    alertaSuccess() {
        swal.fire({
            title: this.titulo,
            text: this.mensagem,
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.confirmButtonText,
            cancelButtonText: this.cancelButtonText,
        }).then((willDelete) => {
            if (willDelete.value) {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'confirmado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            } else {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'cancelado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            }
        });
    }

    alertaInfo() {
        swal.fire({
            title: this.titulo,
            text: this.mensagem,
            icon: 'info',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.confirmButtonText,
            cancelButtonText: this.cancelButtonText,
        }).then((willDelete) => {
            if (willDelete.value) {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'confirmado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            } else {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'cancelado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            }
        });
    }

    alertaError() {
        swal.fire({
            title: this.titulo,
            text: this.mensagem,
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.confirmButtonText,
            cancelButtonText: this.cancelButtonText,
        }).then((willDelete) => {
            if (willDelete.value) {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'confirmado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            } else {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'cancelado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            }
        });
    }
}
