import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-waterfall',
  templateUrl: './select-waterfall.component.html',
  styleUrls: ['./select-waterfall.component.scss']
})
export class SelectWaterfallComponent implements OnInit {

  
  @Input() title: string;
  @Input() subtitle: string;
  @Input() iconClass: string;
  @Input() inputText: string;
  @Input() labelText: string;
  @Input() value: string;
  @Input() name: string;
  @Input() disabled: string;

  selectedCountry: any;
  cities = {};
  countries = [
    { id: 1, name: 'France', cities: ['Paris', 'Marseille', 'Nice'] },
    { id: 2, name: 'Germany', cities: ['Hamburg', 'Berlin', 'Munich'] },
    { id: 3, name: 'Italy', cities: ['Roma', 'Milan', 'Napoli'] },
  ];

  constructor() { }

  ngOnInit() {
    this.cities = this.countries.filter(x => x.id == 1)[0].cities;
  }

  onChange(deviceValue) {
    this.cities = this.countries.filter(x => x.id == deviceValue)[0].cities;
  }

}

