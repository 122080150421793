<div *ngIf="isAdministrador()">
  <app-button
    (btnClick)="openModalCategoryGuide()"
    color="primary"
    icon="agres-action"
    title="{{ 'tutorials.new-category-guides' | translate }}"></app-button>
  <app-button
    (btnClick)="openModalGuide()"
    color="primary"
    icon="agres-action"
    title="{{ 'tutorials.new-guide' | translate }}"></app-button>
  <app-button
    (btnClick)="openModalTutorial()"
    color="primary"
    icon="agres-action"
    title="{{ 'tutorials.new-tutorial' | translate }}"></app-button>
</div>
<h2 class="agr-title">{{ 'tutorials.title' | translate }}</h2>
<div class="agr-listTutorial">
  <div class="a-column--two">
    <div class="agr-listTutorial__card">
      <div class="agr-listTutorial__card--header">
        <h4 class="agr-listTutorial__card--header---title">{{ 'tutorials.new-guide' | translate }}</h4>
      </div>
      <div class="agr-listTutorial__card--body" *ngFor="let cgl of categoryGuideList">
        <p>{{ cgl.name }}</p>
        <p>{{ cgl.description }}</p>
        <div class="agr-listTutorial__card--link">
          <app-button
            color="primary__outline"
            (btnClick)="editCategoryGuide(cgl._id)"
            *ngIf="isAdministrador()"
            icon="agres-edit"
            title="{{ 'global.edit' | translate }}">
          </app-button>
          <app-button
            color="danger__outline"
            (btnClick)="deleteCategoryGuide(cgl._id)"
            *ngIf="isAdministrador()"
            icon="agres-trash"
            title="{{ 'global.delete' | translate }}">
          </app-button>
        </div>
        <div class="agr-listTutorial__card--body" *ngFor="let guideItem of cgl.guideList">
          <p>{{ 'tutorials.revision' | translate }}</p>
          <p>{{ guideItem.name }}</p>
          <p>{{ guideItem.revision }}</p>
          <div class="agr-listTutorial__card--link">
            <app-button
              color="success__outline"
              (btnClick)="(guideItem.linkFileDownload)"
              icon="agres-download"
              title="{{ 'global.download' | translate }}">
            </app-button>
            <app-button
              color="info__outline"
              (btnClick)="editGuide(guideItem._id)"
              *ngIf="isAdministrador()"
              icon="agres-edit"
              title="{{ 'global.edit' | translate }}">
            </app-button>
            <app-button
              color="delete__outline"
              (btnClick)="deleteGuide(guideItem._id)"
              *ngIf="isAdministrador()"
              icon="agres-delete"
              title="{{ 'global.delete' | translate }}">
            </app-button>
          </div>
        </div>
      </div>
      <div class="agr-listTutorial__card--body" *ngIf="categoryGuideList?.length === 0">
        <p>{{ 'tutorials.empty-guide-list-msg' | translate }}</p>
      </div>
    </div>
    <div class="agr-listTutorial__card">
      <div class="agr-listTutorial__card--header">
        <h4 class="agr-listTutorial__card--header---title">{{ 'tutorials.video' | translate }}</h4>
      </div>
      <div class="agr-listTutorial__card--body" *ngFor="let tutor of listTutorial">
        <p>{{ tutor.name }}</p>
        <p>{{ tutor.description }}</p>
        <p>
          <iframe
            width="100%"
            height="315"
            [src]="tutor.linkVideo"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </p>
        <div class="agr-listTutorial__card--link">
          <app-button
            type="submit"
            color="primary__outline"
            (click)="editTutorial(tutor._id)"
            *ngIf="isAdministrador()"
            icon="agres-edit">
          </app-button>
          <app-button
            type="submit"
            color="danger__outline"
            (click)="deleteTutorial(tutor._id)"
            *ngIf="isAdministrador()"
            icon="agres-delete">
          </app-button>
        </div>
      </div>
      <div class="agr-listTutorial__card--body" *ngIf="listTutorial?.length === 0">
        <p>{{ 'tutorials.empty-tutorial-list-msg' | translate }}</p>
      </div>
    </div>
    <div class="agr-listTutorial__card">
      <div class="agr-listTutorial__card--header">
        <h4 class="agr-listTutorial__card--header---title">{{ 'tutorials.service_channel' | translate }}</h4>
      </div>
      <div class="agr-listTutorial__card--body">
        <p>{{ 'tutorials.service_channel_text' | translate }}</p>
        <div class="agr-listTutorial__card--link">
          <app-button
            color="primary__outline"
            (btnClick)="serviceChanel()"
            icon="agres-action"
            title="{{ 'global.view' | translate }}"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- TODO: refatorar os modais -->
<app-modal
  title="tutorials.new-category-guides"
  [show]="modalCategoryGuideVisible"
  [showSubject]="modalCategoryGuideVisibleSubject">
  <form class="form-horizontal" role="form" [formGroup]="frmCategoryGuide" (ngSubmit)="onSubmitCategoryGuide()">
    <input type="hidden" formControlName="_id" />
    <input type="hidden" formControlName="_rev" />
    <div class="form-inputs">
      <div class="form-group">
        <label class="control-label required">{{ 'global.name' | translate }}</label>
        <input
          type="text"
          formControlName="name"
          class="form-control"
          [ngClass]="{
            'is-invalid': submittedForm && fControlCatGuide.name.errors
          }" />
        <div *ngIf="submittedForm && fControlCatGuide.name.errors" class="invalid-feedback">
          <div *ngIf="fControlCatGuide.name.errors.required">
            {{ 'global.validate.fields' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label">{{ 'global.description' | translate }}</label>
        <input
          type="text"
          formControlName="description"
          class="form-control"
          [ngClass]="{
            'is-invalid': submittedForm && fControlCatGuide.description.errors
          }" />
      </div>
    </div>
    <div class="form-controls">
      <button type="button" class="button__default" data-dismiss="modal" (btnClick)="hideModalCategoryGuide()">
        {{ 'global.cancel' | translate }}
      </button>
      <button type="submit" class="button__primary">
        {{ 'global.save' | translate }}
      </button>
    </div>
  </form>
</app-modal>
<app-modal title="tutorials.new-guide" [show]="modalGuideVisible" [showSubject]="modalGuideVisibleSubject">
  <form class="form-horizontal" role="form" [formGroup]="frmGuide" (ngSubmit)="onSubmitGuide()">
    <input type="hidden" formControlName="_id" />
    <input type="hidden" formControlName="_rev" />
    <input type="hidden" formControlName="linkFileDownload" />
    <div class="form-inputs">
      <div class="form-group">
        <label class="control-label required">{{ 'global.name' | translate }}</label>
        <input
          type="text"
          formControlName="name"
          class="form-control"
          [ngClass]="{
            'is-invalid': submittedForm && fControlGuide.name.errors
          }" />
        <div *ngIf="submittedForm && fControlGuide.name.errors" class="invalid-feedback">
          <div *ngIf="fControlGuide.name.errors.required">
            {{ 'global.validate.fields' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label required">{{ 'tutorials.revision-number' | translate }}</label>
        <input
          type="text"
          formControlName="revision"
          class="form-control"
          [ngClass]="{
            'is-invalid': submittedForm && fControlGuide.revision.errors
          }" />
        <div *ngIf="submittedForm && fControlGuide.revision.errors" class="invalid-feedback">
          <div *ngIf="fControlGuide.revision.errors.required">
            {{ 'global.validate.fields' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label required">{{ 'tutorials.category-guide' | translate }}</label>
        <select
          formControlName="idCategoryGuideRelated"
          id="idCategoryGuideRelated"
          name="idCategoryGuideRelated"
          class="form-control"
          [ngClass]="{
            'is-invalid': submittedForm && fControlGuide.idCategoryGuideRelated.errors
          }">
          <option value=""></option>
          <option *ngFor="let cgl of categoryGuideList" [ngValue]="cgl._id">
            {{ cgl.name }}
          </option>
        </select>
        <div *ngIf="submittedForm && fControlGuide.idCategoryGuideRelated.errors" class="invalid-feedback">
          <div *ngIf="fControlGuide.idCategoryGuideRelated.errors.required">
            {{ 'global.validate.fields' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label required">{{ 'tutorials.file' | translate }}</label>
        <input
          type="file"
          formControlName="fileName"
          id="fileName"
          (change)="handleUpload($event)"
          [ngClass]="{
            'is-invalid': submittedForm && fControlGuide.fileName.errors
          }"
          class="form-control" />
        <div *ngIf="submittedForm && fControlGuide.fileName.errors" class="invalid-feedback">
          <div *ngIf="fControlGuide.fileName.errors.required">
            {{ 'global.validate.fields' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-controls">
      <button type="button" class="button__default" data-dismiss="modal" (btnClick)="hideModalGuide()">
        {{ 'global.cancel' | translate }}
      </button>
      <button type="submit" class="button__primary">
        {{ 'global.save' | translate }}
      </button>
    </div>
  </form>
</app-modal>
<app-modal title="tutorials.new-tutorial" [show]="modalTutorialVisible" [showSubject]="modalTutorialVisibleSubject">
  <form class="form-horizontal" role="form" [formGroup]="frmTutorial" (ngSubmit)="onSubmitTutorial()">
    <input type="hidden" formControlName="_id" />
    <input type="hidden" formControlName="_rev" />
    <div class="form-inputs">
      <div class="form-group">
        <label class="control-label required">{{ 'global.name' | translate }}</label>
        <input
          type="text"
          formControlName="name"
          class="form-control"
          [ngClass]="{
            'is-invalid': submittedForm && fControlTutorial.name.errors
          }" />
        <div *ngIf="submittedForm && fControlTutorial.name.errors" class="invalid-feedback">
          <div *ngIf="fControlTutorial.name.errors.required">
            {{ 'global.validate.fields' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label">{{ 'global.description' | translate }}</label>
        <input
          type="text"
          formControlName="description"
          class="form-control"
          [ngClass]="{
            'is-invalid': submittedForm && fControlTutorial.description.errors
          }" />
      </div>
      <div class="form-group">
        <label class="control-label required">{{ 'tutorials.linkVideo' | translate }}</label>
        <input
          type="text"
          formControlName="linkVideo"
          class="form-control"
          [ngClass]="{
            'is-invalid': submittedForm && fControlTutorial.linkVideo.errors
          }" />
        <div *ngIf="submittedForm && fControlTutorial.linkVideo.errors" class="invalid-feedback">
          <div *ngIf="fControlTutorial.linkVideo.errors.required">
            {{ 'global.validate.fields' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-controls">
      <button type="button" class="button__default" data-dismiss="modal" (btnClick)="hideModalTutorial()">
        {{ 'global.cancel' | translate }}
      </button>
      <button type="submit" class="button__primary">
        {{ 'global.save' | translate }}
      </button>
    </div>
  </form>
</app-modal>
<!-- <div *ngIf="safeURL">
      <iframe style="width: 100% !important" height="315" [src]="safeURL" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div> -->
<!-- <div class="row">
<div class="col-lg-12">
<div class="ibox ">
  <div class="ibox-title">
    <h2><strong>{{ 'tutorials.title-page' | translate }}</strong></h2>
  </div>
  <div class="ibox-content">
    <div class="row container">
      <form class="form-horizontal col-lg-6" role="form">
        <div class="form-group" id="fileManager">
          <input type="file" id="fileUpload" (change)="handleUpload($event)"><br>
          <button type="submit" (btnClick)="uploadFile()" class="button__primary pull-left">
            <span class="glyphicon glyphicon-upload" aria-hidden="true"></span> Upload de arquivos
          </button><br><br>
        </div>
        <div class="form-group">
          <label for="filter">{{ 'tutorials.product' | translate }}</label>
          <select class="form-control">
            <option selected>.PDF, .doc, .txt</option>
          </select>
        </div>
        <div class="form-group">
          <label for="contain">{{ 'tutorials.name-archive' | translate }}</label>
          <select class="form-control">
            <option selected>Manual</option>
          </select>
        </div>
        <div class="form-group">
          <label for="contain">{{ 'tutorials.version' | translate }}</label>
          <select class="form-control">
            <option *ngFor="let file of fileArr" [value]="file">{{file}}</option>
          </select>
        </div>
        <div class="form-group">
          <button type="submit" (btnClick)="searchFiles()" class="button__primary pull-left"><span
              class="glyphicon glyphicon-search" aria-hidden="true"></span></button>
        </div>
      </form>
    </div>
    <perfect-scrollbar style="max-height: 300px;" >
      <table *ngIf="isSearch" datatable="ng" dt-options="dtOptions"
        class="table table-striped table-bordered table-hover dataTables-example">
        <thead>
          <tr>
            <th>{{ 'global.title' | translate }}</th>
            <th>{{ 'global.type' | translate }}</th>
            <th>{{ 'global.data' | translate }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of fileArr">
            <td>Manuais</td>
            <td>{{ file }}</td>
            <td>11/06/2019</td>
            <td>
              <a href="http://s3.us-south.cloud-object-storage.appdomain.cloud/firmware-files/{{file}}"
                download="{{file}}" class="button__primary btn-sm dim" (btnClick)="downloadFile(file)">
                <i class="agres-arrow-download"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </perfect-scrollbar>
  </div>
</div>
</div>
</div>
</div> -->
