import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-agres-icons',
  templateUrl: './agres-icons.component.html',
  styleUrls: ['./agres-icons.component.scss']
})
export class AgresIconsComponent {

  @Input() link: string;
  @Input() iconsClass: string;
  @Input() title: string;
  @Input() styleClass: string;
  @Output() iconClick = new EventEmitter();

  public handleClick(event:any) {
    if(!this.link) event.preventDefault()
    return this.iconClick
  }

  constructor() { }
}
