<app-alert></app-alert>
<form [formGroup]="uploadFormFile" (ngSubmit)="onSubmit()" class="container mt-5">
  <div class="form-header">
    <h1>{{ 'global.support-modal-send-file-title' | translate }}</h1>

    <p>{{ 'global.support-modal-send-file-text-select-language' | translate }}</p>
  </div>
  <div class="tabs">
    <div
      *ngFor="let lang of languages"
      [class.active]="selectedLanguage === lang.code"
      (click)="setLanguage(lang.code)">
      <img [src]="'./../../../../../assets/images/support-page/flags/' + lang.flag" [alt]="lang" />
    </div>
  </div>
  <div *ngIf="!allLanguagesFilled" class="text-information">
    *{{ 'global.support-fill-name-description' | translate }}
  </div>
  <div [formGroup]="uploadFormFile.get('fileMetadataByLanguage')?.get(selectedLanguage)">
    <div class="mb-5">
      <input
        type="text"
        id="fileName"
        class="form-control"
        formControlName="fileName"
        (input)="checkLanguages()"
        placeholder="{{ 'global.support-file-name' | translate }}" />
    </div>

    <div class="mb-5">
      <textarea
        id="description"
        class="form-control"
        formControlName="description"
        (input)="checkLanguages()"
        placeholder="{{ 'global.support-description' | translate }}"
        rows="4">
      </textarea>
    </div>
  </div>

  <div class="mb-5">
    <input
      type="number"
      id="version"
      class="form-control"
      formControlName="version"
      [ngClass]="{
        'is-invalid': uploadFormFile.get('version')?.invalid && uploadFormFile.get('version')?.touched,
      }"
      placeholder="{{ 'global.support-version' | translate }}" />
    <div
      *ngIf="uploadFormFile.get('version')?.invalid && uploadFormFile.get('version')?.touched"
      class="invalid-feedback">
      {{ 'global.support-version-msg-mandatory' | translate }}
    </div>
  </div>

  <div class="input-group mb-5">
    <input
      formControlName="file"
      type="file"
      id="file"
      (change)="onFileSelected($event)"
      class="custom-file-input"
      [ngClass]="{
          'is-invalid': uploadFormFile.get('file')?.invalid && uploadFormFile.get('file')?.touched,
      }" />

    <div *ngIf="uploadFormFile.get('file')?.invalid && uploadFormFile.get('file')?.touched" class="invalid-feedback">
      {{ 'global.support-add-file-msg-mandatory' | translate }}
    </div>
  </div>

  <div class="form-group mb-5">
    <select class="form-control" id="category" formControlName="category">
      <option selected>{{ 'global.support-select-category' | translate }}</option>
      <option value="guides">{{ 'global.support-category-guide' | translate }}</option>
      <option value="manuals">{{ 'global.support-category-manual' | translate }}</option>
      <option value="others">{{ 'global.support-category-others' | translate }}</option>
      <option value="softwares">Softwares</option>
    </select>
    <div
      *ngIf="uploadFormFile.get('category')?.invalid && uploadFormFile.get('category')?.touched"
      class="invalid-feedback">
      {{ 'global.support-select-category-msg-mandatory' | translate }}
    </div>
  </div>

  <div class="form-group mb-5">
    <select class="form-control" id="product" formControlName="product">
      <option value="" selected>{{ 'global.support-select-product' | translate }}</option>
      <option *ngFor="let product of products" [value]="product.value">
        {{ product.label }}
      </option>
    </select>
    <div
      *ngIf="uploadFormFile.get('product')?.invalid && uploadFormFile.get('product')?.touched"
      class="invalid-feedback">
      {{ 'global.support-select-product-msg-mandatory' | translate }}
    </div>
  </div>

  <div class="form-group mb-5" id="inputProductCategory">
    <input id="productCategory" formControlName="productCategory" class="form-control" [disabled]="true" />
  </div>

  <div class="form-group mb-5">
    <label class="mb-4" for="language">{{ 'global.support-select-language' | translate }}</label>
    <div class="checkbox-group">
      <div *ngFor="let productLang of productLanguages" class="checkbox-item">
        <input
          type="checkbox"
          [id]="productLang.code"
          [value]="productLang.code"
          (change)="onLanguageChange($event)"
          [checked]="selectedProductLanguages.includes(productLang.code)" />
        <div class="checkbox-flag">
          <label [for]="productLang.code" class="flag-icon">
            <img
              [src]="'./../../../../../assets/images/support-page/flags/' + productLang.flag"
              [alt]="productLang.code" />
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group mb-5">
    <label class="mb-4" for="date">{{ 'global.support-date' | translate }}</label>
    <input
      type="date"
      id="date"
      class="form-control"
      formControlName="date"
      [ngClass]="{
        'is-invalid': uploadFormFile.get('date')?.invalid && uploadFormFile.get('date')?.touched,
      }" />
    <div *ngIf="uploadFormFile.get('date')?.invalid && uploadFormFile.get('date')?.touched" class="invalid-feedback">
      {{ 'global.support-date-msg-mandatory' | translate }}
    </div>
  </div>

  <div class="buttons mt-5">
    <button class="buttons--btn-cancel" type="button" (click)="closeDialog()">
      <i class="agres-close"></i>
      Cancelar
    </button>
    <button
      class="buttons--btn-save"
      type="submit"
      [disabled]="uploadFormFile.invalid"
      [ngClass]="{ 'btn-disabled': uploadFormFile.invalid }">
      <i class="agres-upload"></i>
      Enviar
    </button>
  </div>
</form>
