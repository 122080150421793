import { ToastrService } from 'ngx-toastr';
import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { UserService } from 'app/core/services/user.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FileManagerService } from 'app/core/services/file-manager.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import * as $ from 'jquery';
import 'jquery';
import { Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { _roleEnumUserClass } from 'app/utils/role.utils';
import { AppConstants } from 'app/app.constants';
import { Subject } from 'rxjs';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { DomSanitizer } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';
import { AnalyticsService } from 'app/core/services/analytics.service';
@Component({
  selector: 'app-tutoriais',
  templateUrl: 'tutoriais.component.html',
  styleUrls: ['tutoriais.component.scss'],
})
export class TutoriaisComponent implements OnInit, AfterViewInit {
  localUrl: any;

  ROLE_ENUM = _roleEnumUserClass.ROLE_ENUM;

  constructor(
    public http: HttpClient,
    public toastr: ToastrService,
    public sanitizer: DomSanitizer,
    public usuarioService: UserService,
    public translateService: TranslateService,
    public fileManagerService: FileManagerService,
    public formBuilder: UntypedFormBuilder,
    public readonly loadingAgresSpinner: AgresSpinnerService,
    private readonly cdRef: ChangeDetectorRef,
    public router: Router,
    private readonly analyticsService: AnalyticsService,
  ) {
    this.translateService.get('global.home').subscribe((home: string) => {
      this.translateService
        .get('tutoriais.title-page')
        .subscribe((res: string) => {
          const title = res;
          this.bindHTMLTitle(home, title);
        });
    });
  }

  public global;
  public safeURL;
  public isSearch: boolean;
  public presign;
  public fileName;
  public fileDataNew;

  userLogged;

  public modalCategoryGuideVisible;
  public modalGuideVisible;
  public modalTutorialVisible;

  submittedForm = false;
  frmCategoryGuide: UntypedFormGroup;
  frmGuide: UntypedFormGroup;
  frmTutorial: UntypedFormGroup;
  categoryGuideList;

  CONSTANT_CATEGORY_GUIDE = 'category_guide';
  CONSTANT_GUIDE = 'guide';
  CONSTANT_TUTORIAL = 'tutorial';

  listFiles = [];
  listTutorial = [];
  public modalCategoryGuideVisibleSubject: Subject<boolean> = new Subject();
  public modalGuideVisibleSubject: Subject<boolean> = new Subject();
  public modalTutorialVisibleSubject: Subject<boolean> = new Subject();

  ngOnInit() {
    this.modalCategoryGuideVisible = false;
    this.modalGuideVisible = false;
    this.modalTutorialVisible = false;

    this.changeLanguage();

    this.frmCategoryGuide = this.formBuilder.group({
      _id: [null],
      _rev: [null],
      name: [null, Validators.required],
      description: [''],
      created: [''],
      createdUsuarioId: [''],
      active: [true],
      type: [this.CONSTANT_CATEGORY_GUIDE],
    });

    this.frmGuide = this.formBuilder.group({
      _id: [null],
      _rev: [null],
      name: [null, Validators.required],
      fileName: ['', Validators.required],
      linkFileDownload: [''],
      revision: ['', Validators.required],
      created: [''],
      createdUsuarioId: [''],
      type: [this.CONSTANT_GUIDE],
      active: [true],
      idCategoryGuideRelated: ['', Validators.required],
    });

    this.frmTutorial = this.formBuilder.group({
      _id: [null],
      _rev: [null],
      name: ['', Validators.required],
      description: [''],
      linkVideo: ['', Validators.required],
      created: [''],
      createdUsuarioId: [''],
      active: [true],
      type: [this.CONSTANT_TUTORIAL],
    });

    this.cdRef.detectChanges();
    this.analyticsService.sendPageView('/suporte-tecnico/tutoriais');
  }

  // convenience getter for easy access to form fields
  get fControlCatGuide() {
    return this.frmCategoryGuide.controls;
  }

  // convenience getter for easy access to form fields
  get fControlGuide() {
    return this.frmGuide.controls;
  }

  // convenience getter for easy access to form fields
  get fControlTutorial() {
    return this.frmTutorial.controls;
  }

  ngAfterViewInit() {
    this.translateService
      .get('global.loading.technical_support')
      .subscribe((res: string) => {
        this.loadingAgresSpinner.toShow(res);
      });
    this.userLogged = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );

    let role = this.userLogged.role;

    if (role === this.ROLE_ENUM.Admin) {
      $('#fileManager').css('display', 'block');
    }

    this.applyEventsCollapseLink();

    setTimeout(() => {
      this.loadingAgresSpinner.toHide();
    }, TimeoutEnum.Long);
  }

  bindHTMLTitle(home, title) {
    jQuery('.breadcrumb').html(
      `<li><a href="#">${home}</a></li><li class="active"><strong>${title}</strong></li>`,
    );
  }

  populateCategoryGuidList(resultAll) {
    const self = this;
    this.categoryGuideList = resultAll.filter(
      (a) => a.type == this.CONSTANT_CATEGORY_GUIDE,
    );
    for (let index = 0; index < self.categoryGuideList.length; index++) {
      self.categoryGuideList[index].guideList = resultAll.filter(
        (a) =>
          a.type == self.CONSTANT_GUIDE &&
          a.idCategoryGuideRelated == self.categoryGuideList[index]._id,
      );
    }
  }

  clearLists() {
    this.listFiles = [];
    this.listTutorial = [];
  }

  applyEventsCollapseLink() {
    $(document).off('click', '.collapse-link');
    $(document).on('click', '.collapse-link', function () {
      const ibox = $(this).closest('div.ibox');
      const chevronArrow = $(this).find('[id=chevron-arrow]');
      const content = ibox.children('.ibox-content');
      content.slideToggle(200);
      chevronArrow.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      ibox.toggleClass('').toggleClass('border-bottom');

      setTimeout(function () {
        ibox.resize();
        ibox.find('[id^=map-]').resize();
      }, 50);
    });
  }

  handleUpload(e) {
    const self = this;
    self.fileDataNew = (<HTMLInputElement>e.target).files[0];
    self.fileName = (<HTMLInputElement>e.target).files[0].name;
  }

  public extractData(response: any) {
    return response || {};
  }

  public obterAuthHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `AWS4-HMAC-SHA256 Credential=713dd5e3b834417da7979e30e46e2088/20190604/us-east-1/execute-api/aws4_request, SignedHeaders=host;x-amz-date, Signature=48b64c5b2329da6618397eea01071746acf78282844694a83bc61978371e8738`,
      }),
    };
  }

  changeLanguage() {
    this.translateService.onLangChange.subscribe(
      (_event: TranslationChangeEvent) => {
        this.translateService.get('global.home').subscribe((home: string) => {
          this.translateService
            .get('tutoriais.title-page')
            .subscribe((res: string) => {
              const title = res;
              this.bindHTMLTitle(home, title);
            });
        });
      },
    );
  }

  isAdministrador(): boolean {
    const usuario = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    return usuario.role === this.ROLE_ENUM.Admin;
  }

  safeUrl(videoURL) {
    return;
  }

  openModalCategoryGuide() {
    this.modalCategoryGuideVisible = true;
  }

  hideModalCategoryGuide() {
    this.modalCategoryGuideVisibleSubject.next(false);
    this.modalCategoryGuideVisible = false;
    this.resetFormCategoryGuide();
  }

  openModalGuide() {
    this.modalGuideVisible = true;
  }

  hideModalGuide() {
    this.modalGuideVisibleSubject.next(false);
    this.modalGuideVisible = false;
    this.resetFormGuide();
  }

  openModalTutorial() {
    this.modalTutorialVisible = true;
  }

  hideModalTutorial() {
    this.modalTutorialVisibleSubject.next(false);
    this.modalTutorialVisible = false;
    this.resetFormTutorial();
  }

  editCategoryGuide(idCategoryGuide) {
    this.fileManagerService.getById(idCategoryGuide).subscribe((resultDb) => {
      this.frmCategoryGuide = this.formBuilder.group({});

      this.modalCategoryGuideVisible = true;
    });
  }

  deleteCategoryGuide(idCategoryGuide) {
    const confirmDeleteKey = 'tutoriais.confirm-delete-category-guide';
    const successDeleteKey = 'tutoriais.msg-category-success-deleted';

    this.translateService
      .get(confirmDeleteKey)
      .subscribe((confirmMessage: string) => {
        if (confirm(confirmMessage)) {
          this.fileManagerService
            .getById(idCategoryGuide)
            .subscribe((resultDb) => {
              //resultDb.active = false;

              this.fileManagerService.save(resultDb).subscribe(
                () => {
                  this.translateService
                    .get(successDeleteKey)
                    .subscribe((successMessage: string) => {
                      this.toastr.success(successMessage);
                    });
                },
                (err) => {
                  if (err.error != null && err.error != '') {
                    this.toastr.error(err.error);
                  } else {
                    this.translateService
                      .get('global.alert.msg-unexpected-error')
                      .subscribe((unexpectedErrorMessage: string) => {
                        this.toastr.error(unexpectedErrorMessage);
                      });
                  }
                },
              );
            });
        }
      });
  }

  editGuide(idGuide) {
    this.fileManagerService.getById(idGuide).subscribe((resultDb) => {
      this.frmGuide = this.formBuilder.group({});

      this.modalGuideVisible = true;
    });
  }

  deleteGuide(idGuide) {
    this.translateService
      .get('tutoriais.confirm-delete-guide')
      .subscribe((res: string) => {
        if (confirm(res)) {
          this.fileManagerService.getById(idGuide).subscribe((resultDb) => {
            //resultDb.active = false;

            this.fileManagerService.save(resultDb).subscribe(
              () => {
                this.translateService
                  .get('tutoriais.msg-guide-success-deleted')
                  .subscribe((result: string) => {
                    this.toastr.success(result);
                  });
              },
              (err) => {
                if (err.error != null && err.error != '') {
                  this.toastr.error(err.error);
                } else {
                  this.translateService
                    .get('global.alert.msg-unexpected-error')
                    .subscribe((result: string) => {
                      this.toastr.error(result);
                    });
                }
              },
            );
          });
        }
      });
  }

  editTutorial(idTutorial) {
    this.fileManagerService.getById(idTutorial).subscribe((resultDb) => {
      this.frmTutorial = this.formBuilder.group({});

      this.modalTutorialVisible = true;
    });
  }

  deleteTutorial(idTutorial) {
    this.translateService
      .get('tutoriais.confirm-delete-tutorial')
      .subscribe((res: string) => {
        if (confirm(res)) {
          this.fileManagerService.getById(idTutorial).subscribe((resultDb) => {
            // resultDb.active = false;

            this.fileManagerService.save(resultDb).subscribe(
              () => {
                this.translateService
                  .get('tutoriais.msg-tutorial-success-deleted')
                  .subscribe((result: string) => {
                    this.toastr.success(result);
                  });
              },
              (err) => {
                if (err.error != null && err.error != '') {
                  this.toastr.error(err.error);
                } else {
                  this.translateService
                    .get('global.alert.msg-unexpected-error')
                    .subscribe((result: string) => {
                      this.toastr.error(result);
                    });
                }
              },
            );
          });
        }
      });
  }

  onSubmitCategoryGuide() {
    this.submittedForm = true;

    if (this.frmCategoryGuide.invalid) {
      return;
    }

    if (this.frmCategoryGuide.value._id === null) {
      this.frmCategoryGuide.value._id = this.getIdDocumentCouchDb(
        this.frmCategoryGuide.value,
      );
      this.frmCategoryGuide.value.created = new Date().getTime();
      this.frmCategoryGuide.value.createdUsuarioId = this.userLogged.id;
    }

    this.fileManagerService.save(this.frmCategoryGuide.value).subscribe(
      () => {
        this.translateService
          .get('tutoriais.msg-category-success-save')
          .subscribe((res: string) => {
            this.hideModalCategoryGuide();
            this.toastr.success(res);
          });
      },
      (err) => {
        if (err.error != null && err.error != '') {
          this.toastr.error(err.error);
        } else {
          this.translateService
            .get('global.alert.msg-unexpected-error')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        }
      },
    );
  }

  resetFormCategoryGuide() {
    this.submittedForm = false;
    this.frmCategoryGuide.reset({
      _id: null,
      _rev: null,
      name: null,
      description: '',
      created: '',
      createdUsuarioId: '',
      active: true,
      type: this.CONSTANT_CATEGORY_GUIDE,
    });
  }

  onSubmitGuide() {
    const self = this;
    this.submittedForm = true;

    if (this.frmGuide.invalid) {
      return;
    }

    if (this.frmGuide.value._id === null) {
      this.frmGuide.value._id = this.getIdDocumentCouchDb(this.frmGuide.value);
      this.frmGuide.value.created = new Date().getTime();
      this.frmGuide.value.createdUsuarioId = this.userLogged.id;
    }

    if (this.frmGuide.value._id != null && self.fileName != undefined) {
      setTimeout(() => {
        $.ajax({
          type: 'PUT',
          cache: false,
          processData: false,
          url: self.presign,
          data: self.fileDataNew,
          success: function () {
            self.frmGuide.value.filename = self.fileName;

            self.fileManagerService.save(self.frmGuide.value).subscribe(
              () => {
                self.translateService
                  .get('tutoriais.msg-guide-success-save')
                  .subscribe((res: string) => {
                    self.hideModalGuide();
                    self.toastr.success(res);
                  });
              },
              (err) => {
                if (err.error != null && err.error != '') {
                  self.toastr.error(err.error);
                } else {
                  self.translateService
                    .get('global.alert.msg-unexpected-error')
                    .subscribe((res: string) => {
                      self.toastr.error(res);
                    });
                }
              },
            );
          },
          error: function () {
            self.translateService
              .get('global.alert.msg-unexpected-error')
              .subscribe((res: string) => {
                self.toastr.error(res);
              });
          },
        });
      }, 2000);
    } else {
      self.fileManagerService.save(self.frmGuide.value).subscribe(
        () => {
          self.translateService
            .get('tutoriais.msg-guide-success-save')
            .subscribe((res: string) => {
              self.hideModalGuide();
              self.toastr.success(res);
            });
        },
        (err) => {
          if (err.error != null && err.error != '') {
            self.toastr.error(err.error);
          } else {
            self.translateService
              .get('global.alert.msg-unexpected-error')
              .subscribe((res: string) => {
                self.toastr.error(res);
              });
          }
        },
      );
    }
  }

  resetFormGuide() {
    this.submittedForm = false;
    this.frmGuide.reset({
      _id: null,
      _rev: null,
      name: null,
      fileName: '',
      linkFileDownload: '',
      revision: '',
      created: '',
      createdUsuarioId: '',
      type: this.CONSTANT_GUIDE,
      active: true,
      idCategoryGuideRelated: '',
    });
  }

  onSubmitTutorial() {
    this.submittedForm = true;

    if (this.frmTutorial.invalid) {
      return;
    }

    if (this.frmTutorial.value._id === null) {
      this.frmTutorial.value._id = this.getIdDocumentCouchDb(
        this.frmTutorial.value,
      );
      this.frmTutorial.value.created = new Date().getTime();
      this.frmTutorial.value.createdUsuarioId = this.userLogged.id;
    }

    this.fileManagerService.save(this.frmTutorial.value).subscribe(
      () => {
        this.translateService
          .get('tutoriais.msg-tutorial-success-save')
          .subscribe((res: string) => {
            this.hideModalTutorial();
            this.toastr.success(res);
          });
      },
      (err) => {
        if (err.error != null && err.error != '') {
          this.toastr.error(err.error);
        } else {
          this.translateService
            .get('global.alert.msg-unexpected-error')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        }
      },
    );
  }

  resetFormTutorial() {
    this.submittedForm = false;
    this.frmTutorial.reset({
      _id: null,
      _rev: null,
      name: '',
      description: '',
      linkVideo: '',
      created: '',
      createdUsuarioId: '',
      active: true,
      type: this.CONSTANT_TUTORIAL,
    });
  }

  serviceChanel() {
    this.router.navigate(['/suporte-tecnico/canal-atendimento']);
  }

  getIdDocumentCouchDb(objectToSave) {
    return objectToSave.type + '_' + objectToSave.name;
  }
}
