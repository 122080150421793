<div class="row">
    <div class="col-md-2">
        <div class="dropVer">
            <select id="grade1">
                <option value="display"> Display Columms</option>
                <option value="time">Time</option>
                <option value="operator">Operador</option>
                <option value="culture">Culture</option>
                <option value="test">Test</option>
                <option value="technician">Technician</option>
                <option value="location">Location</option>
                <option value="creator">Creator</option>
                <option value="status">Status</option>
            </select>
            <select id="grade2">
                <option value="display"> Display Columms</option>
                <option value="time">Time</option>
                <option value="operator">Operador</option>
                <option value="culture">Culture</option>
                <option value="test">Test</option>
                <option value="technician">Technician</option>
                <option value="location">Location</option>
                <option value="creator">Creator</option>
                <option value="status">Status</option>
            </select> 
        </div>
    </div>
    <div class="col-md-2">
        <div class="checkItem">
              <div class="checkItem__checkOne">
                <p>Time</p>
                <label>
                  <input type="checkbox" value="test2" name="test"/>
                  <span class="checkItem__checkOne--checkbox"></span>
               </label>
              </div>
              <div class="checkItem__checkOne">
                <p>Time</p>
                <label>
                  <input type="checkbox" value="test2" name="test"/>
                  <span class="checkItem__checkOne--checkbox"></span>
                </label>
              </div>
              <div class="checkItem__checkOne">
                <p>Time</p>
                <label>
                  <input type="checkbox" value="test2" name="test"/>
                  <span class="checkItem__checkOne--checkbox"></span>
                </label>
              </div>
              <div class="checkItem__checkOne">
                <p>Time</p>
                <label>
                  <input type="checkbox" value="test2" name="test"/>
                  <span class="checkItem__checkOne--checkbox"></span>
                </label>
              </div>
        </div>
    </div>
    <div class="col-md-2">
      <div class="checkItem2">
          <div class="checkItem2__checkTwo">
            <p>Time</p>
            <label>
              <input type="checkbox" value="test3" name="test"/>
              <span class="checkItem2__checkTwo--checkbox3"></span>
            </label>
          </div>
          <div class="checkItem2__checkTwo">
            <p>Operator</p>
            <label>
              <input type="checkbox" value="test3" name="test"/>
              <span class="checkItem2__checkTwo--checkbox3"></span>
            </label>
          </div>
          <div class="checkItem2__checkTwo">
            <p>Culture</p>
            <label>
              <input type="checkbox" value="test3" name="test"/>
              <span class="checkItem2__checkTwo--checkbox3"></span>
            </label>
          </div>
          <div class="checkItem2__checkTwo">
            <p>Test</p>
            <label>
              <input type="checkbox" value="test3" name="test"/>
              <span class="checkItem2__checkTwo--checkbox3"></span>
            </label>
          </div>
          <div class="checkItem2__checkTwo">
            <p>Technician</p>
            <label>
              <input type="checkbox" value="test3" name="test"/>
              <span class="checkItem2__checkTwo--checkbox3"></span>
            </label>
          </div>
          <div class="checkItem2__checkTwo">
            <p>Location</p>
            <label>
              <input type="checkbox" value="test3" name="test"/>
              <span class="checkItem2__checkTwo--checkbox3"></span>
            </label>
          </div>
          <div class="checkItem2__checkTwo">
            <p>Creator</p>
            <label>
              <input type="checkbox" value="test3" name="test"/>
              <span class="checkItem2__checkTwo--checkbox3"></span>
            </label>
          </div>
          <div class="checkItem2__checkTwo">
            <p>Status</p>
            <label>
              <input type="checkbox" value="test3" name="test"/>
              <span class="checkItem2__checkTwo--checkbox3"></span>
            </label>
          </div>
    </div>
    </div>
    <div class="col-md-4">
        <div class="dropHor">
            <select id="grade3" class="col-6">
                <option value="display"> Display Columms</option>
                <option value="time">Time</option>
                <option value="operator">Operador</option>
                <option value="culture">Culture</option>
                <option value="test">Test</option>
                <option value="technician">Technician</option>
                <option value="location">Location</option>
                <option value="creator">Creator</option>
                <option value="status">Status</option>
            </select>
            <select id="grade4" class="col-6">
                <option value="display"> Display Columms</option>
                <option value="time">Time</option>
                <option value="operator">Operador</option>
                <option value="culture">Culture</option>
                <option value="test">Test</option>
                <option value="technician">Technician</option>
                <option value="location">Location</option>
                <option value="creator">Creator</option>
                <option value="status">Status</option>
            </select> 
        </div>
    </div>
</div>