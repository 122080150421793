export class NavigationTabModel {
    constructor(
        public latitude: number = 0,
        public longitude: number = 0,
        public elevation: number = 0,
        public usedSatellites: number = 0,
        public frequency: number = 0,
        public baseLocationLatitude: number = 0,
        public baseLocationLongitude: number = 0,
        public positionType: number = 0
    ) {}
}
