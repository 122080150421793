import { Component, Input, OnInit } from '@angular/core';
import { fadeOut } from 'ng-animate';

@Component({
  selector: 'app-informative',
  templateUrl: './informative.component.html',
  styleUrls: ['./informative.component.scss']
})
export class InformativeBannerComponent {

  @Input() styleClass: string;
  @Input() iconClass: string;
  @Input() title: string;
   
  constructor() { }

  closeInformative() {
    $(".close").click(function() {
      $(this)
        .parent(".informative")
        .fadeOut();
    });
  }
}
