<div class="tabNavigation">
  <div class="slider">
    <div class="slider__item">
      <h5 class="slider__item--title">{{ 'monitoring.tabs.navigation.label-current-position' | translate }}</h5>
      <hr />
      <p class="slider__item--text">
        {{ 'monitoring.tabs.navigation.label-elevation' | translate }} <span>{{ navigation?.elevation }} m</span>
      </p>
      <p class="slider__item--text">
        {{ 'monitoring.tabs.navigation.label-latitude' | translate }} <span>{{ navigation?.latitude }}</span>
      </p>
      <p class="slider__item--text">
        {{ 'monitoring.tabs.navigation.label-longitude' | translate }} <span>{{ navigation?.longitude }}</span>
      </p>
      <p class="slider__item--text">
        {{ 'monitoring.tabs.navigation.label-distance-base' | translate }} <span>{{ distanceBaseRtk }}</span>
      </p>
    </div>
    <div class="slider__item">
      <h5 class="slider__item--title">{{ 'monitoring.tabs.navigation.label-types-of-position' | translate }}</h5>
      <hr />
      <p class="slider__item--text">PSRDIFF: <span>(40CM)</span></p>
      <p class="slider__item--text">PSRDIFF: <span>(40CM)</span></p>
      <p class="slider__item--text">RT-2: <span>(1CM)</span></p>
    </div>
    <div class="slider__item">
      <h5 class="slider__item--title">{{ 'monitoring.tabs.navigation.label-satellites' | translate }}</h5>
      <hr />
      <p class="slider__item--text">
        {{ 'monitoring.tabs.navigation.label-channel-frequency' | translate }}
        <span>{{ navigation?.frequency }} MHz</span>
      </p>
      <p class="slider__item--text">
        {{ 'monitoring.tabs.navigation.label-satellites-used' | translate }}
        <span>{{ navigation?.usedSatellites }}</span>
      </p>
    </div>
  </div>
</div>
