import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { PasswordValidationEnum } from 'app/core/enum/password-validation.enum';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { UserService } from 'app/core/services/user.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { TranslateUtil } from 'app/utils/translate.utils';

@Component({
  selector: 'app-reset',
  templateUrl: 'reset.component.html',
  styleUrls: ['reset.scss'],
})
export class ResetPasswordComponent implements OnInit {
  finishedRegistration = false;
  invalidHash = false;
  currentYear: number;
  blockBtnReset = false;
  sendSuccess = false;
  newPasswordForm: UntypedFormGroup;
  displayMessage: { [key: string]: string } = {};
  languageOptions: {
    PT_BR: string;
    EN: string;
    ES: string;
    IT: string;
  };
  translateUtils: TranslateUtil;
  isPasswordVisible = false;
  isConfirmPasswordVisible = false;

  constructor(
    public router: Router,
    public fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    public translate: TranslateService,
    public userService: UserService,
    public http: HttpClient,
    public alertService: AlertService,
  ) {
    this.translateUtils = new TranslateUtil();
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.languageOptions = AppConstants.LANGUAGES;
    this.route.queryParams.subscribe((params) => {
      const hash = params['hash'];

      if (hash === null || hash === '') {
        this.router.navigateByUrl('/login');
      }
    });
    this.newPasswordForm = this.fb.group({
      Senha: ['', [Validators.required]],
      ConfirmarSenha: ['', [Validators.required]],
    });
  }

  changeLanguage(language: string) {
    if (language === null || language === undefined) {
      language = AppConstants.LANGUAGES.PT_BR;
    }

    this.translate.use(language);
    window.localStorage.setItem(
      AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG,
      language,
    );
  }

  newPassword() {
    this.alertService.clear();

    if (this.validateField()) {
      this.sendSuccess = true;
      this.blockBtnReset = false;
      this.route.queryParams.subscribe((params) => {
        const hash = params['hash'];
        const password = this.newPasswordForm.controls.Senha.value;
        this.userService.redefinirSenha(password, hash).subscribe(
          (_) => {
            this.translate
              .get('global.alert.password-success')
              .subscribe((res: string) => {
                this.alertService.success(res);
                this.blockBtnReset = false;
                this.sendSuccess = false;
                setTimeout(() => {
                  this.router.navigate(['login']);
                }, TimeoutEnum.Medium);
              });
          },
          (error) => {
            this.translate
              .get('global.validate.email.required')
              .subscribe((res: string) => {
                this.alertService.error(res);
                this.blockBtnReset = true;
                this.sendSuccess = true;
              });

            if (
              error.error.text === this.translate.get('forgot.title-hash-reset')
            ) {
              this.translate
                .get('global.alert.password-reset-used')
                .subscribe((res: string) => {
                  this.alertService.warning(res);
                });
            } else {
              this.translate
                .get('global.alert.password-reset-error-message')
                .subscribe((res: string) => {
                  this.alertService.warning(res);
                });
            }
          },
        );
      });
    } else {
      this.blockBtnReset = false;
    }
  }

  // newPassword() {
  //   this.alertService.clear();
  //   this.blockBtnReset = true;
  //   if (this.validateField()) {
  //     this.sendSuccess = true;
  //     this.blockBtnReset = false;
  //     this.route.queryParams.subscribe((params) => {
  //       const hash = params['hash'];
  //       const senha = this.newPasswordForm.controls.Senha.value;
  //       const senhaObj = { Senha: senha };
  //       this.userService.redefinirSenha(senhaObj, hash).subscribe(
  //         (_) => {
  //           this.translate
  //             .get('global.alert.password-success')
  //             .subscribe((res: string) => {
  //               this.alertService.success(res);

  //               this.sendSuccess = false;
  //               setTimeout(() => {
  //                 this.router.navigate(['login']);
  //               }, TimeoutEnum.Medium);
  //             });
  //         },
  //         (error) => {
  //           this.translate
  //             .get('global.validate.email.required')
  //             .subscribe((res: string) => {
  //               this.alertService.error(res);
  //               this.blockBtnReset = true;
  //               this.sendSuccess = true;
  //             });

  //           if (
  //             error.error.text === this.translate.get('forgot.title-hash-reset')
  //           ) {
  //             this.translate
  //               .get('global.alert.password-reset-used')
  //               .subscribe((res: string) => {
  //                 this.alertService.warning(res);
  //               });
  //           } else {
  //             this.translate
  //               .get('global.alert.password-reset-error-message')
  //               .subscribe((res: string) => {
  //                 this.alertService.warning(res);
  //               });
  //           }
  //         },
  //       );
  //     });
  //   } else {

  //   }
  // }

  // validateField() {
  //   if (
  //     this.newPasswordForm.controls.Senha.value.length <
  //     PasswordValidationEnum.MinLength
  //   ) {
  //     this.translate
  //       .get('global.validate.password-minlength')
  //       .subscribe((res: string) => {
  //         this.alertService.warning(res);
  //       });

  //     return false;
  //   }

  //   if (
  //     this.newPasswordForm.controls.Senha.value !==
  //     this.newPasswordForm.controls.ConfirmarSenha.value
  //   ) {
  //     this.translate
  //       .get('global.validate.password-wrong')
  //       .subscribe((res: string) => {
  //         this.alertService.warning(res);
  //       });
  //     return false;
  //   }

  //   return true;
  // }

  validateField() {
    this.blockBtnReset = true;
    let isValid = true;

    if (
      this.newPasswordForm.controls.Senha.value.length <
      PasswordValidationEnum.MinLength
    ) {
      this.translate
        .get('global.validate.password-minlength')
        .subscribe((res: string) => {
          this.alertService.warning(res);
        });
      isValid = false;
    }

    if (
      this.newPasswordForm.controls.Senha.value !==
      this.newPasswordForm.controls.ConfirmarSenha.value
    ) {
      this.translate
        .get('global.validate.password-wrong')
        .subscribe((res: string) => {
          this.alertService.warning(res);
        });
      isValid = false;
    }

    this.blockBtnReset = !isValid;
    return isValid;
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmPassword() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  irParaLogin() {
    this.alertService.clear();
    this.router.navigateByUrl('/login');
  }

  verifyMaxLength(event) {
    if (event.target.value.length === PasswordValidationEnum.MaxLength) {
      this.translate
        .get('global.validate.password-maxlength')
        .subscribe((res: string) => {
          this.alertService.warning(res);
        });
    }
  }
}
