import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ColorPickerModule } from 'ngx-color-picker';
import { ConfirmacaoModalComponent } from './confirmacao-modal.component';

@NgModule ({
    declarations: [ConfirmacaoModalComponent],
    imports: [],
    exports: [ConfirmacaoModalComponent],
    providers: [],
})
export class ConfirmacaoModalModule {}
