import 'jquery';
import 'jquery-slimscroll';

import { AppConstants } from 'app/app.constants';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { SprayRateTask } from 'app/core/interface/spray-rate';
import { AnalyticsService } from 'app/core/services/analytics.service';
import { BlobStorageFilesService } from 'app/core/services/blob-storage-files.service';

import { FileManagerService } from 'app/core/services/file-manager.service';

// IMPORT SERVICES
import { NvgShapefileService } from 'app/core/services/nvg-shapefile.service';
import { NvgStorageService } from 'app/core/services/nvg-storage.service';
import { NvgTrabalhoService } from 'app/core/services/nvg-trabalho.service';
import { ReportImages, ReportService } from 'app/core/services/report.service';
import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { EventoISOBUS, OverlapInfo } from 'app/shared/models/eventoISOBUS';
import { Preferences } from 'app/shared/models/preferences.model';
import { ConvertUnit } from 'app/shared/pipes/convertUnit.pipe';
import { GuideType } from 'app/utils/guide.utils';
// IMPORT UTIL
import { TrailClass } from 'app/utils/trail.utils';
import { ECharts, EChartsOption } from 'echarts';
import { slideInRight, slideOutRight } from 'ng-animate';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { transition, trigger, useAnimation } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
// IMPORT MODELS
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
// IMPORT CORE
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MapMarker } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as turf from '@turf/turf';

import { Evento, OPERATION, Operation } from '../../../shared/models/evento';
import { EventoNVG } from '../../../shared/models/eventoNVG';
import { DevicePropertiesAndReferences } from './isobus-object-builder';
import { _roleEnumUserClass } from 'app/utils/role.utils';

declare var google: any;
interface ReferenceCoordinate {
  lat: number;
  lng: number;
}

interface LegendParams {
  name: string;
  selected: { [legendName: string]: boolean };
}

const FIELD_INST_RATE = 'inst_rate';
const FIELD_SPEED = 'speed';
const FIELD_OVERLAP = 'overlap';
const FIELD_ALTIMETRY = 'altitude';

const USER_MAP_COLOR = 'user_map_color.';

const SUBTITLE_PREFERENCES = {
  SPEED: 'tasks.machine-speed',
  APPLICATION_RATE: 'tasks.application-rate',
  DISTRIBUTED_LOAD: 'tasks.distributed-load',
  SEEDING_RATE: 'tasks.seeding-rate',
  SEEDS: 'tasks.tabs.seeds',
  ALTITUDE: 'tasks.altitude',
};

const TIPO_MARCADORES = {
  GUIDES: 'guides',
  MARKERS: 'markers',
  BOUNDARIES: 'boundaries',
  PAUSE_POINT: 'pausePointInit',
  PAUSE_END_POINT: 'pausePointEnd',
  STOP_POINT: 'stopPoint',
  TEMPERATURE_EXCEEDED_POINT: 'exceededTemperaturePoint',
  POWER_POINT: 'powerPoints',
};

const NVG_MAP_INDEX = {
  INST_RATE: 0,
  SPEED: 1,
  OVERLAP: 2,
  ALTIMETRY_OR_RECOMMENDATION: 3,
  RECOMMENDATION_OR_ALTIMETRY: 4,
};

const HEIGTH_SIDES_MENU = '220px';
const COLOR_SIDES_MENU = '#cecece';

interface CurrentViewportBreakpoint {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}
type RasterType<T extends string> =
  | 'inst_rate'
  | 'speed'
  | 'overlap'
  | 'altitude'
  | T;

interface AvailableMapTypeToSelect<T extends string> {
  key: RasterType<T>;
  value: string;
}
interface PathPoint {
  lng: number;
  lat: number;
}

interface Boundary {
  path: PathPoint[];
  area: number | null;
  timestamp: string | null;
  direction: string;
}
type Map = 0 | 1;

interface Etapa {
  e: Evento;
  app_version: string;
  machine_name: string;
  crop_type: string;
  operator: string;
  deviceId: number;
  serial_number: string;
  cover_area: number;
  travel_dist: number;
  avg_speed: number;
  speed: number;
  avg_rate: number;
  app_vol: number;
  sec_number: number;
  nozz_spacing: number;
  nozz_per_section: number;
  nozz_number: number;
  distance_from_antenna_to_hitch: number;
  impl_width: number;
  timestamp: string;
  endTimestamp: string;
  operation: Operation;
  start_location: {
    latitude: number;
    longitude: number;
  };
  end_location: {
    latitude: number;
    longitude: number;
  };
}

interface EcuData {
  implementWidth: number;
  numberOfSections: number;
  averageApplicationRate: [number, string];
}

interface StageInfo {
  startTime: string;
  stopTime: string;
  ecusData: {
    [key: string]: EcuData;
  }[];
}

interface FilteredEcusDataStageInfo {
  implementWidth: number;
  numberOfSections: number;
  averageApplicationRate: [number, string];
}

interface StageIsobusObjectData {
  implementWidth: number;
  numberOfSections: number;
  averageApplicationRate: [number, string];
  appVersion: string;
  machineName: string;
  cropType: string;
  operator: string;
  deviceId: number;
  serialNumber: string;
  cover_area: number;
  travel_dist: number;
  avg_speed: number;
  distanceFromAntennaToHitch: number;
  implWidth: number;
  ecusData?: any;
  timestamp: string;
  endTimestamp: string;
  startLocation: {
    latitude: number;
    longitude: number;
  };
  endLocation: {
    latitude: number;
    longitude: number;
  };
  startTime: string;
  stopTime: string;
}

@Component({
  selector: 'app-resultado-navegacao',
  templateUrl: 'resultado-navegacao.component.html',
  styleUrls: ['resultado-navegacao.component.scss'],
  providers: [ConvertUnit],
  animations: [
    trigger('slideMap', [
      transition(
        ':enter',
        useAnimation(slideInRight, { params: { timing: 1.5 } }),
      ),
      transition(
        ':leave',
        useAnimation(slideOutRight, { params: { timing: 1.5 } }),
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultadoNavegacaoComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  selectedIsobusApplicationRate0: string;
  selectedIsobusApplicationRate1: string;
  fieldId: string;
  foundOverlapIsobus: string;
  graphicDate: string[];
  isSprayRateTask = false;
  isSpeedMap = false;
  legendIsobusString: string;
  legendIsobusUnit: string;
  isFreeTier: boolean;
  isobusOverlapPercentage: number;

  legendValidityChange(isLegendValid: boolean, errorValuesLegend: string) {
    this[errorValuesLegend] = !isLegendValid;
    this.seeButtonSave = isLegendValid;
  }
  apiLoaded: Observable<boolean>;

  @ViewChildren('guiasSideMenu') guiasSideMenuElements: QueryList<ElementRef>;

  nvgStorageData: EventoNVG | EventoISOBUS;
  operation_type: keyof typeof OPERATION = null;
  boundary: Boundary;
  _opened = false;

  bounds: any;
  mapOneLoaded = false;
  mapTwoLoaded = false;
  centerMap: any;

  currentUnitSystem: string;
  unitSystemOptions = [
    AppConstants.UNIT_SYSTEM_OPTIONS.METRIC,
    AppConstants.UNIT_SYSTEM_OPTIONS.IMPERIAL,
  ];

  // MAP
  mapLat = 41.9171;
  mapLng = 8.7094;
  map: any;
  map1: any;
  mapZoom = 2;
  MAP_MAX_ZOOM_DEFAULT = 19;
  MAP_MAX_ZOOM = this.MAP_MAX_ZOOM_DEFAULT;

  splitScreen = false;
  lastInfoWindow: any;
  guideInfoWindowPoint = { lat: 0, long: 0 };

  mapTypesOptions = ['satellite', 'terrain', 'hybrid', 'roadmap'];

  hasAltimetryMap = false;
  mapaType = this.mapTypesOptions[0];

  mapa0 = 0;
  mapa1 = 0;

  point: google.maps.Point;
  scaledSize: google.maps.Size;

  mapOptions: google.maps.MapOptions = {
    center: { lat: this.mapLat, lng: this.mapLng },
    zoom: this.mapZoom,
    mapTypeId: this.mapaType,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    fullscreenControl: true,
    zoomControl: false,
    streetViewControl: false,
    gestureHandling: 'auto',
    tilt: 0,
  };

  secondMapOptions: google.maps.MapOptions = {
    ...this.mapOptions,
    scaleControl: true,
    panControl: true,
    scrollwheel: null,
    fullscreenControl: false,
  };

  taskMapTypes = ['map-application', 'map-speed', 'map-transgression'];

  // TABS
  etapas: Etapa;
  timestamp_etapa_selecionada: string;
  endTimestamp_etapa_selecionada: string;
  travelDistance: number;
  etapasArr = [];

  stageQuantity: number;
  selectedEtapa: number | null = null;

  // RASTER
  workStatus: any;
  sectionArr = [];

  trailsM1: [[] | {}, [], [], [], [], [], [], []] = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];
  trailsM2: [[] | {}, [], [], [], [], [], [], []] = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  guides = [];
  firstGuideIndex: number;
  selectedGuide: any;
  htmlindexSelectedGuide: number;

  preferences: Array<Preferences> = [];

  errorValuesLegend0 = false;
  errorValuesLegend1 = false;
  seeButtonSave = true;

  trabalho: Partial<Evento> | undefined;
  guias: Array<any>;
  marcadores: Array<any>;
  etapa: Array<any>;
  stageInfo: StageInfo[];
  stageInfoIsobus: any;
  devices: DevicePropertiesAndReferences[];

  subtitles: any;
  altimetryPoints: Array<any>;
  boundaries: Array<any> = [];

  // TRAIL COLOR
  trailColorScale: string;
  trailClass: TrailClass;
  guideTypeEnum: typeof GuideType;

  distanceUnit = 'km';
  speed = 0;
  app_vol = 0;
  tempoTotalOperacao: string | [number, string] = [0, ''];
  tempoEfetivoOperacao: string | [number, string] = [0, ''];
  tempoOciosoOperacao: string | [number, string] = [0, ''];
  percentTotalOperation: string | [number, string] = [0, ''];

  devicesIsobus: any[] = [];
  selectedDevice: string = '';

  trails: { [key: string]: Array<any> };
  applicationRates: string[] = [
    'applicationRateSolid',
    'applicationRateSeed',
    'applicationRateLiquid',
  ];
  overlapsIsobus: string[] = ['overlapSolid', 'overlapSeed', 'overlapLiquid'];
  bbox = [];
  boundingBox = [];
  startPin: Array<any> = [{ lat: 0, lng: 0, title: null }];
  showWorkMark: boolean;
  startLat: any;
  startLng: any;
  endLat: any;
  endLng: any;
  stageInfoIsobusStartTime: string = '';
  stageInfoIsobusStopTime: string = '';

  tipo1MapaEscolhido = '';
  tipo2MapaEscolhido = '';

  // TASK
  etapasNvg: any;
  stageNvgData: any;
  stageIsobusData: any;
  stageIsobusObjectData: StageIsobusObjectData;
  taskName: string;
  firstLoad: boolean;

  labelInicio: string;
  labelFim: string;
  labelEtapa: string;

  logoAgres = new Image();

  marcadoresArrayL = [];

  replayController: {
    isRunning: any;
    terminate: any;
    play?: () => Promise<void>;
    pause?: () => void;
    stop?: () => Promise<void>;
    canPlay?: () => boolean;
    canStop?: () => boolean;
    speedUp?: () => void;
    speedDown?: () => void;
    stepOneForward?: () => Promise<void>;
    stepOneBackwards?: () => void;
    getCurrentReproductionSpeed?: () => number;
    getMaxIndex?: () => number;
    getProgress?: () => any;
    progressBarClick?: (value: any) => void;
    fitMapAtStep?: () => void;
    getCurrentLocation?: () => any;
  };

  showRecommendationMapUpload = false;
  referenceCoordinate: ReferenceCoordinate;

  public readonly ERROR_ON_FIELD = 'error';

  destroyed = false;
  taskId: string;
  type: string;
  googleMapsApiLoaded: boolean = false;
  isMap1BeingEdited: boolean;
  isMap2BeingEdited: boolean;
  showModalSubject: Subject<boolean> = new Subject();
  referenceCoordinateSubject: Subject<ReferenceCoordinate> = new Subject();
  viewportBreakpoints: CurrentViewportBreakpoint = {
    mobile: true,
    tablet: false,
    desktop: false,
  };
  mapImages: ReportImages = undefined;

  selectedMapImage: AvailableMapTypeToSelect<string> = {
    key: 'speed',
    value: null,
  };
  readonly preferencesInputSorting: Preferences = {
    red: 0,
    orange: 1,
    yellow: 2,
    lemonGreen: 3,
    green: 4,
  };

  taskSummaryBoxValue: [string, string | undefined, boolean | undefined];
  isIsobusData: boolean = false;
  selectedMap1Value: number = 0;
  selectedMap2Value: number = 0;
  taskIsobusType: any;
  sprayRateTask: SprayRateTask;
  taskData: any;
  hasField = false;
  hasAlerts = false;
  alerts = [];
  operationsFieldsTitle: string;
  fieldButton = false;

  deviceNames: {
    name: string;
    serial: string;
    deviceStructureLabel: string;
  }[] = [];
  filteredTask: string[] = [];
  filteredEcu: string[] = [];
  totals: any[] = [];
  totalObjects: any = {};
  filteredEcusDataStageInfo: FilteredEcusDataStageInfo;
  stageIsobusMergedData: any;
  foundApplicationRate: any;
  operationType: string;
  boundBoxLoadingIntervalRef: NodeJS.Timeout;
  availableRasters: any;
  preferenceSubtitleUnits: [string, string] = ['', ''];
  preferenceSubtitles: [string, string] = ['', ''];
  userId: string;

  modalEditLegendMap1 = false;
  modalEditLegendMap2 = false;

  private selectedLegends: string[] = [];

  private readonly chart!: ECharts;
  chartOptions: EChartsOption;
  graphStringTax: string;
  graphStringTargetTax: string;
  graphStringSpeed: string;
  graphStringSection: string;
  graphStringPressure: string;
  graphStringSectionState: string;
  graphStringFlow: string;
  graphStringTime: string;
  graphStringResetButton: string;
  graphStringDownloadButton: string;

  private languageSelected: string;
  isReadOnly: boolean = true;
  showMapSprayRate = true;
  protected readonly OPERATION = OPERATION;

  constructor(
    public httpClient: HttpClient,
    public toastr: ToastrService,
    public cdr: ChangeDetectorRef,
    public translateService: TranslateService,
    public convertUnitPipe: ConvertUnit,
    public nvgStorageService: NvgStorageService,
    public nvgTrabalhoService: NvgTrabalhoService,
    public nvgShapefileService: NvgShapefileService,
    public blobStorageService: BlobStorageFilesService,
    public route: ActivatedRoute,
    public router: Router,
    public reportService: ReportService,
    private readonly eleRef: ElementRef,
    private readonly breakpointObserver: BreakpointObserver,
    public loadingAgresSpinner: AgresSpinnerService,
    private readonly alertService: AlertService,
    private readonly _location: Location,
    public userTalhoesService: UserTalhaoListService,
    private readonly analyticsService: AnalyticsService,
    public fileManagerService: FileManagerService,
  ) {
    this.languageSelected =
      this.translateService.currentLang || AppConstants.LANGUAGES.PT_BR;

    this.currentUnitSystem = this.convertUnitPipe.getCurrentUnitSystem();
    this.setTranslateFieldsOnMap();
    this.trailClass = new TrailClass();
    this.guideTypeEnum = GuideType;
    this.changeLanguage();
    this.replayController = this.NavigationReplay();
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languageSelected = event.lang;
      if (this.isSprayRateTask) {
        this.translateWordsGraphicSprayRate();
      }
    });

    this.preferenceSubtitleUnits = this.getPreferenceSubtitleUnit();
    this.preferenceSubtitles = this.getPreferenceSubtitle();
    const params = this.route.snapshot.queryParams;
    const taskId = params['id'];
    this.taskId = taskId;
    const type = params['type'];
    this.type = type;

    if (taskId == null || taskId == '') {
      this.translateService
        .get('page-erros.500.error-occurred-try-again')
        .subscribe((res: string) => {
          this.toastr.error(res);

          setTimeout(function () {
            this.router.navigate(['/gestao-operacao/historico-navegacao']);
          }, 500);
        });
    } else {
      this.eventNvgSelect(taskId);
    }

    this.taskSummaryBoxValue = this.taskSummaryBoxApplyedValue();
    this.logoAgres.src = '/assets/images/default/logo-agres.png';
    this.showModalSubject.subscribe((showModal: boolean) => {
      this.showRecommendationMapUpload = showModal;
    });
    this.setGoogleMapComponentObservable(this.httpClient);
    this.breakpointObserver
      .observe(['(max-width: 480px)', '(max-width: 640px)'])
      .subscribe(
        (result: {
          matches: boolean;
          breakpoints: { [key: string]: boolean };
        }) => {
          const breakpointEntries: Array<[string, boolean]> = Object.entries(
            result.breakpoints,
          );
          const mobileBreakPointFilter = (entries: [string, boolean]) =>
            entries[1] && entries[0] === '(max-width: 480px)';
          const tabletBreakPointFilter = (entries: [string, boolean]) =>
            (entries[1] && entries[0] === '(max-width: 640px)') ||
            (!entries[1] && entries[0] === '(max-width: 480px)');
          const desktopBreakPointFilter = (entries: [string, boolean]) =>
            !entries[1] && entries[0] === '(max-width: 640px)';

          const isMobile = breakpointEntries.some(mobileBreakPointFilter);
          const isTablet = breakpointEntries.every(tabletBreakPointFilter);
          const isDesktop = breakpointEntries.some(desktopBreakPointFilter);
          if (isMobile) {
            this.mapOptions = {
              ...this.mapOptions,
              gestureHandling: 'none',
              disableDefaultUI: false,
              streetViewControl: false,
              mapTypeControl: false,
            };
            this.setGoogleMapComponentObservable(this.httpClient);
          } else if (isDesktop) {
            this.setGoogleMapComponentObservable(this.httpClient);
          }
          this.viewportBreakpoints = {
            mobile: isMobile && !(isTablet || isDesktop),
            tablet: isTablet && !isDesktop,
            desktop: isDesktop,
          };
        },
      );

    const usuarioLogado = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    this.userId = usuarioLogado.id;
    this.isFreeTier = _roleEnumUserClass.isRoleFreetier();
  }

  private readonly storageListener = (event: StorageEvent) => {
    if (event.key === 'iso.lang' && event.newValue !== this.languageSelected) {
      this.languageSelected = event.newValue;
      this.translateWordsGraphicSprayRate();
    }
  };

  limitLegendSelection(params: LegendParams): void {
    const selectedLegend = params.name;

    if (params.selected[selectedLegend]) {
      this.selectedLegends.push(selectedLegend);
    } else {
      this.selectedLegends = this.selectedLegends.filter(
        (legend) => legend !== selectedLegend,
      );
    }

    if (this.selectedLegends.length > 2) {
      this.keepNewerLegend(params);
    }
  }

  keepNewerLegend(params: LegendParams): void {
    while (this.selectedLegends.length > 2) {
      this.removeOlderLegend(params);
    }
  }

  removeOlderLegend(params: LegendParams): void {
    const legendToRemove = this.selectedLegends.shift();
    if (legendToRemove) {
      this.chart.dispatchAction({
        type: 'legendUnSelect',
        name: legendToRemove,
      });
      params.selected[legendToRemove] = false;
    }
  }

  generateTimeLabels(timeData: string[]): string[] {
    return timeData.map((timestamp) =>
      new Date(timestamp).toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      }),
    );
  }

  selectEtapa(index: number): void {
    this.selectedEtapa = index;
  }
  private setGoogleMapComponentObservable(httpClient: HttpClient) {
    this.apiLoaded = httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo&libraries=drawing',
        'callback',
      )
      .pipe(
        map(() => {
          this.point = new google.maps.Point(15, 10);
          this.scaledSize = new google.maps.Size(30, 30);
          this.googleMapsApiLoaded = true;
          return true;
        }),
        catchError(() => of(false)),
      );
  }

  _downloadShape(): Promise<void> {
    return new Promise((resolve) => {
      const polygonsOptionKeys = [
        {
          key: 'Dose',
          unit: this._getSIPreferenceSubtitleUnit(0).replace(/[()]/g, ''),
        },
        {
          key: 'Veloc.',
          unit: this._getSIPreferenceSubtitleUnit(1).replace(/[()]/g, ''),
        },
        {
          key: 'Transp.',
          unit: this._getSIPreferenceSubtitleUnit(2).replace(/[()]/g, ''),
        },
      ];
      const polygonsAltimetryOptionKeys = [{ key: 'Altitude' }];

      const trailPolygons =
        this._createTurfShapeFilePolygons(polygonsOptionKeys);
      const turfAltimetryPoints = this._createAltimetryTurfPoints(
        polygonsAltimetryOptionKeys,
      );

      const folderEvent = this.trabalho.evt_name;

      const options = {
        folder: folderEvent,
        altimetryFileName: folderEvent + '_altimetria',
        types: {
          point: 'points',
          polygon: 'polygons',
          line: 'lines',
        },
      };

      window['shpwrite'].download(
        {
          type: 'FeatureCollection',
          features: trailPolygons,
          altimetryPoints: turfAltimetryPoints,
        },
        options,
      );

      resolve();
    });
  }

  _createAltimetryTurfPoints(polygonsAltimetryOptionKeys) {
    const turfAltimetryPoints = [];
    if (this.altimetryPoints && this.altimetryPoints.length > 0) {
      this.altimetryPoints.forEach((point, index) => {
        const turfPoint = turf.point(
          [point.location.longitude, point.location.latitude],
          {
            ID: index,
            [polygonsAltimetryOptionKeys[0].key]:
              this.convertUnitPipe.convertValueToSupportedMapUnits(
                point.altitude,
                'm',
              ),
          },
        );
        turfAltimetryPoints.push(turfPoint);
      });
    }
    return turfAltimetryPoints;
  }

  downloadShapefile() {
    this.translateService
      .get('tasks.generate-shapefile')
      .subscribe((res: string) => {
        this.toastr.info(res, '', { disableTimeOut: false });
        try {
          this.nvgShapefileService
            .getShapefilePressignedURL(
              this.taskName,
              this.trabalho.crop_name,
              this.trabalho.evt_name,
            )
            .subscribe((shapefileURL: string) => {
              this._downloadShapefileZip(shapefileURL);
            });
        } catch (err) {
          this._createShapefileInBrowser();
        }
      });
  }

  downloadTaskData() {
    this.translateService
      .get('tasks.generate-taskdata')
      .subscribe((res: string) => {
        this.alertService.info(res, TimeoutEnum.VeryShort);
        try {
          this.blobStorageService
            .getAzurePresignUrl('taskdata', this.taskId)
            .subscribe(({ presignedUrl }) => {
              this.fileManagerService.downloadFile(
                presignedUrl,
                'taskdata.zip',
              );
            });
        } catch (err) {
          this.translateService
            .get('tasks.generate-taskdata-error')
            .subscribe((res: string) => {
              this.alertService.error(res, TimeoutEnum.Short);
            });
        }
      });
  }

  _report() {
    const selectedLanguage = this.translateService
      .currentLang as (typeof AppConstants.LANGUAGES)[keyof typeof AppConstants.LANGUAGES];
    let metricSystem = this.currentUnitSystem as 'metric' | 'imperial';
    if (this.currentUnitSystem !== 'imperial') {
      metricSystem = 'metric';
    }
    this.reportService
      .getReportPdf<
        typeof selectedLanguage
      >(this.taskId, selectedLanguage, metricSystem)
      .subscribe((blob: Blob) => {
        const pdfUrl = window.URL.createObjectURL(blob);
        const tempA = document.createElement('a');
        tempA.href = pdfUrl;
        tempA.download = `${this.trabalho.crop_name}_${this.translateService.currentLang}.pdf`;
        document.body.appendChild(tempA);
        tempA.click();
        tempA.remove();
      });
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.storageListener);
    this.destroyed = true;
    this.loadingAgresSpinner.toHide();

    if (this.replayController.isRunning()) this.replayController.terminate();
    if (typeof google !== 'undefined') google = undefined;
    clearInterval(this.boundBoxLoadingIntervalRef);
  }

  ngAfterViewInit() {
    this.guiasSideMenuElements.changes.subscribe(
      (guidesSideMenuElements: QueryList<ElementRef>) => {
        if (guidesSideMenuElements.length > 0) {
          (<any>jQuery(guidesSideMenuElements.first.nativeElement)).slimscroll({
            height: HEIGTH_SIDES_MENU,
            color: COLOR_SIDES_MENU,
            allowPageScroll: true,
            alwaysVisible: false,
          });
        }
      },
    );
    $('.ng-sidebar').css({ 'margin-bottom': '45px', 'z-index': '1' });

    jQuery('.tab-divisor').on('click', function (e) {
      e.preventDefault();

      const self = $(this);
      const idTab = self.attr('data-tab-build');

      $('.tab-pane, .tab-divisor').removeClass('active');
      $('.aba').removeClass('abaActive');
      self.addClass('active').children('a').addClass('abaActive');
      $('#' + idTab).addClass('active');
    });

    this.nvgTrabalhoService.trabalho = undefined;
    this.firstLoad = true;
  }

  updateTrailColor(trailIndexToUpdate: number) {
    if (this.trailsM1[trailIndexToUpdate]) {
      if (Array.isArray(this.trailsM1[trailIndexToUpdate])) {
        this.updateArrayTrailColor(
          this.trailsM1[trailIndexToUpdate] as Array<any>,
          trailIndexToUpdate,
        );
      } else {
        for (const key in this.trailsM1[trailIndexToUpdate]) {
          const raster = this.trailsM1[trailIndexToUpdate][key];
          if (raster) {
            this.updateArrayTrailColor(raster, trailIndexToUpdate);
          }
        }
      }

      if (
        !Array.isArray(this.trailsM1[trailIndexToUpdate]) &&
        Array.isArray(this.trailsM2[trailIndexToUpdate])
      ) {
        this.trailsM2[trailIndexToUpdate] = {
          ...this.trailsM1[trailIndexToUpdate],
        };
      } else if (
        Array.isArray(this.trailsM1[trailIndexToUpdate]) &&
        Array.isArray(this.trailsM2[trailIndexToUpdate])
      ) {
        Object.assign(
          this.trailsM2[trailIndexToUpdate],
          this.trailsM1[trailIndexToUpdate],
        );
      }
    }
  }

  private updateArrayTrailColor(trail: any[], trailIndexToUpdate: number) {
    trail.forEach((polygon, index) => {
      const value: number =
        this.shouldShowLegend() ||
        trailIndexToUpdate === NVG_MAP_INDEX.SPEED ||
        trailIndexToUpdate === NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION ||
        trailIndexToUpdate === NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY
          ? polygon.valueOperation
          : 0;

      const newColor = this.trailClass.trailColorPref(
        'false',
        undefined,
        value,
        this.preferences[trailIndexToUpdate],
        'desc',
      ).trailColorScale;
      trail[index].color = newColor;
    });
  }

  etapaClick() {
    if (this.firstLoad) {
      this.stageSelect(event, this.etapasArr[0], 0);

      this.firstLoad = false;
    }
    if (this.viewportBreakpoints.mobile) return;
    this._showAllGuidesDependingOnGuidesSwitch();
    this.showWorkMark = false;
  }

  stageSelect(_event, e, i) {
    if (!this.isIsobusData) {
      this.stageNvgData = e;
      this.etapasNvg = e;
      this.timestamp_etapa_selecionada = e?.timestamp;
      this.endTimestamp_etapa_selecionada = e?.endTimestamp;
      this.travelDistance = e?.travelDist;
      this.setStepPins(this.etapa[i]);
    } else {
      this.totals = e;

      this.stageIsobusObjectData = e;

      this.updateFilteredDataStageInfoEcusIsobus();

      this.updateStageDataForSelectedDevice(i);
    }
  }

  setStepPins(currentStep: { start_location: any; end_location: any }) {
    const startpoint = currentStep.start_location;
    if (startpoint) {
      this.startLng = startpoint.longitude;
      this.startLat = startpoint.latitude;
    }
    const endpoint = currentStep.end_location;
    if (endpoint) {
      this.endLng = endpoint.longitude;
      this.endLat = endpoint.latitude;
    }
  }

  guideSelect(index: number) {
    this.selectedGuide = this.guides[index];
    this.htmlindexSelectedGuide = index;

    if (this.selectedGuide) {
      this.guides.forEach((guide, i) => {
        guide.show = i === index;
      });

      if (this.lastInfoWindow) {
        this.lastInfoWindow.close();
      }

      this._checkGuideMarkerSwitch();
    }
  }

  _hasMarkerOfType(markersObj, marker) {
    return markersObj[marker] != null && markersObj[marker].length > 0;
  }

  toggleMarker(marker: { checked: boolean; property: string }) {
    const markers = {
      [TIPO_MARCADORES.PAUSE_POINT]: 'pausePointsInit',
      [TIPO_MARCADORES.PAUSE_END_POINT]: 'pausePointsEnd',
      [TIPO_MARCADORES.STOP_POINT]: 'stopPoints',
      [TIPO_MARCADORES.TEMPERATURE_EXCEEDED_POINT]: 'exceededTemperaturePoints',
      [TIPO_MARCADORES.POWER_POINT]: 'powerPoint',
    };

    marker.checked = !marker.checked;

    if (marker.property === TIPO_MARCADORES.GUIDES) {
      this._toggleCheckedStateForGuidesMaker(marker.checked);
    } else if (marker.property === TIPO_MARCADORES.MARKERS) {
      this._toggleShowStateForAllMarkers(marker.checked);
      this._toggleCheckedStateForAllMakersSwitchButton(marker.checked);
    } else if (marker.property === TIPO_MARCADORES.BOUNDARIES) {
      this._toggleCheckedStateForBoundariesMarker(marker.checked);
    } else {
      this.marcadores[markers[marker.property]].forEach((taskMarker) => {
        taskMarker.show = marker.checked;
      });
    }
    this._controlMasterMarkersCheckState();
  }

  _getPreferenceSubtitleByOperation(operation: string | number): string {
    return (
      {
        [OPERATION.PULVERIZACAO]: SUBTITLE_PREFERENCES.APPLICATION_RATE,
        [OPERATION.FRUTICULTURA]: SUBTITLE_PREFERENCES.APPLICATION_RATE,
        [OPERATION.ADUBACAO]: SUBTITLE_PREFERENCES.DISTRIBUTED_LOAD,
        [OPERATION.PLANTIO]: SUBTITLE_PREFERENCES.SEEDING_RATE,
      }[operation] || ''
    );
  }

  _toggleShowStateForAllMarkers(newState: boolean) {
    Object.keys(this.marcadores).forEach((markerType) => {
      this.marcadores[markerType].forEach((marker) => {
        marker.show = newState;
      });
    });
  }

  _toggleCheckedStateForAllMakersSwitchButton(newState: boolean) {
    this.marcadoresArrayL.forEach((markerSwitch) => {
      if (markerSwitch.children) {
        markerSwitch.checked = newState;
      }
    });
  }

  _toggleCheckedStateForGuidesMaker(newState: boolean) {
    if (this.selectedGuide) {
      const index = this.guides.indexOf(this.selectedGuide);
      this.guides[index].show = newState;
    } else {
      this.guides.forEach((guide) => {
        guide.show = newState;
      });
    }
  }

  _toggleCheckedStateForBoundariesMarker(newState: boolean) {
    this.boundaries = this.boundaries.map((b) => {
      b.visible = newState;
      return b;
    });
  }

  _checkGuideMarkerSwitch() {
    const guidesMarker = this.marcadoresArrayL.find(
      (marker) => marker.property === TIPO_MARCADORES.GUIDES,
    );

    if (guidesMarker && !guidesMarker.checked) this.toggleMarker(guidesMarker);
  }

  _showAllGuidesDependingOnGuidesSwitch() {
    const guidesMarker = this.marcadoresArrayL.find(
      (marker) => marker.property === TIPO_MARCADORES.GUIDES,
    );
    this.selectedGuide = undefined;
    if (guidesMarker?.checked) {
      this.guides.forEach((guide) => {
        guide.show = true;
      });
    }
  }
  setDefaultSubtitle() {
    if (this.subtitles) {
      this.seeButtonSave = true;
      const instRateSubtitle = this.subtitles[FIELD_INST_RATE];
      const speedSubtitle = this.subtitles[FIELD_SPEED];
      const overlapSubtitle = this.subtitles[FIELD_OVERLAP];
      const altimetrySubtitle = this.subtitles[FIELD_ALTIMETRY];
      const recommendationSubtitle = this.hasAltimetryMap
        ? this.preferencesToSubtitleArray(
            this.preferences[NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY],
          )
        : this.preferencesToSubtitleArray(
            this.preferences[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION],
          );

      this.preferences[NVG_MAP_INDEX.INST_RATE] =
        this.subtitleArrayToPreferences(instRateSubtitle);

      this.preferences[NVG_MAP_INDEX.SPEED] =
        this.subtitleArrayToPreferences(speedSubtitle);
      this.preferences[NVG_MAP_INDEX.OVERLAP] =
        this.subtitleArrayToPreferences(overlapSubtitle);

      if (this.hasAltimetryMap) {
        this.preferences[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] =
          this.subtitleArrayToPreferences(altimetrySubtitle);
        this.updateTrailColor(NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION);
        this.preferences[NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY] =
          this.subtitleArrayToPreferences(recommendationSubtitle);
        this.updateTrailColor(NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY);
      } else {
        this.preferences[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] =
          this.subtitleArrayToPreferences(recommendationSubtitle);
        this.updateTrailColor(NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION);
      }
      this.updateTrailColor(NVG_MAP_INDEX.INST_RATE);
      this.updateTrailColor(NVG_MAP_INDEX.SPEED);
      this.updateTrailColor(NVG_MAP_INDEX.OVERLAP);

      const unitArray = this._getSubtitleUnitsArray('metric');
      this._convertSubtitles(unitArray);
      this.cdr.markForCheck();
      localStorage.setItem(
        USER_MAP_COLOR + this.taskName,
        JSON.stringify(
          this._convertLegendsToSI(
            this.prepareLegendDataToSave(),
            AppConstants.UNIT_SYSTEM_OPTIONS.METRIC,
          ),
        ),
      );

      this.errorValuesLegend0 = false;
      this.errorValuesLegend1 = false;
    }

    this.closeModalEditLegendMap1();
    this.closeModalEditLegendMap2();
  }

  checkIfSelectedDeviceExists(selectedDevice: string, object: any): boolean {
    return selectedDevice in object;
  }

  processSelectedDevice(selectedDevice: string, object: any, subtitles: any) {
    if (!(selectedDevice in object)) {
      return;
    }
    const legendsIsobusEnum = {
      applicationRateLiquid: 'tasks.application-rate',
      applicationRateSolid: 'tasks.distributed-load',
      applicationRateSeed: 'tasks.seeding-rate',
    };
    const availableKeys = [
      'applicationRateLiquid',
      'applicationRateSolid',
      'applicationRateSeed',
    ];

    const overlapsIsobus: string[] = [
      'overlapSolid',
      'overlapSeed',
      'overlapLiquid',
    ];

    const device = object[selectedDevice];

    const matchingKey = availableKeys.find((key) => key in device);

    const overlapKey = overlapsIsobus.find((key) => key in device);

    this.legendIsobusString = legendsIsobusEnum[matchingKey];

    this.legendIsobusUnit = device[matchingKey][1];

    if (!matchingKey || !overlapKey) {
      return;
    }

    const instRateSubtitle = subtitles[matchingKey];
    const overlapSubtitle = subtitles[overlapKey];

    this.preferences[NVG_MAP_INDEX.INST_RATE] =
      this.subtitleArrayToPreferences(instRateSubtitle);
    this.preferences[NVG_MAP_INDEX.OVERLAP] =
      this.subtitleArrayToPreferences(overlapSubtitle);
  }

  setDefaultSubtitleIsobus() {
    if (this.subtitles) {
      this.seeButtonSave = true;
      this.processSelectedDevice(
        this.selectedDevice,
        this.trails,
        this.subtitles,
      );

      const speedSubtitle = this.subtitles[FIELD_SPEED];

      this.preferences[NVG_MAP_INDEX.SPEED] =
        this.subtitleArrayToPreferences(speedSubtitle);

      this.updateTrailColor(NVG_MAP_INDEX.INST_RATE);
      this.updateTrailColor(NVG_MAP_INDEX.SPEED);
      this.updateTrailColor(NVG_MAP_INDEX.OVERLAP);

      this._convertAndSaveLegends();

      this.errorValuesLegend0 = false;
      this.errorValuesLegend1 = false;
    } else {
      this.preferences[NVG_MAP_INDEX.INST_RATE] =
        this.subtitles[NVG_MAP_INDEX.INST_RATE];
    }
  }

  private _convertAndSaveLegends() {
    const unitArray = this._getSubtitleUnitsArray('metric');
    this._convertSubtitles(unitArray);

    const legendData = this.prepareLegendDataToSave();
    const convertedLegends = this._convertLegendsToSI(
      legendData,
      AppConstants.UNIT_SYSTEM_OPTIONS.METRIC,
    );
    localStorage.setItem(
      USER_MAP_COLOR + this.taskName,
      JSON.stringify(convertedLegends),
    );

    this.cdr.markForCheck();
  }

  _getSIPreferenceSubtitleUnitByOperation(operation: keyof typeof OPERATION) {
    return (
      {
        [OPERATION.PULVERIZACAO]: 'l/ha',
        [OPERATION.FRUTICULTURA]: 'l/ha',
        [OPERATION.ADUBACAO]: 'kg/ha',
        [OPERATION.PLANTIO]: 'seeds/m',
      }[operation] || ''
    );
  }

  _getSubtitleUnitsArray(unitSystemFrom: string) {
    const unitArray = [];
    for (const mapIndex in NVG_MAP_INDEX) {
      if (NVG_MAP_INDEX.hasOwnProperty(mapIndex)) {
        const unit = this.convertUnitPipe.getUnitFromSISystem(
          this._getSIPreferenceSubtitleUnit(NVG_MAP_INDEX[mapIndex]),
          unitSystemFrom,
        );
        unitArray.push(unit);
      }
    }
    return unitArray;
  }

  _convertSubtitles(unitArray: any[]) {
    for (const mapIndex in NVG_MAP_INDEX) {
      if (NVG_MAP_INDEX.hasOwnProperty(mapIndex)) {
        const subtitleArray = this.preferencesToSubtitleArray(
          this.preferences[NVG_MAP_INDEX[mapIndex]],
        );
        const convertedSubtitle =
          this.convertUnitPipe.convertAllValuesFromArrayToSameUnit(
            subtitleArray,
            unitArray[NVG_MAP_INDEX[mapIndex]],
          );
        this.preferences[NVG_MAP_INDEX[mapIndex]] =
          this.subtitleArrayToPreferences(convertedSubtitle);
      }
    }
  }
  _convertSubtitlesToSI() {
    for (const mapIndex in NVG_MAP_INDEX) {
      const subtitleArray = this.preferencesToSubtitleArray(
        this.preferences[NVG_MAP_INDEX[mapIndex]],
      );
      const convertedSubtitle = [];
      const unit = this.convertUnitPipe.transformUnit(
        this._getSIPreferenceSubtitleUnit(NVG_MAP_INDEX[mapIndex]),
      );
      subtitleArray.forEach((subtitle) => {
        convertedSubtitle.push(
          +this.convertUnitPipe.convertToSIUnit(+subtitle, unit).val.toFixed(2),
        );
      });
      this.preferences[NVG_MAP_INDEX[mapIndex]] =
        this.subtitleArrayToPreferences(convertedSubtitle);
    }
  }
  _convertLegendsToSI(
    legendDataToSave: {
      inst_rate: [number, number, number, number, number];
      speed: [number, number, number, number, number];
      overlap: [number, number, number, number, number];
      altitude: [number, number, number, number, number];
    },
    currentUnitSystem,
  ) {
    const customTrails = [
      FIELD_INST_RATE,
      FIELD_SPEED,
      FIELD_OVERLAP,
      FIELD_ALTIMETRY,
    ];
    const sIPreferenceSubtitleUnit = [
      this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
      'km/h',
      this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
    ];
    if (this.hasAltimetryMap) {
      sIPreferenceSubtitleUnit.push('m');
    } else {
      sIPreferenceSubtitleUnit.push(
        this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
      );
    }
    for (let i = 0; i < customTrails.length; i++) {
      const subtitleArray = legendDataToSave[customTrails[i]];
      if (currentUnitSystem !== AppConstants.UNIT_SYSTEM_OPTIONS.METRIC) {
        const convertedSubtitle = [];
        const unit = this.convertUnitPipe.transformUnit(
          sIPreferenceSubtitleUnit[i],
        );
        subtitleArray.forEach((subtitle) => {
          convertedSubtitle.push(
            +this.convertUnitPipe
              .convertToSIUnit(+subtitle, unit)
              .val.toFixed(2),
          );
        });
        legendDataToSave[customTrails[i]] =
          this.subtitleArrayToPreferences(convertedSubtitle);
      } else {
        legendDataToSave[customTrails[i]] =
          this.subtitleArrayToPreferences(subtitleArray);
      }
    }
    return legendDataToSave;
  }

  subtitleArrayToPreferences(arraySubtitle: number[]) {
    const preferences = <Preferences>{
      red: arraySubtitle[0],
      orange: arraySubtitle[1],
      yellow: arraySubtitle[2],
      lemonGreen: arraySubtitle[3],
      green: arraySubtitle[4],
    };
    return preferences;
  }

  setNvgSubtitle(nvgId: string) {
    const subtitlesFromLocal = this.getSubtitleFromLocalStorage(nvgId);
    if (subtitlesFromLocal) {
      const parsedSubtitles = JSON.parse(subtitlesFromLocal);

      this.preferences[NVG_MAP_INDEX.INST_RATE] =
        parsedSubtitles[FIELD_INST_RATE];
      this.preferences[NVG_MAP_INDEX.SPEED] = parsedSubtitles[FIELD_SPEED];
      this.preferences[NVG_MAP_INDEX.OVERLAP] = parsedSubtitles[FIELD_OVERLAP];
      if (parsedSubtitles[FIELD_ALTIMETRY]) {
        this.preferences[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] =
          parsedSubtitles[FIELD_ALTIMETRY];
        this.updateTrailColor(NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION);
      }
      this.updateTrailColor(NVG_MAP_INDEX.INST_RATE);
      this.updateTrailColor(NVG_MAP_INDEX.SPEED);
      this.updateTrailColor(NVG_MAP_INDEX.OVERLAP);
      const unitsArray = this._getSubtitleUnitsArray('metric');
      this._convertSubtitles(unitsArray);
    } else {
      this.setDefaultSubtitle();
      this.setDefaultSubtitleIsobus();
    }
  }

  showInfoWindow(
    lat: number,
    long: number,
    infowindow: { open: (marker: MapMarker) => void },
    marker: MapMarker,
  ) {
    if (infowindow) {
      this.guideInfoWindowPoint.lat = lat;
      this.guideInfoWindowPoint.long = long;

      if (this.lastInfoWindow) {
        this.lastInfoWindow.close();
      }
      this.lastInfoWindow = infowindow;
      infowindow.open(marker);
    }
  }

  showMarkerInfoWindow(
    markerInfowindow: { open: (marker: MapMarker) => void },
    marker: MapMarker,
  ) {
    if (this.lastInfoWindow) {
      this.lastInfoWindow.close();
    }
    this.lastInfoWindow = markerInfowindow;
    markerInfowindow.open(marker);
  }

  setStartPinLocation(storageData: any) {
    if (storageData && 'start_location' in storageData) {
      this.startPin[0].lat = storageData.start_location.latitude;
      this.startPin[0].lng = storageData.start_location.longitude;
    } else {
      this.startPin[0].lat = null;
      this.startPin[0].lng = null;
    }
  }

  mapReady(map) {
    const greenFlag = this.eleRef.nativeElement.querySelector(
      '.flag-green-start-work-result',
    );
    if (greenFlag !== null) greenFlag.style.marginBottom = '45px';

    this.map = map;
    this.boundBoxLoadingIntervalRef = setInterval(() => {
      if (this.boundingBox.length > 0 && !this.mapOneLoaded) {
        this.setMapBounds();
        map.fitBounds(this.bounds);
        this.mapOneLoaded = true;
        this.cdr.markForCheck();
        this.mapZoom = map.zoom;
        this.loadingAgresSpinner.toHide();
        clearInterval(this.boundBoxLoadingIntervalRef);
      }
    }, 25);
  }

  mapReadySegundo(map) {
    const greenFlag = this.eleRef.nativeElement.querySelector(
      '.flag-green-start-work-result1',
    );
    if (greenFlag !== null) greenFlag.style.marginBottom = '45px';
    this.map1 = map;

    const boundBoxLoadingIntervalRef = setInterval(() => {
      if (this.mapOneLoaded && !this.mapTwoLoaded) {
        map.fitBounds(this.map.getBounds());
        this.mapTwoLoaded = true;
        map.setZoom(this.map.zoom);
        this.loadingAgresSpinner.toHide();
        clearInterval(boundBoxLoadingIntervalRef);
      }
    }, 25);
  }

  splitMap() {
    if (this.splitScreen === false) {
      this.mapTwoLoaded = false;
      this.splitScreen = true;
      $('#mapa-1')
        .removeClass('col-sm-12 slideOutMap-1')
        .addClass('col-6 slideInMap-1');
      $('#mapa-2')
        .addClass('col-6 slideMap-2')
        .css({ opacity: '0', display: 'block' });
      const indexMap1 = $('.mapType1 option:selected').index();
      if (indexMap1 === 0) {
        $('.mapType2 option').eq(1).prop('selected', true);
        this.mapa1 = NVG_MAP_INDEX.SPEED;
      } else {
        $('.mapType2 option').eq(0).prop('selected', true);
        this.mapa1 = NVG_MAP_INDEX.INST_RATE;
      }

      setTimeout(() => {
        $('#mapa-2').css('opacity', '1');
        $('#mapa-2').css('display', 'block');
        $('.select-map-2').css('display', 'block');
      }, 500);
    } else {
      this.mapTwoLoaded = false;
      this.splitScreen = false;
      $('#mapa-1')
        .removeClass('col-sm-6 slideInMap-1')
        .addClass('col-sm-12 slideOutMap-1');
      $('#mapa-2, .select-map-2').css('display', 'none');
    }
  }

  keyTotranslationKey: Record<string, string> = {
    [FIELD_INST_RATE]: 'application-rate',
    [FIELD_SPEED]: 'map-speed',
    [FIELD_ALTIMETRY]: 'map-altimetry',
    [FIELD_OVERLAP]: 'map-transgression',
    'application-rate': FIELD_INST_RATE,
    'map-speed': FIELD_SPEED,
    'map-altimetry': FIELD_ALTIMETRY,
    'map-transgression': FIELD_OVERLAP,
  };
  getAvailableRasters(): Partial<ReportImages> {
    const availableImages = structuredClone(this.mapImages);
    const backgroundImages = availableImages?.backgroundImages ?? {};
    const rasterImages = availableImages?.rasterImages ?? {};
    delete backgroundImages['guides'];
    for (const key in backgroundImages) {
      const selectedDeviceImagePath = key.split(`${this.selectedDevice}/`);
      const otherDeviceImagePath = key.split('/');
      if (!rasterImages[key]) {
        delete backgroundImages[key];
        continue;
      }
      if (
        !this.keyTotranslationKey[key] &&
        selectedDeviceImagePath.length === 2 &&
        selectedDeviceImagePath[0] === ''
      ) {
        backgroundImages[selectedDeviceImagePath[1]] = backgroundImages[key];
        delete backgroundImages[key];
        continue;
      } else if (otherDeviceImagePath.length === 2) {
        delete backgroundImages[key];
      }
      if (otherDeviceImagePath.length === 2 && otherDeviceImagePath[0] === '') {
        delete backgroundImages[key];
      }
    }
    if (Object.keys(backgroundImages).length > 0) {
      this.cdr.markForCheck();
      if (!this.selectedMapImage.value) {
        const firstBackgroundImageKey = Object.keys(
          backgroundImages,
        )[0] as 'speed';
        this.selectedMapImage.value = backgroundImages[firstBackgroundImageKey];
        this.selectedMapImage.key = firstBackgroundImageKey;
      }
    }

    return backgroundImages;
  }

  getTranslationKey(rasterKey: unknown): string {
    if (typeof rasterKey === 'string') {
      return this.keyTotranslationKey[rasterKey]
        ? 'tasks.' + this.keyTotranslationKey[rasterKey]
        : 'tasks.map-application-isobus-' + rasterKey;
    }
    return 'tasks.map-application-isobus-default';
  }
  compareMapOptionsOnSelect(
    c1: {
      key: RasterType<string>;
      value: string;
    },
    c2: { key: RasterType<string>; value: string },
  ): boolean {
    return c1 && c2 ? c1.key === c2.key : c1 === c2;
  }

  shouldShowLegend(selectedMap: '0' | '1' = '0'): boolean {
    const excludedTypes: Set<string> = new Set([
      'outros',
      'colheita',
      'navegacao',
      'altitude',
    ]);
    if (this.trabalho?.hasOwnProperty('seeding_license')) {
      if (this.trabalho.seeding_license === false) {
        excludedTypes.add('plantio');
      }
    }

    return (
      !excludedTypes.has(this.operation_type) ||
      this[`mapa${selectedMap}`] === NVG_MAP_INDEX.SPEED ||
      this[`mapa${selectedMap}`] ===
        NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION ||
      this[`mapa${selectedMap}`] === NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY
    );
  }

  formatPaths(
    paths: { lat: number | string; long: number | string }[],
  ): { lat: number; lng: number }[] {
    return paths
      .map((path) => ({
        lat: parseFloat(String(path.lat).trim()),
        lng: parseFloat(String(path.long).trim()),
      }))
      .filter((path) => !isNaN(path.lat) && !isNaN(path.lng));
  }

  setTranslateFieldsOnMap(): void {
    this.translateService
      .get(['tasks.inicio', 'tasks.fim'])
      .subscribe((res: { ['tasks.inicio']: string; ['tasks.fim']: string }) => {
        this.labelInicio = res['tasks.inicio'];
        this.labelFim = res['tasks.fim'];
      });

    this.translateService
      .get('tasks.tabs.title-stage')
      .subscribe((resEtapa: string) => {
        this.labelEtapa = resEtapa;
        const startPinLength = this.startPin?.length;
        if (this.startPin != null && startPinLength > 0) {
          let indiceEtapa = 1;
          for (let index = 0; index < startPinLength; index++) {
            this.startPin[index].title = `${resEtapa} ${indiceEtapa++}`;
          }
        }
      });
  }

  splitMapOnRecommendationMapLoading() {
    this.mapa0 = NVG_MAP_INDEX.INST_RATE;
    this.tipo1MapaEscolhido = this.taskMapTypes[this.mapa0];

    this.mapa1 = this.hasAltimetryMap
      ? NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY
      : NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION;
    this.tipo2MapaEscolhido = this.taskMapTypes[this.mapa1];

    if (this.splitScreen === false) {
      this.splitScreen = true;
      $('#mapa-1')
        .removeClass('col-12 slideOutMap-1')
        .addClass('col-6 slideInMap-1');
      $('#mapa-2')
        .addClass('col-6 slideMap-2')
        .css({ opacity: '0', display: 'block' });

      setTimeout(() => {
        $('#mapa-2').css({ opacity: '1', display: 'block' });
        $('.mapType2 option').eq(this.mapa1).prop('selected', true);
        $('.mapType1 option').eq(this.mapa0).prop('selected', true);
      }, 500);
    } else {
      setTimeout(() => {
        $('.mapType2 option').eq(this.mapa1).prop('selected', true);
        $('.mapType1 option').eq(this.mapa0).prop('selected', true);
      }, 500);
    }
  }

  updateMapCenterAndZoom(map, changedMap: 0 | 1) {
    if (changedMap === 0 && this.isMap1BeingEdited && !this.isMap2BeingEdited) {
      if (this.map1) {
        this.map1.fitBounds(map.getBounds());
        this.map1.setZoom(map.zoom);
      }
    }
    if (changedMap === 1 && this.isMap2BeingEdited && !this.isMap1BeingEdited) {
      this.map.fitBounds(map.getBounds());
      this.map.setZoom(map.zoom);
    }
  }

  _createTurfShapeFilePolygons(polygonsOptionKeys) {
    const polygons = [];

    this.trailsM1
      .slice(0, polygonsOptionKeys.length)
      .forEach((trail, trailIndex) => {
        if (trail && Array.isArray(trail)) {
          trail.forEach((e: Record<string, any>, index) => {
            const turfPolygonArray = [];
            e.paths.forEach((coordinate) => {
              turfPolygonArray.push([coordinate.lng, coordinate.lat]);
            });
            if (
              turfPolygonArray[0][0] !==
                turfPolygonArray[e.paths.length - 1][0] ||
              turfPolygonArray[0][1] !== turfPolygonArray[e.paths.length - 1][1]
            ) {
              const lastCoordinate = [e.paths[0].lng, e.paths[0].lat];
              turfPolygonArray.push(lastCoordinate);
            }
            polygons.push(
              turf.polygon([turfPolygonArray], {
                ID: index,
                [polygonsOptionKeys[trailIndex].key]:
                  this.convertUnitPipe.convertValueToSupportedMapUnits(
                    e.valueOperation,
                    polygonsOptionKeys[trailIndex].unit,
                  ),
              }),
            );
          });
        }
      });
    return polygons;
  }

  loadTranslateWords() {
    this.setTranslateFieldsOnMap();
  }

  changeLanguage() {
    this.translateService.onLangChange.subscribe(() => {
      this.setTranslateFieldsOnMap();
    });
  }

  createRecommendedTrail(shapefileArray, propertyToUse) {
    const mapFilterByProperty = shapefileArray.filter(
      (polygon) => polygon.properties[propertyToUse],
    );
    const clearTrail = (trailToClear) => {
      if (trailToClear) trailToClear.length = 0;
    };
    const customTrail = [];
    mapFilterByProperty.forEach((polygon) => {
      const convertedPolygon = this._convertTurfPolygonToCustomWithColor(
        polygon,
        propertyToUse,
      );
      customTrail.push(convertedPolygon);
    });
    const recommendationMapIndex = this.hasAltimetryMap
      ? NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY
      : NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION;
    Object.assign(
      this.preferences[recommendationMapIndex],
      this.preferences[NVG_MAP_INDEX.INST_RATE],
    );
    this.trailsM1[recommendationMapIndex] = customTrail;
    clearTrail(this.trailsM2[recommendationMapIndex]);
    Object.assign(
      this.trailsM2[recommendationMapIndex],
      this.trailsM1[recommendationMapIndex],
    );
    this.taskMapTypes[recommendationMapIndex] = 'map-recommendation';
    this.splitMapOnRecommendationMapLoading();

    this._checkDetectChanges();
  }

  _setMarcadoresArray(t: {
    marcadores: any;
    guias: {};
    boundaries: string | any[];
  }) {
    this.marcadoresArrayL = [];
    const markers = t.marcadores;
    delete markers['temperaturePoints'];

    const markersObj = {
      pausePointsInit: TIPO_MARCADORES.PAUSE_POINT,
      pausePointsEnd: TIPO_MARCADORES.PAUSE_END_POINT,
      stopPoints: TIPO_MARCADORES.STOP_POINT,
      exceededTemperaturePoints: TIPO_MARCADORES.TEMPERATURE_EXCEEDED_POINT,
      powerPoint: TIPO_MARCADORES.POWER_POINT,
    };

    if (Object.keys(t.guias).length > 0) {
      this.marcadoresArrayL.push(
        this._createMarkerObject(
          TIPO_MARCADORES.GUIDES,
          'tasks.guide.label-guide',
          true,
          false,
        ),
      );
    }

    if (Object.keys(markers).length > 0) {
      this.marcadoresArrayL.push(
        this._createMarkerObject(
          TIPO_MARCADORES.MARKERS,
          'tasks.markers',
          true,
          false,
        ),
      );

      Object.keys(markersObj).forEach((marker) => {
        if (this._hasMarkerOfType(markers, marker)) {
          markers[marker] = markers[marker].map((obj) => ({
            ...obj,
            show: true,
          }));

          this.marcadoresArrayL.push(
            this._createMarkerObject(
              markersObj[marker],
              'tasks.marker.type.' + markersObj[marker],
              true,
              true,
            ),
          );
        }
      });
    }

    if ('boundaries' in t && t.boundaries.length > 0) {
      this.marcadoresArrayL.push(
        this._createMarkerObject(
          TIPO_MARCADORES.BOUNDARIES,
          'tasks.boundaries',
          true,
          false,
        ),
      );
    }
  }

  _getSIPreferenceSubtitleUnit(mapSelected: number) {
    return (
      {
        0: this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
        1: 'km/h',
        2: this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
        3: this.hasAltimetryMap
          ? 'm'
          : this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
        4: this.hasAltimetryMap
          ? this._getSIPreferenceSubtitleUnitByOperation(this.operation_type)
          : 'm',
      }[mapSelected] || ''
    );
  }

  addColorIfMissing(trailData: Array<any>, color: string) {
    trailData.forEach((item) => {
      if (!item.color) {
        item.color = color;
      }
    });
  }

  createNvgTrail(trails: { [key: string]: Array<any> }) {
    if (trails[FIELD_INST_RATE] !== undefined)
      this.trailsM1[NVG_MAP_INDEX.INST_RATE] = trails[FIELD_INST_RATE];
    if (trails?.[FIELD_SPEED] !== undefined)
      this.trailsM1[NVG_MAP_INDEX.SPEED] = trails[FIELD_SPEED];
    if (trails[FIELD_OVERLAP] !== undefined)
      this.trailsM1[NVG_MAP_INDEX.OVERLAP] = trails[FIELD_OVERLAP];
    if (trails[FIELD_ALTIMETRY] !== undefined)
      this.trailsM1[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] =
        trails[FIELD_ALTIMETRY];
    if (
      this.trailsM1[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] &&
      (this.trailsM1[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] as Array<any>)
        .length > 0
    ) {
      this.taskMapTypes.push('map-altimetry');
      this.hasAltimetryMap = true;
    }
  }

  createIsobusTrails(trails: { [key: string]: Array<any> }) {
    this.trailsM1[0] = trails[FIELD_INST_RATE];
  }
  _getCoordinatesFromBoundaries(
    boundariesArray: Boundary[] | null,
  ): turf.Feature<turf.Point>[] {
    if (!boundariesArray || boundariesArray.length === 0) {
      return [];
    }

    return boundariesArray.flatMap((boundary) =>
      boundary.path.map(({ lng, lat }) => turf.point([lng, lat])),
    );
  }

  _getStartAndEndPointCoordinatesFromAllSteps(
    stepArray: Array<{ [key: string]: any }>,
  ) {
    if (stepArray && stepArray.length > 0) {
      return stepArray
        .map((e) => {
          return [
            turf.point([e.start_location.longitude, e.start_location.latitude]),
            turf.point([e.end_location.longitude, e.end_location.latitude]),
          ];
        })
        .flat(2);
    }
    return [];
  }

  _getFirstAndLastPointCoordinatesFromEachGuide(guideArray) {
    if (guideArray && guideArray.length > 0) {
      return guideArray
        .map((gui) => {
          if (gui.path.length > 0) {
            return [
              turf.point([gui.path[0].long, gui.path[0].lat]),
              turf.point([
                gui.path[gui.path.length - 1].long,
                gui.path[gui.path.length - 1].lat,
              ]),
            ];
          }
          return [];
        })
        .flat(2);
    }
    return [];
  }

  _getCoordinateFromEachMarker(markersObj) {
    if (markersObj) {
      return Object.values(markersObj)
        .map((markerCatArr) =>
          (markerCatArr as Array<any>).map((marker) =>
            turf.point([marker.location.longitude, marker.location.latitude]),
          ),
        )
        .reduce((acc, val) => acc.concat(val), []);
    }
    return [];
  }

  getPreferenceSubtitle(): [string, string] {
    const legendByOperation: string = this._getPreferenceSubtitleByOperation(
      this.operation_type,
    );

    const preferencesLegends = {
      0: legendByOperation,
      1: SUBTITLE_PREFERENCES.SPEED,
      2: legendByOperation,
      3: this.hasAltimetryMap
        ? SUBTITLE_PREFERENCES.ALTITUDE
        : legendByOperation,
      4: this.hasAltimetryMap,
    };
    return [
      preferencesLegends[this.mapa0] || '',
      preferencesLegends[this.mapa1] || '',
    ];
  }

  saveTx() {
    let legendDataToSave = this.prepareLegendDataToSave();
    const currentUnitSystem = this.convertUnitPipe.getCurrentUnitSystem();
    this._convertLegendsToSI(legendDataToSave, currentUnitSystem);

    if (this.preferencesChanged(legendDataToSave)) {
      this.seeButtonSave = false;
      this.saveLegendData(legendDataToSave);
      for (let i = 0; i < 4; i++) {
        this.updateTrailColor(i);
      }
      const unitsArray = this._getSubtitleUnitsArray(currentUnitSystem);
      this._convertSubtitles(unitsArray);
      this.translateService
        .get('monitoring.maps.saved-caption')
        .subscribe((res: string) => {
          this.alertService.success(res, TimeoutEnum.Short);
          this.closeModalEditLegendMap1();
          this.closeModalEditLegendMap2();
        });
    }
  }
  saveLegendData(legendDataToSave: {
    inst_rate: [number, number, number, number, number];
    speed: [number, number, number, number, number];
    overlap: [number, number, number, number, number];
    altitude: [number, number, number, number, number];
  }) {
    localStorage.setItem(
      USER_MAP_COLOR + this.taskName,
      JSON.stringify(legendDataToSave),
    );
  }
  preferencesChanged(legendData: any): boolean {
    return (
      localStorage.getItem(USER_MAP_COLOR + this.taskName) !==
      JSON.stringify(legendData)
    );
  }

  operationsFields() {
    if (!this.hasField) {
      const center = this.trails.speed[0]?.paths[0];
      this.router.navigate(['/my-fields/criar-talhao'], {
        queryParams: {
          center: JSON.stringify(center),
          fieldName: this.trabalho.crop_name,
        },
      });
    } else {
      this.router.navigate([`/my-fields/ver-talhao/${this.fieldId}`]);
    }
  }

  fetchField() {
    const cropName = this.trabalho.crop_name
      .replace(/\s+/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    this.operationsFieldsTitle = 'fields.create';
    this.userTalhoesService.getAllTalhoes().subscribe(
      (talhoes) => {
        if (talhoes) {
          talhoes.forEach((field) => {
            const fieldName = field.name
              .replace(/\s+/g, '_')
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase();

            if (cropName === fieldName) {
              this.hasField = true;
              this.operationsFieldsTitle = 'fields.open-field';
              this.fieldId = field.id;
            }
          });
        }
        this.fieldButton = true;
      },
      () => {
        this.fieldButton = true;
      },
    );
  }

  private convertSeriesData(data: number[], unit: string): number[] {
    return data.map((value) =>
      parseFloat(this.convertUnitPipe.transform(value, unit)),
    );
  }

  private translateWordsGraphicSprayRate() {
    this.translateService
      .get([
        'tasks.graphic-spray-rate-rate',
        'tasks.graphic-spray-rate-tagert-rate',
        'tasks.graphic-spray-rate-speed',
        'tasks.graphic-spray-rate-section-state',
        'tasks.graphic-spray-rate-section',
        'tasks.graphic-spray-rate-pressure',
        'tasks.graphic-spray-rate-flow',
        'tasks.graphic-spray-rate-time',
        'tasks.graphic-spray-rate-download-button',
        'tasks.graphic-spray-rate-reset-button',
      ])
      .subscribe((res: { [key: string]: string }) => {
        this.graphStringTax = res['tasks.graphic-spray-rate-rate'];
        this.graphStringTargetTax = res['tasks.graphic-spray-rate-tagert-rate'];
        this.graphStringSpeed = res['tasks.graphic-spray-rate-speed'];
        this.graphStringSection = res['tasks.graphic-spray-rate-section'];
        this.graphStringSectionState =
          res['tasks.graphic-spray-rate-section-state'];
        this.graphStringPressure = res['tasks.graphic-spray-rate-pressure'];
        this.graphStringFlow = res['tasks.graphic-spray-rate-flow'];
        this.graphStringTime = res['tasks.graphic-spray-rate-time'];
        this.graphStringDownloadButton =
          res['tasks.graphic-spray-rate-download-button'];
        this.graphStringResetButton =
          res['tasks.graphic-spray-rate-reset-button'];
        this.updateChartOptions();
      });
  }

  private updateChartOptions() {
    if (!this.sprayRateTask?.graph) {
      console.warn(
        'spray Rate Task has not been loaded yet. update chart options aborted.',
      );
      return;
    }

    const isMobile = window.innerWidth <= 640;

    this.chartOptions = {
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: [
          this.graphStringTax,
          this.graphStringTargetTax,
          this.graphStringSectionState,
          this.graphStringSpeed,
          this.graphStringPressure,
        ],
        type: 'scroll',
        orient: 'horizontal',
        top: 0,
        bottom: 10,
        left: 'center',
        itemGap: isMobile ? 15 : 35,
        textStyle: {
          fontSize: isMobile ? '12' : '16',
          fontWeight: 'normal',
          color: 'hsl(270, 1%, 31%)',
        },

        selected: {
          Taxa: true,
          'Taxa Alvo': true,
          'Estado de Seção': false,
          Velocidade: false,
          Pressão: false,
          Vazão: false,
        },
      },
      xAxis: {
        show: true,
        type: 'category',
        data: this.generateTimeLabels(this.sprayRateTask.graph.xData),
        boundaryGap: false,
        name: this.graphStringTime,
      },
      yAxis: [
        {
          type: 'value',
          name: this.convertUnitPipe.transformUnit('l/ha'),
          position: 'left',
          offset: 0,
        },
        {
          type: 'value',
          name: this.graphStringSection,
          position: 'left',
          offset: 50,
        },
        {
          type: 'value',
          name: this.convertUnitPipe.transformUnit('km/h'),
          position: 'right',
          offset: 50,
        },
        {
          type: 'value',
          name: 'bar',
          position: 'right',
          offset: 0,
        },
      ],
      dataZoom: [
        { type: 'slider', show: true, start: 0, end: 100 },
        { type: 'inside', start: 0, end: 100 },
      ],
      series: [
        {
          name: this.graphStringTax,
          type: 'line',
          yAxisIndex: 0,
          data: this.sprayRateTask.graph.instRate,
          smooth: true,
        },
        {
          name: this.graphStringTargetTax,
          type: 'line',
          yAxisIndex: 0,
          data: this.sprayRateTask.graph.targetRate,
          smooth: true,
        },
        {
          name: this.graphStringSectionState,
          type: 'line',
          yAxisIndex: 1,
          data: this.sprayRateTask.graph.secState,
          step: 'start',
          smooth: true,
        },
        {
          name: this.graphStringSpeed,
          type: 'line',
          yAxisIndex: 2,
          data: this.sprayRateTask.graph.speed,
          smooth: true,
        },
        {
          name: this.graphStringPressure,
          type: 'line',
          yAxisIndex: 3,
          data: this.sprayRateTask.graph.pressure,
          smooth: true,
        },
      ],
    };
  }

  eventNvgSelect(taskId: string) {
    this.translateService
      .get('global.loading.operation')
      .subscribe((res: string) => {
        this.loadingAgresSpinner.toShow(res);
      });

    this.nvgStorageService.getStorageData(taskId).then((taskIsobusType) => {
      if (
        taskIsobusType.hasOwnProperty('isISOBUS') &&
        taskIsobusType.isISOBUS === true
      ) {
        const mapImagesOptions = {};
        for (const key in taskIsobusType.devices) {
          mapImagesOptions[
            `${taskIsobusType.devices[key].deviceDesignator}/applicationRateLiquid`
          ] = 1;
          mapImagesOptions[
            `${taskIsobusType.devices[key].deviceDesignator}/applicationRateSolid`
          ] = 1;
          mapImagesOptions[
            `${taskIsobusType.devices[key].deviceDesignator}/applicationRateSeed`
          ] = 1;
        }

        this.isIsobusData = true;
        this.taskIsobusType = taskIsobusType;
        this.devicesIsobus = taskIsobusType.devices;
        this.trabalho = taskIsobusType.trabalho;
        this.guides = taskIsobusType.guias.map((obj) => ({
          ...obj,
          show: true,
        }));
        this.marcadores = taskIsobusType.marcadores;
        this.trails = taskIsobusType.trails;
        this.subtitles = taskIsobusType.subtitles;
        this.tempoEfetivoOperacao = taskIsobusType?.trabalho?.tot_op_e;
        this.tempoTotalOperacao = taskIsobusType?.trabalho?.tot_op_t;
        this.tempoOciosoOperacao = taskIsobusType?.trabalho?.tot_op_o;
        this.percentTotalOperation = taskIsobusType?.trabalho?.tot_op_o_percent;
        this.altimetryPoints = taskIsobusType.altimetryPoints;
        this.totalObjects = taskIsobusType.totals || [];
        this.stageInfoIsobus = taskIsobusType.stageInfo;
        this.stageQuantity = taskIsobusType.trabalho.qtde_etapas;
        this.stageInfoIsobusStartTime = this.stageInfoIsobus[0].startTime;

        if (this.stageInfoIsobus.length > 0) {
          const lastStopTime =
            this.stageInfoIsobus[this.stageInfoIsobus.length - 1].stopTime;
          this.stageInfoIsobusStopTime = lastStopTime;
        }

        this.stageIsobusData = taskIsobusType.etapa || [];
        if (this.stageIsobusData.length > 0) {
          this.setStartPinLocation(this.stageIsobusData[0]);
        }

        this.stageIsobusMergedData = this.stageIsobusData;

        const deviceModel = AppConstants.DEVICE_MODEL;

        const currentDevcieModel = taskIsobusType?.trabalho?.model;
        if (currentDevcieModel === deviceModel.AGRONAVEPRO.name) {
          this.distanceUnit = 'm';
        } else {
          this.distanceUnit = 'km';
        }

        this.firstGuideIndex = this.guides.findIndex(
          (guide) => guide.type !== this.guideTypeEnum.PROJECT,
        );

        this.mapLat = 0;
        this.mapLng = 0;
        this.mapZoom = 3;
        this.taskName = taskId;

        const trails = taskIsobusType.trails;
        let trailsPathsInIsobusTask = Object.values(trails).every(
          (arr) => Array.isArray(arr) && arr.length === 0,
        );

        if (trailsPathsInIsobusTask === false) {
          this._fitMapBoundsNvg();
        }

        this.createNvgTrail(this.trails);

        this.setNvgSubtitle(this.taskName);
        this.loadTranslateWords();
        this.extractDeviceNames();
        this.setDefaultDevice();
        this.updateFilteredData();
        this.showAlerts();
      } else {
        this.taskData = taskIsobusType;
        this.trabalho = this.taskData.trabalho;
        this.guias = this.taskData.guias;
        this.marcadores = this.taskData.marcadores;
        this.etapa = this.taskData.etapa;
        this.trails = this.taskData.trails;
        this.subtitles = this.taskData.subtitles;
        this.altimetryPoints = this.taskData.altimetryPoints;
        this.boundaries = this.taskData.boundaries || this.boundaries;

        this.mapLat = 0;
        this.mapLng = 0;
        this.mapZoom = 2;
        this.nvgStorageData = this.taskData;
        this.taskName = taskId;
        this.loadTranslateWords();
        this.firstLoad = true;

        this.operationType = this.trabalho.operation as string;

        const deviceModel = AppConstants.DEVICE_MODEL;
        const currentDevcieModel = this.taskData?.trabalho?.model;
        if (currentDevcieModel === deviceModel.AGRONAVEPRO.name) {
          this.distanceUnit = 'm';
        } else {
          this.distanceUnit = 'km';
        }
        this.operation_type = this.taskData?.trabalho?.operation;
        if (this.operation_type === OPERATION.NAVEGACAO) {
          this.taskMapTypes.shift();
          this.taskMapTypes.unshift('map-navigation');
        }

        this.tempoEfetivoOperacao = this.taskData?.trabalho?.tot_op_e;
        this.tempoTotalOperacao = this.taskData?.trabalho?.tot_op_t;
        this.tempoOciosoOperacao = this.taskData?.trabalho?.tot_op_o;

        this.etapasArr = [];

        this.taskData.etapa?.forEach((et: any) => {
          this.etapasArr.push(et);
        });

        this.etapas = this.etapasArr[0];
        this.timestamp_etapa_selecionada = this.etapas?.timestamp;
        this.endTimestamp_etapa_selecionada = this.etapas?.endTimestamp;
        this.createNvgTrail(this.trails);

        if (
          Array.isArray(this.trailsM1[NVG_MAP_INDEX.INST_RATE]) &&
          (this.trailsM1[NVG_MAP_INDEX.INST_RATE] as Array<any>).length > 0
        ) {
          this.setStartPinLocation(this.etapa[0]);
        }

        this.setNvgSubtitle(this.taskName);
        this.guides = this.guias;
        this.guides = this.guides.map((obj) => ({ ...obj, show: true }));
        this._setMarcadoresArray(this.taskData);

        this.firstGuideIndex = this.guides.findIndex(
          (guide) => guide.type !== this.guideTypeEnum.PROJECT,
        );

        //SPRAY RATE
        if (
          taskIsobusType.hasOwnProperty('graph') &&
          taskIsobusType.trabalho.model === 'spray-rate'
        ) {
          this.isSprayRateTask = true;
          this.sprayRateTask = taskIsobusType;
          this.translateWordsGraphicSprayRate();
          const trails = taskIsobusType.trails;
          let trailsPathsSprayRate = Object.values(trails).every(
            (arr) => Array.isArray(arr) && arr.length === 0,
          );
          if (trailsPathsSprayRate) {
            this.showMapSprayRate = false;
          } else {
            this._fitMapBoundsNvg();
            this.showMapSprayRate = true;
          }
        }
        const trails = taskIsobusType.trails;
        let trailsPathsInNvgTask = Object.values(trails).every(
          (arr) => Array.isArray(arr) && arr.length === 0,
        );

        if (trailsPathsInNvgTask) {
          console.warn('not exist paths lat long in nvg task');
        } else {
          this._fitMapBoundsNvg();
        }

        this.loadingAgresSpinner.toHide();
      }
      if (this.guides?.length > 0) {
        this.guideSelect(0);
      }
      this.loadingAgresSpinner.toHide();
      this.taskSummaryBoxValue = this.taskSummaryBoxApplyedValue();
      this.fetchField();
    });
  }

  private updateMobileMapImages(mapImages: ReportImages) {
    if (this.mapImages?.backgroundImages && this.mapImages?.rasterImages) {
      this.mapImages.backgroundImages = {
        ...this.mapImages?.backgroundImages,
        ...mapImages.backgroundImages,
      };
      this.mapImages.rasterImages = {
        ...this.mapImages?.rasterImages,
        ...mapImages.rasterImages,
      };
    } else if (
      !this.mapImages?.backgroundImages &&
      !this.mapImages?.rasterImages
    ) {
      this.mapImages = {
        backgroundImages: mapImages.backgroundImages,
        rasterImages: mapImages.rasterImages,
      };
    }
  }

  extractDeviceNames() {
    const devices = this.taskIsobusType.devices;
    this.deviceNames = devices.map((device) => ({
      name: device.deviceDesignator,
      serial: device.deviceSerialNumber,
      deviceStructureLabel: device.deviceStructureLabel,
    }));
  }

  setDefaultDevice() {
    if (this.devicesIsobus && this.devicesIsobus.length > 0) {
      this.selectedDevice =
        this.devicesIsobus[0].deviceDesignator +
        '-' +
        this.devicesIsobus[0].deviceStructureLabel;
      this.setDefaultSubtitleIsobus();
      this.updateFilteredData();
      this.updateFilteredDataStageInfoEcusIsobus();
      this.updateTrailsAndSubtitleIsobus(this.selectedDevice);
    }
  }

  updateTrailsAndSubtitleIsobus(_selectedDeviceName: string) {
    for (const rate of this.applicationRates) {
      if (this.trails[this.selectedDevice]?.hasOwnProperty(rate)) {
        this.foundApplicationRate = rate;

        break;
      }
    }

    if (this.foundApplicationRate) {
      this.trails['inst_rate'] =
        this.trails[this.selectedDevice][this.foundApplicationRate][0];
      this.subtitles[NVG_MAP_INDEX.INST_RATE] =
        this.subtitles[this.foundApplicationRate];
    }
    for (const overlap of this.overlapsIsobus) {
      if (this.trails[this.selectedDevice]?.hasOwnProperty(overlap)) {
        this.foundOverlapIsobus = overlap;

        break;
      }
    }

    if (this.foundOverlapIsobus) {
      this.trails['overlap'] =
        this.trails[this.selectedDevice][this.foundOverlapIsobus][0];
      this.subtitles[NVG_MAP_INDEX.OVERLAP] =
        this.subtitles[this.foundOverlapIsobus];
    }
    this.createNvgTrail(this.trails);
    this.setNvgSubtitle(this.taskName);
  }

  onDeviceNameChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedDevice = selectElement.value;

    this.updateFilteredData();
    this.updateFilteredDataStageInfoEcusIsobus();
    this.updateTrailsAndSubtitleIsobus(this.selectedDevice);
    this.setDefaultSubtitleIsobus();
    this.setNvgSubtitle(this.taskName);
    this._fitMapBoundsNvg();

    if (this.stageIsobusMergedData.length > 0) {
      this.updateStageDataForSelectedDevice(0);
    }
    this.availableRasters = this.getAvailableRasters();
    if (this.selectedMapImage.key.includes('applicationRate')) {
      const curretnApplicationRate = Object.keys(this.availableRasters).find(
        (e) => e.includes('applicationRate'),
      );
      this.selectedMapImage.key = curretnApplicationRate;
    }
  }

  showAlerts() {
    const alerts = {
      'missing boom width': 'tasks.missing-boom-width',
      'missing state of sections': 'tasks.missing-state-of-sections',
    };
    if (this.taskIsobusType.alerts?.length > 0) {
      this.hasAlerts = true;
      this.taskIsobusType.alerts.forEach((item) => {
        this.alerts.push(alerts[item]);
      });
    }
  }
  updateFilteredData() {
    if (this.selectedDevice && this.totalObjects[this.selectedDevice]) {
      this.filteredTask = this.totalObjects[this.selectedDevice].task || [];
      this.filteredEcu = this.totalObjects[this.selectedDevice].ecu || [];
    } else {
      this.filteredTask = [];
      this.filteredEcu = [];
    }
    if (this.selectedDevice && this.totalObjects[this.selectedDevice]) {
      this.isobusOverlapPercentage = this.trabalho.overlap_info.find(
        (item:OverlapInfo) => Object.keys(item)[0] === this.selectedDevice,
      )?.[this.selectedDevice].overlap_percent;
    }
  }
  updateFilteredDataStageInfoEcusIsobus() {
    for (const stageInfo of this.stageInfoIsobus) {
      if (stageInfo.ecusData[this.selectedDevice]) {
        this.filteredEcusDataStageInfo =
          stageInfo.ecusData[this.selectedDevice];
        return;
      }
    }
    this.filteredEcusDataStageInfo = {
      implementWidth: 0,
      numberOfSections: 0,
      averageApplicationRate: [0, ''],
    };
  }
  updateStageDataForSelectedDevice(stageIndex: number) {
    const mergedData = this.stageIsobusMergedData[stageIndex];
    if (mergedData) {
      this.stageIsobusObjectData = mergedData;
    } else {
      this.stageIsobusObjectData = {
        implementWidth: 0,
        numberOfSections: 0,
        averageApplicationRate: [0, ''],
        appVersion: '000',
        machineName: 'isofarm',
        cropType: '',
        operator: 'nome',
        deviceId: 0,
        serialNumber: '00000',
        cover_area: 0,
        travel_dist: 0,
        avg_speed: 0,
        distanceFromAntennaToHitch: 0,
        implWidth: 0,
        timestamp: '',
        endTimestamp: '',
        startLocation: {
          latitude: 0,
          longitude: 0,
        },
        endLocation: {
          latitude: 0,
          longitude: 0,
        },
        startTime: '',
        stopTime: '',
      };
    }
  }

  getSelectedIndex(target: EventTarget | null): number {
    if (target instanceof HTMLSelectElement) {
      return target.selectedIndex;
    }
    return 0;
  }

  onChangeMap(selectedIndex: number, mapa: Map): void {
    if (this.taskMapTypes[selectedIndex] === 'map-speed') {
      this.isSpeedMap = true;
    } else {
      this.isSpeedMap = false;
    }
    if (isNaN(selectedIndex)) return;

    if (this.isMobileFallback(selectedIndex, mapa)) {
      this.applyMobileFallback();
      return;
    }

    if (mapa === 0) {
      this.handleMapZero(selectedIndex);
    } else if (mapa === 1) {
      this.handleMapOne(selectedIndex);
    }

    this['mapa' + mapa] = selectedIndex;
    this.updateSelectedMap2Value();
  }

  private isMobileFallback(selectedIndex: number, mapa: Map): boolean {
    return (
      this.viewportBreakpoints.mobile &&
      selectedIndex === undefined &&
      mapa === undefined
    );
  }

  private applyMobileFallback(): void {
    this.mapa0 = 0;
    if (this.selectedMapImage.key === FIELD_SPEED) {
      this.mapa0 = 1;
    } else if (this.selectedMapImage.key === FIELD_ALTIMETRY) {
      this.mapa0 = 2;
    } else if (this.selectedMapImage.key === FIELD_OVERLAP) {
      this.mapa0 = 3;
    }
  }

  private handleMapZero(selectedIndex: number): void {
    this.tipo1MapaEscolhido = this.taskMapTypes[selectedIndex];
    this.selectedMap1Value = selectedIndex;

    if (this.taskMapTypes[selectedIndex] !== 'map-speed') {
      this.selectedMap2Value = this.taskMapTypes.indexOf('map-speed');
    }

    if (this.taskMapTypes[selectedIndex] !== 'map-application') {
      this.selectedIsobusApplicationRate0 = null;
    } else if (!Array.isArray(this.trailsM1[0])) {
      this.selectedIsobusApplicationRate0 = Object.keys(this.trailsM1[0])[0];
    }
  }

  private handleMapOne(selectedIndex: number): void {
    if (this.taskMapTypes[selectedIndex] !== 'map-application') {
      this.selectedIsobusApplicationRate1 = null;
    }
    this.tipo2MapaEscolhido = this.taskMapTypes[selectedIndex];
    this.selectedMap2Value = selectedIndex;
  }

  updateSelectedMap2Value() {
    if (
      this.taskMapTypes[this.selectedMap1Value] === 'map-application' ||
      this.taskMapTypes[this.selectedMap1Value] === 'map-transgression'
    ) {
      this.selectedMap2Value = this.taskMapTypes.indexOf('map-speed');
    } else if (this.taskMapTypes[this.selectedMap1Value] === 'map-speed') {
      this.selectedMap2Value = this.taskMapTypes.indexOf('map-application');
    } else {
      this.selectedMap2Value = this.taskMapTypes.indexOf('map-application');
    }
  }

  NavigationReplay() {
    const REPLAY_SPEED = 250;
    const MAX_REPLAY_SPEED = 250 / 64;
    const MIN_REPLAY_SPEED = 250 * 4;
    let isPaused = false;
    let isPlaying = false;
    let initialized = false;
    let reproductionSpeedInMs = REPLAY_SPEED;
    let numberOfPolygons: number;
    let idx: number;
    let selectedMap: number;
    let timeout: NodeJS.Timeout;

    const play = async () => {
      if (!this.userId.includes('@agres.com.br')) {
        this.analyticsService.trackEvent(
          'Clicked the play button to replay',
          'Replay',
          this.userId,
        );
      }
      if (!initialized) {
        await _initialize();
      }
      if (isPaused) {
        _returnFromPause();
      } else {
        _play();
      }
    };

    const pause = () => {
      isPaused = true;
      isPlaying = false;
    };

    const stop = async () => {
      this.translateService
        .get('global.loading.replay-stopping')
        .subscribe((res: string) => {
          this.loadingAgresSpinner.toShow(res);
        });
      await delayToShowSpinnerCorrectly();
      terminate();
      _returnOriginalMap();
      this.loadingAgresSpinner.toHide();
    };

    const terminate = () => {
      clearTimeout(timeout);
      pause();
      _setInitialConditions();
    };

    const canPlay = () => {
      return !isPlaying;
    };

    const canStop = () => {
      return isPlaying || isPaused;
    };

    const speedUp = () => {
      if (reproductionSpeedInMs > MAX_REPLAY_SPEED) reproductionSpeedInMs /= 2;
    };

    const speedDown = () => {
      if (reproductionSpeedInMs < MIN_REPLAY_SPEED) reproductionSpeedInMs *= 2;
    };

    const stepOneForward = async () => {
      if (!initialized) {
        await _initialize();
      }
      pause();
      _draw(idx);
      idx++;
    };

    const stepOneBackwards = () => {
      if (initialized && idx > 0) {
        pause();
        idx--;
        _erase(idx);
      }
    };

    const isRunning = () => {
      return initialized;
    };

    const getCurrentReproductionSpeed = () => {
      return REPLAY_SPEED / reproductionSpeedInMs;
    };

    const _initialize = () => {
      return new Promise(async (resolve) => {
        this.translateService
          .get('global.loading.replay')
          .subscribe((res: string) => {
            this.loadingAgresSpinner.toShow(res);
          });
        await delayToShowSpinnerCorrectly();
        _prepareReplay();
        _setReplayScreenMode();
        _setAllPolygonsToInvisible();
        initialized = true;
        this.loadingAgresSpinner.toHide();
        resolve('');
      });
    };

    const delayToShowSpinnerCorrectly = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve('');
        }, 50);
      });
    };

    const _treatNonReplayMaps = () => {
      if (selectedMap > 1) {
        selectedMap = 0;
        this._selectMap(selectedMap);
        this.translateService
          .get('tasks.replay.just-available')
          .subscribe((res: string) => {
            this.toastr.info(res, '', { timeOut: 4000 });
          });
      }
    };

    const _prepareReplay = () => {
      selectedMap = $('.mapType1 option:selected').index();
      _treatNonReplayMaps();
      if (Array.isArray(this.trailsM1[selectedMap])) {
        numberOfPolygons = (this.trailsM1[selectedMap] as Array<any>).length;
      }
      _setInitialConditions();
    };

    const _setReplayScreenMode = () => {
      if (this.splitScreen === true) {
        this.splitMap();
      }
    };

    const _setAllPolygonsToInvisible = () => {
      if (Array.isArray(this.trailsM1[selectedMap])) {
        (this.trailsM1[selectedMap] as Array<any>).forEach(
          (polygon) => (polygon.visible = false),
        );
      }
      this._checkDetectChanges();
    };

    const _setInitialConditions = () => {
      isPaused = false;
      isPlaying = false;
      reproductionSpeedInMs = REPLAY_SPEED;
      idx = 0;
      initialized = false;
    };

    const _play = () => {
      isPlaying = true;
      _drawPolygonContinuously();
    };

    const _returnFromPause = () => {
      isPaused = false;
      _play();
    };

    const _returnOriginalMap = () => {
      if (Array.isArray(this.trailsM1[selectedMap])) {
        (this.trailsM1[selectedMap] as Array<any>).forEach(
          (poly) => (poly.visible = true),
        );
      }
      this._checkDetectChanges();
    };

    const _drawPolygonContinuously = () => {
      if (idx < numberOfPolygons) {
        timeout = setTimeout(() => {
          _draw(idx);
          idx++;
          if (!isPaused) _drawPolygonContinuously();
        }, reproductionSpeedInMs);
      } else {
        stop();
      }
    };

    const _draw = (index) => {
      this.trailsM1[selectedMap][index].visible = true;
      if (this.cdr.detectChanges) this._checkDetectChanges();
    };

    const _erase = (index) => {
      this.trailsM1[selectedMap][index].visible = false;
      this._checkDetectChanges();
    };

    const progressBarClick = (value) => {
      pause();
      _setAllPolygonsToInvisible();
      if (Array.isArray(this.trailsM1[selectedMap])) {
        (this.trailsM1[selectedMap] as Array<any>)
          .slice(0, value)
          .forEach((poly) => (poly.visible = true));
      }
      this._checkDetectChanges();
      idx = value;
    };

    const getMaxIndex = () => {
      return numberOfPolygons - 1;
    };

    const getProgress = () => {
      return idx;
    };

    const fitMapAtStep = () => {
      const currentStep = this.trailsM1[selectedMap][idx];
      const centerPoint = _getCenterFromPaths(currentStep.paths);
      this.mapLng = centerPoint.geometry.coordinates[0];
      this.mapLat = centerPoint.geometry.coordinates[1];
      this.map.setCenter({ lat: this.mapLat, lng: this.mapLng });
      if (this.map1)
        this.map1.setCenter({ lat: this.mapLat, lng: this.mapLng });
    };

    const _getCenterFromPaths = (currentPaths: any[]) => {
      const points = [];
      currentPaths.forEach((path) => {
        points.push([path.lng, path.lat]);
      });
      points.push([currentPaths[0].lng, currentPaths[0].lat]);

      const turfCurrentStep = turf.polygon([points]);
      return turf.center(turfCurrentStep);
    };

    const getCurrentLocation = () => {
      const currentPaths = this.trailsM1[selectedMap][idx].paths;
      return currentPaths[currentPaths.length - 1];
    };

    return {
      play,
      pause,
      stop,
      terminate,
      canPlay,
      canStop,
      speedUp,
      speedDown,
      stepOneForward,
      stepOneBackwards,
      isRunning,
      getCurrentReproductionSpeed,
      getMaxIndex,
      getProgress,
      progressBarClick,
      fitMapAtStep,
      getCurrentLocation,
    };
  }
  _getRelevantPoints(
    operationPoints: Array<{ [key: string]: any }> | undefined,
  ) {
    let navigationPoints: turf.helpers.FeatureCollection<
      turf.helpers.Geometry,
      { [name: string]: any }
    >;

    if (this.trails[FIELD_SPEED].length > 0) {
      const minPoint = turf.point([180, 90]);
      const maxPoint = turf.point([-180, -90]);

      for (const trail of this.trails[FIELD_SPEED]) {
        for (const point of trail.paths) {
          if (point.lat > maxPoint.geometry.coordinates[1]) {
            maxPoint.geometry.coordinates[1] = point.lat;
          }

          if (point.lng > maxPoint.geometry.coordinates[0]) {
            maxPoint.geometry.coordinates[0] = point.lng;
          }

          if (point.lat < minPoint.geometry.coordinates[1]) {
            minPoint.geometry.coordinates[1] = point.lat;
          }

          if (point.lng < minPoint.geometry.coordinates[0]) {
            minPoint.geometry.coordinates[0] = point.lng;
          }
        }
      }

      navigationPoints = turf.featureCollection([minPoint, maxPoint]);
    } else {
      operationPoints.push(
        ...this._getStartAndEndPointCoordinatesFromAllSteps(this.etapa),
      );
      operationPoints.push(
        ...this._getFirstAndLastPointCoordinatesFromEachGuide(this.guias),
      );
      operationPoints.push(
        ...this._getCoordinateFromEachMarker(this.marcadores),
      );
      operationPoints.push(
        ...this._getCoordinatesFromBoundaries(this.boundaries),
      );

      navigationPoints = turf.featureCollection(
        operationPoints as Array<
          turf.Feature<turf.helpers.Geometry, { [name: string]: any }>
        >,
      );
    }
    return navigationPoints;
  }

  getPreferenceSubtitleUnit(): [string, string] {
    const getPreferenceSubtitleUnitByMap = (mapSelected) => {
      let unit = this.convertUnitPipe.transformUnit(
        this._getSIPreferenceSubtitleUnit(mapSelected),
      );

      return unit;
    };

    return [
      getPreferenceSubtitleUnitByMap(this.mapa0),
      getPreferenceSubtitleUnitByMap(this.mapa1),
    ];
  }

  _controlMasterMarkersCheckState() {
    const childrenL = this.marcadoresArrayL.filter((a) => a.children);
    const childrenCheckedTotal = childrenL.filter((a) => a.checked).length;
    const childrenNotCheckedTotal = childrenL.filter((a) => !a.checked).length;
    const indexOfMarkersSwitch = this.marcadoresArrayL.findIndex(
      (markerSwitch) => markerSwitch.property === TIPO_MARCADORES.MARKERS,
    );

    if (indexOfMarkersSwitch >= 0) {
      const stateOfMarkersSwitch =
        this.marcadoresArrayL[indexOfMarkersSwitch].checked;

      if (mustCheckMarkersSwitch(stateOfMarkersSwitch)) {
        this.marcadoresArrayL[indexOfMarkersSwitch].checked = true;
      } else if (mustUncheckMarkersSwitch(stateOfMarkersSwitch)) {
        this.marcadoresArrayL[indexOfMarkersSwitch].checked = false;
      }
    }

    function mustCheckMarkersSwitch(stateOfMarkersSwitch: boolean) {
      return (
        (childrenCheckedTotal > 0 ||
          childrenCheckedTotal === childrenL.length) &&
        stateOfMarkersSwitch === false
      );
    }

    function mustUncheckMarkersSwitch(stateOfMarkersSwitch: boolean) {
      return (
        childrenNotCheckedTotal === childrenL.length &&
        stateOfMarkersSwitch === true
      );
    }
  }

  _convertTurfPolygonToCustomWithColor(turfPolygon, propertyToUse) {
    const color = this.trailClass.trailColorPref(
      'false',
      undefined,
      turfPolygon.properties[propertyToUse],
      this.preferences[NVG_MAP_INDEX.INST_RATE],
      'desc',
    ).trailColorScale;

    const customPolygon = {
      color: color,
      paths: [],
      valueOperation: turfPolygon.properties[propertyToUse],
    };

    if (turfPolygon.geometry.type === 'MultiPolygon') {
      customPolygon.paths = turfPolygon.geometry.coordinates.map((polygon) => {
        const lastIndex = polygon[0].length;
        return polygon[0].map((coord, index) => {
          if (index < lastIndex) return { lat: coord[1], lng: coord[0] };
        });
      });
    } else if (turfPolygon.geometry.type === 'Polygon') {
      const lastIndex = turfPolygon.geometry.coordinates[0].length;
      customPolygon.paths = turfPolygon.geometry.coordinates[0].map(
        (coord, index) => {
          if (index < lastIndex) return { lat: coord[1], lng: coord[0] };
        },
      );
    }

    return customPolygon;
  }

  getRasterImageURL(
    rasterType: RasterType<string> = this.selectedMapImage?.key,
  ): string {
    if (!this.selectedMapImage || !this.mapImages?.rasterImages) {
      return '';
    }
    const rasterB64 =
      this.mapImages.rasterImages[`${this.selectedDevice}/${rasterType}`] ??
      this.mapImages.rasterImages[rasterType];

    if (rasterB64) {
      return `data:image/png;base64,${rasterB64}`;
    }

    const firstImageKey = Object.keys(this.mapImages.rasterImages)[0];
    return `data:image/png;base64,${this.mapImages.rasterImages[firstImageKey]}`;
  }

  getBackgroundImageURL(): string {
    if (!this.mapImages?.backgroundImages) {
      return '';
    }
    if (this.selectedMapImage.value) {
      return `data:image/png;base64,${this.selectedMapImage.value}`;
    }
    const firstImageKey = Object.keys(this.mapImages.backgroundImages)[0];
    this.selectedMapImage.key = firstImageKey;
    return `data:image/png;base64,${this.mapImages.backgroundImages[firstImageKey]}`;
  }

  _checkDetectChanges() {
    if (!this.destroyed) this.cdr.detectChanges();
  }

  dashboard() {
    this.router.navigate(['/']);
  }

  _downloadShapefileZip(url: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_self');
    link.setAttribute('href', url);
    link.setAttribute('download', 'download');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  _createShapefileInBrowser() {
    this._downloadShape().then(() => {
      this._clearShapefileDownloadVisualState();
    });
  }

  _clearShapefileDownloadVisualState() {
    this.translateService
      .get('tasks.success-shapefile')
      .subscribe((sucText: string) => {
        this.toastr.clear();
        this.toastr.success(sucText, '', { disableTimeOut: false });
      });

    this._checkDetectChanges();
  }

  _toggleSidebar() {
    this._opened = !this._opened;

    if (this._opened) {
      $('.etapa-menu').css('margin-right', '5px');
      $('.btn-collapse-expand-side')
        .find('i')
        .removeClass('agres-arrow-left agres-lg')
        .addClass('agres-arrow-right agres-lg');
    } else {
      $('.etapa-menu').css('margin-right', '25px');
      $('.btn-collapse-expand-side')
        .find('i')
        .removeClass('agres-arrow-right agres-lg')
        .addClass('agres-arrow-left agres-lg');
    }
  }

  showRecommendationMapUploadModal() {
    this.showRecommendationMapUpload = true;
    this.referenceCoordinateSubject.next({
      lat: this.mapLat,
      lng: this.mapLng,
    });
  }

  onReceiveRecommendationMap(event: any) {
    const { shapefileArray, propertyToUse } = event;
    this.createRecommendedTrail(shapefileArray, propertyToUse);
  }

  changeMapType(options: { isFirstMap: boolean; mapTypeId: string }) {
    const { isFirstMap, mapTypeId } = options;
    if (isFirstMap) {
      this.map.setMapTypeId(mapTypeId);
    } else {
      this.map1.setMapTypeId(mapTypeId);
    }
  }

  _selectMap(mapIndex) {
    $('.mapType1 option').eq(mapIndex).prop('selected', true);
    this.tipo1MapaEscolhido = this.taskMapTypes[mapIndex];
    this.mapa0 = mapIndex;
  }

  selectSystemUnit(event: Event) {
    const selectedSystem = (event.target as HTMLSelectElement).value;
    const unitsArray = this._getSubtitleUnitsArray(this.currentUnitSystem);
    this.convertUnitPipe.use(selectedSystem);
    this.currentUnitSystem = selectedSystem;
    this._convertSubtitles(unitsArray);
    this.updateChartOptions();
  }

  _createMarkerObject(
    property: string,
    title: string,
    checked: boolean,
    childen: boolean,
  ) {
    return {
      property: property,
      title: title,
      checked: checked,
      children: childen,
    };
  }

  preferencesToSubtitleArray(
    preferences: Preferences,
  ): [number, number, number, number, number] {
    const subtitleArray: number[] = [];
    subtitleArray.push(preferences?.red);
    subtitleArray.push(preferences?.orange);
    subtitleArray.push(preferences?.yellow);
    subtitleArray.push(preferences?.lemonGreen);
    subtitleArray.push(preferences?.green);
    return subtitleArray as [number, number, number, number, number];
  }

  getSubtitleFromLocalStorage(nvgId: string) {
    const subtitles = localStorage.getItem(USER_MAP_COLOR + nvgId);
    return subtitles;
  }
  prepareLegendDataToSave(): {
    [FIELD_INST_RATE]: [number, number, number, number, number];
    [FIELD_SPEED]: [number, number, number, number, number];
    [FIELD_OVERLAP]: [number, number, number, number, number];
    [FIELD_ALTIMETRY]: [number, number, number, number, number];
  } {
    const instRateSubtitles: [number, number, number, number, number] =
      this.preferencesToSubtitleArray(
        this.preferences[NVG_MAP_INDEX.INST_RATE],
      );
    const speedSubtitles: [number, number, number, number, number] =
      this.preferencesToSubtitleArray(this.preferences[NVG_MAP_INDEX.SPEED]);
    const overlapSubtitles: [number, number, number, number, number] =
      this.preferencesToSubtitleArray(this.preferences[NVG_MAP_INDEX.OVERLAP]);
    const altimetrySubtitles: [number, number, number, number, number] =
      this.preferencesToSubtitleArray(
        this.preferences[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION],
      );

    const legendObject = {
      [FIELD_INST_RATE]: instRateSubtitles,
      [FIELD_SPEED]: speedSubtitles,
      [FIELD_OVERLAP]: overlapSubtitles,
      [FIELD_ALTIMETRY]: altimetrySubtitles,
    };
    return legendObject;
  }

  _fitMapBoundsNvg() {
    const operationPoints: Array<turf.Feature> = [];
    const navigationPoints = this._getRelevantPoints(operationPoints);

    this.boundingBox = turf.bbox(navigationPoints);
    this.centerMap = turf.center(navigationPoints);
    this.mapLng = this.centerMap.geometry.coordinates[0];
    this.mapLat = this.centerMap.geometry.coordinates[1];
    this.mapZoom = 15;
  }

  setMaxGoogleMapsZoomValueOnMapArea(
    googleMapBounds: google.maps.LatLngBounds,
  ) {
    const maxZoomService = new google.maps.MaxZoomService();

    maxZoomService.getMaxZoomAtLatLng(
      googleMapBounds.getCenter(),
      (_response) => {
        this.MAP_MAX_ZOOM = this.MAP_MAX_ZOOM_DEFAULT;
      },
    );
  }
  mapReadyReportGenerateTrigger(map) {
    map.fitBounds(this.bounds);
    this.cdr.markForCheck();
    map.addListener('tilesloaded', () => {
      $('#gerarReport').trigger('click');
    });
  }

  setMapBounds() {
    const gMapBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(this.boundingBox[1], this.boundingBox[0]),
      new google.maps.LatLng(this.boundingBox[3], this.boundingBox[2]),
    );
    this.bounds = gMapBounds;
    this.setMaxGoogleMapsZoomValueOnMapArea(gMapBounds);
  }

  clickOnMap() {
    if (this.lastInfoWindow) {
      this.lastInfoWindow.close();
    }
  }

  goBack() {
    this._location.back();
  }
  taskSummaryBoxApplyedValue(): [
    string,
    string | undefined,
    boolean | undefined,
  ] {
    if (!this.app_vol) {
      return ['', undefined, false];
    } else {
      if (this.operation_type === OPERATION.ADUBACAO) {
        let value: string;
        if (this.app_vol) {
          value = this.app_vol + '';
        } else {
          value = this.convertUnitPipe.transform(
            (this.trabalho as any)?.app_vol,
            'kg',
          );
        }
        return ['monitoring.maps.title-mass', value, true];
      } else if (this.operation_type === OPERATION.PLANTIO) {
        return [
          'tasks.tabs.label-number-of-seeds',
          this.app_vol ? this.app_vol : (this.trabalho as any)?.app_vol,
          true,
        ];
      } else {
        let value: string;
        if (this.app_vol) {
          value = this.app_vol + '';
        } else {
          value = this.convertUnitPipe.transform(
            (this.trabalho as any)?.app_vol,
            'l',
          );
        }
        return ['tasks.tabs.label-applied-volume', value, true];
      }
    }
  }
  getMapPolygonPaths(e) {
    return e;
  }

  openModalEditLegendMap1() {
    this.modalEditLegendMap1 = true;
  }

  closeModalEditLegendMap1() {
    this.modalEditLegendMap1 = false;
  }

  openModalEditLegendMap2() {
    this.modalEditLegendMap2 = true;
  }

  closeModalEditLegendMap2() {
    this.modalEditLegendMap2 = false;
  }
}
