import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
  QueryList,
  ViewChild,
  Type,
} from '@angular/core';
import { FormControlName } from '@angular/forms';
import { AlertService } from '../../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import * as shapefile from 'shapefile';
import * as turf from '@turf/turf';
import { Subject } from 'rxjs';

interface ShapefileData {
  shapefileData: any;
  shpFileName: string;
}

interface RecommendationMap {
  shapefileArray: any[];
  propertyToUse: string;
}

interface ReferenceCoordinate {
  lat: number;
  lng: number;
}

interface DbfColumn {
  id: string;
  name: string;
}

class CustomError extends Error {
  public OriginalError: {};

  constructor(message: string, originalError: {}) {
    super(message);
    this.name = 'CustomError';
    this.OriginalError = originalError;
  }
}

const MAX_DISTANCE_FROM_REFERENCE_TO_SHAPEFILE = 20000;

@Component({
  selector: 'app-form-recommendation-map-upload',
  templateUrl: './recommendation-map-upload.html',
  styleUrls: ['recommendation-map-upload.scss'],
})
export class RecommendationMapUploadComponent implements OnInit {
  @Input() referenceCoordinate: ReferenceCoordinate;
  @Input() uploadDBF = true;

  @Output() modalChange: EventEmitter<boolean>;
  @Output() recommendationMapSent: EventEmitter<RecommendationMap>;
  @Output() modalUpdatedState: EventEmitter<boolean>;
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];
  @ViewChild('inputFileSHP', { static: true }) inputFileSHP: ElementRef;
  @ViewChild('inputFileDBF', { static: true }) inputFileDBF: ElementRef;
  @Input() showModalSubject: Subject<boolean>;

  @Input() referenceCoordinateSubject: Subject<ReferenceCoordinate>;
  public shp: any;
  public dbf: any;
  public shpSelected: boolean;
  public dbfSelected: boolean;
  public dbfFildNameSelected: boolean;
  public dbfData: any;
  public selectedDbfField: string;
  public shapefileData: any;
  public numberOfPolygons: number;
  public dbfColumnNames: Array<any>;
  public selectedDbfColumn: DbfColumn;
  public shpFileName: string;
  public dbfFilename: string;

  constructor(
    public AlertService: AlertService,
    public translateService: TranslateService,
  ) {
    this.modalChange = new EventEmitter<boolean>();
    this.recommendationMapSent = new EventEmitter<RecommendationMap>();
    this.modalUpdatedState = new EventEmitter<boolean>();
    this.selectedDbfColumn = {
      id: '',
      name: '',
    };
  }

  ngOnInit() {
    this.referenceCoordinateSubject.subscribe(
      (referenceCoordinate: ReferenceCoordinate) => {
        this.referenceCoordinate = referenceCoordinate;
      },
    );

    this.setAllStatesToInitial();
  }

  public async handleShapefileUpload(event: any): Promise<any> {
    this.translateService
      .get('global.loading.recommendation-map')
      .subscribe((translate: any) => {
        this.AlertService.info(translate);
      });
    this.dbfSelected = false;
    if (event.target.files) {
      this.shp = event.target.files[0];
      this.shpFileName = this.shp.name;
      try {
        this.shapefileData = await this.openShapefile(this.shp).catch((e) =>
          console.log(e),
        );
        this.validateDistanceFromShapefileToTargetLocation(this.shapefileData);
        this.numberOfPolygons = this.shapefileData.length;
        this.shpSelected = true;
        this.showFileUploadToastr();
      } catch (error) {
        this.showErrorToastr(error.message);
        this.inputFileSHP.nativeElement.value = '';
        this.shpSelected = false;
      }
      this.modalUpdatedState.emit(true);
    }
  }

  public async handleDBFUpload(event: any): Promise<any> {
    this.translateService
      .get('global.loading.recommendation-map')
      .subscribe((translate: any) => {
        this.AlertService.info(translate);
      });
    if (event.target.files) {
      this.dbf = event.target.files[0];
      this.dbfFilename = this.dbf.name;
      try {
        this.dbfData = await this.openDbfFile(this.dbf);
        this.checkIfDbfHasTheSameNumberOfPolygonsThanShapefile(this.dbfData);
        this.fillDropDownWithDbfColumns();
        this.dbfSelected = true;
        this.showFileUploadToastr();
      } catch (error) {
        this.showErrorToastr(error.message);
        this.inputFileDBF.nativeElement.value = '';
        this.dbfSelected = false;
      }
      this.modalUpdatedState.emit(true);
    }
  }

  public sendRecommendationMap(): void {
    this.AlertService.clear();

    if (this.uploadDBF) {
      this.shapefileData = this.linkDBFDataToShapefileData(
        this.shapefileData,
        this.dbfData,
      );
    }
    this.recommendationMapSent.emit({
      shapefileArray: this.shapefileData,
      propertyToUse: this.selectedDbfField,
    });
    // this.closeModalAndResetAllFields();
    this.hideModal();
    this.translateService
      .get('tasks.recommendation_map.map-import-success')
      .subscribe((translate: any) => {
        this.AlertService.success(translate);
      });
  }

  public setSelectedDbfColumn(): void {
    this.selectedDbfField = this.selectedDbfColumn.id;
    this.dbfFildNameSelected = true;
    this.modalUpdatedState.emit(true);
  }

  public showFileUploadToastr(): void {
    this.translateService
      .get('tasks.recommendation_map.map-import-success')
      .subscribe((translate: any) => {
        this.AlertService.clear();
        this.AlertService.success(translate);
      });
  }

  public showErrorToastr(message): void {
    this.AlertService.clear();
    this.AlertService.error(message);
  }

  public validateDistanceFromShapefileToTargetLocation(
    shapefileData: GeoJSON.Feature<turf.Polygon>[],
  ): void {
    const shapefileCoordinate = {
      lat: shapefileData[0].geometry.coordinates[0][0]['1'],
      lng: shapefileData[0].geometry.coordinates[0][0]['0'],
    };
    if (
      this.isReferenceCoordinateFarFromShapefile(
        this.referenceCoordinate,
        shapefileCoordinate,
        MAX_DISTANCE_FROM_REFERENCE_TO_SHAPEFILE,
      )
    ) {
      this.translateService
        .get(['tasks']['recommendation'])
        .subscribe((translate: any) => {
          this.AlertService.clear();
          throw new CustomError(
            `${translate['tasks']['recommendation']['error']['map-too-distant-from-reference']}.
                  ${translate['tasks']['recommendation']['error']['load-another-shp']}`,
            {},
          );
        });
    }
  }

  public isReferenceCoordinateFarFromShapefile(
    coordinate1: ReferenceCoordinate,
    coordinate2: ReferenceCoordinate,
    distance: number,
  ): boolean {
    if (!coordinate1.lat || !coordinate1.lng) {
      return false;
    }

    const point1 = turf.point([coordinate1.lng, coordinate1.lat]);
    const point2 = turf.point([coordinate2.lng, coordinate2.lat]);
    const distanceBetweenPoints = turf.distance(point1, point2);

    if (distanceBetweenPoints > distance) return true;
    return false;
  }

  public checkIfDbfHasTheSameNumberOfPolygonsThanShapefile(dbfData): void {
    const numberOfDbfRecords = dbfData.length;
    if (this.numberOfPolygons !== numberOfDbfRecords) {
      this.translateService
        .get(['tasks']['recommendation'])
        .subscribe((translate: any) => {
          throw new CustomError(
            `${translate['tasks']['recommendation']['error']['polygon-count-does-not-match']}.
            ${translate['tasks']['recommendation']['error']['load-another-dbf']}`,
            {},
          );
        });
    }
  }

  public linkDBFDataToShapefileData(shapefile: any, dbf: any): Array<any> {
    const shp = shapefile.slice();
    shp.forEach((polygon, index) => {
      polygon.properties = dbf[index];
    });
    return shp;
  }

  public fillDropDownWithDbfColumns(): void {
    const dbfFileColumns = Object.keys(this.dbfData[0]);

    this.dbfColumnNames = dbfFileColumns.map((column) => {
      return {
        id: column,
        name: column,
      };
    });
  }

  public async openShapefile(shp: File): Promise<Array<any>> {
    const openedFile: ArrayBuffer = await this.openFile(shp);
    const shapefileData = (await this.readShapefile(
      openedFile,
    )) as unknown as Array<any>;
    if (shapefileData.length === 0) {
      this.translateService
        .get(['tasks']['recommendation'])
        .subscribe((translate: any) => {
          throw new CustomError(
            `${translate['tasks']['recommendation']['error']['empty-shapefile']}.
            ${translate['tasks']['recommendation']['error']['load-another-shp']}`,
            {},
          );
        });
    }
    return shapefileData;
  }

  public async openDbfFile(dbf: File): Promise<any> {
    const openedFile: ArrayBuffer = await this.openFile(dbf);
    const dbfContent = await this.readDbfFile(openedFile);
    return dbfContent;
  }

  public openFile(file): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const reader: FileReader = new FileReader();
        reader.onload = (evt) => resolve(evt.target.result);
        reader.readAsArrayBuffer(file);
      } catch (error) {
        this.translateService
          .get(['tasks']['recommendation'])
          .subscribe((translate: any) => {
            reject(
              new CustomError(
                `${translate['tasks']['recommendation']['error']['cannot-open-file']}`,
                error,
              ),
            );
          });
      }
    });
  }

  public readShapefile(
    shp: ArrayBuffer,
  ): Promise<Array<GeoJSON.GeometryObject>> {
    return this.readShapefileOrDbf(shp, shapefile.open);
  }

  public readDbfFile(
    dbf: ArrayBuffer,
  ): Promise<Array<GeoJSON.GeoJsonProperties>> {
    return this.readShapefileOrDbf(dbf, shapefile.openDbf);
  }

  public readShapefileOrDbf(
    data: ArrayBuffer,
    method: typeof shapefile.open | typeof shapefile.openDbf,
  ): Promise<any> {
    const dataRead: Array<
      GeoJSON.GeometryCollection | GeoJSON.GeoJsonProperties
    > = [];
    return new Promise((resolve, reject) => {
      method(data)
        .then(
          (
            source:
              | shapefile.Source<GeoJSON.GeometryObject>
              | shapefile.Source<GeoJSON.GeoJsonProperties>,
          ) =>
            source.read().then(function log(result: {
              done: boolean;
              value: GeoJSON.GeometryCollection | GeoJSON.GeoJsonProperties;
            }) {
              if (result.done) {
                resolve(dataRead);
                return;
              }
              dataRead.push(result.value);
              return source.read().then(log);
            }),
        )
        .catch((error) => {
          this.translateService
            .get(['tasks']['recommendation'])
            .subscribe((translate: any) => {
              reject(
                new CustomError(
                  `${translate['tasks']['recommendation']['error']['cannot-read-file']}`,
                  error,
                ),
              );
            });
        });
    });
  }

  public setAllStatesToInitial(): void {
    this.selectedDbfColumn.id = '';
    this.shpSelected = false;
    this.dbfSelected = false;
    this.dbfFildNameSelected = false;
    this.dbfColumnNames = [];
    this.referenceCoordinate = {
      lat: null,
      lng: null,
    };
  }

  hideModal() {
    this.showModalSubject.next(false);
  }
}
