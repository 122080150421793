import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { BasicLayoutComponent } from './basicLayout.component';
import { BlankLayoutComponent } from './blankLayout.component';

import { HeaderComponent } from './../header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarMenuComponent } from '../sidebar-menu/sidebar-menu.component';
import { AgresSpinnerComponent } from '../agres-spinner/agres-spinner.component';

@NgModule({
  declarations: [
    BasicLayoutComponent,
    BlankLayoutComponent,
    SidebarMenuComponent,
    HeaderComponent,
    AgresSpinnerComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    TranslateModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    BasicLayoutComponent,
    BlankLayoutComponent,
    SidebarMenuComponent,
    HeaderComponent,
    AgresSpinnerComponent,
  ],
})
export class LayoutsModule {}
