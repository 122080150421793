<div class="row border-bottom white-bg dashboard-header">
  <div class="row">
    <div class="col-xs-12">
      <div class="container4">
        <p class="colorAlignment">
          {{ 'global.alert.under-development' | translate }}
        </p>
      </div>
      <img alt="image" src="./assets/images/default/redimenssionada.jpg" class="centerimage" />
    </div>
  </div>
</div>
