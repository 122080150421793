<div class="middle-box text-center animated fadeInDown">
  <h1>403</h1>
  <h3 class="font-bold">
    {{ 'error.403.title' | translate }}
  </h3>
  <div class="error-desc">
    {{ 'error.403.description' | translate }}
    <a href="#"
      ><u> {{ 'error.403.description-complete' | translate }}</u>
    </a>
    <form class="form-inline m-t" role="form">
      <app-button
        type="button"
        color="card"
        icon="agres-close"
        (btnClick)="voltar()"
        title="{{ 'global.back' | translate }}">
      </app-button>
    </form>
  </div>
</div>
