import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ConfirmacaoModalService {
    public dataSource = new BehaviorSubject<any>(null);
    currentData = this.dataSource.asObservable();

  changeData(data: any) {
        this.dataSource.next(data);
    }
}
