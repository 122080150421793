import { Component, ElementRef, ViewChild, AfterViewInit, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-scroll',
  templateUrl: './horizontal-scroll.component.html',
  styleUrls: ['./horizontal-scroll.component.scss']
})
export class HorizontalScrollComponent implements AfterContentInit {
  @ViewChild('carousel', { static: true }) carousel: ElementRef;

  showLeftButton = false;
  showRightButton = false;

  ngAfterContentInit() {
    this.updateButtonVisibility();
  }

  onScroll() {
    this.updateButtonVisibility();
  }

  updateButtonVisibility() {
    const carousel = this.carousel.nativeElement;
    const scrollLeft = carousel.scrollLeft;
    const scrollWidth = carousel.scrollWidth;
    const clientWidth = carousel.clientWidth;
    const firstElementWidth = carousel.children[0].clientWidth;
    this.showLeftButton = scrollLeft > firstElementWidth*0.05;
    this.showRightButton = scrollLeft + clientWidth < scrollWidth;
  }

  scrollLeft() {
    const target = this.getPrevElement(this.carousel.nativeElement.scrollLeft);
    if (target) {
      this.carousel.nativeElement.scrollTo({ left: target.offsetLeft, behavior: 'smooth' });
    }
  }

  scrollRight() {
    const target = this.getNextElement(this.carousel.nativeElement.scrollLeft);
    if (target) {
      this.carousel.nativeElement.scrollTo({ left: target.offsetLeft, behavior: 'smooth' });
    }
  }

  getPrevElement(currentScrollLeft: number) {
    const children = this.carousel.nativeElement.children;
    for (let i = children.length - 1; i >= 0; i--) {
      if (children[i].offsetLeft < currentScrollLeft) {
        return children[i];
      }
    }
    return null;
  }
  getNextElement(currentScrollLeft: number) {
    const children = this.carousel.nativeElement.children;
    for (let i = 0; i < children.length; i++) {
      if (children[i].offsetLeft > currentScrollLeft) {
        return children[i];
      }
    }
    return null;
  }


}