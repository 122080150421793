import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-termos-uso',
    templateUrl: 'termos-uso.template.html',
    styleUrls: ['termos-uso.component.scss'],
})
export class TermosUsoComponent {
    constructor(
        public translate: TranslateService,
        public toastr: ToastrService,
        public router: Router
    ) {}

    voltar() {
        this.toastr.clear();
        this.router.navigateByUrl('/login');
    }
}
