import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-manutencao-page',
  templateUrl: './manutencao-page.component.html',
  styleUrls: ['./manutencao-page.component.scss'],
})
export class ManutencaoPageComponent {
  router: Router;

  constructor(public _location: Location) {}
  voltar() {
    this._location.back();
  }
}
