import {
  merge as observableMerge,
  fromEvent as observableFromEvent,
  Observable,
} from 'rxjs';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChildren,
  AfterViewInit,
} from '@angular/core';
import { GenericValidator } from 'app/utils/generic-form-validator';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormControlName,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];
  public registrarForm: UntypedFormGroup;
  public validationMessages: { [key: string]: { [key: string]: string } };
  public genericValidator: GenericValidator;
  public displayMessage: { [key: string]: string } = {};
  public errors: any[] = [];
  modal: boolean;
  disableButton = undefined;

  constructor(
    public fb: UntypedFormBuilder,
    public translateService: TranslateService,
  ) {
    this.validationMessages = {
      _id: {
        required: '',
      },
    };

    this.translateService.get('global.home').subscribe((home: string) => {
      var home = home;
      this.bindHTMLTitle(home, 'FAQ');
    });

    this.translateService.get('faq.id-required').subscribe((res: string) => {
      this.validationMessages._id.required = res;
      this.genericValidator = new GenericValidator(this.validationMessages);
    });

    this.modal = false;
  }

  searchKeys = ['pergunta'];
  public search;
  questionsFilter = [];
  questionsOriginal = [
    {
      id: '1',
      pergunta: ' Preciso pagar algo para fazer algum curso?',
      resposta:
        'Não, TODOS os cursos disponíveis no portal primecursos.com.br são 100% gratuitos. Para fazer qualquer um de nossos cursos, basta você estar cadastrado no site e fazer a matrícula no curso desejado.',
    },
    {
      id: '2',
      pergunta: 'Os cursos têm alguma mensalidade?',
      resposta:
        'Não. Conforme já informado acima, TODOS os cursos disponíveis em nosso site são totalmente gratuitos, incluindo a avaliação. Cobramos apenas uma taxa administrativa para Confecção, Emissão e Envio do Certificado até a sua residência. Não há nenhum tipo de custo adicional ou mensalidade.',
    },
    {
      id: '3',
      pergunta: 'Qual a duração ou prazo para concluir os cursos?',
      resposta:
        'Não há um prazo mínimo ou máximo para concluir os cursos. Você faz de acordo com seu ritmo e disponibilidade. Os cursos estão disponíveis em nosso portal e você acessa quando e quanto desejar.',
    },
    {
      id: '4',
      pergunta: 'Quando posso me matricular?',
      resposta:
        'Os cursos são permanentes. Você pode matricular-se em qualquer dia/hora. Basta acessar a página de cadastro, preencher com seus dados e matricular-se no(s) curso(s) desejado(s).',
    },
  ];

  ngOnInit() {
    this.registrarForm = this.fb.group({
      categoria: '',
      servico: '',
      descricao: '',
      file: '',
    });
    this.questionsOriginal = this.questionsOriginal.map((x) => {
      x['isCollapsed'] = true;
      return x;
    });
    this.questionsFilter = this.questionsOriginal;
  }

  bindHTMLTitle(home, title) {
    jQuery('.breadcrumb').html(
      `<li><a href="#">${home}</a></li><li class="active"><strong>${title}</strong></li>`,
    );
  }

  showResposta(item) {
    let itemFind = this.questionsFilter.find((x) => x.id == item.id);
    itemFind['isCollapsed'] = !itemFind['isCollapsed'];
  }

  searchItens() {
    this.questionsFilter = this.filter(this.questionsOriginal);
  }

  filter(data) {
    const filter = this.search.toLowerCase();
    return !filter
      ? data.slice(0)
      : data.filter((d) => {
          return (
            Object.keys(d)
              .filter((k) => this.searchKeys.includes(k))
              .map((k) => String(d[k]))
              .join('|')
              .toLowerCase()
              .indexOf(filter) !== -1 || !filter
          );
        });
  }

  ngAfterViewInit() {
    let controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) =>
        observableFromEvent(formControl.nativeElement, 'blur'),
    );
    observableMerge(...controlBlurs).subscribe(() => {
      this.displayMessage = this.genericValidator.processMessages(
        this.registrarForm,
      );
    });
  }

  showModal() {
    this.modal = true;
  }

  hideModal() {
    this.modal = false;
  }
}
