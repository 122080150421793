import { AppConstants } from '../app.constants';

export class TranslateUtil {
    public _title;

    isLanguageSelected(lang) {
        const langSelected = this.getCurrentLocale();
        return langSelected !== lang;
    }

    getCurrentLocale() {
        let langSelected = window.localStorage.getItem(
            AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG
        );

        if (!langSelected) langSelected = AppConstants.LANGUAGES.PT_BR;

        return langSelected;
    }
}
