import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-nova-funcionalidade-page',
  templateUrl: './nova-funcionalidade-page.component.html',
  styleUrls: ['./nova-funcionalidade-page.component.scss'],
})
export class NovaFuncionalidadePageComponent {
  router: Router;

  constructor(public _location: Location) {}

  voltar() {
    this._location.back();
  }
}
