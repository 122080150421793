<div class="tabOperation">
  <div class="slider">
    <div *ngFor="let card of listOfCards" class="slider__item">
      <ng-container *ngFor="let line of card">
        <p
          *ngIf="
            line.label !== 'monitoring.tabs.operation.label-status-session' &&
            line.label !== 'monitoring.tabs.operation.label-operation'
          "
          class="slider__item--text">
          {{ line.label | translate }} <span>{{ line.value }}</span>
        </p>
        <p *ngIf="line.label === 'monitoring.tabs.operation.label-operation'" class="slider__item--text">
          {{ line.label | translate }} <span> {{ line.value | translate }}</span>
        </p>
        <div *ngIf="line.label === 'monitoring.tabs.operation.label-status-session'">
          <p>{{ line.label | translate }}</p>
          <img *ngFor="let sectionArr of statusSession" [src]="sectionArr" />
        </div>
      </ng-container>
    </div>
  </div>
</div>
