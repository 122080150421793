import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Talhao } from 'app/shared/models/talhao';
import { TranslateService } from '@ngx-translate/core';
import { MapLoaderService } from './map.loader';
import { FormControlName } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as turf from '@turf/turf';
import 'jquery';
import 'jquery-slimscroll';
import * as $ from 'jquery';
import * as shapefile from 'shapefile';
import { _roleEnumUserClass } from 'app/utils/role.utils';
import { AppConstants } from 'app/app.constants';
declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'app-contornos',
  templateUrl: './contornos.component.html',
  styleUrls: ['./contornos.component.scss'],
})
export class ContornosComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];
  @ViewChild('gmap', { static: true }) gmapElement: any;

  public drawingManager: any;
  public map: google.maps.Map;
  public search;
  public path;
  public listaLatLong = [];

  public formCampos = {
    _id: '',
    Nome: '',
    Location: [],
    nomeCliente: '',
    active: true,
  };

  public isAdmin;
  public talhaoData: Talhao[] = [];
  public isEditing = false;

  public idInterval;

  public nomeClienteSelected;

  public mensagemExcluirTalhao = '';
  public rolesEnum = _roleEnumUserClass.getUsers();

  constructor(
    public translateService: TranslateService,
    public toastr: ToastrService,
  ) {
    this.translateService.get('global.home').subscribe((home: string) => {
      this.translateService
        .get('cadastro-talhao.title-page')
        .subscribe((res: string) => {
          const title = res;
          this.bindHTMLTitle(home, title);
        });
    });
  }

  bindHTMLTitle(home, title) {
    jQuery('.breadcrumb').html(
      `<li><a href='#'>${home}</a></li><li class='active'><strong>${title}</strong></li>`,
    );
  }

  ngOnInit() {
    if (this.translateService.currentLang == AppConstants.LANGUAGES.PT_BR)
      $('#upload-talhao')
        .addClass('custom-file-input-br')
        .removeClass('custom-file-input-en custom-file-input-es');
    else if (this.translateService.currentLang == AppConstants.LANGUAGES.EN)
      $('#upload-talhao')
        .addClass('custom-file-input-en')
        .removeClass('custom-file-input-br custom-file-input-es');
    else if (this.translateService.currentLang == AppConstants.LANGUAGES.ES)
      $('#upload-talhao')
        .addClass('custom-file-input-es')
        .removeClass('custom-file-input-en custom-file-input-br');

    const role = window.localStorage.getItem(
      AppConstants.KEYS_LOCAL_STORAGE.ROLE,
    );
    this.isAdmin = role === this.rolesEnum.Admin;

    this.loadTalhao();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadMapInit();
    }, 1000);
  }

  ngOnDestroy() {
    if (this.idInterval) {
      clearInterval(this.idInterval);
    }
  }

  newTalhao() {
    this.isEditing = false;
    this.clearForm();
    this.loadMapInit();
  }

  editTalhao(talhao) {
    this.isEditing = true;
    this.formCampos = talhao;

    try {
      this.loadPolygon(turf.polygon([talhao.Location]));
    } catch (error) {
      this.translateService
        .get('global.validate.load-drawing')
        .subscribe((res: string) => {
          this.toastr.error(res);
        });
      console.error(error);
    }
  }

  loadTalhao() {
    const self = this;
    const talhaoFilter = [];
    self.talhaoData = [];

  }

  // TRANSFERÊNCIA DO ARQUIVO DO USB PARA LOCAL
  handleUpload(e) {
    const self = this;
    const files = (<HTMLInputElement>e.target).files;

    if (files.length >= 1) {
      for (let i = 0; i < files.length; i++) {
        const file = (<HTMLInputElement>e.target).files[i];
        const extension = file.name
          .substring(file.name.lastIndexOf('.') + 1)
          .toLowerCase();

        if (extension != 'shp') {
          this.translateService
            .get('global.validate.extention-shp')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        } else {
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (evt) => resolve(reader.result);
            reader.readAsArrayBuffer(file);
          }).then((result: any) => {
            shapefile
              .open(result)
              .then((source) =>
                source.read().then(function log(result) {
                  if (result.done) return;
                  self.loadPolygon(result.value);
                  return source.read().then(log);
                }),
              )
              .catch((error) => console.error(error.stack));
          });
        }
      }
    }
  }

  mapReady(map) {
    this.map = map;
  }

  initAutocomplete() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: -21.20822, lng: -47.86921 },
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      zoom: 4,
    });

    if (!this.isEditing) {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['polygon'],
        },
      });

      this.drawingManager.setMap(this.map);

      google.maps.event.addListener(
        this.drawingManager,
        'overlaycomplete',
        (event) => {
          if (event.type === google.maps.drawing.OverlayType.POLYGON) {
            if (
              this.isEditing &&
              this.formCampos.Location != null &&
              this.formCampos.Location.length > 0
            ) {
              this.translateService
                .get('contours.field-added')
                .subscribe((res: string) => {
                  this.toastr.warning(res);
                });

              this.formCampos.Location = [];
            }

            const pointsArr = event.overlay.getPath().getArray();
            for (let i = 0; i < pointsArr.length; i++) {
              this.formCampos.Location.push([
                pointsArr[i].lng(),
                pointsArr[i].lat(),
              ]);
            }

            this.formCampos.Location.push([
              pointsArr[0].lng(),
              pointsArr[0].lat(),
            ]);
          }
        },
      );
    }
  }

  eraseMap() {
    this.formCampos.Location = [];
    this.loadMapInit();
  }

  loadPolygon(PolygonFeature) {
    // Limpar o mapa antes de adicionar o poligono
    this.map = new google.maps.Map(this.gmapElement.nativeElement);
    MapLoaderService.load()
      .then(() => {
        this.initAutocomplete();
      })
      .then(() => {
        // validar GEOJSON
        try {
          turf.featureOf(PolygonFeature, 'Polygon', 'coordinates');
        } catch (error) {
          console.error(error);
          this.translateService
            .get('contours.file-imported-fail')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        }

        const PolygonCoords = PolygonFeature.geometry.coordinates[0];

        this.formCampos.Location = PolygonCoords;

        let gPolygon = [];
        if (Array.isArray(PolygonCoords) && Array.isArray(PolygonCoords[0])) {
          gPolygon = PolygonCoords.map(
            (e) => new google.maps.LatLng(e[1], e[0]),
          );
        }

        const polygon_0 = new google.maps.Polygon({
          path: gPolygon,

          strokeColor: '#ff0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#ff0000',
          fillOpacity: 0.3,
        });

        const centerPoint = turf.center(PolygonFeature);

        polygon_0.setMap(this.map);
        this.map.setCenter(
          new google.maps.LatLng(
            centerPoint.geometry.coordinates[1],
            centerPoint.geometry.coordinates[0],
          ),
        );

        const bbox = turf.bbox(PolygonFeature);

        const gMapBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(bbox[1], bbox[0]),
          new google.maps.LatLng(bbox[3], bbox[2]),
        );

        this.map.fitBounds(gMapBounds);
      });
  }

  deleteTalhao() {
    this.translateService
      .get('contours.message-confirm-delete-field')
      .subscribe((res: string) => {
        this.mensagemExcluirTalhao = res;
        if (confirm(this.mensagemExcluirTalhao)) {
          this.formCampos.active = false;
        }
      });
  }

  salvar() {
    if (this.formCampos.Nome == null || this.formCampos.Nome == '') {
      this.translateService
        .get('contours.message-field-nome-invalid')
        .subscribe((res: string) => {
          this.toastr.warning(res);
        });
    } else if (
      this.formCampos.Location == null ||
      (Array.isArray(this.formCampos.Location) &&
        this.formCampos.Location.length == 0)
    ) {
      this.translateService
        .get('contours.message-field-desenho-invalid')
        .subscribe((res: string) => {
          this.toastr.warning(res);
        });
    } else {
      if (this.formCampos._id == null || this.formCampos._id == '') {
        this.formCampos._id = Date.now().toString();
      }
    }
  }

  clearForm() {
    this.formCampos = {
      _id: '',
      Nome: '',
      Location: [],
      nomeCliente: '',
      active: true,
    };
  }

  loadMapInit() {
    this.map = new google.maps.Map(this.gmapElement.nativeElement);
    MapLoaderService.load().then(() => {
      this.initAutocomplete();
    });
  }
}
