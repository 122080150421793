import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'FilterTablePipe',
})
export class FilterTablePipeService implements PipeTransform {
    transform(lista: any[], searchTerm: string): any[] {
        if (!lista || !searchTerm) {
            return lista;
        }

        const listaFiltrados = [];

        for (let item of lista) {
            const propriedades = Object.keys(item);

            for (let prop of propriedades) {
                if (item[prop] instanceof Array) {
                    let filtradoInterno = false;

                    for (let itemArray of item[prop]) {
                        const propriedadesItemArray = Object.keys(itemArray);

                        for (let propItemArray of propriedadesItemArray) {
                            if (typeof itemArray[propItemArray] != 'object') {
                                const filtrado = this.retirarCaracterEspecial(
                                    itemArray[propItemArray]
                                ).includes(
                                    this.retirarCaracterEspecial(searchTerm)
                                );

                                if (filtrado && !filtradoInterno) {
                                    filtradoInterno = true;
                                    listaFiltrados.push(item);
                                    break;
                                }
                            }
                        }

                        if (filtradoInterno) {
                            break;
                        }
                    }

                    if (filtradoInterno) {
                        break;
                    }
                } else if (typeof item[prop] == 'object') {
                    let filtradoInterno = false;
                    if(item[prop]===null) continue
                    const propriedadesInterno = Object.keys(item[prop]);

                    for (let propInterno of propriedadesInterno) {
                        if (typeof item[prop][propInterno] != 'object') {
                            const filtrado = this.retirarCaracterEspecial(
                                item[prop][propInterno]
                            ).includes(
                                this.retirarCaracterEspecial(searchTerm)
                            );

                            if (filtrado && !filtradoInterno) {
                                filtradoInterno = true;
                                listaFiltrados.push(item);
                                break;
                            }
                        }
                    }

                    if (filtradoInterno) {
                        break;
                    }
                } else {
                    const filtrado = this.retirarCaracterEspecial(
                        item[prop]
                    ).includes(this.retirarCaracterEspecial(searchTerm));

                    if (filtrado) {
                        listaFiltrados.push(item);
                        break;
                    }
                }
            }
        }

        return listaFiltrados;
    }

    verificarTextoDoFiltroNoItemDaLista(item: any, searchTerm: string) {
        return this.retirarCaracterEspecial(item).includes(
            this.retirarCaracterEspecial(searchTerm)
        );
    }

    retirarCaracterEspecial(item: any) {
        return item
            .toString()
            .toLowerCase()
            .replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll('/', '');
    }
}
