import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { Subscription, timer as observableTimer } from 'rxjs';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { DeviceDTO } from './device/device.model';
import { DeviceService } from './device/device.service';

@Component({
  selector: 'app-fleet-monitoring',
  templateUrl: './fleet-monitoring.component.html',
  styleUrls: ['./fleet-monitoring.component.scss'],
})
export class FleetMonitoringComponent implements AfterViewInit, OnDestroy {
  @Input() searchTerm: string;
  private refreshIntervalInSeconds = 5;
  private intervalRefreshLightSignal: Subscription;
  realtimeDevices: DeviceDTO[] = [];
  devicesList = false;

  constructor(
    public readonly alertService: AlertService,
    private readonly deviceService: DeviceService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly loadingAgresSpinner: AgresSpinnerService,
    private readonly translateService: TranslateService,
  ) {}

  ngAfterViewInit() {
    this.translateService.get('global.loading.devices').subscribe((res) => {
      this.loadingAgresSpinner.toShow(res);
    });
    this.initDeviceStatus();
  }

  ngOnDestroy() {
    this.intervalRefreshLightSignal.unsubscribe();
  }

  dashboard() {
    this.router.navigate(['/']);
  }

  isDeviceOnline(timestamp: number | null): boolean {
    if (timestamp == null) {
      return false;
    }

    let date: string = new Date(
      Date.now() - this.refreshIntervalInSeconds * 3000,
    ).toISOString();
    const timeNow: number = Date.parse(date);

    if (timestamp > timeNow) {
      return true;
    } else {
      return false;
    }
  }

  deviceSelect(device: DeviceDTO) {
    if (device.workStatus === 'offline') {
      this.translateService
        .get('global.alert.device_offline')
        .subscribe((res: string) => {
          this.alertService.error(res);
        });
    } else {
      window.localStorage.setItem(
        AppConstants.KEYS_LOCAL_STORAGE.DEVICE_ID,
        device.deviceId,
      );
      if (device.deviceType && device.deviceType == 'agronave') {
        this.router.navigate(['device', device.deviceId, device.deviceType], {
          relativeTo: this.route,
        });
      } else {
        this.router.navigate(['device', device.deviceId, 'isoview'], {
          relativeTo: this.route,
        });
      }
    }
  }

  filterDevice(deviceId: string): boolean {
    return (
      this.searchTerm === undefined ||
      deviceId.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  updateSearch(event: KeyboardEvent) {
    const value: string = (event.target as HTMLInputElement).value;
    this.searchTerm = value;
  }

  private initDeviceStatus() {
    this.intervalRefreshLightSignal = observableTimer(
      1000,
      this.refreshIntervalInSeconds * 1000,
    ).subscribe(() => {
      this.changeDeviceStatus();
    });
  }

  private async changeDeviceStatus(): Promise<void> {
    let devices = await this.getAllDevices();
    let agronaveDevices = await this.getAllAgronaveDevices();
    let devicesWorking: Array<DeviceDTO> = [];
    let devicesMoving: Array<DeviceDTO> = [];
    let devicesOffline: Array<DeviceDTO> = [];
    let activeAgronaveDevices: Array<DeviceDTO> = [];
    this.realtimeDevices = [];

    if (agronaveDevices.length > 0) {
      agronaveDevices.forEach(
        (device: {
          timestamp: number;
          deviceId: string;
          taskActive: boolean;
          isIsobus: boolean;
        }) => {
          if (this.isDeviceOnline(Number(device.timestamp))) {
            const deviceDTO: DeviceDTO = {
              deviceId: device.deviceId,
              location: { elevation: 0, latitude: 0, longitude: 0 },
              timestamp: device.timestamp.toString(),
              workStatus:
                device.taskActive === true ? 'working' : 'not_working',
              deviceType: 'agronave',
            };
            activeAgronaveDevices.push(deviceDTO);
            activeAgronaveDevices.sort((a, b) => +a.deviceId - +b.deviceId);
            this.realtimeDevices.push(...activeAgronaveDevices);
            devices = devices.filter((d) => d.deviceId !== device.deviceId);
          }
        },
      );
    }

    if (devices.length > 0) {
      devices.forEach((device) => {
        if (this.isDeviceOnline(Number(device.timestamp))) {
          if (device.workStatus === 'Working') {
            devicesWorking.push(device);
          } else {
            devicesMoving.push(device);
          }
        } else {
          device.workStatus = 'offline';
          device.timestamp = null;
          device.location.elevation = 0;
          device.location.latitude = 0;
          device.location.longitude = 0;
          devicesOffline.push(device);
        }
      });

      devicesWorking.sort((a, b) => +a.deviceId - +b.deviceId);
      devicesMoving.sort((a, b) => +a.deviceId - +b.deviceId);
      devicesOffline.sort((a, b) => +a.deviceId - +b.deviceId);
      this.realtimeDevices.push(
        ...devicesWorking,
        ...devicesMoving,
        ...devicesOffline,
      );
      this.devicesList = true;
    } else {
      this.devicesList = false;
    }
    this.loadingAgresSpinner.toHide();
  }

  private getAllDevices(): Promise<DeviceDTO[]> {
    return this.deviceService.getAllDeviceRealtime().toPromise<DeviceDTO[]>();
  }

  private getAllAgronaveDevices(): Promise<any> {
    return this.deviceService.getAllAgronaveDevicesRealtime().toPromise<any>();
  }
}
