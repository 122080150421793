import * as shapefile from 'shapefile';
import * as turf from '@turf/turf';
interface ReferenceCoordinate {
  lat: number;
  lng: number;
}

export class ShapefileReader {
  readonly MAX_DISTANCE_FROM_REFERENCE_TO_SHAPEFILE = 20;

  public async openShapefile(shp: File): Promise<Array<any>> {
    const openedFile: ArrayBuffer = await this.openFile(shp);
    const shapefileData = (await this.readShapefile(
      openedFile,
    )) as unknown as Array<any>;

    return shapefileData;
  }

  public async openDbfFile(dbf: File): Promise<any> {
    const openedFile: ArrayBuffer = await this.openFile(dbf);
    const dbfContent = await this.readDbfFile(openedFile);
    return dbfContent;
  }

  public openFile(file): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const reader: FileReader = new FileReader();
        reader.onload = (evt) => resolve(evt.target.result);
        reader.readAsArrayBuffer(file);
      } catch (error) {
        reject(error);
      }
    });
  }

  public readShapefile(
    shp: ArrayBuffer,
  ): Promise<Array<GeoJSON.GeometryObject>> {
    return this.readShapefileOrDbf(shp, shapefile.open);
  }

  public readDbfFile(
    dbf: ArrayBuffer,
  ): Promise<Array<GeoJSON.GeoJsonProperties>> {
    return this.readShapefileOrDbf(dbf, shapefile.openDbf);
  }

  public readShapefileOrDbf(
    data: ArrayBuffer,
    method: typeof shapefile.open | typeof shapefile.openDbf,
  ): Promise<any> {
    const dataRead: Array<
      GeoJSON.GeometryCollection | GeoJSON.GeoJsonProperties
    > = [];
    return new Promise((resolve) => {
      method(data)
        .then(
          (
            source:
              | shapefile.Source<GeoJSON.GeometryObject>
              | shapefile.Source<GeoJSON.GeoJsonProperties>,
          ) =>
            source.read().then(function log(result: {
              done: boolean;
              value: GeoJSON.GeometryCollection | GeoJSON.GeoJsonProperties;
            }) {
              if (result.done) {
                resolve(dataRead);
                return;
              }
              dataRead.push(result.value);
              return source.read().then(log);
            }),
        )
        .catch((error) => {
          return error;
        });
    });
  }
  public linkDBFDataToShapefileData(shapefile: any, dbf: any): Array<any> {
    const shp = shapefile.slice();
    shp.forEach((polygon, index) => {
      polygon.properties = dbf[index];
    });
    return shp;
  }

  public isReferenceCoordinateFarFromShapefile(
    coordinate1: ReferenceCoordinate,
    coordinate2: ReferenceCoordinate,
  ): boolean {
    if (!coordinate1.lat || !coordinate1.lng) {
      return false;
    }

    const point1 = turf.point([coordinate1.lng, coordinate1.lat]);
    const point2 = turf.point([coordinate2.lng, coordinate2.lat]);
    const distanceBetweenPoints = turf.distance(point1, point2, {
      units: 'kilometers',
    });

    if (distanceBetweenPoints > this.MAX_DISTANCE_FROM_REFERENCE_TO_SHAPEFILE) {
      return true;
    } else {
      return false;
    }
  }

  public getCoordinatesFromShapefile(shpArray) {
    if (
      shpArray[0].geometry.type === 'MultiPolygon' ||
      shpArray[0].geometry.type === 'MultiLineString'
    ) {
      return {
        lat: shpArray[0].geometry.coordinates[0][0][0]['1'],
        lng: shpArray[0].geometry.coordinates[0][0][0]['0'],
      };
    } else if (shpArray[0].geometry.type === 'LineString') {
      return {
        lat: shpArray[0].geometry.coordinates[0]['1'],
        lng: shpArray[0].geometry.coordinates[0]['0'],
      };
    } else if (shpArray[0].geometry.type === 'Polygon') {
      return {
        lat: shpArray[0].geometry.coordinates[0][0]['1'],
        lng: shpArray[0].geometry.coordinates[0][0]['0'],
      };
    }
  }
}
