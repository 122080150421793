<div class="wrap">
  <div class="wrap__content">
    <app-header
      [toggleSidebarSubject]="toggleSidebarSubject"
      [hideMenuButton]="hideMenuButton"
      [resizeEventEmitter]="onResize"></app-header>
    <app-sidebar
      [toggleSidebarSubject]="toggleSidebarSubject"
      [isSidebarOpen]="isSidebarOpen"
      [resizeEventEmitter]="onResize"></app-sidebar>
    <main class="wrap__content--container">
      <router-outlet>
        <app-agres-spinner></app-agres-spinner>
      </router-outlet>
    </main>
    <footer></footer>
  </div>
</div>
