<div class="dropdown-box">
    <div class="dropdown-box__current" tabindex="1">
        <div class="dropdown-box__value">
            <input class="dropdown-box__input" type="radio" checked="checked" />
            <p class="dropdown-box__input-text">{{ title }}</p>
        </div>
        <span class="dropdown-box__icon" aria-hidden="true"><i class="agres-arrow-down"></i></span>
    </div>
    <ul class="dropdown-box__list">
        <li *ngFor="let item of this.dataList;let i = index" value="{{item.code}}" [selected]="i === 0">
            <label class="dropdown-box__option"> <a [disabled]="disabled" (click)="click.emit($event)"> {{item.name}}</a></label>
        </li>
    </ul>
</div>
