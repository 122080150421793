import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-offcanvas',
  templateUrl: './offcanvas.component.html',
  styleUrls: ['./offcanvas.component.scss'],
})
export class OffcanvasComponent implements OnInit {
  @Input() showSubject = new Subject<boolean>();
  @Input() show: boolean = false;
  @Input() size: 'medium';
  @Input() styleClass: string;
  @Input() title: string;
  @Input() headerTemplate: TemplateRef<any>;
  @Input() bodyTemplate: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;

  constructor(public _location: Location) {}

  ngOnInit(): void {
    if (this.showSubject)
      this.showSubject.subscribe((show) => {
        this.show = show;
      });
  }

  goBack() {
    this._location.back();
  }

  hideOffCanvas(): void {
    this.show = false;
  }
  showOffCanvas() {
    this.show = true;
  }
}
