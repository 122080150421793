import { Injectable } from '@angular/core';
import { TierUploadQuota } from './../../shared/interfaces/tier-upload-quota';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import * as shajs from 'sha.js';
declare var gtag: Function;
@Injectable()
export class AnalyticsService {
  constructor(private readonly router: Router) {
    // Monitoramento de navegação: O serviço está observando eventos de navegação do Angular
    //(NavigationEnd) para enviar uma visualização de página (page view) ao Google Analytics
    //via a função gtag.
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.sendPageView(event.urlAfterRedirects);
      });
  }

  // Função de rastreamento de eventos genéricos
  public trackEvent(eventName: string, eventCategory: string, user: any): void {
    if (typeof gtag !== 'undefined') {
      gtag('event', eventName, {
        event_category: eventCategory,
        event_label: eventName,
        userId: this.saltAndHash(user.id), //transforma em hash para enviar ao GA
      });
    } else {
      console.error('gtag function is not defined');
    }
  }
  public pushToDataLayer(data: unknown): void {
    const w: any = window;
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push(data);
  }

  public sendPageView(url: string): void {
    if (typeof gtag !== 'undefined') {
      gtag('event', 'page_view', {
        page_location: url,
        page_title: document.title,
      });
    } else {
      console.error('gtag function is not defined');
    }
  }

  public sendEvent(eventName: string, params: object): void {
    if (typeof gtag !== 'undefined') {
      gtag('event', eventName, params);
    } else {
      console.error('gtag function is not defined');
    }
  }
  public submitUserLoginEvent(user: any): void {
    this.pushToDataLayer({
      event: 'userLogin',
      userId: this.saltAndHash(user.id),
    });
  }

  public submitRegistrationStartEvent(): void {
    this.pushToDataLayer({
      event: 'registrationStart',
    });
  }

  public submitRegistrationCompleteEvent(user: any): void {
    this.pushToDataLayer({
      event: 'registrationComplete',
      userId: this.saltAndHash(user.id),
    });
  }

  public submitRegistrationFailureEvent(user: any, errorMessage: string): void {
    this.pushToDataLayer({
      event: 'registrationFailure',
      userId: this.saltAndHash(user.id),
      language: user.lang,
      errorMessage,
    });
  }

  public submitFileUploadLimitEvent(amountReached: number): void {
    this.pushToDataLayer({
      event: 'fileUploadLimit',
      maxFileUploadsAllowed: amountReached,
    });
  }

  public submitFileUploadAttempt(nomeMd5: string) {
    this.pushToDataLayer({
      event: 'fileUploadAttempt',
      fileId: nomeMd5,
    });
  }

  public submitFileOpenEvent(nomeArquivo: string) {
    this.pushToDataLayer({
      event: 'fileOpen',
      fileId: nomeArquivo,
    });
  }

  public submitFileDeleteEvent(
    fileId: string,
    tierUploadQuota: TierUploadQuota,
  ) {
    const { maxFileUploadsAllowed, remainingFileUploads } = tierUploadQuota;
    this.pushToDataLayer({
      event: 'fileDelete',
      fileId,
      maxFileUploadsAllowed,
      remainingFileUploads,
    });
  }

  public saltAndHash(str: string): string {
    return shajs('sha256').update(`#[v1nEg4r-]${str}%buB1e$s`).digest('base64');
  }
}
