<div class="container-fluid">
  <div class="error-404">
    <h4><i class="agres-agres agres-4x"></i></h4>
    <div class="row">
      <h1 class="error-404__title">404</h1>
      <h4 class="error-404__subtitle">{{ 'error.404.title' | translate }}</h4>
      <p class="error-404__description">{{ 'error.404.description' | translate }}</p>
    </div>
    <app-button
      type="button"
      color="return"
      size="full"
      icon="agres-return"
      (btnClick)="return()"
      title="{{ 'global.back' | translate }}">
    </app-button>
  </div>
</div>
