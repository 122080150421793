import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { User } from '../../shared/models/user';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { decodeJwt } from 'jose';
import { environment } from 'environments/environment';

@Injectable()
export class AzureLoginService extends LoginService {
  constructor(http: HttpClient) {
    super(http);
  }

  public login(email: string, password: string): Observable<User> {
    let headers = new HttpHeaders({
      authorization: 'Basic ' + btoa(`${email}:${password}`),
      'Ocp-Apim-Subscription-Key': this.subscriptionKey,
    });

    if (environment.environmentName === 'LOCAL') {
      headers = new HttpHeaders({
        authorization: 'Basic ' + btoa(`${email}:${password}`),
      });
    }

    const loginUrl = this.UrlServiceOauthV1 + 'signin';
    const response = this.http
      .post(loginUrl, null, { headers, responseType: 'text' })
      .pipe(
        map(super.extractData),
        map(this.convert),
        catchError(super.serviceError),
      );

    return response;
  }

  private convert(token): User {
    const user = new User();
    const metadata = decodeJwt(token);
    user.access_token = token;
    user.metadata = metadata;
    return user;
  }
}
