import { Component, OnInit } from '@angular/core';
import {ExcelService} from './../../../core/services/excel.service';
import { NvgTrabalhoService } from 'app/core/services/nvg-trabalho.service';


@Component({
  selector: 'app-resumos',
  templateUrl: './resumos.component.html',
  styleUrls: ['./resumos.component.scss']
})
export class ResumosComponent implements OnInit {
    groupData: any;
    data: any = [{}];


  constructor(
    private excelService: ExcelService,
    public nvgTrabalhosService: NvgTrabalhoService) {}

  ngOnInit(): void {
    this.nvgTrabalhosService.getTerminals().subscribe(
      (trabalhos) => {
        this.data = trabalhos;
      });
  }
  exportAsXLSX():void {
    this.excelService.exportJSONAsExcelFile(this.data, 'export-to-excel');
  }
}
