import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './app.module';
import { NumberFormatPipe } from './pipes/shared/number-format.pipe';
import { ModalComponent } from './shared/components/modal/modal.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from './shared/components/button/button.component';
import { HorizontalScrollComponent } from './shared/components/horizontal-scroll/horizontal-scroll.component';
import { TabComponent } from './shared/components/tabs/tab.component';
import { TabsComponent } from './shared/components/tabs/tabs.component';
import { AgresIconsComponent } from './shared/components/agres-icons/agres-icons.component';
import { InputComponent } from './shared/components/input/input.component';
import { AlertComponent } from './shared/components/alert/alert.component';
import { RecommendationMapUploadComponent } from './shared/components/forms/recommendation-map-upload/recommendation-map-upload';
import { MenuPwaComponent } from './shared/components/menu-pwa/menu-pwa.component';
import { OffcanvasComponent } from './shared/components/offcanvas/offcanvas.component';
import { AgresSpinnerCanvasComponent } from './shared/components/agres-spinner-canvas/agres-spinner-canvas.component';

@NgModule({
  imports: [
    NgSelectModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    NumberFormatPipe,
    ModalComponent,
    BreadcrumbComponent,
    ButtonComponent,
    TabComponent,
    TabsComponent,
    HorizontalScrollComponent,
    AgresIconsComponent,
    InputComponent,
    AlertComponent,
    MenuPwaComponent,
    RecommendationMapUploadComponent,
    OffcanvasComponent,
    AgresSpinnerCanvasComponent,
  ],
  exports: [
    NumberFormatPipe,
    ModalComponent,
    BreadcrumbComponent,
    ButtonComponent,
    TabComponent,
    TabsComponent,
    HorizontalScrollComponent,
    AgresIconsComponent,
    InputComponent,
    AlertComponent,
    MenuPwaComponent,
    RecommendationMapUploadComponent,
    OffcanvasComponent,
    AgresSpinnerCanvasComponent,
  ],
})
export class AppSharedModule {
  static forRoot(): ModuleWithProviders<AppSharedModule> {
    return {
      ngModule: AppSharedModule,
      providers: [],
    };
  }
}
