import { HttpClient } from '@angular/common/http';
import { FileManagerService } from 'app/core/services/file-manager.service';
import { Observable } from 'rxjs';

export class UserFilesManager {
  constructor(
    private fileManagerService: FileManagerService,
    public httpClient: HttpClient,
  ) {}

  getUserFiles(userLogged: string): Observable<any> {
    return this.fileManagerService.getById(userLogged);
  }

  downloadUserFiles(
    userLogged: string,
    fileNamesToDownload: Array<string>,
    fileType: string,
    id?: string,
  ) {
    const filesWithFolder = fileNamesToDownload.map((fileName) =>
      id
        ? `${userLogged}/${fileType}/${id}/${fileName}`
        : `${userLogged}/${fileType}/${fileName}`,
    );

    this.fileManagerService
      .signedUrl(filesWithFolder)
      .subscribe((sasUrls: Array<string>) => {
        sasUrls.forEach((url, index) => {
          const file = fileNamesToDownload[index];
          this.fileManagerService.downloadFile(url, file);
        });
      });
  }

  async getFiles(
    userLogged: string,
    fileNamesToDownload: Array<string>,
    fileType: string,
    id?: string,
  ): Promise<File> {
    const filesWithFolder = fileNamesToDownload.map((fileName) =>
      id
        ? `${userLogged}/${fileType}/${id}/${fileName}`
        : `${userLogged}/${fileType}/${fileName}`,
    );
    return new Promise((resolve) => {
      this.fileManagerService
        .signedUrl(filesWithFolder)
        .subscribe((sasUrls: Array<string>) => {
          sasUrls.forEach((url, index) => {
            const file = fileNamesToDownload[index];
            this.fileManagerService
              .getStorageData(url, file)
              .then((response) => resolve(response));
          });
        });
    });
  }

  private uploadUserFiles(
    userLogged: string,
    fileNamesToUpload: Array<string>,
    filesToUpload: Array<File>,
    fileType: string,
    id?: string,
  ): Promise<void> {
    const promises: Array<Promise<Object>> = [];
    const filesWithFolder = fileNamesToUpload.map((fileName) =>
      id
        ? `${userLogged}/${fileType}/${id}/${fileName}`
        : `${userLogged}/${fileType}/${fileName}`,
    );

    return new Promise((resolve, reject) => {
      this.fileManagerService.signedUrl(filesWithFolder).subscribe(
        (sasUrls: Array<string>) => {
          sasUrls.forEach((url, index) => {
            if (filesToUpload && filesToUpload[index]) {
              const file = filesToUpload[index];
              const uploadPromise = this.httpClient
                .put(url, file, {
                  headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': file.type,
                  },
                })
                .toPromise();

              promises.push(uploadPromise);
            }
          });

          Promise.all(promises)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        },
        (error) => reject(error),
      );
    });
  }

  saveData(
    userLogged: string,
    fileNamesToSave: Array<string>,
    fileType: string,
    id?: string,
    fileClassification?: {
      recommendation_files: Array<string>;
      guides_files: Array<string>;
      boundaries: Array<string>;
    },
  ): Observable<any> {
    return new Observable((observer) => {
      this.fileManagerService.getById(userLogged).subscribe(
        ({ response }) => {
          response = response || { files: {} };

          let responseFiles: Array<string> = [];

          if ((fileType === 'devices' || fileType === 'fields') && id) {
            if (!response.files[fileType]) {
              response.files[fileType] = {};
            }
            if (!response.files[fileType][id]) {
              if (fileType === 'devices') {
                response.files[fileType][id] = [];
              } else {
                response.files[fileType][id] = {};
                response.files[fileType][id].recommendation_files = [];
                response.files[fileType][id].guides_files = [];
                response.files[fileType][id].boundaries = [];
              }
            }
            responseFiles = response.files[fileType][id];
          } else {
            responseFiles = response.files[fileType] || [];
          }
          let newFiles;
          if (fileClassification) {
            newFiles = {};
            newFiles.recommendation_files = [];
            newFiles['recommendation_files'] =
              fileClassification.recommendation_files.filter((file) => {
                return responseFiles['recommendation_files']
                  ? !responseFiles['recommendation_files'].includes(file)
                  : true;
              });
            newFiles.guides_files = [];
            newFiles['guides_files'] = fileClassification.guides_files.filter(
              (file) => {
                return responseFiles['guides_files']
                  ? !responseFiles['guides_files'].includes(file)
                  : true;
              },
            );
            newFiles.boundaries = [];
            newFiles['boundaries'] = fileClassification.boundaries.filter(
              (file) => {
                return responseFiles['boundaries']
                  ? !responseFiles['boundaries'].includes(file)
                  : true;
              },
            );
          } else {
            newFiles = fileNamesToSave.filter(
              (file) => !responseFiles.includes(file),
            );
          }

          if (typeof newFiles === 'object' && fileType === 'fields') {
            responseFiles['recommendation_files'] = (
              responseFiles['recommendation_files'] || []
            ).concat(newFiles.recommendation_files);

            responseFiles['guides_files'] = (
              responseFiles['guides_files'] || []
            ).concat(newFiles.guides_files);
            responseFiles['boundaries'] = (
              responseFiles['boundaries'] || []
            ).concat(newFiles.boundaries);
          } else if (newFiles.length > 0) {
            responseFiles.push(...newFiles);
          }

          if (newFiles.length > 0 || typeof newFiles === 'object') {
            if ((fileType === 'devices' || fileType === 'fields') && id) {
              response.files[fileType][id] = responseFiles;
            } else {
              response.files[fileType] = responseFiles;
            }

            const documentData = {
              id: response.id || userLogged,
              files: response.files,
              _rid: response._rid || '',
              _self: response._self || '',
              _etag: response._etag || '',
              _attachments: response._attachments || '',
              _ts: response._ts || Math.floor(Date.now() / 1000),
            };

            this.fileManagerService.save(documentData).subscribe(
              (result) => observer.next(result),
              (error) => observer.error(error),
            );
          } else {
            observer.next('The file already exists');
          }
        },
        (error) => observer.error(error),
      );
    });
  }

  saveDataAndUploadUserFiles(
    userLogged: string,
    filesToUpload: Array<File>,
    fileType: string,
    id?: string,
    fileClassification?: {
      recommendation_files: Array<string>;
      guides_files: Array<string>;
      boundaries: Array<string>;
    },
  ): Observable<any> {
    let fileNamesToUpload: Array<string> = [];

    fileNamesToUpload = filesToUpload.map((file) => file.name);

    return new Observable((observer) => {
      this.uploadUserFiles(
        userLogged,
        fileNamesToUpload,
        filesToUpload,
        fileType,
        id,
      )
        .then(() => {
          this.saveData(
            userLogged,
            fileNamesToUpload,
            fileType,
            id,
            fileClassification,
          ).subscribe(
            (result) => observer.next(result),
            (error) => observer.error(error),
            () => observer.complete(),
          );
        })
        .catch((error) => observer.error(error));
    });
  }
}
