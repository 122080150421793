import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'app-modal-graph',
    templateUrl: './modal-graph.component.html',
    styleUrls: ['./modal-graph.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class ModalGraphComponent {
    constructor() {
        this.modal = false;
        this.modalChange = new EventEmitter<boolean>();
    }

    @Input() public title;

    public lineChartLegend = false;
    @Input() modal: boolean;
    @Output() modalChange: EventEmitter<boolean>;

    @Input() public lineChartOptions: any = {
        responsive: true,
        scales: {
            xAxes: [
                {
                    ticks: {
                        display: false,
                    },
                },
            ],
        },
    };

    @Input() public lineChartType = 'line';
    @Input() public lineChartLabels: Array<any>;
    @Input() public selectedGrapColor: Array<any> = [
        {
            backgroundColor: 'rgba(0,255,255,0.3)',
            borderColor: 'rgba(0,255,255, 1.0)',
            pointBackgroundColor: 'rgb(0,255,255)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        },
    ];

    @Input() public selectedGrapData: Array<any>;

    hideModal(): void {
        this.modal = false;
        this.modalChange.emit(this.modal);
    }
}
