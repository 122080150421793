<div class="offcanvas" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas__header">
    <p class="offcanvas__header--title">{{ 'fields.tutorial-field-title' | translate }}</p>
    <button
      type="button"
      class="offcanvas__header--btn-close offcanvas__btn-close"
      data-bs-dismiss="offcanvas"
      aria-label="Close"></button>
  </div>
  <div class="offcanvas__body">
    <div
      class="offcanvas__body--card collapsed"
      id="headingAddTalhao"
      (click)="showCreateFieldInstrutions()"
      data-bs-toggle="collapse"
      data-bs-target="#collapseAddTalhao"
      aria-expanded="false"
      aria-controls="collapseAddTalhao">
      <div class="offcanvas__body--card--text">
        <i class="agres-field"></i>
        <p>{{ 'fields.tutorial-field' | translate }}</p>
      </div>
    </div>
    <div
      id="collapseAddTalhao"
      class="accordion-collapse collapse"
      aria-labelledby="headingAddTalhao"
      data-bs-parent="#accordionExample">
      <div class="offcanvas__accordion offcanvas__accordion--justify">
        <p>
          <strong>
            <u>{{ 'fields.tutorial-field-step-1' | translate }}</u>
          </strong>
        </p>
        <p>
          {{ 'fields.tutorial-field-step-1-text' | translate }}
          <strong>
            {{ 'fields.tutorial-field-step-1-text-bold' | translate }}
          </strong>
        </p>
        <p>
          <strong>
            <u>
              {{ 'fields.tutorial-field-step-2' | translate }}
            </u>
          </strong>
        </p>
        <p>
          {{ 'fields.tutorial-field-step-2-text' | translate }}
          <strong>
            {{ 'fields.tutorial-field-step-2-text-bold' | translate }}
          </strong>
        </p>
        <p>
          <strong>
            <u>
              {{ 'fields.tutorial-field-step-3' | translate }}
            </u>
          </strong>
        </p>
        <p>
          {{ 'fields.tutorial-field-step-3-text' | translate }}
        </p>
        <p>
          <strong>
            <u>{{ 'fields.tutorial-field-step-4' | translate }}</u>
          </strong>
        </p>
        <p>
          {{ 'fields.tutorial-field-step-4-text' | translate }}
          <strong>{{ 'fields.tutorial-field-step-4-text-bold' | translate }}</strong>
        </p>
        <p>
          <strong>
            <u>{{ 'fields.tutorial-field-step-5' | translate }}</u>
          </strong>
        </p>
        <p>
          {{ 'fields.tutorial-field-step-5-text' | translate }}
          <strong>{{ 'fields.tutorial-field-step-5-text-bold' | translate }}</strong>
        </p>
        <p>{{ 'fields.tutorial-field-step-obs' | translate }}</p>
        <p>
          <em>{{ 'fields.tutorial-field-step-obs-1' | translate }}</em>
        </p>
        <p>
          <em>
            {{ 'fields.tutorial-field-step-obs-2' | translate }}
            <strong>{{ 'fields.tutorial-field-step-obs-2-bold' | translate }}</strong>
          </em>
        </p>
        <hr />
        <p>{{ 'fields.tutorial-field-title-video' | translate }}:</p>
        <div class="offcanvas__accordion--embed-video-responsive">
          <div class="embed-responsive embed-responsive-16by9">
            <video class="embed-responsive-item" [src]="videoPath" controls></video>
          </div>
        </div>
      </div>
    </div>
    <div
      class="offcanvas__body--card collapsed"
      id="headingEditTalhao"
      (click)="showEditFieldInstrutions()"
      data-bs-toggle="collapse"
      data-bs-target="#collapseEditTalhao"
      aria-expanded="false"
      aria-controls="collapseEditTalhao">
      <div class="offcanvas__body--card--text">
        <i class="agres-field"></i>
        <p>{{ 'fields.tutorial-field-edit' | translate }}</p>
      </div>
    </div>
    <div
      id="collapseEditTalhao"
      class="accordion-collapse collapse"
      aria-labelledby="headingEditTalhao"
      data-bs-parent="#accordionExample">
      <div class="offcanvas__accordion offcanvas__accordion--justify">
        <p>
          <strong>
            <u>
              {{ 'fields.tutorial-field-step-1' | translate }}:
              {{ 'fields.tutorial-field-step-1-edit' | translate }}
            </u>
          </strong>
        </p>
        <p>
          {{ 'fields.tutorial-field-step-1-edit-text' | translate }}
          <strong> {{ 'fields.tutorial-field-step-1-edit-text-bold-1' | translate }} </strong>,
          {{ 'fields.tutorial-field-step-1-edit-text-1' | translate }}
          <strong> {{ 'fields.tutorial-field-step-1-edit-text-bold-2' | translate }} </strong>,
          {{ 'fields.tutorial-field-step-1-edit-text-1' | translate }}
          <strong>{{ 'fields.tutorial-field-step-1-edit-text-bold-3' | translate }} </strong>,
          {{ 'fields.tutorial-field-step-1-edit-text-2' | translate }}
          <strong>
            {{ 'fields.tutorial-field-step-1-edit-text-bold-4' | translate }}
          </strong>
          {{ 'fields.tutorial-field-step-1-edit-text-3' | translate }}
        </p>
        <p>
          <strong>
            <u>
              {{ 'fields.tutorial-field-step-2' | translate }}:
              {{ 'fields.tutorial-field-step-2-edit' | translate }}
            </u>
          </strong>
        </p>
        <p>
          {{ 'fields.tutorial-field-step-2-edit-text' | translate }}
          <strong> {{ 'fields.tutorial-field-step-2-edit-text-bold' | translate }}</strong>
        </p>
        <p>
          <strong>
            <u
              >{{ 'fields.tutorial-field-step-3' | translate }}:
              {{ 'fields.tutorial-field-step-3-edit' | translate }}
            </u>
          </strong>
        </p>
        <p>{{ 'fields.tutorial-field-step-3-edit-text' | translate }}</p>
        <p>
          <strong>{{ 'fields.tutorial-field-step-3-edit-text-bold' | translate }}</strong>
        </p>
        <p>
          {{ 'fields.tutorial-field-step-3-edit-text-1' | translate }}
          <strong>"{{ 'fields.tutorial-field-step-3-edit-text-1-bold' | translate }}"</strong>
        </p>
        <p>
          {{ 'fields.tutorial-field-step-3-edit-text-3' | translate }}
          <strong>"{{ 'fields.tutorial-field-step-3-edit-text-3-bold' | translate }}".</strong>
        </p>
        <p>
          <strong>{{ 'fields.tutorial-field-step-3-edit-text-4' | translate }}</strong>
        </p>
        <p>{{ 'fields.tutorial-field-step-3-edit-text-5' | translate }}</p>
        <p>
          {{ 'fields.tutorial-field-step-3-edit-text-3' | translate }}
          <strong>"{{ 'fields.tutorial-field-step-3-edit-text-3-bold' | translate }}".</strong>
        </p>
        <hr />
        <p>{{ 'fields.tutorial-field-title-video' | translate }}:</p>
        <div class="offcanvas__accordion--embed-video-responsive">
          <div class="embed-responsive embed-responsive-16by9">
            <video class="embed-responsive-item" [src]="videoPath" controls></video>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
