import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppConstants } from 'app/app.constants';
import { Weather } from 'app/shared/models/weather';
import { FleetMonitoringService } from 'app/core/services/fleet-monitoring.service';

type CardList = Array<Card>;
type Card = Array<Line>;
type Line = {
  label: string;
  value: any;
};

@Component({
  selector: 'app-weather-tab',
  templateUrl: './weather-tab.component.html',
  styleUrls: ['./weather-tab.component.scss'],
})
export class WeatherTabComponent implements OnInit, OnDestroy {
  weather: Weather;
  tempF: number;
  weatherInterval: NodeJS.Timeout;
  deviceId: string;
  lines: Line[] = [];
  listOfCards: CardList = [];

  @Input() lat: number;
  @Input() long: number;

  constructor(private fleetMonitoringService: FleetMonitoringService) {}

  ngOnInit(): void {
    this.deviceId = localStorage.getItem(
      AppConstants.KEYS_LOCAL_STORAGE.DEVICE_ID,
    );
    if (this.deviceId && !this.weatherInterval) {
      this.updateWeatherData();
      this.startWeatherInterval();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.weatherInterval);
  }

  private updateListOfCards(): void {
    this.updateValues();
    this.listOfCards = this.groupLinesIntoCards(this.lines, 3);
  }

  private updateValues(): void {
    const formatValue = (value: number | undefined): string =>
      value !== undefined ? `${Math.round(value)}` : '-';

    this.lines = [
      {
        label: 'monitoring.maps.title-temperature',
        value: `${formatValue(this.weather?.Temperatura)} ºC`,
      },
      {
        label: 'monitoring.maps.title-rain',
        value: `${formatValue(this.weather?.Precipitacao)} %`,
      },
      {
        label: 'monitoring.maps.title-humidity',
        value: `${formatValue(this.weather?.Umidade)} %`,
      },
      {
        label: 'monitoring.maps.speed-wind',
        value: `${formatValue(this.weather?.Vento)} km/h`,
      },
    ];
  }

  private groupLinesIntoCards(lines: Line[], linesPerCard: number): CardList {
    return lines.reduce((cards, line, index) => {
      const cardIndex = Math.floor(index / linesPerCard);
      cards[cardIndex] = cards[cardIndex] || [];
      cards[cardIndex].push(line);
      return cards;
    }, [] as CardList);
  }

  private startWeatherInterval(): void {
    this.weatherInterval = setInterval(() => {
      this.updateWeatherData();
    }, 500_000);
  }

  private updateWeatherData(): void {
    if (this.lat && this.long) {
      this.fleetMonitoringService.getWeather(this.lat, this.long).subscribe({
        next: (res: Weather) => {
          this.weather = res;
          this.tempF = this.convertToFahrenheit(this.weather.Temperatura);
          this.updateListOfCards();
        },
        error: (error) => console.error(error),
      });
    }
  }

  private convertToFahrenheit(tempCelsius: number): number {
    return (tempCelsius * 9) / 5 + 32;
  }
}
