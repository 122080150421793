import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from './service.base';

@Injectable()
export class NvgShapefileService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  getShapefilePressignedURL(nvgName, cropName, eventName) {
    return this.http
      .get(
        `${this.UrlServiceV1}shapefile-service?id=${nvgName}&event_name=${eventName}&crop_name=${cropName}`,
        { ...this.obterAuthHeader(), responseType: 'text' },
      )
      .pipe(map(this.extractData<string>), catchError(this.serviceError));
  }
}
