import { Injectable } from '@angular/core';

// cdk
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerViewComponent } from '../../modules/spinner-view/spinner-view.component';
// rxjs
import { Subject } from 'rxjs';
import { scan, map, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class LoadingSpinnerService {
    public spinnerTopRef: OverlayRef;

    public spin$: Subject<number> = new Subject();

    public translateKeyMessage: string;

    constructor(public overlay: Overlay) {
        this.spinnerTopRef = this.overlay.create({
            hasBackdrop: true,
            positionStrategy: this.overlay
                .position()
                .global()
                .centerHorizontally()
                .centerVertically(),
        });

        this.spin$
            .asObservable()
            .pipe(
                scan((acc, next) => {
                    if (!next) return 0;
                    return acc + next >= 0 ? acc + next : 0;
                }, 0),
                map((val) => val > 0),
                distinctUntilChanged()
            )
            .subscribe((res) => {
                if (res) {
                    const componentRef = this.spinnerTopRef.attach(
                        new ComponentPortal(SpinnerViewComponent)
                    );
                    componentRef.instance.translateKeyToDisplay =
                        this.translateKeyMessage;
                } else if (this.spinnerTopRef.hasAttached()) {
                    this.spinnerTopRef.detach();
                }
            });
    }
    show(translateKeyMessage: string) {
        this.translateKeyMessage = translateKeyMessage;
        this.spin$.next(1);
    }
    hide() {
        this.spin$.next(-1);
    }
}
