<div class="middle-box text-center animated fadeInDown">
  <h1>500</h1>
  <h3 class="font-bold">{{ 'error.500.title' | translate }}</h3>
  <div class="error-desc">
    {{ 'error.500.description' | translate }}
    <div class="text-big">
      {{ 'error.500.description-complete' | translate }}
      <a href="#"
        ><u>{{ 'error.500.contact' | translate }} </u></a
      >
    </div>
    <form class="form-inline m-t" role="form">
      <button type="submit" class="btn btn-primary" (click)="voltar()">
        {{ 'global.back' | translate }}
      </button>
    </form>
  </div>
</div>
