<form [formGroup]="registrarForm" novalidate (ngSubmit)="editUsuario()">
  <div class="modal__body">
    <div class="row">
      <div class="modal__body--form col-md-12" [ngClass]="{ 'has-error': displayMessage.Nome }">
        <label class="modal__body--form---label">{{ 'global.name' | translate }}</label>
        <input type="text" class="input-field" formControlName="Nome" />
        <span class="text-danger" *ngIf="displayMessage.Nome">
          <p [innerHTML]="displayMessage.Nome"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-6" [ngClass]="{ 'has-error': displayMessage.email }">
        <label class="modal__body--form---label">{{ 'global.mail' | translate }}</label>
        <input type="text" class="input-field" formControlName="email" />
        <span class="text-danger" *ngIf="displayMessage.email">
          <p [innerHTML]="displayMessage.email"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-6" [ngClass]="{ 'has-error': displayMessage.Identificacao }">
        <label>{{ 'global.identification' | translate }}</label>
        <input
          type="text"
          class="input-field"
          formControlName="Identificacao"
          [mask]="getIdentificacaoMask()"
          id="cpfCnpj"
          maxlength="18" />
        <span class="text-danger" *ngIf="displayMessage.Identificacao">
          <p [innerHTML]="displayMessage.Identificacao"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label">{{ 'global.phone' | translate }}</label>
        <input type="tel" class="input-field" formControlName="Telefone" [mask]="getTelefoneMask()" />
        <span class="text-danger" *ngIf="displayMessage.phoneNumber">
          <p [innerHTML]="displayMessage.phoneNumber"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label">{{ 'global.country' | translate }}</label>
        <input type="text" class="input-field" formControlName="Pais" />
        <span class="text-danger" *ngIf="displayMessage.country">
          <p [innerHTML]="displayMessage.country"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label">{{ 'global.city' | translate }}</label>
        <input type="text" class="input-field" formControlName="Cidade" />
        <span class="text-danger" *ngIf="displayMessage.city">
          <p [innerHTML]="displayMessage.city"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label">{{ 'global.state' | translate }}</label>
        <input type="text" class="input-field" formControlName="Estado" />
        <span class="text-danger" *ngIf="displayMessage.stateOrProvince">
          <p [innerHTML]="displayMessage.stateOrProvince"></p>
        </span>
      </div>
      <div class="modal__body--form visually-hidden">
        <input type="email" class="input-field" />
      </div>
      <div class="modal__body--form visually-hidden">
        <input type="password" class="input-field" />
      </div>
      <button class="btn tbn-secondary" type="button" (click)="mostrarSenha()" *ngIf="!mostrarDivSenha">
        {{ 'global.eye' | translate }}
      </button>
      <div class="divSenha" *ngIf="mostrarDivSenha">
        <div class="modal__body--form" [ngClass]="{ 'has-error': displayMessage.Senha }">
          <label class="modal__body--form---label" style="text-align: left">{{ 'global.password' | translate }}</label>
          <input
            type="password"
            class="input-field"
            formControlName="Senha"
            (keypress)="verifyMaxLength($event)"
            minlength="6"
            maxlength="16" />
          <span class="label-dica-senha"
            ><small>{{ 'global.validate,password-minlength' | translate }}</small></span
          >
          <span class="text-danger" *ngIf="displayMessage.Senha">
            <p [innerHTML]="displayMessage.Senha"></p>
          </span>
          <span class="text-danger" *ngIf="senhaInvalidaMinLength">
            <p [innerHTML]="">
              {{ 'global.validate,password-maxlength' | translate }}
            </p>
          </span>
        </div>
        <div
          class="modal__body--form"
          [ngClass]="{
            'has-error': displayMessage.SenhaConfirmacao
          }">
          <label class="modal__body--form---label" style="text-align: left">{{
            'global.confirm-password' | translate
          }}</label>
          <input
            type="password"
            class="input-field"
            formControlName="SenhaConfirmacao"
            (keypress)="verifyMaxLength($event)"
            minlength="6"
            maxlength="16" />
          <span class="label-dica-senha"
            ><small>{{ 'global.password' | translate }}</small></span
          >
          <span class="text-danger" *ngIf="displayMessage.SenhaConfirmacao">
            <p [innerHTML]="displayMessage.SenhaConfirmacao"></p>
          </span>
          <span class="text-danger" *ngIf="senhaInvalidaIgual">
            <p [innerHTML]="">
              {{ 'global.validate.password-wrong' | translate }}
            </p>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal__linha"></div>
  <div class="modal__footer">
    <app-button
      type="submit"
      color="primary"
      icon="agres-close"
      title="{{ 'global.save' | translate }}"
      [disabled]="disableButton || !registrarForm.valid"
      data-bs-dismiss="modal">
    </app-button>
    <app-button
      type="button"
      color="danger"
      icon="agres-close"
      title="{{ 'global.cancel' | translate }}"
      data-bs-dismiss="modal"
      (btnClick)="hideModal()">
    </app-button>
  </div>
</form>
