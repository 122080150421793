import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent {
  title = 'Table Dynamic'
  data: Array<any>;
  @Input() columns: Array<{
    field: string,
    headerName: string,
    width?: number,
    hidden?: boolean,
    valueGetter?: (value) => string
  }> = [
      {
        field: 'id',
        headerName: '',
        hidden: true
      },
      {
        field: 'firstName',
        headerName: 'translation.key.fName',
        width: 50
      },
      {
        field: 'lastName',
        headerName: 'translation.key.lName',
        width: 50
      },
      {
        field: 'age',
        headerName: 'translation.key.age',
        width: 10
      }
    ]
  @Input() rows: Array<{ [key: string]: String | Object }> = [
    { firstName: 'John', lastName: 'Doe', age: '35' },
    { firstName: 'Michael', lastName: 'Smith', age: '39' },
    { firstName: 'Michael', lastName: 'Jordan', age: '45' },
    { firstName: 'Tanya', lastName: 'Blake', age: '47' }
  ]

  constructor() {
  }

  getCellValues(row: { [key: string]: String | Object }) {
    const rowValues = []
    this.columns.forEach((column) => {
      let cell = row[column.field]
      if (cell instanceof Object)
        cell = column.valueGetter(cell)
      rowValues.push(cell)
    })
    return rowValues
  }
  getColumnStyle(column) {
    const proportion = column.width / this.columns
      .map(column => column.width)
      .reduce((accumulator, currentValue) => accumulator + currentValue)
    return { "max-width": `${proportion}%` }
  }

}