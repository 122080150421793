import { AppConstants } from 'app/app.constants';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { AnalyticsService } from 'app/core/services/analytics.service';
import { ConfirmacaoModalService } from 'app/core/services/confirmacaoModal.service';
import { NvgFilesService } from 'app/core/services/nvg-files.service';
import {
  NvgHistService,
  TaskHistList,
} from 'app/core/services/nvg-hist.service';
import { NvgListFilesService } from 'app/core/services/nvg-list-files.service';
import { NvgTrabalhoService } from 'app/core/services/nvg-trabalho.service';
import { ParametrizacaoService } from 'app/core/services/parametrizacao.service';
import { ReportService } from 'app/core/services/report.service';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { _roleEnumUserClass } from 'app/utils/role.utils';
import { FileUploader } from 'ng2-file-upload';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';

import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { TierUploadQuota } from '../../../shared/interfaces/tier-upload-quota';

interface MenuItem {
  label: string;
  callback: (a: string, mouseEvent: MouseEvent) => void;
}
interface RasterMapImagesB64 {
  backgroundFileB64s: Array<string>;
  rasterFileB64s: Array<string>;
  backgroundImageB64: string;
}
type TaskCard = {
  id: string;
  fileName: string;
  status: string;
  equipment: string;
  msgError?: string;
  coverArea?: number;
  endTimestamp?: number;
  operation?: string;
  expired?: boolean;
  statusChangeEventEmitter?: EventEmitter<string>;
};

@Component({
  selector: 'app-gestao-arquivo',
  templateUrl: 'gestao-arquivo.component.html',
  styleUrls: ['gestao-arquivo.component.scss'],
})
export class GestaoArquivoComponent implements OnInit, OnDestroy {
  @ViewChild('cardNotificationGFreeTier')
  cardNotificationGFreeTier: TemplateRef<any>;
  @Input() menuItems: MenuItem[];
  listFile: Array<TaskCard> = [];
  initialListFile: TaskCard[] = [];
  isHovering: boolean;
  files: File[] = [];
  listMd5 = [];
  nvgsJaExistem = [];
  nvgsNovos = [];
  nvgs = [];
  nvgArrayMd5Bucket = [];
  listaUpload = [];
  arquivosStorage = [];
  fileNameArr = [];
  fileDataArr = [];
  presignArr = [];
  firstLine;
  nvgData;
  filesLabel;
  fileLength;
  deviceList: Array<any>;
  fileName;
  fileData;
  filesData;
  presign;
  timestamp_start_upload;
  expirou = false;
  isFreeTier = false;
  blockUploadNvgFreeTier = false;
  mostrarModalConfirmacao = false;
  mensagemModalUploadFileNvg = '';
  mensagemModalConfirmacao = '';
  tipoModalConfirmacao = '';
  tipoModalUploadFileNvg = '';
  tituloModalConfirmacao = '';
  tituloModalUploadFileNvg = '';
  modalTranslateButtonConfirmText = '';
  modalTranslateButtonCancelText = '';
  idArquivoSelecionadoExcluir: any;
  mostrarModalCompatibilidade = false;
  mensagemModalCompatibilidade = '';
  tipoModalCompatibilidade = '';
  tituloModalCompatibilidade = '';
  idArquivoSelecionadoCompatibilidade: any;
  excludeAllFiles = false;
  deletingFiles = false;
  schedulingNotify = false;
  parametrosSessionStorage: any;
  miliSegundosValidadeArquivoNVG;
  qtdMaximaNvgsFreeTier;
  itensPerSection: number = 16;
  section: number = 0;
  isLoading: boolean = true;
  valueInputSearch: string;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  intervalRefreshListFiles: any;
  refreshSubscribed: any;
  errorsVisibleNotifyNvg = ['archive.format_type_not_supported'];
  CONST_STATUS = {
    COMPLETED: 'completed',
    PROCESSING: 'processing',
    ERROR: 'error',
  };
  DEVICE_MODEL = AppConstants.DEVICE_MODEL;
  STATUS_CONFIRMED_DELETE = 'confirmado';
  HORA_EM_MILISEGUNDOS = 3600000;
  unexpectedErrorTranslationStringKey = 'archive.unexpected';
  mapImages: RasterMapImagesB64;
  mapImageIndex = {
    inst_rate: 0,
    speed: 1,
    overlap: 2,
  };
  openMenu = false;
  selectedCardId: string = '';
  countUserNVGFiles: number;
  searchWorksSubject = new Subject<string>();
  listFilesIsEmpty: boolean = false;
  userId: string;
  fieldName: string;
  dateMaxNvg: number;
  constructor(
    public translateService: TranslateService,
    public AlertService: AlertService,
    public nvgHistService: NvgHistService,
    public nvgFilesService: NvgFilesService,
    public analyticsService: AnalyticsService,
    public router: Router,
    public nvgListFilesService: NvgListFilesService,
    public nvgTrabalhoService: NvgTrabalhoService,
    public confirmacaoModalService: ConfirmacaoModalService,
    public http: HttpClient,
    public parametrizacaoService: ParametrizacaoService,
    public changeDetection: ChangeDetectorRef,
    public showModalSubject: Subject<boolean>,
    public reportService: ReportService,
    public route: ActivatedRoute,
    public readonly loadingAgresSpinner: AgresSpinnerService,
    public showCardsSubject: Subject<boolean>,
    private datePipe: DatePipe,
  ) {
    const usuarioLogado = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    this.userId = usuarioLogado.id;
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.sendPageView('/gestao-operacao/gestao-arquivo');
      this.analyticsService.trackEvent(
        'Accessed the task report page',
        'Task Report',
        this.userId,
      );
    }
    this.uploader = new FileUploader({
      disableMultipart: true,
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, _reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date(),
          });
        });
      },
    });

    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = '';
    this.uploader.response.subscribe((res) => (this.response = res));
  }

  ngAfterViewInit() {
    this.translateService.get('global.loading.operation').subscribe((res) => {
      this.loadingAgresSpinner.toShow(res);
    });
  }

  ngOnInit() {
    this.fieldName = this.route.snapshot.queryParams.fieldName;
    this.confirmacaoModalService.changeData(null);
    this.refreshSubscribed = undefined;
    this.menuItems = [
      {
        label: 'global.delete',
        callback: this.getDeleteNvgCallback(),
      },
    ];

    this.isFreeTier = _roleEnumUserClass.isRoleFreetier();

    if (this.isFreeTier) {
      this.aplicarConfiguracoesParaFreeTier();
    } else {
      this.carregarArquivosHist();
    }

    this.confirmacaoModalService.currentData.subscribe((data) => {
      if (data != null) {
        if (
          this.idArquivoSelecionadoExcluir !== undefined &&
          this.idArquivoSelecionadoExcluir !== ''
        ) {
          if (data.message === this.STATUS_CONFIRMED_DELETE) {
            const idNvg = data.conteudo;
            this.excluirNvg(idNvg);
          }

          this.idArquivoSelecionadoExcluir = '';
        } else if (this.excludeAllFiles) {
          if (data.message === this.STATUS_CONFIRMED_DELETE) {
            this.deleteAllFiles();
          }
        } else if (
          this.idArquivoSelecionadoCompatibilidade !== undefined &&
          this.idArquivoSelecionadoCompatibilidade !== ''
        ) {
          if (data.message === this.STATUS_CONFIRMED_DELETE) {
            this.agendarNotificacaoNvg();
          }
        }
      }
    });

    this.searchWorksSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchTerm: string) => {
        const filteredItems: Array<string> = [];
        if (searchTerm !== '') {
          this.listFile = this.initialListFile.filter((item: any, _i) => {
            const partialMatch: boolean = item?.fileName
              ?.toLowerCase()
              ?.includes(searchTerm.toLocaleLowerCase());
            if (partialMatch) {
              filteredItems.push(item.id);
            }
            return partialMatch;
          });
        }

        this.section = 0;
        this.valueInputSearch = searchTerm;
        this.carregarArquivosHist(filteredItems);
      });
    this.analyticsService.sendPageView('/report-of-tasks/');
  }

  ngOnDestroy() {
    this.loadingAgresSpinner.toHide();
    clearTimeout(this.refreshSubscribed);
  }

  scheduleStatusUpdateForProcessingNVGs(processingNVGs: Partial<TaskHistList>) {
    this.refreshSubscribed = setTimeout(() => {
      this.nvgHistService
        .getMultipleHist(processingNVGs.data.map((nvg) => nvg.id))
        .subscribe((nvgsToUpdate) => {
          const data = this.getProcessingNVGs({ data: nvgsToUpdate });
          const stillProcessing = { data };
          this.updateNVGsStatus(nvgsToUpdate);
          if (stillProcessing.data.length > 0)
            this.scheduleStatusUpdateForProcessingNVGs(stillProcessing);
        });
    }, 5000);
  }

  updateNVGsStatus(nvgsToUpdate: Record<string, any>[]) {
    for (const nvg of nvgsToUpdate) {
      const indexToUpdate = this.listFile.findIndex(
        (nvgObj) => nvgObj.id === nvg.id,
      );

      if (indexToUpdate >= 0) {
        if (nvg.status === this.CONST_STATUS.ERROR) {
          const errorMsg = this._getErrorMessage(nvg);
          this.listFile[indexToUpdate].msgError = errorMsg;
        }
        this.listFile[indexToUpdate].status = nvg.status;
        if (nvg.status === this.CONST_STATUS.COMPLETED) {
          this.listFile[indexToUpdate] = this._buildNVGObject(
            nvg,
            this.dateMaxNvg,
          );
          this.listFile[indexToUpdate].statusChangeEventEmitter?.emit(
            this.CONST_STATUS.COMPLETED,
          );
        }
      }
    }
  }

  getProcessingNVGs(nvgs: { data: Record<string, any>[] }) {
    return nvgs.data.filter(
      (nvgObj) => nvgObj.status === this.CONST_STATUS.PROCESSING,
    );
  }

  aplicarConfiguracoesParaFreeTier() {
    this.parametrosSessionStorage = window.sessionStorage.getItem(
      AppConstants.KEYS_SESSION_STORAGE.PARAMETROS,
    );

    if (!this.parametrosSessionStorage) {
      this.parametrizacaoService.get().subscribe((parametros) => {
        window.sessionStorage.setItem(
          AppConstants.KEYS_SESSION_STORAGE.PARAMETROS,
          JSON.stringify(parametros),
        );

        this.miliSegundosValidadeArquivoNVG =
          parseInt(parametros.NVGValidHours, 10) * this.HORA_EM_MILISEGUNDOS;
        this.qtdMaximaNvgsFreeTier = parseInt(parametros.NVGsMaxFreeTier, 10);

        this.carregarArquivosHist();
      });
    } else {
      const jsonParametros = JSON.parse(this.parametrosSessionStorage);

      this.miliSegundosValidadeArquivoNVG =
        parseInt(jsonParametros.NVGValidHours, 10) * this.HORA_EM_MILISEGUNDOS;
      this.qtdMaximaNvgsFreeTier = parseInt(jsonParametros.NVGsMaxFreeTier, 10);

      this.carregarArquivosHist();
    }
  }

  public getTierUploadLimits(): TierUploadQuota {
    if (this.isFreeTier) {
      return {
        maxFileUploadsAllowed: this.qtdMaximaNvgsFreeTier,
        remainingFileUploads: this.qtdMaximaNvgsFreeTier - this.listFile.length,
      };
    }
    return {
      maxFileUploadsAllowed: -1,
      remainingFileUploads: -1,
    };
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
      const file = files.item(i);
      this.computeMd5Hash(file).then((nomeMd5) => {
        this.analyticsService.submitFileUploadAttempt(nomeMd5);
        if (!this.userId.includes('@agres.com.br')) {
          this.analyticsService.trackEvent(
            'Uploaded a file on the task reports page',
            'Task Report',
            this.userId,
          );
        }
      });
    }

    this.handleUpload();
  }

  validateExtension(files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = this._getFileExtension(file.name);

      if (
        !Object.keys(this.DEVICE_MODEL)
          .map((k) => this.DEVICE_MODEL[k].extension)
          .includes(extension)
      ) {
        this.translateService
          .get('global.validate.extention-nvg')
          .subscribe((res: string) => {
            this.AlertService.error(res);
          });

        this.files = [];
        return false;
      }
    }

    return true;
  }

  handleUpload() {
    this.fileLength = this.files.length;

    const qtdPermitidaUpload =
      this.qtdMaximaNvgsFreeTier - this.listFile.length;

    if (this.files.length > qtdPermitidaUpload) {
      let msgWarning = null;
      this.translateService
        .get('archive.limit-upload')
        .subscribe((res: string) => {
          this.translateService
            .get('archive.limit-upload-two')
            .subscribe((res1: string) => {
              this.files = [];

              msgWarning = res + qtdPermitidaUpload + res1;
              this.AlertService.warning(msgWarning, TimeoutEnum.Medium);
              if (!this.userId.includes('@agres.com.br')) {
                this.analyticsService.trackEvent(
                  'Successful uploading a file on the task reports page',
                  'Task Report',
                  this.userId,
                );
              }
              this.analyticsService.submitFileUploadLimitEvent(
                qtdPermitidaUpload,
              );
            });
        });
    } else {
      if (this.validateExtension(this.files)) {
        this.uploadFile();
      }
    }
  }

  uploadFile() {
    if (this.files.length > 1) {
      // Os arquivos estão sendo enviado! Aguarde...
      this.translateService
        .get('archive.files-sending-nvg')
        .subscribe((res: string) => {
          this.AlertService.info(res, TimeoutEnum.Medium);
        });
    } else {
      // O arquivo está sendo enviado! Aguarde...
      this.translateService
        .get('archive.file-sending-nvg')
        .subscribe((res: string) => {
          this.AlertService.info(res, TimeoutEnum.Medium);
        });
    }

    this.validarArquivosExistentes();
  }

  validarArquivosExistentes() {
    this.nvgArrayMd5Bucket = [];

    const promiseArrayL = [];

    return new Promise((resolve, _reject) => {
      this.files.forEach((file) => {
        promiseArrayL.push(
          new Promise((resolve2, _reject2) => {
            this.computeMd5Hash(file).then((nomeMd5) => {
              const item = {
                nomeMd5,
                nome: file.name,
                arquivo: file,
              };

              const blobFile = file.slice(0, file.size, file.type);
              const arquivoNovo = new File([blobFile], item.nomeMd5, {
                type: '',
              });

              item.arquivo = arquivoNovo;
              this.listMd5.push(item);

              resolve2(item.nomeMd5);
            });
          }),
        );
      });

      Promise.all(promiseArrayL)
        .then((arraymd5) => {
          this.nvgListFilesService
            .checkFilesExists({ fileNames: arraymd5 })
            .subscribe((responseBucket) => {
              this.nvgArrayMd5Bucket = responseBucket;

              this.listMd5.forEach((element) => {
                this.nvgs.push(element);
              });

              resolve('OK');

              this.processarArquivos();
            });
        })
        .catch((err) => {
          resolve(err);
        });
    });
  }

  public computeMd5Hash(file: File): Promise<string> {
    return new Promise((resolve, _reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (_e) => {
        const buffer = reader.result;
        const md5 = new Md5();
        md5.appendByteArray(new Uint8Array(buffer as ArrayBuffer));
        const md5Hash: string = md5.end().toString();
        resolve(md5Hash);
      };
    });
  }

  processarArquivos() {
    this.nvgs = this.filterNvgsOnList();

    if (this.nvgs.length > 0) {
      this.timestamp_start_upload = new Date().getTime();

      // Iniciando processamento do arquivo...
      this.translateService
        .get('archive.processing-file-nvg')
        .subscribe((res: string) => {
          this.AlertService.info(res, TimeoutEnum.VeryLong);
        });

      this.saveFile();
    } else {
      // Arquivos já existem na listagem...
      this.translateService
        .get('archive.nvg-file-name-already-exists-message')
        .subscribe((res: string) => {
          this.files.forEach((arq) => {
            this.AlertService.error(
              res + ' - ' + arq.name,
              TimeoutEnum.VeryLong,
            );
          });
        });

      this.files = new Array<File>();
    }
  }

  filterNvgsOnList() {
    const existOnList = this.listFile.map((f) => {
      return f.id;
    });
    const filterNvgs = this.nvgs.filter(
      (nvg) => existOnList.indexOf(nvg.nomeMd5) === -1,
    );

    return filterNvgs;
  }

  mostrarMensagemArquivoJaExistente(error, file) {
    if (error.error === 'Arquivo já existe!') {
      this.translateService
        .get('archive.nvg-file-name-already-exists-message')
        .subscribe((res: string) => {
          this.AlertService.error(res + ' - ' + file, TimeoutEnum.VeryLong);
        });
    }
  }

  saveFile() {
    const promiseArrayL = [];

    return new Promise((resolve, _reject) => {
      this.nvgs.forEach((file) => {
        promiseArrayL.push(
          new Promise((resolve2, reject2) => {
            const findFileNotExistBucket = this.nvgArrayMd5Bucket.filter(
              (a) => a.id === file.nomeMd5 && !a.exists,
            );

            // se o arquivo não estiver na lista
            if (findFileNotExistBucket.length > 0) {
              // fazer upload do arquivo no S3 Bucket e associar ao nvg-hist
              const fileNameCustom = file.nomeMd5;
              this.nvgFilesService.uploadFile(fileNameCustom).subscribe(
                (_dataPreSign) => {
                  this.http
                    .put(_dataPreSign, file.arquivo, {
                      headers: new HttpHeaders().set(
                        'X-Ms-Blob-Type',
                        'BlockBlob',
                      ),
                    })
                    .subscribe(
                      (_response) => {
                        const user = {
                          userId: '',
                          fileName: file.nome,
                          timestamp: this.timestamp_start_upload,
                          endTimestamp: new Date().getTime(),
                        };

                        const equipment = this.getEquipmentName(file);

                        const requestFinishHist = {
                          id: fileNameCustom,
                          status: this.CONST_STATUS.PROCESSING,
                          user: user,
                          equipment: equipment,
                        };

                        this.nvgHistService
                          .postHist(requestFinishHist)
                          .subscribe(
                            (_dataHist) => {
                              resolve2('OK');
                            },
                            (error) => {
                              reject2(error);
                            },
                          );
                      },
                      (error) => {
                        reject2(error);
                      },
                    );
                },
                (error) => {
                  reject2(error);
                },
              );
            } else {
              const user = {
                userId: '',
                fileName: file.nome,
                timestamp: this.timestamp_start_upload,
                endTimestamp: new Date().getTime(),
              };

              const equipment = this.getEquipmentName(file);

              const requestFinishHist = {
                id: file.nomeMd5,
                status: this.CONST_STATUS.PROCESSING,
                user: user,
                equipment: equipment,
              };

              this.nvgHistService.postHist(requestFinishHist).subscribe(
                (_dataHist) => {
                  resolve2('OK');
                },
                (error) => {
                  reject2(error);
                },
              );
            }
          }),
        );
      });

      Promise.all(promiseArrayL)
        .then((values) => {
          this.listaUpload = [];
          this.files = new Array<File>();
          this.listMd5 = new Array<File>();
          this.nvgs = new Array<File>();
          this.nvgsJaExistem = [];

          this.successSendFile();
          resolve(values);
        })
        .catch((err) => {
          if (
            err.error != null &&
            err.error.message !== undefined &&
            err.error.message.includes('Arquivo já existe')
          ) {
            this.translateService
              .get('archive.nvg-file-name-already-exists-message')
              .subscribe((res: string) => {
                this.AlertService.error(res, TimeoutEnum.VeryLong);
              });
          } else {
            this.translateService
              .get('global.alert.msg-unexpected-error')
              .subscribe((res: string) => {
                this.AlertService.error(res, TimeoutEnum.VeryLong);
              });
          }

          this.listaUpload = [];
          this.files = new Array<File>();
          this.listMd5 = new Array<File>();
          this.nvgs = new Array<File>();
          this.nvgsJaExistem = [];
          resolve(err);
        });
    });
  }

  getEquipmentName(file: any): string {
    const equipment = Object.keys(this.DEVICE_MODEL)
      .map((k) => this.DEVICE_MODEL[k])
      .find(
        (_equipment) =>
          _equipment.extension === this._getFileExtension(file.nome),
      );
    if (equipment) {
      return equipment.name;
    } else {
      return this.DEVICE_MODEL.ISOVIEW.name;
    }
  }

  successSendFile() {
    const self = this;
    self.translateService
      .get('archive.processed-file-nvg-success')
      .subscribe((res: string) => {
        self.AlertService.success(res, TimeoutEnum.VeryLong);
      });

    self.listaUpload = [];
    self.files = new Array<File>();
    self.listMd5 = [];

    self.carregarArquivosHist();
    this.closeModal();
  }

  carregarArquivosHist(filteredItems: Array<string> = []) {
    if (this.isFreeTier) {
      this.dateMaxNvg =
        new Date().getTime() - this.miliSegundosValidadeArquivoNVG;
    }

    this.getUsersNVGFiles().subscribe((listOfUserNVGFiles) => {
      this.isLoading = false;
      setTimeout(() => {
        this.loadingAgresSpinner.toHide();
      }, TimeoutEnum.Long);
      this.countUserNVGFiles = +listOfUserNVGFiles.count;

      const processingNVGs = this.getProcessingNVGs(listOfUserNVGFiles);

      if (processingNVGs.length > 0)
        this.scheduleStatusUpdateForProcessingNVGs(listOfUserNVGFiles);

      this.initialListFile = listOfUserNVGFiles.data.map((nvgFile) => {
        return this._buildNVGObject(nvgFile, this.dateMaxNvg);
      });

      if (this.valueInputSearch !== '' && this.valueInputSearch !== undefined) {
        this.listFile = [
          ...this.listFile,
          ...this.initialListFile.filter(
            (listItem) => !filteredItems.includes(listItem.id),
          ),
        ];
      } else {
        if (this.valueInputSearch === '') {
          const showingItemsIds = this.listFile.map((item) => item.id);
          this.listFile = [
            ...this.listFile,
            ...this.initialListFile.filter(
              (listItem) => !showingItemsIds.includes(listItem.id),
            ),
          ];
        } else {
          this.listFile = [...this.initialListFile];
        }
      }
      this.listFile.sort((a, b) => +b.endTimestamp - +a.endTimestamp);
      this._checkLimitNvgFreeTier();
      this.checkIfListFilesIsEmpty();
    });
  }

  private checkIfListFilesIsEmpty() {
    if (this.listFile.length === 0) {
      this.listFilesIsEmpty = true;
    } else {
      this.listFilesIsEmpty = false;
    }
  }

  _buildNVGObject(nvg, dateMaxNvg: number): TaskCard {
    let errorMsg = null;
    if (nvg.status === this.CONST_STATUS.ERROR) {
      errorMsg = this._getErrorMessage(nvg);
      return {
        id: nvg.id,
        fileName: nvg.user.fileName,
        status: nvg.status,
        equipment: nvg.equipment,
        msgError: errorMsg,
      };
    }
    const endTimestamp = new Date(nvg.endTimestamp);
    const nvgObject = {
      id: nvg.id,
      fileName: nvg.user.fileName,
      status: nvg.status,
      equipment: nvg.equipment,
      msgError: errorMsg,
      coverArea: nvg.coverArea,
      endTimestamp: endTimestamp.getTime(),
      operation: nvg.operation,
      expired: false,
      statusChangeEventEmitter: new EventEmitter(),
    };

    if (this.isFreeTier && nvg.endTimestamp && dateMaxNvg) {
      const userEndTimestamp = new Date(nvg.user.endTimestamp);
      if (userEndTimestamp < new Date(dateMaxNvg)) {
        nvgObject['expired'] = true;
      } else {
        nvgObject['labelTimeStamp'] = this._timeDifference(
          userEndTimestamp,
          new Date(dateMaxNvg),
        );
      }
    }

    return nvgObject;
  }

  getContentTranslations(nvg: any): { [key: string]: string } {
    const operationTranslationMap = {
      pulverizacao: 'tasks.operation.spraying',
      adubacao: 'tasks.operation.fertilization',
      colheita: 'tasks.operation.harvest',
      plantio: 'tasks.operation.planting',
      navegacao: 'tasks.operation.navigation',
      fruticultura: 'tasks.operation.fruticultura',
      outros: 'tasks.operation.others',
      PULVERIZATION: 'tasks.operation.PULVERIZATION',
      FERTILIZATION: 'tasks.operation.FERTILIZATION',
      OTHER: 'tasks.operation.OTHER',
      SEEDING: 'tasks.operation.SEEDING',
      NAVIGATION: 'tasks.operation.NAVIGATION',
      HARVEST: 'tasks.operation.HARVEST',
      isobus: 'tasks.operation.isobus',
      null: 'tasks.operation.null',
    };

    const contentTranslations = {};
    if (nvg.coverArea) {
      const formattedCoverArea = nvg.coverArea.toFixed(2);

      contentTranslations['Area Coberta'] = formattedCoverArea + ' ha';
    }
    if (nvg.endTimestamp) {
      contentTranslations['Data:'] =
        this.translateService.instant('global.date') +
        ' : ' +
        this.datePipe.transform(nvg.endTimestamp, 'dd/MM/yyyy');
    }

    if (nvg.operation) {
      const operationTranslationKey = operationTranslationMap[nvg.operation];

      if (operationTranslationKey) {
        this.translateService
          .get(operationTranslationKey)
          .subscribe((value) => {
            contentTranslations['Operação'] = value;
          });
      }
    }

    return contentTranslations;
  }

  getContentTranslationsMenu(): Array<MenuItem> {
    return this.menuItems;
  }

  getUsersNVGFiles(): Observable<TaskHistList> {
    return this.nvgHistService.getHist(
      this.itensPerSection,
      this.section,
      this.valueInputSearch || '',
      {
        metadata: true,
      },
      this.fieldName ?? '',
    );
  }

  onSaveComplete(id) {
    id.status = 'concluido';
  }

  visualizarArquivoNvg(nomeArquivo: string) {
    this.nvgTrabalhoService.trabalho = { name_nvg: nomeArquivo };
    this.analyticsService.submitFileOpenEvent(nomeArquivo);
    this.router.navigate(['/gestao-operacao/resultado-navegacao'], {
      queryParams: { id: nomeArquivo, type: 'NVG' },
    });
  }

  buscarMensagemExclusaoNvg() {
    this.translateService
      .get('archive.message-confirm-delete-nvg')
      .subscribe((res: string) => {
        this.mostrarModalConfirmacao = true;
        this.mensagemModalConfirmacao = '';
        this.tituloModalConfirmacao = res;
        this.tipoModalConfirmacao = 'warning';
        this.modalTranslateButtonConfirmText = 'global.yes';
        this.modalTranslateButtonCancelText = 'global.no';
      });
  }

  confirmarExclusaoNvg(idArquivo) {
    this.idArquivoSelecionadoExcluir = idArquivo;
    this.buscarMensagemExclusaoNvg();
  }

  excluirNvg(idArquivoSelecionadoExcluir) {
    this.analyticsService.submitFileDeleteEvent(
      idArquivoSelecionadoExcluir,
      this.getTierUploadLimits(),
    );
    this.nvgHistService.excluirNvg(idArquivoSelecionadoExcluir).subscribe(
      (_result) => {
        this.translateService
          .get('archive.message-success-delete-nvg')
          .subscribe((res: string) => {
            this.AlertService.success(res, TimeoutEnum.MediumLong);
            this.listFile = this.listFile.filter(
              (f) => f.id !== idArquivoSelecionadoExcluir,
            );
            this.initialListFile = this.initialListFile.filter(
              (f) => f.id !== idArquivoSelecionadoExcluir,
            );

            this._checkLimitNvgFreeTier();
            this.checkIfListFilesIsEmpty();
          });
        if (!this.userId.includes('@agres.com.br')) {
          this.analyticsService.trackEvent(
            'Deleted a file on the task reports page',
            this.userId,
            idArquivoSelecionadoExcluir,
          );
        }
      },
      (error) => {
        if (!this.userId.includes('@agres.com.br')) {
          this.analyticsService.trackEvent(
            'An error occurred while deleting a file on the task reports page',
            this.userId,
            idArquivoSelecionadoExcluir,
          );
        }
        if (error.error !== undefined) {
          this.translateService
            .get(error.error[0])
            .subscribe((msgErro: string) => {
              this.AlertService.error(msgErro, TimeoutEnum.VeryLong);
            });
        } else {
          this.translateService
            .get('archive.message-error-delete-nvg')
            .subscribe((msgErro: string) => {
              this.AlertService.error(msgErro, TimeoutEnum.VeryLong);
            });
        }
      },
    );
  }

  buscarMensagemCompatibilidadeNvg() {
    this.translateService
      .get('archive.message-confirm-notify-when-supported')
      .subscribe((res: string) => {
        this.mostrarModalCompatibilidade = true;
        this.mensagemModalCompatibilidade = '';
        this.tituloModalCompatibilidade = res;
        this.tipoModalCompatibilidade = 'warning';
        this.modalTranslateButtonConfirmText = 'global.yes';
        this.modalTranslateButtonCancelText = 'global.no';
      });
  }

  confirmarCompatibilidadeNvg(idArquivo) {
    this.idArquivoSelecionadoCompatibilidade = idArquivo;
    this.buscarMensagemCompatibilidadeNvg();
  }

  agendarNotificacaoNvg() {
    this.schedulingNotify = true;
  }

  redirecionaTelaHistorio() {
    this.router.navigate(['/gestao-operacao/historico-navegacao']);
  }

  _getFileExtension(filename) {
    return filename.split('.').pop().toLowerCase();
  }

  dashboard() {
    this.router.navigate(['/']);
  }

  confirmDeleteAllFiles() {
    this.excludeAllFiles = true;

    this.translateService
      .get('archive.message-confirm-delete_all-nvg')
      .subscribe((res: string) => {
        this.mostrarModalConfirmacao = true;
        this.mensagemModalConfirmacao = '';
        this.tituloModalConfirmacao = res;
        this.tipoModalConfirmacao = 'warning';
        this.modalTranslateButtonConfirmText = 'global.yes';
        this.modalTranslateButtonCancelText = 'global.no';
      });
  }

  deleteAllFiles() {
    this.deletingFiles = true;

    this.nvgHistService.excluirAllFiles().subscribe(
      (_result) => {
        this.excludeAllFiles = false;

        this.translateService
          .get('archive.message-success-delete_all-nvg')
          .subscribe((res: string) => {
            this.AlertService.success(res, TimeoutEnum.MediumLong);
            this.listFile = [];
            this.deletingFiles = false;

            this._checkLimitNvgFreeTier();
            this.checkIfListFilesIsEmpty();
          });
      },
      (_error) => {
        this.excludeAllFiles = false;
        this.deletingFiles = false;

        this.translateService
          .get('archive.message-error-delete_all-nvg')
          .subscribe((msgErro: string) => {
            this.AlertService.error(msgErro, TimeoutEnum.VeryLong);
          });
      },
    );
  }

  _timeDifference(date1: Date, date2: Date) {
    let difference = date1.getTime() - date2.getTime();

    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24;

    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    const minutesDifference = Math.floor(difference / 1000 / 60);

    return (
      daysDifference + 'd ' + hoursDifference + 'h ' + minutesDifference + 'm '
    );
  }

  _checkLimitNvgFreeTier() {
    if (this.isFreeTier && this.listFile.length >= this.qtdMaximaNvgsFreeTier)
      this.blockUploadNvgFreeTier = true;
    else this.blockUploadNvgFreeTier = false;
  }

  /**
   * Get key to translatable error string from NVG object
   *
   * This creates an adapter to both the older and the most recent format
   * as api is changing the format for errors.
   *
   * @param {any} nvg - Object that represents a NVG (according to nvg-hist DB schema)
   * @return {string} String corresponding to i18n error key.
   */
  _getErrorMessage(nvg: any): string {
    let errorMessage: string = this.unexpectedErrorTranslationStringKey;
    if (nvg.msgError) {
      // If error status according to new standart
      if (nvg.msgError.hasOwnProperty('error')) {
        errorMessage = nvg.msgError.error.code;
      } else {
        // If error follows the previous standart
        if (typeof nvg.msgError === 'string') {
          errorMessage = nvg.msgError;
        }
      }
    }

    return errorMessage;
  }

  openModal() {
    this.showModalSubject.next(true);
  }

  closeModal() {
    this.showModalSubject.next(false);
  }

  atualizarBusca(event): void {
    if (
      event.target.value != undefined &&
      this.valueInputSearch !== event.target.value
    ) {
      this.valueInputSearch = event.target.value;
      this.searchWorksSubject.next(this.valueInputSearch);
    }
  }

  getVisualizaNvgCallBack(idArquivoSelecionadoExcluir) {
    const visualizaNvg = () => {
      this.visualizarArquivoNvg(idArquivoSelecionadoExcluir);
    };
    return visualizaNvg.bind(this);
  }

  getDeleteNvgCallback() {
    const deletaNvg = (
      idArquivoSelecionadoExcluir: string,
      event: MouseEvent,
    ) => {
      event.stopPropagation();
      this.buscarMensagemExclusaoNvg();
      this.confirmarExclusaoNvg(idArquivoSelecionadoExcluir);
    };

    return deletaNvg;
  }

  transformMapImage(mapImg: any): any {
    const finalImage = {
      backgroundB64: '',
      instRateB64: '',
    };

    finalImage.backgroundB64 = `data:image/png;base64,${mapImg.backgroundFileB64s[0]}`;
    finalImage.instRateB64 = `data:image/png;base64,${mapImg.rasterFileB64s[0]}`;

    return finalImage;
  }

  isOpen(id) {
    return this.selectedCardId === id;
  }

  handleChange(event) {
    this.selectedCardId = event;
  }

  getImagemPathEquipamento(equipamento: string): string {
    if (equipamento === AppConstants.DEVICE_MODEL.ISOVIEW.name) {
      return './../../../../assets/images/default/logo-isoview.png';
    } else if (equipamento === AppConstants.DEVICE_MODEL.AGRONAVEPRO.name) {
      return './../../../../assets/images/default/logo-agronave.png';
    } else {
      return './../../../../assets/images/default/spray-rate-img.png';
    }
  }

  onCardClicked(idArquivoSelecionadoExcluir) {
    this.visualizarArquivoNvg(idArquivoSelecionadoExcluir);
  }

  pageChanged(pageNumber) {
    this.translateService
      .get('global.loading.wait')
      .subscribe((res: string) => {
        this.loadingAgresSpinner.toShow(res);
      });
    this.section = pageNumber - 1;
    this.carregarArquivosHist();
  }

  getCardImage(
    status: string,
    errorCardImage,
    processingCardImage,
    completedCardImage,
  ) {
    if (status === this.CONST_STATUS.ERROR) {
      return errorCardImage;
    } else if (status === this.CONST_STATUS.PROCESSING) {
      return processingCardImage;
    } else if (status === this.CONST_STATUS.COMPLETED) {
      return completedCardImage;
    } else {
      return errorCardImage;
    }
  }
  back(): void {
    window.history.back();
  }

  goToSprayRatePage() {
    this.router.navigate(['/gestao-operacao/spray-rate']);
  }
}
