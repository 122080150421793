import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-angular-email-autocomplete',
    templateUrl: './angular-email-autocomplete.component.html',
    styleUrls: ['./angular-email-autocomplete.component.scss'],
})
export class AngularEmailAutocompleteComponent {
    public toggleDropDown: boolean;
    public dropDownValues: Array<{
        imgUrl?: undefined | string;
        value: string;
    }> = [];
    public inputValue: string;
    public placeholder: string;

    @Output() selectedValue: EventEmitter<string> = new EventEmitter<string>();
    @Input() givenPlaceHolder: string;
    @Input() domainNames: Array<{
        imgUrl?: undefined | string;
        value: string;
    }>;
    @Input() formGroup: UntypedFormGroup;
    @Input() formGroupInputName: string; 

    constructor() {
        this.toggleDropDown = false;
        this.placeholder = 'Digite seu email...';
        this.inputValue = '';
        this.givenPlaceHolder = '';
        this.domainNames = [];
    }

    handleVisibility = (visibilty: string) => {
        visibilty === 'show' && this.dropDownValues.length
            ? (this.toggleDropDown = true)
            : (this.toggleDropDown = false);
    };

    handleSelect = (value: string) => {
        this.selectedValue.emit(value);
        this.inputValue = value;
        this.toggleDropDown = false;
        this.saveEmailValueOnFormGroup();
    };

    onInputChange = () => {
        this.toggleDropDown = true;
        this.dropDownValues = [];
        if (this.inputValue && this.inputValue.length) {
            this.domainNames.forEach((domainName) => {
                const valueToDisplay = `${this.inputValue}@${domainName.value}`;
                this.dropDownValues.push({
                    value: valueToDisplay,
                    imgUrl: domainName.imgUrl,
                });
            });
        } else {
            this.toggleDropDown = false;
        }
    };

    saveEmailValueOnFormGroup() {
        this.formGroup.patchValue({
            email: this.inputValue
        })
    }
}
