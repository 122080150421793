<div class="pagination-container">

    <ul [class]="small ? 'pagination pagination-sm' : 'pagination'">
        <!-- first button -->
        <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'" *ngIf="firstLastButtons">
            <a class="page-link first-last-button-page-button" (click)="selectPageNumber(1)">««</a>
        </li>
        <!-- previous button -->
        <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'" *ngIf="nextPreviousButtons">
            <a class="page-link previous-page-button" (click)="previous()">«</a>
        </li>
        <!-- page numbers -->
        <ng-container *ngFor="let p of totalPages; index as i">
            <li *ngIf="i + 1 >= (currentPage - maxSize) && i + 1 <= (currentPage + maxSize)"
                [class]="currentPage === (i  + 1) ? 'page-item active' : 'page-item'">
                <a class="page-link" (click)="selectPageNumber(i + 1)">{{i + 1}}</a>
            </li>
        </ng-container>
        <!-- next button -->
        <li [class]="currentPage === totalPages.length ? 'page-item disabled' : 'page-item'"
            *ngIf="nextPreviousButtons">
            <a class="page-link" (click)="next()">»</a>
        </li>
        <!-- last button -->
        <li [class]="currentPage === totalPages.length ? 'page-item disabled' : 'page-item'" *ngIf="firstLastButtons">
            <a id="select-page-number" class="page-link" (click)="selectPageNumber(totalPages.length)">»»</a>
        </li>
    </ul>
</div>