import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Observable } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';

import { ServiceBase } from './service.base';

@Injectable({
  providedIn: 'root',
})
export class AutomaticFarmApiService extends ServiceBase {
  @Input() dadosDoFormularioMapaDePrescricaoRecebidos;
  @Input() dadosDoFormularioMapaDeRecomendationRecebidos;

  /**
   * @ngdoc constantes para gerar mapa NDVI
   * caso queira alterar seguir os padroes recomendados pela api
   * https://automaticfarmsolution.live/
   */

  private readonly vegetationIndex = 'ndvi';
  private readonly autoPrescription = 'False';
  private readonly initialMap = 'EsriWorldImagery';
  private readonly filterTime = 'onelastmonth';
  private readonly baseMap = 'one';
  private readonly geographicReference = 'WGS84';

  private formDataObj;
  private formDataRecomendationObj;

  constructor(private http: HttpClient) {
    super();
  }
  private getHeaders() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, /',
      Authorization: 'Bearer ' + this.obterTokenUsuario(),
      'Ocp-Apim-Subscription-Key': this.subscriptionKey,
    };
    return headers;
  }
  /**
   * @description Pega os dados do Formulario
   * Atualiza os dados do formulário com os novos dados recebidos
   * para gerar o mapa de prescrição quando a autoPrescription for false.
   */
  private getFormData(): any {
    if (this.dadosDoFormularioMapaDePrescricaoRecebidos) {
      this.formDataObj = this.dadosDoFormularioMapaDePrescricaoRecebidos;
    } else {
      this.formDataObj = {
        numberZone: '5',
        userFertilizer: '0',
        strategy: 'highwherehigh',
        productType: 'Solid',
        productName: 'Urea',
        doseDifference: '0',
        averageLitersPerHectare: '0',
      };
    }
    return this.formDataObj;
  }
  private getRecomendationFormData() {
    if (this.dadosDoFormularioMapaDePrescricaoRecebidos) {
      this.formDataRecomendationObj =
        this.dadosDoFormularioMapaDePrescricaoRecebidos;
    }
    return this.formDataRecomendationObj;
  }

  public getWidgetPrescriptionMap(
    geometry: any,
    talhaoId,
  ): Observable<AxiosResponse<any>> {
    const actionType = 'none';
    const formDataObjeto = this.getFormData();
    const apiUrlRecomendation = `
    ${this.UrlServiceV1}AFS/AutoPrescriptionHtmlWidgetNew?AutoPrescription=${this.autoPrescription}&fieldnumber=${talhaoId}&action=${actionType}&NumberZone=${formDataObjeto.numberZone}&userfertilizer=${formDataObjeto.userFertilizer}&Strategy=${formDataObjeto.strategy}&ProductType=${formDataObjeto.productType}&ProductName=${formDataObjeto.productName}&GeographicReference=${this.geographicReference}&DoseDifference=${formDataObjeto.doseDifference}&initialmap=${this.initialMap}&basemap=${this.baseMap}`;

    const headers = this.getHeaders();
    const axiosConfig: AxiosRequestConfig = {
      headers,
      responseType: 'json',
    };

    return new Observable<AxiosResponse<any>>((observer) => {
      axios
        .post(apiUrlRecomendation, geometry, axiosConfig)
        .then((response: AxiosResponse<any>) => {
          observer.next(response);
          observer.complete();
        })
        .catch((error: AxiosError) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public getWidgetMapNdvi(
    geometry,
    talhaoId,
    actionType,
  ): Observable<AxiosResponse<any>> {
    const url = `${this.UrlServiceV1}AFS/VegetationIndexHtmlWidgetNew?fieldnumber=${talhaoId}&action=${actionType}&vegetationindex=${this.vegetationIndex}&initialmap=${this.initialMap}&filterTime=${this.filterTime}&basemap=${this.baseMap}`;
    const headers = this.getHeaders();

    const axiosConfig: AxiosRequestConfig = {
      headers,
      responseType: 'json',
    };

    return new Observable<AxiosResponse<any>>((observer) => {
      axios
        .post(url, geometry, axiosConfig)
        .then((response: AxiosResponse<any>) => {
          observer.next(response);
          observer.complete();
        })
        .catch((error: AxiosError) => {
          observer.error(error);
          observer.complete();
        });
    });
  }
  public getEchartsNdvi(
    geometry,
    talhaoId,
    actionType,
  ): Observable<AxiosResponse<any>> {
    const url = `${this.UrlServiceV1}AFS/EchartsVegetationIndexTemporalVariationNew?fieldnumber=${talhaoId}&action=${actionType}&vegetationindex=${this.vegetationIndex}`;

    const headers = this.getHeaders();
    // Configuração da solicitação Axios
    const axiosConfig: AxiosRequestConfig = {
      headers,
      responseType: 'json',
    };

    return new Observable<AxiosResponse<any>>((observer) => {
      axios
        .post(url, geometry, axiosConfig)
        .then((response: AxiosResponse<any>) => {
          observer.next(response);
          observer.complete();
        })
        .catch((error: AxiosError) => {
          console.error('Erro na solicitação POST:', error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  async generateShapefile(geometry: any, talhaoId): Promise<any> {
    const formDataObjeto = this.getFormData();
    const actionType = 'none';

    const apiUrlPrescriptionMapShapefile = `${this.UrlServiceV1}AFS/AutoPrescriptionShapefileNew?AutoPrescription=${this.autoPrescription}&fieldnumber=${talhaoId}&action=${actionType}&NumberZone=${formDataObjeto.numberZone}&userfertilizer=${formDataObjeto.userFertilizer}&Strategy=${formDataObjeto.strategy}&ProductType=${formDataObjeto.productType}&ProductName=${formDataObjeto.productName}&GeographicReference=${this.geographicReference}&DoseDifference=${formDataObjeto.doseDifference}&initialmap=${this.initialMap}&basemap=${this.baseMap}`;
    try {
      const headers = this.getHeaders();
      const response: HttpResponse<Blob> = await this.http
        .post(apiUrlPrescriptionMapShapefile, geometry, {
          headers,
          responseType: 'blob',
          observe: 'response',
        })
        .toPromise();

      return response;
    } catch (error) {
      console.error('An error occurred:', error.message);
      throw error;
    }
  }

  async generateAndDownloadShapefile(
    geometry: any,
    fieldName,
    talhaoId,
  ): Promise<void> {
    const formDataObjeto = this.getFormData();
    const actionType = 'none';
    const apiUrlPrescriptionMapShapefile = `${this.UrlServiceV1}AFS/AutoPrescriptionShapefileNew?AutoPrescription=${this.autoPrescription}&fieldnumber=${talhaoId}&action=${actionType}&NumberZone=${formDataObjeto.numberZone}&userfertilizer=${formDataObjeto.userFertilizer}&Strategy=${formDataObjeto.strategy}&ProductType=${formDataObjeto.productType}&ProductName=${formDataObjeto.productName}&GeographicReference=${this.geographicReference}&DoseDifference=${formDataObjeto.doseDifference}&initialmap=${this.initialMap}&basemap=${this.baseMap}`;
    try {
      const headers = this.getHeaders();
      const fileNameWithoutSpaces = fieldName.replace(/\s+/g, '_'); // chacge espaces to underscores
      const response: HttpResponse<Blob> = await this.http
        .post(apiUrlPrescriptionMapShapefile, geometry, {
          headers,
          responseType: 'blob',
          observe: 'response',
        })
        .toPromise();

      this.downloadFile(
        response.body,
        `shapefile_${fileNameWithoutSpaces}.zip`,
      );
    } catch (error) {
      console.error('An error occurred:', error.message);
      throw error;
    }
  }
  private downloadFile(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  downloadShapefile(geometry, fieldName, talhaoId) {
    this.generateAndDownloadShapefile(geometry, fieldName, talhaoId);
  }
  public getRecomendationData() {
    const apiurl =
      'https://www.api.automaticfarmsolutionwebapp.com/AFS/Nitrogen?cropanalyze=';
    // set the product_name parameter

    const cropanalyze = 'Strawberry';
    const expectedyield = '85';
    const titlefertilizer = '42';

    // set the apiendpoint

    const url = apiurl.concat(
      cropanalyze,
      '&expectedyield=',
      expectedyield,
      '&titlefertilizer=',
      titlefertilizer,
    );
    const headers = this.getHeaders();
    const axiosConfig: AxiosRequestConfig = {
      headers,
    };

    return new Observable<AxiosResponse<any>>((observer) => {
      axios
        .get(url, axiosConfig)
        .then((response: AxiosResponse<any>) => {
          observer.next(response);
          observer.complete();
        })
        .catch((error: AxiosError) => {
          observer.error(error);
          observer.complete();
        });
    });
  }
}
