export class AgresSpinner {
  id: string;
  type: AgresSpinnerType;
  message: string;
  autoClose: boolean;
  keepAfterRouteChange: boolean;
  fade: boolean;

  constructor(init?: Partial<AgresSpinner>) {
    Object.assign(this, init);
  }
}

export enum AgresSpinnerType {
  Show,
  Hide,
}
