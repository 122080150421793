<app-alert></app-alert>
<div class="auth">
  <div class="auth__wrap">
    <div class="auth__wrap--pic">
      <img alt="Agres" src="./assets/images/default/logo-isofarm.svg" />
    </div>
    <div class="auth__wrap--language">
      <a (click)="changeLanguage(languages_options.PT_BR)">
        <img
          src="./assets/images/languages/ptBR.png"
          alt="Português"
          [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.PT_BR) }" />
      </a>
      <a (click)="changeLanguage(languages_options.EN)">
        <img
          src="./assets/images/languages/en.png"
          alt="English"
          [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.EN) }" />
      </a>
      <a (click)="changeLanguage(languages_options.ES)">
        <img
          src="./assets/images/languages/es.png"
          alt="Spanish"
          [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.ES) }" />
      </a>
      <a (click)="changeLanguage(languages_options.IT)">
        <img
          src="./assets/images/languages/it.png"
          alt="Italian"
          [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.IT) }" />
      </a>
    </div>
    <div class="auth__wrap--heading">
      {{ 'register.title' | translate }}
    </div>
    <form
      [formGroup]="registrarForm"
      (ngSubmit)="cadastrarUsuarioFreeTier()"
      novalidate="novalidate"
      id="cadastro-agres">
      <div class="field">
        <div class="field__label" for="usuario.id">
          {{ 'register.title-name' | translate }}
        </div>
        <div class="field__wrap">
          <input
            [(ngModel)]="usuario.id"
            type="text"
            class="field__input"
            formControlName="name"
            [ngClass]="{ 'field__input--alert': displayMessage.name }"
            required="true" />
          <span class="field__input--alert" *ngIf="displayMessage.name">
            {{ displayMessage.name }}
          </span>
        </div>
      </div>
      <div class="field">
        <div class="field__label">
          {{ 'register.title-mail' | translate }}
        </div>
        <div class="field__wrap">
          <input
            type="email"
            class="field__input"
            formControlName="id"
            [ngClass]="{ 'field__input--alert': displayMessage.id }"
            required="true" />
          <span class="field__input--alert" *ngIf="displayMessage.id">
            {{ displayMessage.id }}
          </span>
        </div>
      </div>
      <div class="field" data-kt-password-meter="true">
        <div class="field__label" id="newPassword">
          {{ 'register.title-password' | translate }}
        </div>
        <div class="field__wrap">
          <input
            [type]="isPasswordVisible ? 'text' : 'password'"
            class="field__input"
            formControlName="Senha"
            autocomplete="off"
            required="true"
            (input)="passwordSize()" />
          <span class="field__view">
            <app-agres-icons
              styleClass="field__view"
              [ngClass]="isPasswordVisible ? 'agres-eye' : 'agres-eye-off'"
              (click)="togglePassword()">
            </app-agres-icons>
          </span>
        </div>
        <span *ngIf="sizePassword" class="field__input--alert">
          {{ 'global.validate.password-minlength' | translate }}
        </span>
      </div>

      <div class="field" data-kt-password-meter="true">
        <div class="field__label" id="confirmPassword">
          {{ 'register.title-new-password-confirm' | translate }}
        </div>
        <div class="field__wrap">
          <input
            type="password"
            class="field__input"
            formControlName="SenhaConfirmacao"
            [type]="isConfirmPasswordVisible ? 'text' : 'password'"
            required="true"
            (input)="equalPasswords()"
            id="confirmPassword" />
          <span class="field__view">
            <app-agres-icons
              styleClass="field__view"
              [ngClass]="isConfirmPasswordVisible ? 'agres-eye' : 'agres-eye-off'"
              (click)="confirmTogglePassword()">
            </app-agres-icons>
          </span>
        </div>
        <span class="field__input--alert" *ngIf="passNotEquals">
          {{ 'global.validate.password-wrong' | translate }}
        </span>
      </div>
      <div class="field__checkbox" for="CheckAcceptTerms">
        <input
          class="checkBox__input"
          #checkAcceptTerms
          type="checkbox"
          formControlName="CheckAcceptTerms"
          id="CheckAcceptTerms"
          name="CheckAcceptTerms" />
        <span>{{ 'global.agree' | translate }}</span>
        <strong>
          <app-link link="#/termos-uso" target="_blank" title="{{ 'global.terms' | translate }}"> </app-link>
        </strong>
      </div>
      <app-button
        type="submit"
        size="full"
        [icon]="executingRegister ? 'agres-reload' : 'agres-action'"
        [title]="executingRegister ? ('register.title-executing-account' | translate) : ('register.title' | translate)"
        [disabled]="executingRegister || !registrarForm.valid || passNotEquals"
        [ngClass]="executingRegister ? 'return' : submitForm() ? 'primary' : 'return'">
      </app-button>
    </form>
    <div class="auth__wrap--account">
      <p>{{ 'register.title-have-an-account' | translate }}</p>
      <strong>
        <app-link (click)="goToLogin()" title="{{ 'register.title-have-an-account-access' | translate }}"></app-link>
      </strong>
    </div>
    <div class="auth__wrap--copyright">
      <app-link link="https://agres.com.br/quem_somos/" target="_blank" title="{{ 'global.copyright' | translate }}">
      </app-link>
    </div>
  </div>
</div>
