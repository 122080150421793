import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServiceBase } from './service.base';

import * as pako from 'pako';
import { EventoNVG } from 'app/shared/models/eventoNVG';
import { EventoISOBUS } from 'app/shared/models/eventoISOBUS';

@Injectable()
export class NvgStorageService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  getPresignedUrl(nvgName) {
    return this.http
      .get(
        this.UrlServiceV1 +
          `manage-blob-storage-files?containerName=parsed-task-files&fileName=${nvgName}`,
        this.obterAuthHeader(),
      )
      .pipe(
        map(this.extractData<{ presignedUrl: string }>),
        catchError(this.serviceError),
      );
  }

  getStorageData(nvgName) {
    return new Promise<any | EventoISOBUS>((resolve) => {
      this.getPresignedUrl(nvgName + '.gz').subscribe(({ presignedUrl }) => {
        fetch(presignedUrl)
          .then((response) => response.arrayBuffer())
          .then((compressedData) =>
            pako.ungzip(compressedData, { to: 'string' }),
          )
          .then((expandedData) => resolve(JSON.parse(expandedData)));
      });
    });
  }
}
