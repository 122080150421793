import { Injectable } from '@angular/core';
import { ServiceBase } from './service.base';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class BlobStorageFilesService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  getAzurePresignUrl(container: string, fileName: string) {
    return this.http
      .get(this.UrlServiceV1 + `manage-blob-storage-files`, {
        params: { containerName: container, fileName: fileName },
        ...this.obterAuthHeader(),
      })
      .pipe(
        map(this.extractData<{ presignedUrl: string }>),
        catchError(this.serviceError),
      );
  }
}
