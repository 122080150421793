import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ServiceBase } from './service.base';

@Injectable()
export class PrevisaoTempoService extends ServiceBase {
  private readonly apiKeyGoogleMaps = environment.apiKeyGoogleMaps;
  constructor(public http: HttpClient) {
    super();
  }
  getSearchLocation(search: string) {
    return this.http
      .get(
        this.UrlServiceV1 +
          `weather?` +
          `operation=location&` +
          `filterType=search&` +
          `id=${search}`,
        this.obterAuthHeader(),
      )
      .pipe(
        map(this.extractData<Record<string, any>[]>),
        catchError(this.serviceError),
      );
  }

  getLocationName(latitude: number, longitude: number) {
    if (!latitude || !longitude) {
      return this.http
        .get(
          this.UrlServiceV1 +
            `weather?` +
            `operation=location&` +
            `filterType=geocode`,
          this.obterAuthHeader(),
        )
        .pipe(map(this.extractData<any>), catchError(this.serviceError));
    } else {
      return this.http
        .get(
          this.UrlServiceV1 +
            `weather?` +
            `operation=location&` +
            `filterType=geocode&` +
            `lat=${latitude}&` +
            `lng=${longitude}`,
          this.obterAuthHeader(),
        )
        .pipe(map(this.extractData<any>), catchError(this.serviceError));
    }
  }

  getTime(
    latitude: number,
    longitude: number,
    language: string,
  ): Observable<any> {
    if (!latitude || !longitude) {
      return this.http
        .get(
          this.UrlServiceV1 +
            `weather?` +
            `operation=forecast&` +
            `lang=${language}`,
          this.obterAuthHeader(),
        )
        .pipe(map(this.extractData), catchError(this.serviceError));
    } else {
      return this.http
        .get(
          this.UrlServiceV1 +
            `weather?` +
            `operation=forecast&` +
            `filterType=geocode&` +
            `lat=${latitude}&` +
            `lng=${longitude}&` +
            `lang=${language}`,
          this.obterAuthHeader(),
        )
        .pipe(map(this.extractData), catchError(this.serviceError));
    }
  }
  getTimeNextThreeDays(
    latitude: number,
    longitude: number,
    language: string,
  ): Observable<any> {
    return this.http
      .get(
        this.UrlServiceV1 +
          `weather?operation=forecast&filterType=geocode3days` +
          `&lat=${latitude}&lng=${longitude}&lang=${language}`,
        this.obterAuthHeader(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getNowcast(latitude: number, longitude: number): Observable<any> {
    return this.http
      .get(
        this.UrlServiceV1 +
          `weather?` +
          `operation=nowcast&` +
          `filterType=geocode&` +
          `lat=${latitude}&` +
          `lng=${longitude}&`,
        this.obterAuthHeader(),
      )
      .pipe(catchError(this.serviceError));
  }

  buscarCoordenadas(endereco: string): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${endereco}&key=${this.apiKeyGoogleMaps}`;
    return this.http.get(url);
  }
}
