import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { _roleEnumUserClass } from 'app/utils/role.utils';

@Component({
  selector: 'app-chamado',
  templateUrl: './chamado.component.html',
  styleUrls: ['./chamado.component.css'],
})
export class ChamadoComponent implements OnInit {
  ROLE_ENUM = _roleEnumUserClass.ROLE_ENUM;
  constructor(public translateService: TranslateService) {
    this.translateService.get('global.home').subscribe((home: string) => {
      var home = home;
      this.translateService
        .get('chamado.title-page')
        .subscribe((res: string) => {
          var title = res;
          this.bindHTMLTitle(home, title);
        });
    });
  }

  chamado = {
    numero: '1',
    servico: 'Técnico',
    categoria: 'Web',
    descricao: 'Descrição 1',
    status: 'Aberto',
    usuario: 'Anderson Martins',
    mensagens: [
      {
        id: '1',
        mensagem: 'Ola, vamos resolver seu problema em até 15 dias.',
        data: new Date(Date.now()).toUTCString(),
        agres: true,
      },
      {
        id: '2',
        mensagem: 'Muito Obrigado.',
        data: new Date(Date.now()).toUTCString(),
        agres: false,
      },
    ],
  };
  mensagemChamadoOriginal;
  options: any = {
    weekday: 'short',
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  };

  ngOnInit() {
    this.mensagemChamadoOriginal = this.chamado.mensagens.map((x) => {
      return Object.assign({}, x);
    });
    if (this.translateService.currentLang == 'ptBr') {
      this.chamado.mensagens.map((mensagem) => {
        const mensagemOriginal = this.mensagemChamadoOriginal.find(
          (x) => x.id == mensagem.id,
        );
        mensagem.data = new Date(mensagemOriginal.data).toLocaleTimeString(
          'pt-br',
          this.options,
        );
      });
    }
    if (this.translateService.currentLang == 'en') {
      this.chamado.mensagens.map((mensagem) => {
        const mensagemOriginal = this.mensagemChamadoOriginal.find(
          (x) => x.id == mensagem.id,
        );
        mensagem.data = new Date(mensagemOriginal.data).toLocaleTimeString(
          'en',
          this.options,
        );
      });
    }
    if (this.translateService.currentLang == 'es') {
      this.chamado.mensagens.map((mensagem) => {
        const mensagemOriginal = this.mensagemChamadoOriginal.find(
          (x) => x.id == mensagem.id,
        );
        mensagem.data = new Date(mensagemOriginal.data).toLocaleTimeString(
          'es-AR',
          this.options,
        );
      });
    }

    this.changeLanguage();
    this.isAdministrador();
  }

  isAdministrador(): boolean {
    const usuario = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    return usuario.role === this.ROLE_ENUM.Admin;
  }

  bindHTMLTitle(home, title) {
    jQuery('.breadcrumb').html(
      `<li><a href="#">${home}</a></li><li class="active"><strong>${title}</strong></li>`,
    );
  }

  changeLanguage() {
    this.translateService.onLangChange.subscribe(
      (event: TranslationChangeEvent) => {
        if (event.lang === AppConstants.LANGUAGES.EN) {
          this.chamado.mensagens.map((mensagem) => {
            const mensagemOriginal = this.mensagemChamadoOriginal.find(
              (x) => x.id == mensagem.id,
            );
            mensagem.data = new Date(mensagemOriginal.data).toLocaleTimeString(
              'en',
              this.options,
            );
          });
        }
        if (event.lang === AppConstants.LANGUAGES.PT_BR) {
          this.chamado.mensagens.map((mensagem) => {
            const mensagemOriginal = this.mensagemChamadoOriginal.find(
              (x) => x.id == mensagem.id,
            );
            mensagem.data = new Date(mensagemOriginal.data).toLocaleTimeString(
              'pt-br',
              this.options,
            );
          });
        }
        if (event.lang === AppConstants.LANGUAGES.ES) {
          this.chamado.mensagens.map((mensagem) => {
            const mensagemOriginal = this.mensagemChamadoOriginal.find(
              (x) => x.id == mensagem.id,
            );
            mensagem.data = new Date(mensagemOriginal.data).toLocaleTimeString(
              'es-AR',
              this.options,
            );
          });
        }

        this.translateService.get('global.home').subscribe((home: string) => {
          var home = home;
          this.translateService
            .get('chamado.title-page')
            .subscribe((res: string) => {
              var title = res;
              this.bindHTMLTitle(home, title);
            });
        });
      },
    );
  }
}
