import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() btnClass: string;
  @Output() btnClick = new EventEmitter();
  @Input() btnTitle: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() type = 'button';
  @Input() disabled: boolean;
  @Input() action: 'reload';
  @Input() size: 'full' | 'large' | 'medium' | 'small' = 'medium';
  @Input() color:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'warning'
    | 'success'
    | 'info'
    | 'card'
    | 'return'
    | 'info-outline' = 'primary';

  constructor(public translateService: TranslateService) {}
}
