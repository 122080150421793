export class DateUtils {
    public static AddHours(date: Date, h): Date {
        date.setTime(date.getTime() + h * 60 * 60 * 1000);
        return date;
    }
    public static lessMinutes(date: Date, m): Date {
        date.setTime(date.getTime() - m * 60 * 1000);
        return date;
    }
}
