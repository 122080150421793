<div class="mapTrail">
  <div class="grids">
    <div class="mapTrail-temp">
      <app-weather
        *ngIf="operationSidePanelInfo"
        [operationSidePanel]="operationSidePanelInfo"
        [lat]="location.latitude"
        [long]="location.longitude"></app-weather>
      <app-operation-side
        *ngIf="operationSidePanelInfo"
        [operationSidePanel]="operationSidePanelInfo"
        [staticMessageOperationSidePanel]="staticOperationSidePanelInfo"></app-operation-side>
    </div>
    <div class="ng-star-inserted" *ngIf="apiLoaded">
      <div class="cards__desktop">
        <app-tabs>
          <app-tab tabTitle="{{ 'monitoring.tabs.title-operation' | translate }}" #tab1>
            <app-operation-tab
              *ngIf="tab1.selected"
              [operationTab]="operationTabInfo"
              [staticMessageOperationTab]="staticMessageOperationTabInfo"></app-operation-tab>
          </app-tab>
          <app-tab tabTitle="{{ 'monitoring.tabs.title-navigation' | translate }}" #tab3>
            <app-navigation-tab *ngIf="tab3.selected" [navigationTab]="navigationTabInfo"></app-navigation-tab>
          </app-tab>
        </app-tabs>
      </div>
      <div *ngIf="pointSelected">
        <div class="pointSelected">
          <button (click)="closeInfos()"><i class="agres-close"></i></button>
          <div class="pointSelected__info--text">
            <p>
              {{ 'monitoring.speed' | translate }}: <small>{{ pointVelocity }}</small>
            </p>
            <p>
              {{ 'monitoring.instant_rate' | translate }}: <small>{{ pointInstRate }}</small>
            </p>
            <p>
              {{ 'monitoring.date_and_time' | translate }}: <small>{{ pointDate }}</small>
            </p>
            <p>{{ 'monitoring.session_state' | translate }}:</p>
          </div>
          <div class="pointSelected__info">
            <img *ngFor="let sectionArr of pointStatus" [src]="sectionArr" alt="point" />
          </div>
        </div>
      </div>
      <google-map
        width="100%"
        [center]="{ lat: mapLat || 0, lng: mapLng || 0 }"
        [zoom]="zoom"
        [options]="{
          disableDefaultUI: true,
          zoomControl: false,
          scaleControl: true,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeId: 'satellite',
          panControl: true,
        }">
        <map-marker
          *ngIf="hasCoordinates()"
          [options]="{
            position: {
              lat: location.latitude,
              lng: location.longitude,
            },
            draggable: false,
            visible: marker,
            icon: {
              url: tractorIconHtml,
            },
          }"
          (mapClick)="markerClick()">
        </map-marker>
        <map-circle
          *ngFor="let circle of circles; let i = index"
          [options]="{
            strokeWeight: 0.6,
            strokeOpacity: 1,
            fillOpacity: 1,
            strokeColor: '#000',
            fillColor: changeColor(circle.selected, circle.instRate, circle.sectionArr, circle.gwAddr),
          }"
          [center]="circle.center"
          [radius]="circle.radius"
          (circleClick)="circleClick(i)" />
      </google-map>
      <div class="scaleDisplay" *ngIf="currentOperationType !== 5">
        <small class="text-danger">*{{'monitoring.edit-and-salve-legends' | translate}}</small>
        <ul>
          <li id="win02">
            <input
              id="trailColorValue.red"
              type="text"
              [(ngModel)]="trailColorValue.red"
              [ngModelOptions]="{ standalone: true }"
              class="legendText input-custom"
              (click)="seeButtonSave = true" />
          </li>
          <li id="win03">
            <input
              id="trailColorValue.orange"
              type="text"
              [(ngModel)]="trailColorValue.orange"
              [ngModelOptions]="{ standalone: true }"
              class="legendText input-custom"
              (click)="seeButtonSave = true" />
          </li>
          <li id="win04">
            <input
              id="trailColorValue.yellow"
              type="text"
              [(ngModel)]="trailColorValue.yellow"
              [ngModelOptions]="{ standalone: true }"
              class="legendText input-custom"
              (click)="seeButtonSave = true" />
          </li>
          <li id="win05">
            <input
              id="trailColorValue.lemonGreen"
              type="text"
              [(ngModel)]="trailColorValue.lemonGreen"
              [ngModelOptions]="{ standalone: true }"
              class="legendText input-custom"
              (click)="seeButtonSave = true" />
          </li>
          <li id="win06">
            <input
              id="trailColorValue.green"
              type="text"
              [(ngModel)]="trailColorValue.green"
              [ngModelOptions]="{ standalone: true }"
              class="legendText input-custom"
              (click)="seeButtonSave = true" />
          </li>
          <li>
            <button [hidden]="!seeButtonSave" (click)="saveTx()">
              {{ 'global.save' | translate }}
            </button>
          </li>
        </ul>
      </div>
      <div class="cards__mobile">
        <app-tabs>
          <app-tab tabTitle="{{ 'monitoring.tabs.title-operation' | translate }}" #taMobile1>
            <app-operation-tab
              *ngIf="taMobile1.selected"
              [operationTab]="operationTabInfo"
              [staticMessageOperationTab]="staticMessageOperationTabInfo"></app-operation-tab>
          </app-tab>
          <app-tab tabTitle="{{ 'monitoring.tabs.title-navigation' | translate }}" #taMobile3>
            <app-navigation-tab *ngIf="taMobile3.selected" [navigationTab]="navigationTabInfo"></app-navigation-tab>
          </app-tab>
        </app-tabs>
      </div>
    </div>
  </div>
</div>
<div class="trailMobile">
  <div class="trailList">
    <div *ngFor="let card of cards">
      <div class="trailDashboard">
        <div class="trailDashboard__content">
          <div class="trailDashboard__content--info">
            <div class="trailDashboard__content--info---img">
              <img [src]="card.img" alt="Weather" />
            </div>
            <div class="trailDashboard__content--info---description">
              <h1>{{ card.label | translate }}</h1>
              <p>{{ card.value }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal title="global.status" [show]="showModal" [showSubject]="showModalSubject">
  <div class="alertDevice">
    <i class="agres-fleet-monitoring-off"></i>
    <h6>{{ 'monitoring.deviceNotConnect' | translate }}</h6>
    <div class="alertDevice__actions">
      <app-button
        (btnClick)="goBack()"
        type="button"
        color="return"
        icon="agres-return"
        title="{{ 'global.back' | translate }}">
      </app-button>
    </div>
  </div>
</app-modal>
