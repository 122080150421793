import {
  OpType,
  OpTypeLegacy,
  OperationType,
} from 'app/modules/gestao-operacao/fleet-monitoring/enums/operation-type.enum';
import { AppConstants } from '../app.constants';

export class OperationTypeUtil {
  isOperationOther(operation: OperationType): boolean {
    if (operation === OpType.OTHER || operation === OpTypeLegacy.OTHER) {
      return true;
    } else {
      return false;
    }
  }
}
