import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nao-encontrada',
  templateUrl: './nao-encontrada.component.html',
  styleUrls: ['./nao-encontrada.component.scss']
})
export class NaoEncontradaComponent {

  constructor(
    public _location: Location,
  ) { }

  return() {
    this._location.back();
}

}
