import { Component, Input, OnChanges } from '@angular/core';
import {
  OpType,
  OpTypeLegacy,
  OperationType,
} from '../../enums/operation-type.enum';
import { SectionState } from '../../enums/section-state.enum';
import {
  DynamicMessageOperationTabModel,
  StaticMessageOperationTabModel,
} from './operation-tab.model';

type CardList = Array<Card>;
type Card = Array<Line>;
type Line = {
  label: string;
  value: any;
};

@Component({
  selector: 'app-operation-tab',
  templateUrl: './operation-tab.component.html',
  styleUrls: ['./operation-tab.component.scss'],
})
export class OperationTabComponent implements OnChanges {
  @Input() operationTab: DynamicMessageOperationTabModel =
    new DynamicMessageOperationTabModel();
  @Input() staticMessageOperationTab: StaticMessageOperationTabModel =
    new StaticMessageOperationTabModel();
  operationType: OperationType;
  listOfCards?: CardList;
  instRateUnit = 'L/ha';
  imgSessionOpenPath = '../../../assets/images/default/aberto.png';
  imgSessionClosedPath = '../../../assets/images/default/fechado.png';
  statusSession: string[];
  lines?: Line[];

  constructor() {
    this.updateValues();
    this.updateListOfCards();
  }

  ngOnChanges() {
    this.updateListOfCards();
  }

  private updateValues() {
    if (
      this.operationTab?.operationType !== OpType.PLANTING &&
      this.operationTab?.operationType !== OpTypeLegacy.PLANTING
    ) {
      this.lines = [
        {
          label: 'monitoring.tabs.operation.label-culture',
          value: this.staticMessageOperationTab?.cropType,
        },
        {
          label: 'monitoring.tabs.operation.label-talhao',
          value: this.staticMessageOperationTab?.cropName,
        },
        {
          label: 'monitoring.tabs.operation.label-event',
          value: this.staticMessageOperationTab?.eventName,
        },
        {
          label: 'monitoring.tabs.operation.label-distance',
          value: this.operationTab?.travelDistance.toString() + ' km',
        },
        {
          label: 'monitoring.tabs.operation.title-graphs',
          value: this.operationTab?.operationTimePercent.toString() + '%',
        },
        {
          label: 'monitoring.tabs.operation.legend-dead',
          value: this.operationTab?.operationSleepTime + 'h',
        },
        {
          label: 'monitoring.tabs.operation.legend-effective',
          value: this.operationTab?.operationActiveTime + 'h',
        },
        {
          label: 'monitoring.tabs.operation.legend-total',
          value: this.operationTab?.operationTotalTime + 'h',
        },
        {
          label: 'monitoring.tabs.operation.label-session',
          value: this.operationTab?.numberOfSections.toString(),
        },
        {
          label: 'monitoring.tabs.operation.label-tx-instantaneous',
          value:
            this.operationTab?.instRate.toFixed(1).toString() +
            this.instRateUnit,
        },
        {
          label: 'monitoring.tabs.operation.label-beaks-pul',
          value: this.staticMessageOperationTab?.numberOfNozzles.toString(),
        },
        {
          label: 'monitoring.tabs.operation.label-spacing-beaks',
          value:
            this.staticMessageOperationTab?.spacingPerNozzles.toString() +
            ' cm',
        },
        {
          label: 'monitoring.tabs.operation.label-implement',
          value: this.operationTab?.implementWidth.toFixed(1).toString() + ' m',
        },
        {
          label: 'monitoring.tabs.operation.label-beaks-session',
          value: this.staticMessageOperationTab?.nozzlesPerSection.toString(),
        },
        {
          label: 'monitoring.tabs.operation.label-status-session',
          value: this.statusSession,
        },
      ];
    } else {
      this.lines = [
        {
          label: 'monitoring.tabs.operation.label-culture',
          value: this.staticMessageOperationTab?.cropType,
        },
        {
          label: 'monitoring.tabs.operation.label-field',
          value: this.staticMessageOperationTab?.cropName,
        },
        {
          label: 'monitoring.tabs.operation.label-event',
          value: this.staticMessageOperationTab?.eventName,
        },
        {
          label: 'monitoring.tabs.operation.label-distance',
          value: this.operationTab?.travelDistance.toString() + ' Km',
        },
        {
          label: 'monitoring.tabs.operation.title-graphs',
          value: this.operationTab?.operationTimePercent.toString() + '%',
        },
        {
          label: 'monitoring.tabs.operation.legend-dead',
          value: this.operationTab?.operationSleepTime + 'h',
        },
        {
          label: 'monitoring.tabs.operation.legend-effective',
          value: this.operationTab?.operationActiveTime + 'h',
        },
        {
          label: 'monitoring.tabs.operation.legend-total',
          value: this.operationTab?.operationTotalTime + 'h',
        },
        {
          label: 'monitoring.tabs.operation.label-session',
          value: this.operationTab?.numberOfSections.toString(),
        },
        {
          label: 'monitoring.tabs.operation.label-implement',
          value: this.operationTab?.implementWidth.toFixed(1).toString() + ' m',
        },
      ];
    }
  }

  formatUnits() {
    this.instRateUnit =
      this.operationTab?.operationType !== OpType.FERTILIZATION
        ? ' L/ha'
        : ' kg/ha';

    const status: string[] = [];
    for (let i = 0; i < this.operationTab?.sectionArr.length; i++) {
      if (this.operationTab.sectionArr[i] === SectionState.OPEN) {
        status.push(this.imgSessionOpenPath);
      } else {
        status.push(this.imgSessionClosedPath);
      }
    }
    this.statusSession = [...status];
  }

  private updateListOfCards() {
    this.formatUnits();
    this.updateValues();
    const modifiedLines = this.modifyLinesPerOperation(this.lines);
    this.listOfCards = this.formatAsListOfCards(modifiedLines, 4);
  }

  modifyLinesPerOperation(lines: Array<Line>): Array<Line> {
    let copyOfLines = [...lines];

    if (this.operationTab?.operationType === OpType.OTHER) {
      copyOfLines = this.removeSpecificLinesForOpTypeOutros(copyOfLines);
    }

    return copyOfLines;
  }

  removeSpecificLinesForOpTypeOutros(lines: Array<Line>): Array<Line> {
    lines.splice(8, 4);
    lines.splice(9, 2);

    return lines;
  }

  formatAsListOfCards(lines: Array<Line>, linesPerCard: number): CardList {
    let listOfCards: CardList = [];
    let cardCount = 0;

    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
      if (lineIndex % linesPerCard == 0) {
        listOfCards[cardCount++] = [];
      }

      const line: Line = {
        label: lines[lineIndex].label,
        value: lines[lineIndex].value,
      };

      if (line.value !== '') {
        listOfCards[cardCount - 1].push(line);
      }
    }

    return listOfCards;
  }
}
