<app-card-prototype>
  <div class="cardTasks">
    <div
      [ngClass]="{
        cardTasks__content: true,
        'cardTasks__content--disabled': disableButton(status),
      }"
      (click)="onCardClick()">
      <div class="cardTasks__content--info">
        <div class="cardTasks__content--info---image">
          <ng-container [ngTemplateOutlet]="cardImage"></ng-container>
        </div>
        <div class="cardTasks__content--info---description">
          <h1>{{ title }}</h1>
          <p *ngIf="areaCoberta">{{ content['Area Coberta'] }} - {{ content['Operação'] | translate }}</p>
          <p *ngIf="operacao === 'ISOBUS'">{{ operacao }}</p>
          <p>{{ data }}</p>
          <p>
            <img [src]="imagemPathEquipamento" />
            <span [class]="getStatusClass(status)"> {{ statusTranslationPrefix + status | translate }}</span>
          </p>
          <p *ngIf="cardNotificationGFreeTier">
            <ng-template [ngTemplateOutlet]="cardNotificationGFreeTier"></ng-template>
          </p>
        </div>
      </div>
      <div class="cardTasks__content--menu">
        <div
          class="cardTasks__content--menu---icon"
          (click)="onClickMenu($event)"
          id="menuCard"
          data-bs-toggle="dropdown">
          <app-agres-icons iconsClass="agres-menu-card"></app-agres-icons>
        </div>
        <div class="dropdown-menu" aria-labelledby="menuCard">
          <div [hidden]="!openMenu">
            <div *ngFor="let item of menuItems">
              <app-link
                styleClass="dropdown-item"
                (click)="item.callback(id, $event)"
                title="{{ item.label | translate }}">
              </app-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cardTasks__footer">
      <app-button
        type="button"
        color="card"
        size="full"
        icon="agres-action"
        (click)="onCardClick()"
        [disabled]="disableButton(status)"
        title="{{ 'global.view' | translate }}">
      </app-button>
    </div>
  </div>
</app-card-prototype>
