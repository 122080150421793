import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConvertUnit } from 'app/shared/pipes/convertUnit.pipe';

@Component({
  selector: 'app-data-box-item',
  templateUrl: './data-box-item.component.html',
  styleUrls: ['./data-box-item.component.scss'],
})
export class DataBoxItemComponent implements OnInit {
  @Input() item: [string, any, boolean] | [string, any];
  @Input() breakpoint: number;
  breakLine = true;
  translatedKey = '';
  value: any = '';
  constructor(
    private translate: TranslateService,
    private convertUnit: ConvertUnit,
  ) {}
  ngOnInit(): void {
    this.value = this.item[1];

    // TODO: melhorar lógica após Agrishow
    if (this.value?.includes(' x ')) {
      const splitValues = this.value.split(' x ');
      const firstPart = splitValues[0].split(',');
      const secondPart = splitValues[1].split(',');
      const partA = this.convertUnit.transform(+firstPart[0], firstPart[1]);
      const partB = this.convertUnit.transform(+secondPart[0], secondPart[1]);
      this.value = `${partA} x ${partB}`;
    } else if (Array.isArray(this.value)) {
      this.value = this.convertUnit.transform(this.value[0], this.value[1]);
    }

    this.translateKeyAndCheckLineBreak();
    this.translate.onLangChange.subscribe(() => {
      this.translateKeyAndCheckLineBreak();
    });
  }

  private translateKeyAndCheckLineBreak() {
    this.translate.get(this.item[0]).subscribe((translatedKey: string) => {
      this.translatedKey = translatedKey;
      const itemValueLength =
        typeof this.value === 'string'
          ? this.value.length
          : this.value?.toString().length || 0;
      this.breakLine =
        translatedKey.length + itemValueLength >= this.breakpoint;
    });
  }
}
