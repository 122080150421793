<div class="row">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-content">
        <h2>
          <strong>{{ 'called.title' | translate }}</strong>
        </h2>
        <div style="margin-bottom: 10px !important">
          <div class="search-form">
            <form>
              <div class="input-group" style="width: 100% !important">
                <input
                  name="search"
                  [(ngModel)]="search"
                  class="form-control form-control-lg"
                  style="border-radius: 0 !important" />
                <div class="input-group-btn">
                  <button class="btn btn-primary" type="submit" (click)="searchItens()">
                    {{ 'global.search' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <perfect-scrollbar style="max-height: 600px">
          <table
            datatable="ng"
            dt-options="dtOptions"
            class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th>
                  {{ 'called.number' | translate }}
                </th>
                <th>
                  {{ 'called.description' | translate }}
                </th>
                <th>
                  {{ 'called.service' | translate }}
                </th>
                <th>
                  {{ 'called.category' | translate }}
                </th>
                <th>
                  {{ 'global.status' | translate }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let chamado of chamadosFilter">
                <td>#{{ chamado.numero }}</td>
                <td>{{ chamado.descricao }}</td>
                <td>{{ chamado.servico }}</td>
                <td>{{ chamado.categoria }}</td>
                <td>
                  <span class="badge badge-primary" *ngIf="chamado.status === 'Aberto'">{{ chamado.status }}</span>
                  <span class="badge badge-danger" *ngIf="chamado.status === 'Fechado'">{{ chamado.status }}</span>
                </td>
                <td>
                  <button class="btn btn-primary btn-sm" type="button" [routerLink]="[chamado.numero]">
                    <i class="far fa-eye"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</div>
