<app-alert></app-alert>
<div class="download">
  <section class="download__header">
    <div class="row download__header--welcome">
      <div class="col-sm-6 col-md-9 col-lg-10 download__header--welcome---col-left">
        <h1 [ngClass]="{ noAdminTitle: !canUpload }">{{ 'global.support-modal-page-title' | translate }}</h1>
        <p [ngClass]="{ noAdminDescription: !canUpload }">
          {{ 'global.support-modal-page-text' | translate }}
        </p>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 download__header--welcome---col-right">
        <button *ngIf="canUpload" mat-button (click)="openDialog()">
          {{ 'global.support-send-file' | translate }}
        </button>
        <app-button
          *ngIf="!canUpload"
          type="button"
          color="warning"
          icon="agres-eye"
          (btnClick)="navigateToSiteAgres()"
          title="{{ 'global.support-btn-contact-us' | translate }}">
        </app-button>
      </div>
    </div>

  </section>

  <section class="download__noFiles" *ngIf="noFiles">
    <h2>{{ 'global.support-product-no-files-text' | translate }}</h2>
  </section>

  <!-- Loop to create category -->
  <section *ngFor="let categoria of orderedCategories" [hidden]="!categoria.value || categoria.value.length === 0">
    <div id="desktop" class="download__content">
      <div class="row download__content--row">
        <h2>{{ 'global.support-product-category-' + categoria.key | translate }}</h2>

        <div
          class="col-sm-6 col-md-4 col-lg-2 download__content--row---card-product"
          *ngFor="let item of categoria.value"
          (click)="openDialogProductData(item)"
          [ngClass]="{ 'download--active-product': selectedProduct === item.id }">
          <img *ngIf="item.imageUrl" [src]="item.imageUrl" alt="product image" />
          <p>{{ item.id }}</p>
        </div>
      </div>
    </div>
    <!-- End loop to create category -->

    <!-- MOBILE loop to create category -->
    <div id="mobile">
      <h2 class="category-product-name">{{ 'global.support-product-category-' + categoria.key | translate }}</h2>
      <div class="carousel-container">
        <div class="carousel">
          <div
            class="carousel-item"
            *ngFor="let item of categoria.value"
            (click)="openDialogProductData(item)"
            [ngClass]="{ 'download--active-product': selectedProduct === item.id }">
            <img *ngIf="item.imageUrl" [src]="item.imageUrl" alt="product image" />
            <p>{{ item.id }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--END MOBILE Loop to create category -->
  <ng-template #dialogTemplateProductData>
    <div class="download__content--close">
      <i mat-dialog-close class="i-close-modal agres-close"></i>
    </div>
    <div class="download__table---header">
      <h2>{{ 'global.support-product-modal-title-file-download' | translate }}</h2>
      <p>{{ 'global.support-product-message-default' | translate }}</p>
    </div>
    <mat-dialog-content class="scrollable-content">
      <!-- Softwares Section -->
      <section *ngIf="selectedItemInfo?.softwares?.length > 0" class="download__table">
        <div class="download__table---title">
          <h3>SOFTWARE</h3>
        </div>
        <div class="row download__table---content" *ngFor="let software of selectedItemInfo.softwares">
          <div class="col-sm-12 col-md-10 col-lg-9 download__table---content___data">
            <span>{{ software.fileName }} <strong> | </strong></span>
            <span>{{ 'global.support-version' | translate }} {{ software.version }} </span>
            <strong> | </strong>
            <span> .{{ software.fileExtension }}</span>
            <strong> | </strong>
            <span>{{ software.date | date: 'dd-MM-yyyy' }}</span>
            <strong> | </strong>
            <div class="download__table---content___data--flagsDesktop">
              <img
                *ngFor="let lang of selectedItemInfo.softwares[0].productLanguages"
                [src]="'./../../../../assets/images/support-page/flags/' + lang + '.png'"
                [alt]="lang" />
            </div>
          </div>
          <div class="download__table---content___data--flagsMobile">
            <img
              *ngFor="let lang of selectedItemInfo.softwares[0].productLanguages"
              [src]="'./../../../../assets/images/support-page/flags/' + lang + '.png'"
              [alt]="lang" />
          </div>
          <!-- description delete and download buttons -->
          <div class="col-sm-12 col-md-10 col-lg-3 download__table---content___button">
            <button
              type="button"
              class="download__table---content___button---description"
              (click)="openDialogDescription(software)">
              <i>
                <img
                  src="./../../../../assets/images/support-page/icons/clipboard-text-cinza.svg"
                  alt="icon description" />
              </i>
              {{ 'global.support-description' | translate }}
            </button>

            <ng-template #dialogTemplateDescription let-data>
              <h2 mat-dialog-title>{{ 'global.support-description' | translate }}</h2>
              <mat-dialog-content class="scrollable-content">
                <p class="modal-description-paragraph">{{ data?.description }}</p>
              </mat-dialog-content>

              <mat-dialog-actions>
                <button mat-button mat-dialog-close>{{ 'global.support-modal-btn-close' | translate }}</button>
              </mat-dialog-actions>
            </ng-template>

            <button
              type="button"
              [attr.data-url]="software.filePath"
              class="download__table---content___button---download"
              (click)="
                downloadFile(software.filePath, software.fileName, software.version, software.date, 'softwares')
              ">
              <i>
                <img
                  src="./../../../../assets/images/support-page/icons/download-simple-blue.svg"
                  alt="icon download" />
              </i>
              {{ 'download-support-page.btn-download-file' | translate }}
            </button>

            <button
              *ngIf="canUpload"
              type="button"
              class="download__table---content___button---delete"
              (click)="
                openModalToDeleteFile(
                  'softwares',
                  software.fileName,
                  software.filePath,
                  software.version,
                  software.date
                )
              ">
              <i>
                <img src="./../../../../assets/images/support-page/icons/icon-trash-white.svg" alt="icon delete" />
              </i>
              {{ 'global.delete' | translate }}
            </button>
          </div>
          <!-- end description delete and download buttons -->
        </div>
      </section>

      <!-- Guides Section -->
      <section *ngIf="selectedItemInfo?.guides?.length > 0" class="download__table">
        <div class="download__table---title">
          <h3>{{ 'global.guides' | translate }}</h3>
        </div>
        <div class="row download__table---content" *ngFor="let guide of selectedItemInfo.guides">
          <div class="col-sm-12 col-md-8 col-lg-8 download__table---content___data">
            <span>{{ guide.fileName }} <strong> | </strong></span>
            <span>{{ 'global.support-version' | translate }} {{ guide.version }} </span>
            <strong> | </strong>
            <span> .{{ guide.fileExtension }}</span>
            <strong> | </strong>
            <span>{{ guide.date | date: 'dd-MM-yyyy' }}</span>
            <strong> | </strong>
            <div class="download__table---content___data--flagsDesktop">
              <img
                *ngFor="let lang of selectedItemInfo.guides[0].productLanguages"
                [src]="'./../../../../assets/images/support-page/flags/' + lang + '.png'"
                [alt]="lang" />
            </div>
          </div>
          <div class="download__table---content___data--flagsMobile">
            <img
              *ngFor="let lang of selectedItemInfo.guides[0].productLanguages"
              [src]="'./../../../../assets/images/support-page/flags/' + lang + '.png'"
              [alt]="lang" />
          </div>
          <!-- description delete and download buttons -->
          <div class="col-sm-12 col-md-4 col-lg-4 download__table---content___button">
            <button
              type="button"
              class="download__table---content___button---description"
              (click)="openDialogDescription(guide)">
              <i>
                <img
                  src="./../../../../assets/images/support-page/icons/clipboard-text-cinza.svg"
                  alt="icon description" />
              </i>
              {{ 'global.support-description' | translate }}
            </button>

            <ng-template #dialogTemplateDescription let-data>
              <h2 mat-dialog-title>{{ 'global.support-description' | translate }}</h2>
              <mat-dialog-content class="scrollable-content">
                <p class="modal-description-paragraph">{{ data?.description }}</p>
              </mat-dialog-content>

              <mat-dialog-actions>
                <button mat-button mat-dialog-close>{{ 'global.support-modal-btn-close' | translate }}</button>
              </mat-dialog-actions>
            </ng-template>

            <button
              type="button"
              [attr.data-url]="guide.filePath"
              class="download__table---content___button---download"
              (click)="downloadFile(guide.filePath, guide.fileName, guide.version, guide.date, 'guides')">
              <i>
                <img
                  src="./../../../../assets/images/support-page/icons/download-simple-blue.svg"
                  alt="icon download" />
              </i>
              {{ 'download-support-page.btn-download-file' | translate }}
            </button>

            <button
              *ngIf="canUpload"
              type="button"
              class="download__table---content___button---delete"
              (click)="openModalToDeleteFile('guides', guide.fileName, guide.filePath, guide.version, guide.date)">
              <i>
                <img src="./../../../../assets/images/support-page/icons/icon-trash-white.svg" alt="icon delete" />
              </i>
              {{ 'global.delete' | translate }}
            </button>
          </div>
          <!-- end description delete and download buttons -->
        </div>
      </section>

      <!-- Manuals Section -->
      <section *ngIf="selectedItemInfo?.manuals?.length > 0" class="download__table">
        <div class="download__table---title">
          <h3>{{ 'global.manuals' | translate }}</h3>
        </div>
        <div class="row download__table---content" *ngFor="let manual of selectedItemInfo.manuals">
          <div class="col-sm-12 col-md-8 col-lg-8 download__table---content___data">
            <span>{{ manual.fileName }} <strong> | </strong></span>
            <span>{{ 'global.support-version' | translate }} {{ manual.version }} </span>
            <strong> | </strong>
            <span> .{{ manual.fileExtension }}</span>
            <strong> | </strong>
            <span>{{ manual.date | date: 'dd-MM-yyyy' }}</span>
            <strong> | </strong>
            <div class="download__table---content___data--flagsDesktop">
              <img
                *ngFor="let lang of selectedItemInfo.manuals[0].productLanguages"
                [src]="'./../../../../assets/images/support-page/flags/' + lang + '.png'"
                [alt]="lang" />
            </div>
          </div>
          <div class="download__table---content___data--flagsMobile">
            <img
              *ngFor="let lang of selectedItemInfo.manuals[0].productLanguages"
              [src]="'./../../../../assets/images/support-page/flags/' + lang + '.png'"
              [alt]="lang" />
          </div>
          <!-- description delete and download buttons -->
          <div class="col-sm-12 col-md-4 col-lg-4 download__table---content___button">
            <button
              type="button"
              class="download__table---content___button---description"
              (click)="openDialogDescription(manual)">
              <i>
                <img
                  src="./../../../../assets/images/support-page/icons/clipboard-text-cinza.svg"
                  alt="icon description" />
              </i>
              {{ 'global.support-description' | translate }}
            </button>

            <ng-template #dialogTemplateDescription let-data>
              <h2 mat-dialog-title>{{ 'global.support-description' | translate }}</h2>
              <mat-dialog-content class="scrollable-content">
                <p class="modal-description-paragraph">{{ data?.description }}</p>
              </mat-dialog-content>

              <mat-dialog-actions>
                <button mat-button mat-dialog-close>{{ 'global.support-modal-btn-close' | translate }}</button>
              </mat-dialog-actions>
            </ng-template>

            <button
              type="button"
              [attr.data-url]="manual.filePath"
              class="download__table---content___button---download"
              (click)="downloadFile(manual.filePath, manual.fileName, manual.version, manual.date, 'manuals')">
              <i>
                <img
                  src="./../../../../assets/images/support-page/icons/download-simple-blue.svg"
                  alt="icon download" />
              </i>
              {{ 'download-support-page.btn-download-file' | translate }}
            </button>

            <button
              *ngIf="canUpload"
              type="button"
              class="download__table---content___button---delete"
              (click)="openModalToDeleteFile('manuals', manual.fileName, manual.filePath, manual.version, manual.date)">
              <i>
                <img src="./../../../../assets/images/support-page/icons/icon-trash-white.svg" alt="icon delete" />
              </i>
              {{ 'global.delete' | translate }}
            </button>
          </div>
          <!-- end description delete and download buttons -->
        </div>
      </section>

      <!-- Others Section -->
      <section *ngIf="selectedItemInfo?.others?.length > 0" class="download__table">
        <div class="download__table---title">
          <h3>{{ 'global.others' | translate }}</h3>
        </div>
        <div class="row download__table---content" *ngFor="let other of selectedItemInfo.others">
          <div class="col-sm-12 col-md-8 col-lg-8 download__table---content___data">
            <span>{{ other.fileName }} <strong> | </strong></span>
            <span>{{ 'global.support-version' | translate }} {{ other.version }} </span>
            <strong> | </strong>
            <span> .{{ other.fileExtension }}</span>
            <strong> | </strong>
            <span>{{ other.date | date: 'dd-MM-yyyy' }}</span>
            <strong> | </strong>
            <div class="download__table---content___data--flagsDesktop">
              <img
                *ngFor="let lang of selectedItemInfo.others[0].productLanguages"
                [src]="'./../../../../assets/images/support-page/flags/' + lang + '.png'"
                [alt]="lang" />
            </div>
          </div>
          <div class="download__table---content___data--flagsMobile">
            <img
              *ngFor="let lang of selectedItemInfo.others[0].productLanguages"
              [src]="'./../../../../assets/images/support-page/flags/' + lang + '.png'"
              [alt]="lang" />
          </div>
          <!-- description delete and download buttons -->
          <div class="col-sm-12 col-md-4 col-lg-4 download__table---content___button">
            <button
              type="button"
              class="download__table---content___button---description"
              (click)="openDialogDescription(other)">
              <i>
                <img
                  src="./../../../../assets/images/support-page/icons/clipboard-text-cinza.svg"
                  alt="icon description" />
              </i>
              {{ 'global.support-description' | translate }}
            </button>

            <ng-template #dialogTemplateDescription let-data>
              <h2 mat-dialog-title>{{ 'global.support-description' | translate }}</h2>
              <mat-dialog-content class="scrollable-content">
                <p class="modal-description-paragraph">{{ data?.description }}</p>
              </mat-dialog-content>

              <mat-dialog-actions>
                <button class="btn-close-modal" mat-button mat-dialog-close>
                  {{ 'global.support-modal-btn-close' | translate }}
                </button>
              </mat-dialog-actions>
            </ng-template>

            <button
              type="button"
              [attr.data-url]="other.filePath"
              class="download__table---content___button---download"
              (click)="downloadFile(other.filePath, other.fileName, other.version, other.date, 'others')">
              <i>
                <img
                  src="./../../../../assets/images/support-page/icons/download-simple-blue.svg"
                  alt="icon download" />
              </i>
              {{ 'download-support-page.btn-download-file' | translate }}
            </button>

            <button
              *ngIf="canUpload"
              type="button"
              class="download__table---content___button---delete"
              (click)="openModalToDeleteFile('others', other.fileName, other.filePath, other.version, other.date)">
              <i>
                <img src="./../../../../assets/images/support-page/icons/icon-trash-white.svg" alt="icon delete" />
              </i>
              {{ 'global.delete' | translate }}
            </button>
          </div>
          <!-- end description delete and download buttons -->
        </div>
      </section>

      <!-- Empty state -->
      <section *ngIf="noFiles">
        <div class="download__table">
          <p class="download__table---message-not-exists">
            {{ 'global.support-message-empty-files' | translate }}
          </p>
        </div>
      </section>

    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="btn-close-modal" mat-button mat-dialog-close>
        {{ 'global.support-modal-btn-close' | translate }}
      </button>
    </mat-dialog-actions>
  </ng-template>
</div>

<app-confirmar-modal
  *ngIf="showConfirmationModal"
  [(modal)]="showConfirmationModal"
  [mensagem]="confirmationModalMessage"
  [tipo]="confirmationModalType"
  [titulo]="modalTitleConfirmation"
  [confirmButtonText]="modalTranslateButtonConfirmText"
  [cancelButtonText]="modalTranslateButtonCancelText"
  (modalChange)="showConfirmationModal = $event"
  (buttonAction)="onButtonAction($event)">
</app-confirmar-modal>
