import { Component, Input } from '@angular/core';
import { DynamicMessageOperationSidePanelModel } from './operation-side-panel/operation-side-panel.model';
import {
  DynamicMessageOperationTabModel,
  StaticMessageOperationTabModel,
} from './operation-tab/operation-tab.model';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
})
export class OperationsComponent {
  @Input() currentTab: string;
  operationSidePanelInfo: DynamicMessageOperationSidePanelModel;
  operationTabInfo: DynamicMessageOperationTabModel;
  staticMessageOperationTabInfo: StaticMessageOperationTabModel;

  @Input() set operationSidePanel(
    value: DynamicMessageOperationSidePanelModel,
  ) {
    if (value) {
      this.operationSidePanelInfo = value;
    }
  }

  @Input() set operationTab(value: DynamicMessageOperationTabModel) {
    if (value) {
      this.operationTabInfo = value;
      this.staticMessageOperationTabInfo.nozzlesPerSection.splice(
        this.operationTabInfo.numberOfSections,
      );
    }
  }
}
