import { AppConstants } from 'app/app.constants';
import { GuideType } from 'app/utils/guide.utils';
import { EventoNVG } from '../shared/models/eventoNVG';
import { SystemNumberFormatPipe } from 'app/shared/pipes/systemNumberFormat.pipe';

export const OPERACAO = {
  PULVERIZACAO: 'pulverizacao',
  COLHEITA: 'colheita',
  PLANTIO: 'plantio',
  ADUBACAO: 'adubacao',
  IRRIGACAO: 'irrigacao',
  NAVEGACAO: 'navegacao',
  OUTROS: 'outros',
  FRUTICULTURA: 'fruticultura',
};

const ERROR_ON_FIELD = 'error';

const TYPE_MACHINE = {
  LANCO_1_ESTEIRA: 'LANCO_1_ESTEIRA',
  ACCURA_ACOPLADO: 'ACCURA_ACOPLADO',
  ACCURA_REBOCADO: 'ACCURA_REBOCADO',
  STRONGER_DF: 'STRONGER_DF',
  QUADRA: 'QUADRA',
  GTS: 'GTS',
  EM_LINHA: 'EM_LINHA',
  TAURUS_8000: 'TAURUS_8000',
  GRAVIDADE: 'GRAVITY',
  UNKNOWN: 'UNKNOWN',
};

export class ReportObjectGenerator {
  systemNumberFormatPipe: SystemNumberFormatPipe = new SystemNumberFormatPipe();
  public translateService: any;
  public convertUnitPipe: any;
  public datePipe: any;
  public eventoNvg: any;
  public etapasArr: any;
  public guideType: any;
  public nvgStorageData: any;

  constructor(
    translateService,
    datePipe,
    convertUnitPipe,
    nvgStorageData: EventoNVG,
  ) {
    this.datePipe = datePipe;
    this.translateService = translateService;
    this.convertUnitPipe = convertUnitPipe;
    this.guideType = GuideType;
    this.eventoNvg = nvgStorageData.trabalho;
    this.etapasArr = nvgStorageData.etapa;
    this.nvgStorageData = nvgStorageData;
  }

  _generateValveData(translate, valveHead, valve) {
    const valveObject = [];
    if (valve === 'DOT_NOT_HAVE_WAY') {
      valveObject.push([
        valveHead,
        translate.monitoring.tabs.operation['label-do-not_have_way'],
      ]);
    } else if (valve === '2_WAY_VALVE') {
      valveObject.push([
        valveHead,
        '2 ' + translate.monitoring.tabs.operation['label-way-valve'],
      ]);
    } else if (valve === '3_WAY_VALVE') {
      valveObject.push([
        valveHead,
        '3 ' + translate.monitoring.tabs.operation['label-way-valve'],
      ]);
    } else {
      valveObject.push([
        valveHead,
        translate.monitoring.tabs.operation['label-unknown'],
      ]);
    }
    return valveObject;
  }

  _generateSpecificOrchardData(translate, step) {
    const orchardData = this._generateSpecificSprayingData(translate, step);
    orchardData.push([
      translate.monitoring.tabs.operation['label-street-width'],
      this.convertUnitPipe.transform(step.street_width, 'm'),
    ]);
    orchardData.push([
      translate.monitoring.tabs.operation['label-implement-type'],
      translate.monitoring.tabs.operation[step.impl_type],
    ]);
    return orchardData;
  }

  _generateSpecificFertilizingData(translate, step) {
    const fertilizingData = [];

    fertilizingData.push([
      translate.monitoring.tabs.operation['label-rate-type'],
      translate.monitoring.tabs.operation[step.rate_type],
    ]);
    if (step.application_rate_1 && step.application_rate_2) {
      fertilizingData.push([
        translate.monitoring.tabs.operation['label-dose-fixed-rate'] + ' 1:',
        this.convertUnitPipe.transform(step.application_rate_1, 'kg/ha'),
      ]);
      fertilizingData.push([
        translate.monitoring.tabs.operation['label-dose-fixed-rate'] + ' 2:',
        this.convertUnitPipe.transform(step.application_rate_2, 'kg/ha'),
      ]);
    }
    if (step.map_name) {
      fertilizingData.push([
        translate.monitoring.tabs.operation['label-map'],
        step.map_name,
      ]);
      fertilizingData.push([
        translate.monitoring.tabs.operation['label-map-parameter'],
        step.selected_parameter_name,
      ]);
      fertilizingData.push([
        translate.monitoring.tabs.operation['label-map-area'],
        this.convertUnitPipe.transform(step.current_map_area, 'ha'),
      ]);
    }

    if (step.conveyor_section_or_gate_constant) {
      const measurementUnit =
        step.work_constant_measure_mode !== ' '
          ? translate.monitoring.tabs.operation[step.work_constant_measure_mode]
          : step.work_constant_measure_mode;

      fertilizingData.push([
        translate.monitoring.tabs.operation['label-working-constant'],
        String(step.conveyor_section_or_gate_constant) + measurementUnit,
      ]);
    }

    if (step.conveyor_belt_min_rotation) {
      fertilizingData.push([
        translate.monitoring.tabs.operation['label-minimum-belt-rotation'],
        String(step.conveyor_belt_min_rotation) + ' RPM',
      ]);
      fertilizingData.push([
        translate.monitoring.tabs.operation['label-maximum-belt-rotation'],
        String(step.conveyor_belt_max_rotation) + ' RPM',
      ]);
      if (step.disk_min_rotation) {
        fertilizingData.push([
          translate.monitoring.tabs.operation['label-minimum-disk-rotation'],
          String(step.disk_min_rotation) + ' RPM',
        ]);
        fertilizingData.push([
          translate.monitoring.tabs.operation['label-maximum-disk-rotation'],
          String(step.disk_max_rotation) + ' RPM',
        ]);
      }
      fertilizingData.push([
        translate.monitoring.tabs.operation['label-ppr-belt-encoder'],
        String(step.each_motor_encoder_pulses_per_rotation),
      ]);
      fertilizingData.push([
        translate.monitoring.tabs.operation['label-ppr-disk-encoder'],
        String(step.disk_encoder_pulses_per_rotation),
      ]);
    }

    if (step.accura_gate_calibration_AD_position_0_l) {
      fertilizingData.push(
        ...this._generateFertilizingCalibrationData(translate, step),
      );
    }

    if (step.scale_type) {
      fertilizingData.push([
        translate.monitoring.tabs.operation['label-scale-type'],
        translate.monitoring.tabs.operation[step.scale_type],
      ]);
      if (step.scale_type !== 'NONE') {
        if (step.scale_usage_flag) {
          fertilizingData.push([
            translate.monitoring.tabs.operation['label-scale-usage'],
            translate.monitoring.tabs.operation['label-true'],
          ]);
        } else {
          fertilizingData.push([
            translate.monitoring.tabs.operation['label-scale-usage'],
            translate.monitoring.tabs.operation['label-false'],
          ]);
        }
        fertilizingData.push([
          translate.monitoring.tabs.operation['label-scale-correction-factor'],
          step.scale_correction_factor,
        ]);
      }
    }

    return fertilizingData;
  }

  _generateFertilizingCalibrationData(translate, step) {
    const calibrationData = [];
    const finalStringValueMaxByMachineType = {
      [TYPE_MACHINE.GRAVIDADE]:
        ' ' + translate.monitoring.tabs.operation['label-maximum'] + ':',
    };
    const finalStringValueMinByMachineType = {
      [TYPE_MACHINE.GRAVIDADE]:
        ' ' + translate.monitoring.tabs.operation['label-minimum'] + ':',
    };

    const finalStringMax =
      finalStringValueMaxByMachineType[step.type_machine] || ' 580:';
    const finalStringMin =
      finalStringValueMinByMachineType[step.type_machine] || ' 0:';

    calibrationData.push([
      translate.monitoring.tabs.operation['label-gate-calibration-l'] +
        finalStringMin,
      step.accura_gate_calibration_AD_position_0_l,
    ]);
    calibrationData.push([
      translate.monitoring.tabs.operation['label-gate-calibration-l'] +
        finalStringMax,
      step.accura_gate_calibration_AD_position_580_l,
    ]);
    calibrationData.push([
      translate.monitoring.tabs.operation['label-gate-calibration-r'] +
        finalStringMin,
      step.accura_gate_calibration_AD_position_0_r,
    ]);
    calibrationData.push([
      translate.monitoring.tabs.operation['label-gate-calibration-r'] +
        finalStringMax,
      step.accura_gate_calibration_AD_position_580_r,
    ]);

    return calibrationData;
  }

  _generateSpecificSprayingData(translate, step) {
    let sprayData = [];

    if (step.flowmeter_constant > 0) {
      sprayData.push([
        translate.monitoring.tabs.operation['label-flowmeter-constant'],
        step.flowmeter_constant,
      ]);
      sprayData.push([
        translate.monitoring.tabs.operation['label-application-rate'] + ' 1',
        this.convertUnitPipe.transform(step.application_rate_1, 'l/ha'),
      ]);
      sprayData.push([
        translate.monitoring.tabs.operation['label-application-rate'] + ' 2',
        this.convertUnitPipe.transform(step.application_rate_2, 'l/ha'),
      ]);
    }

    if (step.nozzle_flow_rate) {
      sprayData.push([
        translate.monitoring.tabs.operation['label-nozzle-flow-rate'],
        this.convertUnitPipe.transform(step.nozzle_flow_rate, 'l/min'),
      ]);
    }
    if (step.nozzle_pressure) {
      sprayData.push([
        translate.monitoring.tabs.operation['label-nozzle-pressure'],
        this.convertUnitPipe.transform(step.nozzle_pressure, 'bar'),
      ]);
    }

    if (step.main_valve) {
      sprayData = sprayData.concat(
        this._generateValveData(
          translate,
          translate.monitoring.tabs.operation['label-main-valve'],
          step.main_valve,
        ),
      );
    }

    if (step.proportional_valve) {
      sprayData = sprayData.concat(
        this._generateValveData(
          translate,
          translate.monitoring.tabs.operation['label-proportional-valve'],
          step.proportional_valve,
        ),
      );
    }

    if (step.section_valve) {
      sprayData = sprayData.concat(
        this._generateValveData(
          translate,
          translate.monitoring.tabs.operation['label-section-valve'],
          step.section_valve,
        ),
      );
    }

    sprayData.push([
      translate.monitoring.tabs.operation['label-section-valve-opening-time'],
      step.section_valve_opening_time
        ? String(
            this.systemNumberFormatPipe.transform(
              step.section_valve_opening_time,
              2,
              this.convertUnitPipe.getCurrentUnitSystem(),
            ),
          ) + ' s'
        : null,
    ]);

    if (step.spraying_product) {
      sprayData.push([
        translate.monitoring.tabs.operation['label-spraying-product-number'],
        step.spraying_product.length,
      ]);
    }

    return sprayData;
  }

  _generateSpecificSeedingData(translate, step) {
    const seedingData = [];

    if (step.seed_population) {
      seedingData.push([
        translate.monitoring.tabs.operation['label-seed-population'],
        String(step.seed_population) + ' ' + translate.tasks.tabs['seeds'],
      ]);
    }

    if (step.seed_germ_rate) {
      seedingData.push([
        translate.monitoring.tabs.operation['label-seed-germination-rate'],
        String(step.seed_germ_rate) + ' %',
      ]);
    }

    if (step.seed_purity_rate) {
      seedingData.push([
        translate.monitoring.tabs.operation['label-seed-purity-rate'],
        String(step.seed_purity_rate) + ' %',
      ]);
    }

    if (step.stand) {
      seedingData.push([
        translate.monitoring.tabs.operation['label-seeding-stand'],
        String(step.stand) + ' ' + translate.tasks.tabs['seeds'] + '/ha',
      ]);
    }

    if (step.application_rate) {
      seedingData.push([
        translate.monitoring.tabs.operation['label-seeding-target-rate'],
        String(step.application_rate) +
          ' ' +
          translate.tasks.tabs['seeds'] +
          '/m',
      ]);
    }

    if (step.seed_spacing) {
      seedingData.push([
        translate.monitoring.tabs.operation['label-seed-spacing'],
        this.convertUnitPipe.transform(step.seed_spacing, 'cm'),
      ]);
    }

    if (step.seed_sensor_type) {
      seedingData.push([
        translate.monitoring.tabs.operation['label-seed-sensor-type'],
        this._generateSeedingSensorData(step.seed_sensor_type, translate),
      ]);
    }

    if (step.seed_count) {
      seedingData.push([
        translate.monitoring.tabs.operation['label-seed-count'],
        this._generateSeedingSeedCount(step.seed_count, translate),
      ]);
    }

    if (step.seed_size) {
      seedingData.push([
        translate.monitoring.tabs.operation['label-seed-size'],
        this._generateSeedingSeedSize(step.seed_size, translate),
      ]);
    }

    return seedingData;
  }

  _generateSeedingSensorData(sensor, translate) {
    const SEEDING_SENSOR_TYPE = ['pulse', 'can'];
    if (SEEDING_SENSOR_TYPE.indexOf(sensor) >= 0) {
      return translate.monitoring.tabs.operation['label-seed-sensor-' + sensor];
    }
    return translate.monitoring.tabs.operation['label-unknown'];
  }

  _generateSeedingSeedSize(seedSize, translate) {
    const SEEDING_SENSOR_TYPE = ['small', 'medium', 'large'];
    if (SEEDING_SENSOR_TYPE.indexOf(seedSize) >= 0) {
      return translate.monitoring.tabs.operation['label-seed-size-' + seedSize];
    }
    return translate.monitoring.tabs.operation['label-unknown'];
  }

  _generateSeedingSeedCount(seedCountMethod, translate) {
    const SEEDING_SENSOR_TYPE = ['seed_count', 'blockage'];
    if (SEEDING_SENSOR_TYPE.indexOf(seedCountMethod) >= 0) {
      return translate.monitoring.tabs.operation[
        'label-seed-count-' + seedCountMethod
      ];
    }
    return translate.monitoring.tabs.operation['label-unknown'];
  }

  generateReportMachineConfiguration(step) {
    const machineConfigurationObject = [];

    this.translateService
      .get([
        'devices',
        'tutorials',
        'global',
        'monitoring',
        'tasks',
        'altimetryData',
      ])
      .subscribe((translate: any) => {
        machineConfigurationObject.push([
          translate['devices']['tb-number-serie'],
          this.eventoNvg.serial_number,
        ]);
        machineConfigurationObject.push([
          translate.global['software_version'],
          step.app_version,
        ]);
        machineConfigurationObject.push([
          translate.monitoring.maps['title-operator'],
          step.operator,
        ]);
        machineConfigurationObject.push([
          translate['devices']['tb-machine'],
          this.eventoNvg.machine_name,
        ]);
        machineConfigurationObject.push([
          translate.monitoring.tabs.operation['label-implement'],
          this.convertUnitPipe.transform(step.impl_width, 'm'),
        ]);
        machineConfigurationObject.push([
          translate.monitoring.tabs.operation['label-implement-overlay'],
          step.impl_overlay !== null
            ? this.convertUnitPipe.transform(step.impl_overlay, 'm')
            : null,
        ]);

        if (step.impl_antenna_distance > 0) {
          machineConfigurationObject.push([
            translate.monitoring.tabs.operation[
              'label-implement-antenna-distance'
            ],
            this.convertUnitPipe.transform(step.impl_antenna_distance, 'm') +
              ' ' +
              translate.monitoring.tabs.operation['label-forward'],
          ]);
        } else {
          machineConfigurationObject.push([
            translate.monitoring.tabs.operation[
              'label-implement-antenna-distance'
            ],
            this.convertUnitPipe.transform(
              Math.abs(step.impl_antenna_distance),
              'm',
            ) +
              ' ' +
              translate.monitoring.tabs.operation['label-backward'],
          ]);
        }

        if (
          this.eventoNvg.operation === OPERACAO.PULVERIZACAO ||
          this.eventoNvg.operation === OPERACAO.FRUTICULTURA
        ) {
          machineConfigurationObject.push([
            translate.monitoring.tabs.operation['label-session'],
            step.sec_number,
          ]);
          const sizeEachStage = [];
          step.sec_width_meters
            .slice(0, step.sec_number)
            .forEach((stageSize) => {
              sizeEachStage.push(
                this.convertUnitPipe.transform(stageSize, 'm'),
              );
            });
          machineConfigurationObject.push([
            translate.monitoring.tabs.operation['label-size-each-stage'],
            sizeEachStage.join(' | '),
          ]);
          machineConfigurationObject.push([
            translate.monitoring.tabs.operation['label-number-beaks'],
            step.nozz_number,
          ]);
          machineConfigurationObject.push([
            translate.monitoring.tabs.operation['label-spacing-beaks'],
            step.nozz_spacing
              ? this.convertUnitPipe.transform(step.nozz_spacing, 'cm')
              : null,
          ]);
        } else if ((this.eventoNvg as any).operation === OPERACAO.ADUBACAO) {
          machineConfigurationObject.push([
            translate.monitoring.tabs.operation['label-type-machine'],
            translate.monitoring.tabs.operation[step.type_machine],
          ]);
          if (step.fertilizing_module_type) {
            if (step.fertilizing_module_type === 'UNKNOWN') {
              machineConfigurationObject.push([
                translate.monitoring.tabs.operation[
                  'label-fertilizing-module-type'
                ],
                translate.monitoring.tabs.operation['label-unknown'],
              ]);
            } else {
              machineConfigurationObject.push([
                translate.monitoring.tabs.operation[
                  'label-fertilizing-module-type'
                ],
                step.fertilizing_module_type,
              ]);
            }
          }

          if (step.accura_disk_type) {
            if (step.accura_disk_type === 'UNKNOWN') {
              machineConfigurationObject.push([
                translate.monitoring.tabs.operation['label-disk-type'],
                translate.monitoring.tabs.operation['label-unknown'],
              ]);
            } else {
              machineConfigurationObject.push([
                translate.monitoring.tabs.operation['label-disk-type'],
                step.accura_disk_type,
              ]);
            }
          }

          if (step.encoder_connector_type) {
            if (step.encoder_connector_type === 'UNKNOWN') {
              machineConfigurationObject.push([
                translate.monitoring.tabs.operation[
                  'label-encoder-connector-type'
                ],
                translate.monitoring.tabs.operation['label-unknown'],
              ]);
            } else {
              machineConfigurationObject.push([
                translate.monitoring.tabs.operation[
                  'label-encoder-connector-type'
                ],
                translate.monitoring.tabs.operation[
                  step.encoder_connector_type
                ],
              ]);
            }
          }
          if (step.conveyor_belt_min_rotation) {
            if (step.disk_control_flag) {
              machineConfigurationObject.push([
                translate.monitoring.tabs.operation['label-disk-control-flag'],
                translate.monitoring.tabs.operation['label-able'],
              ]);
            } else {
              machineConfigurationObject.push([
                translate.monitoring.tabs.operation['label-disk-control-flag'],
                translate.monitoring.tabs.operation['label-disabled'],
              ]);
            }
          }
        } else if ((this.eventoNvg as any).operation === OPERACAO.PLANTIO) {
          machineConfigurationObject.push([
            translate.tasks.tabs.stage['label-number-of-lines'],
            step.lines_number,
          ]);
          machineConfigurationObject.push([
            translate.tasks.tabs.stage['label-seeding-line-spacing'],
            step.line_spacing
              ? this.convertUnitPipe.transform(step.line_spacing, 'cm')
              : null,
          ]);
          machineConfigurationObject.push([
            translate.tasks.tabs.stage['label-number-of-ecus'],
            step.ecu_number,
          ]);

          machineConfigurationObject.push([
            translate.tasks.tabs['label-actuator'],
            step.hasActuator
              ? translate.tasks.tabs['label-present']
              : translate.tasks.tabs['label-not-present'],
          ]);

          machineConfigurationObject.push([
            translate.tasks.tabs['label-actuation-time'],
            step.actuationTime
              ? this.convertUnitPipe.transform(step.actuationTime, 's')
              : null,
          ]);

          machineConfigurationObject.push([
            translate.tasks.tabs['label-main-actuator'],
            step.hasMainActuator
              ? translate.tasks.tabs['label-present']
              : translate.tasks.tabs['label-not-present'],
          ]);
        }

        if (step.command_type) {
          const commandTypeSplitted = step.command_type.split('_');
          if (commandTypeSplitted[0] === 'hydraulic') {
            machineConfigurationObject.push([
              translate.monitoring.tabs.operation['label-pilot-command-type'],
              translate.monitoring.tabs.operation['label-hydraulic'] +
                ' ' +
                commandTypeSplitted[1] +
                ' cm3',
            ]);
          } else if (commandTypeSplitted[0] === 'electric') {
            machineConfigurationObject.push([
              translate.monitoring.tabs.operation['label-pilot-command-type'],
              translate.monitoring.tabs.operation['label-eletric-type'] +
                ' ' +
                commandTypeSplitted[1],
            ]);
          } else if (commandTypeSplitted[0] === 'agres') {
            machineConfigurationObject.push([
              translate.monitoring.tabs.operation['label-pilot-command-type'],
              translate.monitoring.tabs.operation['label-block-agres'],
            ]);
          } else {
            machineConfigurationObject.push([
              translate.monitoring.tabs.operation['label-pilot-command-type'],
              translate.monitoring.tabs.operation['label-unknown'],
            ]);
          }
          machineConfigurationObject.push([
            translate.monitoring.tabs.operation['label-aggressiveness'],
            step.agressiveness,
          ]);
          if (step.hidraulic_factor === 'unknown') {
            machineConfigurationObject.push([
              translate.monitoring.tabs.operation['label-hydraulic-factor'],
              translate.monitoring.tabs.operation['label-unknown'],
            ]);
          } else {
            machineConfigurationObject.push([
              translate.monitoring.tabs.operation['label-hydraulic-factor'],
              step.hidraulic_factor.split('_').pop(),
            ]);
          }

          machineConfigurationObject.push([
            translate.monitoring.tabs.operation['label-wheelbase'],
            String(step.wheelbase) + ' m',
          ]);
        }

        if (step.altimetry_op_mode) {
          machineConfigurationObject.push([
            translate.tasks.tabs.stage['label-altimetry-mode'],
            translate.altimetryData.altimetryMode[step.altimetry_op_mode],
          ]);
          machineConfigurationObject.push([
            translate.tasks.tabs.stage['label-altimetry-n-points'],
            step.altimetry_n_points,
          ]);
        }
      });
    const noNullFields = machineConfigurationObject.filter(this._isValidField);
    return noNullFields;
  }

  generateReportOperation(step) {
    let operationObject = [];
    this.translateService
      .get(['weather', 'monitoring', 'tasks', 'operation', 'devices', 'shared'])
      .subscribe((translate: any) => {
        let cover_area: string;
        if (this._errorOnFieldValue(step.cover_area)) {
          cover_area = translate.global['error'];
        } else {
          cover_area = step.cover_area
            ? this.convertUnitPipe.transform(step.cover_area.toFixed(2), 'ha')
            : null;
        }

        operationObject.push([
          translate['weather'].agriculture['title-form-culture'],
          step.crop_type,
        ]);
        operationObject.push([
          translate.monitoring.maps['title-operation'],
          translate.tasks.operation[this.eventoNvg.operation],
        ]);
        operationObject.push([
          translate.tasks.tabs.stage['label-start-date'],
          this.datePipe.transform(
            step.timestamp,
            'dd/MM/yyyy - HH:mm:ss',
            this.nvgStorageData.trabalho.timezone,
          ),
        ]);
        operationObject.push([
          translate.tasks.tabs.stage['label-end-date'],
          this.datePipe.transform(
            step.endTimestamp,
            'dd/MM/yyyy - HH:mm:ss',
            this.nvgStorageData.trabalho.timezone,
          ),
        ]);

        if (
          this.eventoNvg.operation === OPERACAO.PULVERIZACAO ||
          this.eventoNvg.operation === OPERACAO.FRUTICULTURA
        ) {
          if (this._errorOnFieldValue(step.app_vol)) {
            operationObject.push([
              translate.monitoring.maps['title-vol'],
              translate.global['error'],
            ]);
          } else {
            operationObject.push([
              translate.monitoring.maps['title-vol'],
              step.app_vol
                ? this.convertUnitPipe.transform(step.app_vol, 'l')
                : null,
            ]);
          }
        } else if (this.eventoNvg.operation === OPERACAO.ADUBACAO) {
          if (this._errorOnFieldValue(step.app_vol)) {
            operationObject.push([
              translate.monitoring.maps['title-mass'],
              translate.global['error'],
            ]);
          } else {
            operationObject.push([
              translate.monitoring.maps['title-mass'],
              step.app_vol
                ? this.convertUnitPipe.transform(step.app_vol, 'kg')
                : null,
            ]);
          }
        } else if (this.eventoNvg.operation === OPERACAO.PLANTIO) {
          if (this._errorOnFieldValue(step.app_vol)) {
            operationObject.push([
              translate.monitoring.maps['title-seeding'],
              translate.global['error'],
            ]);
          } else {
            operationObject.push([
              translate.monitoring.maps['title-seeding'],
              step.app_vol
                ? step.app_vol + ' ' + translate.tasks.tabs['seeds']
                : null,
            ]);
          }

          operationObject.push([
            translate.tasks.tabs['label-section-control-mode'],
            translate.tasks.tabs[`label-${step.ctrlMode}`],
          ]);

          operationObject.push([
            translate.tasks.tabs['label-section-control-type'],
            translate.tasks.tabs[`label-${step.ctrlType}`],
          ]);

          operationObject.push([
            translate.tasks.tabs['label-seeding-license-type'],
            translate.tasks.tabs[`label-seeding-${step.licenseType}`],
          ]);
        }

        operationObject.push([
          translate.tasks.tabs.stage['label-applied-area'],
          cover_area,
        ]);

        if (this._errorOnFieldValue(step.avg_rate)) {
          operationObject.push([
            translate.tasks.tabs.stage['label-applied-rate'],
            translate.global['error'],
          ]);
        } else {
          if (
            this.eventoNvg.operation === OPERACAO.PULVERIZACAO ||
            this.eventoNvg.operation === OPERACAO.FRUTICULTURA
          ) {
            if (step.avg_rate < 9999999) {
              operationObject.push([
                translate.tasks.tabs.stage['label-applied-rate'],
                step.avg_rate
                  ? this.convertUnitPipe.transform(
                      step.avg_rate.toFixed(2),
                      'l/ha',
                    )
                  : null,
              ]);
            } else {
              operationObject.push([
                translate.tasks.tabs.stage['label-applied-rate'],
                translate.monitoring.tabs.operation.inconsistencies,
              ]);
            }
          } else if (this.eventoNvg.operation === OPERACAO.ADUBACAO) {
            operationObject.push([
              translate.tasks.tabs.stage['label-applied-rate'],
              step.avg_rate
                ? this.convertUnitPipe.transform(
                    step.avg_rate.toFixed(2),
                    'kg/ha',
                  )
                : null,
            ]);
          } else if (this.eventoNvg.operation === OPERACAO.PLANTIO) {
            operationObject.push([
              translate.tasks.tabs.stage['label-planted-seeds-rate'],
              step.avg_rate
                ? step.avg_rate.toFixed(2) +
                  ' ' +
                  translate.tasks.tabs['seeds'] +
                  '/m'
                : null,
            ]);
          }
        }

        operationObject.push([
          translate.tasks.tabs['label-distance-calculated'],
          this.convertUnitPipe.transform(step.travel_dist.toFixed(2), 'km'),
        ]);
        operationObject.push([
          translate.tasks.tabs['label-average-speed'],
          this.convertUnitPipe.transform(step.avg_speed.toFixed(2), 'km/h'),
        ]);

        if (this.eventoNvg.operation === OPERACAO.PULVERIZACAO) {
          operationObject = operationObject.concat(
            this._generateSpecificSprayingData(translate, step),
          );
        } else if (this.eventoNvg.operation === OPERACAO.ADUBACAO) {
          operationObject = operationObject.concat(
            this._generateSpecificFertilizingData(translate, step),
          );
        } else if (this.eventoNvg.operation === OPERACAO.FRUTICULTURA) {
          operationObject = operationObject.concat(
            this._generateSpecificOrchardData(translate, step),
          );
        } else if (this.eventoNvg.operation === OPERACAO.PLANTIO) {
          operationObject = operationObject.concat(
            this._generateSpecificSeedingData(translate, step),
          );
        }

        if (step.applied_product_name) {
          operationObject.push([
            translate.tasks['applied-product-name'],
            step.applied_product_name,
          ]);
        }
      });
    const noNullFields = operationObject.filter((field) => field[1] !== null);
    return noNullFields;
  }

  _errorOnFieldValue(fieldValue: any) {
    return fieldValue && fieldValue === ERROR_ON_FIELD;
  }

  generateSprayingProducts(step) {
    const sprayingProductsObject = [];
    let sprayingProductsHead = [];
    this.translateService.get(['monitoring']).subscribe((translate: any) => {
      sprayingProductsHead = [
        [
          translate.monitoring.tabs.operation['label-product-name'],
          translate.monitoring.tabs.operation['label-minimum-dose'],
          translate.monitoring.tabs.operation['label-maximum-dose'],
          translate.monitoring.tabs.operation['label-used-dose'],
        ],
      ];

      step.spraying_product.forEach((product) => {
        sprayingProductsObject.push([
          product.name,
          String(product.min_dosage) + ' ' + product.measure_mode,
          String(product.max_dosage) + ' ' + product.measure_mode,
          String(product.current_dosage) + ' ' + product.measure_mode,
        ]);
      });
    });
    return [sprayingProductsObject, sprayingProductsHead];
  }

  generateWeatherObject(weatherData) {
    const weatherDataKeys = [
      AppConstants.GRAPH_OPTIONS.TEMPERATURE,
      AppConstants.GRAPH_OPTIONS.RELATIVE_HUMIDITY,
      AppConstants.GRAPH_OPTIONS.WIND_SPEED,
      AppConstants.GRAPH_OPTIONS.ROLLING_PRECIPITATION,
    ];

    const unit = ['C', '%', 'km/h', 'mm'];
    const separatorEmpty = '---';
    const weatherObject = [];

    let weatherHead = [];
    this.translateService
      .get('weatherReportPage.table')
      .subscribe((tableTranslate: any) => {
        weatherHead = [
          [
            tableTranslate.header.indicator,
            tableTranslate.header.max,
            tableTranslate.header.min,
            tableTranslate.header.average,
          ],
        ];

        weatherDataKeys.forEach((key, index) => {
          if (unit[index] === '%') {
            weatherObject.push([
              tableTranslate.indicators[key],
              weatherData[key].max + ' ' + unit[index],
              weatherData[key].min
                ? weatherData[key].min + ' ' + unit[index]
                : separatorEmpty,
              weatherData[key].avg
                ? weatherData[key].avg + ' ' + unit[index]
                : separatorEmpty,
            ]);
          } else {
            const unitConverted = this.convertUnitPipe.convertUnits(
              0,
              unit[index],
            ).unit;

            weatherObject.push([
              tableTranslate.indicators[key],
              weatherData[key].max + ' ' + unitConverted,
              weatherData[key].min
                ? weatherData[key].min + ' ' + unitConverted
                : separatorEmpty,
              weatherData[key].avg
                ? weatherData[key].avg + ' ' + unitConverted
                : separatorEmpty,
            ]);
          }
        });
      });
    return [weatherObject, weatherHead];
  }

  generateOrchardCoverBySection(step) {
    const orchardObject = [];
    const orchardObjectHead = [];

    this.translateService.get(['monitoring']).subscribe((translate: any) => {
      step.cover_by_sec.slice(0, step.sec_number).forEach((cover, index) => {
        orchardObjectHead.push(
          translate.monitoring.tabs.operation['label-section'] +
            ' ' +
            String(index + 1),
        );
        orchardObject.push(String(cover) + '%');
      });
    });

    return [[orchardObject], [orchardObjectHead]];
  }

  generateOrchardConfiguredNozzle(step) {
    const orchardNozzleObject = [];
    const orchardNozzleHead = [];

    this.translateService
      .get(['tutorials', 'monitoring'])
      .subscribe((translate: any) => {
        orchardNozzleHead.push([
          translate.tutorials['label-name'],
          this.convertUnitPipe.transform(
            translate.monitoring.tabs.operation['label-flow'],
            'l/min',
          ),
          translate.monitoring.tabs.operation['label-pressure'] + ' (Bar)',
          translate.monitoring.tabs.operation['label-amount-used-by-section'],
        ]);
        step.configured_nozzles.forEach((nozzle) => {
          orchardNozzleObject.push([
            nozzle.name,
            String(nozzle.rate),
            String(nozzle.pressure),
            nozzle.amount_used_section.slice(0, step.sec_number).join(' | '),
          ]);
        });
      });

    return [orchardNozzleObject, orchardNozzleHead];
  }

  generateOrchardUltrasonicSensor(step) {
    const ultrasonicSensorObject = [];
    const ultrasonicHead = [];

    this.translateService
      .get(['tutorials', 'monitoring', 'chamado'])
      .subscribe((translate: any) => {
        ultrasonicHead.push([
          translate.monitoring.tabs.operation['label-sensor'],
          translate.monitoring.tabs.operation['label-connection'],
          translate.monitoring.tabs.operation['label-assembly-mode'],
          translate.monitoring.tabs.operation['label-controled-sections'],
          translate.monitoring.tabs.operation['label-sensor-to-gps-distance'],
        ]);
        step.ultrasonic_sensors.forEach((sensor, index) => {
          if (sensor.assembly_mode) {
            let sensorDistance;
            if (sensor.sensor_distance_gps > 0) {
              sensorDistance =
                this.convertUnitPipe.transform(
                  sensor.sensor_distance_gps,
                  'm',
                ) +
                ' ' +
                translate.monitoring.tabs.operation['label-forward'];
            } else {
              sensorDistance =
                this.convertUnitPipe.transform(
                  Math.abs(sensor.sensor_distance_gps),
                  'm',
                ) +
                ' ' +
                translate.monitoring.tabs.operation['label-backward'];
            }
            ultrasonicSensorObject.push([
              index + 1,
              translate.monitoring.tabs.operation[sensor.connection],
              translate.monitoring.tabs.operation[sensor.assembly_mode],
              sensor.section_flags.join(', '),
              sensorDistance,
            ]);
          } else {
            ultrasonicSensorObject.push([
              index + 1,
              translate.monitoring.tabs.operation[sensor.connection],
              translate.monitoring.tabs.operation[sensor.connection],
              translate.monitoring.tabs.operation[sensor.connection],
              translate.monitoring.tabs.operation[sensor.connection],
            ]);
          }
        });
      });

    return [ultrasonicSensorObject, ultrasonicHead];
  }

  generateGuideinfo(guia) {
    const guideInfo = [];
    this.translateService
      .get(['guide', 'tasks'])
      .subscribe((translate: any) => {
        guideInfo.push([
          translate.tasks.guides['label-guide-type'],
          translate.tasks.guide.type[guia.type],
        ]);
        guideInfo.push([
          translate.tasks.guide['point-A'],
          this._coordinateToUniqueLatLngString(
            translate.tasks.guide['lat'],
            translate.tasks.guide['long'],
            guia.path[0],
          ),
        ]);
        guideInfo.push([
          translate.tasks.guide['point-B'],
          this._coordinateToUniqueLatLngString(
            translate.tasks.guide['lat'],
            translate.tasks.guide['long'],
            guia.path[guia.path.length - 1],
          ),
        ]);
        guideInfo.push([
          translate.tasks.guide['ab_distance'],
          this.convertUnitPipe.transform(guia.dist_A_B.toFixed(2), 'm'),
        ]);
      });
    return guideInfo;
  }

  _coordinateToUniqueLatLngString(latStr, lngStr, coordinate) {
    return `${latStr} ${coordinate.lat.toFixed(
      6,
    )}, ${lngStr} ${coordinate.long.toFixed(6)}`;
  }

  generateReportFirstPage() {
    const templateFirstPage = [];

    const operators = this.etapasArr.map((etapa) => {
      return etapa.operator;
    });

    const uniqueOperators = Array.from(new Set(operators)).join(', ');

    const travel_dist =
      this.eventoNvg.travel_dist !== null
        ? this.convertUnitPipe.transform(
            this.eventoNvg.travel_dist.toFixed(2),
            AppConstants.UNIT_SPEED_OPTIONS.KILOMETER,
          )
        : null;

    const avg_speed =
      this.eventoNvg.avg_speed !== null
        ? this.convertUnitPipe.transform(
            this.eventoNvg.avg_speed.toFixed(2),
            AppConstants.UNIT_SPEED_OPTIONS.KILOMETER_PER_HOUR,
          )
        : null;

    let coveredAreaWithoutOverlap;
    let overlapArea;
    let overlapPercentage;
    let overlapAppVol;
    if (this.eventoNvg.overlap_info) {
      coveredAreaWithoutOverlap =
        this.eventoNvg.overlap_info.cover_area_no_overlap !== undefined
          ? this.convertUnitPipe.transform(
              this.eventoNvg.overlap_info.cover_area_no_overlap,
              'ha',
            )
          : undefined;
      overlapArea =
        this.eventoNvg.overlap_info.overlap_area !== undefined
          ? this.convertUnitPipe.transform(
              this.eventoNvg.overlap_info.overlap_area,
              'ha',
            )
          : undefined;
      overlapPercentage =
        this.eventoNvg.overlap_info.overlap_percent !== undefined
          ? this.systemNumberFormatPipe.transform(
              this.eventoNvg.overlap_info.overlap_percent,
              2,
              this.convertUnitPipe.getCurrentUnitSystem(),
            ) + ' %'
          : undefined;
      overlapAppVol =
        this.eventoNvg.overlap_info.overlap_app_vol !== undefined
          ? this.systemNumberFormatPipe.transform(
              this.eventoNvg.overlap_info.overlap_app_vol,
              2,
              this.convertUnitPipe.getCurrentUnitSystem(),
            )
          : undefined;
    }

    this.translateService
      .get(['tasks', 'monitoring', 'operation', 'guide'])
      .subscribe((translate: any) => {
        templateFirstPage.push([
          translate.tasks.tabs['label-field'],
          this.eventoNvg.crop_name,
        ]);
        templateFirstPage.push([
          translate.tasks['created-on'],
          this.datePipe.transform(
            this.eventoNvg.tal_timestamp,
            'dd/MM/yyyy - HH:mm:ss',
            this.nvgStorageData.trabalho.timezone,
          ),
        ]);
        templateFirstPage.push([
          translate.tasks.tabs['title-event'],
          this.eventoNvg.evt_name,
        ]);
        templateFirstPage.push([
          translate.tasks.tabs.stage['label-start-date'],
          this.datePipe.transform(
            this.eventoNvg.timestamp,
            'dd/MM/yyyy - HH:mm:ss',
            this.nvgStorageData.trabalho.timezone,
          ),
        ]);
        templateFirstPage.push([
          translate.tasks.tabs.stage['label-end-date'],
          this.datePipe.transform(
            this.eventoNvg.endTimestamp,
            'dd/MM/yyyy - HH:mm:ss',
            this.nvgStorageData.trabalho.timezone,
          ),
        ]);
        templateFirstPage.push([
          translate.monitoring.maps['title-operation'],
          translate.operation[(this.eventoNvg as any).operation],
        ]);

        switch (this.eventoNvg.operation) {
          case OPERACAO.ADUBACAO:
            templateFirstPage.push([
              translate.monitoring.maps['title-mass'],
              this.eventoNvg.app_vol
                ? this.convertUnitPipe.transform(this.eventoNvg.app_vol, 'kg')
                : null,
            ]);
            templateFirstPage.push([
              translate.tasks.tabs['label-applied-volume-overlap'],
              overlapAppVol !== undefined
                ? this.convertUnitPipe.transform(overlapAppVol, 'kg')
                : undefined,
            ]);
            templateFirstPage.push([
              translate.monitoring.tabs.operation['label-avr-app-vol'],
              this.eventoNvg.avr_app_vol
                ? this.convertUnitPipe.transform(
                    this.eventoNvg.avr_app_vol.toFixed(2),
                    'kg/ha',
                  )
                : null,
            ]);
            break;
          case OPERACAO.PLANTIO:
            templateFirstPage.push([
              translate.monitoring.maps['title-seeding'],
              this.eventoNvg.app_vol
                ? this.eventoNvg.app_vol + ' ' + translate.tasks.tabs['seeds']
                : null,
            ]);
            templateFirstPage.push([
              translate.tasks.tabs['label-applied-volume-overlap'],
              overlapAppVol !== undefined
                ? overlapAppVol + ' ' + translate.tasks.tabs['seeds']
                : undefined,
            ]);
            templateFirstPage.push([
              translate.tasks.tabs.stage['label-planted-seeds-rate'],
              this.eventoNvg.avr_app_vol
                ? this.eventoNvg.avr_app_vol.toFixed(2) +
                  ' ' +
                  translate.tasks.tabs['seeds'] +
                  '/m'
                : null,
            ]);
            break;
          default:
            templateFirstPage.push([
              translate.monitoring.maps['title-vol'],
              this.eventoNvg.app_vol
                ? this.convertUnitPipe.transform(this.eventoNvg.app_vol, 'l')
                : null,
            ]);
            templateFirstPage.push([
              translate.tasks.tabs['label-applied-volume-overlap'],
              overlapAppVol !== undefined
                ? this.convertUnitPipe.transform(
                    overlapAppVol.replace(',', '.'),
                    'l',
                  )
                : undefined,
            ]);
            templateFirstPage.push([
              translate.monitoring.tabs.operation['label-avr-app-vol'],
              this.eventoNvg.avr_app_vol
                ? this.convertUnitPipe.transform(
                    this.eventoNvg.avr_app_vol.toFixed(2),
                    'l/ha',
                  )
                : null,
            ]);
            break;
        }

        templateFirstPage.push([
          translate.tasks.tabs.stage['label-total-applied-area'],
          this.convertUnitPipe.transform(
            this.eventoNvg.cover_area.toFixed(2),
            'ha',
          ),
        ]);
        templateFirstPage.push([
          translate.tasks.tabs.stage['label-area-without-transgression'],
          coveredAreaWithoutOverlap,
        ]);
        templateFirstPage.push([
          translate.tasks.tabs.stage['label-transgression-area'],
          overlapArea,
        ]);
        templateFirstPage.push([
          translate.monitoring.tabs.operation['label-distance'],
          travel_dist,
        ]);
        templateFirstPage.push([
          translate.tasks.tabs['label-average-speed'],
          avg_speed,
        ]);

        if (this.eventoNvg.tot_op_t) {
          templateFirstPage.push([
            translate.tasks.tabs.stage['label-working-time'],
            this.eventoNvg.tot_op_t,
          ]);
          templateFirstPage.push([
            translate.tasks.tabs.stage['label-working-time-effective'],
            this.eventoNvg.tot_op_e +
              ' - ' +
              this.systemNumberFormatPipe.transform(
                this.eventoNvg.tot_op_e_percent,
                2,
                this.convertUnitPipe.getCurrentUnitSystem(),
              ) +
              '% ' +
              translate.tasks.tabs.stage['label-percentage-time'],
          ]);
          templateFirstPage.push([
            translate.tasks.tabs.stage['label-working-time-idle'],
            this.eventoNvg.tot_op_o +
              ' - ' +
              this.systemNumberFormatPipe.transform(
                this.eventoNvg.tot_op_o_percent,
                2,
                this.convertUnitPipe.getCurrentUnitSystem(),
              ) +
              '% ' +
              translate.tasks.tabs.stage['label-percentage-time'],
          ]);

          const isMetricUnitSystem =
            AppConstants.UNIT_SYSTEM_OPTIONS.METRIC ===
            this.convertUnitPipe.getCurrentUnitSystem();
          const areaPerTimeTranslationKeyPrefix = isMetricUnitSystem
            ? 'label-hectares'
            : 'label-acres';

          templateFirstPage.push([
            translate.tasks.tabs.stage[
              `${areaPerTimeTranslationKeyPrefix}-hour`
            ],
            this.convertUnitPipe.transform(
              this.eventoNvg.hectarePerHourTotal,
              'ha',
            ) + '/h',
          ]);
          templateFirstPage.push([
            translate.tasks.tabs.stage[
              `${areaPerTimeTranslationKeyPrefix}-work-hour`
            ],
            this.convertUnitPipe.transform(
              this.eventoNvg.hectarePerHourEfective,
              'ha',
            ) + '/h',
          ]);
        }
        templateFirstPage.push([
          translate.tasks.tabs['label-transgression'],
          overlapPercentage,
        ]);
        templateFirstPage.push([
          translate.tasks.tabs.stage['label-number-stage'],
          this.etapasArr.length,
        ]);
        templateFirstPage.push([
          translate.monitoring.maps['title-operators'],
          uniqueOperators,
        ]);
        templateFirstPage.push([
          translate.tasks.guide['guides-marked'],
          this._generateFistPageGuideInfo(this.nvgStorageData.guias),
        ]);
      });
    const noNullFields = templateFirstPage.filter(this._isValidField);
    return noNullFields;
  }

  _isValidField(field) {
    return field[1];
  }

  _generateFistPageGuideInfo(guides: Array<any>) {
    let text = '';
    this.translateService.get(['guide']).subscribe((translate: any) => {
      const projectAndCustomGuides = guides.filter(
        (guia) =>
          guia.type === this.guideType.CUSTOM ||
          guia.type === this.guideType.PROJECT,
      );

      if (guides.length > 0) {
        if (projectAndCustomGuides.length > 0) {
          text +=
            translate.tasks.guide.type['project'] +
            '/' +
            translate.tasks.guide.type['custom'];
          if (
            hasNormalGuideAndCustomOrProject(guides, projectAndCustomGuides)
          ) {
            text += ' + ';
            text += guides.length - projectAndCustomGuides.length;
          }
        } else {
          text += guides.length;
        }
      } else {
        text += translate.tasks.guide['no-guide'];
      }
    });
    return text;

    function hasNormalGuideAndCustomOrProject(
      normalGuides,
      projectAndCustomGuides,
    ) {
      return (
        projectAndCustomGuides.length > 0 &&
        projectAndCustomGuides.length < normalGuides.length
      );
    }
  }
}
