import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Task } from 'app/core/interface/entity';
import { TarefasTalhaoFieldService } from 'app/core/services/operation-field.service';
import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';
import { LoadingSpinnerService } from 'app/core/services/loading-spinner.service';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

@Component({
    selector: 'app-detalhes-da-tarefa-talhoes',
    templateUrl: './detalhes-da-tarefa-talhoes.component.html',
    styleUrls: ['./detalhes-da-tarefa-talhoes.component.scss'],
})
export class DetalhesDaTarefasTalhoesComponent implements OnInit {
    talhaoById: Task[] = [];
    fileName = `${new Date().toISOString()}_detalhe-da-operacao.xlsx`;

    operationFieldId: string | null;

    fieldName: string;

    @ViewChild('content', { static: false }) el!: ElementRef;
    @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

    operation: any;
    public namesFileExported: { [key: string]: { [key: string]: string } };

    constructor(
        public translate: TranslateService,
        private route: ActivatedRoute,
        public userTalhoesService: UserTalhaoListService,
        private operationService: TarefasTalhaoFieldService,
        public loadingSpinner: LoadingSpinnerService
    ) {
        this.namesFileExported = {
            exportPdf: {
                required: '',
            },
        };
    }

    ngOnInit(): void {
        this.operationFieldId =
            this.route.snapshot.paramMap.get('operationFieldId');
        this.operationService
            .getTarefasTalhaoById(this.operationFieldId)
            .subscribe((operation) => {
                this.operation = operation[0];

                this.userTalhoesService
                    .getTalhaoId(operation[0].fieldId)
                    .subscribe((dataTalhao) => {
            this.fieldName = dataTalhao.name;
                    });
            });
    }

    generateToPdf() {
        this.loadingSpinner.show(
            'global.loading.operation'
        );

        this.translate
            .get('tasks.all-operation-of')
            .subscribe((res: string) => {
                this.namesFileExported.exportPdf.required = res;
            });

        const DATA = document.getElementById('bodyReport');
        const doc = new jsPDF('p', 'pt', 'a4');
        const options = {
            background: 'white',
            scale: 3,
        };
        html2canvas(DATA, options)
            .then((canvas) => {
                const img = canvas.toDataURL('image/PNG');

                // Add image Canvas to PDF
                const bufferX = 15;
                const bufferY = 15;
                const imgProps = (doc as any).getImageProperties(img);
                const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                doc.addImage(
                    img,
                    'PNG',
                    bufferX,
                    bufferY,
                    pdfWidth,
                    pdfHeight,
                    undefined,
                    'FAST'
                );
                return doc;
            })
            .then((docResult) => {
                this.loadingSpinner.hide();
                docResult.save(
                    `${this.namesFileExported.exportPdf.required }-${this.fieldName}`
                );
            });
    }

    backField(): void {
        history.go(-1);
    }
}
