import { ConfirmacaoModalModule } from './../confirmacao-modal/confirmacao-modal..module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { StarterViewComponent } from './starterview.component';

import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AppSharedModule } from 'app/app-shared.module';
import { GerenciamentoDeTalhoesModule } from './my-fields/gerenciamento-de-talhoes/gerenciamento-de-talhoes.module';
import { TranslateModule } from '@ngx-translate/core';
import { UploadFieldFilesComponent } from 'app/modules/appviews/my-fields/visualizar-talhoes/upload-field-files/upload-field-files.component';
import { SendFieldFilesComponent } from './my-fields/visualizar-talhoes/send-field-files/send-field-files.component';
import { SidebarMenuComponent } from 'app/shared/components/sidebar-menu/sidebar-menu.component';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SidebarMenuFieldFilesComponent } from './my-fields/visualizar-talhoes/sidebar-menu-field-files/sidebar-menu-field-files.component';
@NgModule({
  declarations: [
    StarterViewComponent,
    UploadFieldFilesComponent,
    SendFieldFilesComponent,
    SidebarMenuFieldFilesComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    MatAutocompleteModule,
    AppSharedModule,
    GerenciamentoDeTalhoesModule,
    ConfirmacaoModalModule,
    TranslateModule,
    FormsModule,
    MatSlideToggleModule,
  ],

  exports: [
    StarterViewComponent,
    UploadFieldFilesComponent,
    SendFieldFilesComponent,
    SidebarMenuFieldFilesComponent,
  ],
})
export class AppviewsModule {}
