import { Pipe, PipeTransform, Injectable } from '@angular/core';

type KeyValueWithShow = [string, any, boolean];
type KeyValue = [string, any];

type Data = Array<KeyValueWithShow | KeyValue>;

@Pipe({
  name: 'filterDataBoxArray',
})
@Injectable()
export class FilterDataBoxArray implements PipeTransform {
  transform(
    data: Data,
    showNullishValues = false,
    showEmptyStrings = true,
    showInvalidNumbers = false,
  ): Array<KeyValue> {
    function verifyInvalidNumber(value: any): boolean {
      return (
        typeof value === 'number' && (showInvalidNumbers || isFinite(value))
      );
    }

    function verifyEmptyString(value: any): boolean {
      return (
        typeof value === 'string' &&
        (showEmptyStrings || value.trim().length > 0)
      );
    }

    function verifyNullish(value: any): boolean {
      return showNullishValues || (value !== undefined && value !== null);
    }
    function keepDataItem(value) {
      return (
        verifyNullish(value) ||
        verifyEmptyString(value) ||
        verifyInvalidNumber(value)
      );
    }
    return data.filter((item) => {
      if (typeof item[2] === 'boolean') {
        const showKeyValue = item.pop();
        
        return showKeyValue;
        
      } else {
        return keepDataItem(item[1]);
      }
    }) as Array<KeyValue>;
  }
}
