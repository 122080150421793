<h1 class="title text-center m-5">RESUMOS DOS TRABALHOS</h1>
<table class="table table-striped table-bordered">

	<tr *ngFor="let item of groupData">
		<td>
      <div *ngFor="let data of item.data">
        <span>{{data.case_worked}}</span>
        </div>
    </td>
    <td>
      <div *ngFor="let data of item.data">
        <span>{{data.note}}</span>
        </div>
    </td>
		<td>{{item.id}}</td>
	</tr>
</table>

<p>Clique no botão para fazer o download!</p>

<button (click)="exportAsXLSX()"><i class="far fa-file-excel" style="font-size:48px;color:rgb(39, 39, 133)"></i></button>
