import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http';
import { ServiceBase } from './service.base';
import { INTERCEPTInternalServerError } from '../interceptors/contexts';

interface ImageOptions {
  inst_rate?: 0 | 1;
  overlap?: 0 | 1;
  speed?: 0 | 1;
  altitude?: 0 | 1;
  guides?: 0 | 1;
  [key: string]: 0 | 1;
}

export type ReportImages = {
  backgroundImages: Record<string, string>;
  rasterImages: Record<string, string>;
};
@Injectable()
export class ReportService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  getReportImages(
    id: string,
    options: ImageOptions = {
      inst_rate: 1,
      overlap: 1,
      speed: 1,
      altitude: 1,
      guides: 1,
    },
  ): Observable<ReportImages> {
    return this.http
      .get(`${this.UrlServiceV1}report-generator-map-image`, {
        ...this.obterAuthHeader(),
        params: {
          ...options,
          id,
        },
      })
      .pipe(map(this.extractData<ReportImages>), catchError(this.serviceError));
  }

  getImageThumbnails(
    id: string,
    options: ImageOptions = { speed: 1 },
  ): Observable<ReportImages> {
    return this.http
      .get(`${this.UrlServiceV1}report-generator-map-image`, {
        ...this.obterAuthHeader(),
        params: { ...options, id },
      })
      .pipe(map(this.extractData<ReportImages>), catchError(this.serviceError));
  }

  getReportPdf<T extends string>(
    id: string,
    language: T,
    unitSystem: 'metric' | 'imperial',
  ): Observable<Blob> {
    return this.http
      .get(`${this.UrlServiceV1}task-report/${id}`, {
        ...this.obterAuthHeader(),
        params: { language, unitSystem },
        context: new HttpContext().set(INTERCEPTInternalServerError, false),
        responseType: 'blob',
      })
      .pipe(map(this.extractData<Blob>), catchError(this.serviceError));
  }
}
