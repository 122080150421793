<div class="row">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-content">
        <h2>
          <strong>{{ 'called.title' | translate }}</strong>
          <div class="btn-group pull-right" dropdown>
            <button
              id="button-basic"
              dropdownToggle
              type="button"
              class="btn btn-primary dropdown-toggle"
              aria-controls="dropdown-basic">
              {{ 'called.title' | translate }}
              <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem">
                <a class="dropdown-item" href="javascript:void(0)" (click)="showModal()">{{
                  'called.new-calls' | translate
                }}</a>
              </li>
              <li role="menuitem">
                <a class="dropdown-item" [routerLink]="['meus-chamados']">{{ 'called.my-calls' | translate }}</a>
              </li>
            </ul>
          </div>
        </h2>
        <div style="margin-bottom: 10px !important">
          <div class="search-form">
            <form>
              <div class="input-group" style="width: 100% !important">
                <input
                  name="search"
                  [(ngModel)]="search"
                  class="form-control form-control-lg"
                  style="border-radius: 0 !important" />
                <div class="input-group-btn">
                  <button class="btn btn-primary" type="submit" (click)="searchItens()">
                    {{ 'global.search' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="wrapper wrapper-content animated fadeInRight">
              <div class="faq-item" *ngFor="let item of questionsFilter">
                <div class="row" (click)="showResposta(item)">
                  <div class="col-md-7">
                    <a data-toggle="collapse" href="javascript:void(0)" class="faq-question">{{ item.pergunta }}</a>
                    <small
                      >Added by
                      <strong>Alex Smith</strong>
                      <i class="agres-time"></i> Today 2:40 pm - 24.06.2014</small
                    >
                  </div>
                  <div class="col-md-3">
                    <span class="small font-bold">Robert Nowak</span>
                    <div class="tag-list">
                      <span class="tag-item">General</span>
                      <span class="tag-item">License</span>
                    </div>
                  </div>
                  <div class="col-md-2 text-right">
                    <span class="small font-bold">Voting </span><br />
                    42
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div
                      id="faq1"
                      class="panel-collapse"
                      [ngClass]="{
                        collapse: item.isCollapsed
                      }">
                      <div class="faq-answer">
                        <p>
                          {{ item.resposta }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: modal ? 'block' : 'none' }">
  <div class="modal-dialog" role="document">
    <form class="modal-content" [formGroup]="registrarForm" novalidate (ngSubmit)="(undefined)">
      <div class="modal-header">
        <h3 class="modal-title">
          {{ 'faq.modal-novo-chamado.title' | translate }}
          <button type="button" class="close" (click)="hideModal()" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </h3>
      </div>
      <div class="form-inputs">
        <div class="form-group" [ngClass]="{ 'has-error': displayMessage.categoria }">
          <label class="form-label">{{ 'faq.modal-novo-chamado.label-categoria' | translate }}</label>
          <select class="form-control input-sm" formControlName="categoria">
            <option value="Técnico">
              {{ 'faq.modal-novo-chamado.select-categoria.item-tecnico' | translate }}
            </option>
            <option value="Operacional">
              {{ 'faq.modal-novo-chamado.select-categoria.item-operacional' | translate }}
            </option>
            <option value="Comercial">
              {{ 'faq.modal-novo-chamado.select-categoria.item-comercial' | translate }}
            </option>
          </select>
          <span class="text-danger" *ngIf="displayMessage.categoria">
            <p [innerHTML]="displayMessage.categoria"></p>
          </span>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': displayMessage.servico }">
          <label class="form-label">{{ 'faq.modal-novo-chamado.label-servicos' | translate }}</label>
          <select class="form-control input-sm" formControlName="servico">
            <option value="Web">Web</option>
            <option value="Modulo">
              {{ 'faq.modal-novo-chamado.select-servicos.item-modulo' | translate }}
            </option>
          </select>
          <span class="text-danger" *ngIf="displayMessage.servico">
            <p [innerHTML]="displayMessage.servico"></p>
          </span>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': displayMessage.file }">
          <label class="form-label">{{ 'faq.modal-novo-chamado.label-anexo' | translate }}</label>
          <input class="form-control" type="file" formControlName="file" rows="10" />
          <span class="text-danger" *ngIf="displayMessage.file">
            <p [innerHTML]="displayMessage.file"></p>
          </span>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': displayMessage.descricao }">
          <label class="form-label">{{ 'faq.modal-novo-chamado.label-descricao' | translate }}</label>
          <textarea class="form-control input-sm" formControlName="descricao" rows="10"></textarea>
          <span class="text-danger" *ngIf="displayMessage.descricao">
            <p [innerHTML]="displayMessage.descricao"></p>
          </span>
        </div>
      </div>
      <div class="form-controls">
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="disableButton || !registrarForm.valid"
          data-dismiss="modal">
          {{ 'global.save' | translate }}
        </button>
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="hideModal()">
          {{ 'global.cancel' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
