import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() placeholder: string;
  @Input() iconClass: string;
  @Input() inputText: string;
  @Input() labelText: string;
  @Input() value: string;
  @Input() name: string;
  @Input() type: string = 'text';
  @Input() disabled: string;

  ngOnInit() {
    null;
  }
}
