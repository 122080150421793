import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { Weather } from 'app/shared/models/weather';
import { FleetMonitoringService } from 'app/core/services/fleet-monitoring.service';
import { ToastrService } from 'ngx-toastr';
import { DynamicMessageOperationSidePanelModel } from '../operations/operation-side-panel/operation-side-panel.model';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent implements OnInit, OnDestroy {
  weather: Weather;
  tempF: number;
  public weatherInterval: NodeJS.Timeout;
  public deviceId: string;

  @Input() lat: number;
  @Input() long: number;
  @Input() operationSidePanel: DynamicMessageOperationSidePanelModel;

  constructor(
    public fleetMonitoringService: FleetMonitoringService,
    public translateService: TranslateService,
    public toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.deviceId = window.localStorage.getItem(
      AppConstants.KEYS_LOCAL_STORAGE.DEVICE_ID,
    );
    if (this.deviceId !== null) {
      this.waitForCoordinates().then(() => {
        this.weatherData();
        this.startWeatherInterval();
      });
    }
  }

  private waitForCoordinates(): Promise<void> {
    return new Promise((resolve) => {
      const checkCoordinatesInterval = setInterval(() => {
        if (this.lat !== null && this.long !== null) {
          clearInterval(checkCoordinatesInterval);
          resolve();
        }
      }, 1000);
    });
  }

  private startWeatherInterval() {
    this.weatherInterval = setInterval(() => {
      if (this.lat !== null && this.long !== null) {
        this.weatherData();
      }
    }, 900_000);
  }

  ngOnDestroy(): void {
    clearInterval(this.weatherInterval);
  }

  weatherData() {
    if (this.lat && this.long) {
      this.fleetMonitoringService.getWeather(this.lat, this.long).subscribe(
        (res: Weather) => {
          this.weather = res;
          this.tempF = (this.weather.Temperatura * 9) / 5 + 32;
        },
        () => {
          this.translateService
            .get('error.500.error-occurred-try-again')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        },
      );
    }
  }
}
