import { Component, Input } from '@angular/core';
import { NavigationTabModel } from './navigation-tab.model';

@Component({
    selector: 'app-navigation-tab',
    templateUrl: './navigation-tab.component.html',
    styleUrls: ['./navigation-tab.component.scss'],
})
export class NavigationTabComponent {
    distanceBaseRtk: string;
    navigation: NavigationTabModel;
    public diffAge: number;
    public distance: Array<number> = [];
    public elevation: Array<number> = [];

  @Input() set navigationTab(value: NavigationTabModel) {
        if (value) {
            this.navigation = value;
            this.setData();
        }
    }

    setData(): void {
        const R: number = 6371;
        const baseLat: number = this.navigation.baseLocationLatitude;
        const baseLng: number = this.navigation.baseLocationLongitude;
        const deviceLat: number = this.navigation.latitude;
        const deviceLng: number = this.navigation.longitude;
        const dLat: number = ((deviceLat - baseLat) * Math.PI) / 180;
        const dLng: number = ((deviceLng - baseLng) * Math.PI) / 180;
        const a: number =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((baseLat * Math.PI) / 180) *
                Math.cos((deviceLat * Math.PI) / 180) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2);
        const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d: number = R * c;

        if (d > 1) this.distanceBaseRtk = Math.round(d) + ' km';
        else if (d <= 1) this.distanceBaseRtk = Math.round(d * 1000) + ' m';

        this.diffAge = d * 1000;
    }
}
