import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {

  @Input() title: string;
  @Input() disabled: string;
  @Output() dropdownClick = new EventEmitter();

  dataList: Array<any> = [];
  schedule: { branch: '' };

  constructor() {
    this.dataList = [
      { code: 1, name: "Name Example 01" },
      { code: 2, name: "Name Example 02" },
      { code: 3, name: "Name Example 03" }
    ]
  }
}