/// <reference types="google.maps" />

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChildren,
} from '@angular/core';
import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';
import { fromEvent, merge, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Field } from '../../../../core/interface/entity';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormBuilder,
  FormControlName,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { GenericValidator } from 'app/utils/generic-form-validator';
import { PrevisaoTempoService } from 'app/core/services/previsao-tempo.service';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { AutomaticFarmApiService } from 'app/core/services/automatic-farm-api.service';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { FeatureCollection } from 'isoxml';
import { AnalyticsService } from 'app/core/services/analytics.service';
import { AppConstants } from 'app/app.constants';
import { Geometry } from '@turf/turf';
import { zip } from '@mapbox/shp-write';
import { UserFilesManager } from 'app/utils/user-files-manager';
import { FileManagerService } from 'app/core/services/file-manager.service';
import { unzipFiles } from 'app/utils/zip-manager';
import { normalizeString } from 'app/utils/string.utils';

@Component({
  selector: 'app-editar-talhoes',
  templateUrl: './editar-talhoes.component.html',
  styleUrls: ['./editar-talhoes.component.scss'],
})
export class EditarTalhoesComponent implements OnInit, AfterViewInit {
  offCanvasOne = new Subject<boolean>();
  talhaoById = [];
  talhaoName = '';
  talhaoDescription = '';
  cityName = '';

  loading: boolean = true;

  mapPolygonColorSubject: Subject<string> = new Subject();
  updateIsPolygonClosed: Subject<boolean> = new Subject();

  selectedColor = '#000';

  @Input() emitEventEditarTalhaoById;
  @Input() onEmitVoltarParaListaDeTalhoes;
  @Input() talhaoId: string | null;

  fetchFieldData: Subject<boolean> = new Subject();

  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];

  isPolygonClosed = false;
  isEditingPolygon = true;
  geometry: FeatureCollection<any> = {
    features: [
      {
        geometry: {
          coordinates: [[]],
          type: 'Polygon',
        },
        properties: {},
        type: 'Feature',
      },
    ],
    type: 'FeatureCollection',
  };

  polygons: google.maps.LatLngLiteral[][] = [];

  showPolygon = false;

  apiLoaded: Observable<boolean>;

  vertices: google.maps.LatLngLiteral[] = [];

  mapOptions: google.maps.MapOptions = { draggable: false };

  markerPositions: google.maps.LatLngLiteral[] = [];
  editFieldForm: UntypedFormGroup;
  public validationMessages: { [key: string]: { [key: string]: string } };
  public genericValidator: GenericValidator;
  public displayMessage: { [key: string]: string } = {};
  ndvi_date: string;
  ndvi: boolean;
  actionType: string;
  limitSizeMessageErrorMapNdvi: any;
  prescriptionMapQuantity: string[];
  userId: string;
  fieldId: any;

  private readonly cloudantManager: UserFilesManager;

  constructor(
    httpClient: HttpClient,
    public previsaoTempoService: PrevisaoTempoService,
    public router: Router,
    public userTalhoesService: UserTalhaoListService,
    public translateService: TranslateService,
    public alertService: AlertService,
    public formBuilder: UntypedFormBuilder,
    public loadingAgresSpinner: AgresSpinnerService,
    public automaticFarmSolutionApi: AutomaticFarmApiService,
    public readonly analyticsService: AnalyticsService,
    private readonly activeRoute: ActivatedRoute,
    public fileManagerService: FileManagerService,
  ) {
    this.cloudantManager = new UserFilesManager(fileManagerService, httpClient);
    const usuarioLogado = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    this.userId = usuarioLogado.id;
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Accessed the edit field',
        'Edit field',
        this.userId,
      );
    }
    this.validationMessages = {
      name: {
        required: '',
      },
      description: {},
    };
    this.populateValidateObj();
    this.genericValidator = new GenericValidator(this.validationMessages);
    this.apiLoaded = httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo',
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  ngOnInit(): void {
    this.editFieldForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      area: [''],
      description: [''],
    });
    this.activeRoute.params.subscribe((params) => {
      this.fieldId = params['fieldId'];

      if (this.fieldId) {
        this.userTalhoesService.getTalhaoId(this.fieldId).subscribe(
          (talhoes: Field) => {
            const fieldName = talhoes.name;
            const fieldDescription = talhoes.description;
            const fieldArea = (+talhoes.totalArea).toFixed(3);
            this.editFieldForm.controls.area.setValue(fieldArea);
            this.talhaoName = fieldName;
            this.editFieldForm.controls.name.setValue(fieldName);
            this.talhaoDescription = fieldDescription;
            this.editFieldForm.controls.description.setValue(fieldDescription);
            this.geometry = talhoes.geometry;
            this.selectedColor = talhoes.color;
            this.loading = false;
            this.ndvi = talhoes.ndvi;
            this.ndvi_date = talhoes.ndvi_date;
            this.prescriptionMapQuantity = talhoes.prescriptionMapQuantity;
            const [lng, lat] =
              this.geometry.features[0].geometry.coordinates[0][0];
            this.getCityName({ lng, lat });
          },
          (err) => {
            this.translateService
              .get('global.error')
              .subscribe((res: string) => {
                this.alertService.info(res);
              });
          },
        );
      }
    });
  }

  ngAfterViewInit() {
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'),
    );

    merge(...controlBlurs).subscribe((value) => {
      this.displayMessage = this.genericValidator.processMessages(
        this.editFieldForm,
      );
    });
  }

  populateValidateObj() {
    this.translateService
      .get('field-register.validate.name.required')
      .subscribe((res: string) => {
        this.validationMessages.name.required = res;
      });
  }

  openInfomationsOfField() {
    if (this.isPolygonClosed) {
      this.isEditingPolygon = !this.isEditingPolygon;
      this.offCanvasOne.next(true);
    }
  }

  callTogglePolygonDrawing() {
    this.updateIsPolygonClosed.next(false);
    this.isEditingPolygon = true;
    this.isPolygonClosed = false;
  }

  togglePolygonEditButtonTitle(): string {
    if (
      (this.geometry.features[0].geometry as Geometry).coordinates.length > 0
    ) {
      return this.translateService.instant('fields.title-button-draw-the-hole');
    }
    return this.translateService.instant('fields.title-button-draw-the-field');
  }

  hideOffCanvas(): void {
    this.offCanvasOne.next(false);
  }

  cancelEditPolygons(): void {
    this.polygons = [];
    this.markerPositions = [];
    window.location.reload();
  }

  clearPolygons() {
    (this.geometry.features[0].geometry as Geometry).coordinates = [];
    this.isEditingPolygon = true;
    this.isPolygonClosed = false;
    this.fetchFieldData.next(true);
    this.editFieldForm.controls.area.setValue(0);
    this.updateIsPolygonClosed.next(false);
  }

  salvePolygons() {
    this.userTalhoesService
      .putTalhao({
        data: {
          geometry: this.geometry,
          name: this.editFieldForm.controls.name.value,
          id: this.fieldId,
          color: this.selectedColor,
          description: this.editFieldForm.controls.description.value,
          ndvi: this.ndvi,
          ndvi_date: this.ndvi_date,
          city: this.cityName,
          prescriptionMapQuantity: this.prescriptionMapQuantity,
        },
      })

      .subscribe(
        (talhoes) => {
          if (talhoes) {
            this.createShapefile(talhoes.id);
          } else {
            this.translateService
              .get('global.alert.http-status-code-400')
              .subscribe((res: string) => {
                this.alertService.error(res, TimeoutEnum.Medium);
                this.router.navigate(['/my-fields/']);
              });
          }
        },
        () => {
          this.translateService
            .get('global.alert.http-status-code-400')
            .subscribe((res: string) => {
              this.alertService.error(res, TimeoutEnum.Medium);
              this.router.navigate(['/my-fields/']);
            });
        },
      );
  }

  editField() {
    this.offCanvasOne.next(false);
    if (this.ndvi) {
      this.setActionType();
      if (!this.userId.includes('@agres.com.br')) {
        this.analyticsService.trackEvent(
          'Field with NDVI map edited successfully',
          'Edit_Field_With_NDVI_Map',
          this.userId,
        );
      }
    } else {
      this.salvePolygons();
      if (this.userId.includes('@agres.com.br')) {
        this.analyticsService.trackEvent(
          'Edited field successfully',
          'Edit_Field_Not_NDVI_Map',
          this.userId,
        );
      }
    }
  }

  setActionType() {
    this.actionType = 'edit';
    this.postDataToGetWidgetMapNdvi(this.geometry);
  }

  postDataToGetWidgetMapNdvi(geometry: any) {
    const statusMessages = {
      400: 'global.validate.error-limit-size-map-ndvi',
      500: 'global.validate.error-map-ndvi-not-generated',
    };

    this.translateService
      .get('global.alert.ndvi-1')
      .subscribe((res: string) => {
        this.loadingAgresSpinner.toShow(res);
      });
    this.automaticFarmSolutionApi
      .getWidgetMapNdvi(geometry, this.talhaoId, this.actionType)
      .subscribe(
        (response) => {
          this.salvePolygons();
        },
        (error) => {
          this.limitSizeMessageErrorMapNdvi =
            statusMessages[error.response.status] || 'Erro desconhecido';
          this.translateService.get(this.limitSizeMessageErrorMapNdvi);
        },
      );
  }

  onUpdatePolygons() {
    return (geometry, polygonStatus: boolean, area?: number) => {
      if (!!area) {
        this.editFieldForm.controls.area.setValue(area.toFixed(3));
      }
      this.isPolygonClosed = polygonStatus;
      this.geometry = geometry;
    };
  }

  selectClick(event) {
    this.mapPolygonColorSubject.next(event.target.value);
    this.selectedColor = event.target.value;
  }

  selectCoresTalhao(event) {
    this.mapPolygonColorSubject.next(event.target.value);
    this.selectedColor = event.target.value;
  }

  getCityName(coordinates: { lat: number; lng: number }) {
    this.previsaoTempoService
      .getLocationName(coordinates.lat, coordinates.lng)
      .subscribe({
        next: (data) => {
          this.cityName = data;
        },
        error: () => {},
      });
  }
  async createShapefile(id: string) {
    const name = normalizeString(this.editFieldForm.controls.name.value);

    const shapefilesZip = await zip(this.geometry, {
      types: {
        polygon: name,
      },
      compression: 'STORE',
      outputType: 'blob',
    });

    const files = await unzipFiles(shapefilesZip);
    const fileClassification = {
      boundaries: files.map((file) => file.name),
      recommendation_files: [],
      guides_files: [],
    };
    this.cloudantManager
      .saveDataAndUploadUserFiles(
        this.userId,
        files,
        'fields',
        id,
        fileClassification,
      )
      .subscribe(() => {
        this.translateService
          .get('global.alert.http-status-code-200')
          .subscribe((res: string) => {
            this.alertService.info(res, TimeoutEnum.Medium);
          });
        this.router.navigate([`/my-fields/ver-talhao/${this.fieldId}`]);
      });
  }
}
