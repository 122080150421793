<form [formGroup]="registrarForm" novalidate (ngSubmit)="adicionarUsuario()">
  <div class="modal__body">
    <div class="row">
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label">{{ 'global.permission' | translate }}</label>
        <select class="input-field-select" [(ngModel)]="selectedRole" [ngModelOptions]="{ standalone: true }">
          <option value="{{ ROLE_ENUM.Admin }}">
            {{ 'global.profiles.admin' | translate }}
          </option>
          <option value="{{ ROLE_ENUM.TelemetryUser }}">
            {{ 'global.profiles.telemetry-user' | translate }}
          </option>
          <option value="{{ ROLE_ENUM.Standard }}">
            {{ 'global.profiles.user-default' | translate }}
          </option>
          <option value="{{ ROLE_ENUM.FreeTier }}">
            {{ 'global.profiles.user-freetier' | translate }}
          </option>
        </select>
      </div>
      <div class="modal__body--form col-md-6" [ngClass]="{ 'has-error': displayMessage.Nome }">
        <label class="modal__body--form---label">{{ 'global.name' | translate }}</label>
        <input type="text" class="input-field" formControlName="Nome" />
        <span class="text-danger" *ngIf="displayMessage.Nome">
          <p [innerHTML]="displayMessage.Nome"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label">{{ 'global.email' | translate }}</label>
        <input type="text" class="input-field" formControlName="email" />
        <span class="text-danger" *ngIf="displayMessage.email">
          <p [innerHTML]="displayMessage.email"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label">{{ 'global.phone' | translate }}</label>
        <input type="tel" class="input-field" formControlName="Telefone" [mask]="getTelefoneMask()" />
        <span class="text-danger" *ngIf="displayMessage.phoneNumber">
          <p [innerHTML]="displayMessage.phoneNumber"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-4">
        <label class="modal__body--form---label">{{ 'global.country' | translate }}</label>
        <input type="text" class="input-field" formControlName="Pais" />
        <span class="text-danger" *ngIf="displayMessage.country">
          <p [innerHTML]="displayMessage.country"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-4">
        <label class="modal__body--form---label">{{ 'global.city' | translate }}</label>
        <input type="text" class="input-field" formControlName="Cidade" />
        <span class="text-danger" *ngIf="displayMessage.city">
          <p [innerHTML]="displayMessage.city"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-4">
        <label class="modal__body--form---label">{{ 'global.state' | translate }}</label>
        <input type="text" class="input-field" formControlName="Estado" />
        <span class="text-danger" *ngIf="displayMessage.stateOrProvince">
          <p [innerHTML]="displayMessage.stateOrProvince"></p>
        </span>
      </div>
      <!-- Para esconder os campos preenchidos automaticamente pelo navegador-->
      <div class="modal__body--form visually-hidden">
        <input type="email" class="input-field" />
      </div>
      <div class="modal__body--form visually-hidden">
        <input type="password" class="input-field" />
      </div>
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label" style="text-align: left">
          {{ 'global.validate,password-minlength' | translate }}</label
        >
        <input type="password" class="input-field" formControlName="Senha" minlength="4" maxlength="16" />
        <span class="text-danger" *ngIf="displayMessage.Senha">
          <p [innerHTML]="displayMessage.Senha"></p>
        </span>
      </div>
      <div class="modal__body--form col-md-6">
        <label class="modal__body--form---label" style="text-align: left">
          {{ 'global.confirm-password' | translate }}</label
        >
        <input type="password" class="input-field" formControlName="SenhaConfirmacao" minlength="4" maxlength="16" />
        <span class="text-danger" *ngIf="displayMessage.SenhaConfirmacao">
          <p [innerHTML]="displayMessage.SenhaConfirmacao"></p>
        </span>
      </div>
    </div>
  </div>
  <div class="modal__linha"></div>
  <div class="modal__footer">
    <app-button
      type="submit"
      color="primary"
      icon="agres-close"
      title="{{ 'global.save' | translate }}"
      [disabled]="disableButton || !registrarForm.valid"
      data-bs-dismiss="modal">
    </app-button>
    <app-button
      type="button"
      color="danger"
      icon="agres-close"
      title="{{ 'global.cancel' | translate }}"
      data-bs-dismiss="modal"
      (btnClick)="hideModal()">
    </app-button>
  </div>
</form>
