<ng-container *ngIf="show">
  <div class="modal" [ngStyle]="{ display: 'block' }">
    <div
      [ngClass]="{
        modal__dialog: true,
        'modal__dialog--sm': size === 'sm',
        'modal__dialog--md': size === 'md',
        'modal__dialog--lg': size === 'lg',
        'modal__dialog--xl': size === 'xl',
        'modal__dialog--fullscreen': size === 'fullscreen'
      }">
      <div class="modal__content">
        <div class="modal__content--header">
          <p class="modal__content--header---title">{{ title | translate }}</p>
        </div>
        <ng-content> </ng-content>
        <div class="modal__content--footer">
          <app-button
            *ngIf="showButtonCloseModal"
            type="button"
            color="danger"
            icon="agres-close"
            data-bs-dismiss="modal"
            (click)="hideModal()"
            title="{{ 'global.cancel' | translate }}">
          </app-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
