// @ts-nocheck
export class MachineTabModel {
    constructor(
        public deviceId: string = 'Default Device Id',
        public rpm: number = 0,
        public waterTemperature: number = 0,
        public oilPressure: number = 0,
        public speed: number | string = 0,
        public batteryVoltage: number = 0,
        public fuelLevel: number = 0,
        public engineType: string = 'Default Engine Type',
        public diskRpm: number = 0
    ) {}
}
