import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'starter',
    templateUrl: 'starter.template.html',
})
export class StarterViewComponent implements OnDestroy, OnInit {
    public nav: any;

    public constructor() {
        this.nav = document.querySelector('nav.navbar');
    }

    public ngOnInit(): any {
        this.nav.className += ' white-bg';
    }

    public ngOnDestroy(): any {
        this.nav.classList.remove('white-bg');
    }
}
