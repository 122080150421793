import { ErrorHandler, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
  HttpStatusCode,
} from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  INTERCEPTConflict,
  INTERCEPTForbidden,
  INTERCEPTInternalServerError,
  INTERCEPTNotFound,
  INTERCEPTNotModified,
  INTERCEPTRequestTimeout,
  INTERCEPTServiceUnavailable,
  INTERCEPTUnauthorized,
} from './contexts';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    public toastr: ToastrService,
    public translate: TranslateService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let shouldIntercept = true;
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        let errMsg = '';
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case HttpStatusCode.InternalServerError:
              shouldIntercept = request.context.get(INTERCEPTInternalServerError);
              if (shouldIntercept) this.router.navigate(['/manutencao']);
              errMsg = `Error: ${err?.error?.message}`;
              break;

            case HttpStatusCode.Forbidden:
              shouldIntercept = request.context.get(INTERCEPTForbidden);
              if (shouldIntercept) errMsg = `Error: ${err?.error?.message}`;
              break;

            case HttpStatusCode.NotFound:
              shouldIntercept = request.context.get(INTERCEPTNotFound);
              if (shouldIntercept) errMsg = `Error: ${err?.error?.message}`;
              break;

            case HttpStatusCode.Unauthorized:
              shouldIntercept = request.context.get(INTERCEPTUnauthorized);
              if (shouldIntercept) {
                localStorage.removeItem('AppConstants.KEYS_LOCAL_STORAGE.ISO_ACCESS_TOKEN');
                localStorage.removeItem('AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO');
                this.router.navigate(['/login']);
              }
              errMsg = `Error: ${err?.error?.message}`;
              break;

            case HttpStatusCode.RequestTimeout:
              shouldIntercept = request.context.get(INTERCEPTRequestTimeout);
              if (shouldIntercept) errMsg = `Error: ${err?.error?.message}`;
              break;

            case HttpStatusCode.Conflict:
              shouldIntercept = request.context.get(INTERCEPTConflict);
              if (shouldIntercept) errMsg = `Error: ${err?.error?.message}`;
              break;

            case HttpStatusCode.ServiceUnavailable:
              shouldIntercept = request.context.get(INTERCEPTServiceUnavailable);
              if (shouldIntercept) this.router.navigate(['/manutencao']);
              errMsg = `Error: ${err?.error?.message}`;
              break;

            case HttpStatusCode.NotModified:
              shouldIntercept = request.context.get(INTERCEPTNotModified);
              if (shouldIntercept) {
                errMsg = `Error: ${err?.error?.message}`;
              }
              break;

            default:
              break;
          }
          if (errMsg !== '') {
            this.translate.get(errMsg).subscribe((translatedErrorMessage) => {
              if (errMsg !== translatedErrorMessage) {
                this.toastr.error(translatedErrorMessage);
              }
            });
          }
        }
        return observableThrowError(err);
      }),
    );
  }
}
