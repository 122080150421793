<div class="modal" id="myModal" tabindex="-1" role="dialog" [ngStyle]="{ display: modal ? 'block' : 'none' }">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" (click)="hideModal()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4>{{ title }}</h4>
      </div>
      <div class="form-inputs text-center">
        <div *ngIf="selectedGrapData">
          <div id="modal-graph">
            <canvas
              baseChart
              class="graph-class"
              [datasets]="selectedGrapData"
              [labels]="lineChartLabels"
              [options]="lineChartOptions"
              [legend]="false"
              [colors]="selectedGrapColor"
              [chartType]="lineChartType">
            </canvas>
          </div>
        </div>
        <div class="form-controls">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="hideModal()">
            {{ 'global.cancel' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
