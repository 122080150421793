import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from './service.base';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NvgListFilesService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  public dataSource = new BehaviorSubject<any>([]);
  currenteData = this.dataSource.asObservable();

  changeData(data: any[]) {
    this.dataSource.next(data);
  }

  checkFilesExists(params) {
    return this.http
      .get(this.UrlServiceV1 + 'task-list-files', {
        params,
        ...this.obterAuthHeader(),
      })
      .pipe(
        map(this.extractData<Record<string, any>[]>),
        catchError(this.serviceError),
      );
  }
}
