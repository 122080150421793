import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { UserService } from 'app/core/services/user.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { TranslateUtil } from 'app/utils/translate.utils';
import { CustomValidators } from 'ng2-validation';

@Component({
  selector: 'app-forgot',
  templateUrl: 'forgot.component.html',
  styleUrls: ['forgot.scss'],
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {
  @ViewChild('email') emailInput: ElementRef;
  sendSuccess = false;
  currentYear: number;
  disabeBtnForgot: boolean;
  registerForm: UntypedFormGroup;
  httpRequestInProgress = false;
  languageOptions: {
    PT_BR: string;
    EN: string;
    ES: string;
    IT: string;
  };
  translateUtils: TranslateUtil;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public userService: UserService,
    public translate: TranslateService,
    public alertService: AlertService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    this.translateUtils = new TranslateUtil();
    this.currentYear = new Date().getFullYear();
  }

  ngAfterViewInit() {
    this.emailInput?.nativeElement.addEventListener('blur', () => {
      this.validateEmailInput();
    });
  }

  ngOnInit() {
    this.languageOptions = AppConstants.LANGUAGES;

    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.email]],
    });
  }

  changeLanguage(language) {
    if (language === null || language === undefined) {
      language = AppConstants.LANGUAGES.PT_BR;
    }
    this.translate.use(language);
    window.localStorage.setItem(
      AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG,
      language,
    );
  }

  resetPassword() {
    if (this.validateEmailInput() && !this.httpRequestInProgress) {
      this.sendSuccess = true;
      this.disabeBtnForgot = false;

      let langSelected = window.localStorage.getItem(
        AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG,
      );

      if (!langSelected) {
        langSelected = AppConstants.LANGUAGES.PT_BR;
      }

      const email = this.registerForm.controls.email.value;

      this.httpRequestInProgress = true;
      this.userService.forgotPassword(email, langSelected).subscribe(
        () => {
          this.translate
            .get('forgot.subtitle-mail')
            .subscribe((res: string) => {
              this.alertService.success(res);
              this.sendSuccess = false;
              this.disabeBtnForgot = false;
              setTimeout(() => {
                this.router.navigate(['login']);
              }, TimeoutEnum.Long);
            });
        },
        () => {
          this.translate
            .get('global.validate.mail-required')
            .subscribe((res: string) => {
              this.alertService.error(res);
              this.sendSuccess = true;
              this.disabeBtnForgot = true;
            });
        },
      );
    }
  }

  backLogin() {
    this.alertService.clear();
    this.router.navigateByUrl('login');
  }

  validateEmailInput() {
    if (this.registerForm.controls.email.value === '') {
      this.translate
        .get('global.validate.mail-required')
        .subscribe((res: string) => {
          this.alertService.warning(res);
        });
      return false;
    }

    if (!this.registerForm.get('email').valid) {
      this.translate
        .get('global.validate.mail-error')
        .subscribe((res: string) => {
          this.alertService.warning(res);
        });
      return false;
    }

    return true;
  }
}
