import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  DeviceInfoModel,
  DeviceMessageModel,
  convertToRealtimeStatus,
} from 'app/modules/gestao-operacao/fleet-monitoring/device/device.model';
import { MachineTabModel } from 'app/modules/gestao-operacao/fleet-monitoring/machines/machine-tab/machine-tab.model';
import { NavigationTabModel } from 'app/modules/gestao-operacao/fleet-monitoring/navigations/navigation-tab/navigation-tab.model';
import { DeviceService } from '../device/device.service';
import {
  DynamicMessageOperationSidePanelModel,
  StaticMessageOperationSidePanelModel,
} from '../operations/operation-side-panel/operation-side-panel.model';
import {
  DynamicMessageOperationTabModel,
  StaticMessageOperationTabModel,
} from '../operations/operation-tab/operation-tab.model';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-realtime-device-monitoring',
  templateUrl: './realtime-device-monitoring.component.html',
  styleUrls: ['./realtime-device-monitoring.component.scss'],
})
export class RealtimeDeviceMonitoringComponent implements OnInit, OnDestroy {
  deviceInfo: DeviceInfoModel;
  deviceInfoArray: DeviceInfoModel[] = [];
  circles = [];
  oldMessages = [];
  currentTab = 'operation';
  operationSidePanelInfo: DynamicMessageOperationSidePanelModel;
  staticMessageOperationSidePanelInfo: StaticMessageOperationSidePanelModel;
  machineTabInfo: MachineTabModel;
  navigationTabInfo: NavigationTabModel;
  operationTabInfo: DynamicMessageOperationTabModel;
  staticMessageOperationTabInfo: StaticMessageOperationTabModel;
  private deviceInterval: NodeJS.Timeout;
  private enableProcessing = true;
  private getLastMessageOnly = false;
  lastmessage: DeviceMessageModel;
  lengthArrayOfMessages: number;
  timestamps = [];
  machineTabHidden = true;
  updateCircles: NodeJS.Timeout;

  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    public _location: Location,
    private readonly loadingAgresSpinner: AgresSpinnerService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.translateService.get('global.loading.map-wait').subscribe((res) => {
      this.loadingAgresSpinner.toShow(res);
    });
    this.route.params.subscribe((params) => {
      this.fetchRealtimeMsgsPeriodically(params.id);
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.deviceInterval);
  }

  fetchRealtimeMsgsPeriodically(deviceId: string): void {
    this.deviceInterval = setInterval(() => {
      this.deviceService.getDeviceRealtime(deviceId).subscribe(
        (deviceMessage: DeviceMessageModel) => {
          this.parseRealtimeMsg(deviceMessage);
        },
        (err: any) => {
          console.error(err);
        },
      );
    }, 3000);
  }

  parseRealtimeMsg(deviceMessages): Promise<void> {
    if (this.enableProcessing) {
      if (deviceMessages.length !== 0 && Array.isArray(deviceMessages)) {
        if (this.getLastMessageOnly) {
          this.loadingAgresSpinner.toHide();
          const deviceMessage = deviceMessages[deviceMessages.length - 1];
          const dinamycMessages = deviceMessages.filter((item) => 'd' in item);
          const messagesToGet = dinamycMessages.filter((item) => {
            if (!this.timestamps.includes(item.d.timestamp)) {
              return item;
            }
          });
          if (messagesToGet.length > 0) {
            if (
              this.lastmessage.d.operation.work_status !==
              messagesToGet[0].d.operation.work_status
            ) {
              window.location.reload();
            }
            if (this.lastmessage.d.timestamp !== messagesToGet[0].d.timestamp) {
              const device: DeviceMessageModel = messagesToGet[0];
              const operationType = device.d.operation.op_type;
              this.operationSidePanelInfo =
                new DynamicMessageOperationSidePanelModel(
                  operationType,
                  device.d.operation.app_vol,
                  device.d.navigation.speed,
                  device.d.operation.cover_area,
                  device.d.machine.rpm,
                );
              const activeTime: number = device.d.operation.tot_op_e;
              const totalTime: number = device.d.operation.tot_op_t;
              const sleepTime: number = totalTime - activeTime;
              const operationActiveTime = this.secondsToTime(activeTime);
              const operationTotalTime = this.secondsToTime(totalTime);
              const operationSleepTime = this.secondsToTime(sleepTime);
              const percentTime = (activeTime / totalTime) * 100;
              const operationTimePercent = Number(percentTime.toFixed(0));
              const numberKm = device.d.operation.travel_dist;
              const travelDistance = numberKm.toFixed(1);
              const sectionStatus = device.d.operation.sec_state;
              const numberOfSections = device.d.operation.sec_number;
              let sectionArr = [];

              if (Number(sectionStatus) === 0) {
                for (let i = 0; i < numberOfSections; i++) {
                  sectionArr.push('0');
                }
              } else {
                sectionArr = sectionStatus.split('');
              }

              this.operationTabInfo = new DynamicMessageOperationTabModel(
                device.d.operation.impl_width,
                device.d.operation.sec_number,
                operationSleepTime,
                operationActiveTime,
                operationTotalTime,
                travelDistance,
                device.d.operation.inst_rate,
                sectionArr,
                operationTimePercent,
                operationType,
              );
              this.machineTabInfo = new MachineTabModel(
                device.d.node_addr,
                device.d.machine.rpm,
                device.d.machine.engine_temp,
                device.d.machine.oil_press,
                device.d.navigation.speed,
                device.d.machine.bat_volt,
                device.d.machine.fuel_lvl,
                device.d.machine.engine_type,
              );
              if (device.d.machine.rpm !== 0) {
                this.machineTabHidden = false;
              }
              this.navigationTabInfo = new NavigationTabModel(
                device.d.navigation.location.latitude,
                device.d.navigation.location.longitude,
                device.d.navigation.location.elevation,
                device.d.navigation.used_sat,
                device.d.telemetry?.freq,
                device.d.base_location.latitude,
                device.d.base_location.longitude,
                device.d.navigation.pos_type,
              );
              const status = convertToRealtimeStatus(
                device.d.operation.work_status,
              );

              this.deviceInfo = new DeviceInfoModel(
                device.d.node_addr,
                device.d.gw_addr,
                status,
                device,
                true,
                false,
                sectionArr,
              );
              this.lastmessage = device;
              this.timestamps.push(device.d.timestamp);
            }
          }

          if ('e' in deviceMessage) {
            const device: DeviceMessageModel = deviceMessage;
            this.staticMessageOperationTabInfo =
              new StaticMessageOperationTabModel(
                device.e.crop_type,
                device.e.field_name,
                device.e.event_name,
                device.e.nozz_number,
                device.e.nozz_per_section,
                device.e.nozz_spacing,
              );
            this.staticMessageOperationSidePanelInfo =
              new StaticMessageOperationSidePanelModel(device.e.operator);
          }
          return;
        }
        for (const deviceMessage of deviceMessages) {
          if ('d' in deviceMessage) {
            this.lastmessage = deviceMessage;
            const device: DeviceMessageModel = deviceMessage;
            const operationType = device.d.operation.op_type;
            this.operationSidePanelInfo =
              new DynamicMessageOperationSidePanelModel(
                operationType,
                device.d.operation.app_vol,
                device.d.navigation.speed,
                device.d.operation.cover_area,
                device.d.machine.rpm,
              );
            const activeTime: number = device.d.operation.tot_op_e;
            const totalTime: number = device.d.operation.tot_op_t;
            const sleepTime: number = totalTime - activeTime;
            const operationActiveTime = this.secondsToTime(activeTime);
            const operationTotalTime = this.secondsToTime(totalTime);
            const operationSleepTime = this.secondsToTime(sleepTime);
            const percentTime = (activeTime / totalTime) * 100;
            const operationTimePercent = Number(percentTime.toFixed(0));
            const numberKm = device.d.operation.travel_dist;
            const travelDistance = numberKm.toFixed(1);
            const sectionStatus = device.d.operation.sec_state;
            const numberOfSections = device.d.operation.sec_number;
            let sectionArr = [];

            if (Number(sectionStatus) === 0) {
              for (let i = 0; i < numberOfSections; i++) {
                sectionArr.push('0');
              }
            } else {
              sectionArr = sectionStatus.split('');
            }

            this.operationTabInfo = new DynamicMessageOperationTabModel(
              device.d.operation.impl_width,
              device.d.operation.sec_number,
              operationSleepTime,
              operationActiveTime,
              operationTotalTime,
              travelDistance,
              device.d.operation.inst_rate,
              sectionArr,
              operationTimePercent,
              operationType,
            );
            this.machineTabInfo = new MachineTabModel(
              device.d.node_addr,
              device.d.machine.rpm,
              device.d.machine.engine_temp,
              device.d.machine.oil_press,
              device.d.navigation.speed,
              device.d.machine.bat_volt,
              device.d.machine.fuel_lvl,
              device.d.machine.engine_type,
            );
            this.navigationTabInfo = new NavigationTabModel(
              device.d.navigation.location.latitude,
              device.d.navigation.location.longitude,
              device.d.navigation.location.elevation,
              device.d.navigation.used_sat,
              device.d.telemetry?.freq,
              device.d.base_location.latitude,
              device.d.base_location.longitude,
              device.d.navigation.pos_type,
            );
            this.oldMessages.push({
              center: {
                lat: device.d.navigation.location.latitude,
                lng: device.d.navigation.location.longitude,
              },
              radius: device.d.operation.impl_width / 2,
              instRate: device.d.operation.inst_rate,
              sectionArr: sectionArr,
              timestamp: device.d.timestamp,
              selected: false,
              gwAddr: device.d.gw_addr,
              velocity: device.d.navigation.speed,
            });
            this.timestamps.push(device.d.timestamp);
          } else if ('e' in deviceMessage) {
            const device: DeviceMessageModel = deviceMessage;
            this.staticMessageOperationTabInfo =
              new StaticMessageOperationTabModel(
                device.e.crop_type,
                device.e.field_name,
                device.e.event_name,
                device.e.nozz_number,
                device.e.nozz_per_section,
                device.e.nozz_spacing,
              );
            this.staticMessageOperationSidePanelInfo =
              new StaticMessageOperationSidePanelModel(device.e.operator);
          }
        }
        this.circles = this.oldMessages;
        this.enableProcessing = false;
      }
    }
  }

  onProcessingComplete() {
    if (this.deviceInfoArray.length > 0) {
      this.deviceInfo = this.deviceInfoArray.shift();
    } else {
      this.deviceInfoArray = [];
      this.enableProcessing = true;
      this.getLastMessageOnly = true;
    }
  }

  secondsToTime(seconds: number): string {
    const totalSeconds: number = seconds;
    const hours: number = Math.floor(totalSeconds / 3600);
    const minutes: number = Math.floor((totalSeconds - hours * 3600) / 60);
    let result: string = String(hours < 10 ? '0' + hours : hours);
    result += ':' + (minutes < 10 ? '0' + minutes : minutes);
    return result;
  }

  dashboard() {
    this.router.navigate(['/']);
  }
  goBack() {
    this._location.back();
  }
}
