import { Pipe, PipeTransform } from '@angular/core';
import {
  OpType,
  OpTypeLegacy,
  OperationType,
} from 'app/modules/gestao-operacao/fleet-monitoring/enums/operation-type.enum';
import { match } from 'ts-pattern';

@Pipe({ name: 'operationType', pure: false })
export class OperationTypePipe implements PipeTransform {
  transform(opType: OperationType): string {
    return match(opType)
      .with(
        OpType.SPRAYING,
        OpTypeLegacy.SPRAYING,
        () => 'tasks.operation.spraying',
      )
      .with(
        OpType.FERTILIZATION,
        OpTypeLegacy.FERTILIZATION,
        () => 'tasks.operation.fertilization',
      )
      .with(
        OpType.HARVEST,
        OpTypeLegacy.HARVEST,
        () => 'tasks.operation.harvest',
      )
      .with(
        OpType.PLANTING,
        OpTypeLegacy.PLANTING,
        () => 'tasks.operation.planting',
      )
      .with(
        OpType.IRRIGATION,
        OpTypeLegacy.IRRIGATION,
        () => 'tasks.operation.irrigation',
      )
      .with(OpType.ISOBUS, OpTypeLegacy.ISOBUS, () => 'tasks.operation.isobus')
      .with(OpType.ORCHARDING, () => 'tasks.operation.fruticultura')
      .with(OpType.OTHER, OpTypeLegacy.OTHER, () => 'tasks.operation.others')
      .otherwise(() => 'No OperationType!');
  }
}
