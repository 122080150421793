<button
  [type]="type"
  [disabled]="disabled"
  (click)="btnClick.emit($event)"
  [ngClass]="{
    btn: true,
    btn__primary: color === 'primary',
    btn__success: color === 'success',
    btn__warning: color === 'warning',
    btn__danger: color === 'danger',
    btn__info: color === 'info',
    btn__card: color === 'card',
    btn__return: color === 'return',
    btn__full: size === 'full',
    btn__large: size === 'large',
    btn__medium: size === 'medium',
    btn__small: size === 'small',
    btn__icon: true
  }">
  {{ title }}
  <span [ngClass]="{ reload: action === 'reload' }">
    <i [ngClass]="icon"></i>
  </span>
</button>
