import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Realtime } from '../../shared/models/realtime';
import { Weather } from '../../shared/models/weather';
import { ServiceBase } from './service.base';

@Injectable()
export class FleetMonitoringService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  getAllDeviceRealtime() {
    return this.http
      .get(this.UrlServiceV1 + 'navigation', this.obterAuthHeader())
      .pipe(
        map(this.extractData<Record<string, any>[]>),
        catchError(this.serviceError),
      );
  }

  getDeviceRealtime(deviceId: string): Observable<Realtime> {
    return this.http
      .get<Realtime>(
        this.UrlServiceV1 + `navigation?` + `deviceId=${deviceId}`,
        this.obterAuthHeader(),
      )
      .pipe(catchError(this.serviceError));
  }

  getWeather(lat: number, lng: number): Observable<Weather> {
    return this.http
      .get<Weather>(
        this.UrlServiceV1 +
          `weather?` +
          `operation=nowcast&` +
          `lat=${lat}&` +
          `lng=${lng}`,
        this.obterAuthHeader(),
      )
      .pipe(catchError(this.serviceError));
  }
}
