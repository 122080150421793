import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from './service.base';
import { Device } from 'app/shared/models/device';

@Injectable()
export class DeviceService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  public dataSource = new BehaviorSubject<any>([]);
  currentData = this.dataSource.asObservable();

  changeData(data: any[]) {
    this.dataSource.next(data);
  }

  public deviceId: string;

  getAllDevices() {
    return this.http
      .get(this.UrlServiceV1 + 'device', this.obterAuthHeader())
      .pipe(map(this.extractData<Device[]>), catchError(this.serviceError));
  }

  getDevice() {
    return this.http
      .get(
        this.UrlServiceV1 + `device?deviceId=${this.deviceId}`,
        this.obterAuthHeader(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  postDevice(data) {
    return this.http
      .post(this.UrlServiceV1 + 'device', data, this.obterAuthHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  atualizarDevice(data) {
    return this.http
      .post(
        this.UrlServiceV1 + `device?deviceId=${this.deviceId}`,
        data,
        this.obterAuthHeader(),
      )
      .pipe(map(this.extractData<Device>), catchError(this.serviceError));
  }
}
