import { Component, Input, OnChanges } from '@angular/core';
import { OpType, OperationType } from '../enums/operation-type.enum';
import { SectionState } from '../enums/section-state.enum';
import {
  DynamicMessageOperationTabModel,
  StaticMessageOperationTabModel,
} from './operation-tab.model';

type CardList = Array<Card>;
type Card = Array<Line>;
type Line = {
  label: string;
  value: any;
};

@Component({
  selector: 'app-operation-tab',
  templateUrl: './operation-tab.component.html',
  styleUrls: ['./operation-tab.component.scss'],
})
export class OperationTabComponent implements OnChanges {
  @Input() operationTab: DynamicMessageOperationTabModel =
    new DynamicMessageOperationTabModel();
  @Input() staticMessageOperationTab: StaticMessageOperationTabModel =
    new StaticMessageOperationTabModel();
  operationType: OperationType;
  listOfCards?: CardList;
  instRateUnit = 'L/ha';
  imgSessionOpenPath = '../../../assets/images/default/aberto.png';
  imgSessionClosedPath = '../../../assets/images/default/fechado.png';
  statusSession: string[];
  lines?: Line[];

  operationLabels: Record<OpType, string> = {
    [OpType.SPRAYING]: 'monitoring.tabs.operation.label-spraying',
    [OpType.FERTILIZATION]: 'monitoring.tabs.operation.label-fertilization',
    [OpType.HARVEST]: 'monitoring.tabs.operation.label-harvest',
    [OpType.PLANTING]: 'monitoring.tabs.operation.label-planting',
    [OpType.IRRIGATION]: 'monitoring.tabs.operation.label-irrigation',
    [OpType.OTHER]: 'monitoring.tabs.operation.label-other',
    [OpType.ORCHARDING]: 'monitoring.tabs.operation.label-orcharding',
    [OpType.ISOBUS]: 'monitoring.tabs.operation.label-other',
    [OpType.NAVIGATION]: 'monitoring.tabs.operation.label-other',
  };

  constructor() {
    this.updateValues();
    this.updateListOfCards();
  }

  ngOnChanges() {
    this.updateListOfCards();
  }

  private updateValues() {
    const formatValue = (value: number | string | undefined): string =>
      value !== undefined
        ? typeof value === 'number'
          ? `${value.toFixed(1)}`
          : `${value}`
        : '-';

    if (this.operationTab?.operationType === OpType.ISOBUS) {
      this.lines = [
        {
          label: 'monitoring.tabs.operation.label-culture',
          value: formatValue(this.staticMessageOperationTab?.cropType),
        },
        {
          label: 'monitoring.tabs.operation.label-field',
          value: formatValue(this.staticMessageOperationTab?.cropName),
        },
        {
          label: 'monitoring.tabs.operation.label-event',
          value: formatValue(this.staticMessageOperationTab?.eventName),
        },
        {
          label: 'monitoring.tabs.operation.label-farm-name',
          value: formatValue(this.staticMessageOperationTab?.farmName),
        },
        {
          label: 'monitoring.tabs.operation.label-operator',
          value: formatValue(this.staticMessageOperationTab?.operatorName),
        },
        {
          label: 'monitoring.tabs.operation.label-machine-name',
          value: formatValue(this.staticMessageOperationTab?.machineName),
        },
        {
          label: 'monitoring.tabs.operation.label-sector-name',
          value: formatValue(this.staticMessageOperationTab?.sectorName),
        },
        {
          label: 'monitoring.tabs.operation.label-speed',
          value: this.operationTab?.speed.toFixed(0).toString() + ' km/h',
        },
        {
          label: 'monitoring.tabs.operation.label-distance',
          value: formatValue(this.operationTab?.travelDistance) + ' km',
        },
        {
          label: 'monitoring.tabs.operation.label-session',
          value: this.operationTab?.numberOfSections.toFixed(0).toString(),
        },
        {
          label: 'monitoring.tabs.operation.label-implement',
          value: formatValue(this.operationTab?.implementWidth) + ' m',
        },
        {
          label: 'monitoring.tabs.operation.label-tx-instantaneous',
          value:
            formatValue(this.operationTab?.instRate) +
            ' ' +
            (this.operationTab?.instRateUnit
              ? this.operationTab?.instRateUnit
              : this.instRateUnit),
        },
        {
          label: 'monitoring.tabs.operation.label-status-session',
          value: this.statusSession === undefined ? '-' : this.statusSession,
        },
      ];
    } else if (this.operationTab?.operationType === OpType.NAVIGATION) {
      this.lines = [
        {
          label: 'monitoring.tabs.operation.label-speed',
          value: this.operationTab?.speed.toFixed(0).toString() + ' km/h',
        },
        {
          label: 'monitoring.tabs.operation.label-implement',
          value: formatValue(this.operationTab?.implementWidth) + ' m',
        },
        {
          label: 'monitoring.tabs.operation.label-applied-area',
          value: formatValue(this.operationTab?.appliedArea) + ' ha',
        },
        {
          label: 'monitoring.tabs.operation.label-session',
          value: this.operationTab?.numberOfSections.toFixed(0).toString(),
        },
        {
          label: 'monitoring.tabs.operation.label-status-session',
          value: this.statusSession === undefined ? '-' : this.statusSession,
        },
      ];
    } else {
      this.lines = [
        {
          label: 'monitoring.tabs.operation.label-machine-name',
          value: formatValue(this.staticMessageOperationTab?.machineName),
        },
        {
          label: 'monitoring.tabs.operation.label-operator',
          value: formatValue(this.staticMessageOperationTab?.operatorName),
        },
        {
          label: 'monitoring.tabs.operation.label-culture',
          value: formatValue(this.staticMessageOperationTab?.cropType),
        },
        {
          label: 'monitoring.tabs.operation.label-field',
          value: formatValue(this.staticMessageOperationTab?.cropName),
        },
        {
          label: 'monitoring.tabs.operation.label-event',
          value: formatValue(this.staticMessageOperationTab?.eventName),
        },
        {
          label: 'monitoring.tabs.operation.label-distance',
          value: formatValue(this.operationTab?.travelDistance) + ' Km',
        },
        {
          label: 'monitoring.tabs.operation.label-speed',
          value:
            Number(this.operationTab?.speed).toFixed(0).toString() + ' km/h',
        },
        {
          label: 'monitoring.tabs.operation.title-graphs',
          value: formatValue(this.operationTab?.operationTimePercent) + '%',
        },
        {
          label: 'monitoring.tabs.operation.legend-effective',
          value: formatValue(this.operationTab?.operationActiveTime) + 'h',
        },
        {
          label: 'monitoring.tabs.operation.legend-total',
          value: formatValue(this.operationTab?.operationTotalTime) + 'h',
        },
        {
          label: 'monitoring.tabs.operation.label-implement',
          value: formatValue(this.operationTab?.implementWidth) + ' m',
        },
        {
          label: 'monitoring.tabs.operation.label-operation',
          value: this.operationLabels[this.operationTab.operationType],
        },
        {
          label: 'monitoring.tabs.operation.label-tx-instantaneous',
          value:
            formatValue(this.operationTab?.instRate) +
            ' ' +
            (this.operationTab?.instRateUnit
              ? this.operationTab?.instRateUnit
              : this.instRateUnit),
        },
        {
          label: 'monitoring.tabs.operation.label-session',
          value: this.operationTab?.numberOfSections.toFixed(0).toString(),
        },
        {
          label: 'monitoring.tabs.operation.label-status-session',
          value: this.statusSession === undefined ? '-' : this.statusSession,
        },
      ];
    }
  }

  formatUnits() {
    if (this.operationTab?.instRateUnit) {
      this.instRateUnit = ' ' + this.operationTab?.instRateUnit;
    } else {
      this.instRateUnit = (() => {
        switch (this.operationTab?.operationType) {
          case OpType.FERTILIZATION:
            return ' kg/ha';
          case OpType.SPRAYING:
          case OpType.IRRIGATION:
            return ' L/ha';
          case OpType.HARVEST:
          case OpType.PLANTING:
          case OpType.ORCHARDING:
          case OpType.NAVIGATION:
            return '';
          default:
            return '';
        }
      })();
    }

    const status: string[] = [];
    for (let i = 0; i < this.operationTab?.sectionArr.length; i++) {
      if (this.operationTab.sectionArr[i] === SectionState.OPEN) {
        status.push(this.imgSessionOpenPath);
      } else {
        status.push(this.imgSessionClosedPath);
      }
    }
    this.statusSession = [...status];
  }

  private updateListOfCards() {
    this.formatUnits();
    this.updateValues();
    const modifiedLines = this.modifyLinesPerOperation(this.lines);
    this.listOfCards = this.formatAsListOfCards(modifiedLines, 3);
  }

  removeSpecificLinesForOpTypeHarvest(
    lines: Array<{ label: string; value: any }>,
  ): Array<{ label: string; value: any }> {
    return lines.filter(
      (line) =>
        ![
          'monitoring.tabs.operation.label-tx-instantaneous',
          'monitoring.tabs.operation.label-session',
          'monitoring.tabs.operation.label-status-session',
          'monitoring.tabs.operation.label-implement',
          'monitoring.tabs.operation.legend-effective',
          'monitoring.tabs.operation.legend-total',
          'monitoring.tabs.operation.title-graphs',
        ].includes(line.label),
    );
  }

  removeSpecificLinesForOpTypePlanting(
    lines: Array<{ label: string; value: any }>,
  ): Array<{ label: string; value: any }> {
    return lines.filter(
      (line) =>
        ![
          'monitoring.tabs.operation.label-tx-instantaneous',
          'monitoring.tabs.operation.legend-effective',
          'monitoring.tabs.operation.legend-total',
          'monitoring.tabs.operation.title-graphs',
        ].includes(line.label),
    );
  }

  removeSpecificLinesForOpTypeIrrigation(
    lines: Array<{ label: string; value: any }>,
  ): Array<{ label: string; value: any }> {
    return lines.filter(
      (line) =>
        !['monitoring.tabs.operation.label-tx-instantaneous'].includes(
          line.label,
        ),
    );
  }

  removeSpecificLinesForOpTypeOthers(
    lines: Array<{ label: string; value: any }>,
  ): Array<{ label: string; value: any }> {
    return lines.filter(
      (line) =>
        ![
          'monitoring.tabs.operation.label-tx-instantaneous',
          'monitoring.tabs.operation.label-session',
          'monitoring.tabs.operation.label-status-session',
          'monitoring.tabs.operation.legend-effective',
          'monitoring.tabs.operation.legend-total',
          'monitoring.tabs.operation.title-graphs',
        ].includes(line.label),
    );
  }

  modifyLinesPerOperation(lines: Array<Line>): Array<Line> {
    let copyOfLines = [...lines];

    if (this.operationTab?.operationType === OpType.OTHER) {
      copyOfLines = this.removeSpecificLinesForOpTypeOthers(copyOfLines);
    } else if (this.operationTab?.operationType === OpType.HARVEST) {
      copyOfLines = this.removeSpecificLinesForOpTypeHarvest(copyOfLines);
    } else if (this.operationTab?.operationType === OpType.PLANTING) {
      copyOfLines = this.removeSpecificLinesForOpTypePlanting(copyOfLines);
    } else if (this.operationTab?.operationType === OpType.IRRIGATION) {
      copyOfLines = this.removeSpecificLinesForOpTypeIrrigation(copyOfLines);
    }

    return copyOfLines;
  }

  formatAsListOfCards(lines: Array<Line>, linesPerCard: number): CardList {
    let listOfCards: CardList = [];
    let cardCount = 0;

    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
      if (lineIndex % linesPerCard == 0) {
        listOfCards[cardCount++] = [];
      }

      const line: Line = {
        label: lines[lineIndex].label,
        value: lines[lineIndex].value,
      };

      if (line.value !== '') {
        listOfCards[cardCount - 1].push(line);
      }
    }

    return listOfCards;
  }
}
