import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServiceBase } from './service.base';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NvgTrabalhoService extends ServiceBase {
  trabalho: any;
  informativoTempoFreeTier$: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  constructor(public http: HttpClient) {
    super();
  }

  public dataSource = new BehaviorSubject<any>([]);
  currenteData = this.dataSource.asObservable();

  changeData(data: any[]) {
    this.dataSource.next(data);
  }

  getTrabalhoSummaryByDeviceId(id: string | number, machineName: string) {
    return this.http
      .get(
        `${this.UrlServiceV1}task-hist?deviceId=${id}&machineName=${machineName}&metadata=true`,
        this.obterAuthHeader(),
      )
      .pipe(
        map(this.extractData<Record<string, any>[]>),
        catchError(this.serviceError),
      );
  }

  getTerminals() {
    return this.http
      .get(this.UrlServiceV1 + 'task-hist', {
        ...this.obterAuthHeader(),
        params: { metadata: 'true' },
      })
      .pipe(
        map(this.extractData<Record<string, any>[]>),
        catchError(this.serviceError),
      );
  }
}
