import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {

  @Input() title: string;
  @Input() iconClass: string;
  @Input() inputText: string;
  @Input() labelText: string;
  @Input() value: string;
  @Input() name: string;
  @Input() disabled: string;

  @Input() selectList: Array<{ value: string | number, label: string }> = [
    { value: 1, label: "Select Example 01" },
    { value: 2, label: "Select Example 02" },
    { value: 3, label: "Select Example 03" }
  ];

  constructor() { }
}
