import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from 'app/core/services/service.base';
import { catchError, map } from 'rxjs/operators';
import { Task } from '../interface/entity';

@Injectable({
  providedIn: 'root',
})
export class TarefasTalhaoFieldService extends ServiceBase {
  public tarefasTalhaoFieldId: string;

  constructor(public http: HttpClient) {
    super();
  }

  getTarefasTalhaoById(tarefasTalhaoFieldId: string) {
    return this.http
      .get(
        `${this.UrlServiceV1}field-operations?id=${tarefasTalhaoFieldId}`,
        this.obterAuthHeader(),
      )
      .pipe(map(this.extractData<Task[]>), catchError(this.serviceError));
  }

  getTarefaDetailsByFieldId(fieldId: string) {
    return this.http
      .get(`${this.UrlServiceV1}field-operations`, {
        ...this.obterAuthHeader(),
        params: { fieldId },
      })
      .pipe(map(this.extractData<Task[]>), catchError(this.serviceError));
  }

  postTarefasTalhao(data) {
    return this.http
      .post(
        this.UrlServiceV1 + 'field-operations',
        { data },
        {
          ...this.obterAuthHeader(),
        },
      )
      .pipe(
        map(this.extractData<Record<string, any>>),
        catchError(this.serviceError),
      );
  }

  deleteTarefaTalhao(tarefasTalhaoFieldId) {
    return this.http
      .delete(
        `${this.UrlServiceV1}field-operations?id=${tarefasTalhaoFieldId}`,
        this.obterAuthHeader(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  putTarefasTalhao(data: Record<string, any>) {
    return this.http
      .put(
        `${this.UrlServiceV1}field-operations?id=${data.data.id}`,
        data,
        this.obterAuthHeader(),
      )
      .pipe(
        map(this.extractData<Record<string, any>>),
        catchError(this.serviceError),
      );
  }
}
