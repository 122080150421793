import {
  merge as observableMerge,
  fromEvent as observableFromEvent,
  Observable,
} from 'rxjs';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { User } from 'app/shared/models/user';
import { Router } from '@angular/router';
import 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/core/services/user.service';
import { AnalyticsService } from 'app/core/services/analytics.service';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  UntypedFormControl,
  FormControlName,
} from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { GenericValidator } from 'app/utils/generic-form-validator';
import 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import { AppConstants } from 'app/app.constants';
import { TranslateUtil } from 'app/utils/translate.utils';
import { tap } from 'rxjs/operators';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';

const translateKeyError = 'register.title-error-support';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: Array<ElementRef> = [];
  @ViewChild('checkAcceptTerms', { static: false })
  checkAcceptTermsCheckbox: ElementRef;

  @Input() ng2TelInputOptions: any;
  @Output() hasError: EventEmitter<boolean> = new EventEmitter();
  @Output() ng2TelOutput: EventEmitter<any> = new EventEmitter();
  @Output() intlTelInputObject: EventEmitter<any> = new EventEmitter();
  ngTelInput: any;

  public usuario: User = new User();
  public metadata;
  public string;
  public anoAtual;

  public registrarForm: UntypedFormGroup;
  public displayMessage: { [key: string]: string } = {};
  public validationMessages: { [key: string]: { [key: string]: string } };
  public genericValidator: GenericValidator;

  sizePassword: boolean = true;
  passNotEquals: boolean = true;

  languages_options: {
    PT_BR: string;
    EN: string;
    ES: string;
    IT: string;
  };
  public translateUtils: TranslateUtil;

  public executingRegister = false;

  public buttonEnabled = false;

  public isPasswordVisible = false;

  public isConfirmPasswordVisible = false;

  constructor(
    public fb: UntypedFormBuilder,
    public usuarioService: UserService,
    public analyticsService: AnalyticsService,
    public translateService: TranslateService,
    public AlertService: AlertService,
    public router: Router,
  ) {
    this.languages_options = AppConstants.LANGUAGES;
    this.translateUtils = new TranslateUtil();

    this.iniciarFormulario();
    this.anoAtual = new Date().getFullYear();
  }

  ngOnInit() {
    this.inicializarFormulario();
    this.analyticsService.submitRegistrationStartEvent();
  }

  inicializarFormulario() {
    const senha = new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(16),
      Validators.minLength(6),
    ]);

    const senhaConfirmacao = new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(16),
      Validators.minLength(6),
    ]);

    const id = new UntypedFormControl('', [
      Validators.required,
      CustomValidators.email,
    ]);

    this.registrarForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(150),
          Validators.minLength(2),
        ],
      ],
      id,
      Senha: senha,
      SenhaConfirmacao: senhaConfirmacao,
      CheckAcceptTerms: [false, Validators.requiredTrue],
    });

    this.executingRegister = false;
  }

  somenteNumericosTelefone(event) {
    const patt = /^([0-9])$/;
    const result = patt.test(event.key);

    if (
      (!result && event.key === '(') ||
      event.key === ')' ||
      event.key === ' ' ||
      event.key === '-'
    ) {
      return true;
    }

    return result;
  }

  iniciarFormulario() {
    this.validationMessages = {
      name: {
        required: '',
        minlength: '',
        maxlength: '',
        pattern: '',
      },
      id: {
        required: '',
        email: '',
      },
      Senha: {
        required: '',
        minlength: '',
      },
      SenhaConfirmacao: {
        required: '',
        minlength: '',
        equalTo: '',
      },
    };

    this.populateValidateObj();

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  populateValidateObj() {
    this.translateService
      .get('global.validate.user-required')
      .subscribe((res: string) => {
        this.validationMessages.name.required = res;
      });
    this.translateService
      .get('global.validate.user-maxlength')
      .subscribe((res: string) => {
        this.validationMessages.name.maxlength = res;
      });
    this.translateService
      .get('global.validate.user-minlength')
      .subscribe((res: string) => {
        this.validationMessages.name.minlength = res;
      });
    this.translateService
      .get('global.validate.user-pattern')
      .subscribe((res: string) => {
        this.validationMessages.name.pattern = res;
      });

    this.translateService
      .get('global.validate.mail-required')
      .subscribe((res: string) => {
        this.validationMessages.id.required = res;
      });
    this.translateService
      .get('global.validate.mail-error')
      .subscribe((res: string) => {
        this.validationMessages.id.email = res;
      });

    this.translateService
      .get('global.validate.password-minlength')
      .subscribe((res: string) => {
        this.validationMessages.Senha.minlength = res;
      });

    this.translateService
      .get('global.validate.password-maxlength')
      .subscribe((res: string) => {
        this.validationMessages.Senha.maxlength = res;
      });

    this.translateService
      .get('global.validate.password-required')
      .subscribe((res: string) => {
        this.validationMessages.Senha.required = res;
      });

    this.translateService
      .get('global.validate.password-wrong')
      .subscribe((res: string) => {
        this.validationMessages.SenhaConfirmacao.required = res;
      });
  }

  ngAfterViewInit() {
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) =>
        observableFromEvent(formControl.nativeElement, 'blur'),
    );
    observableMerge(...controlBlurs).subscribe((value) => {
      this.displayMessage = this.genericValidator.processMessages(
        this.registrarForm,
      );
    });
  }

  changeLanguage(language) {
    this.translateService.use(language);
    window.localStorage.setItem(
      AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG,
      language,
    );
    setTimeout(() => {
      this.populateValidateObj();
      this.genericValidator = new GenericValidator(this.validationMessages);
      this.displayMessage = this.genericValidator.processMessages(
        this.registrarForm,
      );
    }, 0);
  }

  cadastrarUsuarioFreeTier() {
    this.executingRegister = false;
    this.displayMessage = this.genericValidator.processMessages(
      this.registrarForm,
    );

    if (
      this.registrarForm.valid &&
      this.registrarForm.dirty &&
      this.registrarForm.value.Senha ===
        this.registrarForm.value.SenhaConfirmacao
    ) {
      document.body.style.cursor = 'wait';
      this.executingRegister = true;

      const usuario = this.montarObjUsuario();

      this.usuarioService.saveUsuarioFreeTier(usuario).subscribe(
        () => {
          this.registrarForm.reset();
          this.executingRegister = false;
          this.translateService
            .get('register.title-mail-post-registration')
            .subscribe((res: string) => {
              this.AlertService.success(res);
            });
          document.body.style.cursor = 'default';
          if (!usuario.id.includes('@agres.com.br')) {
            this.analyticsService.submitRegistrationCompleteEvent(usuario.id);
            this.analyticsService.trackEvent(
              'registration_complete',
              'free_tier',
              usuario.id,
            );
          }
          setTimeout(() => {
            this.goToLogin();
          }, TimeoutEnum.Medium);
        },
        (errorMsg) => {
          this.executingRegister = false;
          document.body.style.cursor = 'default';
          let errorMessage: string;

          if (
            errorMsg.translateKey !== null &&
            errorMsg.translateKey === translateKeyError
          ) {
            this.translateService
              .get('register.title-support')
              .subscribe((res: string) => {
                this.AlertService.error(res);
              });
            errorMessage = translateKeyError;
          } else if (errorMsg.error != null) {
            if (errorMsg.error.name === 'Conflict') {
              errorMessage = 'global.alert.msg-account-error';
              this.translateService
                .get(errorMessage)
                .subscribe((res: string) => {
                  this.AlertService.error(res);
                });
            } else {
              errorMessage = 'global.validate.title-registration-invalid';
              this.translateService
                .get(errorMessage)
                .subscribe((res: string) => {
                  this.AlertService.warning(res);
                });
              errorMessage += `: ${errorMsg.error.text}`;
            }
          } else {
            errorMessage = 'global.validate.title-registration-invalid';
            this.translateService.get(errorMessage).subscribe((res: string) => {
              this.AlertService.warning(res);
            });
          }
          this.analyticsService.submitRegistrationFailureEvent(
            usuario,
            errorMessage,
          );
        },
      );
    } else {
      const formControls = { ...this.registrarForm.controls };
      delete formControls.CheckAcceptTerms;
      const showMustAcceptTermsToast: boolean =
        Object.values(formControls)
          .map((e) => e.valid)
          .reduce((prev, curr) => prev && curr) &&
        !this.registrarForm.get('CheckAcceptTerms').valid;
      if (showMustAcceptTermsToast)
        this.translateService
          .get('global.validate.check_terms')
          .subscribe((translatedMessage) => {
            this.AlertService.warning('', translatedMessage);
            this.checkAcceptTermsCheckbox.nativeElement.focus();
          });

      this.translateService
        .get(['global.validate.check_terms'])
        .pipe(
          tap((translations: Object | string[]) => {
            const [text, title] = Array.isArray(translations)
              ? translations
              : Object.values(translations);
            this.AlertService.error(text, title);
          }),
        )
        .subscribe();
    }
  }

  montarObjUsuario() {
    let langSelected = window.localStorage.getItem(
      AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG,
    );

    if (!langSelected) {
      langSelected = AppConstants.LANGUAGES.PT_BR;
    }

    const user = {
      name: this.registrarForm.controls.name.value,
      id: this.registrarForm.controls.id.value,
      password: this.registrarForm.controls.Senha.value,
      lang: langSelected,
    };

    return user;
  }

  public goToLogin() {
    this.router.navigateByUrl('/login');
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  confirmTogglePassword() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  equalPasswords() {
    const password = this.registrarForm.get('Senha').value;
    const passwordConfirmation =
      this.registrarForm.get('SenhaConfirmacao').value;

    if (password === passwordConfirmation) {
      this.passNotEquals = false;
    } else {
      this.passNotEquals = true;
    }
  }

  passwordSize() {
    const pass = this.registrarForm.get('Senha').value;

    if (pass.length < 6) {
      this.sizePassword = true;
    } else {
      this.sizePassword = false;
    }
  }

  submitForm(): boolean {
    if (
      this.registrarForm.valid &&
      this.registrarForm.dirty &&
      this.registrarForm.get('CheckAcceptTerms').value
    ) {
      return true;
    } else {
      return false;
    }
  }
}
