import { FormGroup, UntypedFormGroup } from '@angular/forms';

export class GenericValidator {
  constructor(
    public validationMessages: { [key: string]: { [key: string]: string } },
  ) {}

  processMessages(container: UntypedFormGroup | FormGroup): {
    [key: string]: string;
  } {
    const messages = {};

    Object.keys(container.controls).forEach((controlKey) => {
      const control = container.controls[controlKey];

      if (control instanceof UntypedFormGroup) {
        Object.assign(messages, this.processMessages(control));
      } else if (this.validationMessages[controlKey]) {
        messages[controlKey] = '';

        if ((control.dirty || control.touched) && control.errors) {
          Object.keys(control.errors).forEach((messageKey) => {
            if (this.validationMessages[controlKey][messageKey]) {
              messages[controlKey] +=
                this.validationMessages[controlKey][messageKey];
            }
          });
        }
      }
    });

    return messages;
  }
}
