export class NavigationTabModel {
  constructor(
    public latitude: number = 0,
    public longitude: number = 0,
    public elevation: number = 0,
    public usedSatellites: number = 0,
    public positionType: number = 0,
    public gpsSource: number = 0,
    public isSteeringEngaged: boolean = false,
    public deviceType: string = 'isoview',
  ) {}
}
