import { Component, OnInit } from '@angular/core';
import { User } from '../../../shared/models/user';
import { LoginService } from '../../../core/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '../../../utils/date.utils';
import { UserService } from '../../../core/services/user.service';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { environment } from '../../../../environments/environment';
import { AppConstants } from '../../../app.constants';
import { TranslateUtil } from '../../../utils/translate.utils';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class LoginPageComponent implements OnInit {
  public usuario: User = new User();
  public environmentName;
  public loginExecuting = false;
  public anoAtual;
  public languages_options: any;
  public translateUtils: TranslateUtil;

  public successAccountRegister = false;
  public doubleAccountRegister = false;
  public hashInvalido = false;

  public isPasswordVisible = false;

  constructor(
    public loginService: LoginService,
    public usuarioService: UserService,
    public analyticsService: AnalyticsService,
    public translate: TranslateService,
    public router: Router,
    public AlertService: AlertService,

    public route: ActivatedRoute,
    public http: HttpClient,
  ) {
    this.translateUtils = new TranslateUtil();
    this.anoAtual = new Date().getFullYear();
  }

  ngOnInit() {
    this.environmentName = environment.environmentName;
    this.languages_options = AppConstants.LANGUAGES;

    this.validarUsuarioFreeTier();

    if (!localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.FIRST_LOAD)) {
      window.localStorage[AppConstants.KEYS_LOCAL_STORAGE.FIRST_LOAD] = true;
      window.localStorage.removeItem(AppConstants.KEYS_LOCAL_STORAGE.ROLE);
      window.localStorage.removeItem(
        AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO,
      );
      window.localStorage.removeItem(
        AppConstants.KEYS_LOCAL_STORAGE.DATE_EXPIRES,
      );
      window.localStorage.removeItem(
        AppConstants.KEYS_LOCAL_STORAGE.REFRESH_TOKEN,
      );
      window.localStorage.removeItem(
        AppConstants.KEYS_LOCAL_STORAGE.ISO_ACCESS_TOKEN,
      );
    } else {
      window.localStorage.removeItem(
        AppConstants.KEYS_LOCAL_STORAGE.FIRST_LOAD,
      );
    }
  }

  changeLanguage(language) {
    if (language == null || language == undefined) {
      language = AppConstants.LANGUAGES.PT_BR;
    }
    this.translate.use(language);
    window.localStorage.setItem(
      AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG,
      language,
    );
  }

  validarUsuarioFreeTier() {
    let hash = '';
    this.route.queryParams.subscribe((params) => {
      hash = params['hash'];
      if (hash)
        this.usuarioService.confirmarCadastroUsuarioFreeTier(hash).subscribe(
          (_res) => {
            this.translate
              .get('global.alert.auth-account-register')
              .subscribe((res: string) => {
                this.AlertService.success(res);
                setTimeout(() => {
                  this.router.navigate(['login']);
                }, 3000);
              });
          },
          (error) => {
            if (error.status === 304) {
              this.translate
                .get('global.alert.auth-account-register')
                .subscribe((res: string) => {
                  this.AlertService.success(res);
                });
            } else {
              this.translate
                .get('global.alert.auth-account-register-error')
                .subscribe((res: string) => {
                  this.AlertService.error(res);
                });
            }
          },
        );
    });
  }

  register() {
    this.router.navigateByUrl('/register');
  }

  esqueceuSenha() {
    this.router.navigateByUrl('/forgot');
  }

  executeLogin() {
    this.loginExecuting = true;

    if (
      this.usuario.email === undefined ||
      this.usuario.password === undefined
    ) {
      this.translate.get('global.validate.fields').subscribe((res: string) => {
        this.AlertService.error(res);
        this.loginExecuting = false;
      });
    } else {
      this.loginService
        .login(this.usuario.email, this.usuario.password)
        .subscribe(
          (data) => {
            this.analyticsService.submitUserLoginEvent(this.usuario);
            if (!data.metadata.active) {
              this.translate
                .get('global.alert.auth-account-mail-attention')
                .subscribe((res: string) => {
                  this.AlertService.error(res);
                  this.loginExecuting = false;
                });
            } else {

              window.localStorage.setItem(
                AppConstants.KEYS_LOCAL_STORAGE.ROLE,
                data.metadata.role,
              );
              window.localStorage.setItem(
                AppConstants.KEYS_LOCAL_STORAGE.ISO_ACCESS_TOKEN,
                data.access_token,
              );
              const userData = JSON.stringify(data.metadata);
              window.localStorage.setItem(
                AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO,
                userData,
              );
              window.localStorage.setItem(
                AppConstants.KEYS_LOCAL_STORAGE.REFRESH_TOKEN,
                data.refresh_token,
              );
              window.localStorage.setItem(
                AppConstants.KEYS_LOCAL_STORAGE.DATE_EXPIRES,
                DateUtils.AddHours(new Date(), 12).toString(),
              );

              this.loginExecuting = false;

              this.router.navigate([''], {
                queryParams: { afterLogin: true },
              });
            }
          },
          (err) => {
            this.translate
              .get('global.validate.user-login')
              .subscribe((res: string) => {
                this.AlertService.error(res);
                this.loginExecuting = false;
              });
          },
        );
    }
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
