import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { TooltipPosition } from '../components/tooltip/ui/enum';
import { AlertService } from '../components/alert/alert.service';

@Component({
  selector: 'app-design-system',
  templateUrl: './design-system.component.html',
  styleUrls: ['./design-system.component.scss'],
})
export class DesignSystemComponent {
  initialState = {
    sweetAlertsSubject: {
      success: new Subject<boolean>(),
      info: new Subject<boolean>(),
      warning: new Subject<boolean>(),
      error: new Subject<boolean>(),
    },
    offCanvas: {
      medium: new Subject<boolean>(),
      large: new Subject<boolean>(),
    },
  };

  title = 'angular-tooltips-tutorial';
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  x = 0;
  y = 0;
  coordinates = '';

  @HostListener('mousemove', ['$event'])
  onMouseMove($event: MouseEvent): void {
    this.x = $event.clientX;
    this.y = $event.clientY;
    this.coordinates = `${this.x},${this.y}`;
  }

  constructor(
    public translate: TranslateService,
    protected alertService: AlertService,
  ) {}
}
