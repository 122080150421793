import { Injectable } from '@angular/core';
import {
  AgresSpinnerCanvas,
  AgresSpinnerCanvasType,
} from './agres-spinner-canvas.model';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AgresSpinnerCanvasService {
  private readonly subject = new Subject<AgresSpinnerCanvas>();
  private readonly defaultId = 'spinnerAgresCanvas';

  onAgresSpinnerCanvas(id = this.defaultId): Observable<AgresSpinnerCanvas> {
    return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
  }

  toShow(message: string) {
    this.show(
      new AgresSpinnerCanvas({ type: AgresSpinnerCanvasType.Show, message }),
    );
  }

  toHide() {
    this.clear();
  }

  show(spinner: AgresSpinnerCanvas) {
    spinner.id = spinner.id || this.defaultId;
    this.subject.next(spinner);
  }

  clear(id = this.defaultId) {
    this.subject.next(new AgresSpinnerCanvas({ id }));
  }
}
