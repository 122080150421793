import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { AuthorizationService } from '../services/authorization.service';
import { ToastrService } from 'ngx-toastr';
//import { MaintenanceService } from '../services/maintenance.service';

@Injectable()
export class RedirectAndShowToastRGuard implements CanActivate {
    constructor(
        public router: Router,
        public translateService: TranslateService,
        public toasService: ToastrService,

    ) {}
    public token: string;
    public userLogged: string;

    canActivate(
        route: ActivatedRouteSnapshot,

    ): Observable<boolean> | Promise<boolean> | boolean {
        
        const {toastrMesage,routeToRedirect} = route.data;

       if(toastrMesage){
        this.translateService.get(toastrMesage).subscribe((mesage:string)=>{
            this.toasService.info(mesage)
        })
       }
       if(routeToRedirect){
        this.router.navigate([`/${routeToRedirect}`]);
       }

        return true
    }
}
