import { Injectable } from '@angular/core';
import { User } from '../../shared/models/user';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';

@Injectable()
export class LoginService extends ServiceBase {
    constructor(protected http: HttpClient) {
        super();
    }

    public login(email: string, password: string): Observable<User> {
        return new Observable<User>();
    }
}
