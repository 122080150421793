import { Location } from './location';

export class Navigation {
    location: Location = new Location();
    pos_type = 0;
    dif_age = 0;
    hdop = 0;
    used_sat = 0;
    speed = 0;
}
