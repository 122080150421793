export class AppConstants {
    public static LANGUAGES = {
        PT_BR: 'ptBR',
        EN: 'en',
        ES: 'es',
        IT:'it',
    };

    public static KEYS_LOCAL_STORAGE = {
        ISO_LANG: 'iso.lang',
        ISO_USUARIO: 'iso.usuario',
        ISO_ACCESS_TOKEN: 'iso.access_token',
        USER_LOCATION: 'userLocation',
        HIDE_MODAL: 'hide_modal',
        ROLE: 'role',

        DATE_EXPIRES: 'date_expires',

        REFRESH_TOKEN: 'refresh_token',
        FIRST_LOAD: 'firstLoad',
        UNITS: 'system_units',
        DEVICE_ID: 'deviceId',
        ID_TRATOR: 'idTrator',
    };

    public static KEYS_SESSION_STORAGE = {
        PARAMETROS: 'parametros',
    };

    public static UNIT_SYSTEM_OPTIONS = {
        METRIC: 'metric',
        IMPERIAL: 'imperial',
    };

    public static UNIT_TEMPERATURE_OPTIONS = {
        CELSIUS: 'C',
        FAHRENHEIT: 'F',
    };

    public static UNIT_SPEED_OPTIONS = {
        KILOMETER_PER_HOUR: 'km/h',
        MILES_PER_HOUR: 'm/h',
        KILOMETER: 'km',
        MILES: 'm',
    };

    public static GRAPH_OPTIONS = {
        TEMPERATURE: 'temperature',
        RELATIVE_HUMIDITY: 'relative_humidity',
        WIND_SPEED: 'wind_speed',
        ROLLING_PRECIPITATION: 'rolling_precipitation',
    };

    public static RESULTADO_NAVEGACAO_OPTIONS = {
        NVG: 'NVG',
        TELEMETRIA: 'TELEMETRIA',
    };
    public static DEVICE_MODEL = {
        ISOVIEW: { name: 'isoview', extension: 'nvg' },
        AGRONAVEPRO: { name: 'agronavepro', extension: 'agp' },
        AGRONAVEPRO_ENGINEERING: { name: 'agronavepro', extension: 'zip' },
    }
  public static readonly COUNTRY_CODES = [
    'AFG',
    'ALB',
    'DZA',
    'ASM',
    'AND',
    'AGO',
    'AIA',
    'ATA',
    'ATG',
    'ARG',
    'ARM',
    'ABW',
    'AUS',
    'AUT',
    'AZE',
    'BHS',
    'BHR',
    'BGD',
    'BRB',
    'BLR',
    'BEL',
    'BLZ',
    'BEN',
    'BMU',
    'BTN',
    'BOL',
    'BES',
    'BIH',
    'BWA',
    'BVT',
    'BRA',
    'IOT',
    'BRN',
    'BGR',
    'BFA',
    'BDI',
    'CPV',
    'KHM',
    'CMR',
    'CAN',
    'CYM',
    'CAF',
    'TCD',
    'CHL',
    'CHN',
    'CXR',
    'CCK',
    'COL',
    'COM',
    'COD',
    'COG',
    'COK',
    'CRI',
    'HRV',
    'CUB',
    'CUW',
    'CYP',
    'CZE',
    'CIV',
    'DNK',
    'DJI',
    'DMA',
    'DOM',
    'ECU',
    'EGY',
    'SLV',
    'GNQ',
    'ERI',
    'EST',
    'SWZ',
    'ETH',
    'FLK',
    'FRO',
    'FJI',
    'FIN',
    'FRA',
    'GUF',
    'PYF',
    'ATF',
    'GAB',
    'GMB',
    'GEO',
    'DEU',
    'GHA',
    'GIB',
    'GRC',
    'GRL',
    'GRD',
    'GLP',
    'GUM',
    'GTM',
    'GGY',
    'GIN',
    'GNB',
    'GUY',
    'HTI',
    'HMD',
    'VAT',
    'HND',
    'HKG',
    'HUN',
    'ISL',
    'IND',
    'IDN',
    'IRN',
    'IRQ',
    'IRL',
    'IMN',
    'ISR',
    'ITA',
    'JAM',
    'JPN',
    'JEY',
    'JOR',
    'KAZ',
    'KEN',
    'KIR',
    'PRK',
    'KOR',
    'KWT',
    'KGZ',
    'LAO',
    'LVA',
    'LBN',
    'LSO',
    'LBR',
    'LBY',
    'LIE',
    'LTU',
    'LUX',
    'MAC',
    'MDG',
    'MWI',
    'MYS',
    'MDV',
    'MLI',
    'MLT',
    'MHL',
    'MTQ',
    'MRT',
    'MUS',
    'MYT',
    'MEX',
    'FSM',
    'MDA',
    'MCO',
    'MNG',
    'MNE',
    'MSR',
    'MAR',
    'MOZ',
    'MMR',
    'NAM',
    'NRU',
    'NPL',
    'NLD',
    'NCL',
    'NZL',
    'NIC',
    'NER',
    'NGA',
    'NIU',
    'NFK',
    'MNP',
    'NOR',
    'OMN',
    'PAK',
    'PLW',
    'PSE',
    'PAN',
    'PNG',
    'PRY',
    'PER',
    'PHL',
    'PCN',
    'POL',
    'PRT',
    'PRI',
    'QAT',
    'MKD',
    'ROU',
    'RUS',
    'RWA',
    'REU',
    'BLM',
    'SHN',
    'KNA',
    'LCA',
    'MAF',
    'SPM',
    'VCT',
    'WSM',
    'SMR',
    'STP',
    'SAU',
    'SEN',
    'SRB',
    'SYC',
    'SLE',
    'SGP',
    'SXM',
    'SVK',
    'SVN',
    'SLB',
    'SOM',
    'ZAF',
    'SGS',
    'SSD',
    'ESP',
    'LKA',
    'SDN',
    'SUR',
    'SJM',
    'SWE',
    'CHE',
    'SYR',
    'TWN',
    'TJK',
    'TZA',
    'THA',
    'TLS',
    'TGO',
    'TKL',
    'TON',
    'TTO',
    'TUN',
    'TUR',
    'TKM',
    'TCA',
    'TUV',
    'UGA',
    'UKR',
    'ARE',
    'GBR',
    'UMI',
    'USA',
    'URY',
    'UZB',
    'VUT',
    'VEN',
    'VNM',
    'VGB',
    'VIR',
    'WLF',
    'ESH',
    'YEM',
    'ZMB',
    'ZWE',
    'ALA',
  ];
}
