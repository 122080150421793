import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    Output,
    EventEmitter,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          height: '100%',
          opacity: 1,
          backgroundColor: 'transparent',
        }),
      ),
      state(
        'closed',
        style({
          height: '100px',
          opacity: 0.2,
          backgroundColor: 'blue',
        }),
      ),
      transition('open => closed', [
        animate('0.5s erase', style({ opacity: 0.2 })),
      ]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class ModalComponent implements OnInit {
  @Input() show = false;
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' | 'fullscreen' = 'lg';
  @Input() showSubject: Subject<boolean>;
  @Input() title: string;

  showButtonCloseModal = false;

  constructor(private readonly activatedRoute: ActivatedRoute) {}
  ngOnInit(): void {
    if (this.showSubject)
      this.showSubject.subscribe((show: boolean) => {
        this.show = show;
      });

    this.activatedRoute.url.subscribe((urlSegments) => {
      if (urlSegments.some((segment) => segment.path === 'gestao-arquivo')) {
        this.showButtonCloseModal = true;
      }
    });
  }

  hideModal(): void {
    setTimeout(() => {
      this.show = false;
    }, TimeoutEnum.Short);
  }

  showModal() {
    this.show = true;
  }
}
