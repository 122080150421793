import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import {
  ValidationContract,
  ValidatorError,
} from 'app/core/validators/fluent-validator';
import { AppliedQuantityPipe } from 'app/pipes/fleet-monitoring/applied-quantity.pipe';
import { OperationTypePipe } from 'app/pipes/fleet-monitoring/operation-type.pipe';
import { NumberFormatPipe } from 'app/pipes/shared/number-format.pipe';
import { Location } from 'app/shared/models/location';
import { OperationTypeUtil } from 'app/utils/operationType.utils';
import { TrailClass, Trails } from 'app/utils/trail.utils';
import { ToastrService } from 'ngx-toastr';
import { Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DeviceInfoModel, RealtimeDeviceStatus } from '../device/device.model';
import { DeviceService } from '../device/device.service';
import { OpType, OperationType } from '../enums/operation-type.enum';
import { NavigationTabModel } from '../navigation-tab/navigation-tab.model';
import {
  DynamicMessageOperationTabModel,
  StaticMessageOperationTabModel,
} from '../operation-tab/operation-tab.model';
import { SectionState } from '../enums/section-state.enum';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

interface TrailColor {
  red: number;
  orange: number;
  yellow: number;
  lemonGreen: number;
  green: number;
}
@Component({
  selector: 'app-trail',
  templateUrl: './trail.component.html',
  styleUrls: ['./trail.component.scss'],
  providers: [
    DeviceService,
    AppliedQuantityPipe,
    OperationTypePipe,
    NumberFormatPipe,
  ],
})
export class TrailComponent implements OnInit, OnDestroy {
  tractorIconHtml = '../../../assets/images/default/tractor.png';
  imgSessionOpenPath = '../../../assets/images/default/aberto.png';
  imgSessionClosedPath = '../../../assets/images/default/fechado.png';
  navigationTabInfo: NavigationTabModel;
  totalTabInfo: any;
  operationTabInfo: DynamicMessageOperationTabModel;
  staticMessageOperationTab: StaticMessageOperationTabModel;
  operationType: OperationType;
  appliedQuantityPipe = new AppliedQuantityPipe();
  operationTypePipe = new OperationTypePipe();
  numberFormatPipe = new NumberFormatPipe(this.translateService);
  operationTypeUtil = new OperationTypeUtil();
  marker = true;
  zoom = 2;
  mapLat: number;
  mapLng: number;
  workStatus = RealtimeDeviceStatus.OFFLINE;
  deviceWorkingStatus = RealtimeDeviceStatus.WORKING;
  trailColorValue: TrailColor = {
    red: 10.5,
    orange: 12.5,
    yellow: 15.7,
    lemonGreen: 20.8,
    green: 22.0,
  };
  location: Location = { latitude: null, longitude: null, elevation: null };
  colorGreaterThanZero = '';
  greaterThan = '';
  zoomTimeout: NodeJS.Timeout;
  zoomInterval: NodeJS.Timeout;
  trailInterval: NodeJS.Timeout;
  trailTimeout: NodeJS.Timeout;
  trailClass: TrailClass;
  pinClicked = true;
  implementWidth: number;
  instRate: number;
  numberOfSections: number | string;
  sectionArr: string[] = [];

  circles = [];
  secArray: any;
  maxArrayLength = 360;
  pointSelected = false;
  pointStatus: string[];
  pointInstRate: any;
  pointDate: any;
  gatewayAddress: string;
  validationContract: ValidationContract;
  zoomEnabled = true;
  currentDeviceId = 'deviceId';
  trailEnabled: boolean;
  apiLoaded!: boolean;
  deviceId: string;
  showModalSubject: Subject<boolean> = new Subject();
  showModal: boolean;
  readonly refreshIntervalInSeconds = 30;
  isOnlineInterval: NodeJS.Timeout;
  isobus = OpType.ISOBUS;
  other = OpType.OTHER;
  navigation = OpType.NAVIGATION;
  pointVelocity: any;
  currentOperationType: OperationType;
  isInitialTrailColorSet = false;
  showErrorLegends = false;

  @Input() staticMessageOperationTabInfo: StaticMessageOperationTabModel;

  @Input() currentTab: string;

  @Input() set navigationTab(value: NavigationTabModel) {
    if (value) {
      this.navigationTabInfo = value;
    }
  }

  @Input() set totalTab(value: any) {
    if (value) {
      this.totalTabInfo = value;
    }
  }

  @Input() set operationTab(value: DynamicMessageOperationTabModel) {
    if (value) {
      this.operationTabInfo = value;
      if (this.staticMessageOperationTabInfo !== undefined) {
        this.staticMessageOperationTabInfo.nozzlesPerSection.splice(
          this.operationTabInfo.numberOfSections,
        );
      }
    }
  }

  @Output() processingComplete = new EventEmitter<void>();

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private readonly deviceService: DeviceService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    this.trailClass = new TrailClass();
    this.validationContract = new ValidationContract();
    this.loadGoogleMapsAPI();
  }

  ngOnDestroy() {
    clearInterval(this.isOnlineInterval);
  }

  ngOnInit() {
    this.isInitialTrailColorSet = false;
    this.deviceId = window.localStorage.getItem(
      AppConstants.KEYS_LOCAL_STORAGE.DEVICE_ID,
    );

    if (this.deviceId !== null) {
      this.isDeviceOnline();
    }
  }

  loadGoogleMapsAPI() {
    this.httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo&libraries=drawing',
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      )
      .subscribe((loaded: boolean) => {
        this.apiLoaded = loaded;
        this.cdr.detectChanges();
      });
  }

  goBack() {
    this.router.navigate(['/']);
  }

  isDeviceOnline() {
    const checkDeviceStatus = (response: any[]) => {
      const status = response.filter(
        (device) => device.deviceId === this.deviceId,
      )[0];
      if (status) {
        if (status.timestamp == null) {
          return false;
        }
        const date: string = new Date(
          Date.now() - this.refreshIntervalInSeconds * 1000,
        ).toISOString();
        const timeNow: number = Date.parse(date);

        if (status.timestamp > timeNow) {
          this.showModal = false;
          this.showModalSubject.next(false);
        } else {
          this.showModal = true;
          this.showModalSubject.next(true);
        }
      }
    };

    this.isOnlineInterval = setInterval(() => {
      if (this.gatewayAddress === 'agronave') {
        this.deviceService
          .getAllAgronaveDevicesRealtime()
          .subscribe(checkDeviceStatus);
      } else if (this.gatewayAddress === 'Isolink-Dongle') {
        this.deviceService.getAllDeviceRealtime().subscribe(checkDeviceStatus);
      }
    }, 15000);
  }

  @Input() set initialArray(value: any) {
    if (value.length > 0) {
      if (this.circles) {
        this.circles = value;
        this.location.latitude =
          this.circles[this.circles.length - 1].center.lat;
        this.location.longitude =
          this.circles[this.circles.length - 1].center.lng;
        this.deviceZoom();
        this.processingComplete.emit();
      }
    }
  }

  @Input() set receivedDeviceInfo(value: DeviceInfoModel) {
    const {
      deviceId,
      gateway,
      deviceStatus,
      secArray,
      opType,
      implWidth,
      instRate,
      latitude,
      longitude,
      timestamp,
      secState,
      speed,
      isIsobusDeviceChanged,
    } = value || {
      deviceId: undefined,
      gateway: undefined,
      deviceStatus: undefined,
      secArray: undefined,
      opType: undefined,
      implWidth: undefined,
      instRate: undefined,
      latitude: undefined,
      longitude: undefined,
      timestamp: undefined,
      secState: undefined,
      speed: undefined,
      isIsobusDeviceChanged: undefined,
    };

    if (deviceId && this.circles) {
      this.workStatus = deviceStatus;
      this.secArray = secArray;
      this.currentDeviceId = deviceId;
      this.gatewayAddress = gateway;
      this.currentOperationType = opType;
      if (this.currentDeviceId !== deviceId) {
        clearTimeout(this.trailTimeout);
        clearInterval(this.trailInterval);
        if (this.workStatus === RealtimeDeviceStatus.OFFLINE) {
          clearInterval(this.zoomInterval);
          clearTimeout(this.zoomTimeout);
          this.zoomEnabled = false;
          this.marker = false;
          this.zoom = 2;
        } else {
          this.trailEnabled = true;
          this.setLocation(latitude, longitude, timestamp);
          this.marker = true;
          this.zoomEnabled = true;
          this.createTrail(
            latitude,
            longitude,
            timestamp,
            speed,
            secState,
            gateway,
            opType,
          );
          this.trailEnabled = false;
        }
      } else if (this.currentDeviceId === deviceId) {
        this.setLocation(latitude, longitude, timestamp);
        this.implementWidth = implWidth;

        this.instRate = instRate;

        if (opType === OpType.ISOBUS && isIsobusDeviceChanged) {
          this.isInitialTrailColorSet = false;
        }

        if (
          this.workStatus === RealtimeDeviceStatus.WORKING &&
          this.instRate > 0 &&
          !this.isInitialTrailColorSet
        ) {
          const yellowValue = this.trailColorValue.yellow || 1;
          const scaleFactor = this.instRate / yellowValue;

          this.trailColorValue = {
            red: parseFloat(
              (this.trailColorValue.red * scaleFactor).toFixed(1),
            ),
            orange: parseFloat(
              (this.trailColorValue.orange * scaleFactor).toFixed(1),
            ),
            yellow: parseFloat(this.instRate.toFixed(1)),
            lemonGreen: parseFloat(
              (this.trailColorValue.lemonGreen * scaleFactor).toFixed(1),
            ),
            green: parseFloat(
              (this.trailColorValue.green * scaleFactor).toFixed(1),
            ),
          };
          this.isInitialTrailColorSet = true;
        }

        this.marker = true;
        this.zoomEnabled = true;
        this.createTrail(
          latitude,
          longitude,
          timestamp,
          speed,
          secState,
          gateway,
          opType,
        );
        this.trailEnabled = false;
      }
      this.currentDeviceId = deviceId;
    }
  }

  setLocation(latitude: number, longitude: number, timestamp: number) {
    if (latitude !== 0 && longitude !== 0 && timestamp !== 0) {
      if (timestamp > this.circles[this.circles.length - 1].timestamp) {
        this.location.latitude = latitude;
        this.location.longitude = longitude;
      }
    }
  }

  createTrail(
    latitude: number,
    longitude: number,
    timestamp: number,
    speed: number,
    secState: string,
    gwAddr: string,
    opType: OperationType,
  ) {
    if (latitude !== 0 && longitude !== 0) {
      if (this.instRate !== undefined) {
        if (this.circles.length > this.maxArrayLength) {
          this.circles.shift();
        }
        this.circles.push({
          center: {
            lat: this.location.latitude,
            lng: this.location.longitude,
          },
          radius: this.implementWidth / 2,
          instRate: this.instRate,
          sec_state: secState,
          sectionArr: this.secArray,
          gwAddr: gwAddr,
          selected: false,
          timestamp: timestamp,
          velocity: speed,
          opType: opType,
        });
      } else {
        if (this.circles.length > this.maxArrayLength) {
          this.circles.shift();
        }
        this.circles.push({
          center: {
            lat: this.location.latitude,
            lng: this.location.longitude,
          },
          radius: this.implementWidth / 2,
          instRate: 0,
          sec_state: secState,
          sectionArr: this.secArray,
          gwAddr: gwAddr,
          selected: false,
          timestamp: timestamp,
          velocity: speed,
          opType: opType,
        });
      }

      this.deviceZoom();
      if (this.pinClicked === true) {
        this.mapLat = this.location.latitude;
        this.mapLng = this.location.longitude;
      }

      this.processingComplete.emit();
    } else {
      this.processingComplete.emit();
    }
  }

  deviceZoom() {
    this.zoomTimeout = setTimeout(() => {
      if (this.zoom < 5) {
        if (this.zoomEnabled) {
          this.mapLat = this.location.latitude;
          this.mapLng = this.location.longitude;
        }
      }

      this.zoomInterval = setInterval(() => {
        if (this.zoomEnabled) {
          this.zoom <= 17 ? this.zoom++ : clearInterval(this.zoomInterval);
        }
      }, 0);
    }, 0);
  }

  circleClick(event) {
    const clearLastPoint = this.circles.filter(
      (item) => item.selected === true,
    );
    if (clearLastPoint.length > 0) {
      clearLastPoint[0].selected = false;
    }

    const point = this.circles[event];
    const status: string[] = [];
    for (let i = 0; i < point.sectionArr.length; i++) {
      if (point.sectionArr[i] === SectionState.OPEN) {
        status.push(this.imgSessionOpenPath);
      } else {
        status.push(this.imgSessionClosedPath);
      }
    }
    point.selected = true;
    this.pointSelected = true;
    this.pointStatus = status;
    this.pointInstRate = point.instRate.toFixed(2);
    this.pointVelocity = point.velocity;
    if (this.gatewayAddress === 'Isolink-Dongle') {
      this.pointDate = this.datePipe.transform(
        point.timestamp * 1000,
        'dd/MM/yyyy - HH:mm:ss',
      );
    } else {
      this.pointDate = this.datePipe.transform(
        point.timestamp,
        'dd/MM/yyyy - HH:mm:ss',
      );
    }
  }

  closeInfos() {
    const clearLastPoint = this.circles.filter(
      (item) => item.selected === true,
    );
    if (clearLastPoint.length > 0) {
      clearLastPoint[0].selected = false;
    }
    this.pointSelected = false;
  }

  markerClick() {
    if (this.pinClicked === true) {
      this.pinClicked = false;
    } else {
      this.pinClicked = true;
    }
  }

  validationValuesPreferences(colorPreferences: TrailColor): boolean {
    this.greaterThan = '';
    this.validationContract.isGreaterThanZero(
      colorPreferences.green,
      'green',
      'green ' + this.colorGreaterThanZero,
    );
    this.validationContract.isGreaterThanZero(
      colorPreferences.lemonGreen,
      'lemonGreen',
      'lemonGreen ' + this.colorGreaterThanZero,
    );
    this.validationContract.isGreaterThanZero(
      colorPreferences.yellow,
      'yellow',
      'yellow ' + this.colorGreaterThanZero,
    );
    this.validationContract.isGreaterThanZero(
      colorPreferences.orange,
      'orange',
      'orange ' + this.colorGreaterThanZero,
    );
    this.validationContract.isGreaterThanZero(
      colorPreferences.red,
      'red',
      'red ' + this.colorGreaterThanZero,
    );
    this.validationContract.isLowerThan(
      colorPreferences.lemonGreen,
      colorPreferences.green,
      'lemonGreen',
      'lemonGreen ' + this.greaterThan + ' green',
    );
    this.validationContract.isLowerThan(
      colorPreferences.yellow,
      colorPreferences.lemonGreen,
      'yellow',
      'yellow ' + this.greaterThan + ' lemonGreen',
    );
    this.validationContract.isLowerThan(
      colorPreferences.orange,
      colorPreferences.yellow,
      'orange',
      'orange ' + this.greaterThan + ' yellow',
    );
    this.validationContract.isLowerThan(
      colorPreferences.red,
      colorPreferences.orange,
      'red',
      'red ' + this.greaterThan + ' orange',
    );
    return this.validationContract.isValid();
  }

  onTrailColorValueChange() {
    const colorPreferencesValid = {
      red: Number(this.trailColorValue.red),
      orange: Number(this.trailColorValue.orange),
      yellow: Number(this.trailColorValue.yellow),
      lemonGreen: Number(this.trailColorValue.lemonGreen),
      green: Number(this.trailColorValue.green),
    };
    this.validationContract.clear();
    if (this.validationValuesPreferences(colorPreferencesValid)) {
      this.trailColorValue = colorPreferencesValid;
      this.showErrorLegends = false;
    } else {
      this.showErrorLegends = true;
    }
  }

  changeColor(
    selected: boolean,
    instRate: number,
    secArray: Array<string>,
    gwAddr: string,
    opType: OperationType,
  ) {
    if (selected) {
      return '#556B61';
    } else if (this.isClose(secArray)) {
      return '#A1CAAE';
    } else if (this.isAgronave(gwAddr) && opType !== this.isobus) {
      return '#1cbd52';
    } else {
      const thresholds = [
        { color: '#FF0000', value: this.trailColorValue.red },
        { color: '#FFA500', value: this.trailColorValue.orange },
        { color: '#FFFF00', value: this.trailColorValue.yellow },
        { color: '#ADFF2F', value: this.trailColorValue.lemonGreen },
        { color: '#008000', value: this.trailColorValue.green },
      ];

      const minValue = this.trailColorValue.red;
      const maxValue = this.trailColorValue.green;

      if (instRate < minValue) {
        return '#8B0000';
      } else if (instRate > maxValue) {
        return '#004d00';
      }

      for (let i = 0; i < thresholds.length - 1; i++) {
        const lower = thresholds[i];
        const upper = thresholds[i + 1];

        if (instRate >= lower.value && instRate <= upper.value) {
          const ratio = (instRate - lower.value) / (upper.value - lower.value);
          return this.interpolateColor(lower.color, upper.color, ratio);
        }
      }
    }
  }

  hexToRgb(hex: string) {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  }

  rgbToHex(r: number, g: number, b: number): string {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  }

  interpolateColor(color1: string, color2: string, ratio: number): string {
    const rgb1 = this.hexToRgb(color1);
    const rgb2 = this.hexToRgb(color2);

    const r = Math.round(rgb1.r + (rgb2.r - rgb1.r) * ratio);
    const g = Math.round(rgb1.g + (rgb2.g - rgb1.g) * ratio);
    const b = Math.round(rgb1.b + (rgb2.b - rgb1.b) * ratio);

    return this.rgbToHex(r, g, b);
  }

  isClose(secArray: string[]) {
    let isClose = true;
    for (const section of secArray) {
      if (section === '1') {
        isClose = false;
      }
    }
    return isClose;
  }

  isAgronave(gwAddr: string) {
    if (gwAddr === 'agronave') {
      return true;
    } else {
      return false;
    }
  }

  hasCoordinates(): boolean {
    return (
      this.location.latitude != undefined &&
      this.location.longitude != undefined
    );
  }
}
