import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from 'app/core/services/service.base';
import { catchError, map } from 'rxjs/operators';
import { Field } from '../interface/entity';

@Injectable({
  providedIn: 'root',
})
export class UserTalhaoListService extends ServiceBase {
  public id: string;

  constructor(public http: HttpClient) {
    super();
  }

  getAllTalhoes() {
    return this.http
      .get(this.UrlServiceV1 + 'fields', { ...this.obterAuthHeader() })
      .pipe(map(this.extractData<Field[]>), catchError(this.serviceError));
  }

  getTalhaoId(id: string | number) {
    return this.http
      .get(`${this.UrlServiceV1}fields?id=${id}`, this.obterAuthHeader())
      .pipe(map(this.extractData<Field>), catchError(this.serviceError));
  }

  postTalhao(data) {
    return this.http
      .post(this.UrlServiceV1 + 'fields/', data, this.obterAuthHeader())
      .pipe(map(this.extractData<Field | null>), catchError(this.serviceError));
  }

  putTalhao(data: { data: Field }) {
    return this.http
      .put(
        `${this.UrlServiceV1}fields?id=${data.data.id}`,
        data,
        this.obterAuthHeader(),
      )
      .pipe(
        map(this.extractData<Record<string, any>>),
        catchError(this.serviceError),
      );
  }

  deleteTalhao(idTalhao: string) {
    return this.http
      .delete(
        `${this.UrlServiceV1}fields?id=${idTalhao}`,
        this.obterAuthHeader(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
}
