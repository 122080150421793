import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ContornosComponent } from './contornos/contornos.component';
import { MapasRecomendacaoComponent } from 'app/modules/gestao-mapas/mapas-recomendacao/mapas-recomendacao.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SafeHtmlPipe } from 'app/shared/pipes/safehtml.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ConvertUnit } from 'app/shared/pipes/convertUnit.pipe';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        PerfectScrollbarModule,
        GoogleMapsModule
    ],
    providers:[
        ConvertUnit
    ],
    declarations: [ContornosComponent, MapasRecomendacaoComponent],
})
export class GestaoMapasModule {}
