import { Component, Input, OnInit } from '@angular/core';

interface ListItem {
  id: string | number, name: string
}
@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent {

  @Input() title: string;
  @Input() iconClass: string;
  @Input() inputText: string;
  @Input() labelText: string;
  @Input() value: string;
  @Input() name: string;
  @Input() disabled: string;

  @Input() codeList: Array<ListItem> = [
    { id: 1, name: 'Example 2+' },
    { id: 2, name: 'Example 4' },
    { id: 3, name: 'Example 5' },
    { id: 4, name: 'Example 6' },
    { id: 5, name: 'Example 7' },
    { id: 5, name: 'Example 8' },
    { id: 5, name: 'Example 9' },
    { id: 5, name: 'Example 11' },
    { id: 5, name: 'Example 12' },
  ];

  public codeValue: string;

  public saveCode(): void {
  }

  constructor() { }
}