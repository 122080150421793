import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from 'jspdf';
import { ConvertUnit } from 'app/shared/pipes/convertUnit.pipe';
import { Evento } from 'app/shared/models/evento';
import { EventoNVG } from 'app/shared/models/eventoNVG';
import {
  OPERACAO,
  ReportObjectGenerator,
} from 'app/utils/report-object-generator';
import { GuideType } from 'app/utils/guide.utils';
import { gitVersion } from 'assets/json/json.interfaces';
import { scaleImgAsB64 } from './scaleAsB64';
import 'jspdf-autotable';
import { ReportImages } from 'app/core/services/report.service';

export class ReportBuilder {
  private doc;
  private eventoNvg;
  private nvgStorageData;
  private logoAgres: HTMLImageElement;
  private reportObject;
  private datePipe;
  private images: ReportImages;
  private report;

  constructor(
    private translateService: TranslateService,
    private convertUnitPipe: ConvertUnit,
    private locale,
  ) {
    this.doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });
    this.datePipe = new DatePipe(locale);
  }

  public buildReport(
    eventoNvg: Evento,
    nvgStorageData: EventoNVG,
    logoAgres: HTMLImageElement,
    images: ReportImages,
  ): Promise<void> {
    this.eventoNvg = eventoNvg;
    this.nvgStorageData = nvgStorageData;
    this.logoAgres = logoAgres;
    this.images = images;

    return new Promise((resolve) => {
      this.buildReportData();
      this.addFirstPage();
      this.addGuidesPages();
      this.addEventMapsPages();
      this.addStepsPages();
      this.saveDocument();
      resolve();
    });
  }

  public buildReportData() {
    this.report = this.getReportData(this.nvgStorageData, this.convertUnitPipe);
  }

  public getReportData(nvgStorageData, convertUnitPipe) {
    const result = {};
    this.reportObject = new ReportObjectGenerator(
      this.translateService,
      this.datePipe,
      convertUnitPipe,
      nvgStorageData,
    );
    result['summaryData'] = this.buildSummaryData();
    result['guideData'] = this.buildGuidesData();
    result['stepData'] = this.buildStepsData();
    return result;
  }

  private buildSummaryData() {
    return this.reportObject.generateReportFirstPage();
  }

  private buildGuidesData() {
    return this.nvgStorageData.guias
      .filter((g) => g.type !== GuideType.PROJECT)
      .map((guia) => this.reportObject.generateGuideinfo(guia));
  }

  private buildStepsData() {
    return this.nvgStorageData.etapa.map((s) => {
      const step = {};
      step['machineData'] =
        this.reportObject.generateReportMachineConfiguration(s);
      step['operationData'] = this.reportObject.generateReportOperation(s);

      if (s.operation === OPERACAO.FRUTICULTURA) {
        step['orchardData'] =
          this.reportObject.generateOrchardCoverBySection(s);
        step['orchardNozzleData'] =
          this.reportObject.generateOrchardConfiguredNozzle(s);
        step['orchardSensorData'] =
          this.reportObject.generateOrchardUltrasonicSensor(s);
      }

      if (s.spraying_product) {
        step['sprayingProductData'] =
          this.reportObject.generateSprayingProducts(s);
      }

      return step;
    });
  }

  private addFirstPage() {
    const firstPageObject = this.report.summaryData;
    this.addTablePage(this.setFirstPageHeader.bind(this), firstPageObject);
  }

  private addEventMapsPages() {
    const {
      inst_rate: inst_rateRaster,
      speed: speedRaster,
      overlap: overlapRaster,
      altitude: altitudeRaster,
      guides: guidesRaster,
    } = this.images.rasterImages;

    const {
      inst_rate: inst_rateBackground,
      speed: speedBackground,
      overlap: overlapBackground,
      altitude: altitudeBackground,
      guides: guideBackground,
    } = this.images.backgroundImages;
    const scaleValues = structuredClone(this.nvgStorageData.subtitles);

    let instRateUnit;
    if (inst_rateRaster) {
      if (
        this.eventoNvg.operation === OPERACAO.PULVERIZACAO ||
        this.eventoNvg.operation === OPERACAO.FRUTICULTURA
      ) {
        instRateUnit = 'l/ha';
      } else if (this.eventoNvg.operation === OPERACAO.ADUBACAO) {
        instRateUnit = 'kg/ha';
      } else if (
        this.eventoNvg.operation === OPERACAO.NAVEGACAO ||
        this.eventoNvg.operation === OPERACAO.OUTROS
      ) {
        instRateUnit = 'km/h';
      } else {
        instRateUnit = '';
      }
      if (instRateUnit) {
        scaleValues.inst_rate = scaleValues.inst_rate.map((scaleValue) =>
          this.convertUnitPipe.transform(scaleValue, instRateUnit),
        );
      }
      this.addInstRateMapToDoc(
        inst_rateRaster,
        inst_rateBackground,
        scaleImgAsB64,
        scaleValues.inst_rate,
      );
    }
    if (overlapRaster) {
      if (instRateUnit) {
        scaleValues.overlap = scaleValues.overlap.map((scaleValue) =>
          this.convertUnitPipe.transform(scaleValue, instRateUnit),
        );
      }
      this.addOverlapMapToDoc(
        overlapRaster,
        overlapBackground,
        scaleImgAsB64,
        scaleValues.overlap,
      );
    }
    if (speedRaster) {
      scaleValues.speed = scaleValues.speed.map((scaleValue) =>
        this.convertUnitPipe.transform(scaleValue, 'km/h'),
      );
      this.addSpeedMapToDoc(
        speedRaster,
        speedBackground,
        scaleImgAsB64,
        scaleValues.speed,
      );
    }
    if (altitudeRaster) {
      let altitudeRasterUnit;
      if (this.nvgStorageData.etapa[0]?.altimetry_op_mode === 'distance') {
        altitudeRasterUnit = 'm';
      } else {
        if (
          typeof this.nvgStorageData.etapa[0]?.altimetry_op_mode === 'string'
        ) {
          altitudeRasterUnit = 'h';
        }
      }
      if (altitudeRasterUnit) {
        scaleValues.altitude = scaleValues.altitude.map((scaleValue) =>
          this.convertUnitPipe.transform(scaleValue, altitudeRasterUnit),
        );
      }
      this.addAltimetryMapToDoc(
        altitudeRaster,
        altitudeBackground,
        scaleImgAsB64,
        scaleValues.altitude,
      );
    }
    if (guidesRaster && guideBackground) {
      this.addGuideMapToDoc(
        guidesRaster,
        guideBackground,
        scaleImgAsB64,
        scaleValues.altitude,
      );
    }
  }

  private addStepsPages() {
    this.nvgStorageData.etapa.forEach((step, index) => {
      this.addMachineConfigPage(index);
      this.addOperationPages(index);

      if (step.operation === OPERACAO.FRUTICULTURA) {
        this.addOrchardPages(index);
      }

      if (step.spraying_product) {
        this.addSprayingProductsPages(index);
      }
    });
  }

  private addOrchardPages(index) {
    this.doc.addPage();
    this.setDefaultHeader();
    this.setOperationHeader(index);
    this.setDefaultFooter();

    this.translateService.get(['tasks']).subscribe((translate: any) => {
      this.addOrchardCoverPages(index, translate);
      this.addOrchardNozzlesPages(index, translate);
      this.addOrchardSensorsPages(translate, index);
    });
  }

  private addOrchardSensorsPages(translate: any, index: any) {
    const ultrasonicTitle =
      translate.monitoring.tabs.operation['label-ultrasonic-sensor'];
    const [orchardultrasonicData, orchardUltrasonicHead] =
      this.report.stepData[index].orchardSensorData;
    this.addReportTable(
      orchardultrasonicData,
      orchardUltrasonicHead,
      ultrasonicTitle,
      (this.doc as any).lastAutoTable.finalY,
    );
  }

  private addOrchardNozzlesPages(index: any, translate: any) {
    const [orchardConfiguredNozzle, orchardConfiguredNozzleHead] =
      this.report.stepData[index].orchardNozzleData;
    const configuredNozzzleTitle =
      translate.monitoring.tabs.operation['label-configured-nozzles'];
    this.addReportTable(
      orchardConfiguredNozzle,
      orchardConfiguredNozzleHead,
      configuredNozzzleTitle,
      (this.doc as any).lastAutoTable.finalY,
    );
  }

  private addOrchardCoverPages(index: any, translate: any) {
    const [orchardCoverBySec, orchardCoverbySecHead] =
      this.report.stepData[index].orchardData;
    const coverbySectionTitle =
      translate.monitoring.tabs.operation['label-cover-by-sec'];
    this.addReportTable(
      orchardCoverBySec,
      orchardCoverbySecHead,
      coverbySectionTitle,
      65,
    );
  }

  private addSprayingProductsPages(index: any) {
    this.doc.addPage();
    const [sprayingProductsObject, sprayingProductsHead] =
      this.report.stepData[index].sprayingProductData;
    this.addTablePage(
      this.setSprayProductHeader.bind(this),
      sprayingProductsObject,
      index,
      sprayingProductsHead,
    );
  }

  private addMachineConfigPage(index: number) {
    this.doc.addPage();
    const machineConfigurationObject = this.report.stepData[index].machineData;
    this.addTablePage(
      this.setMachineConfigurationHeader.bind(this),
      machineConfigurationObject,
      index,
    );
  }

  private addOperationPages(index: number) {
    this.doc.addPage();
    const operationObject = this.report.stepData[index].operationData;
    this.addTablePage(
      this.setOperationHeader.bind(this),
      operationObject,
      index,
    );
  }
  private addInstRateMapToDoc(image, backgroundImg, scaleImg, scaleValues) {
    this.addMapToDoc(image, backgroundImg, scaleImg, scaleValues)(0);
  }
  private addSpeedMapToDoc(image, backgroundImg, scaleImg, scaleValues) {
    this.addMapToDoc(image, backgroundImg, scaleImg, scaleValues)(1);
  }
  private addOverlapMapToDoc(image, backgroundImg, scaleImg, scaleValues) {
    this.addMapToDoc(image, backgroundImg, scaleImg, scaleValues)(2);
  }
  private addAltimetryMapToDoc(image, backgroundImg, scaleImg, scaleValues) {
    this.addMapToDoc(image, backgroundImg, scaleImg, scaleValues)(3);
  }
  private addGuideMapToDoc(image, backgroundImg, scaleImg, scaleValues) {
    this.addMapToDoc(image, backgroundImg, scaleImg, scaleValues)(4);
  }
  private addMapToDoc(image, backgroundImg, scaleImg, scaleValues) {
    return (headerTranslationIndex: 0 | 1 | 2 | 3 | 4) => {
      if (image) {
        this.doc.addPage();
        this.setDefaultHeader();
        this.doc.addImage(scaleImg, 'png', 12, 40, 186, 10, '', 'fast');
        this.doc.addImage(backgroundImg, 'png', 12, 55, 186, 0, '', 'fast');
        this.doc.addImage(image, 'png', 12, 55, 186, 0, '', 'fast');

        if (Array.isArray(scaleValues)) {
          scaleValues.forEach((value, index) => {
            const stringValue = value.toString();
            const paddingLeftA = 26;
            const paddingLeftFactor = 38;
            let paddingLeft = paddingLeftA + index * paddingLeftFactor;
            const stringLengthImportance = 0.7;
            paddingLeft -= Math.ceil(
              stringValue.length * stringLengthImportance,
            );
            const topPadding = 47;
            this.doc.text(stringValue, paddingLeft, topPadding);
          });
        }
        this.setReportMapHeader(headerTranslationIndex);
        this.setDefaultFooter();
      }
    };
  }

  private addTablePage(
    setSpecificHeader: (stepNumber: any) => void,
    pageObject,
    stepIndex = 0,
    pageHead = [],
  ) {
    this.doc.autoTable({
      head: pageHead,
      body: pageObject,
      styles: { fontSize: 13 },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [105, 105, 105],
      },
      theme: 'striped',
      didDrawPage: () => {
        this.setDefaultHeader();
        setSpecificHeader(stepIndex);
        this.setDefaultFooter();
      },
      margin: {
        bottom: 10,
        top: 65,
      },
    });
  }

  private addReportTable(tableBody, tableHead, tableTitle, startY) {
    this.doc.setFontSize(12);
    this.doc.text(tableTitle, 15, startY + 12);
    this.doc.autoTable({
      head: tableHead,
      body: tableBody,
      styles: { fontSize: 11 },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [105, 105, 105],
      },
      theme: 'striped',
      startY: startY + 16,
      margin: {
        bottom: 10,
        top: 65,
      },
    });
  }

  private addGuidesPages() {
    const guidesToBeReported = this.report.guideData;
    if (guidesToBeReported.length > 0) {
      const GUIDE_INFO_PER_PAGE = 4;
      this.translateService.get(['tasks']).subscribe((translate: any) => {
        const guideTitle = translate.tasks.guide['label-guide'];
        let guidesLeft = guidesToBeReported.length;
        let insertedGuideIdx = 0;
        while (guidesLeft > 0) {
          this.doc.addPage();
          this.setDefaultHeader();
          this.setGuidesHeader();
          this.setDefaultFooter();
          const timesToAddGuideInfo =
            guidesLeft - GUIDE_INFO_PER_PAGE > 0
              ? GUIDE_INFO_PER_PAGE
              : guidesLeft;
          for (let i = 0; i < timesToAddGuideInfo; i++) {
            const positionOnPage =
              i === 0 ? 65 : (this.doc as any).lastAutoTable.finalY;
            const guideInfo = this.report.guideData[insertedGuideIdx];
            this.addReportTable(
              guideInfo,
              [],
              guideTitle + ' ' + (insertedGuideIdx + 1),
              positionOnPage,
            );
            insertedGuideIdx++;
            guidesLeft--;
          }
        }
      });
    }
  }

  private setDefaultFooter() {
    const pageSize = this.doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageNumber = String(this.doc.internal.getNumberOfPages());
    this.doc.setFontSize(9);
    this.doc.text(pageNumber, pageWidth / 2, pageHeight - 10, 'center');
  }

  private setGuidesHeader() {
    const pageWidth = this.doc.internal.pageSize.width
      ? this.doc.internal.pageSize.width
      : this.doc.internal.pageSize.getWidth();
    let guideHeader: string;
    let guideSubtitle: string;
    let talhaoHeader: string;
    this.translateService
      .get(['monitoring', 'tasks', 'guide'])
      .subscribe((translate: any) => {
        talhaoHeader =
          translate.tasks.tabs['label-field'] +
          ' ' +
          (this.eventoNvg as any).crop_name;
        guideHeader = translate.tasks.tabs['title-guides'];
        guideSubtitle = translate.tasks.guide['guides-marked'];
      });

    this.doc.setFontSize(12);
    this.doc.text(talhaoHeader, pageWidth / 2, 30, 'center');

    this.doc.setFontSize(16);
    this.doc.text(guideHeader, pageWidth / 2, 40, 'center');

    this.doc.setFontSize(12);
    this.doc.text(guideSubtitle, pageWidth / 2, 60, 'center');
  }

  private setDefaultHeader() {
    const pageWidth = this.doc.internal.pageSize.width
      ? this.doc.internal.pageSize.width
      : this.doc.internal.pageSize.getWidth();
    const dataHeader = this.datePipe.transform(
      this.nvgStorageData.trabalho.tal_timestamp,
      'dd/MM/yyyy - HH:mm:ss',
      this.nvgStorageData.trabalho.timezone,
    );
    const imageRatio = 0.2;
    const resizeValue = [
      this.logoAgres.width * imageRatio,
      this.logoAgres.height * imageRatio,
    ];
    let serieNumberHeader: string;
    let isoFarmVersionHeader: string;

    this.translateService
      .get(['monitoring', 'devices', 'tasks'])
      .subscribe((translate: any) => {
        serieNumberHeader =
          translate.tasks.equipment[(this.eventoNvg as any).model] +
          ' – ' +
          translate['devices']['tb-number-serie'] +
          ': ' +
          String((this.eventoNvg as any).serial_number);
        isoFarmVersionHeader =
          translate.tasks.tabs['label-report-version'] +
          ' ' +
          gitVersion.version;
      });

    this.doc.setFontSize(9);
    this.doc.text(dataHeader, pageWidth - 10, 10, 'right');
    this.doc.text(isoFarmVersionHeader, pageWidth - 10, 14, 'right');
    this.doc.text(serieNumberHeader, pageWidth - 10, 18, 'right');

    this.doc.addImage(
      this.logoAgres,
      'png',
      10,
      6,
      resizeValue[0],
      resizeValue[1],
    );
  }

  private setFirstPageHeader() {
    const pageWidth = this.doc.internal.pageSize.width
      ? this.doc.internal.pageSize.width
      : this.doc.internal.pageSize.getWidth();
    let serialNumberText: string;
    let fieldNameText: string;
    let workReportText: string;

    this.translateService
      .get(['monitoring', 'tasks', 'devices'])
      .subscribe((translate: any) => {
        const portalTr = translate.tasks.tabs['label-report-portal'];
        const fieldTr = translate.tasks['label-field'];
        const workReportTr = translate.tasks['work-report'];
        const serialNumberTr = translate['devices']['tb-number-serie'];
        const workDataTr = translate.tasks['report-data-work'];
        const modelTr = translate.tasks.equipment[this.eventoNvg.model];

        fieldNameText = fieldTr + ' ' + this.eventoNvg.crop_name;
        workReportText = workReportTr + ' – ' + modelTr;
        serialNumberText =
          serialNumberTr + ' ' + String(this.eventoNvg.serial_number);

        this.doc.setFontSize(10);
        this.doc.text(portalTr, pageWidth / 2, 25, 'center');
        this.doc.setFontSize(12);
        this.doc.text(fieldNameText, pageWidth / 2, 30, 'center');
        this.doc.setFontSize(16);
        this.doc.text(workReportText, pageWidth / 2, 40, 'center');
        this.doc.setFontSize(12);
        this.doc.text(serialNumberText, pageWidth / 2, 46, 'center');
        this.doc.text(workDataTr, pageWidth / 2, 60, 'center');
      });
  }

  private setReportMapHeader(id) {
    const mapTitleKey = {
      0: 'map-application',
      1: 'map-speed',
      2: 'map-transgression',
      3: 'map-altimetry',
      4: 'map-guides',
      5: 'map-recommendation',
    };
    const pageWidth = this.doc.internal.pageSize.width
      ? this.doc.internal.pageSize.width
      : this.doc.internal.pageSize.getWidth();
    const pageNumber = this.doc.internal.getNumberOfPages();
    let talhaoHeader: string;
    let mapHeader: string;
    this.translateService.get(['tasks']).subscribe((translate: any) => {
      talhaoHeader =
        translate.tasks['label-field'] +
        ' ' +
        (this.eventoNvg as any).crop_name;
      mapHeader = translate.tasks[mapTitleKey[id]];
    });
    this.doc.setFontSize(12);
    this.doc.text(talhaoHeader, pageWidth / 2, 25, 'center');
    this.doc.setFontSize(14);
    this.doc.text(mapHeader, pageWidth / 2, 36, 'center');
  }

  private setMachineConfigurationHeader(stepNumber) {
    const pageWidth = this.doc.internal.pageSize.width
      ? this.doc.internal.pageSize.width
      : this.doc.internal.pageSize.getWidth();
    let machineConfigHeader: string;
    let talhaoHeader: string;
    let stepNumberText: string;
    this.translateService.get(['tasks']).subscribe((translate: any) => {
      talhaoHeader =
        translate.tasks['label-field'] +
        ' ' +
        (this.eventoNvg as any).crop_name;
      stepNumberText =
        translate.tasks.tabs['title-stage'] + ' #' + String(stepNumber + 1);
      machineConfigHeader = translate.tasks.tabs['label-machine-config'];
    });

    this.doc.setFontSize(12);
    this.doc.text(talhaoHeader, pageWidth / 2, 30, 'center');
    this.doc.setFontSize(16);
    this.doc.text(stepNumberText, pageWidth / 2, 40, 'center');
    this.doc.setFontSize(12);
    this.doc.text(machineConfigHeader, pageWidth / 2, 60, 'center');
  }

  private setOperationHeader(stepNumber) {
    const pageWidth = this.doc.internal.pageSize.width
      ? this.doc.internal.pageSize.width
      : this.doc.internal.pageSize.getWidth();
    let operationHeader: string;
    let talhaoHeader: string;
    let stepNumberText: string;
    this.translateService
      .get(['monitoring', 'tasks'])
      .subscribe((translate: any) => {
        talhaoHeader =
          translate.monitoring.tabs['label-field'] +
          ' ' +
          (this.eventoNvg as any).crop_name;
        stepNumberText =
          translate.monitoring.tabs['title-stage'] +
          ' #' +
          String(stepNumber + 1);
        operationHeader = translate.monitoring.maps['title-operation'];
      });

    this.doc.setFontSize(12);
    this.doc.text(talhaoHeader, pageWidth / 2, 30, 'center');
    this.doc.setFontSize(16);
    this.doc.text(stepNumberText, pageWidth / 2, 40, 'center');
    this.doc.setFontSize(13);
    this.doc.text(operationHeader, pageWidth / 2, 58, 'center');
  }

  private setSprayProductHeader(stepNumber) {
    const pageWidth = this.doc.internal.pageSize.width
      ? this.doc.internal.pageSize.width
      : this.doc.internal.pageSize.getWidth();
    let sprayProductHeader: string;
    let talhaoHeader: string;
    let stepNumberText: string;
    this.translateService
      .get(['monitoring', 'tasks'])
      .subscribe((translate: any) => {
        talhaoHeader =
          translate.monitoring.tabs['label-field'] +
          ' ' +
          (this.eventoNvg as any).crop_name;
        stepNumberText =
          translate.monitoring.tabs['title-stage'] +
          ' #' +
          String(stepNumber + 1);
        sprayProductHeader =
          translate.monitoring.tabs.operation['title-spraying-product'];
      });

    this.doc.setFontSize(12);
    this.doc.text(talhaoHeader, pageWidth / 2, 30, 'center');
    this.doc.setFontSize(16);
    this.doc.text(stepNumberText, pageWidth / 2, 40, 'center');
    this.doc.setFontSize(12);
    this.doc.text(sprayProductHeader, pageWidth / 2, 60, 'center');
  }

  private saveDocument() {
    this.doc.save(
      this.nvgStorageData.trabalho.crop_name + '_' + this.locale + '.pdf',
    );
  }
}
