import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-prescription-map',
  templateUrl: './prescription-map.component.html',
  styleUrls: ['./prescription-map.component.scss'],
})
export class PrescriptionMapComponent {
  @Input() mapHeight: string;
}
