import JSZip from 'jszip';
export async function unzipFiles(arquivoZip): Promise<Array<File>> {
  const jszip = new JSZip();

  try {
    const zip = await jszip.loadAsync(arquivoZip);
    const files: Array<File> = [];

    for (const filename of Object.keys(zip.files)) {
      if (!filename.endsWith('.cpg') && !filename.endsWith('.prj')) {
        try {
          const blobContent = await zip.files[filename].async('blob');
          files.push(
            new File([blobContent], filename, {
              type: blobContent.type,
            }),
          );
        } catch (_error) {
          console.log('Failed to unzip file ' + filename);
        }
      }
    }

    return files;
  } catch (_error) {
    throw new Error('Failed to unzip files');
  }
}
