<div *ngIf="deviceId !== null && weather !== undefined">
  <div class="weatherInfo">
    <div class="weatherInfo__content">
      <div class="weatherInfo__content--info">
        <div class="weatherInfo__content--info---img">
          <img src="../../../assets/images/weather/sol.png" alt="Weather" />
        </div>
        <div class="weatherInfo__content--info---description">
          <h1>{{ weather?.Temperatura | numberFormat: 1 }}ºC</h1>
          <p>{{ 'monitoring.maps.title-temperature' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="weatherInfo">
    <div class="weatherInfo__content">
      <div class="weatherInfo__content--info">
        <div class="weatherInfo__content--info---img">
          <img src="../../../assets/images/weather/chuvacomsol.png" alt="Weather" />
        </div>
        <div class="weatherInfo__content--info---description">
          <h1>{{ weather?.Precipitacao | numberFormat: 0 }}%</h1>
          <p>{{ 'monitoring.maps.title-rain' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="weatherInfo">
    <div class="weatherInfo__content">
      <div class="weatherInfo__content--info">
        <div class="weatherInfo__content--info---img">
          <img src="../../../assets/images/weather/chuva.png" alt="Weather" />
        </div>
        <div class="weatherInfo__content--info---description">
          <h1>{{ weather?.Umidade | numberFormat: 0 }}%</h1>
          <p>{{ 'monitoring.maps.title-humidity' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="weatherInfo">
    <div class="weatherInfo__content">
      <div class="weatherInfo__content--info">
        <div class="weatherInfo__content--info---img">
          <img src="../../../assets/images/weather/chuva-vento.png" alt="Weather" />
        </div>
        <div class="weatherInfo__content--info---description">
          <h1>{{ weather?.Vento | numberFormat: 2 }} km/h</h1>
          <p>{{ 'global.speed-wind' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
