<div class="a-form a-valid">
  <label for="name">
    {{ title }}
    <span class="a-form__required">{{ labelText }}</span>
  </label>
  <div class="a-form__group">
    <select class="a-form__group--input" [name]="name" [disabled]="disabled">
      <option *ngFor="let item of this.selectList; let id = index" value="{{ item.value }}" [selected]="id === 0">
        {{ item.label }}
      </option>
    </select>
  </div>
</div>
