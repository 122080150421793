<ng-container *ngIf="show">
  <div class="overlay"></div>
  <div
    [ngClass]="{
      offcanvas: true,
      offcanvas__medium: size === 'medium',
      show: this.show
    }">
    <div class="hidden__mobile">
      <div class="offcanvas__header">
        <h5 class="offcanvas__header--title">{{ title | translate }}</h5>
        <a
          class="offcanvas__header--btn-close offcanvas__btn-close"
          (click)="hideOffCanvas()"
          data-bs-dismiss="offcanvas"></a>
      </div>
    </div>
    <div class="hidden__desktop">
      <div class="offcanvas__header">
        <a class="offcanvas__header--btn-close" (click)="hideOffCanvas()" data-bs-dismiss="offcanvas">
          <i class="agres-return"></i>
        </a>
        <h5 class="offcanvas__header--title">{{ title | translate }}</h5>
      </div>
    </div>
    <div class="offcanvas__body">
      <ng-container [ngTemplateOutlet]="bodyTemplate">
        <app-agres-spinner-canvas></app-agres-spinner-canvas>
      </ng-container>
    </div>
    <div class="offcanvas__footer">
      <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
    </div>
  </div>
</ng-container>
