import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-map-markers',
  templateUrl: './map-markers.component.html',
  styleUrls: ['./map-markers.component.scss'],
})
export class MapMarkersComponent {
  @Input() marcadoresArrayL;
  @Output() changeMarkers = new EventEmitter<string>();

  toggleMarker(marker: any) {
    this.changeMarkers.emit(marker);
  }
}
