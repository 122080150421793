import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from 'app/core/services/excel.service';
import { NvgTrabalhoService } from 'app/core/services/nvg-trabalho.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import 'rxjs/add/operator/single';
import { Observable, Subject, throwError } from 'rxjs';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { AnalyticsService } from 'app/core/services/analytics.service';

@Component({
  selector: 'app-meus-equipamentos',
  templateUrl: './meus-equipamentos.component.html',
  styleUrls: ['./meus-equipamentos.component.scss'],
})
export class MeusEquipamentosComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  terminals = [];
  nvgItensById = [];
  nvgIds = '';
  valueSelectField;
  valueRadio = '';
  trabalhos = [];
  translateUtils: any;
  translateService: any;
  arrayData: [];
  selectedItens: boolean[] = [];
  hasSelectedItem: boolean;

  dataInicio: string = '';
  dataFinal: string = '';

  filteredData: any[] = [];

  rowsPerPage: number = 10;

  pagination: number[] = [0, this.rowsPerPage];

  pages: number[] = [];

  previousPages: number[] = [];

  pageAtual: number = 0;

  public nenhumTrabalho = false;
  public trabalhoSel;
  public showModalSubject: Subject<boolean> = new Subject();
  public showModal: boolean;

  constructor(
    private excelService: ExcelService,
    public nvgTrabalhosService: NvgTrabalhoService,
    public translate: TranslateService,
    public router: Router,
    public _location: Location,
    public loadingAgresSpinner: AgresSpinnerService,
    private readonly analyticsService: AnalyticsService,
  ) {
    this.translate.get('global.home').subscribe((home: string) => {
      this.translate.get('my-work.title').subscribe((res: string) => {
        const title = res;
        this.bindHTMLTitle(home, title);
      });
    });
  }

  ngAfterViewInit(): void {
    this.translate.get('global.loading.equipment').subscribe((res: string) => {
      this.loadingAgresSpinner.toShow(res);
    });
  }

  dashboard() {
    this.router.navigate(['/']);
  }

  ngOnInit(): void {
    this.nvgTrabalhosService
      .getTerminals()
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)))
      .subscribe((terminals) => {
        this.terminals = terminals
          .map(({ metadata }) => {
            const serialNumber = `${metadata.serialNumber}`.padStart(19, '0');
            const machineName = metadata.machineName;
            if (serialNumber !== `${metadata.serialNumber}`) {
              return {
                machineName,
                serialNumber,
                type: 'isoview',
              };
            }
            return metadata;
          })
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.machineName === value.machineName &&
                  t.serialNumber === value.serialNumber,
              ),
          );
      });
    jQuery(document).ready(function () {
      jQuery('#btn-selecionados').hide();
      jQuery('#btn-exportarTodos').hide();
    });
    this.loadingAgresSpinner.toHide();
    this.analyticsService.sendPageView('/my-tasks/');
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      this.loadingAgresSpinner.toHide();
    }, TimeoutEnum.MediumLong);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    this.loadingAgresSpinner.toHide();

    if (
      error.status === HttpStatusCode.BadRequest ||
      error.status === HttpStatusCode.NotFound
    ) {
      return throwError(
        `Erro ${error.status}: Requisição inválida ou recurso não encontrado.`,
      );
    } else if (error.status === HttpStatusCode.Forbidden) {
      return throwError('Erro de permissão. Acesso proibido.');
    } else {
      return throwError('Erro na requisição. Por favor, tente novamente.');
    }
  }

  /**
   * DO THE TRANSLATION AND CREATE THE BREADCRUMBS IN THE PAGE HEADER
   * @param home
   * @param title
   */

  bindHTMLTitle(home, title) {
    jQuery('.breadcrumb').html(
      `<li><a href="#">${home}</a></li><li class="active"><strong>${title}</strong></li>`,
    );
  }

  /**
   * CHANGE THE LANGUAGE ACCORDING TO THE USER'S CHOICE
   */

  changeLanguage(): void {
    this.translateService.onLangChange.subscribe(() => {
      this.translateService
        .get('global.home')
        .subscribe((home: string) =>
          this.translateService
            .get('my-work.title')
            .subscribe((title: string) => this.bindHTMLTitle(home, title)),
        );
    });
  }

  /**
   * TO EXPORT ALL JOBS WHEN CLICKING THE BUTTON (EXPORT ALL)
   */
  exportAllTrabalhos(): void {
    let trabalhosAsAoA = this.asArrayOfArray(this.trabalhos);
    trabalhosAsAoA = this.translateAttrNames(trabalhosAsAoA);
    this.excelService.exportAoAAsExcelFile(
      trabalhosAsAoA,
      'todos-os-dados-dos-meus-nvg-trabalhos',
    );
  }

  /**
   * EXPORT ALL SELECTED WORKS
   */
  exportAllSelected(): void {
    const filtered = this.trabalhos.filter((_e, index: number) => {
      return this.selectedItens[index];
    });
    let trabalhosAsAoA = this.asArrayOfArray(filtered);
    trabalhosAsAoA = this.translateAttrNames(trabalhosAsAoA);
    this.excelService.exportAoAAsExcelFile(
      trabalhosAsAoA,
      'dados-dos-trabalhos-selecionados',
    );
  }

  /**
   * SPECIFIC FUNCTION FOR THE VIEW BUTTON.
   * IT MUST STILL BE IMPLEMENTED AT THE MOMENT IT IS CALLING AN ALERT
   */

  btnVisualizar(): void {
    alert('Estamos trabalhando nesta funcionalidade!');
  }

  /**
   * LIST ALL USER EQUIPMENT ACCORDING TO SERIAL NUMBER, THROUGH SELECT FIELDS
   * @param event
   */

  onDeviceChange(event): void {
    const selectedValue = event.target.value;
    let [serialNumber, machineName] = selectedValue.split('|');

    const terminal = this.terminals.find(
      (t) => t.serialNumber === serialNumber && t.machineName === machineName,
    );

    if (terminal.type === 'isoview') {
      serialNumber = terminal.serialNumber.replace(/^0+/, '').padStart(6, '0');
    }

    this.nvgTrabalhosService
      .getTrabalhoSummaryByDeviceId(serialNumber, terminal.machineName)
      .subscribe((trabalhos) => {
        this.trabalhos = trabalhos.map(({ metadata }) => {
          return this.formatNumericPrecision(metadata, 2);
        });

        this.filteredData = this.trabalhos;
        this._clearSelectedItems();
        this._resetPagination();
        jQuery('#btn-exportarTodos').show();
      });

    this.filterClear();
  }

  private _resetPagination() {
    const numDocs = this.filteredData.length;
    const numPages = Math.ceil(numDocs / this.rowsPerPage);
    this.pages = [...Array(numPages).keys()];
    this.pageAtual = 0;
  }

  private _clearSelectedItems() {
    this.selectedItens = [];
    this.selectedItens[this.filteredData.length - 1] = false;
    this.selectedItens.fill(false);
  }

  /**
   * LIST OF EQUIPMENT ACCORDING TO THE RANGE OF DATES CHOSEN
   * @param filterStaticDays
   */
  onCheckboxDateChange(filterStaticDays: string): void {
    const filterValue: number = parseInt(filterStaticDays);
    let today = new Date();
    let previousDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - filterValue,
    );

    this.filteredData = this.trabalhos.filter((trabalho) => {
      const endTimestamp: number = new Date(trabalho.endTimestamp).getTime();
      return endTimestamp >= previousDate.getTime();
    });
    this._clearSelectedItems();
    this._resetPagination();
  }

  /**
   * FILTERS LISTING ALL EQUIPMENT WHEN SELECTED
   */
  radioFilterAllEquipments(): void {
    this.filteredData = this.trabalhos;
    this._clearSelectedItems();
    this._resetPagination();
    this.filterClear();
  }

  /**
   * MODIFY THE STYLE OF THE DATES AND TIMES DISPLAYED IN THE TABLE IMPROVING THE UI/UX
   * @param n
   * @returns
   */
  timeConvert(n: number) {
    const date = new Date(null);
    date.setSeconds(n);
    const result = date.toISOString().substring(11, 19);

    const horariosL = result.split(':');
    const labelFormatado =
      horariosL[0] + 'h' + horariosL[1] + 'm' + horariosL[2] + 's';

    return labelFormatado;
  }

  timeConvertInMinutes(n) {
    const date = new Date(null);
    date.setSeconds(n);
    const result = date.toISOString().substring(11, 19);

    const horariosL = result.split(':');
    let minutos = parseInt(horariosL[0], 10) * 60 + parseInt(horariosL[1], 10);

    if (minutos == 0) {
      minutos = parseInt(horariosL[2], 10) / 60;
    }

    return minutos;
  }

  /**
   * TAKES THE SELECTED ITEMS AND SHOWS THE SELECTED EXPORT BUTTON
   * @param i
   * @param checked
   */
  updateSelectedItem(i: number, checked: boolean): void {
    this.selectedItens[i + this.pageAtual * this.rowsPerPage] = checked;
    this.hasSelectedItem = this.selectedItens.some((item) => item);
    if (this.hasSelectedItem === true) {
      jQuery('#btn-selecionados').show();
    } else {
      jQuery('#btn-selecionados').hide();
    }
  }

  /**
   * LIST THE EQUIPMENT ACCORDING TO THE SELECTED DATES (DATE RANGE)
   */
  updateDateFilter(): void {
    if (this.dataInicio != '' && this.dataFinal != '') {
      let inputValueInicial: number = new Date(
        `${this.dataInicio}T00:00:00.0000`,
      ).getTime();
      let inputValueFinal: number = new Date(
        `${this.dataFinal}T00:00:00.000`,
      ).getTime();

      if (inputValueInicial > 0 && inputValueFinal > 0) {
        this.filteredData = this.trabalhos.filter((trabalho) => {
          const endTimestamp: number = new Date(
            trabalho.endTimestamp,
          ).getTime();
          this.closeModal();
          return (
            endTimestamp >= inputValueInicial && endTimestamp < inputValueFinal
          );
        });
      }
    }
  }

  /**
   * CLEAR THE RADIO AND INPUT DATE FIELDS
   */

  filterClear() {
    $('input[name="filtroDias"]').prop('checked', false);
    this.nenhumTrabalho = false;
    $('.dataIni, .dataFim').val('');
    $('.maquina, .talhao, .tipo').val('-1');

    $(
      '#radioFilterDate7Dias, #radioFilterDate15Dias, #radioFilterDate30Dias, #radioFilterAllDates',
    ).css('background', 'transparent');
  }

  /**
   * PAGE LIST 10 OUT OF 10 ITEMS PER PAGE
   * @param index
   * @param e
   */

  clickPagination(index: number, e: any): void {
    if (index >= 0 && index < this.pages.length) {
      if (e !== undefined) {
        e.preventDefault();
      }

      this.pagination = [
        index * this.rowsPerPage,
        index * this.rowsPerPage + this.rowsPerPage,
      ];

      this.pageAtual = index;
    }
  }
  private formatNumericPrecision(json: any, precision: number) {
    Object.keys(json).forEach((k) => {
      const propIsObject =
        Object.keys(json[k]).length > 0 && typeof json[k] === 'object';
      const propIsNotNull = json[k] !== null;

      if (propIsObject && propIsNotNull) {
        json[k] = this.formatNumericPrecision(json[k], precision);
      }

      const isNull = json[k] === null;
      const isNumber = !isNull && !isNaN(json[k]);
      const isNumericString = isNumber && typeof json[k] === 'string';
      const isFloat = isNumber && json[k].toString().indexOf('.') > -1;

      if (isFloat) {
        if (!isNumericString) {
          json[k] = json[k].toFixed(precision);
        }
      }
    });

    return json;
  }
  private translateAttrNames(aoa: any[][]) {
    const result = aoa;
    const translationMap = {
      cropName: 'my-work.label-field',
      evtName: 'my-work.label-event',
      cropType: 'my-work.label-culture',
      operation: 'my-work.label-operation',
      talTimestamp: 'my-work.label-start-date',
      endTimestamp: 'my-work.label-end-date',
      machineName: 'my-work.label-machine',
      operator: 'my-work.label-operator',
      appliedProductName: 'my-work.applied-product-name',
      appliedVolume: 'my-work.label-applied-volume',
      coverArea: 'my-work.label-covered-area',
      averageAppliedVolume: 'my-work.label-applied-volume',
      averageSpeed: 'my-work.label-average-speed',
    };

    result[0].forEach((key, index) => {
      const label = translationMap[key];
      this.translate.get([label]).subscribe((value) => {
        let tr = Object.values(value)[0] as string;
        /* workaround to add dimensionality to values */
        switch (key) {
          case 'appliedVolume':
            tr = tr.replace('(', '– kg | L (');
            break;
          case 'coverArea':
            tr = tr.replace('(', '– ha (');
            break;
          case 'averageAppliedVolume':
            tr = tr.replace('(', '– kg/ha | L/ha (');
            break;
          case 'averageSpeed':
            tr = tr.replace('(', '– km/h (');
            break;
        }

        result[0][index] = tr;
      });
    });

    return result;
  }
  private asArrayOfArray(nvgTrabDocs): any[][] {
    const orderedHeader = [
      'cropName',
      'evtName',
      'cropType',
      'operation',
      'talTimestamp',
      'endTimestamp',
      'machineName',
      'operator',
      'appliedProductName',
      'appliedVolume',
      'coverArea',
      'averageAppliedVolume',
      'averageSpeed',
    ];

    const result = [orderedHeader];
    nvgTrabDocs.forEach((doc) => {
      const row = orderedHeader.map((prop) => {
        if (prop === 'machineName') {
          const serial = `${doc.serialNumber}`.padStart(19, '0');
          return `${serial} - ${doc.machineName}`;
        } else if (prop === 'appliedProductName') {
          return doc[prop] === '' ? 'N/A' : doc[prop];
        } else {
          return doc[prop];
        }
      });
      result.push(row);
    });
    return result;
  }
  openModal() {
    this.showModal = true;
    this.showModalSubject.next(true);
  }
  closeModal() {
    this.showModal = false;
    this.showModalSubject.next(false);
  }
}
