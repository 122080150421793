import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from 'app/app-shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AddDeviceComponent } from '../../shared/components/forms/add-device/add-device.component';
import { EditUserComponent } from 'app/shared/components/forms/edit-user/edit-user.component';
import { RegisterUserComponent } from 'app/shared/components/forms/register-user/register-user.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { AngularEmailAutocompleteModule } from 'app/shared/components/angular-email-autocomplete/angular-email.autocomplete.module';
import { DeleteAccountComponent } from './my-profile/delete-account/delete-account.component';
import { RouterLinkActive, RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AddDeviceComponent,
    RegisterUserComponent,
    EditUserComponent,
    MyProfileComponent,
    DeleteAccountComponent,
  ],
  imports: [
    RouterModule,
    RouterLinkActive,
    AngularEmailAutocompleteModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BsDropdownModule.forRoot(),
    NgxMaskModule.forRoot(),
    PerfectScrollbarModule,
    NgSelectModule,
    UiSwitchModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000, // 5 seconds
      progressBar: true,
    }),
    MatIconModule,
    ReactiveFormsModule,
    MatRadioModule,
    HttpClientXsrfModule.withOptions({
      cookieName: '_xsrf',
      headerName: '2|370adcd3|5d2b09f74e3d74a3c7e9f7309c7c6292|1654514364',
    }),
    AppSharedModule,
  ],
  providers: [],
  exports: [],
})
export class ManagementModule {}
