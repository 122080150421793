import { Inject } from '@angular/core';
import { Location } from '@angular/common';

export class LocaleClass {
    locale: string;
    constructor(@Inject(Location) public location) {}

    setLocale(localeSet): Promise<string> {
        const noProviders: Object[] = [];
        this.locale = localeSet.toLowerCase();
        return Promise.resolve(this.locale);
    }

    getLocale(): string {
        return this.locale;
    }
}
