import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormBuilder,
  FormControlName,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TarefasTalhaoFieldService } from 'app/core/services/operation-field.service';
import { GenericValidator } from 'app/utils/generic-form-validator';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, merge } from 'rxjs';

@Component({
  selector: 'app-criar-editar-tarefa-talhao',
  templateUrl: './criarEditar-tarefa-talhao.component.html',
  styleUrls: ['./criarEditar-tarefa-talhao.component.scss'],
})
export class CriarEditarTarefasTalhaoComponent
  implements OnInit, AfterViewInit
{
  public displayMessage: { [key: string]: string } = {};

  mode: { create: boolean; edit: boolean };

  statusChecked: boolean = false;

  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];
  @ViewChild('soilPreparation') soilPreparation: ElementRef;
  @ViewChild('planting') planting: ElementRef;
  @ViewChild('fertilizing') fertilizing: ElementRef;
  @ViewChild('pulverization') pulverization: ElementRef;
  @ViewChild('harvest') harvest: ElementRef;
  @ViewChild('other') other: ElementRef;

  public fieldOperationFormGroup: UntypedFormGroup;
  public genericValidator: GenericValidator;

  public disableButton;
  public listaIdAlocado;
  public arrayIdAlocado: any[] = [];
  public selectedPermisao;
  public selectedPerfil;
  public perfilInvalido = false;

  public errors: any[] = [];

  public validationMessages: { [key: string]: { [key: string]: string } };
  fieldId: string;
  operationId: string;
  fieldOperationData: any;

  constructor(
    public fb: UntypedFormBuilder,
    public translateService: TranslateService,
    public toastrService: ToastrService,
    private toastr: ToastrService,
    private operationFieldService: TarefasTalhaoFieldService,
    private route: ActivatedRoute,
    public router: Router,
  ) {
    this.validationMessages = {
      adversity: {
        required: '',
      },
      cultureName: {
        required: '',
      },
      stage: {
        required: '',
      },
      startedAt: {
        required: '',
      },
      finishedAt: {
        required: '',
      },
      productName: {
        required: '',
      },
      activeIngredient: {
        required: '',
      },
      concentration: {
        required: '',
      },
      supplierName: {
        required: '',
      },
      dosage: {
        required: '',
      },
      rate: {
        required: '',
      },
      total: {
        required: '',
      },
      waitingPeriod: {
        required: '',
      },
    };

    this.translateService
      .get('global.validate.adversity-required')
      .subscribe((res: string) => {
        this.validationMessages.adversity.required = res;
      });
    this.translateService
      .get('global.validate.culture-required')
      .subscribe((res: string) => {
        this.validationMessages.cultureName.required = res;
      });
    this.translateService
      .get('global.validate.stage-required')
      .subscribe((res: string) => {
        this.validationMessages.stage.required = res;
      });
    this.translateService
      .get('global.validate.startedAt-required')
      .subscribe((res: string) => {
        this.validationMessages.startedAt.required = res;
      });
    this.translateService
      .get('global.validate.finishedAt-required')
      .subscribe((res: string) => {
        this.validationMessages.finishedAt.required = res;
      });
    this.translateService
      .get('global.validate.productName-required')
      .subscribe((res: string) => {
        this.validationMessages.productName.required = res;
      });
    this.translateService
      .get('global.validate.activeIngredient-required')
      .subscribe((res: string) => {
        this.validationMessages.activeIngredient.required = res;
      });
    this.translateService
      .get('global.validate.concentration-required')
      .subscribe((res: string) => {
        this.validationMessages.concentration.required = res;
      });

    this.translateService
      .get('global.validate.supplierName-required')
      .subscribe((res: string) => {
        this.validationMessages.supplierName.required = res;
      });

    this.translateService
      .get('global.validate.dosage-required')
      .subscribe((res: string) => {
        this.validationMessages.dosage.required = res;
      });

    this.translateService
      .get('global.validate.rate-required')
      .subscribe((res: string) => {
        this.validationMessages.rate.required = res;
      });

    this.translateService
      .get('global.validate.total-required')
      .subscribe((res: string) => {
        this.validationMessages.total.required = res;
      });

    this.translateService
      .get('global.validate.waitingPeriod-required')
      .subscribe((res: string) => {
        this.validationMessages.waitingPeriod.required = res;
      });

    this.genericValidator = new GenericValidator(this.validationMessages);
  }
  isCreateEditRoute(): { create: boolean; edit: boolean } {
    let currentPath: string[] | string = this.router.url.split('/');
    currentPath.pop();
    currentPath = currentPath.pop();
    const create = currentPath.includes('criar');
    const edit = currentPath.includes('editar');
    return { create, edit };
  }
  fetchOperationData() {
    this.operationFieldService
      .getTarefasTalhaoById(this.operationId)
      .subscribe((data) => {
        // this.fieldOperationFormGroup.controls.ty
        const [fieldOperationData] = data;
        this.fieldOperationData = fieldOperationData;
        if (fieldOperationData.type.operation === 'soil-preparation')
          this.soilPreparation.nativeElement.checked = true;
        else
          try {
            this[fieldOperationData.type.operation].nativeElement.checked =
              true;
          } catch (error) {}

        const toIsoString = (
          timestamp: number,
          spliceStart: number = 0,
          spliceEnd: number = 19,
        ): string => {
          let date = new Date(timestamp);
          date.setUTCHours(date.getUTCHours() - 3);
          return date.toISOString().slice(spliceStart, spliceEnd);
        };
        const startedAt = toIsoString(fieldOperationData.startedAt);
        const finishedAt = toIsoString(fieldOperationData.finishedAt);

        (
          this.fieldOperationFormGroup.controls.crop as UntypedFormGroup
        ).controls.adversity.setValue(fieldOperationData.crop.adversity);
        (
          this.fieldOperationFormGroup.controls.crop as UntypedFormGroup
        ).controls.name.setValue(fieldOperationData.crop.name);
        (
          this.fieldOperationFormGroup.controls.crop as UntypedFormGroup
        ).controls.stage.setValue(fieldOperationData.crop.stage);
        (
          this.fieldOperationFormGroup.controls.operator as UntypedFormGroup
        ).controls.name.setValue(fieldOperationData.operator.name);
        (
          this.fieldOperationFormGroup.controls.machine as UntypedFormGroup
        ).controls.name.setValue(fieldOperationData.machine.name);
        (
          this.fieldOperationFormGroup.controls.machine as UntypedFormGroup
        ).controls.model.setValue(fieldOperationData.machine.model);
        this.fieldOperationFormGroup.controls.startedAt.setValue(startedAt);
        this.fieldOperationFormGroup.controls.finishedAt.setValue(finishedAt);
        (
          this.fieldOperationFormGroup.controls.product as UntypedFormGroup
        ).controls.name.setValue(fieldOperationData.product.name);
        (
          this.fieldOperationFormGroup.controls.product as UntypedFormGroup
        ).controls.activeIngredient.setValue(
          fieldOperationData.product.activeIngredient,
        );
        (
          this.fieldOperationFormGroup.controls.product as UntypedFormGroup
        ).controls.concentration.setValue(
          fieldOperationData.product.concentration,
        );
        (
          (this.fieldOperationFormGroup.controls.product as UntypedFormGroup)
            .controls.supplier as UntypedFormGroup
        ).controls.name.setValue(fieldOperationData.product.supplier.name);
        (
          this.fieldOperationFormGroup.controls.application as UntypedFormGroup
        ).controls.dosage.setValue(fieldOperationData.application.dosage);
        (
          this.fieldOperationFormGroup.controls.application as UntypedFormGroup
        ).controls.rate.setValue(fieldOperationData.application.rate);
        (
          this.fieldOperationFormGroup.controls.application as UntypedFormGroup
        ).controls.total.setValue(fieldOperationData.application.total);
        (
          this.fieldOperationFormGroup.controls.application as UntypedFormGroup
        ).controls.waitingPeriod.setValue(
          fieldOperationData.application.waitingPeriod,
        );
        this.fieldOperationFormGroup.controls.description.setValue(
          fieldOperationData.description,
        );
        this.disableButton = false;
      });
  }

  ngOnInit() {
    this.mode = this.isCreateEditRoute();

    if (this.mode.create) {
      this.fieldId = this.route.snapshot.paramMap.get('fieldId');
    }
    if (this.mode.edit) {
      this.operationId = this.route.snapshot.paramMap.get('operationId');
      this.fetchOperationData();
    }

    this.fieldOperationFormGroup = this.fb.group({
      crop: this.fb.group({
        adversity: ['', [Validators.required]],
        name: ['', [Validators.required]],
        stage: ['', [Validators.required]],
      }),
      operator: this.fb.group({
        name: ['', []],
      }),
      machine: this.fb.group({
        name: ['', []],
        model: ['', []],
      }),
      startedAt: ['', []],
      finishedAt: ['', []],
      product: this.fb.group({
        name: ['', []],
        activeIngredient: ['', []],
        concentration: ['', []],
        supplier: this.fb.group({
          name: ['', []],
        }),
      }),
      application: this.fb.group({
        dosage: ['', []],
        rate: ['', []],
        total: ['', []],
        waitingPeriod: ['', []],
      }),
      description: ['', []],
    });
    this.disableButton = false;
  }

  ngAfterViewInit() {
    let controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'),
    );
    merge(...controlBlurs).subscribe(() => {
      this.displayMessage = this.genericValidator.processMessages(
        this.fieldOperationFormGroup,
      );
    });
  }

  submitForm() {
    if (this.mode.create && this.statusChecked == true) {
      this.adicionarDevice();
    }
    if (this.mode.edit) {
      this.updateDevice();
    }

    if (this.mode.create && !this.mode.edit && this.statusChecked == false) {
      this.translateService
        .get('global.validate.type-add-error')
        .subscribe((msgErro: string) => {
          this.toastr.error(msgErro, '', {
            timeOut: 3000,
          });
        });
    }
  }

  adicionarDevice() {
    this.displayMessage = this.genericValidator.processMessages(
      this.fieldOperationFormGroup,
    );

    this.disableButton = false;
    if (
      this.fieldOperationFormGroup.valid &&
      this.fieldOperationFormGroup.dirty
    ) {
      this.disableButton = true;

      let operationFieldData = this.prepareFieldOperationObject();

      // this.fieldOperationFormGroup.reset()

      this.operationFieldService
        .postTarefasTalhao(operationFieldData)
        .subscribe((data) => {
          if (data.ok) {
            this.translateService
              .get('global.validate.add-success')
              .subscribe((res: string) => {
                this.toastr.success(res, '', { timeOut: 5000 });
              });
            this.backField();
          } else {
            this.fieldOperationFormGroup.reset();
            this.translateService
              .get('global.validate.add-error')
              .subscribe((msgErro: string) => {
                this.toastr.error(msgErro, '', {
                  timeOut: 10000,
                });
              });
            this.backField();
          }
        });
    }
  }
  updateDevice() {
    this.displayMessage = this.genericValidator.processMessages(
      this.fieldOperationFormGroup,
    );

    this.disableButton = false;
    if (this.fieldOperationFormGroup.valid) {
      this.disableButton = true;

      let fieldOperationData = this.prepareFieldOperationObject();

      this.operationFieldService
        .putTarefasTalhao({ data: fieldOperationData })
        .subscribe(
          (data) => {
            if (data) {
              this.translateService
                .get('global.validate.add-success')
                .subscribe((res: string) => {
                  this.toastr.success(res, '', { timeOut: 5000 });
                });
              this.backField();
            } else {
              this.resetFormAndDisplayErrorMessage();
            }
          },
          () => {
            this.resetFormAndDisplayErrorMessage();
          },
        );
    }
  }

  private resetFormAndDisplayErrorMessage() {
    this.fieldOperationFormGroup.reset();
    this.translateService
      .get('global.validate.add-error')
      .subscribe((msgErro: string) => {
        this.toastr.error(msgErro, '', {
          timeOut: 10000,
        });
      });
    this.backField();
  }

  prepareFieldOperationObject() {
    const operationData = structuredClone(this.fieldOperationFormGroup.value);

    let operationName = '';
    if (this.soilPreparation.nativeElement.checked) {
      operationName = 'soil-preparation';
    }
    if (this.planting.nativeElement.checked) {
      operationName = 'planting';
    }
    if (this.fertilizing.nativeElement.checked) {
      operationName = 'fertilizing';
    }
    if (this.pulverization.nativeElement.checked) {
      operationName = 'pulverization';
    }
    if (this.harvest.nativeElement.checked) {
      operationName = 'harvest';
    }
    if (this.other.nativeElement.checked) {
      operationName = 'other';
    }
    operationData.type = { operation: operationName };

    operationData.startedAt = new Date(operationData.startedAt).getTime();
    operationData.finishedAt = new Date(operationData.finishedAt).getTime();

    if (this.mode.create) {
      operationData.fieldId = this.fieldId;
    }
    if (this.mode.edit) {
      operationData.fieldId = this.fieldOperationData.fieldId;
      operationData.id = this.fieldOperationData.id;
    }
    return operationData;
  }

  onSaveComplete(response: any) {
    this.errors = [];
    this.fieldOperationFormGroup.reset();
    this.disableButton = false;
    this.translateService
      .get('global.alert.success-add')
      .subscribe((res: string) => {
        this.toastrService.success(res);
      });
  }

  onError(fail: any) {
    this.disableButton = false;
    this.errors = [fail.error];

    this.translateService
      .get('error.500.error-occurred')
      .subscribe((res: string) => {
        this.toastrService.error(this.errors[0], res);
      });
  }

  backField(): void {
    history.go(-1);
  }

  selectOperationType(event): void {
    this.statusChecked = true;
    this.soilPreparation.nativeElement.checked = false;
    this.planting.nativeElement.checked = false;
    this.fertilizing.nativeElement.checked = false;
    this.pulverization.nativeElement.checked = false;
    this.harvest.nativeElement.checked = false;
    this.other.nativeElement.checked = false;
    event.srcElement.checked = true;
  }
}
