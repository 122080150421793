export class StringUtils {
  public static isNullOrEmpty(val: string): boolean {
    if (val === undefined || val === null || val.trim() === '') return true;

    return false;
  }
}

export function normalizeString(str: string) {
  return str
    .replace(/\s+/g, '_')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}
