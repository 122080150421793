import { OperationType, OpType } from "app/modules/gestao-operacao/fleet-monitoring/enums/operation-type.enum";

export class Operation {
    inst_rate = 0;
    impl_width = 0;
    app_vol = 0;
    cover_area = 0;
    sec_state = 'Default Section State';
    tot_op_t = 0;
    tot_op_e = 0;
    se = 0;
  op_type: OperationType = OpType.SPRAYING;
    travel_dist = 0;
    work_status = 'Default Work Status';
    sec_number = 0;
}
