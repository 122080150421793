<div *ngIf="apiLoaded | async">
  <google-map [options]="options" width="100%" [height]="mapHeight">
    <map-polygon
      *ngFor="let trail of recommendationMapArray"
      [paths]="trail.paths"
      [options]="{
        fillOpacity: 0.5,
        strokeWeight: 0,
        zIndex: 1,
        clickable: false,
        fillColor: trail.color,
        visible: trail.visible
      }">
    </map-polygon>
    <map-polygon
      *ngFor="let trail of boundariesArray"
      [paths]="trail.paths"
      [options]="{
        fillOpacity: 0.5,
        strokeWeight: 0,
        zIndex: 1,
        clickable: false,
        fillColor: trail.color,
        visible: trail.visible
      }">
    </map-polygon>
    <map-polyline
      *ngFor="let guide of guidesArray"
      [path]="guide.paths"
      [options]="{
        strokeColor: 'blue',
        strokeWeight: 3,
        strokeOpacity: 0.6,
        visible: true,
        zIndex: 2
      }">
    </map-polyline>
  </google-map>
</div>
