<div class="a-form a-valid">
  <label for="name">
    {{ title }}
    <span class="a-form__required">{{ labelText }}</span>
  </label>
  <div class="a-form__group">
    <select [name]="name" class="a-form__group--input" (change)="onChange($event.target.value)" [disabled]="disabled">
      <option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</option>
    </select>
  </div>
</div>
<div class="a-form a-valid">
  <label for="name">
    {{ subtitle }}
    <span class="a-form__required">{{ labelText }}</span>
  </label>
  <div class="a-form__group">
    <select id="sel1" name="Country" class="a-form__group--input" [name]="name" [disabled]="disabled">
      <option *ngFor="let city of cities" [value]="city">{{ city }}</option>
    </select>
  </div>
</div>
