import { Component, ElementRef, Input, OnInit, Output, EventEmitter, ViewChildren } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmacaoModalService } from 'app/core/services/confirmacaoModal.service';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';

@Component({
    selector: 'app-sweet-alert',
    templateUrl: './sweet-alert.component.html',
    styleUrls: ['./sweet-alert.component.scss']
})
export class SweetAlertComponent implements OnInit {

    @Input() buttonTitle: string;
    @Input() styleClass: string;
    @Output() alertEvent;
    @Input() showAlert;
    @Input() showAlertSubject: Subject<boolean>;
    
    @Input() modal: boolean;
    @Input() conteudo: string;
    @Input() bodyText: string;
    @Input() type: string;
    @Input() title: string;
    @Input() confirmButtonText: string;
    @Input() cancelButtonText: string;
    @Output() modalChange: EventEmitter<boolean>;
    @ViewChildren(FormControlName, { read: ElementRef })
    formInputElements: ElementRef[];

    public sweetClick() {

    }

    constructor(
        public confirmacaoModalService: ConfirmacaoModalService,
        public translateService: TranslateService
    ) {
        this.modalChange = new EventEmitter<boolean>();
    }

    ngOnInit() {
        if (!this.confirmButtonText)
            this.translateService
                .get('global.yes')
                .subscribe((res: string) => {
                    this.confirmButtonText = res;
                });
        else
            this.translateService
                .get(this.confirmButtonText)
                .subscribe((res: string) => {
                    this.confirmButtonText = res;
                });

        if (!this.cancelButtonText)
            this.translateService
                .get('global.cancel')
                .subscribe((res: string) => {
                    this.cancelButtonText = res;
                });
        else
            this.translateService
                .get(this.cancelButtonText)
                .subscribe((res: string) => {
                    this.cancelButtonText = res;
                });
        if (this.showAlert && this.type != undefined) {
            this.switchtAlertAction();
        }

        if (this.showAlertSubject)
            this.showAlertSubject.subscribe((showAlert: boolean) => {
                // this.showAlert = showAlert;
                if (showAlert && this.type != undefined) {
                    this.switchtAlertAction()
                }

            });

        // (new EventEmitter()).subscribe
    }

    switchtAlertAction() {
        switch (this.type) {
            case 'warning':
                this.alertaWarning();
                break;
            case 'success':
                this.alertaSuccess();
                break;
            case 'info':
                this.alertaInfo();
                break;
            case 'error':
                this.alertaError();
                break;
        }
    }


    alertaWarning() {
        swal.fire({
            title: this.title,
            text: this.bodyText,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.confirmButtonText,
            cancelButtonText: this.cancelButtonText,
        }).then((willDelete) => {
            if (willDelete.value) {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'confirmado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            } else {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'cancelado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            }
        });
    }

    alertaSuccess() {
        swal.fire({
            title: this.title,
            text: this.bodyText,
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.confirmButtonText,
            cancelButtonText: this.cancelButtonText,
        }).then((willDelete) => {
            if (willDelete.value) {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'confirmado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            } else {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'cancelado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            }
        });
    }

    alertaInfo() {
        swal.fire({
            title: this.title,
            text: this.bodyText,
            icon: 'info',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.confirmButtonText,
            cancelButtonText: this.cancelButtonText,
        }).then((willDelete) => {
            if (willDelete.value) {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'confirmado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            } else {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'cancelado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            }
        });
    }

    alertaError() {
        swal.fire({
            title: this.title,
            text: this.bodyText,
            icon: 'error',
            confirmButtonText: this.confirmButtonText,
            cancelButtonText: this.cancelButtonText,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            timer: 3000
        }).then((willDelete) => {
            if (willDelete.value) {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'confirmado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            } else {
                this.modal = false;
                this.modalChange.emit(this.modal);
                var result = { message: 'cancelado', conteudo: this.conteudo };
                this.confirmacaoModalService.changeData(result);
            }
        });
    }

}
