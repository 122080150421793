<app-alert></app-alert>
<div class="auth">
  <div class="auth__wrap">
    <div class="auth__wrap--pic">
      <div class="auth__wrap--pic">
        <img alt="Agres" src="./assets/images/default/logo-isofarm.svg" />
      </div>
      <div class="auth__wrap--language">
        <a (click)="changeLanguage(languageOptions.PT_BR)">
          <img
            src="./assets/images/languages/ptBR.png"
            alt="Português"
            [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languageOptions.PT_BR) }" />
        </a>
        <a (click)="changeLanguage(languageOptions.EN)">
          <img
            src="./assets/images/languages/en.png"
            alt="English"
            [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languageOptions.EN) }" />
        </a>
        <a (click)="changeLanguage(languageOptions.ES)">
          <img
            src="./assets/images/languages/es.png"
            alt="Spanish"
            [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languageOptions.ES) }" />
        </a>
        <a (click)="changeLanguage(languageOptions.IT)">
          <img
            src="./assets/images/languages/it.png"
            alt="Italian"
            [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languageOptions.IT) }" />
        </a>
      </div>
      <div class="auth__wrap--heading">{{ 'forgot.title' | translate }}</div>
      <form [formGroup]="registerForm" (ngSubmit)="resetPassword()">
        <div class="field" for="email">
          <div class="field__label">
            {{ 'forgot.title-mail' | translate }}
          </div>
          <div class="field__wrap">
            <input type="mail" class="field__input" id="email" formControlName="email" />
          </div>
        </div>
        <app-button
          type="submit"
          color="primary"
          size="full"
          icon="agres-action"
          [disabled]="!registerForm.valid || disabeBtnForgot"
          title="{{ 'forgot.title-button' | translate }}"
          *ngIf="!sendSuccess"></app-button>
        <app-button
          color="return"
          size="full"
          action="reload"
          icon="agres-reload"
          title="{{ 'forgot.title-button-executing' | translate }}"
          disabled="disabled"
          *ngIf="sendSuccess"></app-button>
        <div class="auth__wrap--account">
          <p>{{ 'forgot.title-back-title' | translate }}</p>
          <strong>
            <app-link (click)="backLogin()" title="{{ 'forgot.title-back-login' | translate }}"> </app-link>
          </strong>
        </div>
      </form>
    </div>
    <div class="auth__wrap--copyright">
      <app-link link="https://agres.com.br/quem_somos/" target="_blank" title="{{ 'global.copyright' | translate }}">
      </app-link>
    </div>
  </div>
</div>
