import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { AuthorizationService } from 'app/core/services/authorization.service';
import { ChangeLanguageService } from 'app/core/services/changeLanguage.service';
import { FleetMonitoringService } from 'app/core/services/fleet-monitoring.service';
import { LoginService } from 'app/core/services/login.service';
import { NvgTrabalhoService } from 'app/core/services/nvg-trabalho.service';
import { UserService } from 'app/core/services/user.service';
import { User } from 'app/shared/models/user';
import { _roleEnumUserClass } from 'app/utils/role.utils';
import { TranslateUtil } from 'app/utils/translate.utils';
import { CurrentViewportBreakpoint } from '../layouts/basicLayout.component';
import { Subject } from 'rxjs';
import { IBreadCrumb } from '../breadcrumb/breadcrumb.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  elem = document.documentElement;
  @Input() hideMenuButton = false;
  @Input() resizeEventEmitter: EventEmitter<CurrentViewportBreakpoint>;
  @Input() toggleSidebarSubject: Subject<boolean>;
  @Output() clickOutside = new EventEmitter<void>();
  nomeUsuarioLogado = '';
  userRole = '';
  ROLE_USERS_ENUM = _roleEnumUserClass.ROLE_ENUM;
  wasInsideOfProfileMenu = false;
  backButton: boolean;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const profileMenu = document.getElementById('profileMenu');
    if (this.wasInsideOfProfileMenu && event.target !== profileMenu) {
      this.wasInsideOfProfileMenu = false;
      this.toggleMenu();
    }
  }

  public auth;
  public flagImgLanguage = '';
  public flagName = '';
  public requestFullscreen = false;
  public showAll = false;
  public nome;
  public tokenInterval;
  public usuario: User;
  public deviceList: Array<any>;
  public isFreeTier = false;
  public languages_options: any;
  public translateUtils: TranslateUtil;
  public breadcrumbs: IBreadCrumb[];
  constructor(
    public loginService: LoginService,
    public router: Router,
    public translate: TranslateService,
    public monitoramentoFrotaService: FleetMonitoringService,
    public nvgTrabalhoService: NvgTrabalhoService,
    public changeLanguageService: ChangeLanguageService,
    public cdRef: ChangeDetectorRef,
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    public _location: Location,
  ) {
    this.translate.get('sidebar.title-customers').subscribe((res: string) => {
      this.nome = res;
    });
    this.languages_options = AppConstants.LANGUAGES;
    this.translateUtils = new TranslateUtil();
  }

  openUserDropdown() {
    this.toggleMenu();
    this.wasInsideOfProfileMenu = !this.wasInsideOfProfileMenu;
  }

  toggleMenu(): void {
    const subMenu = document.getElementById('subMenu');
    subMenu.classList.toggle('header__nav--profile---wrap----open');
  }
  toggleSidebar(): void {
    if (this.toggleSidebarSubject) this.toggleSidebarSubject.next();
  }

  goBack() {
    this._location.back();
  }

  changeLanguage(language) {
    if (language == null || language == undefined) {
      language = AppConstants.LANGUAGES.PT_BR;
    }

    this.translate.use(language);
    localStorage.setItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG, language);
    this.changeLanguageService.changeData(language);
    this.flagImgLanguage = `./assets/images/languages/${language}.png`;
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    });
    if (this.breadcrumbs[0].label === 'breadcrumb.title-dashboard') {
      this.backButton = false;
    } else {
      this.backButton = true;
    }
    const selectedLanguage =
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG) ||
      AppConstants.LANGUAGES.PT_BR;

    this.flagImgLanguage = `./assets/images/languages/${selectedLanguage}.png`;
    this.flagName = `${selectedLanguage}`;

    this.userRole = new AuthorizationService().getLoggedUserRole();
    const loggedUser = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    if (!loggedUser.Nome) {
      this.userService.getUserByEmail(loggedUser.id).subscribe((user) => {
        const loggedUserWithName = { ...loggedUser, name: user.name };
        window.localStorage.setItem(
          AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO,
          JSON.stringify(loggedUserWithName),
        );
        this.nomeUsuarioLogado = user.name;
      });
    } else {
      this.nomeUsuarioLogado = loggedUser.name;
    }
    if (!!this.resizeEventEmitter) {
      this.resizeEventEmitter.subscribe(
        (currentViewportBreakpoint: CurrentViewportBreakpoint) => {
          if (
            currentViewportBreakpoint.tablet ||
            currentViewportBreakpoint.mobile
          ) {
            this.hideMenuButton = false;
          }
          if (currentViewportBreakpoint.desktop) {
            this.hideMenuButton = true;
          }
        },
      );
    }
  }

  logout() {
    window.sessionStorage.clear();
    window.localStorage.removeItem(AppConstants.KEYS_LOCAL_STORAGE.ROLE);
    window.localStorage.removeItem(
      AppConstants.KEYS_LOCAL_STORAGE.DATE_EXPIRES,
    );
    window.localStorage.removeItem(AppConstants.KEYS_LOCAL_STORAGE.HIDE_MODAL);
    clearInterval(this.tokenInterval);
    this.router.navigate(['/login']);
  }
  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: IBreadCrumb[] = [],
  ): IBreadCrumb[] {
    let label =
      route.routeConfig && route.routeConfig.data
        ? route.routeConfig.data.breadcrumb
        : '';
    let path =
      route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    const lastRoutePart = path.split('/').pop();

    const isDynamicRoute = lastRoutePart.startsWith(':');

    if (
      isDynamicRoute &&
      !!route.snapshot &&
      path.includes('monitoramento-frota')
    ) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }

    const nextUrl = path ? `${url}/${path}` : url;
    const breadcrumb: IBreadCrumb = {
      label: label,
      url: nextUrl,
    };

    const newBreadcrumbs = breadcrumb.label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}
