import {
  CurrentUserPermissions,
  UserPermissions,
  UserResources,
  UserRole,
  UserRoleType,
} from 'app/shared/models/role';
import { User } from 'app/shared/models/user';

import { Injectable } from '@angular/core';

import { ServiceBase } from './service.base';

@Injectable()
export class AuthorizationService extends ServiceBase {
  public rbac: RBAC = new RBAC();

  constructor() {
    super();
  }

  gethandler(): RBAC {
    const user = this.obterUsuarioLogado();
    this.rbac.initRoleTree(user);
    return this.rbac;
  }

  getLoggedUserRole(): string {
    return this.obterUsuarioLogado().role;
  }
}

class RBAC {
  roles: UserRole[] = [];
  resources: typeof UserResources = UserResources;
  permissions: typeof UserPermissions = UserPermissions;

  initRoleTree(user: User): void {
    this.roles = [];

    if (user.role) {
      this.roles.push(RoleFactory.build(user.role));
    } else {
      this.roles.push(RoleFactory.build());
    }
  }

  getResourcePermissions(resource: UserResources): CurrentUserPermissions {
    let currentUserPermissions: CurrentUserPermissions = {
      canNavigate: false,
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
    };
    currentUserPermissions = this.roles.reduce((oldRole, currentRole) => {
      currentUserPermissions = currentRole.getResourcePermissions(resource);
      return {
        canNavigate: currentUserPermissions.canNavigate || oldRole.canNavigate,
        canCreate: currentUserPermissions.canCreate || oldRole.canCreate,
        canRead: currentUserPermissions.canRead || oldRole.canRead,
        canUpdate: currentUserPermissions.canUpdate || oldRole.canUpdate,
        canDelete: currentUserPermissions.canDelete || oldRole.canDelete,
      };
    }, currentUserPermissions);
    return currentUserPermissions;
  }

  permits(permission: UserPermissions, resource: UserResources): boolean {
    return this.roles.some((role: UserRole) => {
      return role.getPermission(permission, resource);
    });
  }

  forbids(permission: UserPermissions, resource: UserResources): boolean {
    return !this.permits(permission, resource);
  }
}

class RoleFactory {
  static build(
    roleName = '',
  ): AdminRole | StandardRole | TelemetryUser | FreeTierRole {
    switch (roleName) {
      case 'Admin':
        return new AdminRole();
      case 'Standard': {
        return new StandardRole();
      }
      case 'TelemetryUser': {
        return new TelemetryUser();
      }
      default: {
        return new FreeTierRole();
      }
    }
  }
}

class FreeTierRole extends UserRole {
  constructor() {
    super();
    this.initFreeTierUserPermissions();
  }

  initFreeTierUserPermissions(): void {
    this.grant(UserPermissions.NAVIGATE, UserResources.USER_ADMINISTRATION);
    this.grant(UserPermissions.NAVIGATE, UserResources.NAVIGATION_RESULT);
    this.grant(UserPermissions.NAVIGATE, UserResources.CUSTOMER_SUPPORT);
    this.grant(UserPermissions.NAVIGATE, UserResources.WEATHER_FORECAST);
    this.grant(UserPermissions.NAVIGATE, UserResources.USER_PROFILE);
  }
}

class StandardRole extends FreeTierRole {
  constructor() {
    super();
    this.type = UserRoleType.STANDARD;
    this.initStandardUserPermissions();
  }

  initStandardUserPermissions(): void {
    this.grant(UserPermissions.NAVIGATE, UserResources.NAVIGATION_RESULT);
    this.grant(UserPermissions.NAVIGATE, UserResources.WEATHER_FORECAST);
    this.grant(UserPermissions.NAVIGATE, UserResources.NAVIGATION_HISTORY);
    this.grant(UserPermissions.NAVIGATE, UserResources.FIELD_ADMINISTRATION);
    this.grant(UserPermissions.NAVIGATE, UserResources.DEVICE_ADMINISTRATION);
    this.grant(UserPermissions.CREATE, UserResources.DEVICE_ADMINISTRATION);
    this.grant(UserPermissions.UPDATE, UserResources.DEVICE_ADMINISTRATION);
    this.grant(UserPermissions.READ, UserResources.DEVICE_ADMINISTRATION);
  }
}

class TelemetryUser extends StandardRole {
  constructor() {
    super();
    this.type = UserRoleType.TELEMETRY_USER;
    this.initTelemetryUserPermissions();
  }

  initTelemetryUserPermissions(): void {
    this.grant(UserPermissions.NAVIGATE, UserResources.FLEET_MONITORING);
    this.revoke(UserPermissions.NAVIGATE, UserResources.USER_ADMINISTRATION);
    this.revoke(UserPermissions.NAVIGATE, UserResources.NAVIGATION_HISTORY);
  }
}

class AdminRole extends StandardRole {
  constructor() {
    super();
    this.type = UserRoleType.ADMIN;
    this.initAdminUserPermissions();
  }

  initAdminUserPermissions(): void {
    this.grant(UserPermissions.NAVIGATE, UserResources.FLEET_MONITORING);
    this.grant(UserPermissions.UPDATE, UserResources.USER_ADMINISTRATION);
    this.grant(UserPermissions.CREATE, UserResources.USER_ADMINISTRATION);
    this.grant(UserPermissions.READ, UserResources.USER_ADMINISTRATION);
    this.grant(UserPermissions.NAVIGATE, UserResources.DEVICE_ADMINISTRATION);
    this.grant(UserPermissions.CREATE, UserResources.DEVICE_ADMINISTRATION);
    this.grant(UserPermissions.UPDATE, UserResources.DEVICE_ADMINISTRATION);
    this.grant(UserPermissions.READ, UserResources.DEVICE_ADMINISTRATION);
    this.grant(
      UserPermissions.SEND_COMMAND,
      UserResources.DEVICE_ADMINISTRATION,
    );
  }
}
