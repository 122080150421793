<div class="card__body">
  <p class="mb-3">
    <strong>{{ 'tasks.title-markers' | translate }}</strong>
  </p>
  <div *ngFor="let ma of marcadoresArrayL">
    <div [ngClass]="{ 'col-md-offset-0 col-marcadores-children': ma.children }">
      <mat-slide-toggle color="primary" [checked]="ma.checked" (change)="toggleMarker(ma)">{{
        ma.title | translate
      }}</mat-slide-toggle>
    </div>
  </div>
</div>
