<div class="border"></div>
<div style="height: 10vh"></div>
<div class="container">
    <div class="card">
        <img alt="Agres" src="./assets/images/default/logo-isofarm.svg" />
        <h1 class="card__text-center">TERMOS E CONDIÇÕES DE USO</h1>
        <div class="card__text-right">
            <em>Última atualização, 9 de julho de 2020</em>
            <hr>
        </div>
        <div class="row col-md-12">
            <p>
                Este documento contém os Termos e Condições de Uso do site de
                propriedade da Agres Sistemas Eletrônicos S/A, pessoa jurídica
                de direito privado, com sede na cidade de Pinhais, estado do
                Paraná, na Rua Jaguariaíva, 67, Alphaville Graciosa, inscrita no
                CNPJ sob nº 06.635.277/0001-77.
            </p>

            <p>
                Os Termos e Condições de Uso devem ser observados pelo usuário
                na utilização do site e de suas funcionalidades. Assim sendo,
                você declara que fez a leitura completa e atenta deste
                documento, bem como da nossa Política de Privacidade publicada
                em nosso site, estando ciente e plenamente de acordo com ambos
                os documentos.
            </p>
            <ol>
                <li>
                    <strong>Informações gerais sobre o site Agres</strong>
                    <ul>
                        <li>
                            O site Agres é dedicado aos usuários e entusiastas
                            da marca Agres.
                        </li>
                        <li>
                            No site Agres são disponibilizados informações
                            institucionais, técnicas, documentos, imagens,
                            fotografias, gráficos, áudios e vídeos com dicas de
                            agricultura de precisão, depoimentos de clientes,
                            acesso à área restrita para utilização de serviços
                            online e informações sobre os produtos e serviços
                            fornecidos pela Agres.
                        </li>
                        <li>
                            A Agres envida seus melhores esforços para manter os
                            dados e informações disponíveis no site Agres sempre
                            atualizados e corretos, entretanto, não garante a
                            infalibilidade dos mesmos.
                        </li>
                        <li>
                            Qualquer informação e material, incluindo, mas não
                            se limitando a textos, documentos, imagens,
                            fotografias, gráficos, áudios, ou vídeos,
                            disponibilizados no site Agres tem finalidade
                            meramente informativa. A Agres emprega um corpo de
                            especialistas composto por engenheiros eletricistas,
                            engenheiros mecânicos, engenheiros agronômicos,
                            dentre outros profissionais igualmente qualificados,
                            que ajudam na curadoria do conteúdo, mas não se
                            responsabilizam pelo mesmo. Qualquer opinião
                            manifestada no site Agres, por uma determinada
                            pessoa, seja cliente, fornecedor ou colaborador, não
                            representa necessariamente a opinião institucional
                            da Agres.
                        </li>
                    </ul>
                </li>
            </ol>
            <ol start="2">
                <li>
                    <strong>Das condições de uso do site Agres</strong>
                    <ul>
                        <li>
                            Os Termos e Condições de Uso serão atualizados
                            constantemente visando melhoria e aprimoramento.
                            Assim, a Agres se reserva o direito de modificá-los
                            a qualquer tempo, conforme sua finalidade e
                            conveniência, tal qual para adequação e conformidade
                            legal de disposição de lei ou norma que tenha força
                            jurídica, cabendo aos usuários verificá-los sempre
                            que efetuar o acesso no site Agres, ou utilizar seus
                            serviços.
                        </li>
                        <li>
                            Para o uso geral do site Agres os usuários não
                            precisam realizar nenhum cadastro, salvo para o
                            recebimento de newsletter, ou para contato com a
                            Agres (e.g. envio de currículo). Os clientes, no
                            entanto, são encorajados a realizarem o seu cadastro
                            na área específica do site para acesso a funções
                            exclusivas e direcionadas, tirando total proveito
                            dos produtos e serviços da Agres que eventualmente
                            operem, possuam ou utilizem.
                            <ul>
                                <li>
                                    O cadastro para recebimento de newsletter é
                                    aberto a todos os usuários. Para tanto, o
                                    usuário deverá fornecer informações
                                    corretas, completas e atualizadas.
                                </li>
                                <li>
                                    O contato poderá ser realizado por usuários
                                    para solucionar dúvidas, reclamações e
                                    sugestões que eventualmente possuam.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ol>
            <ol start="3">
                <li>
                    <strong>Das responsabilidades e obrigações dos usuários</strong>
                    <ul>
                        <li>
                            Utilizar de forma apropriada e ética o site Agres,
                            obedecendo aos propósitos aqui estabelecidos, sempre
                            respeitando as condições que regem a utilização do
                            site Agres e sua finalidade, e não utilizar as
                            informações disponibilizadas para finalidade
                            comerciais, incluindo-se marketing.
                        </li>
                        <li>
                            Os usuários se comprometem a fornecer dados
                            cadastrais corretos, completos e atualizados para
                            que o site Agres possa funcionar corretamente, além
                            de informar canal de contato apto a ser acionado
                            pela Agres para o melhor cumprimento dos serviços.
                            Se a qualquer momento for constatado que o usuário
                            forneceu dados falsos ou não condizentes com a
                            realidade, a Agres se reserva o direito de suspender
                            ou cancelar seu cadastro, sem prejuízo de adotar as
                            medidas que entender cabíveis, conforme o caso.
                        </li>
                        <li>
                            Para acessar o site Agres de forma segura e utilizar
                            suas funcionalidades de forma integral, é de inteira
                            responsabilidade dos usuários dispor de dispositivos
                            e equipamentos compatíveis, serviço de conexão à
                            internet, softwares devidamente atualizados, além da
                            adoção de medidas de segurança mínimas, o que
                            inclui, mas não se limita a, utilização de senha
                            segura, antivírus e firewall.
                        </li>
                        <li>
                            Adotar medidas em seus dispositivos tecnológicos
                            para evitar o acesso físico e lógico por terceiros
                            não autorizados, tais como utilização de senha e/ou
                            biometria.
                        </li>
                        <li>
                            É de responsabilidade dos usuários deixarem seus
                            sistemas de anti-spam, filtros similares ou
                            configurações de redirecionamento de mensagens
                            ajustados de modo que não interfiram no recebimento
                            dos comunicados e materiais da Agres, não sendo
                            aceitável nenhuma escusa caso não tenha tido acesso
                            a algum e-mail ou mensagem eletrônica em virtude dos
                            recursos mencionados. Não é usual, mas eventualmente
                            a Agres pode enviar algum e-mail ou outro tipo de
                            comunicação contendo links externos, de sua
                            propriedade ou domínio, bem como de propriedade ou
                            domínio de terceiros. Desta forma, caso o usuário
                            receba alguma mensagem contendo links externos, deve
                            avaliar a procedência e estar ciente dos riscos ao
                            clicar no link, pois pode ser tentativa de fraude
                            conhecida como phishing.
                        </li>
                        <li>
                            Ao acessar o site Agres, os usuários declaram que
                            irão respeitar todos os direitos de propriedade
                            intelectual de titularidade da Agres, tal qual todos
                            os direitos referentes a terceiros que porventura
                            estejam, ou estiveram, de alguma forma disponíveis
                            no site Agres.
                            <ul>
                                <li>
                                    Todos os nomes comerciais de produtos da
                                    Agres e logotipos que aparecem neste
                                    website, conforme desenhados com seu símbolo
                                    de marca registrada ou formato que seja
                                    diferente do texto ao redor, são marcas
                                    registradas da Agres, salvo se indicado em
                                    contrário. Todos os produtos e nomes
                                    comerciais de terceiros que aparecem neste
                                    website são de propriedade de seus
                                    respectivos proprietários e a Agres não faz
                                    alegações de propriedade destes.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Os usuários somente poderão reproduzir os conteúdos
                            disponíveis no site Agres, em especial suas marcas e
                            layout do ambiente, desde que devidamente
                            autorizados previamente por escrito pela Agres. Isto
                            se aplica a qualquer alteração do material,
                            especialmente à tradução ou outro tipo de
                            processamento, bem como à transmissão do material.
                        </li>
                        <li>
                            Os usuários estão cientes e concordam que a
                            utilização, redistribuição, comercialização e/ou
                            reprodução de conteúdo ou parte de conteúdo
                            disponibilizado no site Agres deverão seguir as
                            normas concernentes às leis brasileiras de direitos
                            autorais, sob pena de caracterização de utilização
                            indevida e/ou infração a direitos intelectuais de
                            terceiros.
                        </li>
                        <li>
                            As funcionalidades que compõem o site Agres são
                            oferecidas na forma de prestação de serviço, não
                            conferindo ao usuário nenhum direito sobre o
                            software utilizado pela Agres ou sobre suas
                            estruturas de informática que sustentam o site
                            Agres.
                        </li>
                        <li>
                            Se o usuário enviar sugestões, reclamações,
                            propostas, planos, ou outros materiais seja online,
                            por e-mail, pelo correio, ou outra forma, a pedido
                            da Agres ou não, o usuário concorda que a Agres
                            pode, a qualquer momento, sem restrição e desde que
                            respeitada a legislação, editar, copiar, publicar,
                            distribuir, traduzir ou utilizar de outra maneira
                            estes conteúdos sem que decorra, por parte da Agres,
                            responsabilidade por indenização de qualquer sorte.
                        </li>
                        <li>
                            Não é permitido o acesso às áreas de programação do
                            site Agres, seu banco de dados ou qualquer outro
                            conjunto de informações que faça parte da atividade
                            de administração do site (webmastering).
                        </li>
                        <li>
                            Também não é autorizado ao usuário realizar ou
                            permitir que se realize engenharia reversa, nem
                            traduzir, decompilar, copiar, modificar, reproduzir,
                            alugar, sublicenciar, publicar, divulgar,
                            transmitir, emprestar, distribuir ou, de outra
                            maneira, dispor das ferramentas do site Agres e de
                            suas funcionalidades.
                        </li>
                        <li>
                            No site Agres é proibida a utilização de softwares
                            de mineração de dados, de qualquer tipo ou espécie
                            (e.g. bots, crawlers, spiders), além de outro aqui
                            não tipificado, mas que atue de modo automatizado,
                            tanto para realizar operações massificadas ou para
                            quaisquer outras finalidades.
                        </li>
                        <li>
                            O descumprimento de quaisquer das obrigações aqui
                            estipuladas poderá levar à suspensão das
                            funcionalidades, conforme previsto neste documento.
                        </li>
                        <li>
                            A eventual remoção, bloqueio ou suspensão de
                            qualquer conteúdo ou funcionalidade do site Agres em
                            decorrência de alguma reclamação, deverá ser sempre
                            compreendida como demonstração de boa-fé e intenção
                            de solução amigável de conflitos, jamais como
                            reconhecimento de culpa ou de qualquer infração pela
                            Agres a direito de terceiro.
                        </li>
                        <li>
                            Na ocorrência de danos ao site Agres ou a terceiros,
                            o responsável se compromete a arcar com todas as
                            obrigações de indenizar quem for lesado.
                        </li>
                    </ul>
                </li>
            </ol>

            <ol start="4">
                <li>
                    <strong>Da responsabilidade da Agres</strong>
                    <ul>
                        <li>
                            A Agres não se responsabiliza por quaisquer
                            problemas, bugs, glitches ou funcionamento indevido
                            que eventualmente possa ocorrer nos dispositivos e
                            equipamentos dos usuários decorrentes do uso regular
                            do site Agres.
                        </li>
                        <li>
                            A Agres não garante e nem se responsabiliza pela
                            disponibilidade integral e ininterrupta do site
                            Agres, cujo correto funcionamento depende do acesso
                            e tráfego de dados entre dispositivos e equipamentos
                            dos usuários e os servidores da Agres e de seus
                            provedores de serviços, não possuindo a Agres
                            nenhuma ingerência e não sendo responsável por
                            eventuais falhas no tráfego destes dados e no acesso
                            ao site Agres decorrentes de falhas na internet ou
                            da própria rede e serviços de telecomunicação
                            prestados por provedores de acesso à internet.
                        </li>
                        <li>
                            A Agres não se responsabiliza por qualquer dano
                            direto ou indireto ocasionado por eventos de
                            terceiros, como ataque de hackers, falhas no
                            sistema, no servidor ou na conexão à internet,
                            inclusive por ações de softwares maliciosos como
                            vírus, malwares, e outros que possam, de algum modo,
                            danificar os dispositivos e equipamentos, ou a
                            conexão dos usuários em decorrência do acesso,
                            utilização ou navegação no site Agres, bem como a
                            transferência de dados, arquivos, imagens,
                            fotografias, textos, logotipos, gráficos, áudios ou
                            vídeos contidos neste.
                        </li>
                        <li>
                            A Agres não se responsabiliza pelas atitudes tomadas
                            pelos usuários com base, direta ou indireta, no site
                            Agres, suas regras, informações, dados, pesquisas,
                            relatórios, regulamentos, opiniões, sugestões e
                            outros, concordando o usuário que utilizará sempre
                            bom senso, e agirá de forma ética, baseado na moral
                            e bons costumes.
                        </li>
                        <li>
                            Os usuários não possuem qualquer direito para exigir
                            a disponibilidade do site Agres conforme melhor lhes
                            convêm, tampouco poderão pleitear indenização ou
                            reparação de danos em caso de o site Agres
                            permanecer fora do ar, independente da motivação.
                        </li>
                        <li>
                            A Agres pode, de acordo com seus objetivos de
                            negócio, modificar ou descontinuar (temporária ou
                            permanentemente) a distribuição ou a atualização do
                            site Agres.
                        </li>
                        <li>
                            A Agres pode realizar, a qualquer tempo, ajustes nos
                            serviços prestados, campos adicionais de cadastro, e
                            inserção de dados, e tudo aquilo que julgar
                            necessário para o correto funcionamento do site
                            Agres e seu negócio, não cabendo nenhum aviso prévio
                            aos usuários.
                        </li>
                        <li>
                            A Agres não detém qualquer responsabilidade pela
                            navegação dos usuários nos links externos
                            eventualmente contidos no site Agres, sendo dever
                            dos usuários a leitura de eventuais regras de
                            utilização do site acessado e agir conforme o
                            determinado.
                            <ul>
                                <li>
                                    A Agres não verifica, controla, aprova ou
                                    garante a adequação ou exatidão das
                                    informações ou dados disponibilizados em
                                    tais links, não sendo, portanto, responsável
                                    por prejuízos, perdas ou danos ocorridos
                                    pela visita a tais sites, cabendo ao
                                    interessado verificar a confiabilidade das
                                    informações e dados ali exibidos antes de
                                    tomar alguma decisão ou praticar algum ato.
                                </li>
                            </ul>
                        </li>
                        <li>
                            O site Agres e suas funcionalidades são apresentadas
                            aos usuários na maneira como estão disponíveis,
                            podendo passar por constantes aprimoramentos e
                            atualizações, obrigando-se a Agres a:
                        </li>
                    </ul>
                </li>
            </ol>
            <ul>
                <li>
                    Preservar a funcionalidade do site Agres, com links não
                    quebrados e utilizando layout que respeita a usabilidade e
                    navegabilidade, sempre que possível;
                </li>
                <li>
                    Exibir as funcionalidades de maneira clara, completa,
                    precisa e suficiente de modo que exista a exata percepção
                    das operações realizadas; e
                </li>
                <li>
                    Preservar, por meio do estado da técnica disponível, os
                    dados inseridos nas funcionalidades oferecidas em seu site.
                    <ul>
                        <li>
                            A Agres envida seus esforços para a disponibilidade
                            contínua e permanente do site Agres. No entanto,
                            pode ocorrer, eventualmente, alguma
                            indisponibilidade temporária decorrente de
                            manutenção necessária ou mesmo gerada por motivo de
                            força maior, como desastres naturais, falhas ou
                            colapsos nos sistemas centrais de comunicação e
                            acesso à internet, ou fatos de terceiro que fogem de
                            sua esfera de cuidado e responsabilidade.
                            <ul>
                                <li>
                                    Se isso ocorrer, a Agres fará o que estiver
                                    ao seu alcance para restabelecer o acesso ao
                                    site Agres o mais breve possível, dentro das
                                    limitações técnicas de seus serviços e
                                    serviços de terceiros, dos quais a Agres
                                    depende para ficar online.
                                </li>
                                <li>
                                    Eventuais procedimentos de manutenção que
                                    acarretem a indisponibilidade do site Agres
                                    por longos períodos serão informados por
                                    meio dos canais oficiais de comunicação,
                                    como e-mails, perfis oficiais em mídias
                                    sociais ou telefone de atendimento.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <ol start="5">
                <li>
                    <strong>Privacidade dos usuários no site Agres</strong>
                    <ul>
                        <li>
                            A Agres possui documento próprio, denominado
                            Política de Privacidade, que regula o tratamento
                            dado às informações coletadas no site Agres.
                        </li>
                        <li>
                            A Política de Privacidade é parte integrante e
                            inseparável dos Termos e Condições de Uso do site
                            Agres, e pode ser acessada
                            <a href="#/politica-privacidade" style="text-decoration: underline"
                                target="_blank">aqui</a>. , ou no link encontrado no rodapé do site.
                        </li>
                        <li>
                            Caso alguma disposição da Política de Privacidade
                            conflite com qualquer outra do presente documento,
                            deverá prevalecer o descrito na norma mais
                            específica.
                        </li>
                    </ul>
                </li>
            </ol>
            <ol start="6">
                <li>
                    <strong>Disposições gerais</strong>
                    <ul>
                        <li>
                            O atendimento aos usuários poderá ser realizado
                            pelos canais de atendimento da Agres, pelo e-mail
                            <a href="mailto:relacionamento@agres.com.br">relacionamento@agres.com.br</a>,
                            ou pelo telefone (41) 3132-3300.
                        </li>
                        <li>
                            A tolerância pelo eventual descumprimento de
                            quaisquer das cláusulas e condições do presente
                            documento não constituirá novação das obrigações
                            aqui estipuladas e tampouco impedirá ou inibirá a
                            exigibilidade das mesmas a qualquer tempo.
                        </li>
                        <li>
                            Caso alguma disposição destes Termos e Condições de
                            Uso ou da Política de Privacidade publicadas no site
                            Agres for julgada inaplicável ou sem efeito, o
                            restante de ambos os documentos continua a viger,
                            sem a necessidade de qualquer medida judicial que
                            declare tal assertiva.
                        </li>
                        <li>
                            O site Agres tem como base a data e horários
                            oficiais de Brasília, exceto se indicado de modo
                            contrário.
                        </li>
                    </ul>
                </li>
            </ol>
            <ol start="7">
                <li>
                    <strong>Lei aplicável e jurisdição</strong>
                    <ul>
                        <li>
                            Os Termos e Condições de Uso aqui descritos são
                            interpretados segundo a legislação brasileira, no
                            idioma português, sendo eleito o Foro da Comarca de
                            Pinhais no Estado do Paraná para dirimir qualquer
                            litígio ou controvérsia envolvendo o presente
                            documento, salvo ressalva específica de competência
                            pessoal, territorial ou funcional conforme
                            legislação aplicável.
                        </li>
                    </ul>
                </li>
            </ol>
            <a (click)="voltar()" class="button button__primary">Voltar</a>
        </div>
    </div>
</div>