import { Pipe, PipeTransform } from '@angular/core';
import {
  OpType,
  OpTypeLegacy,
  OperationType,
} from 'app/modules/gestao-operacao/fleet-monitoring/enums/operation-type.enum';
import { match } from 'ts-pattern';

@Pipe({ name: 'appliedQuantity', pure: false })
export class AppliedQuantityPipe implements PipeTransform {
  transform(opType: OperationType, option: string): string {
    switch (option) {
      case 'name':
        return match(opType)
          .with(
            OpType.PLANTING,
            OpTypeLegacy.PLANTING,
            () => 'tasks.operation.appl-vol.name.planting',
          )
          .otherwise(() => 'tasks.operation.appl-vol.name.other-op');

      case 'unit':
        return match(opType)
          .with(OpType.SPRAYING, OpTypeLegacy.SPRAYING, () => 'L')
          .with(OpType.FERTILIZATION, OpTypeLegacy.FERTILIZATION, () => 'kg')
          .with(
            OpType.PLANTING,
            OpTypeLegacy.PLANTING,
            () => 'tasks.operation.appl-vol.planting-unit',
          )
          .otherwise(() => 'L');

      default:
        return '';
    }
  }
}
