import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService } from './alert.service';
import { Alert, AlertType } from './alert.model';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() id = 'agr--alert';
  @Input() fade = true;

  alerts: Alert[] = [];
  alertSubscription: Subscription;
  routeSubscription: Subscription;

  constructor(
    private router: Router,
    private AlertService: AlertService,
  ) {}

  ngOnInit() {
    this.alertSubscription = this.AlertService.onAlert(this.id).subscribe((alert) => {
      if (!alert.message) {
        this.alerts = this.alerts.filter((x) => x.keepAfterRouteChange);
        this.alerts.forEach((x) => delete x.keepAfterRouteChange);
        return;
      }
      this.alerts.push(alert);
      if (alert) {
        setTimeout(() => this.removeAlert(alert), TimeoutEnum.Medium);
      }
    });
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.AlertService.clear(this.id);
      }
    });
  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  removeAlert(alert: Alert) {
    if (!this.alerts.includes(alert)) return;
    if (this.fade) {
      this.alerts.find((x) => x === alert).fade = true;
      setTimeout(() => {
        this.alerts = this.alerts.filter((x) => x !== alert);
      }, TimeoutEnum.VeryShort);
    } else {
      this.alerts = this.alerts.filter((x) => x !== alert);
    }
  }

  cssClass(alert: Alert) {
    if (!alert) return;
    const classes = ['agr--alert', 'agr--alert__dismissable'];
    const alertTypeClass = {
      [AlertType.Success]: 'agr--alert agr--alert__success',
      [AlertType.Error]: 'agr--alert agr--alert__danger',
      [AlertType.Info]: 'agr--alert agr--alert__info',
      [AlertType.Warning]: 'agr--alert agr--alert__warning',
    };

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }
}
