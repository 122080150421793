<app-alert></app-alert>

<div class="sprayrate">
  <div class="sprayrate__header">
    <app-button type="button" color="success" icon="agres-download" title="Botão 1"> </app-button>

    <app-button type="button" color="info" icon="agres-download" title="Botão 2"> </app-button>

    <app-button type="button" color="secondary" icon="agres-download" title="Botão 3"> </app-button>
  </div>

  <div class="sprayrate__tabs">
    <mat-tab-group dynamicHeight animationDuration="1200ms">
      <mat-tab label="Resumo">
        <div class="sprayrate__tabs--content">
          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '55333'],
                  ['tasks.created-on', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>
          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '55333'],
                  ['tasks.created-on', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>
          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '55333'],
                  ['tasks.created-on', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '55333'],
                  ['tasks.created-on', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '55333'],
                  ['tasks.created-on', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '55333'],
                  ['tasks.created-on', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                  ['tasks.tabs.label-event', '55333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Etapas">
        <div class="sprayrate__tabs--content">
          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '2222'],
                  ['tasks.created-on', '2222'],
                  ['tasks.tabs.label-machine', '2222'],
                  ['tasks.tabs.label-event', '2222'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '2222'],
                  ['tasks.created-on', '2222'],
                  ['tasks.tabs.label-machine', '2222'],
                  ['tasks.tabs.label-event', '2222'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '2222'],
                  ['tasks.created-on', '2222'],
                  ['tasks.tabs.label-machine', '2222'],
                  ['tasks.tabs.label-event', '2222'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '2222'],
                  ['tasks.created-on', '2222'],
                  ['tasks.tabs.label-machine', '2222'],
                  ['tasks.tabs.label-event', '2222'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '2222'],
                  ['tasks.created-on', '2222'],
                  ['tasks.tabs.label-machine', '2222'],
                  ['tasks.tabs.label-event', '2222'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Eventos">
        <div class="sprayrate__tabs--content">
          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '111113333'],
                  ['tasks.created-on', '111113333'],
                  ['tasks.tabs.label-machine', '111113333'],
                  ['tasks.tabs.label-event', '111113333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '111113333'],
                  ['tasks.created-on', '111113333'],
                  ['tasks.tabs.label-machine', '111113333'],
                  ['tasks.tabs.label-event', '111113333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '111113333'],
                  ['tasks.created-on', '111113333'],
                  ['tasks.tabs.label-machine', '111113333'],
                  ['tasks.tabs.label-event', '111113333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '111113333'],
                  ['tasks.created-on', '111113333'],
                  ['tasks.tabs.label-machine', '111113333'],
                  ['tasks.tabs.label-event', '111113333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>

          <div class="example-large-box mat-elevation-z4">
            <app-data-box
              [data]="
                [
                  ['tasks.tabs.label-field', '111113333'],
                  ['tasks.created-on', '111113333'],
                  ['tasks.tabs.label-machine', '111113333'],
                  ['tasks.tabs.label-event', '111113333'],
                ] | filterDataBoxArray
              "></app-data-box>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="sprayrate__map" *ngIf="googleMapsApiLoaded">
    <google-map [options]="mapOptions" width="100%" height="50vh"></google-map>
  </div>

  <div class="sprayrate__graphics">
    <h2 class="sprayrate__graphics--title">{{'spray-rate.performance-graph'}}</h2>
    <div id="chart" echarts [options]="chartOptions" [autoResize]="true" style="height: 420px"></div>
  </div>
</div>
