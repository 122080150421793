import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ServiceBase } from './service.base';

@Injectable()
export class ParametrizacaoService extends ServiceBase {
    constructor(public http: HttpClient) {
        super();
    }

    public dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    get() {
        return this.http
            .get(this.UrlServiceV1 + 'parametrizacao', this.obterAuthHeader())
      .pipe(
        map(this.extractData<Record<string, any>>),
        catchError(this.serviceError),
      );
  }
}
