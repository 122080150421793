<div class="panel-container">
  <label class="input-field__label"><i class="agres-mail"></i> E-mail </label>

  <input
    id="inputEmail"
    class="input-email-autocomplete"
    [(ngModel)]="inputValue"
    (keyup)="onInputChange()"
    (focus)="handleVisibility('show')"
    (blur)="saveEmailValueOnFormGroup()"
    type="email"
    [placeholder]="givenPlaceHolder ? givenPlaceHolder : placeholder" />
  <div class="dropdown-panel" *ngIf="toggleDropDown">
    <div *ngFor="let domain of dropDownValues" (click)="handleSelect(domain.value)" class="dropdrown-list">
      <img *ngIf="domain.imgUrl" class="image" src="{{ domain.imgUrl }}" />
      <span class="item">{{ domain.value }}</span>
    </div>
  </div>
</div>
