import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-erro-servidor',
    templateUrl: './erro-servidor.component.html',
    styleUrls: ['./erro-servidor.component.css'],
})
export class ErroServidorComponent {
    constructor(public _location: Location) {}

    voltar() {
        this._location.back();
    }
}
