import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from 'app/app-shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ConfirmacaoModalModule } from '../confirmacao-modal/confirmacao-modal..module';
import { CanalAtendimentoComponent } from './canal-atendimento/canal-atendimento.component';
import { ChamadoComponent } from './chamado/chamado.component';
import { ChamadosAgresComponent } from './chamados-agres/chamados-agres.component';
import { ChamadosFechadosComponent } from './chamados-fechados/chamados-fechados.component';
import { FaqComponent } from './faq/faq.component';
import { MeusChamadosComponent } from './meus-chamados/meus-chamados.component';
import { TutoriaisComponent } from './tutoriais/tutoriais.component';

@NgModule({
    declarations: [
        TutoriaisComponent,
        CanalAtendimentoComponent,
        FaqComponent,
        MeusChamadosComponent,
        ChamadoComponent,
        ChamadosAgresComponent,
        ChamadosFechadosComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        PerfectScrollbarModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        BsDropdownModule.forRoot(),
        PerfectScrollbarModule,
        BrowserModule,
        TooltipModule.forRoot(),
        ConfirmacaoModalModule,
        UiSwitchModule,
        BrowserAnimationsModule,
        AppSharedModule,
    ],
    exports: [],
})
export class AreaTrabalhoModule {}
