import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'app/app.constants';


@Component({
  selector: 'app-tutoriais-talhoes',
  templateUrl: './tutoriais-talhoes.component.html',
  styleUrls: ['./tutoriais-talhoes.component.scss']
})
export class TutoriaisTalhoesComponent implements OnInit {

  public videoPath = '';
  public videoLanguage = ''; 
  

  constructor(
    public translate: TranslateService,
    public router: Router,
    public translateService: TranslateService,
    public toastr: ToastrService,
  ) { }

  changeLanguage(videoTutorial, showEditVideo = false) {
    this.translate.use(videoTutorial);
    localStorage.setItem(
      AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG,
      videoTutorial
    );
    if (showEditVideo) {
      this.videoPath = `./assets/videos/talhao-edit-${videoTutorial}.mp4`;
    } else {
      this.videoPath = `./assets/videos/talhao-${videoTutorial}.mp4`;
    }
  }
  
  showEditFieldInstrutions() {
    this.videoPath = `./assets/videos/talhao-edit-${this.videoLanguage}.mp4`;
  }

  showCreateFieldInstrutions() {
    this.videoPath = `./assets/videos/talhao-${this.videoLanguage}.mp4`;
  }

  ngOnInit(): void {
    this.videoLanguage =
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG) ||
      AppConstants.LANGUAGES.PT_BR;

    this.videoPath = `./assets/videos/talhao-${this.videoLanguage}.mp4`;

  }

}
