import { Component, Input } from '@angular/core';
import { MachineTabModel } from './machine-tab/machine-tab.model';

@Component({
    selector: 'app-machines',
    templateUrl: './machines.component.html',
})
export class MachinesComponent {
    @Input() currentTab: string;
    machineTabInfo: MachineTabModel;

  @Input() set machineTab(value: MachineTabModel) {
        if (value) this.machineTabInfo = value;
    }
}
