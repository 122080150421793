/*
 * Inspinia js helpers:
 *
 * correctHeight() - fix the height of main wrapper
 * detectBody() - detect windows size
 * smoothlyMenu() - add smooth fade in/out on navigation show/ide
 *
 */

declare var jQuery: any;

export function correctHeight() {
    const pageWrapper = jQuery('#page-wrapper');
    const navbarHeight = jQuery('nav.navbar-default').height();
    const wrapperHeight = pageWrapper.height();

    if (navbarHeight > wrapperHeight) {
        pageWrapper.css('min-height', navbarHeight + 'px');
    }

    if (navbarHeight <= wrapperHeight) {
        if (navbarHeight < jQuery(window).height()) {
            pageWrapper.css('min-height', jQuery(window).height() + 'px');
        } else {
            pageWrapper.css('min-height', navbarHeight + 'px');
        }
    }

    if (jQuery('body').hasClass('fixed-nav')) {
        if (navbarHeight > wrapperHeight) {
            pageWrapper.css('min-height', navbarHeight + 'px');
        } else {
            pageWrapper.css('min-height', jQuery(window).height() - 60 + 'px');
        }
    }
}

export function detectBody() {
    if (jQuery(document).width() < 769) {
        jQuery('body').addClass('body-small');
    } else {
        jQuery('body').removeClass('body-small');
    }

    checkSideMenu();
}

export function checkSideMenu() {
    const isDesviceDesktop = $('#spanDeviceIsDesktop').css('display');
    if (jQuery('body').hasClass('body-small')) {
        if (isDesviceDesktop == 'none') {
            jQuery('.body-small').find('#wrapper').find('.sidebar-collapse').find('.social-media-footer > a.hide-minibar').fadeOut();
            hideIsofarmImage();
        } else {
            showIsofarmImage();
        }
    } else {
        if (isDesviceDesktop == 'none') {
            jQuery('.bg-isofarm').find('#wrapper').find('.sidebar-collapse').find('.social-media-footer > a.hide-minibar').fadeIn();
            hideIsofarmImage();
        } else {
            showIsofarmImage();
        }
    }
}

function showIsofarmImage() {
    jQuery('.img-agres-minimized').hide();
    jQuery('.img-isofarm-minimized').show();
}

function hideIsofarmImage() {
    jQuery('.img-agres-minimized').show();
    jQuery('.img-isofarm-minimized').hide();
}

export function smoothlyMenu() {
    if (
        !jQuery('body').hasClass('body-small') ||
        jQuery('body').hasClass('body-small')
    ) {
        // Hide menu in order to smoothly turn on when maximize menu
        jQuery('#side-menu').hide();
        // For smoothly turn on menu
        setTimeout(function () {
            jQuery('#side-menu').fadeIn(400);
        }, 200);
    } else if (jQuery('body').hasClass('fixed-sidebar')) {
        jQuery('#side-menu').hide();

        setTimeout(function () {
            jQuery('#side-menu').fadeIn(400);
        }, 100);
    } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        jQuery('#side-menu').removeAttr('style');
    }

    checkSideMenu();
}
