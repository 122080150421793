<div *ngIf="filesRequireds" class="message">
  <span>{{ message | translate }}</span>
</div>
<div class="modal__body">
  <div class="modal__body--form">
    <fieldset [disabled]="false">
      <div class="dropZone">
        <label for="files" class="dropZone__wrapper">
          <div [ngClass]="fileSelected ? 'dropZone__wrapper--uploadCheck' : 'dropZone__wrapper--upload'">
            <i
              [ngClass]="
                fileSelected
                  ? 'dropZone__wrapper--uploadCheck---icon agres-check'
                  : 'dropZone__wrapper--upload---icon agres-upload'
              "></i>
          </div>
          <div class="dropZone__wrapper--title">
            <div *ngIf="fileSelected; else selectFile">
              <p>{{ 'tasks.recommendation_map.selected-file' | translate }}</p>
            </div>
            <ng-template #selectFile>
              <p>{{ 'global.upload-file-title' | translate }}</p>
            </ng-template>
          </div>
        </label>
        <input
          id="files"
          class="dropZone"
          (change)="handleFileUpload($event)"
          type="file"
          accept=".shp,.dbf,.shx"
          #inputFileSHP
          multiple />
      </div>
    </fieldset>
  </div>
</div>
<div class="modal__linha"></div>
<div class="modal__footer">
  <app-button
    type="button"
    color="danger"
    icon="agres-close"
    data-bs-dismiss="modal"
    (click)="hideModal()"
    title="{{ 'global.cancel' | translate }}">
  </app-button>
  <app-button
    type="button"
    [color]="fileSelected ? 'success' : 'warning'"
    [icon]="fileSelected ? 'agres-check' : 'agres-upload'"
    data-bs-dismiss="modal"
    [disabled]="!allFiles"
    (click)="createArrayToUpload()"
    title="{{ 'global.import' | translate }}">
  </app-button>
</div>
