import { Component, Input } from '@angular/core';
import { LoadingSpinnerService } from 'app/core/services/loading-spinner.service';

@Component({
    selector: 'app-spinner-view-component',
    templateUrl: './spinner-view.component.html',
    styleUrls: ['./spinner-view.component.scss'],
})
export class SpinnerViewComponent {
    @Input() public translateKeyToDisplay: string;

    constructor(public loader: LoadingSpinnerService) { }
}
