<div class="dashboard">
  <div class="a-card" [ngClass]="{ 'a-card__disabbled': disabled }">
    <div class="a-card__content">
      <i [class]="iconClass"></i>
      <h1>{{ title }}</h1>
      <p>{{ description }}</p>
      <app-button
        color="card"
        icon="agres-action"
        (click)="handleClick($event)"
        title="{{ 'global.view' | translate }}"
        iconClass="agres-action"></app-button>
    </div>
  </div>
</div>
<div class="dashboardMobile" [ngClass]="{ cardDashboard__disabbled: disabled }">
  <div class="cardDashboard" (click)="handleClick($event)">
    <div class="cardDashboard__content">
      <div class="cardDashboard__content--info">
        <div class="cardDashboard__content--info---icon">
          <i [class]="iconClass"></i>
        </div>
        <div class="cardDashboard__content--info---description">
          <h1>{{ title }}</h1>
          <p>{{ description }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
