import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu-pwa',
  templateUrl: './menu-pwa.component.html',
  styleUrls: ['./menu-pwa.component.scss'],
})
export class MenuPwaComponent {
  constructor(public translateService: TranslateService) {}
}
