import _gitVersion from './git-version.json';
import _isolinkConfig from './isolink-config.json';

export interface GitVersion {
    version: string;
}

export interface IsoLinkConfig {
    version: string;
    configuration: {
        channel: string;
        gw_app_version: string;
        authMethod: string;
        enterprise: string;
        timeOfVariableStatusMessage: number;
        timeOfDynamicMessage: number;
        timeOfStaticMessage: number;
        time_of_send_message_ibm: number;
        cleanStart: true;
        sessionExpiry: number;
        keepAlive: number;
        deviceType: {
            gateway: string;
            device: string;
        };
        interfaceUsed: string;
        connectionScript: string;
        network_management: {
            connection_type: string;
            connection_options: {
                apn: string;
                'gsm.user': string;
                'gsm.password': string;
            };
            preferred_interface: string;
            connection_name: string;
            connectivity_check_period: number;
        };
        deleteNVGsEnable: true;
        deleteNVGsDays: number;
        compressEnable: true;
        compressAfterDays: number;
        compressDeleteAfterDays: number;
        threshold_burst: number;
        threshold_max_db_messages: number;
        burst_messages: number;
        timeout_last_event_id: number;
    };
}

export const gitVersion = _gitVersion as GitVersion;
export const isolinkConfig = _isolinkConfig as IsoLinkConfig;
