import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    TemplateRef,
} from '@angular/core';
import { ConfirmacaoModalService } from 'app/core/services/confirmacaoModal.service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'app/core/services/analytics.service';
import { NvgHistService } from 'app/core/services/nvg-hist.service';
import { ToastrService } from 'ngx-toastr';
import { _roleEnumUserClass } from 'app/utils/role.utils';

@Component({
    selector: 'app-nvg-card-list',
    templateUrl: './nvg-card-list.component.html',
    styleUrls: ['./nvg-card-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NvgCardListComponent {
    @Input() dataNvgs: Array<any>;
    @Input() conteudo: string;
    @Input() valorRecebidoBkgndMap: any;
    @Input() cardImageTemplate: TemplateRef<any>;
    @Output() deleteCardId: EventEmitter<string> = new EventEmitter();
    @Output() viewCardNvgId: EventEmitter<string> = new EventEmitter();

    menuOpen = [];
    idFileSelectedToDelete: any;
    selectedMenuId = null;
    isoview: string = 'isoview';
    isofarm: string = 'isofarm';
    agronavepro: string = 'agronavepro';
    imageRaster: Array<string> = [];
    imageBackground: string;

    public mostrarModalConfirmacao = false;
    public mensagemModalConfirmacao = '';
    public tipoModalConfirmacao = '';
    public tituloModalConfirmacao = '';
    public modalTranslateButtonConfirmText = '';
    public modalTranslateButtonCancelText = '';
    public isFreetier: boolean = true;
    public mostrarModalCompatibilidade = false;
    public mensagemModalCompatibilidade = '';
    public tipoModalCompatibilidade = '';
    public tituloModalCompatibilidade = '';
    public idArquivoSelecionadoCompatibilidade: any;
    public nvgArrayNotifySupported = [];

    STATUS_CONFIRMED_DELETE = 'confirmado';

    constructor(
        public confirmacaoModalService: ConfirmacaoModalService,
        public translateService: TranslateService,
        public nvgHistService: NvgHistService,
        public analyticsService: AnalyticsService,
        public toastr: ToastrService
    ) {}
}
