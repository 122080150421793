import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ServiceBase } from './service.base';

@Injectable()
export class FileManagerService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  public dataSource = new BehaviorSubject<any>([]);
  currenteData = this.dataSource.asObservable();

  save(params) {
    return this.http
      .post(
        this.UrlServiceV1 + 'files/upload',
        params,
        this.obterAuthHeader(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getById(id: string) {
    return this.http
      .get(this.UrlServiceV1 + 'files/' + id, this.obterAuthHeader())
      .pipe(
        map(this.extractData<Record<string, any>>),
        catchError(this.serviceError),
      );
  }

  signedUrl(files: Array<string>) {
    return this.http
      .post(
        this.UrlServiceV1 + 'files/signedUrl',
        files,
        this.obterAuthHeader(),
      )
      .pipe(
        map(this.extractData<Record<string, any>>),
        catchError(this.serviceError),
      );
  }

  getStorageData(url: string, fileName: string): Promise<File> {
    return new Promise<any>((resolve) => {
      fetch(url).then((response) =>
        response.arrayBuffer().then((arrayBuffer) => {
          const file = new File([arrayBuffer], fileName);
          resolve(file);
        }),
      );
    });
  }

  downloadFile(urlFile: string, fileName: string): void {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', urlFile, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.status === 200) {
        const url = window.URL.createObjectURL(xhr.response);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      }
    };

    xhr.send();
  }
}
