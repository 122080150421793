import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServiceBase } from './service.base';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CanalAtendimentoService extends ServiceBase {
    constructor(public http: HttpClient) {
        super();
    }

    public dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    sendFormAtendimento(params) {
        return this.http
            .post(
                this.UrlServiceV1 + 'contact-form',
                params,
                this.obterAuthHeader()
            )
            .pipe(map(this.extractData), catchError(this.serviceError));
    }
}
