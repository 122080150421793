import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

  @Input() src: string;
  @Input() styleClass: string;
  @Input() height: string;
  @Input() width: string;

  constructor() { }
}
