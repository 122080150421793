<div class="a-form a-valid">
  <label>
    {{ inputText }}
    <span class="a-form__required">{{ labelText }}</span>
  </label>
  <div class="a-form__group">
    <span class="a-form__group--icon">
      <i [class]="iconClass"></i>
    </span>
    <input
      [type]="type"
      [disabled]="disabled"
      class="a-form__group--input"
      [placeholder]="placeholder"
      [name]="name"
      [value]="value" />
  </div>
</div>
