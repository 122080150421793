import { Trabalho } from './eventoISOBUS';

export const OPERATION = {
  PULVERIZACAO: 'pulverizacao',
  COLHEITA: 'colheita',
  PLANTIO: 'plantio',
  ADUBACAO: 'adubacao',
  IRRIGACAO: 'irrigacao',
  NAVEGACAO: 'navegacao',
  OUTROS: 'outros',
  FRUTICULTURA: 'fruticultura',
};
export class Evento {
  _id: string;
  deviceId: string;
  speed: string;
  timestamp: string;
  endTimestamp: string;
  value: string;
  trabalhoId: string;
  trabalho: Trabalho;
  e: E;
  operation: keyof typeof OPERATION | Operation;
  evt_name: string;
  timestamp_etapa: string;
  guias: Array<any>;
  marcadores: Array<any>;
  travel_dist: any;
  tempoTotalOperacao: any;
  crop_name: any;
  timezone: any;
  serialNumber: any;
  serial_number: any;
  cover_area: any;
  overlap_info: any;
  avg_speed: any;
  tempoEfetivoOperacao: any;
  tempoOciosoOperacao: any;
  tal_timestamp: any;
  machine_name: any;
  percentTotalOperation: number;
  model: string;
  crop_type: string;
  nozz_number: string;
  nozz_spacing: string;
  app_version: string;
  seeding_license?: boolean;
}

export class E {
  crop_name: string;
  crop_timestamp: string;
  evt_name: string;
  machine_model: string;
  machine_name: string;
  manufacturer: string;
  serial_number: string;
  nozz_number: string;
  operator: string;
  nomeCultura: string;
  nozz_per_section: string;
  nozz_spacing: string;
  app_version: string;
  percent_transp: string;
  area_transp: string;
  average_speed: string;
}

export class Operation {
  app_vol: string;
  conv_rpm: string;
  cover_area: string;
  disk_rpm: string;
  impl_width: string;
  inst_rate: string;
  op_type: string;
  sec_number: string;
  sec_state: string;
  tot_op_e: string;
  tot_op_t: string;
  travel_dist: string;
  avg_rate?: string | number;
}
