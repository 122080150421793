import { ListaTalhoesComponent } from './modules/appviews/my-fields/lista-talhoes/lista-talhoes.component';
import { RegisterComponent } from './modules/appviews/register/register.component';
import { Routes } from '@angular/router';
import { UserPermissions, UserResources } from 'app/shared/models/role';
import { AuthGuard } from './core/guards/auth-guard.service';
import { ForgotPasswordComponent } from './modules/appviews/password/forgot.component';
import { LoginPageComponent } from './modules/appviews/auth/auth.component';
import { DashboardComponent } from './modules/appviews/dashboard/dashboard.component';
import { PoliticaPrivacidadeComponent } from './modules/appviews/politica-privacidade/politica-privacidade.component';
import { ResetPasswordComponent } from './modules/appviews/password/reset.component';
import { TermosUsoComponent } from './modules/appviews/termos-uso/termos-uso.component';
import { CanalAtendimentoComponent } from './modules/area-trabalho/canal-atendimento/canal-atendimento.component';
import { ChamadoComponent } from './modules/area-trabalho/chamado/chamado.component';
import { ChamadosAgresComponent } from './modules/area-trabalho/chamados-agres/chamados-agres.component';
import { ChamadosFechadosComponent } from './modules/area-trabalho/chamados-fechados/chamados-fechados.component';
import { FaqComponent } from './modules/area-trabalho/faq/faq.component';
import { MeusChamadosComponent } from './modules/area-trabalho/meus-chamados/meus-chamados.component';
import { TutoriaisComponent } from './modules/area-trabalho/tutoriais/tutoriais.component';
import { ErroServidorComponent } from './modules/error/erro-servidor/erro-servidor.component';
import { ManutencaoPageComponent } from './modules/error/manutencao-page/manutencao-page.component';
import { NaoEncontradaComponent } from './modules/error/nao-encontrada/nao-encontrada.component';
import { ContornosComponent } from './modules/gestao-mapas/contornos/contornos.component';
import { MapasRecomendacaoComponent } from './modules/gestao-mapas/mapas-recomendacao/mapas-recomendacao.component';
import { FleetMonitoringComponent } from './modules/gestao-operacao/fleet-monitoring/fleet-monitoring.component';
import { GestaoArquivoComponent } from './modules/gestao-operacao/gestao-arquivo/gestao-arquivo.component';
import { GestaoNavegacaoComponent } from './modules/gestao-operacao/gestao-navegacao/gestao-navegacao.component';
import { MapaTempoComponent } from './modules/gestao-operacao/mapa-tempo/mapa-tempo.component';
import { PrevisaoTempoComponent } from './modules/gestao-operacao/previsao-tempo/previsao-tempo.component';
import { ResultadoNavegacaoComponent } from './modules/gestao-operacao/resultado-navegacao/resultado-navegacao.component';
import { BasicLayoutComponent } from './shared/components/layouts/basicLayout.component';
import { BlankLayoutComponent } from './shared/components/layouts/blankLayout.component';
import { ResumosComponent } from './modules/gestao-operacao/resumos/resumos.component';
import { MeusEquipamentosComponent } from './modules/appviews/administrativo/meus-equipamentos/meus-equipamentos.component';
import { NovaFuncionalidadePageComponent } from './modules/error/nova-funcionalidade-page/nova-funcionalidade-page.component';
import { EditarTalhoesComponent } from './modules/appviews/my-fields/editar-talhoes/editar-talhoes.component';
import { CriaTalhoesComponent } from './modules/appviews/my-fields/cria-talhoes/cria-talhoes.component';
import { ListagemTarefaTalhaoComponent } from './modules/appviews/my-fields/gerenciamento-de-talhoes/listagem-tarefa-talhao/listagem-tarefa-talhao.component';
import { DetalhesDaTarefasTalhoesComponent } from './modules/appviews/my-fields/gerenciamento-de-talhoes/detalhes-da-tarefa-talhoes/detalhes-da-tarefa-talhoes.component';
import { MyProfileComponent } from './modules/management/my-profile/my-profile.component';
import { CriarEditarTarefasTalhaoComponent } from './modules/appviews/my-fields/gerenciamento-de-talhoes/criarEditar-tarefa-talhao/criarEditar-tarefa-talhao.component';
import { RedirectAndShowToastRGuard } from './core/guards/redirect-and-show-toastr-guard.service';
import { DesignSystemComponent } from './shared/design-system/design-system.component';
import { RealtimeDeviceMonitoringComponent } from './modules/gestao-operacao/fleet-monitoring/realtime-device-monitoring/realtime-device-monitoring.component';
import { VisualizarTalhoesComponent } from './modules/appviews/my-fields/visualizar-talhoes/visualizar-talhoes.component';
import { DeleteAccountComponent } from './modules/management/my-profile/delete-account/delete-account.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    pathMatch: 'full',
    data: {
      breadcrumb: 'breadcrumb.title-dashboard',
    },
    children: [
      { path: '', component: DashboardComponent },
      {
        path: 'monitoramento-frota',
        component: FleetMonitoringComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-dashboard',
          authorization: {
            resource: UserResources.FLEET_MONITORING,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
    ],
    canActivate: [AuthGuard],
  },

  {
    path: 'login',
    component: BlankLayoutComponent,
    children: [{ path: '', component: LoginPageComponent }],
  },
  {
    path: 'menu',
    canActivate: [AuthGuard],
    component: BasicLayoutComponent,
    children: [{ path: '', component: DashboardComponent }],
  },
  {
    path: 'register',
    component: BlankLayoutComponent,
    children: [{ path: '', component: RegisterComponent }],
  },
  {
    path: 'termos-uso',
    component: BlankLayoutComponent,
    children: [{ path: '', component: TermosUsoComponent }],
  },
  {
    path: 'politica-privacidade',
    component: BlankLayoutComponent,
    children: [{ path: '', component: PoliticaPrivacidadeComponent }],
  },
  {
    path: 'forgot',
    component: BlankLayoutComponent,
    children: [{ path: '', component: ForgotPasswordComponent }],
  },
  {
    path: 'reset',
    component: BlankLayoutComponent,
    children: [{ path: '', component: ResetPasswordComponent }],
  },
  { path: 'manage', redirectTo: '', pathMatch: 'full' },
  {
    path: 'manage',
    component: BasicLayoutComponent,
    children: [
      {
        path: 'my-profile',
        component: MyProfileComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'profile.title',
          authorization: {
            resource: UserResources.USER_PROFILE,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
      {
        path: 'delete-account',
        component: DeleteAccountComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'profile.title',
          authorization: {
            resource: UserResources.USER_PROFILE,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
    ],
  },
  {
    path: 'user',
    component: BasicLayoutComponent,
    children: [
      {
        path: 'fields',
        component: ContornosComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'talhoes.page-title',
          authorization: {
            resource: UserResources.DEVICE_ADMINISTRATION,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
    ],
  },
  {
    path: 'suporte-tecnico',
    component: BasicLayoutComponent,
    children: [
      { path: 'tutoriais', component: TutoriaisComponent },
      { path: 'canal-atendimento', component: CanalAtendimentoComponent },
      { path: 'chamados-agres', component: ChamadosAgresComponent },
      { path: 'chamados-fechados', component: ChamadosFechadosComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'faq/meus-chamados', component: MeusChamadosComponent },
      { path: 'faq/meus-chamados/:id', component: ChamadoComponent },
    ],
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'breadcrumb.title-support',
      authorization: {
        resource: UserResources.CUSTOMER_SUPPORT,
        permission: UserPermissions.NAVIGATE,
      },
    },
  },
  {
    path: 'gestao-talhoes',
    component: BasicLayoutComponent,
    children: [
      {
        path: 'cadastro-talhao',
        component: ContornosComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-fields-add',
          authorization: {
            resource: UserResources.FIELD_ADMINISTRATION,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
      {
        path: 'mapas-recomendacao',
        component: MapasRecomendacaoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-map-recommendation',
          authorization: {
            resource: UserResources.PRESCRIPTION_MAPS,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
    ],
    data: {
      breadcrumb: 'breadcrumb.title-fields-management',
    },
  },
  {
    path: 'gestao-operacao',
    redirectTo: 'monitoramento-frota',
    pathMatch: 'full',
    data: {
      breadcrumb: 'breadcrumb.title-operation-management',
      authorization: {
        resource: UserResources.FLEET_MONITORING,
        permission: UserPermissions.NAVIGATE,
      },
    },
  },
  {
    path: 'administrativo',
    component: BasicLayoutComponent,
    children: [
      {
        path: 'meus-equipamentos',
        component: MeusEquipamentosComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-jobs',
          authorization: {
            resource: UserResources.NAVIGATION_RESULT,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
    ],
  },
  {
    path: 'gestao-operacao',
    component: BasicLayoutComponent,
    children: [
      {
        path: 'monitoramento-frota',
        component: FleetMonitoringComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-monitoring-fleet',
          authorization: {
            resource: UserResources.FIELD_ADMINISTRATION,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
      {
        path: 'monitoramento-frota/device/:id',
        component: RealtimeDeviceMonitoringComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-monitoring-fleet',
          authorization: {
            resource: UserResources.FIELD_ADMINISTRATION,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
      {
        path: 'gestao-navegacao',
        component: GestaoNavegacaoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-task-management',
          authorization: {
            resource: UserResources.NAVIGATION_RESULT,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
      {
        path: 'gestao-arquivo',
        component: GestaoArquivoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-task-report',
          authorization: {
            resource: UserResources.NAVIGATION_RESULT,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
      {
        path: 'previsao-tempo',
        component: PrevisaoTempoComponent,
        data: {
          breadcrumb: 'breadcrumb.title-weather',
          authorization: {
            resource: UserResources.WEATHER_FORECAST,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
      {
        path: 'resultado-navegacao',
        component: ResultadoNavegacaoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-task-result',
          authorization: {
            resource: UserResources.NAVIGATION_RESULT,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
      {
        path: 'mapa-tempo',
        component: MapaTempoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-weather-map',
          authorization: {
            resource: UserResources.WEATHER_FORECAST,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },

      {
        path: 'resumos',
        component: ResumosComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-resume',
          authorization: {
            resource: UserResources.NAVIGATION_RESULT,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
    ],
  },
  {
    path: 'my-fields',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        component: ListaTalhoesComponent,
        data: { breadcrumb: 'breadcrumb.title-fields' },
      },
      {
        path: 'edit/:fieldId',
        component: EditarTalhoesComponent,
        data: { breadcrumb: 'breadcrumb.title-fields-edit' },
      },
      {
        path: 'criar-talhao',
        component: CriaTalhoesComponent,
        data: { breadcrumb: 'breadcrumb.title-fields-add' },
      },
      {
        path: 'ver-talhao/:fieldId',
        component: VisualizarTalhoesComponent,
        data: { breadcrumb: 'breadcrumb.title-fields-eye' },
      },
      {
        path: 'gerenciamento-de-talhoes',
        component: BlankLayoutComponent,
        children: [
          {
            path: 'listagem-tarefa-talhao/:fieldId',
            component: ListagemTarefaTalhaoComponent,
            data: { breadcrumb: 'breadcrumb.title-fields-taks-list' },
          },
          {
            path: 'detalhes-da-tarefa-talhao/:operationFieldId',
            component: DetalhesDaTarefasTalhoesComponent,
            data: { breadcrumb: 'breadcrumb.title-fields-taks-detail' },
          },
          {
            path: 'criar-operacoes',
            canActivate: [RedirectAndShowToastRGuard],
            data: {
              toastrMesage: 'global.validate.operation-required-text',
              breadcrumb: 'breadcrumb.title-operation-create',
            },
            component: ListaTalhoesComponent,
          },
          {
            path: 'criar-tarefa-talhao/:fieldId',
            component: CriarEditarTarefasTalhaoComponent,
            data: { breadcrumb: 'breadcrumb.title-fields-taks-create' },
          },
          {
            path: 'editar-operacoes',
            canActivate: [RedirectAndShowToastRGuard],
            data: {
              toastrMesage: 'global.validate.operation-required-text',
            },
            component: ListaTalhoesComponent,
          },
          {
            path: 'editar-operacoes/:operationId',
            component: CriarEditarTarefasTalhaoComponent,
            data: { breadcrumb: 'breadcrumb.title-operation-edit' },
          },
        ],
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'breadcrumb.title-fields-management',
          authorization: {
            resource: UserResources.DEVICE_ADMINISTRATION,
            permission: UserPermissions.NAVIGATE,
          },
        },
      },
    ],
    canActivate: [AuthGuard],
    data: {
      authorization: {
        resource: UserResources.FIELD_ADMINISTRATION,
        permission: UserPermissions.NAVIGATE,
      },
    },
  },
  {
    path: 'nova-funcionalidade',
    component: BlankLayoutComponent,
    children: [{ path: '', component: NovaFuncionalidadePageComponent }],
  },
  {
    path: 'acesso-negado',
    component: BlankLayoutComponent,
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'erro-servidor',
    component: BlankLayoutComponent,
    children: [{ path: '', component: ErroServidorComponent }],
  },
  {
    path: 'nao-encontrada',
    component: BlankLayoutComponent,
    children: [{ path: '', component: NaoEncontradaComponent }],
  },
  {
    path: 'manutencao',
    component: ManutencaoPageComponent,
    children: [
      {
        path: '',
        component: ManutencaoPageComponent,
      },
    ],
  },
  {
    path: 'design-system',
    component: BasicLayoutComponent,
    children: [{ path: '', component: DesignSystemComponent }],
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'breadcrumb.title-design-system',
      authorization: {
        resource: UserResources.FIELD_ADMINISTRATION,
        permission: UserPermissions.NAVIGATE,
      },
    },
  },
  {
    path: '**',
    component: NaoEncontradaComponent,
    children: [
      {
        path: 'nao-encontrada',
        component: NaoEncontradaComponent,
      },
    ],
  },
];
