<ng-container *ngIf="isSidebarOpen">
  <div class="overlay"></div>
  <aside class="sidebar">
    <div class="sidebar__container">
      <div class="sidebar__container--header">
        <div class="sidebar__container--header---logo">
          <img src="./assets/images/default/logo-isofarm-white.svg" alt="Agres" />
          <a class="sidebar__container--header---logo----close" (click)="close()"> <i class="agres-close"></i></a>
        </div>
      </div>
      <nav class="sidebar__container--nav">
        <ul class="sidebar__container--nav---list">
          <li>
            <a [routerLink]="'/'"> <i class="agres-app"></i> {{ 'sidebar.title-dashboard' | translate }} </a>
          </li>
          <li
            *ngIf="
              auth.forbids(auth.permissions.NAVIGATE, auth.resources.NAVIGATION_RESULT);
              else enable_navigation_result
            "
            [ngClass]="{ active: activeRoute('/gestao-operacao/gestao-arquivo') }">
            <a [routerLink]="'/gestao-operacao/gestao-arquivo'">
              <i class="agres-navigation-report"></i> {{ 'sidebar.title-navigation-report' | translate }}
              <span class="sidebar__container--nav---list----premium">{{
                'sidebar.title-user-premium' | translate
              }}</span></a
            >
          </li>
          <ng-template #enable_navigation_result>
            <li [ngClass]="{ active: activeRoute('/gestao-operacao/gestao-arquivo') }">
              <a [routerLink]="['/gestao-operacao/gestao-arquivo']">
                <i class="agres-navigation-report"></i> {{ 'sidebar.title-navigation-report' | translate }}
              </a>
            </li>
          </ng-template>
          <li
            *ngIf="
              auth.forbids(auth.permissions.NAVIGATE, auth.resources.FIELD_ADMINISTRATION);
              else enable_fleet_monitoring
            "
            [ngClass]="{ active: activeRoute('/gestao-operacao/monitoramento-frota') }">
            <a [routerLink]="'/gestao-operacao/monitoramento-frota'">
              <i class="agres-fleet-monitoring"></i> {{ 'sidebar.title-monitoring-fleet' | translate }}
              <span class="sidebar__container--nav---list----premium">{{
                'sidebar.title-user-premium' | translate
              }}</span></a
            >
          </li>
          <ng-template #enable_fleet_monitoring>
            <li [ngClass]="{ active: activeRoute('/gestao-operacao/monitoramento-frota') }">
              <a [routerLink]="['/gestao-operacao/monitoramento-frota']">
                <i class="agres-fleet-monitoring"></i> {{ 'sidebar.title-monitoring-fleet' | translate }}
              </a>
            </li>
          </ng-template>
          <li
            *ngIf="auth.forbids(auth.permissions.NAVIGATE, auth.resources.FIELD_ADMINISTRATION); else enable_field"
            [ngClass]="{ active: activeRoute('/my-fields') }">
            <a [routerLink]="'/my-fields/'">
              <i class="agres-field"></i> {{ 'sidebar.title-fields' | translate }}
              <span class="sidebar__container--nav---list----premium">{{
                'sidebar.title-user-premium' | translate
              }}</span>
            </a>
          </li>
          <ng-template #enable_field>
            <li [ngClass]="{ active: activeRoute('/my-fields') }">
              <a [routerLink]="'/my-fields/'"><i class="agres-field"></i> {{ 'sidebar.title-fields' | translate }}</a>
            </li>
          </ng-template>
          <li [ngClass]="{ active: activeRoute('/administrativo/meus-equipamentos') }">
            <a [routerLink]="'/administrativo/meus-equipamentos'">
              <i class="agres-my-jobs"></i> {{ 'sidebar.title-jobs' | translate }}
            </a>
          </li>
          <li
            *ngIf="!isRoleFreetier()"
            [ngClass]="{ active: activeRoute('/gestao-talhoes') }"
            [ngClass]="{ active: activeRoute('/mapa-recomendacao') }"
            hidden>
            <a [routerLink]="['/gestao-talhoes/mapas-recomendacao']">
              <i class="agres-device-mobile-vibration"></i> {{ 'sidebar.title-recommendation-maps' | translate }}
            </a>
          </li>
          <li [ngClass]="{ active: activeRoute('/gestao-operacao/previsao-tempo') }">
            <a [routerLink]="['/gestao-operacao/previsao-tempo']">
              <i class="agres-sun"></i> {{ 'sidebar.title-weather' | translate }}
            </a>
          </li>
          <li [ngClass]="{ active: activeRoute('/manage/my-profile') }">
            <a [routerLink]="'/manage/my-profile'">
              <i class="agres-user"></i> {{ 'sidebar.title-profile' | translate }}
            </a>
          </li>
          <li [ngClass]="{ active: activeRoute('/suporte-tecnico/tutoriais') }">
            <a [routerLink]="['/suporte-tecnico/tutoriais']">
              <i class="agres-support"></i> {{ 'sidebar.title-support' | translate }}
            </a>
          </li>
          <li hidden *ngIf="isAdmin()" [ngClass]="{ active: activeRoute('/suporte-tecnico/chamados-agres') }">
            <a [routerLink]="['/suporte-tecnico/chamados-agres']">
              <i class="agres-agres"></i> {{ 'sidebar.title-support' | translate }}
            </a>
          </li>
          <li routerLinkActive="active">
            <a (click)="goToLink('https://agres.com.br/quem_somos/')" target="_blank">
              <i class="agres-agres"></i> {{ 'sidebar.title-about' | translate }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <footer>
      <ul class="sidebar__container--nav---list">
        <li *ngIf="environmentName === 'PROD'" class="sidebar__container--nav---list----footer">
          <p>IsoFarm - {{ 'global.copyright' | translate }}</p>
          <button (click)="home()">
            <p>{{ versaoGit }} - {{ environmentName }}</p>
          </button>
        </li>
        <li *ngIf="environmentName === 'DEV'" class="sidebar__container--nav---list----dev">
          <p>IsoFarm - {{ 'global.copyright' | translate }}</p>
          <button (click)="designSystem()">
            <p>{{ versaoGit }} - {{ environmentName }}</p>
          </button>
        </li>
        <li *ngIf="environmentName === 'STAGE'" class="sidebar__container--nav---list----stg">
          <button (click)="home()">
            <p>&copy; IsoFarm - {{ 'global.copyright' | translate }}</p>
          </button>
          <p>{{ versaoGit }} - {{ environmentName }}</p>
        </li>
      </ul>
    </footer>
  </aside>
</ng-container>
