import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { AppConstants } from 'app/app.constants';
import { FormUploadFileComponent } from './form-upload-file/form-upload-file.component';
import { SupportPageService } from 'app/core/services/support-page.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { ConfirmacaoModalService } from 'app/core/services/confirmacaoModal.service';
import { AuthorizationService } from 'app/core/services/authorization.service';
import { UserPermissions, UserResources } from 'app/shared/models/role';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import {
  FileData,
  FileDataCategory,
  FileInfo,
  FileMetaData,
  ItemFileSelected,
  OrderedCategory,
  ProductItem,
} from 'app/shared/interfaces/upload-file-support-page';

interface LanguageData {
  fileName: string;
  description: string;
}

interface FileItem {
  date: string;
  version: string;
  filePath: string;
  productLanguages: string[];
  [languageCode: string]: LanguageData | any;
}
@Component({
  selector: 'app-download-file-page',
  templateUrl: './download-file-page.component.html',
  styleUrls: ['./download-file-page.component.scss'],
})
export class DownloadFilePageComponent implements OnInit, OnDestroy {
  showModalSubject: Subject<boolean> = new Subject();
  selectedProduct: string | null = null;
  products: string[] = [];
  guides = [];
  softwares = [];
  manuals = [];
  others = [];

  languageSelected: string;
  canUpload: boolean = false;
  isEmpty: boolean = false;
  fileExtension: string;
  selectedItemInfo: {
    fileName: string;
    productCategory: string;
    description: string;
    softwares: FileMetaData[] | [];
    guides: FileMetaData[] | [];
    others: FileMetaData[] | [];
    manuals: FileMetaData[] | [];
  } = null;

  selectedFileInfo: {
    category?: string;
    fileName?: string;
    filePath?: string;
    fileVersion?: string;
    fileDate?: string;
    productCategory?: string;
  } = {};

  private readonly productLanguages = [
    {
      code: 'PT',
      name: 'Português',
      flag: './../../../../assets/images/support-page/flags/brazil.png',
    },
    {
      code: 'EN',
      name: 'English',
      flag: './../../../../assets/images/support-page/flags/usa.png',
    },
    {
      code: 'ES',
      name: 'Español',
      flag: './../../../../assets/images/support-page/flags/spain.png',
    },
    {
      code: 'IT',
      name: 'Italiano',
      flag: './../../../../assets/images/support-page/flags/italy.png',
    },
    {
      code: 'FR',
      name: 'Français',
      flag: './../../../../assets/images/support-page/flags/france.png',
    },
    {
      code: 'DE',
      name: 'Deutsch',
      flag: './../../../../assets/images/support-page/flags/german.png',
    },
    {
      code: 'PL',
      name: 'Polski',
      flag: './../../../../assets/images/support-page/flags/poland.png',
    },
    {
      code: 'RU',
      name: 'Русский',
      flag: './../../../../assets/images/support-page/flags/rusia.png',
    },
    {
      code: 'NL',
      name: 'Nederlands',
      flag: './../../../../assets/images/support-page/flags/netherlands.png',
    },
  ];

  private readonly destroy$ = new Subject<void>();
  private readonly urlSupportFileBlobUrl =
    environment.azureBlobStorageSupportFile;
  private readonly sassTokenBlobUrl =
    environment.azureBlobbStorageSupportFileSassToken;
  private languageCheckInterval: NodeJS.Timeout | null;
  showConfirmationModal: boolean;
  confirmationModalMessage: string;
  modalTitleConfirmation: string;
  confirmationModalType: string;
  modalTranslateButtonConfirmText: string;
  modalTranslateButtonCancelText: string;
  selectedCategory: string = 'controllers';
  @ViewChild('dialogTemplateDescription')
  dialogTemplateDescription!: TemplateRef<FileData>;
  @ViewChild('dialogTemplateProductData')
  dialogTemplateProductData!: TemplateRef<FileData>;
  orderedCategories: OrderedCategory[] = [];
  noFiles: boolean = false;
  productImageUrl: string = '';
  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly supportPageService: SupportPageService,
    private readonly translateService: TranslateService,
    private readonly alertService: AlertService,
    public readonly confirmacaoModalService: ConfirmacaoModalService,
    private readonly authorizationService: AuthorizationService,
  ) {
    this.languageSelected =
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG) ||
      AppConstants.LANGUAGES.PT_BR;
  }

  ngOnInit() {
    this.fetchAllSupportFiles();
    this.startLanguageCheck();
    this.canUpload = this.authorizationService
      .gethandler()
      .permits(UserPermissions.CREATE, UserResources.USER_ADMINISTRATION);

    this.isMobile = window.innerWidth <= 768;
    this.isTablet = window.innerWidth <= 1024 && window.innerWidth > 768;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    clearInterval(this.languageCheckInterval);
  }

  private startLanguageCheck() {
    this.languageCheckInterval = setInterval(() => {
      const currentLanguage = localStorage.getItem(
        AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG,
      );

      if (currentLanguage && currentLanguage !== this.languageSelected) {
        this.languageSelected = currentLanguage;
        if (this.selectedItemInfo) {
          this.updateModalContent();
        }
      }
    }, TimeoutEnum.VeryShort);
  }

  private fetchAllSupportFiles() {
    this.supportPageService.getAllSupportFile().subscribe(
      (data) => {
        if (!data || data.length === 0) {
          this.noFiles = true;
          console.error('No data received');
          return;
        }

        const categoryOrder = [
          'onboardComputers',
          'controller',
          'switches',
          'antennas',
          'ecu',
          'connectivity',
        ];

        const productsByCategory: Record<string, ProductItem[]> = {};

        data.forEach((file) => {
          if (!file || !file.fileData) {
            console.warn('Invalid File', file);
            return;
          }

          const category = file.fileData.productCategory;
          if (category) {
            if (!productsByCategory[category]) {
              productsByCategory[category] = [];
            }

            if (this.hasFiles(file.fileData as unknown as FileData)) {
              productsByCategory[category].push(file as unknown as ProductItem);
            }
          }
        });

        this.orderedCategories = categoryOrder
          .filter((category) => productsByCategory[category])
          .map((category) => ({
            key: category,
            value: productsByCategory[category],
          })) as OrderedCategory[];

        this.orderedCategories.forEach((categoria) => {
          categoria.value.forEach(
            (item: ItemFileSelected & { imageUrl?: string }) => {
              const imageUrl = this.getProductImageUrl(item);
              this.checkImageExists(imageUrl).then((exists) => {
                item.imageUrl = exists
                  ? imageUrl
                  : './../../../../assets/images/support-page/products/default_img_product.jpg';
              });
            },
          );
        });
      },
      (error) => console.error(error),
    );
  }

  private checkImageExists(url: string): Promise<boolean> {
    return fetch(url, { method: 'HEAD' })
      .then((response) => response.ok)
      .catch(() => false);
  }

  hasFiles(fileData: FileData): boolean {
    return (
      (fileData.guides && fileData.guides.length > 0) ||
      (fileData.manuals && fileData.manuals.length > 0) ||
      (fileData.others && fileData.others.length > 0) ||
      (fileData.softwares && fileData.softwares.length > 0)
    );
  }

  public getProductImageUrl(item: ItemFileSelected): string {
    if (item?.fileData?.productImage) {
      return `${this.urlSupportFileBlobUrl}images/${item.fileData.productImage}.png${this.sassTokenBlobUrl}`;
    }

    if (item?.fileData && !item.fileData.productImage) {
      console.warn('⚠️ productImage ausente para o item:', item);
    }

    return './../../../../assets/images/support-page/products/default_img_product.jpg';
  }

  private updateDataForSelectedProduct() {
    if (!this.selectedProduct) {
      console.error('No selected product');
      return;
    }

    this.supportPageService.getSupportFileById(this.selectedProduct).subscribe(
      (data: ItemFileSelected) => {
        if (!data || !data.fileData) {
          console.error(data);
          return;
        }
        this.processFileData(data);
      },
      (error) => {
        console.error(error);
      },
    );
  }

  private validateFileData(fileData: FileDataCategory): {
    guides: FileMetaData[];
    manuals: FileMetaData[];
    others: FileMetaData[];
    softwares: FileMetaData[];
  } {
    return {
      guides: fileData.guides || [],
      manuals: fileData.manuals || [],
      others: fileData.others || [],
      softwares: fileData.softwares || [],
    };
  }

  private processFileData(data: ItemFileSelected) {
    const validatedData = this.validateFileData(data.fileData);
    this.guides = validatedData.guides.map((guide) =>
      this.mapFileCategory(guide, 'guides'),
    );
    this.manuals = validatedData.manuals.map((manual) =>
      this.mapFileCategory(manual, 'manuals'),
    );
    this.others = validatedData.others.map((other) =>
      this.mapFileCategory(other, 'others'),
    );
    this.softwares = validatedData.softwares.map((software) =>
      this.mapFileCategory(software, 'softwares'),
    );

    this.isEmpty = this.isObjectCompletelyEmpty({
      guides: this.guides,
      manuals: this.manuals,
      others: this.others,
      softwares: this.softwares,
    });
  }

  private filterCategoryByLanguage(
    items: FileItem[],
    languageCode: string,
  ): FileInfo[] {
    if (!items || items.length === 0) return [];

    const normalizedLanguageCode = this.normalizeLanguageCode(languageCode);

    const filteredItems = items.filter(
      (item) => item[normalizedLanguageCode] || item['en'] || item['ptBR'],
    );

    return filteredItems.map((item) => {
      const languageData =
        item[normalizedLanguageCode] || item['ptBR'] || item['en'] || {};

      return {
        fileName: languageData.fileName || 'No name available',
        description: languageData.description || 'No description available',
        selectedLanguage: normalizedLanguageCode,
        date: item.date,
        version: item.version,
        filePath: item.filePath,
        fileExtension: item.filePath
          ? item.filePath.match(/\.([0-9a-z]+)$/i)?.[1] || ''
          : '',
        productLanguages: item.productLanguages || [],
      };
    });
  }

  private processCategoryData(
    items: FileInfo[],
    languageCode: string,
  ): FileInfo[] {
    if (!items || items.length === 0) return [];

    return items.map((item) => {
      const languageData =
        item[languageCode] || item['en'] || item['ptBR'] || {};

      return {
        fileName: languageData.fileName || 'No name available',
        description: languageData.description || 'No description available',
        date: item.date,
        version: item.version,
        filePath: item.filePath,
        fileExtension: item.filePath
          ? item.filePath.match(/\.([0-9a-z]+)$/i)?.[1] || ''
          : '',
        productLanguages: item.productLanguages || [],
      };
    });
  }



  private isObjectCompletelyEmpty(obj: {}): boolean {
    return Object.values(obj).every((category) =>
      Array.isArray(category)
        ? category.length === 0
        : this.isObjectCompletelyEmpty(category),
    );
  }

  private mapFileCategory(fileData: FileItem, category: string): FileInfo[] {
    if (!fileData) {
      return [];
    }

    return fileData.map((data: FileItem) => {
      const languageData = (data[this.languageSelected] ||
        data['ptBR']) as LanguageData;
      const languages = data.productLanguages.map((lang: string) => {
        const language = this.productLanguages.find((pl) => pl.code === lang);

        return language ? language.flag : '';
      });

      return {
        fileName: languageData.fileName,
        description: languageData.description,
        filePath: data.filePath,
        fileDate: data.date,
        fileVersion: String(data.version),
        fileExtension: data.filePath
          ? data.filePath.match(/\.([0-9a-z]+)$/i)?.[1] || ''
          : '',
        languages,
        filePathUrl: `${this.urlSupportFileBlobUrl}${this.selectedProduct}/${category}/${data.version}/${data.date}/${data.filePath}${this.sassTokenBlobUrl}`,
      };
    });
  }

  private normalizeLanguageCode(languageCode: string): string {
    const normalizedCode = languageCode.toLowerCase();

    const languageMap = {
      pt_br: 'ptBR',
      'pt-br': 'ptBR',
      pt: 'ptBR',
      en_us: 'en',
      'en-us': 'en',
      en: 'en',
      es_es: 'es',
      'es-es': 'es',
      es: 'es',
      it_it: 'it',
      'it-it': 'it',
      it: 'it',
    };

    return languageMap[normalizedCode] || normalizedCode;
  }

  private deleteFromBlobStorage(
    filePath: string,
    category: string,
    fileVersion: string,
    fileDate: string,
  ) {
    const prdCategory = this.selectedItemInfo.productCategory;
    this.supportPageService
      .deleteFile(
        this.selectedProduct,
        category,
        fileVersion,
        fileDate,
        filePath,
        prdCategory,
      )
      .subscribe(
        () => {
          this.translateService
            .get('download-support-page.file-successfully-deleted')
            .subscribe((res: string) => {
              this.alertService.info(res);
            });
          this.updateDataForSelectedProduct();
          this.fetchAllSupportFiles();

          setTimeout(() => {
            this.claseDialogProductData();
          }, TimeoutEnum.Short);
        },
        () => {
          this.translateService
            .get('download-support-page.error-delete-file')
            .subscribe((res: string) => {
              this.alertService.error(res);
            });
        },
      );
  }

  private deleteFromCosmoStorage(
    category: string,
    fileName: string,
    date: string,
    version: string,
  ) {
    this.supportPageService
      .deleteSupportFile(
        this.selectedProduct,
        category,
        fileName,
        date,
        version,
      )
      .subscribe(
        () => {
          this.deleteFromBlobStorage(
            this.selectedFileInfo.filePath,
            category,
            this.selectedFileInfo.fileVersion,
            this.selectedFileInfo.fileDate,
          );
        },
        (error) => {
          console.error(error);
          this.translateService
            .get('download-support-page.error-delete-file')
            .subscribe((res: string) => {
              this.alertService.error(res);
            });
        },
      );
  }

  openModalProductData(item: ItemFileSelected) {
    this.selectedProduct = item.id;
    const currentLanguage = this.normalizeLanguageCode(this.languageSelected);

    this.selectedItemInfo = {
      fileName: item.fileData?.productImage,
      description: '',
      productCategory: item.fileData?.productCategory,
      softwares: this.filterCategoryByLanguage(
        item.fileData?.softwares || [],
        currentLanguage,
      ) as [],
      guides: this.filterCategoryByLanguage(
        item.fileData?.guides || [],
        currentLanguage,
      ) as [],
      manuals: this.filterCategoryByLanguage(
        item.fileData?.manuals || [],
        currentLanguage,
      ) as [],
      others: this.filterCategoryByLanguage(
        item.fileData?.others || [],
        currentLanguage,
      ) as [],
    };
  }

  openDescriptionModal(itemInfo: {
    fileName: string;
    description: string;
    productCategory: string;
  }) {
    this.selectedItemInfo = {
      fileName: itemInfo.fileName,
      description: itemInfo.description,
      productCategory: itemInfo.productCategory,
      softwares: [],
      guides: [],
      manuals: [],
      others: [],
    };
  }

  updateModalContent() {
    if (!this.selectedItemInfo) return;
    const currentLanguage =
      this.languageSelected.toLowerCase() === 'pt_br'
        ? 'ptBR'
        : this.languageSelected.toLowerCase();

    this.selectedItemInfo = {
      fileName: this.selectedItemInfo.fileName,
      description: this.selectedItemInfo.description,
      productCategory: this.selectedItemInfo.productCategory,
      softwares: this.processCategoryData(
        this.selectedItemInfo.softwares || [],
        currentLanguage,
      ) as [],
      guides: this.processCategoryData(
        this.selectedItemInfo.guides || [],
        currentLanguage,
      ) as [],
      manuals: this.processCategoryData(
        this.selectedItemInfo.manuals || [],
        currentLanguage,
      ) as [],
      others: this.processCategoryData(
        this.selectedItemInfo.others || [],
        currentLanguage,
      ) as [],
    };
  }

  openDialog() {
    const dialogRef = this.dialog.open(FormUploadFileComponent, {
      width: this.isMobile ? '100%' : this.isTablet ? '100%' : '35%',
      height: this.isMobile ? '100vh' : '100%',
    });
    dialogRef.afterClosed().subscribe(() => this.fetchAllSupportFiles());
  }

  openDialogDescription(item: FileData) {
    this.dialog.open(this.dialogTemplateDescription, {
      width: this.isMobile ? '90%' : this.isTablet ? '100%' : '45%',
      data: item,
    });
  }

  openDialogProductData(item: ItemFileSelected) {
    this.dialog.open(this.dialogTemplateProductData, {
      width: this.isMobile ? '100%' : this.isTablet ? '100%' : '55%',
      data: {
        item,
      },
    });
    this.selectedProduct = item.id;
    this.openModalProductData(item);
  }

  claseDialogProductData() {
    this.dialog.closeAll();
  }

  downloadFile(
    filePath: string,
    fileName: string,
    version: string,
    date: string,
    category: string,
  ) {
    const documentId = encodeURIComponent(this.selectedProduct);
    const prdCategory = this.selectedItemInfo.productCategory;
    const url = `${this.urlSupportFileBlobUrl}${prdCategory}/${documentId}/${category}/${version}/${date}/${filePath}${this.sassTokenBlobUrl}`;
    this.productImageUrl = `${this.urlSupportFileBlobUrl}/images/${this.sassTokenBlobUrl}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`error downloaded file: ${response.statusText}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName || 'downloaded-file');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error(error));
  }

  navigateToSiteAgres() {
    if (this.languageSelected === AppConstants.LANGUAGES.PT_BR) {
      window.open(
        'https://www.agres.com.br/quem_somos/#entre_em_contato/',
        '_blank',
      );
    } else if (this.languageSelected === AppConstants.LANGUAGES.EN) {
      window.open(
        'https://en.agres.com.br/quem_somos/#entre_em_contato/',
        '_blank',
      );
    } else if (this.languageSelected === AppConstants.LANGUAGES.ES) {
      window.open(
        'https://es.agres.com.br/quem_somos/#entre_em_contato',
        '_blank',
      );
    } else {
      window.open(
        'https://en.agres.com.br/quem_somos/#entre_em_contato/',
        '_blank',
      );
    }
  }

  translateConfirmationMessage() {
    this.translateService
      .get('global.alert.delete-title')
      .subscribe((res: string) => {
        this.showConfirmationModal = true;
        this.confirmationModalMessage = '';
        this.modalTitleConfirmation = res;
        this.confirmationModalType = 'warning';
        this.modalTranslateButtonConfirmText = 'global.delete';
        this.modalTranslateButtonCancelText = 'global.cancel';
      });
  }

  openModalToDeleteFile(
    category: string,
    fileName: string,
    filePath: string,
    fileVersion?: string,
    fileDate?: string,
    productCategory?: string,
  ) {
    this.selectedFileInfo = {
      category,
      fileName,
      filePath,
      fileVersion,
      fileDate,
      productCategory,
    };
    this.translateConfirmationMessage();
  }

  onButtonAction(event: {
    action: boolean;
    type: string;
    buttonText?: string;
  }) {
    if (event.buttonText === 'Excluir' || event.buttonText === 'Delete') {
      this.deleteFromCosmoStorage(
        this.selectedFileInfo.category,
        this.selectedFileInfo.fileName,
        this.selectedFileInfo.fileDate,
        this.selectedFileInfo.fileVersion,
      );
    }
  }
}
