<table class="table">
    <thead>
        <tr>
            <th *ngFor="let column of columns">
                <ng-container *ngIf="!column.hidden">
                    {{column.headerName}}
                </ng-container>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of rows">
            <td *ngFor="let cell of getCellValues(row)">{{cell}}</td>
        </tr>
    </tbody>
</table>