import { AppConstants } from 'app/app.constants';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { PostalCodeService } from 'app/core/services/postalCode.service';
import { UserService } from 'app/core/services/user.service';
import { Address, User } from 'app/shared/models/user';
import { ValidateId } from 'app/shared/validators/ValidateId';
import { GenericValidator } from 'app/utils/generic-form-validator';
import { Subject } from 'rxjs';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AgresSpinnerService } from '../../agres-spinner/agres-spinner.service';
import { AlertService } from '../../alert/alert.service';

type AddressFormGroup = FormGroup<{
  country: FormControl<string>;
  city: FormControl<string>;
  stateOrProvince: FormControl<string>;
  postalCode: FormControl<string>;
}>;

@Component({
  selector: 'app-form-atualizacao-cadastro',
  templateUrl: './atualizacao-cadastro.component.html',
  styleUrls: ['./atualizacao-cadastro.component.scss'],
})
export class AtualizacaoCadastroFormComponent implements OnInit {
  @Input() offCanvas: boolean;
  @Input() userId: string;
  @Input() showOffCanvasSubject: Subject<boolean>;
  @Output() modalChange: EventEmitter<boolean>;
  user: User;
  public outsideBrazil: boolean = false;
  public registrarForm: FormGroup<{
    phoneNumber: FormControl<string>;
    documentIdentification: FormControl<string>;
    company: FormControl<string>;
    addressFormGroup: AddressFormGroup;
  }>;
  private readonly validationMessages: Record<string, Record<string, string>>;
  private readonly genericValidator: GenericValidator;
  public displayMessage: Record<string, string> = {};
  private errors: string[] = [];
  public disableSubmitButton: boolean;
  public readonly countries: Array<string> = AppConstants.COUNTRY_CODES;

  constructor(
    public fb: FormBuilder,
    public translateService: TranslateService,
    public AlertService: AlertService,
    public userService: UserService,
    public postalCodeService: PostalCodeService,
    public alertService: AlertService,
    private readonly spinnerService: AgresSpinnerService,
  ) {
    this.validationMessages = {
      phoneNumber: {
        required: '',
        maxlength: '',
        minlength: '',
      },
      documentIdentification: {
        required: '',
        cpf: '',
        cnpj: '',
      },
      company: {
        required: '',
        minlength: '',
      },
      country: {
        required: '',
      },
      city: {
        required: '',
        minlength: '',
      },
      stateOrProvince: {
        required: '',
        minlength: '',
      },
      postalCode: {
        required: '',
        minlength: '',
      },
    };

    this.populateValidateObj();

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  populateValidateObj() {
    const fields = 'global.validate.fields';
    const phoneError = 'global.validate.error-phone-data';
    const userCompanyError = 'global.validate.error-user-data-incorrect-text';
    const document = 'global.validate.error-document-data';
    const identification = 'global.validate.identification-required';
    const keys = [
      fields,
      phoneError,
      userCompanyError,
      identification,
      document,
    ];

    this.translateService.get(keys).subscribe((translations: string[]) => {
      this.validationMessages.documentIdentification.required =
        translations[fields];
      this.validationMessages.phoneNumber.required = translations[phoneError];
      this.validationMessages.company.required = translations[userCompanyError];

      const identificationRequired = translations[identification];
      this.validationMessages.country.required = identificationRequired;
      this.validationMessages.city.required = identificationRequired;
      this.validationMessages.stateOrProvince.required = identificationRequired;
      this.validationMessages.postalCode.required = identificationRequired;

      const documentData = translations[document];
      this.validationMessages.documentIdentification.cpf = documentData;
      this.validationMessages.documentIdentification.cnpj = documentData;
    });
  }

  ngOnInit() {
    this.userService.getUserByEmail(this.userId).subscribe(
      (user: User) => {
        this.user = user;
        this.registrarForm = this.fb.group(this.buildRegistrationForm());
        this.registrarForm.valueChanges.subscribe(() => {
          this.displayMessage = this.genericValidator.processMessages(
            this.registrarForm,
          );
        });

        const addressFormGroup = this.registrarForm.get('addressFormGroup');

        addressFormGroup.get('country').valueChanges.subscribe((country) => {
          this.outsideBrazil = country !== 'BRA';
          const documentIdControl = this.registrarForm.get(
            'documentIdentification',
          );
          if (this.outsideBrazil) {
            documentIdControl.setValidators([Validators.required]);
            addressFormGroup.get('city').enable();
            addressFormGroup.get('stateOrProvince').enable();
          } else {
            documentIdControl.setValidators([
              Validators.required,
              ValidateId.ValidadeCPFCNPJ,
            ]);
          }
          documentIdControl.updateValueAndValidity();
        });
        this.setDefaultCountry();
      },
      (e) => {
        this.translateService.get('profile.failed').subscribe((res: string) => {
          this.AlertService.warning(res, TimeoutEnum.VeryLong);
        });
      },
    );

    this.disableSubmitButton = false;
  }

  buildRegistrationForm(): {
    phoneNumber: FormControl<string>;
    documentIdentification: FormControl<string>;
    company: FormControl<string>;
    addressFormGroup: FormGroup<{
      country: FormControl<string>;
      city: FormControl<string>;
      stateOrProvince: FormControl<string>;
      postalCode: FormControl<string>;
    }>;
  } {
    let city: string,
      stateOrProvince: string,
      country: string,
      postalCode: string;

    if (this.user.address) {
      postalCode = this.user.address.postalCode ?? '';
      city = this.user.address.city ?? '';
      stateOrProvince = this.user.address.stateOrProvince ?? '';
      country = this.user.address.country ?? '';
    }

    return {
      documentIdentification: new FormControl(this.user['nationalIdNumber'], [
        Validators.required,
        ValidateId.ValidadeCPFCNPJ,
      ]),
      company: new FormControl(this.user['company'], [Validators.required]),
      addressFormGroup: this.fb.group({
        country: [country, [Validators.required]],
        city: [city, [Validators.required]],
        stateOrProvince: [stateOrProvince, [Validators.required]],
        postalCode: [
          postalCode,
          [Validators.required, Validators.minLength(2)],
        ],
      }),
      phoneNumber: new FormControl(this.user.phoneNumber ?? '', [
        Validators.required,
      ]),
    }
  }

  getIdentificationMask(): string {
    return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
  }

  isCPF(): boolean {
    const documentIdentification =
      this.registrarForm.value.documentIdentification;
    return !documentIdentification || documentIdentification.length < 12;
  }

  isCelular(): boolean {
    return this.registrarForm.controls.phoneNumber.value.length === 11;
  }

  removePhoneNumberMask(phoneNumber: string) {
    let telefoneSemMask = phoneNumber.replace('(', '');
    telefoneSemMask = telefoneSemMask.replace(')', '');
    telefoneSemMask = telefoneSemMask.replace('-', '');
    telefoneSemMask = telefoneSemMask.replace(' ', '');

    return telefoneSemMask;
  }
  popularTelefone(telefoneForm: string): string {
    telefoneForm = telefoneForm.replace(/\D/g, '');

    if (telefoneForm.length === 10) {
      return `(${telefoneForm.slice(0, 2)}) ${telefoneForm.slice(2, 6)}-${telefoneForm.slice(6, 10)}`;
    } else if (telefoneForm.length === 11) {
      return `(${telefoneForm.slice(0, 2)}) ${telefoneForm.slice(2, 7)}-${telefoneForm.slice(7, 11)}`;
    } else {
      return telefoneForm;
    }
  }

  applyPhoneMask(event: InputEvent): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    value = value.replace(/\D/g, '');

    if (value.length > 11) {
      value = value.substring(0, 11);
    }

    const selectedCountry = this.registrarForm
      .get('addressFormGroup')
      .get('country');
    const selectedCountryValue = selectedCountry.value;
    if (selectedCountryValue === 'BRA' && value.length === 0) {
      value = '+55 ';
    }

    if (value.startsWith('+55')) {
      value = value.replace('+55 ', '').trim();
      if (value.length === 11) {
        value = `+55 ${value.replace(/(\d{2})(\d)(\d{4})(\d{4})/, '$1 $2 $3-$4')}`;
      }
      if (value.length === 10) {
        value = `+55 ${value.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2-$3')}`;
      }
    }

    if (!this.outsideBrazil) {
      value = this.popularTelefone(value);
    }
    this.registrarForm
      .get('phoneNumber')
      ?.setValue(value, { emitEvent: false });
  }

  checkCep(postalCode: Event) {
    const postalCodeValue = (postalCode.target as HTMLInputElement).value;
    if (postalCodeValue) {
      this.postalCodeService.searchCEP(postalCodeValue).subscribe(
        (dados) => {
          this.addPostalCodeDataIntoForm(
            dados,
            this.registrarForm.controls.addressFormGroup,
          );
        },
        (error) => {
          if (error) {
            this.translateService
              .get('global.validate.error-cep')
              .subscribe((res: string) => {
                this.alertService.error(res);
              });
          }
        },
      );
    }
  }

  addPostalCodeDataIntoForm(
    postalCodeData: Address | null,
    form: AddressFormGroup,
  ) {
    if (postalCodeData instanceof Object) {
      if ('erro' in postalCodeData) {
        this.translateService
          .get('global.validate.error-cep')
          .subscribe((res: string) => {
            this.alertService.error(res);
          });
      } else {
        if (postalCodeData.city) {
          form.get('city').disable();
        }

        if (postalCodeData.uf) {
          form.get('stateOrProvince').disable();
        }
        form.patchValue({
          city: postalCodeData.city,
          stateOrProvince: postalCodeData.uf,
        });
      }
    }
  }

  buildUserObject(): User {
    const addressFormGroup = this.registrarForm.controls.addressFormGroup;
    const user = {
      id: this.user.id,
      name: this.user.name,
      role: this.user.role,
      nationalIdNumber:
        this.registrarForm.controls.documentIdentification.value,
      company: this.registrarForm.controls.company.value,
      address: {
        postalCode: addressFormGroup.get('postalCode').value,
        country: addressFormGroup.get('country').value,
        city: addressFormGroup.get('city').value,
        stateOrProvince: addressFormGroup.get('stateOrProvince').value,
      },
      phoneNumber: this.removePhoneNumberMask(
        this.registrarForm.controls.phoneNumber.value,
      ),
    };
    return user;
  }

  updateUser() {
    this.displayMessage = this.genericValidator.processMessages(
      this.registrarForm,
    );

    this.translateService
      .get('profile.updating-your-data')
      .subscribe((res: string) => {
        this.spinnerService.toShow(res);
      });

    if (this.registrarForm.valid && this.registrarForm.dirty) {
      const user = this.buildUserObject();

      this.disableSubmitButton = true;
      this.userService.patchUser(user).subscribe(
        () => {
          this.onSaveComplete();
        },
        (error) => {
          this.onError(error);
        },
      );
    }
  }

  onSaveComplete() {
    this.errors = [];
    this.disableSubmitButton = true;
    window.localStorage.removeItem(AppConstants.KEYS_LOCAL_STORAGE.ROLE);
    window.localStorage.removeItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO);
    window.localStorage.removeItem(
      AppConstants.KEYS_LOCAL_STORAGE.DATE_EXPIRES,
    );
    window.localStorage.removeItem(
      AppConstants.KEYS_LOCAL_STORAGE.REFRESH_TOKEN,
    );
    window.localStorage.removeItem(
      AppConstants.KEYS_LOCAL_STORAGE.ISO_ACCESS_TOKEN,
    );

    this.spinnerService.toHide();
    window.document.location.reload();
  }

  onError(fail: Record<string, string>) {
    this.disableSubmitButton = false;
    this.errors = [fail.error];

    this.translateService
      .get('error.500.error-occurred-try-again')
      .subscribe((res: string) => {
        this.AlertService.error(this.errors[0], res);
      });
  }

  public hideOffCanvas(): void {
    this.showOffCanvasSubject.next(false);
    window.localStorage.setItem(
      AppConstants.KEYS_LOCAL_STORAGE.HIDE_MODAL,
      'true',
    );
  }
  setDefaultCountry() {
    const language = localStorage.getItem(
      AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG,
    );
    if (language === AppConstants.LANGUAGES.PT_BR) {
      const countryControl =
        this.registrarForm.controls.addressFormGroup.controls.country;
      if (countryControl && !countryControl.value) {
        countryControl.setValue('BRA');
      }
    }
  }
}
