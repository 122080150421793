import { NgxEchartsModule } from 'ngx-echarts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from 'app/app-shared.module';
import { ConvertUnit } from 'app/shared/pipes/convertUnit.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SidebarModule } from 'ng-sidebar';
import { ChartsModule } from 'ng2-charts';
import { Ng2CompleterModule } from 'ng2-completer';
import { FileUploadModule } from 'ng2-file-upload';
import { Ng5SliderModule } from 'ng5-slider';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxGaugeModule } from 'ngx-gauge';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { IboxtoolsModule } from '../../shared/components/iboxtools/iboxtools.module';
import { SafeHtmlPipe } from '../../shared/pipes/safehtml.pipe';
import { ConfirmacaoModalModule } from '../confirmacao-modal/confirmacao-modal..module';
import { FleetMonitoringComponent } from './fleet-monitoring/fleet-monitoring.component';
import { NavigationTabComponent } from './fleet-monitoring/navigation-tab/navigation-tab.component';
import { OperationTabComponent } from './fleet-monitoring/operation-tab/operation-tab.component';
import { TrailComponent } from './fleet-monitoring/trail/trail.component';
import { WeatherTabComponent } from './fleet-monitoring/weather-tab/weather-tab.component';
import { GestaoNavegacaoComponent } from './gestao-navegacao/gestao-navegacao.component';
import { MapaTempoComponent } from './mapa-tempo/mapa-tempo.component';
import { GaugeChartComponent } from './previsao-tempo/modal-gauge/gauge-chart.component';
import { ModalGraphComponent } from './previsao-tempo/modal-graph/modal-graph.component';
import { PrevisaoTempoComponent } from './previsao-tempo/previsao-tempo.component';
import { ResultadoNavegacaoComponent } from './resultado-navegacao/resultado-navegacao.component';
import { ResumosComponent } from './resumos/resumos.component';
import { ReportService } from 'app/core/services/report.service';
import { DeviceService } from './fleet-monitoring/device/device.service';
import { RealtimeDeviceMonitoringComponent } from './fleet-monitoring/realtime-device-monitoring/realtime-device-monitoring.component';
import { OperationTypePipe } from 'app/pipes/fleet-monitoring/operation-type.pipe';
import { AppliedQuantityPipe } from 'app/pipes/fleet-monitoring/applied-quantity.pipe';
import { ReplayComponent } from './resultado-navegacao/replay/replay.component';
import { MapMarkersComponent } from './resultado-navegacao/map-markers/map-markers.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LegendsControlComponent } from './resultado-navegacao/legends-control/legends-control.component';
import { DataBoxComponent } from 'app/modules/gestao-operacao/resultado-navegacao/data-box/data-box.component';
import { DataBoxItemComponent } from './resultado-navegacao/data-box-item/data-box-item.component';
import { FilterDataBoxArray } from 'app/pipes/resultado-navegacao/FilterDataBoxArray.pipe';
import { SprayRateComponent } from './spray-rate/spray-rate.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TotalTabComponent } from './fleet-monitoring/total-tab/total-tab.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  useBothWheelAxes: true,
};

@NgModule({
  declarations: [
    LegendsControlComponent,
    MapMarkersComponent,
    ReplayComponent,
    FleetMonitoringComponent,
    GaugeChartComponent,
    PrevisaoTempoComponent,
    SafeHtmlPipe,
    ModalGraphComponent,
    GestaoNavegacaoComponent,
    MapaTempoComponent,
    ConvertUnit,
    FilterDataBoxArray,
    OperationTabComponent,
    NavigationTabComponent,
    TotalTabComponent,
    TrailComponent,
    WeatherTabComponent,
    ResultadoNavegacaoComponent,
    OperationTypePipe,
    AppliedQuantityPipe,
    ResumosComponent,
    RealtimeDeviceMonitoringComponent,
    DataBoxComponent,
    DataBoxItemComponent,
    SprayRateComponent,
  ],
  imports: [
    MatSlideToggleModule,
    AppSharedModule.forRoot(),
    BrowserModule,
    MatTableModule,
    ChartsModule,
    FormsModule,
    TranslateModule,
    CommonModule,
    Ng5SliderModule,
    ReactiveFormsModule,
    IboxtoolsModule,
    Ng2CompleterModule,
    FileUploadModule,
    ColorPickerModule,
    MatSliderModule,
    PerfectScrollbarModule,
    ConfirmacaoModalModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    SidebarModule.forRoot(),
    NgCircleProgressModule.forRoot({}),
    NgxGaugeModule,
    GoogleMapsModule,
    BrowserAnimationsModule,
    NgxEchartsModule,
    MatTabsModule,
    MatButtonToggleModule,
  ],
  exports: [FleetMonitoringComponent, ResultadoNavegacaoComponent],
  providers: [
    ReportService,
    Subject,
    DeviceService,
    FilterDataBoxArray,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class GestaoOperacaoModule {}
