<div class="a-form a-valid">
  <label>
    {{ title }}
    <span class="a-form__required">{{ labelText }}</span>
  </label>
  <div class="a-form__group">
    <span class="a-form__group--icon">
      <i [class]="iconClass"></i>
    </span>
    <input
      type="search"
      list="codes"
      class="a-form__group--input"
      [(ngModel)]="codeValue"
      (change)="saveCode()"
      [name]="name"
      [disabled]="disabled" />
    <datalist id="codes">
      <option *ngFor="let c of codeList" [value]="c.name">{{ c.name }}</option>
    </datalist>
  </div>
</div>
