<section class="panel">
  <div class="panel__group hidden__mobile">
    <app-button
      *ngIf="!ndviActivated"
      type="button"
      color="success"
      icon="agres-field"
      (btnClick)="activeNdvi()"
      title="{{ 'fields.active-ndvi' | translate }}">
    </app-button>
    <app-button
      *ngIf="!echartNotWorking && !mapNotWorking && ndviActivated"
      type="button"
      color="success"
      icon="agres-prescription"
      (btnClick)="openFormGeneratePrescriptionMap()"
      title="{{ 'fields.recommendation-map' | translate }}">
    </app-button>
    <app-button
      type="button"
      color="primary"
      icon="agres-file"
      (btnClick)="openTarefaDoTalhaoById()"
      title="{{ 'fields.title' | translate }}">
    </app-button>
    <app-button
      type="button"
      color="primary"
      icon="agres-app"
      (btnClick)="openSidebarMenuFieldFiles()"
      title="{{ titleButtonMenuFieldFile | translate }}">
      ></app-button
    >
    <app-button
      [disabled]="!showButton"
      type="button"
      color="info"
      icon="agres-field"
      (btnClick)="navigateToTaskListWithFieldName()"
      title="{{ 'fields.my-tasks' | translate }}">
    </app-button>
    <app-button
      type="button"
      color="primary"
      icon="agres-edit"
      (btnClick)="navigateToEditFieldPage()"
      title="{{ 'global.edit' | translate }}">
    </app-button>
    <app-button
      type="button"
      color="return"
      icon="agres-return"
      (btnClick)="goBack()"
      title="{{ 'global.back' | translate }}">
    </app-button>
  </div>
</section>
<section *ngIf="mapNotWorking">
  <div class="notify notify__warning" role="alert">
    <app-agres-icons iconsClass="agres-alert-triangle agres-xl"></app-agres-icons>
    <div class="notify__warning">
      <p>{{ limitSizeMessageErrorMapNdvi | translate }}</p>
    </div>
  </div>
</section>
<section *ngIf="hideNdviMapSection">
  <div class="notify notify__warning" role="alert">
    <app-agres-icons iconsClass="agres-alert-triangle agres-xl"></app-agres-icons>
    <div class="notify__warning">
      <p>{{ 'fields.generate-map-ndvi-and-prescription' | translate }}</p>
    </div>
  </div>
</section>
<section class="weather">
  <div class="weather__content">
    <img src="../../../../../assets/images/weather/sun-light.png" alt="" />
    <div class="weather__content--text">
      <h1>{{ summarizedForecast.temperature }} {{ 'global.temperature-symbol' | translate }}</h1>
      <p>{{ 'global.temperature' | translate }}</p>
    </div>
  </div>
  <div class="weather__content">
    <img src="../../../../../assets/images/weather/heavy-rain-light.png" alt="" />
    <div class="weather__content--text">
      <h1>{{ summarizedForecast.precipChance }} {{ 'global.percentage' | translate }}</h1>
      <p>{{ 'global.rain' | translate }}</p>
    </div>
  </div>
  <div class="weather__content">
    <img src="../../../../../assets/images/weather/partial-cloudy-light.png" alt="" />
    <div class="weather__content--text">
      <h1>{{ summarizedForecast.relativeHumidity }} {{ 'global.percentage' | translate }}</h1>
      <p>{{ 'global.humidity' | translate }}</p>
    </div>
  </div>
  <div class="weather__content">
    <img src="../../../../../assets/images/weather/heavy-wind-light.png" alt="" />
    <div class="weather__content--text">
      <h1>{{ summarizedForecast.windSpeed }} {{ 'global.velocity-symbol' | translate }}</h1>
      <p>{{ 'global.velocity' | translate }}</p>
    </div>
  </div>
</section>
<section class="description">
  <div class="description__content">
    <p>
      {{ 'fields.name' | translate }}:
      <span>
        {{ talhaoById?.name }}
      </span>
    </p>
    <p>
      {{ 'global.area' | translate }}:
      <span>
        {{ talhaoById?.totalArea }}
      </span>
    </p>
    <p>
      {{ 'global.description' | translate }}:
      <span>
        {{ talhaoById?.description }}
      </span>
    </p>
  </div>
</section>

<div class="row">
  <div class="col-sm-12 col-md-3 col-lg-3">
    <!-- ADICIONAR SIDEBAR MENU PARA ARQUIVOS -->
    <section>
      <div *ngIf="apiData">
        <app-sidebar-menu-field-files
          [isMenuFieldFileOpen]="menuFieldFileStatus"
          [shpFiles]="shpFiles"
          [name]="talhaoById.name"
          [fieldName]="fieldId"
          [geometry]="geometry"
          (filesToShow)="showShapefiles($event)"></app-sidebar-menu-field-files>
      </div>
    </section>
    <!-- FINAL DO MENU MY FILES -->
  </div>

  <div class="col-{{ menuFieldFileStatus ? '9' : '12' }} colMapa">
    <section class="maps">
      <div *ngIf="!ndviActivated">
        <div *ngIf="apiData">
          <div class="maps__content">
            <app-mapa-talhao
              [polygonColor]="selectedColor"
              [mode]="'view'"
              mapHeight="68vh"
              [recommendationMapArray]="recommendationMapArray"
              [guidesArray]="guidesArray"
              [boundariesArray]="boundariesArray"
              >>
            </app-mapa-talhao>
          </div>
        </div>
      </div>
      <div class="maps__content" *ngIf="!echartNotWorking && !mapNotWorking && !hideNdviMapSection && ndviActivated">
        <div *ngIf="apiData">
          <div class="maps__content">
            <app-mapa-talhao
              [polygonColor]="selectedColor"
              [mode]="'view'"
              mapHeight="350px"
              [recommendationMapArray]="recommendationMapArray"
              [guidesArray]="guidesArray"
              [boundariesArray]="boundariesArray">
            </app-mapa-talhao>
          </div>
        </div>
        <div *ngIf="!mapNotWorking && ndviActivated">
          <div class="maps__content">
            <iframe id="iframeWidgetMapNdvi" width="100%" height="350px" title="Maps" title="Maps"></iframe>
          </div>
        </div>
      </div>
      <div class="maps__content" *ngIf="!echartNotWorking && !mapNotWorking && !hideNdviMapSection && ndviActivated">
        <div class="maps__content--header">
          <h1>{{ 'fields.info-echart-map-ndvi' | translate }}</h1>
        </div>
        <div class="maps__content--result">
          <iframe id="iframeEchatsMapNdvi" width="100%" height="350px" title="Maps"></iframe>
        </div>
      </div>
      <div class="maps__content" *ngIf="echartNotWorking || mapNotWorking">
        <div class="maps__content--result">
          <div class="notify notify__warning" role="alert">
            <app-agres-icons iconsClass="agres-alert-triangle agres-xl"></app-agres-icons>
            <div class="notify__warning">
              <h1>{{ 'global.alert.ndvi-2' | translate }}</h1>
              <p>{{ 'fields.graphics' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<ng-template #resultBody>
  <div role="alert" *ngIf="alertTermsPrescriptions">
    <div class="notifyApp" role="alert" *ngIf="alertTermsPrescriptions">
      <img src="../../../../../assets/images/default/alert.svg" alt="" />
      <h1>{{ 'global.alert.ndvi-2' | translate }}</h1>
      <p>{{ 'global.alert.ndvi-3' | translate }}.</p>
      <p>
        <strong>{{ 'global.alert.ndvi-4' | translate }}</strong>
      </p>
    </div>
    <div class="hidden__mobile" role="alert">
      <div class="notify notify__warning">
        <div class="notify__icon">
          <app-agres-icons iconsClass="agres-info agres-xl"></app-agres-icons>
        </div>
        <div class="notify__warning">
          <p>
            {{ 'global.alert.ndvi-3' | translate }}
            <strong>{{ 'global.alert.ndvi-4' | translate }}</strong>
          </p>
        </div>
      </div>
      <div class="notify__btns">
        <app-button
          (btnClick)="agreePrescription()"
          type="submit"
          color="primary"
          styleClass="notify__button"
          icon="agres-check"
          title="{{ 'global.agreeOld' | translate }}">
        </app-button>
        <app-button
          (btnClick)="disagreePrescription()"
          type="submit"
          color="danger"
          styleClass="notify__button"
          icon="agres-close"
          title="{{ 'global.disagree' | translate }}">
        </app-button>
      </div>
    </div>
  </div>
  <div *ngIf="clickedCreateMapPrescriptions">
    <form [formGroup]="prescriptionForm" (ngSubmit)="onSubmit()">
      <div class="offcanvas__body">
        <div class="notify notify__warning" role="alert">
          <app-agres-icons iconsClass="agres-info agres-xl"></app-agres-icons>
          <div class="notify__warning">
            <p>
              {{ 'global.alert.ndvi-3' | translate }}
              <strong>{{ 'global.alert.ndvi-4' | translate }}</strong>
            </p>
          </div>
        </div>
        <div class="field__row">
          <div class="field">
            <div class="field__label" for="numberZone">
              {{ 'fields.number-of-zones' | translate }}: ( 1 {{ 'fields.number-of-zones-to' | translate }} 5 )
              <app-agres-icons
                iconsClass="agres-info"
                tooltip="{{ 'fields.help-number-zones' | translate }}"
                position="left">
              </app-agres-icons>
            </div>
            <div class="field__wrap">
              <select class="field__input minimal" id="numberZone" formControlName="numberZone" required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
          <div class="field">
            <div class="field__label" for="indexVegetation">
              {{ 'fields.vegetative-index' | translate }}: ( {{ 'fields.vegetative-index-ndvi' | translate }} )
              <app-agres-icons iconsClass="agres-info" tooltip="{{ 'fields.help-ndvi' | translate }}" position="left">
              </app-agres-icons>
            </div>
            <div class="field__wrap">
              <input type="text" class="field__input" id="indexVegetation" formControlName="indexVegetation" readonly />
            </div>
          </div>
          <div class="field">
            <div class="field__label" for="productName">
              {{ 'fields.products-name' | translate }}
              <app-agres-icons
                iconsClass="agres-info"
                tooltip="{{ 'fields.help-name-product' | translate }}"
                position="left">
              </app-agres-icons>
            </div>
            <div class="field__wrap">
              <input type="text" class="field__input" id="productName" formControlName="productName" />
            </div>
          </div>
          <div class="field">
            <div class="field__label" for="productType">
              {{ 'fields.products-type' | translate }}:
              <app-agres-icons
                iconsClass="agres-info"
                tooltip="{{ 'fields.help-type-product' | translate }}"
                position="left">
              </app-agres-icons>
            </div>
            <div class="field__wrap">
              <select class="field__input" id="productType" formControlName="productType" required>
                <option value="solid">{{ 'fields.products-type-solid' | translate }}</option>
                <option value="liquid">{{ 'fields.products-type-liquid' | translate }}</option>
              </select>
            </div>
          </div>
          <div class="field">
            <div class="field__label" for="userFertilizer">
              {{ 'fields.fertilizer-quantity' | translate }}
              <small *ngIf="prescriptionForm.get('productType').value === 'liquid'">
                ({{ 'fields.fertilizer-quantity-lts' | translate }})
              </small>
              <small *ngIf="prescriptionForm.get('productType').value === 'solid'">
                ({{ 'fields.fertilizer-quantity-kg' | translate }})
              </small>
              <app-agres-icons iconsClass="agres-info" tooltip="{{ 'fields.help-dose' | translate }}" position="left">
              </app-agres-icons>
            </div>
            <div class="field__wrap">
              <input type="text" class="field__input" id="userFertilizer" formControlName="userFertilizer" />
            </div>
          </div>
          <div class="field">
            <div class="field__label" for="strategy">
              {{ 'fields.choose-a-strategy' | translate }}:
              <app-agres-icons
                iconsClass="agres-info"
                tooltip="{{ 'fields.help-max-dose' | translate }}"
                position="left">
              </app-agres-icons>
            </div>
            <div class="field__wrap">
              <select id="strategy" formControlName="strategy" class="field__input" required>
                <option value="highwherehigh">
                  {{ 'fields.choose-a-strategy-dose-smaller' | translate }}
                </option>
                <option value="highwherelow">{{ 'fields.choose-a-strategy-dose-bigger' | translate }}</option>
              </select>
            </div>
          </div>
          <div class="field" *ngIf="showFertilizanteForm">
            <div class="field__label" for="doseDifference">
              {{ 'fields.difference-dosage' | translate }}: <strong>(0 - 100)</strong>
              <app-agres-icons iconsClass="agres-info" tooltip="{{ 'fields.help-zone' | translate }}" position="left">
              </app-agres-icons>
            </div>
            <div class="field__wrap">
              <input type="number" class="field__input" id="doseDifference" formControlName="doseDifference" />
              <span
                *ngIf="
                  prescriptionForm.get('doseDifference').hasError('min') ||
                  prescriptionForm.get('doseDifference').hasError('max')
                ">
                <small>{{ 'fields.error-input-strategy' | translate }}</small>
              </span>
            </div>
          </div>
        </div>
        <div class="result-ndvi" *ngIf="showSpinnerInMapPrescriptionOld">
          <div class="spinner" *ngIf="showSpinnerInMapPrescription"></div>
          <div class="a-column--two">
            <div>
              <iframe id="iframeWidgetMapNdvi2" width="100%" height="350px" title="Maps"></iframe>
            </div>
            <div>
              <app-prescription-map mapHeight="350px"></app-prescription-map>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #actionPrescription>
  <div class="hidden__mobile" *ngIf="clickedCreateMapPrescriptions">
    <app-button
      [disabled]="!prescriptionForm.valid"
      type="button"
      color="primary"
      icon="agres-reload"
      (btnClick)="onSubmit()"
      title="{{ 'fields.generate-map' | translate }}">
    </app-button>
    <app-button
      *ngIf="showButtonShapefileDownload"
      type="button"
      color="success"
      icon="agres-download"
      (btnClick)="downloadShapfileMapPrescription()"
      title="{{ 'fields.download-shapefile' | translate }}">
    </app-button>
    <app-button
      *ngIf="showButtonShapefileDownload"
      type="button"
      color="primary"
      icon="agres-send"
      (btnClick)="openOffCanvasDevices()"
      title="{{ 'fields.send-files-to-device' | translate }}">
    </app-button>
    <app-button
      *ngIf="showIrrigacaoForm && showDataIrrigation"
      type="button"
      color="warning"
      icon="agres-send"
      (btnClick)="sendMapPrescription()"
      title="LITROS {} POR HECTARES">
    </app-button>
  </div>
  <app-menu-pwa>
    <div class="pwa__list--info">
      <button type="button" (click)="agreePrescription()">
        <i class="agres-check"></i>
        <p>{{ 'global.agreeOld' | translate }}</p>
      </button>
    </div>
    <div class="pwa__list--info">
      <button type="button" (click)="disagreePrescription()">
        <i class="agres-close"></i>
        <p>{{ 'global.disagree' | translate }}</p>
      </button>
    </div>
  </app-menu-pwa>
  <app-menu-pwa *ngIf="clickedCreateMapPrescriptions">
    <div class="pwa__list--info">
      <button type="button" (click)="onSubmit()">
        <i class="agres-reload"></i>
        <p>{{ 'fields.generate-map' | translate }}</p>
      </button>
    </div>
    <div class="pwa__list--info" *ngIf="showButtonShapefileDownload">
      <button type="button" (click)="downloadShapfileMapPrescription()">
        <i class="agres-download"></i>
        <p>{{ 'fields.download-shapefile' | translate }}</p>
      </button>
    </div>
    <div class="pwa__list--info" *ngIf="showButtonShapefileDownload">
      <button type="button" (click)="openOffCanvasDevices()">
        <i class="agres-send"></i>
        <p>{{ 'fields.send-files-to-device' | translate }}</p>
      </button>
    </div>
  </app-menu-pwa>
</ng-template>
<app-offcanvas
  size="medium"
  [showSubject]="offCanvasOne"
  [show]="false"
  [bodyTemplate]="resultBody"
  [footerTemplate]="actionPrescription"
  title="{{ 'fields.create-prescription-map' | translate }}">
</app-offcanvas>
<ng-template #sendFiles>
  <app-send-field-files
    [fileName]="fileName"
    [fieldKey]="fieldId"
    [offCanvasDevices]="offCanvasDevices"
    [filesToAssociate]="filesToAssociate"></app-send-field-files>
</ng-template>
<app-offcanvas
  size="medium"
  [showSubject]="offCanvasDevices"
  [show]="false"
  [bodyTemplate]="sendFiles"
  title="{{ 'fields.send-files-to-device' | translate }}">
</app-offcanvas>
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="dashboard()">
      <i class="agres-app"></i>
      <p>{{ 'sidebar.title-dashboard' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info" *ngIf="!ndviActivated">
    <button type="button" (click)="activeNdvi()">
      <i class="agres-ndvi"></i>
      <p>{{ 'fields.active-ndvi' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info" *ngIf="!echartNotWorking && !mapNotWorking && ndviActivated">
    <button type="button" (click)="openFormGeneratePrescriptionMap()">
      <i class="agres-prescription"></i>
      <p>{{ 'fields.recommendation-map' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" (click)="openSidebarMenuFieldFiles()">
      <i class="agres-folder"></i>
      <p>{{ titleButtonMenuFieldFile | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" (click)="openTarefaDoTalhaoById()">
      <i class="agres-file"></i>
      <p>{{ 'fields.title' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" (click)="navigateToEditFieldPage()">
      <i class="agres-field-edit"></i>
      <p>{{ 'global.edit' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" (click)="navigateToTaskListWithFieldName()" [disabled]="!showButton">
      <i class="agres-field-edit"></i>
      <p>{{ 'fields.my-tasks' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
