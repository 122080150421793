<section>
  <app-button
    type="button"
    color="success"
    icon="agres-download"
    (btnClick)="generateToPdf()"
    title="{{ 'global.export' | translate }}">
  </app-button>
  <app-button
    type="button"
    color="return"
    icon="agres-return"
    (btnClick)="backField()"
    title="{{ 'global.back' | translate }}">
  </app-button>
</section>
<section id="bodyReport">
  <ng-container *ngIf="operation !== undefined">
    <div class="a-report">
      <div class="a-report__logo">
        <img src="../../../../../../assets/images/default/logo-isofarm.png" alt="IsoFarm" />
      </div>
      <div class="a-report__info">
        <p>
          <strong>{{ 'tasks.title' | translate }}</strong> {{ fieldName }}
        </p>
        <p>
          <strong>{{ 'tasks.title-report' | translate }}:</strong> {{ operation.startedAt | date: 'dd/MM/yyyy-HH:mm' }}
        </p>
        <p>
          <strong>{{ 'tasks.operator' | translate }}:</strong> {{ operation.operator.name }}
        </p>
      </div>
    </div>
    <div class="a-column--one">
      <table class="table">
        <tbody>
          <tr>
            <th>{{ 'tasks.operation-adversity' | translate }}:</th>
            <td>{{ operation.crop.adversity }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.phenological-phase' | translate }}:</th>
            <td>{{ operation.crop.stage }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.machine-equipment-model' | translate }}:</th>
            <td>{{ operation.machine.model }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.supplier-identification' | translate }}:</th>
            <td>{{ operation.product.supplier.name }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.active-defensive-principle' | translate }}</th>
            <td>{{ operation.product.activeIngredient }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.product-total-quantity' | translate }}</th>
            <td>{{ operation.application.total }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.product-dose' | translate }}:</th>
            <td>{{ operation.application.dosage }} {{ operation.application.dosageUnit }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.grace-period' | translate }}:</th>
            <td>{{ operation.application.waitingPeriod }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.start-time' | translate }}:</th>
            <td>{{ operation.startedAt | date: 'dd/MM/yyyy-HH:mm' }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.end-time' | translate }}:</th>
            <td>{{ operation.inishedAt | date: 'dd/MM/yyyy-HH:mm' }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.culture-name' | translate }}:</th>
            <td>{{ operation.crop.name }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.equipment-machine' | translate }}:</th>
            <td>{{ operation.machine.name }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.name-commercial' | translate }}:</th>
            <td>{{ operation.product.name }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.supplier-identification' | translate }}:</th>
            <td>{{ operation.product.supplier.name }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.concentration' | translate }}:</th>
            <td>{{ operation.product.concentration }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.used-quantity' | translate }}:</th>
            <td>{{ operation.application.rate }} / {{ operation.application.rateUnit }}</td>
          </tr>
          <tr>
            <th>{{ 'tasks.description' | translate }}:</th>
            <td id="exampleFormControlTextarea1">{{ tasks.description }}</td>
          </tr>
        </tbody>
      </table>
      <p>{{ 'global.create' | translate }}: {{ operation.createdBy }}</p>
    </div>
  </ng-container>
</section>
