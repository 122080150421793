import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-acesso-negado',
    templateUrl: './acesso-negado.component.html',
    styleUrls: ['./acesso-negado.component.css'],
})
export class AcessoNegadoComponent {
    constructor(public _location: Location) {}

    voltar() {
        this._location.back();
    }
}
