import { Injectable } from '@angular/core';
import { AgresSpinner, AgresSpinnerType } from './agres-spinner.model';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AgresSpinnerService {
  private readonly subject = new Subject<AgresSpinner>();
  private readonly defaultId = 'spinnerAgres';

  onAgresSpinner(id = this.defaultId): Observable<AgresSpinner> {
    return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
  }

  toShow(message: string) {
    this.show(new AgresSpinner({ type: AgresSpinnerType.Show, message }));
  }

  toHide() {
    this.clear();
  }

  show(spinner: AgresSpinner) {
    spinner.id = spinner.id || this.defaultId;
    this.subject.next(spinner);
  }

  clear(id = this.defaultId) {
    this.subject.next(new AgresSpinner({ id }));
  }
}
