<div class="row replay">
  <h1 class="replay--title">{{ 'tasks.replay.label-title' | translate }}</h1>
  <div class="replay--buttons">
    <ng-container *ngIf="replayController?.canPlay(); else showPause">
      <ng-container *ngIf="!replayController?.isRunning(); else playBlinking">
        <button
          class="ellipsis-cards"
          tooltip="{{ 'tasks.replay.play' | translate }}"
          (click)="replayController.play()">
          <i class="agres-play agres-2x"></i>
        </button>
      </ng-container>

      <ng-template #playBlinking>
        <button
          class="ellipsis-cards"
          tooltip="{{ 'tasks.replay.play' | translate }}"
          (click)="replayController.play()">
          <i class="agres-play agres-2x"></i>
        </button>
      </ng-template>
    </ng-container>

    <ng-template #showPause>
      <button
        class="ellipsis-cards"
        tooltip="{{ 'tasks.replay.pause' | translate }}"
        [disabled]="replayController?.canPlay()"
        (click)="replayController.pause()">
        <i class="agres-pause agres-2x"></i>
      </button>
    </ng-template>

    <button
      class="ellipsis-cards"
      tooltip="{{ 'tasks.replay.stop' | translate }}"
      [disabled]="!replayController?.canStop()"
      (click)="replayController.stop()">
      <i class="agres-stop agres-2x"></i>
    </button>

    <button
      class="ellipsis-cards"
      tooltip="{{ 'tasks.replay.step-backward' | translate }}"
      [disabled]="!replayController?.canStop()"
      (click)="replayController.stepOneBackwards()">
      <i class="agres-skip-back agres-2x"></i>
    </button>
    <button
      class="ellipsis-cards"
      tooltip="{{ 'tasks.replay.step-forward' | translate }}"
      [disabled]="!replayController?.canPlay() && !replayController?.canStop()"
      (click)="replayController.stepOneForward()">
      <i class="agres-skip-forward agres-2x"></i>
    </button>

    <ng-container *ngIf="replayController?.isRunning()">
      <button
        class="ellipsis-cards"
        tooltip="{{ 'tasks.replay.speed-down' | translate }}"
        [disabled]="!replayController?.canStop()"
        (click)="replayController.speedDown()">
        <i class="agres-fast-rewind agres-2x"></i>
      </button>
      <button
        class="ellipsis-cards"
        tooltip="{{ 'tasks.replay.speed-up' | translate }}"
        [disabled]="!replayController?.canStop()"
        (click)="replayController.speedUp()">
        <i class="agres-fast-forward agres-2x"></i>
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="replayController.isRunning()">
    <div class="row d-flex justify-content-center align-items-center replay--matSlider">
      <mat-slider
        class="replay-slider"
        step="2"
        min="0"
        [max]="replayController.getMaxIndex()"
        [value]="replayController?.getProgress()"
        (input)="replayController.progressBarClick($event.value)">
      </mat-slider>
      <p class="text-application-rate b map-label-1">
        {{ 'tasks.replay.current-replay-speed' | translate }} : {{ replayController?.getCurrentReproductionSpeed() }}x
      </p>
    </div>
  </ng-container>
</div>
