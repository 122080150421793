import { Injectable } from '@angular/core';

import { ServiceBase } from './service.base';
import { catchError, map } from 'rxjs/operators';
import { SupportFile, SupportFiles } from 'app/shared/models/support-file';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  UploadPayload,
  UploadResponse,
  DeleteResponse,
  SupportFileResponse,
} from '../../shared/interfaces/upload-file-support-page';

@Injectable({
  providedIn: 'root',
})
export class SupportPageService extends ServiceBase {
  public supportFileId: string;

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  getPresignedUrl(
    product: string,
    category: string,
    productCategory: string,
    version: string,
    date: string,
    fileName: string,
  ) {
    const url = `${this.UrlServiceV1}manage-blob-storage-files?containerName=support-file/${product}/${category}/${productCategory}/${version}/${date}`;

    return this.http
      .get(url, {
        params: { fileName },
        ...this.obterAuthHeader(),
      })
      .pipe(
        map(this.extractData<{ presignedUrl: string }>),
        catchError(this.serviceError),
      );
  }

  uploadFile(file: File, url: string): Observable<UploadResponse> {
    return this.http
      .put<UploadResponse>(url, file, {
        headers: new HttpHeaders({ 'X-Ms-Blob-Type': 'BlockBlob' }),
      })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getAllSupportFile() {
    return this.http
      .get(`${this.UrlServiceV1}support-files`, this.obterAuthHeader())
      .pipe(
        map(this.extractData<SupportFiles[]>),
        catchError(this.serviceError),
      );
  }

  getSupportFileById(id: string): Observable<SupportFileResponse> {
    const url = `${this.UrlServiceV1}support-files`;
    const params = new HttpParams().set('id', id);

    return this.http
      .get<SupportFileResponse>(url, { params, ...this.obterAuthHeader() })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  postSupportFile(supportFileData: UploadPayload): Observable<UploadResponse> {
    const url = `${this.UrlServiceV1}support-files/upload`;
    return this.http
      .put<UploadResponse>(url, supportFileData, this.obterAuthHeader())
      .pipe(
        catchError((error) => {
          if (error.status === 409) {
            this.translateService
              .get('global.support-duplicate-file')
              .subscribe((message) => {
                alert(message);
              });
          }
          return this.serviceError(error);
        }),
      );
  }

  updateSupportFile(id: string, updatedData: FormData) {
    const url = `${this.UrlServiceV1}support-files/update?id=${id}`;
    return this.http
      .put(url, updatedData, this.obterAuthHeader())
      .pipe(map(this.extractData<SupportFile>), catchError(this.serviceError));
  }

  checkIfExists(
    productId: string,
    categoryName: string,
  ): Observable<UploadPayload> {
    const url = `${this.UrlServiceV1}support-files?id=${productId}&category=${categoryName}`;
    return this.http.get<UploadPayload>(url, this.obterAuthHeader());
  }

  patchSupportFile(
    productId: string,
    data: FormData,
  ): Observable<UploadPayload> {
    const url = `${this.UrlServiceV1}support-files?id=${productId}`;
    return this.http.patch<UploadPayload>(url, data);
  }

  deleteSupportFile(
    productId: string,
    category: string,
    fileName: string,
    date: string,
    version: string,
  ): Observable<UploadPayload> {
    const url = `${this.UrlServiceV1}support-files/${productId}/${category}/${fileName}/${date}?version=${version}`;
    return this.http.delete<UploadPayload>(url, this.obterAuthHeader());
  }

  deleteFile(
    product: string,
    category: string,
    fileVersion: string,
    fileDate: string,
    filePath: string,
    productCategory: string,
  ): Observable<DeleteResponse> {
    const blobPath = `${productCategory}/${product}/${category}/${fileVersion}/${fileDate}/${filePath}`;
    return this.http
      .delete<DeleteResponse>(
        `${this.UrlServiceV1}support-files-blob/${encodeURIComponent(blobPath)}`,
        this.obterAuthHeader(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
}
