import { ConfirmacaoModalModule } from '../../../confirmacao-modal/confirmacao-modal..module';
import { ConfirmacaoModalService } from 'app/core/services/confirmacaoModal.service';
import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { DetalhesDaTarefasTalhoesComponent } from './detalhes-da-tarefa-talhoes/detalhes-da-tarefa-talhoes.component';

import { ListagemTarefaTalhaoComponent } from './listagem-tarefa-talhao/listagem-tarefa-talhao.component';
import { AppSharedModule } from 'app/app-shared.module';
import { CriarEditarTarefasTalhaoComponent } from './criarEditar-tarefa-talhao/criarEditar-tarefa-talhao.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
  TranslateService,
} from '@ngx-translate/core';
import { HttpLoaderFactory } from 'app/app.module';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    ListagemTarefaTalhaoComponent,
    DetalhesDaTarefasTalhoesComponent,
    CriarEditarTarefasTalhaoComponent,
  ],

  imports: [
    BrowserModule,
    CommonModule,
    AppSharedModule,
    ConfirmacaoModalModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  providers: [TranslatePipe, TranslateService],

  exports: [ListagemTarefaTalhaoComponent],
})
export class GerenciamentoDeTalhoesModule {}
