import { Component, Input } from '@angular/core';
import { NavigationTabModel } from './navigation-tab/navigation-tab.model';

@Component({
    selector: 'app-navigations',
    templateUrl: './navigations.component.html',
})
export class NavigationsComponent {
    @Input() currentTab: string;
    navigationTabInfo: NavigationTabModel;

  @Input() set navigationTab(value: NavigationTabModel) {
        if (value) this.navigationTabInfo = value;
    }
}
