<app-agres-spinner></app-agres-spinner>
<ng-container *ngIf="registrarForm && registrarForm.contains('addressFormGroup')">
  <form [formGroup]="registrarForm" novalidate (ngSubmit)="updateUser()">
    <div class="notify notify__warning" role="alert">
      <div class="notify__warning">
        <p>{{ 'global.alert.users-premium-msg-nvg' | translate }}</p>
      </div>
    </div>
    <ng-container formGroupName="addressFormGroup">
      <div class="field">
        <div class="field__label">{{ 'global.country' | translate }}</div>
        <div class="field__wrap">
          <div class="field__select">
            <select formControlName="country" title="{{ 'global.country' | translate }}">
              <option *ngFor="let country of countries" [value]="country">
                {{ 'global.country_codes.' + country | translate }}
              </option>
            </select>
          </div>
          <span
            class="field__input--alert"
            *ngIf="displayMessage.country && !outsideBrazil"
            [innerHTML]="displayMessage.country">
          </span>
        </div>
      </div>
    </ng-container>
    <div class="field">
      <div class="field__label">{{ 'global.identification' | translate }}</div>
      <div class="field__wrap">
        <input
          type="text"
          class="field__input"
          formControlName="documentIdentification"
          [mask]="outsideBrazil ? '' : getIdentificationMask()"
          id="cpfCnpj"
          aria-label="cpfCnpj"
          maxlength="18" />
        <span
          class="field__input--alert"
          *ngIf="displayMessage.documentIdentification"
          [innerHTML]="displayMessage.documentIdentification">
        </span>
      </div>
    </div>
    <div class="field">
      <div class="field__label">{{ 'global.company-name' | translate }}</div>
      <div class="field__wrap">
        <input class="field__input" type="text" formControlName="company" title="{{ 'global.phone' | translate }}" />
        <span class="field__input--alert" *ngIf="displayMessage.company" [innerHTML]="displayMessage.company"> </span>
      </div>
    </div>
    <div class="field">
      <div class="field__label">{{ 'global.phone' | translate }}</div>
      <div class="field__wrap">
        <input
          class="field__input"
          type="tel"
          formControlName="phoneNumber"
          aria-label="phoneNumber"
          (input)="applyPhoneMask($event)" />
        <span class="field__input--alert" *ngIf="displayMessage.phoneNumber" [innerHTML]="displayMessage.phoneNumber">
        </span>
      </div>
    </div>
    <div class="field__space"></div>
    <ng-container formGroupName="addressFormGroup">
      <div class="field">
        <div class="field__label">{{ 'global.postalCode' | translate }}</div>
        <div class="field__wrap">
          <input
            type="text"
            class="field__input"
            formControlName="postalCode"
            id="cep"
            aria-label="cep"
            (blur)="outsideBrazil ? '' : checkCep($event)" />
          <span
            class="field__input--alert"
            *ngIf="displayMessage.postalCode && !outsideBrazil"
            [innerHTML]="displayMessage.postalCode">
          </span>
        </div>
      </div>
      <div class="field">
        <div class="field__label">{{ 'global.city' | translate }}</div>
        <div class="field__wrap">
          <input type="text" class="field__input" formControlName="city" aria-label="city" />
          <span
            class="field__input--alert"
            *ngIf="displayMessage.city && !outsideBrazil"
            [innerHTML]="displayMessage.city">
          </span>
        </div>
      </div>
      <div class="field">
        <div class="field__label">{{ 'global.state' | translate }}</div>
        <div class="field__wrap">
          <input type="text" class="field__input" formControlName="stateOrProvince" aria-label="stateOrProvince" />
          <span
            class="field__input--alert"
            *ngIf="displayMessage.stateOrProvince && !outsideBrazil"
            [innerHTML]="displayMessage.stateOrProvince">
          </span>
        </div>
      </div>
    </ng-container>
    <div class="hidden__mobile">
      <div class="buttons__top">
        <app-button
          type="submit"
          color="primary"
          icon="agres-save"
          styleClass="field__btns--margin"
          title="{{ 'global.save' | translate }}"
          [disabled]="disableSubmitButton || !(registrarForm.valid && registrarForm.dirty)"
          data-bs-dismiss="modal">
        </app-button>
        <app-button
          type="button"
          color="danger"
          icon="agres-close"
          styleClass="field__btns--margin"
          title="{{ 'global.cancel' | translate }}"
          data-bs-dismiss="modal"
          (btnClick)="hideOffCanvas()">
        </app-button>
      </div>
    </div>
  </form>
  <app-menu-pwa>
    <div class="pwa__list--info">
      <button
        type="submit"
        (click)="updateUser()"
        [disabled]="disableSubmitButton || !(registrarForm.valid && registrarForm.dirty)"
        data-bs-dismiss="modal">
        <i class="agres-save"></i>
        <p>{{ 'global.save' | translate }}</p>
      </button>
    </div>
    <div class="pwa__list--info">
      <button type="button" (click)="hideOffCanvas()" data-bs-dismiss="modal">
        <i class="agres-close"></i>
        <p>{{ 'global.cancel' | translate }}</p>
      </button>
    </div>
  </app-menu-pwa>
</ng-container>
