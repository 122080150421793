import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mapas-recomendacao',
  templateUrl: './mapas-recomendacao.component.html',
  styleUrls: ['./mapas-recomendacao.component.css'],
})
export class MapasRecomendacaoComponent {
  constructor(public translateService: TranslateService) {
    this.translateService.get('global.home').subscribe((home: string) => {
      var home = home;
      this.translateService
        .get('mapas-recomendacao.title-page')
        .subscribe((res: string) => {
          var title = res;
          this.bindHTMLTitle(home, title);
        });
    });
  }

  bindHTMLTitle(home, title) {
    jQuery('.breadcrumb').html(
      `<li><a href="#">${home}</a></li><li class="active"><strong>${title}</strong></li>`,
    );
  }
}
