<app-alert></app-alert>
<div class="auth">
  <div class="auth__wrap">
    <div class="auth__wrap--pic">
      <div class="auth__wrap--pic">
        <div class="auth__wrap--pic">
          <img alt="Agres" src="./assets/images/default/logo-isofarm.svg" />
        </div>
        <div class="auth__wrap--language">
          <a (click)="changeLanguage(languageOptions.PT_BR)">
            <img
              src="./assets/images/languages/ptBR.png"
              alt="Português"
              [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languageOptions.PT_BR) }" />
          </a>
          <a (click)="changeLanguage(languageOptions.EN)">
            <img
              src="./assets/images/languages/en.png"
              alt="English"
              [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languageOptions.EN) }" />
          </a>
          <a (click)="changeLanguage(languageOptions.ES)">
            <img
              src="./assets/images/languages/es.png"
              alt="Spanish"
              [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languageOptions.ES) }" />
          </a>
          <a (click)="changeLanguage(languageOptions.IT)">
            <img
              src="./assets/images/languages/it.png"
              alt="Spanish"
              [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languageOptions.IT) }" />
          </a>
        </div>
        <div class="auth__wrap--heading">{{ 'forgot.title' | translate }}</div>
        <form [formGroup]="newPasswordForm" (ngSubmit)="newPassword()">
          <div class="field">
            <div class="field__label">
              {{ 'forgot.title-new-password' | translate }}
            </div>
            <div class="field__wrap">
              <input
                [type]="isPasswordVisible ? 'text' : 'password'"
                class="field__input"
                formControlName="Senha"
                (keypress)="verifyMaxLength($event)"
                minlength="6"
                maxlength="16" />
              <span class="field__view">
                <app-agres-icons
                  styleClass="field__view"
                  [ngClass]="isPasswordVisible ? 'agres-eye' : 'agres-eye-off'"
                  (click)="togglePassword()">
                </app-agres-icons>
              </span>
            </div>
            <span class="field__input--alert" *ngIf="displayMessage.Senha">
              {{ displayMessage.Senha }}
            </span>
          </div>
          <div class="field">
            <div class="field__label">
              {{ 'forgot.title-new-password-confirm' | translate }}
            </div>
            <div class="field__wrap">
              <input
                [type]="isConfirmPasswordVisible ? 'text' : 'password'"
                class="field__input"
                formControlName="ConfirmarSenha"
                (keypress)="verifyMaxLength($event)"
                minlength="6"
                maxlength="16" />
              <span class="field__view">
                <app-agres-icons
                  styleClass="field__view"
                  [ngClass]="isConfirmPasswordVisible ? 'agres-eye' : 'agres-eye-off'"
                  (click)="toggleConfirmPassword()">
                </app-agres-icons>
              </span>
            </div>
            <span class="field__input--alert" *ngIf="displayMessage.ConfirmarSenha">
              {{ displayMessage.ConfirmarSenha }}
            </span>
          </div>
          <app-button
            id="enviar"
            type="submit"
            color="primary"
            size="full"
            icon="agres-action"
            [disabled]="!newPasswordForm.valid || blockBtnReset"
            title="{{ 'forgot.title-update-button' | translate }}"
            *ngIf="!sendSuccess"></app-button>
          <app-button
            color="return"
            size="full"
            action="reload"
            icon="agres-reload"
            title="{{ 'forgot.title-update-button-executing' | translate }}"
            [disabled]="blockBtnReset"
            *ngIf="sendSuccess"></app-button>
        </form>
      </div>
    </div>
    <div class="auth__wrap--copyright">
      <app-link link="https://agres.com.br/quem_somos/" target="_blank" title="{{ 'global.copyright' | translate }}">
      </app-link>
    </div>
  </div>
</div>
