// @ts-nocheck
import { Component, Input } from '@angular/core';
import { MachineTabModel } from './machine-tab.model';

interface WaterTemperatureData {
    data: Array<number>;
    borderWidth?: number;
    pointStyle?: string;
}
interface RpmData {
    data: Array<number>;
    borderWidth?: number;
    pointStyle?: string;
}
interface OilPressureData {
    data: Array<number>;
    borderWidth?: number;
    pointStyle?: string;
}
interface SpeedData {
    data: Array<string>;
    borderWidth?: number;
    pointStyle?: string;
}

@Component({
    selector: 'app-machine-tab',
    templateUrl: './machine-tab.component.html',
    styleUrls: ['./machine-tab.component.scss'],
})
export class MachineTabComponent {
    machine: MachineTabModel;
    public currentDevice = '';

  @Input() set machineTab(value: MachineTabModel) {
        if (value) this.graphSetData(value);
    }

    graphSetData(machine: MachineTabModel): void {
        this.machine = machine;

        if (this.currentDevice !== this.machine.deviceId) {
            this.currentDevice = this.machine.deviceId;
        }

        this.machine.speed = Number(this.machine.speed).toFixed(1);
    }
}
