import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {

  @Input() styleClass: string;
  @Input() iconClass: string;
  @Input() title: string;
   
  constructor() { }
}
