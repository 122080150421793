import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeusEquipamentosComponent } from './meus-equipamentos/meus-equipamentos.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from 'app/app.module';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { AppSharedModule } from 'app/app-shared.module';

registerLocaleData(localeDe, 'de');
@NgModule({
    declarations: [MeusEquipamentosComponent],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppSharedModule
    ],

    providers: [
        TranslatePipe,
        TranslateService
    ],

    exports: [MeusEquipamentosComponent],
})
export class AdministrativoModule {}
