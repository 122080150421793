import { OpType, OperationType } from '../../enums/operation-type.enum';

export class DynamicMessageOperationSidePanelModel {
  constructor(
    public operationType: OperationType = OpType.SPRAYING,
    public appliedVolume: number = 0,
    public speed: number = 0,
    public coveredArea: number = 0,
    public rpm: number = 0,
  ) {}
}

export class StaticMessageOperationSidePanelModel {
  constructor(public operatorName: string = '') {}
}
