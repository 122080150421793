<div class="tabMachine">
  <div class="slider">
    <div class="slider__item">
      <p class="slider__item--text">{{ 'monitoring.tabs.machine.rpm' | translate }}</p>
      <hr />
      <div class="slider__item--info">
        <h1>{{ machine?.rpm }}</h1>
      </div>
    </div>
    <div class="slider__item">
      <p class="slider__item--text">{{ 'monitoring.tabs.machine.temp-water' | translate }}</p>
      <hr />
      <div class="slider__item--info">
        <h1>{{ machine?.waterTemperature }} &#8451;</h1>
      </div>
    </div>
    <div class="slider__item">
      <p class="slider__item--text">{{ 'monitoring.tabs.machine.pression' | translate }}</p>
      <hr />
      <div class="slider__item--info">
        <h1>{{ machine?.oilPressure }} &#13225;</h1>
      </div>
    </div>
    <div class="slider__item">
      <p class="slider__item--text">{{ 'monitoring.tabs.machine.battery' | translate }}</p>
      <hr />
      <div class="slider__item--info">
        <h1>{{ machine?.batteryVoltage }}v</h1>
      </div>
    </div>
  </div>
</div>
