import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-prototype',
  templateUrl: './card-prototype.component.html',
  styleUrls: ['./card-prototype.component.scss']
})
export class CardPrototypeComponent {

  @Input() styleClass: string;

  constructor() { }
}
