export class MapLoaderService {
    public static promise: Promise<any>;
    public static load(): Promise<any> {
        let map = {
            URL: 'https://maps.googleapis.com/maps/api/js?&libraries=geometry,drawing&key=AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo&callback=initAutocomplete&sensor=false',
        };
        if (!this.promise) {
            this.promise = new Promise((resolve) => {
                this.loadScript(map.URL);
                window['initAutocomplete'] = ($event) => {
                    resolve('google maps api loaded');
                };
            });
        }
        return this.promise;
    }

    static loadScript(src, callback?): void {
        var s: any, r, t;
        r = false;
        s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = src;
        s.onload = s.onreadystatechange = function () {
            if (!r && (!this.readyState || this.readyState == 'complete')) {
                r = true;
                if (typeof callback === 'function') callback();
            }
        };
        t = document.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
    }
}
