import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { FileManagerService } from 'app/core/services/file-manager.service';
import { AgresSpinnerCanvasService } from 'app/shared/components/agres-spinner-canvas/agres-spinner-canvas.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { ShapefileReader } from 'app/utils/shapefile-reader';
import { UserFilesManager } from 'app/utils/user-files-manager';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-upload-field-files',
  templateUrl: './upload-field-files.component.html',
  styleUrls: ['./upload-field-files.component.scss'],
})
export class UploadFieldFilesComponent {
  @Input() offCanvasUploadArquivos = new Subject<boolean>();
  @Input() listOfFiles: Array<string>;
  @Input() fieldKey: string;
  @Input() geometry;
  @Output() updateFilesList = new EventEmitter();

  public fileSelected = false;
  public shapefileForm: FormGroup;

  filesReceived: Array<File> = [];
  userLogged: string;
  baseUrl: string;
  errorMessage = 'global.alert.msg-unexpected-error';
  private cloudantManager: UserFilesManager;
  filesRequireds = false;
  private readonly shapefileReader;
  fileClassification: {
    recommendation_files: Array<string>;
    guides_files: Array<string>;
    boundaries: Array<string>;
  } = null;
  allFiles = false;
  message: string;
  isFarFromShapefile: any;

  constructor(
    public translateService: TranslateService,
    public fileManagerService: FileManagerService,
    public alertService: AlertService,
    public loadingAgresSpinnerCanvas: AgresSpinnerCanvasService,
    public httpClient: HttpClient,
  ) {
    this.cloudantManager = new UserFilesManager(fileManagerService, httpClient);
    this.shapefileReader = new ShapefileReader();
  }

  ngOnInit() {
    this.userLogged = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    ).id;
  }

  handleFileUpload(event) {
    this.fileSelected = true;
    for (const file of event.target.files) {
      const newName = file.name
        .replace(/\s+/g, '_')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const newFile = new File([file], newName);
      this.filesReceived.push(newFile);
    }
    this.allFiles = this.checkRequiredFiles(this.filesReceived);
    if (this.allFiles) {
      this.checkIfFilesIsNearOfField().then((value) => {
        if (!value) {
          this.filesRequireds = false;
          this.getTypeOfFile().then((array) => {
            if (
              array.guides_files.length > 0 ||
              array.recommendation_files.length > 0
            ) {
              this.fileClassification = array;
            } else {
              this.allFiles = false;
              this.filesRequireds = true;
              this.fileSelected = false;
              this.filesReceived = [];
              this.message = 'fields.missing-requireds-files';
            }
          });
        } else {
          this.message = 'fields.map-too-far-from-reference';
          this.allFiles = false;
          this.filesRequireds = true;
          this.fileSelected = false;
          this.filesReceived = [];
        }
      });
    } else {
      this.message = 'fields.missing-requireds-files';
      this.filesRequireds = true;
      this.fileSelected = false;
      this.filesReceived = [];
    }
  }

  hideModal() {
    this.fileSelected = false;
    this.offCanvasUploadArquivos.next(false);
  }

  createArrayToUpload() {
    if (this.filesReceived) {
      this.translateService.get('global.loading.map-wait').subscribe((res) => {
        this.loadingAgresSpinnerCanvas.toShow(res);
      });
      this.fileSelected = false;
      this.uploadFiles(this.filesReceived);
    }
  }

  async uploadFiles(filesToUpload: Array<File>) {
    if (filesToUpload) {
      this.cloudantManager
        .saveDataAndUploadUserFiles(
          this.userLogged,
          filesToUpload,
          'fields',
          this.fieldKey,
          this.fileClassification,
        )
        .subscribe(
          () => {
            this.feedBackToUser('global.alert.send-success', 'success');
            this.filesReceived = [];
            this.offCanvasUploadArquivos.next(false);
            this.loadingAgresSpinnerCanvas.toHide();
            this.updateFilesList.emit();
          },
          (error) => {
            console.error('Error:', error);
          },
        );
    }
  }

  private feedBackToUser(message: string, type: string) {
    this.translateService.get(message).subscribe((res: string) => {
      switch (type) {
        case 'success':
          this.alertService.success(res);
          break;
        case 'error':
          this.alertService.error(res);
          break;
        case 'info':
          this.alertService.info(res);
          break;
        default:
          this.alertService.info(res);
          break;
      }
    });
  }

  checkRequiredFiles(files: Array<File>) {
    const requiredExtensions = ['.shp', '.dbf', '.shx'];
    const fileNames = files.map((file) => file.name.toLowerCase());
    const baseNames = {};

    for (const extension of requiredExtensions) {
      const matchingFiles = fileNames.filter((fileName) =>
        fileName.endsWith(extension),
      );

      if (matchingFiles.length === 0) {
        return false;
      }

      const baseName = matchingFiles[0].slice(0, -extension.length);

      baseNames[extension] = baseName;
    }

    const firstBaseName = baseNames[requiredExtensions[0]];
    const allMatch = requiredExtensions.every((extension) => {
      return baseNames[extension] === firstBaseName;
    });

    return allMatch;
  }

  async getTypeOfFile() {
    let recommendationFiles = [];
    let guidesFiles = [];

    for (const file of this.filesReceived) {
      if (file.name.endsWith('.shp')) {
        const shpContent = await this.shapefileReader.openShapefile(file);
        const geometryType = shpContent[0].geometry.type;

        const relatedFiles = this.filesReceived.filter((item) =>
          item.name.startsWith(file.name.split('.')[0] + '.'),
        );

        if (geometryType === 'Polygon' || geometryType === 'MultiPolygon') {
          if (shpContent.length > 1) {
            recommendationFiles = recommendationFiles.concat(relatedFiles);
          }
        } else if (
          geometryType === 'LineString' ||
          geometryType === 'MultiLineString'
        ) {
          guidesFiles = guidesFiles.concat(relatedFiles);
        }
      }
    }

    recommendationFiles = recommendationFiles.map((file) => file.name);
    guidesFiles = guidesFiles.map((file) => file.name);

    return {
      recommendation_files: recommendationFiles,
      guides_files: guidesFiles,
      boundaries: [],
    };
  }

  async checkIfFilesIsNearOfField() {
    let isFarFromShapefile;
    for (const file of this.filesReceived) {
      if (file.name.endsWith('.shp')) {
        const shpContent = await this.shapefileReader.openShapefile(file);
        const shapefileCoordinate =
          this.shapefileReader.getCoordinatesFromShapefile(shpContent);
        const referenceCoordinate = {
          lat: this.geometry.features[0].geometry.coordinates[0][0][1],
          lng: this.geometry.features[0].geometry.coordinates[0][0][0],
        };
        isFarFromShapefile =
          this.shapefileReader.isReferenceCoordinateFarFromShapefile(
            referenceCoordinate,
            shapefileCoordinate,
          );
      }
    }
    return isFarFromShapefile;
  }
}
