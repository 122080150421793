<div class="agr-channel">
  <div class="agr-channel__info">
    <div class="agr-channel__info--content">
      <h2 class="agr-channel__info--content---title">{{ 'tutorials.service_channel' | translate }}</h2>
      <p class="agr-channel__info--content---subtitle">{{ 'tutorials.service_channel_text' | translate }}</p>
    </div>
    <div class="agr-channel__info--image">
      <div class="agr-channel__info--image---circle"></div>
    </div>
  </div>
</div>
<div class="agr-listTutorial">
  <div class="a-column--collumn">
    <div class="agr-listTutorial__card">
      <div class="agr-listTutorial__card--body">
        <p>
          <i class="agres-map-pin"></i>&nbsp; <strong>{{ 'global.address' | translate }}</strong>
        </p>
        <small>
          <a href="https://g.page/AgresSistemasEletronicosLtda" target="_blank">
            Av. Com. Franco, 6720 - Uberaba, Curitiba - PR</a
          >
        </small>
        <hr />
        <p>
          <i class="agres-mail"></i>&nbsp; <strong>{{ 'global.mail' | translate }}</strong>
        </p>
        <small>
          <a href="mailto:relacionamento@agres.com.br" target="_blank"> relacionamento@agres.com.br </a>
        </small>
        <hr />
        <p>
          <i class="agres-smartphone"></i>&nbsp; <strong>{{ 'global.phone' | translate }}</strong>
        </p>
        <small>
          <a href="https://web.whatsapp.com/send?phone=554131323302&text&app_absent=0" target="_blank">
            &nbsp; +55 41 3132 - 3302
          </a>
        </small>
        <hr />
        <p>
          <i class="agres-link"></i>&nbsp; <strong>{{ 'global.web_site' | translate }}</strong>
        </p>
        <small>
          <a href="https://www.agres.com.br" target="_blank"> &nbsp; https://www.agres.com.br </a>
        </small>
      </div>
    </div>
    <div class="agr-listTutorial__card">
      <div class="agr-listTutorial__card--body">
        <form [formGroup]="registerForm" (ngSubmit)="send()">
          <div class="agr--input">
            <label for="default-input">
              {{ 'global.name' | translate }}
            </label>
            <div class="agr--input__group">
              <div class="agr--input__group--icon">
                <app-agres-icons iconsClass="agres-user"></app-agres-icons>
              </div>
              <input
                formControlName="name"
                type="text"
                [ngClass]="{ 'agr--input__group--input---error': displayMessage.name }"
                class="agr--input__group--input"
                required="true" />
            </div>
            <span class="text-danger" *ngIf="displayMessage.name" [innerHTML]="displayMessage.name"></span>
          </div>
          <div class="agr--input">
            <label for="default-input">
              {{ 'global.mail' | translate }}
            </label>
            <div class="agr--input__group">
              <div class="agr--input__group--icon">
                <app-agres-icons iconsClass="agres-mail"></app-agres-icons>
              </div>
              <input
                formControlName="email"
                type="email"
                [ngClass]="{ 'agr--input__group--input---error': displayMessage.email }"
                class="agr--input__group--input"
                required="true" />
            </div>
            <span class="text-danger" *ngIf="displayMessage.email" [innerHTML]="displayMessage.email"></span>
          </div>
          <div class="agr--input">
            <label for="default-input">
              {{ 'global.phone' | translate }}
            </label>
            <div class="agr--input__group">
              <div class="agr--input__group--icon">
                <app-agres-icons iconsClass="agres-smartphone"></app-agres-icons>
              </div>
              <input
                formControlName="phoneNumber"
                type="text"
                [ngClass]="{ 'agr--input__group--input---error': displayMessage.phoneNumber }"
                class="agr--input__group--input"
                required="true" />
            </div>
            <span
              class="text-danger"
              *ngIf="displayMessage.phoneNumber"
              [innerHTML]="displayMessage.phoneNumber"></span>
          </div>
          <div class="agr--input">
            <label for="default-input">
              {{ 'tutorials.subject' | translate }}
            </label>
            <div class="agr--input__group">
              <div class="agr--input__group--icon">
                <app-agres-icons iconsClass="agres-file"></app-agres-icons>
              </div>
              <select class="agr--input__group--input" formControlName="subject">
                <option *ngFor="let subject of subjects; let i = index" [value]="subject.value">
                  {{ subject.name | translate }}
                </option>
              </select>
            </div>
            <span class="text-danger" *ngIf="displayMessage.subject" [innerHTML]="displayMessage.subject"></span>
          </div>
          <div class="agr--input">
            <label for="default-input">
              {{ 'tutorials.message-label' | translate }}
            </label>
            <div class="agr--input__group">
              <textarea rows="6" class="input-field" formControlName="message"> </textarea>
            </div>
            <span class="text-danger" *ngIf="displayMessage.message" [innerHTML]="displayMessage.message"></span>
          </div>
          <div class="agr--input">
            <app-button
              type="submit"
              class="primary"
              size="full"
              title="{{ 'global.send' | translate }}"
              [disabled]="btnSend || !registerForm.valid">
            </app-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
