import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { User } from 'app/shared/models/user';
import { MatDialog } from '@angular/material/dialog';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import {
  UploadPayload,
  SupportFileResponse,
  FileMetaData,
} from 'app/shared/interfaces/upload-file-support-page';
import { SupportPageService } from 'app/core/services/support-page.service';

@Component({
  selector: 'app-form-upload-file',
  templateUrl: './form-upload-file.component.html',
  styleUrls: ['./form-upload-file.component.scss'],
})
export class FormUploadFileComponent {
  uploadFormFile: FormGroup;
  disableButton = true;
  defaultImage: string | ArrayBuffer = '';
  fileUploaded: string | ArrayBuffer = '';
  loggedUser: User;
  fileName: string;
  fileSize: number = 1024;
  selectedLanguage = 'ptBR';
  allLanguagesFilled = false;

  selectedProductLanguages: string[] = [];

  readonly allowedTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/msword', // .doc
    'application/vnd.ms-excel', // .xls
    'text/plain', // .txt
    'text/csv', // .csv
  ];

  readonly maxFileSize = 1 * this.fileSize * this.fileSize;

  products = [
    { value: 'AgroNave 7', label: 'AgroNave 7', category: 'onboardComputers' },
    {
      value: 'AgroNave 12',
      label: 'AgroNave 12',
      category: 'onboardComputers',
    },
    {
      value: 'AgroNave 12W',
      label: 'AgroNave 12W',
      category: 'onboardComputers',
    },
    {
      value: 'AgroNave PRO',
      label: 'AgroNave PRO',
      category: 'onboardComputers',
    },
    {
      value: 'AgroNave UW',
      label: 'AgroNave UW',
      category: 'onboardComputers',
    },
    {
      value: 'AgroNave',
      label: 'AgroNave',
      category: 'onboardComputers',
    },
    { value: 'IsoView', label: 'IsoView', category: 'onboardComputers' },
    { value: 'GeoNave', label: 'GeoNave', category: 'onboardComputers' },
    { value: 'IsoLink', label: 'IsoLink', category: 'connectivity' },
    {
      value: 'IsoBox Sprayer',
      label: 'IsoBox Sprayer',
      category: 'ecu',
    },
    {
      value: 'IsoBox Spreader',
      label: 'IsoBox Spreader',
      category: 'ecu',
    },
    {
      value: 'IsoBox Hydraulic Control',
      label: 'IsoBox Hydraulic Control',
      category: 'ecu',
    },
    {
      value: 'IsoBox Steer',
      label: 'IsoBox Steer',
      category: 'ecu',
    },
    { value: 'ANP21 ANR10', label: 'ANP 21 + ANR 10', category: 'antennas' },
    { value: 'ANP40 ANR10', label: 'ANP 40 + ANR 10', category: 'antennas' },
    {
      value: 'Smart Antenna ANR20',
      label: 'Smart Antenna ANR20',
      category: 'antennas',
    },
    {
      value: 'Smart Antenna ANR30',
      label: 'Smart Antenna ANR30',
      category: 'antennas',
    },
    { value: 'Spray Rate', label: 'Spray Rate', category: 'controller' },
    {
      value: 'Spray Rate Mini',
      label: 'Spray Rate Mini',
      category: 'controller',
    },
    {
      value: 'Strong Box',
      label: 'Strong Box',
      category: 'switches',
    },
  ];

  productCategory = [
    { value: 'onboardComputers', label: 'Computadores de Bordo' },
    { value: 'antennas', label: 'Antenas' },
    { value: 'controller', label: 'Controladores' },
    { value: 'switches', label: 'Comutadores' },
    { value: 'ecu', label: 'ECU' },
    { value: 'connectivity', label: 'Conectividades' },
  ];

  allLanguages = [
    {
      code: 'ptBR',
      shortCode: 'PT',
      name: 'Português',
      flag: '../../../../../assets/images/support-page/flags/PT.png',
    },
    {
      code: 'en',
      shortCode: 'EN',
      name: 'English',
      flag: '../../../../../assets/images/support-page/flags/EN.png',
    },
    {
      code: 'es',
      shortCode: 'ES',
      name: 'Español',
      flag: '../../../../../assets/images/support-page/flags/ES.png',
    },
    {
      code: 'it',
      shortCode: 'IT',
      name: 'Italiano',
      flag: '../../../../../assets/images/support-page/flags/IT.png',
    },
    {
      code: 'fr',
      shortCode: 'FR',
      name: 'Français',
      flag: '../../../../../assets/images/support-page/flags/FR.png',
    },
    {
      code: 'de',
      shortCode: 'DE',
      name: 'Deutsch',
      flag: '../../../../../assets/images/support-page/flags/DE.png',
    },
    {
      code: 'pl',
      shortCode: 'PL',
      name: 'Polski',
      flag: '../../../../../assets/images/support-page/flags/PL.png',
    },
    {
      code: 'ru',
      shortCode: 'RU',
      name: 'Русский',
      flag: '../../../../../assets/images/support-page/flags/RU.png',
    },
    {
      code: 'nl',
      shortCode: 'NL',
      name: 'Nederlands',
      flag: '../../../../../assets/images/support-page/flags/NL.png',
    },
  ];

  languages = this.allLanguages.filter((lang) =>
    ['ptBR', 'en', 'es', 'it'].includes(lang.code),
  );
  productLanguages = this.allLanguages.map((lang) => ({
    code: lang.shortCode,
    name: lang.name,
    flag: lang.flag,
  }));

  constructor(
    private readonly fb: FormBuilder,
    private readonly supportPageService: SupportPageService,
    private readonly translateService: TranslateService,
    public readonly alertService: AlertService,
    private readonly dialog: MatDialog,
  ) {
    this.uploadFormFile = this.fb.group({
      category: ['', Validators.required],
      product: ['', Validators.required],
      productCategory: [],
      productImage: [],
      version: ['', Validators.required],
      file: [null, Validators.required],
      date: ['', Validators.required],
      fileMetadataByLanguage: this.fb.group({}),
    });

    this.uploadFormFile
      .get('product')
      ?.valueChanges.subscribe((selectedProduct) => {
        if (!selectedProduct) return;

        const product = this.products.find((p) => p.value === selectedProduct);

        if (product) {
          this.uploadFormFile.patchValue({ productCategory: product.category });
        }
      });

    this.uploadFormFile
      .get('product')
      ?.valueChanges.subscribe((selectedProduct) => {
        if (!selectedProduct) return;

        const product = this.products.find((p) => p.value === selectedProduct);
        const productName = product?.label
          .replace(/_/g, '')
          .replace(/\+/g, '')
          .replace(/\s+/g, '_')
          .toLowerCase();

        if (product) {
          this.uploadFormFile.patchValue({ productImage: productName });
        }
      });

    this.initializeLanguageForms();
  }

  initializeLanguageForms() {
    const fileMetadataByLanguageGroup = this.uploadFormFile.get(
      'fileMetadataByLanguage',
    ) as FormGroup;
    this.languages.forEach((lang) => {
      fileMetadataByLanguageGroup.addControl(
        lang.code,
        this.fb.group({
          fileName: ['', Validators.required],
          description: ['', Validators.required],
        }),
      );
    });
  }

  setLanguage(langCode: string) {
    this.selectedLanguage = langCode;
  }

  ngOnInit(): void {
    const today = new Date();
    const dateFormated = today.toISOString().split('T')[0];
    this.uploadFormFile.get('category')?.valueChanges.subscribe((value) => {
      this.uploadFormFile.get('date')?.setValue(dateFormated);
      this.uploadFormFile.get('date')?.updateValueAndValidity();
    });
    this.defaultImage = 'assets/images/default-image.png';
    this.getAllSupportFileData();
    this.loggedUser = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
  }

  checkLanguages() {
    this.allLanguagesFilled = this.languages.every((lang) => {
      const metadata = this.uploadFormFile
        .get('fileMetadataByLanguage')
        ?.get(lang.code)?.value;
      return metadata?.fileName?.trim() && metadata?.description?.trim();
    });
  }

  onLanguageChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.selectedProductLanguages.push(checkbox.value);
    } else {
      this.selectedProductLanguages = this.selectedProductLanguages.filter(
        (lang) => lang !== checkbox.value,
      );
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files && input.files.length > 0) {
      const files = Array.from(input.files);
      const validFiles: File[] = [];
      const invalidFiles: string[] = [];

      files.forEach((file) => {
        if (this.validateFile(file)) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file.name);
        }
      });

      if (validFiles.length > 0) {
        const file = validFiles[0];
        this.fileName = file.name;

        this.uploadFormFile.patchValue({ file: file });
        this.uploadFormFile.get('file')?.updateValueAndValidity();
      }

      if (invalidFiles.length > 0) {
        const msgAlert = this.translateService.instant(
          'global.support-error-file-not-accepted',
        );
        alert(`${msgAlert}:\n${invalidFiles.join('\n')}`);
      }
    }
  }

  validateFile(file: File): boolean {
    const isTypeValid = this.allowedTypes.includes(file.type);
    const isSizeValid = file.size <= this.maxFileSize;

    if (!isTypeValid) {
      this.translateService
        .get('global.support-error-file-not-accepted')
        .subscribe((msg) => {
          alert(msg);
        });
    }

    if (!isSizeValid) {
      this.translateService
        .get('global.support-error-file-size')
        .subscribe((msg) => {
          alert(msg);
        });
    }

    return isTypeValid && isSizeValid;
  }

  getAllSupportFileData(id?: string) {
    this.supportPageService.getAllSupportFile().subscribe(
      (data) => {},
      (error) => {
        console.error(error);
      },
    );
  }

  private sendFileToBlobStorage() {
    const formValues = this.uploadFormFile.value;
    const file = formValues.file;
    const { product, version, date, category, productCategory } = formValues;
    this.supportPageService
      .getPresignedUrl(
        productCategory,
        product,
        category,
        version,
        date,
        this.fileName,
      )
      .subscribe(
        (data) => {
          const { presignedUrl } = data;

          this.supportPageService.uploadFile(file, presignedUrl).subscribe(
            () => {
              this.translateService
                .get('global.alert.send-file-upload-success')
                .subscribe((msg) => this.alertService.success(msg));
              this.uploadFormFile.reset();
              this.getAllSupportFileData();
            },
            (error) => {
              console.error(error);
              this.handlePermissionError(error);
            },
          );
        },
        (error) => {
          console.error('Erro ao obter URL pré-assinada:', error);
          this.handlePermissionError(error);
        },
      );
  }

  private isDuplicate(
    fileData: FileMetaData[],
    newFileData: FileMetaData,
  ): boolean {
    return fileData.some(
      (file: any) =>
        file.filePath === newFileData.filePath &&
        file.version === newFileData.version,
    );
  }

  onSubmit() {
    if (this.uploadFormFile.invalid) {
      this.uploadFormFile.markAllAsTouched();
      this.translateService
        .get('global.support-fill-in-all-required-fields')
        .subscribe((msg) => this.alertService.error(msg));
      return;
    }

    const formValues = this.uploadFormFile.value;

    const newFileData: FileMetaData = {
      fileName:
        formValues.fileMetadataByLanguage[this.selectedLanguage].fileName,
      description:
        formValues.fileMetadataByLanguage[this.selectedLanguage].description,
      categoryName: formValues.productCategory,
      fileExtension: '',
      productImage: '',
      productCategory: formValues.productCategory,
      date: formValues.date,
      en: formValues.fileMetadataByLanguage.en,
      es: formValues.fileMetadataByLanguage.es,
      filePath: this.fileName,
      it: formValues.fileMetadataByLanguage.it,
      ptBR: formValues.fileMetadataByLanguage.ptBR,
      version: String(formValues.version),
      productLanguages: this.selectedProductLanguages,
    };

    const category = formValues.category;

    this.supportPageService.getSupportFileById(formValues.product).subscribe(
      (data: SupportFileResponse) => {
        const existingDocument = data?.response || data;
        let fileData = existingDocument?.fileData || {
          productCategory: formValues.productCategory,
          productImage: formValues.productImage,
          guides: [],
          manuals: [],
          others: [],
          softwares: [],
        };

        if (this.isDuplicate(fileData[category], newFileData)) {
          this.translateService
            .get('global.support-error-this.file-and-version-exist')
            .subscribe((msg) => this.alertService.error(msg));
          return;
        }

        fileData[category].push(newFileData);

        const updatedData = {
          id: formValues.product,
          fileData,
        };

        this.supportPageService.postSupportFile(updatedData).subscribe(
          () => {
            this.sendFileToBlobStorage();
            this.translateService
              .get('global.alert.send-file-upload-success')
              .subscribe((msg) => this.alertService.success(msg));
            this.uploadFormFile.reset();
            setTimeout(() => {
              this.dialog.closeAll();
            }, TimeoutEnum.Medium);
          },
          (error) => {
            this.translateService
              .get('global.support-error-save-file-in-db')
              .subscribe((msg) => this.alertService.error(msg));
          },
        );
      },
      (error) => {
        if (error.status === 404) {
          const fileData = {
            productCategory: '',
            productImage: '',
            guides: [],
            manuals: [],
            others: [],
            softwares: [],
          };

          fileData[category].push(newFileData);

          const newDocument: UploadPayload = {
            id: formValues.product,
            fileData,
          };

          this.supportPageService.postSupportFile(newDocument).subscribe(
            () => {
              this.sendFileToBlobStorage();
              this.translateService
                .get('global.alert.send-file-upload-success')
                .subscribe((msg) => this.alertService.success(msg));
              this.uploadFormFile.reset();
            },
            (err) => {
              this.translateService
                .get('global.support-error-creating-document-in-db')
                .subscribe((msg) => {
                  this.alertService.error(msg);
                });
            },
          );
        } else {
          this.translateService
            .get('global.support-error-search-data')
            .subscribe((msg) => this.alertService.error(msg));
        }
      },
    );
  }

  private handleErrorAlert(translationKey: string) {
    this.translateService
      .get(translationKey)
      .subscribe((msg) => this.alertService.error(msg));
  }

  private handlePermissionError(error: any) {
    this.translateService
      .get('global.validate.error-permission')
      .subscribe((permissionMsg: string) => {
        const errorMessage =
          error.error?.text === permissionMsg ? permissionMsg : 'error';
        this.handleErrorAlert(errorMessage);
      });
  }

  closeDialog(): void {
    const dialogRef = this.dialog.closeAll();
  }
}
