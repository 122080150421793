import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewChecked,
} from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GoogleMap } from '@angular/google-maps';
import { AppConstants } from 'app/app.constants';
import {
  FeatureCollection,
  Geometry,
  Position,
  area,
  polygon as turfPolygon,
} from '@turf/turf';
import { AnalyticsService } from 'app/core/services/analytics.service';
declare var google: any;

@Component({
  selector: 'app-mapa-talhao',
  templateUrl: './mapa-talhao.component.html',
  styleUrls: ['./mapa-talhao.component.scss'],
})
export class MapaTalhaoComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  googleMap: GoogleMap;
  overlayPolygon: any;
  allPolygons = [];
  @ViewChild(GoogleMap, { static: false }) set map(googleMap: GoogleMap) {
    if (googleMap) {
      this.initDrawingManager(googleMap);
    }
  }
  area = undefined;
  @Output() startMapLoaded = new EventEmitter<boolean>();
  @Input() polygonColor?: string = '#000';
  @Input() updatePolygons;
  @Input() polygonColorsSubject: Subject<string>;
  @Input() googleMapCenterCoordinate: Subject<{ lat: number; lng: number }>;
  @Input() talhaoId: string | null = null;
  @Input() updateIsPolygonClosed: Subject<boolean>;
  @Input() mode: 'create' | 'edit' | 'view';
  @Input() mapHeight: string;
  @Input() geometry: FeatureCollection = {
    features: [
      {
        geometry: {
          coordinates: [],
          type: 'Polygon',
        },
        properties: {
          fill: this.polygonColor,
          'fill-opacity': 0.6,
        },
        type: 'Feature',
      },
    ],
    type: 'FeatureCollection',
  };
  @Input() resetData: Subject<boolean>;
  @Input() recommendationMapArray;
  @Input() guidesArray;
  @Input() boundariesArray;

  apiLoaded: Observable<boolean>;

  googleMapsOverlay: any;
  polygons: google.maps.LatLngLiteral[][] = [];
  vertices: google.maps.LatLngLiteral[] = [];
  mapOptions: google.maps.MapOptions = { draggable: true };

  options: google.maps.MapOptions = {
    zoom: 3,
    mapTypeId: 'satellite',
    disableDefaultUI: false,
    zoomControl: true,
    fullscreenControl: true,
    center: { lat: -10.33, lng: -53.2 },
  };
  ngAfterViewChecked() {
    const markerButton = document.querySelector('[aria-label="Marcadores"]');
    if (markerButton instanceof HTMLElement) {
      markerButton.click();
      const divToRemove = document.querySelector('.gmnoprint.gm-style-mtc-bbw');
      const divToRemovePegman = document.querySelector(
        '[aria-label="Arraste o Pegman até o mapa para abrir o Street View"]',
      );
      if (divToRemove instanceof HTMLElement) {
        divToRemove.remove();
        divToRemovePegman.remove();
      }
    }
  }

  markerPositions: google.maps.LatLngLiteral[] = [];

  drawingOptions: google.maps.drawing.DrawingManagerOptions;
  googleMapsDrawingManager: google.maps.drawing.DrawingManager;

  googleMapPolygon: google.maps.Polygon;

  polygonsOptions: google.maps.PolygonOptions = {
    fillColor: this.polygonColor,
    fillOpacity: 0.6,
  };

  input: any;
  streets: string[] = [];
  private polygonButtonPressed = false;
  userId: string;
  constructor(
    httpClient: HttpClient,
    public userTalhoesService: UserTalhaoListService,
    public readonly analyticsService: AnalyticsService,
  ) {
    const usuarioLogado = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    this.userId = usuarioLogado.id;
    this.apiLoaded = httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo&libraries=drawing',
        'callback',
      )
      .pipe(
        map(() => {
          this.drawingOptions = {
            drawingControl: false,
            drawingControlOptions: {
              drawingModes: [google.maps.drawing],
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            polygonOptions: {
              editable: true,
              fillColor: this.polygonColor,
            },
            ...this.drawingOptions,
          };
          return true;
        }),
        catchError(() => of(false)),
      );
  }
  ngOnDestroy(): void {
    google = undefined;
  }

  ngOnInit(): void {
    localStorage.removeItem(AppConstants.KEYS_LOCAL_STORAGE.USER_LOCATION);
    if (this.googleMapCenterCoordinate !== undefined) {
      this.googleMapCenterCoordinate.subscribe(
        (coordinate: { lat: number; lng: number }) => {
          localStorage.setItem(
            AppConstants.KEYS_LOCAL_STORAGE.USER_LOCATION,
            JSON.stringify(coordinate),
          );
          this.centerGoogleMap(coordinate);
        },
      );
    }

    this.polygonsOptions.fillColor = this.polygonColor;

    if (this.resetData) {
      this.resetData.subscribe((v) => {
        this.allPolygons.forEach((polygon) => {
          polygon.setMap(null);
        });
        this.allPolygons = [];
        if (this.mode === 'edit') {
          this.googleMapPolygon.setMap(null);
          this.googleMapPolygon = null;
        }
      });
    }

    if (this.polygonColorsSubject)
      this.polygonColorsSubject.subscribe((color) => {
        this.drawingOptions.polygonOptions.fillColor = color;
        this.drawingOptions.polygonOptions.strokeColor = color;

        this.geometry.features[0].properties.fill = color;
        if (this.googleMapPolygon !== undefined) {
          this.googleMapPolygon.setOptions({
            fillColor: color,
            strokeColor: color,
          });
        }

        if (this.googleMapsOverlay !== undefined) {
          this.overlayPolygon.setOptions({
            fillColor: color,
            strokeColor: color,
          });
        } else {
          if (this.googleMap !== undefined) {
            if (this.googleMapsDrawingManager !== undefined) {
              this.googleMapsDrawingManager.setOptions(this.drawingOptions);
            }
          }
        }
      });
    if (this.updateIsPolygonClosed)
      this.updateIsPolygonClosed.subscribe((isPolygonClosed) => {
        if (isPolygonClosed) {
          this.googleMapsDrawingManager.setDrawingMode(null);
        } else {
          this.googleMapsDrawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.POLYGON,
          );
        }
      });
  }

  initDrawingManager(map: GoogleMap) {
    if (map && map.googleMap) {
      if (this.updatePolygons) {
        const localStorageUserLocation = localStorage.getItem(
          AppConstants.KEYS_LOCAL_STORAGE.USER_LOCATION,
        );
        if (localStorageUserLocation !== null) {
          if (map && map.googleMap) {
            map.googleMap.setCenter(JSON.parse(localStorageUserLocation));
            map.googleMap.setZoom(15);
          }
        }
        this.googleMap = map;

        const drawingManager = new google.maps.drawing.DrawingManager(
          this.drawingOptions,
        );

        this.googleMapsDrawingManager = drawingManager;
        drawingManager.setMap(map.googleMap);
        this.startMapLoaded.emit(true);

        drawingManager.addListener('polygoncomplete', (event) => {
          if (this.mode === 'edit') {
            coordinatesData = [];
            let coordinates = [];
            if (this.googleMapPolygon) {
              const paths = this.googleMapPolygon.getPaths();
              for (let i = 0; i < paths.getLength(); i++) {
                const path = paths.getAt(i);
                const array = path
                  .getArray()
                  .map((latLgn) => [latLgn.lat(), latLgn.lng()]);
                if (
                  array[0][0] !== array[array.length - 1][0] &&
                  array[0][1] !== array[array.length - 1][1]
                ) {
                  array.push(array[0]);
                }
                coordinates.push(array);
              }
            }

            let newPolygon = event
              .getPath()
              .getArray()
              .map((latLgn) => [latLgn.lat(), latLgn.lng()]);
            newPolygon.push(newPolygon[0]);
            coordinates.push(newPolygon);
            coordinates.forEach((position) => {
              coordinatesData.push(
                position.map((latLong) => ({
                  lat: latLong[0],
                  lng: latLong[1],
                })),
              );
            });
            this.googleMapPolygon = new google.maps.Polygon({
              paths: coordinatesData,
              fillOpacity: 0.6,
              editable: true,
              fillColor: this.polygonColor,
              strokeColor: this.polygonColor,
            });
          }
          this.allPolygons.push(event);
          this.removeUndoButton();
          this.updatePolygonPaths(event);
          drawingManager.setOptions({
            drawingControl: false,
            drawingMode: null,
          });

          google.maps.event.addListener(event.getPath(), 'insert_at', () => {
            this.updatePolygonPaths(event);
          });

          google.maps.event.addListener(event.getPath(), 'set_at', () => {
            this.updatePolygonPaths(event);
          });
        });
      }
      let coordinatesData: Position | { lat: number; lng: number }[] = [];
      let coordinates = (this.geometry.features[0].geometry as Geometry)
        .coordinates;

      if (
        Array.isArray(coordinates[0]) &&
        coordinates.length > 0 &&
        this.mode != 'view'
      ) {
        coordinates.forEach((position) => {
          coordinatesData.push(
            position.map((latLong) => ({
              lat: latLong[1],
              lng: latLong[0],
            })),
          );
        });

        if (this.updatePolygons)
          this.updatePolygons(this.geometry, true, this.area);

        this.googleMapPolygon = new google.maps.Polygon({
          paths: coordinatesData,
          fillOpacity: 0.6,
          editable: true,
          fillColor: this.polygonColor,
          strokeColor: this.polygonColor,
        });

        this.googleMapPolygon.addListener('mouseup', (polygonEvent) => {
          this.updatePolygonPaths(this.googleMapPolygon);
          if (!this.userId.includes('@agres.com.br')) {
            this.analyticsService.trackEvent(
              'Started polygon editing',
              'Edit Polygon',
              this.userId,
            );
          }
        });

        this.googleMapPolygon.setMap(map.googleMap);

        let bounds = new google.maps.LatLngBounds();

        this.googleMapPolygon.getPath().forEach(function (path) {
          bounds.extend(path);
        });

        map.fitBounds(bounds);
        this.removeUndoButton();
        this.mode = 'edit';
        this.updatePolygonPaths(this.googleMapPolygon);
      } else if (this.boundariesArray) {
        const { lat, lng } = this.findCenter(
          this.boundariesArray.map((items) => items.paths).flat(),
        );
        map.googleMap.setCenter({ lat, lng });
        map.googleMap.setZoom(14);
      } else {
        this.getLocation();
      }
    }
  }

  findCenter(paths) {
    let latTotal = 0;
    let lngTotal = 0;
    const totalPoints = paths[0].length;

    paths[0].forEach((point) => {
      latTotal += point.lat;
      lngTotal += point.lng;
    });

    return {
      lat: latTotal / totalPoints,
      lng: lngTotal / totalPoints,
    };
  }

  removeUndoButton() {
    if (this.googleMap === undefined) return;
    const undoPolygonChangeButton: HTMLImageElement = [
      ...this.googleMap.googleMap.getDiv().getElementsByTagName('img'),
    ].find((img) => img.src.includes('undo_poly.png'));

    if (!undoPolygonChangeButton) {
      setTimeout(() => {
        this.removeUndoButton();
      }, 200);
    } else {
      undoPolygonChangeButton.parentElement.remove();
    }
  }

  private updatePolygonPaths(event: any) {
    let coordinates = [];
    if (this.mode) {
      const paths = this.googleMapPolygon.getPaths();
      for (let i = 0; i < paths.getLength(); i++) {
        const path = paths.getAt(i);
        const array = path
          .getArray()
          .map((latLgn) => [latLgn.lng(), latLgn.lat()]);
        if (
          array[0][0] !== array[array.length - 1][0] &&
          array[0][1] !== array[array.length - 1][1]
        ) {
          array.push(array[0]);
        }
        coordinates.push(array);
      }
      this.area = this.getUsableArea(this.googleMapPolygon) / 10000;
    } else {
      let polygonsAreas: number[] = [];

      this.allPolygons.forEach((values) => {
        polygonsAreas.push(this.getUsableArea(values));

        let paths = values
          .getPath()
          .getArray()
          .map((latLgn) => [latLgn.lng(), latLgn.lat()]);
        paths.push(paths[0]);
        coordinates.push(paths);
      });

      polygonsAreas.sort((a, b) => a - b);
      let outerArea = polygonsAreas.pop();
      this.area =
        polygonsAreas.reduce((acc, item) => acc - item, outerArea) / 10000;
    }

    if (coordinates.length > 1) {
      const outerDirection = this.checkPolygonOrientation(coordinates[0]);
      if (outerDirection != 'Anti-horário') {
        coordinates[0].reverse();
      }
      for (let i = 1; i < coordinates.length; i++) {
        const innerDirection = this.checkPolygonOrientation(coordinates[i]);
        if (innerDirection != 'Horário') {
          coordinates[i].reverse();
        }
      }
    }

    (this.geometry.features[0].geometry as Geometry).coordinates = coordinates;
    this.updatePolygons(this.geometry, true, this.area);
  }

  getUsableArea(polygon: google.maps.Polygon): number {
    const paths = polygon.getPaths();
    const rings = [];

    paths.forEach((path) => {
      const ring = [];
      path.forEach((latLng) => {
        ring.push([latLng.lng(), latLng.lat()]);
      });

      if (
        ring.length > 0 &&
        (ring[0][0] !== ring[ring.length - 1][0] ||
          ring[0][1] !== ring[ring.length - 1][1])
      ) {
        ring.push(ring[0]);
      }

      rings.push(ring);
    });

    rings.sort((a, b) => area(turfPolygon([b])) - area(turfPolygon([a])));

    const externalArea = area(turfPolygon([rings[0]]));
    const holesArea = rings
      .slice(1)
      .reduce((sum, hole) => sum + area(turfPolygon([hole])), 0);

    return externalArea - holesArea;
  }

  checkPolygonOrientation(polygonCoords) {
    let sum = 0;

    for (let i = 0; i < polygonCoords.length; i++) {
      const current = polygonCoords[i];
      const next = polygonCoords[(i + 1) % polygonCoords.length];

      sum += (next[1] - current[1]) * (next[0] + current[0]);
    }

    if (sum > 0) {
      return 'Anti-horário';
    } else if (sum < 0) {
      return 'Horário';
    } else {
      return 'Indeterminado';
    }
  }

  getLocation() {
    const localStorageUserLocation = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.USER_LOCATION),
    );

    if (
      navigator.geolocation &&
      map &&
      this.googleMap &&
      localStorageUserLocation === null
    ) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position) {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            localStorage.setItem(
              AppConstants.KEYS_LOCAL_STORAGE.USER_LOCATION,
              JSON.stringify({ lat, lng }),
            );

            this.googleMap.googleMap.setCenter({ lat, lng });
            this.googleMap.googleMap.setZoom(15);
          }
        },
        (error: any) => {
          if (error.code === 1) {
            localStorage.removeItem(
              AppConstants.KEYS_LOCAL_STORAGE.USER_LOCATION,
            );
          }
        },
      );
    }
  }
  centerGoogleMap(coordinate: { lat: number; lng: number }) {
    this.googleMap.googleMap.setCenter(coordinate);
    this.googleMap.googleMap.setZoom(15);
  }

  togglePolygonDrawing() {
    if (this.polygonButtonPressed) {
      this.googleMapsDrawingManager.setDrawingMode(null);
      this.polygonButtonPressed = false;
    } else {
      this.googleMapsDrawingManager.setDrawingMode(
        google.maps.drawing.OverlayType.POLYGON,
      );
      this.polygonButtonPressed = true;
    }
  }
}
