<div class="container-fluid">
  <div class="container maintenance">
    <h4><i class="agres-agres agres-4x"></i></h4>
    <div class="row">
      <h1 class="maintenance__title">{{ 'error.maintenance.title' | translate }}</h1>
      <p class="maintenance__description">{{ 'error.maintenance.description' | translate }}</p>
    </div>
    <button class="maintenance__button" type="button" (click)="voltar()">
      {{ 'global.back' | translate }}
    </button>
  </div>
</div>
