<div class="modal__body">
  <div class="modal__body--form">
    <fieldset [disabled]="false">
      <div class="dropZone">
        <label for="files" class="dropZone__wrapper">
          <div [ngClass]="shpSelected ? 'dropZone__wrapper--uploadCheck' : 'dropZone__wrapper--upload'">
            <i
              [ngClass]="shpSelected ? 'dropZone__wrapper--uploadCheck---icon agres-check' : 'dropZone__wrapper--upload---icon agres-upload'"></i>
          </div>
          <div class="dropZone__wrapper--title">
            <div *ngIf="shpSelected; else selectSHP">
              <p>{{'tasks.recommendation_map.selected-file' | translate}}</p>
              <p>{{shpFileName}}</p>
            </div>
            <ng-template #selectSHP>
              <p>{{'tasks.recommendation_map.select-shapefile' | translate}}</p>
              <p>{{'tasks.recommendation_map.shp-extension' | translate}}</p>
            </ng-template>
          </div>
        </label>
        <input id="files" class="dropZone" (change)="handleShapefileUpload($event)" type="file"
          accept=".shp,application/vnd.shp" #inputFileSHP />
      </div>
    </fieldset>
  </div>
  <div class="modal__body--form" *ngIf="uploadDBF">
    <fieldset [disabled]="!shpSelected">
      <div class="agr--input">
        <label for="input"> {{'tasks.recommendation_map.select-dbf' | translate}}: </label>
        <label [ngClass]="dbfSelected ? 'btn btn__success btn__full' : 'btn btn__warning btn__full'">
          <input [disabled]="!shpSelected" (change)="handleDBFUpload($event)" type="file"
            accept=".dbf,application/vnd.dbf" #inputFileDBF />
          <div *ngIf="dbfSelected; else selectDBF">
            {{'tasks.recommendation_map.selected-file' | translate}}: {{dbfFilename}}
          </div>
          <ng-template #selectDBF>
            {{'tasks.recommendation_map.select-dbf' | translate}} {{'tasks.recommendation_map.dbf-extension' |
            translate}}
          </ng-template>
        </label>
      </div>
    </fieldset>
  </div>
  <div class="modal__body--form" *ngIf="uploadDBF">
    <div class="agr--input">
      <label for="filter"> {{'tasks.recommendation_map.select-dbf-column' | translate}}: </label>
      <div class="agr--input__group">
        <div class="agr--input__group--icon">
          <app-agres-icons iconsClass="agres-field"></app-agres-icons>
        </div>
        <select [disabled]="!dbfSelected" class="agr--input__group--input" id="cliente"
          [(ngModel)]="selectedDbfColumn.id" [ngModelOptions]="{standalone: true}" (change)="setSelectedDbfColumn()">
          <option *ngFor="let name of dbfColumnNames" value="{{name.id}}">{{name.name}}</option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="modal__linha"></div>
<div class="modal__footer">
  <app-button type="button" color="danger" icon="agres-close" data-bs-dismiss="modal" (click)="hideModal()"
    title="{{'global.cancel' | translate}}">
  </app-button>
  <app-button type="button" [color]="shpSelected ? 'success' : 'warning'"
    [icon]="shpSelected ? 'agres-check' : 'agres-upload'" data-bs-dismiss="modal"
    [disabled]="(!uploadDBF&&!shpSelected)||(uploadDBF&&(!shpSelected||!dbfSelected||!dbfFildNameSelected))"
    (click)="sendRecommendationMap()" title="{{'global.import' | translate}}">
  </app-button>
</div>