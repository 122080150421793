/**
 * Angular 2 decorators and services
 */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
// import * as GaugeChart from 'gauge-chart';

/**
 * GaugeChart Component
 */
@Component({
    selector: 'app-rg-gauge-chart',
    templateUrl: './gauge-chart.component.html',
    styleUrls: ['./gauge-chart.component.css'],
})
export class GaugeChartComponent {
    gaugeType = 'semi';
    gaugeAppendText = '%';
    markerConfig = {
        '0': { color: '#555', size: 8, label: '0', type: 'line' },
        '25': { color: '#555', size: 8, label: '25', type: 'line' },
        '50': { color: '#555', size: 8, label: '50', type: 'line' },
        '75': { color: '#555', size: 8, label: '75', type: 'line' },
        '100': { color: '#555', size: 8, label: '100', type: 'line' },
    };

    @ViewChild('gaugeArea', { static: true }) gaugeArea;

    @Input() gaugeValue: number;
    @Input() centralLabel: number;
    @Input() gaugeLabel: number;

}
