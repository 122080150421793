import { Subject } from 'rxjs';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { detectBody } from '../../../app.helpers';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavigationStart, Router } from '@angular/router';
import { LoadingSpinnerService } from 'app/core/services/loading-spinner.service';

export interface CurrentViewportBreakpoint {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}
@Component({
  selector: 'app-basic',
  templateUrl: 'basicLayout.component.html',
  styleUrls: ['basicLayout.component.scss'],
})
export class BasicLayoutComponent implements OnInit {
  isSidebarOpen: boolean = false;
  hideMenuButton = true;
  onResize = new EventEmitter<CurrentViewportBreakpoint>();

  viewportBreakpoints: CurrentViewportBreakpoint = {
    mobile: true,
    tablet: false,
    desktop: false,
  };

  toggleSidebarSubject = new Subject<boolean | void>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private readonly router: Router,
    public loadingSpinner: LoadingSpinnerService,
  ) {
    const mobileBreakPoint = '(max-width: 480px)';
    const tabletBreakPoint = '(max-width: 1024px)';
    if (breakpointObserver.isMatched(mobileBreakPoint)) {
      this.onResize.emit({ mobile: true, tablet: false, desktop: false });
    } else {
      if (breakpointObserver.isMatched(tabletBreakPoint)) {
        this.onResize.emit({ mobile: false, tablet: true, desktop: false });
      } else {
        this.onResize.emit({ mobile: false, tablet: false, desktop: true });
      }
    }
    const layoutChanges = breakpointObserver.observe([
      mobileBreakPoint,
      tabletBreakPoint,
    ]);
    layoutChanges.subscribe(
      (result: {
        matches: boolean;
        breakpoints: { [key: string]: boolean };
      }) => {
        detectBody();
        const breakpointEntries: Array<[string, boolean]> = Object.entries(
          result.breakpoints,
        );
        const mobileBreakPointFilter = (entries: [string, boolean]) =>
          entries[1] && entries[0] === '(max-width: 480px)';
        const tabletBreakPointFilter = (entries: [string, boolean]) =>
          entries[1] && entries[0] === '(max-width: 1024px)';
        const desktopBreakPointFilter = (entries: [string, boolean]) =>
          !entries[1] && entries[0] === '(max-width: 1024px)';

        if (breakpointEntries.filter(mobileBreakPointFilter).length === 1) {
          this.viewportBreakpoints = {
            mobile: true,
            tablet: false,
            desktop: false,
          };
        } else if (
          breakpointEntries.filter(tabletBreakPointFilter).length === 1
        ) {
          this.viewportBreakpoints = {
            mobile: false,
            tablet: true,
            desktop: false,
          };
        } else if (
          breakpointEntries.filter(desktopBreakPointFilter).length === 1
        ) {
          this.viewportBreakpoints = {
            mobile: false,
            tablet: false,
            desktop: true,
          };
        }
        if (this.viewportBreakpoints.mobile) {
          this.setupMobile();
        }
        if (this.viewportBreakpoints.tablet) {
          this.setupTablet();
        }
        if (this.viewportBreakpoints.desktop) {
          this.setupDesktop();
        }
        this.onResize.emit(this.viewportBreakpoints);
      },
    );
  }

  ngOnInit() {
    detectBody();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingSpinner.hide();
      }
    });
  }

  setupMobile() {
    this.hideMenuButton = false;
  }
  setupTablet() {
    this.hideMenuButton = false;
  }
  setupDesktop() {
    this.hideMenuButton = true;
    this.isSidebarOpen = true;
  }

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.toggleSidebarSubject.next(this.isSidebarOpen);
  }
}
