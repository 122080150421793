import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AgresSpinnerService } from './agres-spinner.service';
import { AgresSpinner, AgresSpinnerType } from './agres-spinner.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-agres-spinner',
  templateUrl: './agres-spinner.component.html',
  styleUrls: ['./agres-spinner.component.scss'],
})
export class AgresSpinnerComponent implements OnInit, OnDestroy {
  @Input() id = 'spinnerAgres';
  @Input() fade = true;

  spinners: AgresSpinner[] = [];
  agresSpinnerSubscription: Subscription;
  routeSubscription: Subscription;
  isLoading = false;

  constructor(
    private readonly router: Router,
    private readonly agresSpinner: AgresSpinnerService,
  ) {}

  ngOnInit() {
    this.agresSpinnerSubscription = this.agresSpinner
      .onAgresSpinner(this.id)
      .subscribe((spinner) => {
        if (!spinner.message) {
          this.spinners = this.spinners.filter((x) => x.keepAfterRouteChange);
          this.spinners.forEach((x) => delete x.keepAfterRouteChange);
          this.isLoading = false;
          return;
        }

        if (spinner) {
          this.spinners.push(spinner);
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
      });
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.agresSpinner.clear(this.id);
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.agresSpinnerSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  cssClass(spinner: AgresSpinner): string {
    if (!spinner) {
      return '';
    }

    const baseClasses = ['spinnerAgres', 'spinnerAgres__dismissable'];
    const typeClasses = {
      [AgresSpinnerType.Show]: 'spinnerAgres',
      [AgresSpinnerType.Hide]: 'spinnerAgres spinnerAgres__hidden',
    };

    const spinnerClass = typeClasses[spinner.type] || '';
    const fadeClass = spinner.fade ? 'fade' : '';

    return [...baseClasses, spinnerClass, fadeClass].join(' ').trim();
  }
}
