import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { FileManagerService } from 'app/core/services/file-manager.service';
import { AgresSpinnerCanvasService } from 'app/shared/components/agres-spinner-canvas/agres-spinner-canvas.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { UserFilesManager } from 'app/utils/user-files-manager';
import { Subject } from 'rxjs';
import { zip } from '@mapbox/shp-write';
import { unzipFiles } from 'app/utils/zip-manager';
import { normalizeString } from 'app/utils/string.utils';

type shapefileArray = {
  file: string;
  type: string;
  operation: string;
};

@Component({
  selector: 'app-sidebar-menu-field-files',
  templateUrl: './sidebar-menu-field-files.component.html',
  styleUrls: ['./sidebar-menu-field-files.component.scss'],
})
export class SidebarMenuFieldFilesComponent implements OnInit, OnChanges {
  @Input() isMenuFieldFileOpen = false;
  @Input() shpFiles: Array<string>;
  @Input() fieldName: string;
  @Input() name: string;
  @Input() geometry: any;
  @Output() filesToShow = new EventEmitter<shapefileArray>();
  recommendationFiles;
  guidesFiles;
  boundariesFiles;
  files: {
    recommendation_map: Array<string>;
    guides: Array<string>;
    boundaries: Array<string>;
  };
  keys;
  fileName: string;
  filesToAssociate: Array<string> = [];
  listOfFiles: Array<string> = [];
  userLogged: string;
  private readonly cloudantManager: UserFilesManager;
  public offCanvasUploadArquivos = new Subject<boolean>();
  public offCanvasDevices = new Subject<boolean>();

  constructor(
    public fileManagerService: FileManagerService,
    public translateService: TranslateService,
    public httpClient: HttpClient,
    public alertService: AlertService,
    public loadingAgresSpinnerCanvas: AgresSpinnerCanvasService,
  ) {
    this.cloudantManager = new UserFilesManager(fileManagerService, httpClient);
  }

  ngOnInit() {
    this.userLogged = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    ).id;
    this.getFiles();
    this.translateService.get('global.loading.map-wait').subscribe((res) => {
      this.loadingAgresSpinnerCanvas.toShow(res);
    });
  }

  ngOnChanges() {
    if (this.shpFiles) {
      this.files = {
        recommendation_map: this.shpFiles['recommendation_files'].filter(
          (file) => file.endsWith('.shp'),
        ),
        guides: this.shpFiles['guides_files'].filter((file) =>
          file.endsWith('.shp'),
        ),
        boundaries: this.shpFiles['boundaries'].filter((file) =>
          file.endsWith('.shp'),
        ),
      };
    }
  }

  getFiles() {
    this.cloudantManager.getUserFiles(this.userLogged).subscribe(
      ({ response }) => {
        if (response?.files?.fields) {
          const files = response.files.fields.hasOwnProperty(this.fieldName)
            ? response.files.fields[this.fieldName]
            : undefined;
          if (files) {
            this.files = {
              recommendation_map: files['recommendation_files'].filter((file) =>
                file.endsWith('.shp'),
              ),
              guides: files['guides_files'].filter((file) =>
                file.endsWith('.shp'),
              ),
              boundaries: files['boundaries'].filter((file) =>
                file.endsWith('.shp'),
              ),
            };
          } else {
            this.createShapefile(this.fieldName);
          }
        }
        this.loadingAgresSpinnerCanvas.toHide();
      },
      () => {
        this.loadingAgresSpinnerCanvas.toHide();
      },
    );
  }

  showMaps(fileName: string, event: any, type: string) {
    if (event.checked) {
      this.filesToShow.emit({ file: fileName, type: type, operation: 'show' });
    } else {
      this.filesToShow.emit({ file: fileName, type: type, operation: 'clear' });
    }
  }

  updateSelectedItem(fileName: string, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;
    if (!isChecked) {
      const baseName = fileName.split('.', 1)[0];
      const files = [fileName, `${baseName}.dbf`, `${baseName}.shx`];
      files.forEach(
        (files) =>
          (this.filesToAssociate = this.filesToAssociate.filter(
            (item) => item !== files,
          )),
      );
      this.filesToAssociate = this.filesToAssociate.filter(
        (item) => item !== fileName,
      );
    } else {
      const baseName = fileName.split('.', 1)[0];
      const files = [fileName, `${baseName}.dbf`, `${baseName}.shx`];
      files.forEach((files) => this.filesToAssociate.push(files));
    }
  }
  openOffCanvasUpload() {
    this.offCanvasUploadArquivos.next(true);
  }

  downloadFile() {
    this.feedBackToUser('global.info-download-files', 'info');
    this.cloudantManager.downloadUserFiles(
      this.userLogged,
      this.filesToAssociate,
      'fields',
      this.fieldName,
    );
  }

  private feedBackToUser(message: string, type: string) {
    this.translateService.get(message).subscribe((res: string) => {
      switch (type) {
        case 'success':
          this.alertService.success(res);
          break;
        case 'error':
          this.alertService.error(res);
          break;
        case 'info':
          this.alertService.info(res);
          break;
        default:
          this.alertService.info(res);
          break;
      }
    });
  }

  async openOffCanvasSelecteds(): Promise<void> {
    this.offCanvasDevices.next(true);
    this.fileName = '';
  }

  openOffCanvasDevices() {
    this.offCanvasDevices.next(true);
    this.fileName = '';
  }

  async createShapefile(id: string) {
    const name = normalizeString(this.name);
    const shapefilesZip = await zip(this.geometry, {
      types: {
        polygon: name,
      },
      compression: 'STORE',
      outputType: 'blob',
    });

    const files = await unzipFiles(shapefilesZip);
    const fileClassification = {
      boundaries: files.map((file) => file.name),
      recommendation_files: [],
      guides_files: [],
    };
    this.cloudantManager
      .saveDataAndUploadUserFiles(
        this.userLogged,
        files,
        'fields',
        id,
        fileClassification,
      )
      .subscribe(() => {
        this.getFiles();
      });
  }
}
