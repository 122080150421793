import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AgresSpinnerCanvasService } from './agres-spinner-canvas.service';
import {
  AgresSpinnerCanvas,
  AgresSpinnerCanvasType,
} from './agres-spinner-canvas.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-agres-spinner-canvas',
  templateUrl: './agres-spinner-canvas.component.html',
  styleUrls: ['./agres-spinner-canvas.component.scss'],
})
export class AgresSpinnerCanvasComponent implements OnInit, OnDestroy {
  @Input() id = 'spinnerAgresCanvas';
  @Input() fade = true;

  spinners: AgresSpinnerCanvas[] = [];
  agresSpinnerSubscription: Subscription;
  routeSubscription: Subscription;
  isLoading = false;

  constructor(
    private readonly router: Router,
    private readonly agresSpinner: AgresSpinnerCanvasService,
  ) {}

  ngOnInit() {
    this.agresSpinnerSubscription = this.agresSpinner
      .onAgresSpinnerCanvas(this.id)
      .subscribe((spinner) => {
        if (!spinner.message) {
          this.spinners = this.spinners.filter((x) => x.keepAfterRouteChange);
          this.spinners.forEach((x) => delete x.keepAfterRouteChange);
          this.isLoading = false;
          return;
        }

        if (spinner) {
          this.spinners.push(spinner);
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
      });
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.agresSpinner.clear(this.id);
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.agresSpinnerSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  cssClass(spinner: AgresSpinnerCanvas): string {
    if (!spinner) {
      return '';
    }

    const baseClasses = [
      'spinnerAgresCanvas',
      'spinnerAgresCanvas__dismissable',
    ];
    const typeClasses = {
      [AgresSpinnerCanvasType.Show]: 'spinnerAgresCanvas',
      [AgresSpinnerCanvasType.Hide]:
        'spinnerAgresCanvas spinnerAgresCanvas__hidden',
    };

    const spinnerClass = typeClasses[spinner.type] || '';
    const fadeClass = spinner.fade ? 'fade' : '';

    return [...baseClasses, spinnerClass, fadeClass].join(' ').trim();
  }
}
