import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-chamados-agres',
  templateUrl: './chamados-agres.component.html',
  styleUrls: ['./chamados-agres.component.css'],
})
export class ChamadosAgresComponent implements OnInit {
  constructor(public translateService: TranslateService) {
    this.translateService.onLangChange.subscribe(
      (event: TranslationChangeEvent) => {
        this.translateService.get('global.home').subscribe((home: string) => {
          var home = home;
          this.translateService.get('called.title').subscribe((res: string) => {
            var title = res;
            this.bindHTMLTitle(home, title);
          });
        });
      },
    );
  }
  chamados = [
    {
      numero: '1',
      servico: 'Técnico',
      categoria: 'Web',
      descricao: 'Descrição 1',
      status: 'Aberto',
      usuario: 'Luis Alberto',
    },
    {
      numero: '2',
      servico: 'Técnico',
      categoria: 'Web',
      descricao: 'Descrição 2',
      status: 'Fechado',
      usuario: 'Luis Alberto',
    },
    {
      numero: '3',
      servico: 'Técnico',
      categoria: 'Modulo',
      descricao: 'Descrição 3',
      status: 'Aberto',
      usuario: 'Luis Alberto',
    },
  ];
  bindHTMLTitle(home, title) {
    jQuery('.breadcrumb').html(
      `<li><a href="#">${home}</a></li><li class="active"><strong>${title}</strong></li>`,
    );
  }

  searchKeys = ['numero', 'servico', 'descricao', 'status', 'categoria'];
  public search;
  chamadosFilter = [];

  ngOnInit() {
    this.changeLanguage();
    this.chamadosFilter = this.chamados;
  }

  searchItens() {
    this.chamadosFilter = this.filter(this.chamados);
  }

  filter(data) {
    const filter = this.search.toLowerCase();
    return !filter
      ? data.slice(0)
      : data.filter((d) => {
          return (
            Object.keys(d)
              .filter((k) => this.searchKeys.includes(k))
              .map((k) => String(d[k]))
              .join('|')
              .toLowerCase()
              .indexOf(filter) !== -1 || !filter
          );
        });
  }

  changeLanguage() {
    this.translateService.onLangChange.subscribe(
      (event: TranslationChangeEvent) => {
        this.translateService.get('global.home').subscribe((home: string) => {
          var home = home;
          this.translateService
            .get('called.my-open-calls')
            .subscribe((res: string) => {
              var title = res;
              this.bindHTMLTitle(home, title);
            });
        });
      },
    );
  }
}
