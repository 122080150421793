import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-dashboard',
  templateUrl: './card-dashboard.component.html',
  styleUrls: ['./card-dashboard.component.scss'],
})
export class CardDashboardComponent {
  @Input() link: string;
  @Input() title: string;
  @Input() description: string;
  @Input() disabled: boolean;
  @Input() styleClass: string;
  @Input() iconClass: string;
  @Output() linkClick = new EventEmitter<string>();

  public handleClick(event: any) {
    if (!this.link) {
      event.preventDefault();
    }
    this.linkClick.emit(this.link);
  }
}
