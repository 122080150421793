import {
  Component,
  ContentChildren,
  QueryList,
  OnInit,
  Input,
  AfterContentInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TabComponent } from './tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
  @Input() tabTitle: string;
  @Input() selected = false;

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  ngAfterContentInit() {
    let selectedTabs = this.tabs.filter((tab) => tab.selected);
    if (selectedTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: TabComponent) {
    if (!tab.active) return;
    this.tabs.toArray().forEach((tab) => (tab.selected = false));
    tab.selected = true;
    tab.tabClick.emit();
  }

  constructor(public translate: TranslateService) {}
}
