<div class="border"></div>
<div style="height: 10vh"></div>
<div class="container">
    <div class="card">
        <img alt="Agres" src="./assets/images/default/logo-isofarm.svg" />
        <h1 class="card__text-center">POLÍTICA DE PRIVACIDADE</h1>
        <div class="card__text-right">
            <em>Última atualização, 9 de julho de 2020</em>
            <hr>
        </div>
        <div class="row col-md-12">
            <p>
                A Agres valoriza a privacidade e segurança das suas informações.
                Por isso, desenvolvemos uma Política de Privacidade que abrange
                a forma como coletamos, usamos, divulgamos, transferimos e
                armazenamos suas informações pessoais.
            </p>

            <p>
                <em>Leia com atenção esta Política de Privacidade antes de usar
                    qualquer produto ou serviço da Agres, para entender nossas
                    práticas e suas opções antes de decidir compartilhar suas
                    informações conosco. O uso de qualquer produto ou serviço da
                    Agres significa que você concorda com a nossa coleta, uso e
                    divulgação das informações que você compartilha conosco,
                    como explicado nesta Política de Privacidade.</em>
            </p>

            <p>&nbsp;</p>
            <p><strong>A propriedade das Informações Pessoais</strong></p>

            <p>
                Você é o proprietário das informações que nos fornece quando
                compra, abre uma conta ou usa qualquer produto ou serviço da
                Agres, incluindo os dados gerados a partir dos equipamentos
                agrícolas de sua propriedade ou que você utiliza (“Informações
                Pessoais”). As Informações Pessoais continuam sendo suas, mesmo
                depois que você as compartilha conosco. Ao fornecer Informações
                Pessoais você declara que detém o direito de compartilhá-las
                conosco e nos permite sua utilização e divulgação conforme
                explicado nesta Política de Privacidade.
            </p>

            <p>&nbsp;</p>
            <p><strong>Informações que podemos coletar sobre você</strong></p>

            <p>
                Quando você nos fornece dados para avaliação de crédito
                comercial, adquire um produto ou serviço, baixa de uma
                atualização de software, registra-se em um evento Agres (e.g.
                treinamento, workshop, webinar, etc.), entra em contato conosco
                (incluindo por mídia social) ou participa de uma pesquisa
                online, podemos coletar várias informações. Podemos coletar de
                você as seguintes informações, incluindo:
            </p>

            <ul>
                <li>Nome, endereço e número de telefone</li>
                <li>Endereço de e-mail</li>
                <li>Preferências de contato</li>
                <li>Identificadores do dispositivo e endereço IP</li>
                <li>Informações sobre localização</li>
                <li>
                    Informações sobre suas operações agrícolas, que incluem mapa
                    ou divisas, tempo, clima, dados agronômicos, perdas de
                    lavouras, rendimento de lavouras, dados ambientais, práticas
                    agrícolas, taxas de plantio de sementes, taxa de aplicação
                    de fertilizantes e outras informações e dados carregados,
                    introduzidos, transmitidos ou armazenados em produtos ou
                    serviços Agres, quer diretamente por você ou pelos
                    equipamentos usados nessas operações.
                </li>
            </ul>

            <p>
                Você também pode nos fornecer o número de seu cartão de crédito,
                cartão de débito ou informações sobre sua conta bancária,
                dependendo de como você escolher pagar por produtos e serviços
                da Agres.
            </p>

            <p>&nbsp;</p>
            <p>
                <strong>Como podemos usar e compartilhar Informações
                    Pessoais</strong>
            </p>

            <p>
                Podemos utilizar suas informações pessoais para as finalidades
                descritas nesta Política de Privacidade, sempre com o seu
                consentimento:
            </p>

            <ul>
                <li>
                    Para entregar, administrar, desenvolver e aprimorar os
                    produtos e serviços da Agres;
                </li>
                <li>
                    Para nos comunicar com você a respeito dos produtos e
                    serviços da Agres ou apresentar a você comunicações,
                    conteúdos ou ofertas que podem ser de seu interesse,
                    respeitado seu direito de desativar essa opção (opt-out) a
                    qualquer tempo; e
                </li>
                <li>Para outros fins que você expressamente consentir.</li>
            </ul>

            <p>
                Exceto para esses usos permitidos, não compartilharemos
                Informações Pessoais:
            </p>

            <ul>
                <li>Com órgãos públicos, salvo se obrigados por lei; ou</li>
                <li>
                    Com terceiros sem seu consentimento, salvo a Prestadores de
                    Serviços conforme definido abaixo.
                </li>
            </ul>

            <p>
                Prestadores de Serviços são as empresas e consultores com quem
                trabalhamos para dar apoio às nossas operações e que concordam
                expressamente em não usar nem divulgar Informações Pessoais
                salvo para nos prestar serviços ou atender a exigências legais.
                Entre os exemplos, estão as empresas e consultores que prestam
                serviços de hospedagem na web, desenvolvimento de software,
                processamento de pagamentos, atendimento de pedidos, tecnologia
                da informação e respectivo suporte de infraestrutura,
                atendimento ao cliente, entrega de e-mail, processamento de
                cartão de crédito, assessoria legal e financeira, e auditoria.
            </p>

            <p>&nbsp;</p>
            <p>
                <strong>Informações Pessoais não fornecidas diretamente por
                    você</strong>
            </p>

            <p>
                Se você é um possível candidato para emprego na Agres, podemos
                ter recebido suas informações pessoais de terceiros, como
                recrutadores ou sites externos. Usaremos as informações pessoais
                recebidas para entrar em contato com você para falar sobre uma
                possível oportunidade ou para avaliar sua candidatura.
            </p>

            <p>
                Para fins de pesquisa e desenvolvimento, podemos usar conjuntos
                de dados que podem incluir imagens ou outros tipos de dados que
                podem ser associados a uma pessoa e possibilitar sua
                identificação.
            </p>

            <p>
                Comprometemo-nos a não vender Informações Pessoais a terceiros,
                sejam elas fornecidas diretamente por você, sejam elas
                fornecidas por terceiros. Podemos, no entanto, vender
                Informações Agregadas, conforme definido abaixo, para
                instituições de governo e do mercado.
            </p>

            <p>&nbsp;</p>
            <p><strong>Informações Agregadas</strong></p>

            <p>
                Informações Agregadas são a combinação e análise de informações
                de múltiplas fontes, que ajuda a identificar, ou criar
                tendências, referenciais, métricas sumárias ou algoritmos
                preditivos, desde que tomemos medidas razoáveis para impedir a
                identificação de um determinado indivíduo ou suas informações.
                Podemos criar Informações Agregadas a partir de informações que
                nossa base de clientes e usuários decidam compartilhar conosco,
                juntamente com as Informações Pessoais, sempre sem identificação
                de um indivíduo específico, garantindo-se assim a anonimização.
            </p>

            <p>Podemos usar Informações Agregadas:</p>

            <ul>
                <li>
                    Para entregar, administrar, desenvolver e aprimorar nossos
                    produtos e serviços;
                </li>
                <li>
                    Para embasar afirmações de marketing e publicidade sobre
                    nossos produtos e serviços; e
                </li>
                <li>Para outros fins que você expressamente consentir.</li>
            </ul>

            <p>&nbsp;</p>
            <p>
                <strong>Outros usos para as Informações Pessoais, direitos de
                    divulgação e compromissos</strong>
            </p>
            <p>
                Podemos usar ou compartilhar Informações Pessoais ou Informações
                Agregadas, conforme julgarmos necessário ou apropriado:
            </p>

            <ul>
                <li>Para cumprir a lei ou exigências processuais; ou</li>
                <li>
                    Para proteger nossos direitos, tais como o de buscar
                    recursos jurídicos disponíveis ou limitar os danos que
                    possamos sofrer.
                </li>
            </ul>

            <p>&nbsp;</p>

            <p><strong>Marketing e publicidade</strong></p>
            <p>&nbsp;</p>

            <p>
                <strong>Como podemos usar Informações Pessoais em marketing e
                    publicidade</strong>
            </p>
            <p>
                Não usaremos Informações Pessoais para promover ou anunciar
                produtos ou serviços de terceiros sem o seu consentimento. Se
                você der seu consentimento, poderá retirá-lo a qualquer momento.
                Podemos usar Informações Pessoais para entrar em contato com
                você sobre nossos produtos e serviços, respeitado seu direito de
                retirar seu consentimento a essas comunicações, conforme
                explicado abaixo.
            </p>
            <p>&nbsp;</p>

            <p>
                <strong>Opção para deixar de receber comunicações de
                    marketing</strong>
            </p>
            <p>
                Você pode optar por não receber mais comunicações de marketing
                da Agres mediante comunicação à nossa equipe, via telefone,
                e-mail, site e também por meio de nossos serviços online. Note,
                todavia, que ainda podemos mandar a você mensagens
                administrativas importantes, relacionadas ao uso de produtos e
                serviços da Agres, as quais você não pode optar por não receber.
            </p>
            <p>&nbsp;</p>

            <p>
                <strong>Possibilidade de excluir Informações Pessoais</strong>
            </p>
            <p>
                Damos a você a possibilidade de excluir quaisquer Informações
                Pessoais relacionadas a produtos e serviços da Agres, a qualquer
                momento. Nossa intenção é que, uma vez que você tenha feito a
                solicitação e tenhamos adotado as medidas necessárias,
                Informações Pessoais não estarão mais disponíveis para nós,
                conforme disposto nesta Política de Privacidade. A não ser que
                você solicite que sua conta seja encerrada ou Informações
                Pessoais sejam deletadas, nós apenas reteremos Informações
                Pessoais na extensão necessária para cumprir as finalidades para
                as quais foram inicialmente coletadas, a não ser que de outra
                forma determinado ou permitido por lei. Informações Pessoais
                podem ser sujeitas a requisitos legais de retenção, os quais
                podem nos exigir reter Informações Pessoais por vários anos.
            </p>

            <p>
                Há algumas limitações à sua possibilidade de ter Informações
                Pessoais excluídas. Especificamente, você não pode excluir
                Informações Pessoais que:
            </p>

            <ul>
                <li>Tenham sido incorporadas às Informações Agregadas; ou</li>
                <li>
                    Estejamos utilizando para suportar o seu uso dos produtos e
                    serviços da Agres no momento em que você solicitar a
                    exclusão.
                </li>
            </ul>

            <p>
                Além disso, você não vai poder excluir certas informações caso
                sua retenção, a nosso critério razoável, seja necessária ou
                apropriada: (i) para cumprir a legislação aplicável e exigências
                processuais, ou (ii) para proteger nossos direitos tais como de
                buscar recursos jurídicos disponíveis ou limitar os danos que
                possamos sofrer. Além disso, note que se tivermos informações
                recebidas de terceiros, além das que você mesmo forneceu, sua
                solicitação de exclusão talvez não signifique que todas as
                informações correspondentes vindas de outras fontes também sejam
                excluídas.
            </p>

            <p>
                Se quiser que Informações Pessoais sejam excluídas (além das
                exceções à exclusão acima), entre em contato conosco pelo
                e-mail <a href="mailto:relacionamento@agres.com.br">relacionamento@agres.com.br</a>.
                Em sua solicitação, deixe claro quais informações quer ver
                excluídas. Para sua proteção, talvez implementemos solicitações
                com respeito às informações associadas com o e-mail usado para
                enviar sua solicitação, e podemos tomar outras providências para
                confirmar sua identidade, antes de implantar sua solicitação.
                Vamos procurar atender à sua solicitação logo que razoavelmente
                praticável, mas sempre respeitando os prazos legais, se
                aplicáveis.
            </p>

            <p>&nbsp;</p>
            <p>
                <strong>Capacidade de compartilhar Informações Pessoais com
                    facilidade</strong>
            </p>

            <p>
                Queremos que você tenha controle sobre seus dados e liberdade
                para os transferir para qualquer empresa em que tiver confiança.
                Vamos continuar a trabalhar para tornar este desejo possível,
                usando um modelo de autorização aberta.
            </p>

            <p>
                Além disso, possibilitamos que você compartilhe determinadas
                informações específicas sobre sua conta diretamente com outros
                clientes e usuários. Esse compartilhamento somente será possível
                se você nos der permissão expressa e confirmar sua identidade.
                Você poderá revogar essa autorização a qualquer momento.
            </p>

            <p>&nbsp;</p>

            <p><strong>Segurança e proteção de dados</strong></p>

            <p>
                Para proteger sua privacidade e segurança, tomamos certas
                medidas concebidas para confirmar sua identidade antes de
                conceder acesso aos nossos serviços. É responsabilidade sua
                manter a segurança de sua senha e informações de conta em todos
                os momentos e para todas as transações efetuadas a partir dessa
                conta.
            </p>

            <p>
                Quando suas Informações Pessoais são armazenadas pela Agres,
                utilizamos sistemas de computador com acesso limitado, mantidos
                em instalações que incluem medidas físicas de segurança. Todos
                os seus dados são armazenados criptografados, mesmo quando
                utilizamos o armazenamento de terceiros.
            </p>

            <p>
                Não coletamos dados definidos pela legislação brasileira como
                sensíveis (dado pessoal sobre origem racial ou étnica, convicção
                religiosa, opinião política, filiação a sindicato ou a
                organização de caráter religioso, filosófico ou político, ou à
                vida sexual, dado genético ou biométrico, quando vinculado a uma
                pessoa natural), exceto dados referentes à saúde, fundamentais
                para proteger a integridade de nossos empregados e colaboradores
                (e.g. doença ou condição médica preexistente, alergias, etc.).
            </p>

            <p>
                Tomamos medidas razoáveis para proteger Informações Pessoais, do
                ponto de vista organizacional, técnico e administrativo. Se
                tiver razões para achar que sua interação conosco deixou de ser
                segura, por favor, avise imediatamente.
            </p>

            <p>&nbsp;</p>
            <p>
                <strong>Avaliações independentes de nosso cumprimento da Política
                    de Privacidade</strong>
            </p>

            <p>
                Podemos contratar avaliações feitas por terceiros independentes
                dos sistemas e procedimentos da Agres para confirmar que estão
                de acordo com a Política de Privacidade.
            </p>

            <p>&nbsp;</p>
            <p><strong>Uso dos Produtos Agres por menores de idade</strong></p>
            <p>
                Os produtos e serviços da Agres não são direcionados para
                menores de 18 (dezoito) anos. Solicitamos que essas pessoas não
                nos forneçam quaisquer informações nem usem nossos produtos e
                serviços.
            </p>

            <p>&nbsp;</p>
            <p><strong>Transferências e Usuários Internacionais</strong></p>

            <p>
                A Agres é controlada e administrada no Brasil. Ainda assim,
                Informações Pessoais podem ser armazenadas e processadas no
                Brasil ou em qualquer outro país onde tenhamos instalações ou
                contratemos Prestadores de Serviços, e utilizando produtos ou
                serviços da Agres você consente com a transferência de
                informações para países fora do Brasil, que podem possuir regras
                sobre proteção de dados diferentes das vigentes no Brasil.
            </p>
            <p>&nbsp;</p>

            <p><strong>Informações on-line</strong></p>

            <p>
                Podemos coletar Informações Pessoais quando do seu uso de
                produtos, serviços, sites da web e serviços online da Agres,
                incluindo conforme segue:
            </p>

            <ul>
                <li>
                    <strong>Seu navegador ou dispositivo:</strong> Quando você
                    faz uso de sites ou serviços online, certas informações são
                    coletadas pela maioria dos navegadores ou automaticamente
                    através de seu dispositivo, incluindo seu endereço de Media
                    Access Control (MAC), resolução de tela, nome e versão do
                    sistema operacional, fabricante e modelo do dispositivo, bem
                    como tipo e versão de navegador que estiver utilizando.
                    Usamos essas informações para garantir que os sites ou
                    serviços online funcionem adequadamente.
                </li>
                <li>
                    <strong>Uso de cookies e tecnologias similares:</strong> Cookies são informações
                    armazenadas diretamente no
                    computador que você está usando. Os cookies nos permitem
                    coletar informações tais como tipo de navegador, tempo gasto
                    usando as páginas visitadas, preferências de linguagem e
                    outros dados anônimos sobre tráfego. Nós e nossos
                    Prestadores de Serviços usamos as informações para fins de
                    segurança, facilitar a navegação, exibir informações com
                    mais eficácia e para personalizar sua experiência com o uso
                    dos serviços online. Também coletamos informações
                    estatísticas sobre o uso dos serviços online para introduzir
                    melhoramentos contínuos em sua funcionalidade, entender como
                    são usados e para resolver questões que lhes digam respeito.
                    Se não quiser que coletemos essas informações por meio de
                    cookies, a maioria dos navegadores permite a recusa
                    automática de cookies ou permite que você recuse a
                    transferência de cookies de um determinado site para seu
                    computador. Por outro lado, se não aceitar cookies, talvez
                    experimente alguma inconveniência no seu uso de serviços da
                    Agres e seu site. Por exemplo, talvez não consigamos
                    reconhecer seu computador, caso em que talvez você tenha que
                    fazer log in sempre que acessar os serviços online
                    aplicáveis.
                </li>
                <li>
                    <strong>Uso de pixel tags e outras tecnologias
                        similares:</strong> Os pixel tags (também conhecidos como web beacons e GIFs
                    claros) podem ser usados em conexão com alguns serviços
                    online para, entre outras coisas, rastrear as atividades de
                    usuários de serviços online (inclusive destinatários de
                    e-mails), medir o sucesso de nossas campanhas de marketing e
                    compilar estatísticas sobre o uso de serviços online e taxas
                    de resposta.
                </li>
                <li>
                    <strong>Endereço IP:</strong> Seu endereço IP é um número
                    automaticamente atribuído pelo seu provedor de serviços de
                    Internet ao computador que você está usando. Os endereços de
                    IP podem ser automaticamente identificados e logados nos
                    nossos servidores sempre que o usuário acessar os serviços
                    online, juntamente com a hora da visita e as páginas
                    visitadas. A coleta de endereços de IP é praxe e feita
                    automaticamente por muitos sites, aplicativos e outros
                    serviços. Usamos essas informações para entender e analisar
                    tendências, administrar o site, diagnosticar problemas nos
                    serviços online, aprender sobre o comportamento do usuário
                    no site, melhorar nossos produtos e serviços e coletar
                    informações demográficas sobre nossa base de usuários como
                    um todo.
                </li>
                <li>
                    <strong>Localização física:</strong> Podemos coletar a
                    localização física de seu dispositivo, por exemplo usando
                    sinais de GNSS (e.g. GPS, A-GPS, GLONASS, etc.), da torre de
                    telefonia celular, do WiFi e do Bluetooth. Podemos usar a
                    localização física de seu dispositivo para lhe fornecer
                    produtos e conteúdo personalizados com relação ao local. Em
                    alguns casos, você vai poder escolher entre permitir ou
                    recusar tais usos e/ou compartilhamento da localização do
                    seu dispositivo, mas, caso você opte pela recusa, talvez
                    fiquemos impedidos de fornecer a você os produtos e conteúdo
                    personalizados aplicáveis.
                </li>
            </ul>

            <p>&nbsp;</p>

            <p>
                <strong>Serviços de terceiros e conteúdo gerado pelo
                    usuário</strong>
            </p>
            <p>
                Esta Política de Privacidade não trata de políticas ou praxe de
                coleta, uso, divulgação ou segurança de terceiros, informações
                por eles prestadas, nem nenhuma outra de suas práticas, tampouco
                somos responsáveis por elas. Aqui se incluem, por exemplo:
            </p>

            <ul>
                <li>
                    Terceiros que operem um site ou serviço ligado aos produtos
                    ou serviços online da Agres; e
                </li>
                <li>
                    Qualquer desenvolvedor ou fornecedor de aplicativo móvel,
                    fornecedor de plataforma de mídia social, fornecedor de
                    serviço operacional, fornecedor de serviços wireless, ou
                    fabricante de dispositivo.
                </li>
            </ul>

            <p>
                Não somos responsáveis pelas informações, incluindo dados
                pessoais, que você apresentar em qualquer serviço com conteúdo
                gerado por usuário, tal como a postagem de uma pergunta em
                mídias sociais, resposta ou blog em qualquer parte com interface
                com o público da Agres. Os dados pessoais que você apresentar
                nesses locais se tornarão públicos, exceto se claramente
                indicados de outra forma, e, portanto, podem ser lidos,
                coletados ou usados por outros usuários e podem ser usados para
                enviar a você mensagens não solicitadas.
            </p>
            <p>&nbsp;</p>

            <p><strong>Atualizações desta Política de Privacidade</strong></p>
            <p>
                Podemos alterar esta Política de Privacidade a qualquer momento,
                mas sempre indicaremos quando esta Política de Privacidade foi
                atualizada pela última vez. As eventuais alterações a esta
                Política de Privacidade entrarão em vigor quando postarmos ou
                distribuirmos a Política de Privacidade revisada. Se os seus
                dados pessoais de contato estiverem armazenados em nossa base de
                dados, é possível que você seja notificado dessa atualização por
                e-mail, ou algum outro método equivalente. O uso dos produtos e
                serviços da Agres a partir dessas alterações significa a sua
                aceitação da Política de Privacidade revisada.
            </p>

            <p>&nbsp;</p>

            <p><strong>Como entrar em contato conosco</strong></p>
            <p>
                Se você tiver alguma dúvida sobre a Política de Privacidade da
                Agres ou sobre o processamento dos seus dados, é possível entrar
                em contato conosco via telefone, e-mail, site e também por meio
                de nossos serviços online. Como as comunicações por e-mail nem
                sempre são seguras, não inclua informações sobre seu cartão de
                crédito nem outras informações sensíveis em seus e-mails
                enviados para nós.
            </p>

            <a (click)="voltar()" class="button button__primary">Voltar</a>
        </div>
    </div>
</div>