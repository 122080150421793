import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IndexdbService {
  private db: IDBDatabase | null = null;
  private objectStoreName = 'imagensB64';
  private dbName = 'imageDB';

  constructor() {
    this.initDB()
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  }

  public async initDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('imageDB', 1);

      request.onupgradeneeded = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result;

        this.db.createObjectStore(this.objectStoreName, {
          autoIncrement: true,
        });
      };

      request.onsuccess = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result;
        resolve();
      };

      request.onerror = (event) => {
        console.error(
          'Erro ao abrir o banco de dados:',
          (event.target as IDBOpenDBRequest).error,
        );
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }

  async saveImage(
    id: string,
    imageObjects: { rasterImage: string; backgroundImage: string },
  ): Promise<void> {
    if (!this.db) {
      console.error('Banco de dados não inicializado.');
      throw new Error('Banco de dados não inicializado.');
    }

    const transaction = this.db.transaction([this.objectStoreName], 'readonly');
    const objectStore = transaction.objectStore(this.objectStoreName);
    const cursorRequest = objectStore.openCursor();

    let isImageExists = false;

    return new Promise<void>((resolve, reject) => {
      cursorRequest.onsuccess = async (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          if (cursor.value.id === id) {
            isImageExists = true;
          }
          cursor.continue();
        } else {
          if (!isImageExists) {
            try {
              const addTransaction = this.db.transaction(
                [this.objectStoreName],
                'readwrite',
              );
              const addObjectStore = addTransaction.objectStore(
                this.objectStoreName,
              );
              const addRequest = addObjectStore.add(
                { imagem: imageObjects },
                id,
              );
              addRequest.onsuccess = () => resolve();
              addRequest.onerror = () => reject(addRequest.error);
            } catch (error) {
              console.error('Erro ao adicionar imagem:', error);
              reject(error);
            }
          } else {
            resolve();
          }
        }
      };

      cursorRequest.onerror = () => {
        console.error('Erro na solicitação do cursor:', cursorRequest.error);
        reject(cursorRequest.error);
      };
    });
  }

  async getDataIndexDBById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName);

      request.onerror = (event) => {
        console.error('Erro ao abrir o banco de dados:', event.target);
        reject('Erro ao abrir o banco de dados.');
      };

      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        const transaction = db.transaction([this.objectStoreName], 'readonly');
        const objectStore = transaction.objectStore(this.objectStoreName);
        const getRequest = objectStore.get(id);

        getRequest.onsuccess = (event) => {
          const result = (event.target as IDBRequest).result;
          if (result) {
            resolve(result);
          } else {
            resolve(null);
          }
        };

        getRequest.onerror = (event) => {
          console.error('Erro ao buscar dados:', event.target);
          reject('Erro ao buscar dados.');
        };
      };
    });
  }

}
