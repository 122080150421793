import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss']
})
export class ToastrComponent {

  @Input() styleClass: string;
  @Input() iconClass: string;
  @Input() title: string;
   
  constructor() { }
  closeInformative() {
    $(".close").click(function() {
      $(this)
        .parent(".toastr")
        .fadeOut();
    });
  }
}
