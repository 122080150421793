<header class="header">
  <div class="header__collapse">
    <div class="menuMobile" *ngIf="backButton">
      <button class="header__collapse--btn" (click)="goBack()">
        <i class="agres-return"></i>
      </button>
    </div>
    <div class="menuDesktop">
      <button *ngIf="!hideMenuButton" class="header__collapse--btn" (click)="toggleSidebar()">
        <i class="agres-menu"></i>
      </button>
    </div>
  </div>
  <div class="header__breadcrumb">
    <div *ngFor="let breadcrumb of breadcrumbs">
      <span>
        <strong>{{ breadcrumb.label | translate }}</strong>
      </span>
    </div>
  </div>
  <div class="header__nav">
    <ul>
      <li class="header__nav--language">
        <a data-bs-toggle="dropdown">
          <img [src]="flagImgLanguage" [alt]="flagName" alt="" />
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <a (click)="changeLanguage(languages_options.PT_BR)" class="dropdown-item language">
            <img
              src="./assets/images/languages/ptBR.png"
              [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.PT_BR) }" />
            <span> {{ 'global.ptBR' | translate }}</span>
          </a>
          <a (click)="changeLanguage(languages_options.EN)" class="dropdown-item language">
            <img
              src="./assets/images/languages/en.png"
              [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.EN) }" />
            <span> {{ 'global.en' | translate }}</span>
          </a>
          <a (click)="changeLanguage(languages_options.ES)" class="dropdown-item language">
            <img
              src="./assets/images/languages/es.png"
              [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.ES) }" />
            <span> {{ 'global.es' | translate }}</span>
          </a>
          <a (click)="changeLanguage(languages_options.IT)" class="dropdown-item language">
            <img
              src="./assets/images/languages/it.png"
              [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.IT) }" />
            <span> {{ 'global.it' | translate }}</span>
          </a>
        </div>
      </li>
      <li class="header__nav--profile" clickOutside>
        <a (click)="openUserDropdown()">
          <img src="./assets/images/default/avatar.png" alt="" id="profileMenu" />
        </a>
        <div class="header__nav--profile---wrap" id="subMenu">
          <div class="header__nav--profile---wrap----subMenu">
            <div class="header__nav--profile---wrap----subMenu-----info">
              <h2>{{ nomeUsuarioLogado }}</h2>
              <p *ngIf="userRole === ROLE_USERS_ENUM.Admin">
                {{ 'header.title-adm' | translate }}
              </p>
              <p *ngIf="userRole === ROLE_USERS_ENUM.Standard">
                {{ 'header.title-user' | translate }}
              </p>
              <p *ngIf="userRole === ROLE_USERS_ENUM.FreeTier">
                {{ 'header.title-free-tier' | translate }}
              </p>
              <p *ngIf="userRole === ROLE_USERS_ENUM.TelemetryUser">
                {{ 'header.title-telemetry-user' | translate }}
              </p>
            </div>
            <hr />
            <a class="header__nav--profile---wrap----subMenu-----link" [routerLink]="['/manage/my-profile']">
              <i class="agres-user"></i>
              <p>{{ 'header.title-my-profile' | translate }}</p>
            </a>
            <a class="header__nav--profile---wrap----subMenu-----link" [routerLink]="['/suporte-tecnico/tutoriais']">
              <i class="agres-support"></i>
              <p>{{ 'header.title-help-center' | translate }}</p>
            </a>
            <a class="header__nav--profile---wrap----subMenu-----link" (click)="logout()">
              <i class="agres-logout"></i>
              <p>{{ 'header.title-logout' | translate }}</p>
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</header>
