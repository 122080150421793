<div class="panel">
  <div class="panel__group">
    <div class="panel__search">
      <div class="field">
        <div class="field__wrap">
          <div class="field__select">
            <select id="selectEquipamentos" [(ngModel)]="valueSelectField" (change)="onDeviceChange($event)">
              <option value="" disabled selected>{{ 'my-work.choose-terminal' | translate }}</option>
              <option
                [selected]="item.serialNumber === true"
                [value]="item.serialNumber + '|' + item.machineName"
                *ngFor="let item of terminals">
                {{ item.serialNumber }} - {{ item.machineName }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel__group">
    <div class="panel__group--buttons hidden__mobile">
      <ng-container>
        <app-button
          id="btn-selecionados"
          type="button"
          color="success"
          icon="agres-download"
          (btnClick)="exportAllSelected()"
          title="{{ 'global.export-select' | translate }}">
        </app-button>
        <app-button
          id="btn-exportarTodos"
          type="button"
          color="success"
          icon="agres-download"
          (btnClick)="exportAllTrabalhos()"
          title="{{ 'global.export-all' | translate }}">
        </app-button>
        <app-button
          type="button"
          color="primary"
          icon="agres-search"
          (btnClick)="openModal()"
          title="{{ 'global.search-advanced' | translate }}">
        </app-button>
      </ng-container>
    </div>
  </div>
</div>
<section class="searchMobile">
  <div class="card">
    <div class="card__header">
      <app-agres-icons iconsClass="agres-circle" title="{{ 'my-work.information' | translate }}"> </app-agres-icons>
    </div>
    <div class="card__body">
      <div class="text-date-invalid" style="display: none" id="div-invalid-date">
        {{ 'global.alert.msg-error-invalid-date' | translate }}
      </div>
      <div class="text-date-invalid" style="display: none" id="div-date-end-greater-date-ini">
        {{ 'global.alert.msg-error-date-end-greater-date-ini' | translate }}
      </div>
      <div class="text-date-invalid" style="display: none" id="div-date-end-greated-today">
        {{ 'global.alert.msg-error-date-end-greated-today' | translate }}
      </div>
      <div class="a-filter">
        <div class="a-filter__column">
          <p>
            <strong>{{ 'global.filter-period' | translate }}:</strong>
          </p>
        </div>
        <div class="a-filter__column">
          <input
            type="radio"
            (click)="onCheckboxDateChange($event.target.value)"
            class="form-check-input"
            id="radioFilterDate7Dias"
            name="dias"
            value="7" />
          <label for="flexRadioDefault1">{{ 'my-work.seven-day' | translate }}</label>
        </div>
        <div class="a-filter__column">
          <input
            type="radio"
            (click)="onCheckboxDateChange($event.target.value)"
            class="form-check-input"
            id="radioFilterDate15Dias"
            name="dias"
            value="15" />
          <label for="flexRadioDefault2"> {{ 'my-work.fifteen-day' | translate }}</label>
        </div>
        <div class="a-filter__column">
          <input
            type="radio"
            (click)="onCheckboxDateChange($event.target.value)"
            class="form-check-input"
            id="radioFilterDate30Dias"
            name="dias"
            value="30" />
          <label for="flexRadioDefault3"> {{ 'my-work.thirty-day' | translate }}</label>
        </div>
        <div class="a-filter__column">
          <input
            type="radio"
            (click)="radioFilterAllEquipments()"
            class="form-check-input"
            id="#radioFilterAllDates"
            name="dias"
            value="all-dates" />
          <label for="flexRadioDefault4"> {{ 'my-work.all-day' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>
              {{ 'my-work.name-field' | translate }} &
              {{ 'my-work.event' | translate | lowercase }}
            </th>
            <th>{{ 'my-work.total-volume' | translate }}</th>
            <th>{{ 'my-work.total-applied-area' | translate }}</th>
            <th>{{ 'my-work.average-rate' | translate }}</th>
            <th>{{ 'my-work.average-speed' | translate }}</th>
            <th>{{ 'global.action' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filteredData.slice(pagination[0], pagination[1]); let i = index">
            <td>
              <input
                (click)="updateSelectedItem(i, $event.target.checked)"
                type="checkbox"
                name="selecionados"
                id="itensSelecionados"
                [checked]="selectedItens[i + this.pageAtual * this.rowsPerPage]" />
            </td>
            <td>
              <p>{{ item.cropName }} - {{ item.evtName }}</p>
              <p>
                {{ item.endTimestamp | date: 'dd/MM/yyyy - HH:mm' }}
              </p>
            </td>
            <td>{{ item.appliedVolume }}</td>
            <td>{{ item.coverArea }}</td>
            <td>{{ item.averageAppliedVolume | number: '.2' : 'de' }}</td>
            <td>{{ item.averageSpeed }}</td>
            <td>
              <app-button
                type="button"
                color="primary"
                icon="agres-eye"
                (btnClick)="btnVisualizar()"
                data-bs-target="#exampleModal"
                title="{{ 'global.view' | translate }}">
              </app-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <nav *ngIf="filteredData.length > rowsPerPage">
    <ul class="pagination">
      <li class="page-item">
        <a class="page-link" (click)="clickPagination(pageAtual - 1, undefined)">
          {{ 'my-work.pagination-previous' | translate }}</a
        >
      </li>
      <li class="page-item" *ngFor="let page of pages; let i = index">
        <a class="page-link" (click)="clickPagination(i, $event)">{{ i + 1 }}</a>
      </li>
      <li class="page-item">
        <a class="page-link" (click)="clickPagination(pageAtual + 1, undefined)">{{
          'my-work.pagination-next' | translate
        }}</a>
      </li>
    </ul>
  </nav>
  <div *ngIf="filteredData.length > rowsPerPage">
    <nav aria-label="Exemplo de paginação">
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link" (click)="clickPagination(pageAtual - 1, undefined)">
            {{ 'my-work.pagination-previous' | translate }}</a
          >
        </li>
        <li class="page-item" *ngFor="let page of pages; let i = index">
          <a class="page-link" (click)="clickPagination(i, $event)">{{ i + 1 }}</a>
        </li>
        <li class="page-item">
          <a class="page-link" (click)="clickPagination(pageAtual + 1, undefined)">{{
            'my-work.pagination-next' | translate
          }}</a>
        </li>
      </ul>
    </nav>
  </div>
</section>
<app-modal title="my-work.filter-by-date" [show]="showModal" [showSubject]="showModalSubject">
  <div class="modal-body">
    <div class="a-column--one">
      <div class="field">
        <div class="field__label" for="selectEquipamentos">
          {{ 'my-work.filter-period-select' | translate }}
        </div>
        <div class="field__wrap">
          <div class="field__select">
            <select id="selectEquipamentos" [(ngModel)]="valueSelectField" (change)="onDeviceChange($event)">
              <option value="" disabled selected>{{ 'my-work.choose-terminal' | translate }}</option>
              <option
                [selected]="item.serialNumber === true"
                [value]="item.serialNumber"
                *ngFor="let item of terminals">
                {{ item.serialNumber }} - {{ item.machineName }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="a-column--two">
      <div class="field">
        <div class="field__label" for="selectEquipamentos">
          {{ 'global.date-to' | translate }}
        </div>
        <div class="field__wrap">
          <input [(ngModel)]="dataInicio" type="date" name="dataInicio" class="field__input dataIni" />
        </div>
      </div>
      <div class="field">
        <div class="field__label" for="selectEquipamentos">
          {{ 'global.date-from' | translate }}
        </div>
        <div class="field__wrap">
          <input [(ngModel)]="dataFinal" type="date" name="dataFinal" class="field__input dataFim" />
        </div>
      </div>
    </div>
  </div>
  <div class="a-column--one">
    <div class="text-date-invalid" style="display: none" id="div-invalid-date">
      {{ 'global.alert.msg-error-invalid-date' | translate }}
    </div>
    <div class="text-date-invalid" style="display: none" id="div-date-end-greater-date-ini">
      {{ 'global.alert.msg-error-date-end-greater-date-ini' | translate }}
    </div>
    <div class="text-date-invalid" style="display: none" id="div-date-end-greated-today">
      {{ 'global.alert.msg-error-date-end-greated-today' | translate }}
    </div>
  </div>
  <div class="modal-footer">
    <app-button
      type="button"
      color="danger"
      icon="agres-close"
      (btnClick)="closeModal()"
      title="{{ 'global.cancel' | translate }}">
    </app-button>
    <app-button
      *ngIf="filteredData"
      type="button"
      color="warning"
      icon="agres-close"
      (btnClick)="filterClear()"
      title="{{ 'global.clear' | translate }}">
    </app-button>
    <app-button
      *ngIf="filteredData"
      type="button"
      color="primary"
      icon="agres-check"
      (btnClick)="updateDateFilter()"
      title="{{ 'global.filter' | translate }}">
    </app-button>
  </div>
</app-modal>
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="dashboard()">
      <i class="agres-app"></i>
      <p>{{ 'sidebar.title-dashboard' | translate }}</p>
    </button>
  </div>
  <ng-container>
    <div id="btn-selecionados" class="pwa__list--info">
      <button type="button" (click)="exportAllSelected()">
        <i class="agres-download"></i>
        <p>{{ 'global.export-select' | translate }}</p>
      </button>
    </div>
    <div id="btn-exportarTodos" class="pwa__list--info">
      <button type="button" (click)="exportAllTrabalhos()">
        <i class="agres-download"></i>
        <p>{{ 'global.export-all' | translate }}</p>
      </button>
    </div>
    <div class="pwa__list--info">
      <button type="button" (click)="openModal()">
        <i class="agres-search"></i>
        <p>{{ 'global.search-advanced' | translate }}</p>
      </button>
    </div>
  </ng-container>
</app-menu-pwa>
