import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from './../../../app.constants';
import { AuthorizationService } from '../../../core/services/authorization.service';
import { _roleEnumUserClass } from '../../../utils/role.utils';
import { gitVersion } from 'assets/json/json.interfaces';
import { environment } from 'environments/environment';
import { CurrentViewportBreakpoint } from '../layouts/basicLayout.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  @Input() isSidebarOpen = false;
  @Input() resizeEventEmitter: EventEmitter<CurrentViewportBreakpoint>;
  @Input() toggleSidebarSubject: Subject<boolean | void>;

  nomeUsuarioLogado = '';
  userRole = '';
  public auth;
  public environmentName;
  public anoAtual;
  public versao;
  public versaoGit;

  ROLE_USERS_ENUM = _roleEnumUserClass.ROLE_ENUM;

  constructor(
    public router: Router,
    public translate: TranslateService,
    public authorizationService: AuthorizationService,
  ) {
    this.auth = authorizationService.gethandler();
    this.versaoGit = gitVersion.version;
    this.environmentName = environment.environmentName;
    this.anoAtual = new Date().getFullYear();
  }

  close(): void {
    this.isSidebarOpen = false;
  }

  ngOnInit() {
    this.userRole = new AuthorizationService().getLoggedUserRole();
    const usuarioLogado = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );

    this.nomeUsuarioLogado = usuarioLogado.Nome;

    if (!!this.toggleSidebarSubject) {
      this.toggleSidebarSubject.subscribe((isSidebarOpen) => {
        if (typeof isSidebarOpen == 'undefined')
          this.isSidebarOpen = !this.isSidebarOpen;
        else this.isSidebarOpen = isSidebarOpen;
      });
    }
    if (!!this.resizeEventEmitter) {
      this.resizeEventEmitter.subscribe(
        (currentViewportBreakpoint: CurrentViewportBreakpoint) => {
          if (
            currentViewportBreakpoint.tablet ||
            currentViewportBreakpoint.mobile
          ) {
            this.isSidebarOpen = false;
          }
          if (currentViewportBreakpoint.desktop) {
            this.isSidebarOpen = true;
          }
        },
      );
    }
  }

  listFields(): void {
    const currentURL = window.location.href;
    switch (true) {
      case currentURL.includes('my-fields/gerenciamento-de-talhoes'):
        this.router.navigate(['/my-fields']);
        break;
      case currentURL.includes('my-fields'):
        window.location.reload();
        break;
      default:
        this.router.navigate(['/my-fields']);
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }
  isAdmin(): boolean {
    const usuario = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    return usuario.role === this.ROLE_USERS_ENUM.Admin;
  }

  isRoleFreetier(): boolean {
    const usuarioLogado = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );

    return usuarioLogado.role === this.ROLE_USERS_ENUM.FreeTier;
  }

  activeRoute(routename: string): boolean {
    const isActive = this.router.isActive(routename, {
      matrixParams: 'exact',
      queryParams: 'exact',
      paths: 'subset',
      fragment: 'exact',
    });
    return isActive;
  }

  home(): void {
    this.router.navigateByUrl('/');
  }

  designSystem(): void {
    this.router.navigateByUrl('/design-system');
  }
}
