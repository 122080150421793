/// <reference types="@types/google.maps" />
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EChartsOption, ECharts } from 'echarts';
import * as echarts from 'echarts';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-spray-rate',
  templateUrl: './spray-rate.component.html',
  styleUrls: ['./spray-rate.component.scss'],
})
export class SprayRateComponent implements OnInit {
  mapOptions: google.maps.MapOptions = {
    center: { lat: -23.55052, lng: -46.633308 },
    zoom: 10,
    mapTypeId: 'roadmap',
    disableDefaultUI: true,
    zoomControl: true,
    streetViewControl: true,
    mapTypeControl: false,
  };

  private selectedLegends: string[] = [];

  chartOptions: EChartsOption = {
    title: {
      text: 'Desempenho do Pulverizador',
      left: 'left',
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Taxa', 'Estado de Seção', 'Velocidade', 'Pressão', 'Vazão'],
      type: 'scroll',
      orient: 'horizontal',
      top: 10,
      left: 'center',
      itemGap: 20,
      textStyle: {
        fontSize: 14,
        fontWeight: 'normal',
        color: 'hsl(270, 1%, 31%)',
      },
      selected: {
        Taxa: false,
        'Estado de Seção': true,
        Velocidade: true,
        Pressão: false,
        Vazão: false,
      },
    },
    xAxis: {
      type: 'category',
      data: this.generateTimeLabels(),
      boundaryGap: false,
      name: 'Tempo',
    },
    yAxis: [
      { type: 'value', name: 'Taxa', position: 'left', offset: 0 },
      {
        type: 'value',
        name: 'Seção',
        axisLabel: {
          formatter: (value) => (value === 1 ? 'A' : 'F'),
        },
        max: 1,
        min: 0,
        position: 'left',
        offset: 50,
      },
      { type: 'value', name: 'km/h', position: 'right', offset: 0 },
      { type: 'value', name: 'psi', position: 'right', offset: 50 },
      { type: 'value', name: 'Lts', position: 'left', offset: 100 },
    ],
    dataZoom: [
      { type: 'slider', show: true, start: 0, end: 100 },
      { type: 'inside', start: 0, end: 100 },
    ],
    toolbox: {
      feature: {
        saveAsImage: { title: 'Download' },
        restore: { title: 'Reset' },
      },
    },
    series: [
      {
        name: 'Taxa',
        type: 'line',
        data: [2.3, 2.5, 2.8, 3.0, 2.9, 3.1, 3.5, 3.8, 4.0, 3.9, 3.6, 3.4, 3.2],
        smooth: true,
      },
      {
        name: 'Estado de Seção',
        type: 'line',
        yAxisIndex: 1,
        data: [1, 1, 0, 0, 1, 1, 1, 0, 0, 1, 1, 0, 1],
        step: 'start',
      },
      {
        name: 'Velocidade',
        type: 'line',
        yAxisIndex: 2,
        data: [12, 13, 15, 16, 15, 14, 13, 12, 11, 12, 13, 14, 15],
        smooth: true,
      },
      {
        name: 'Pressão',
        type: 'line',
        yAxisIndex: 3,
        data: [120, 118, 122, 125, 123, 121, 119, 118, 120, 123, 125, 124, 122],
        smooth: true,
      },
      {
        name: 'Vazão',
        type: 'line',
        yAxisIndex: 4,
        data: [25, 27, 29, 30, 32, 31, 29, 28, 30, 33, 34, 32, 31],
        smooth: true,
      },
    ],
  };

  private chart!: ECharts;
  googleMapsApiLoaded: boolean = false;
  apiLoaded: Observable<boolean>;
  point: google.maps.Point;
  scaledSize: google.maps.Size;

  constructor(public httpClient: HttpClient) {}

  ngOnInit() {
    this.loadGoogleMapsAPI().subscribe(() => {
      this.googleMapsApiLoaded = true;
    });
    const chartDom = document.getElementById('chart')!;
    this.chart = echarts.init(chartDom);
    this.chart.setOption(this.chartOptions);

    // Manipular cliques na legenda
    this.chart.on('legendselectchanged', (params) => {
      this.limitLegendSelection(params);
    });
  }

  loadGoogleMapsAPI(): Observable<boolean> {
    return this.httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo&libraries=drawing',
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  limitLegendSelection(params: any): void {
    const selectedLegend = params.name;

    if (params.selected[selectedLegend]) {
      this.selectedLegends.push(selectedLegend);
    } else {
      this.selectedLegends = this.selectedLegends.filter(
        (legend) => legend !== selectedLegend,
      );
    }

    if (this.selectedLegends.length > 2) {
      this.keepNewerLegend(params);
    }
  }

  keepNewerLegend(params: any): void {
    while (this.selectedLegends.length > 2) {
      this.removeOlderLegend(params);
    }
  }

  removeOlderLegend(params: any): void {
    const legendToRemove = this.selectedLegends.shift();
    if (legendToRemove) {
      this.chart.dispatchAction({
        type: 'legendUnSelect',
        name: legendToRemove,
      });
      params.selected[legendToRemove] = false;
    }
  }

  generateTimeLabels(): string[] {
    const startTime = new Date();
    startTime.setHours(13, 0, 0);
    const endTime = new Date();
    endTime.setHours(20, 0, 0);
    const timeLabels: string[] = [];

    while (startTime <= endTime) {
      const hours = startTime.getHours().toString().padStart(2, '0');
      const minutes = startTime.getMinutes().toString().padStart(2, '0');
      timeLabels.push(`${hours}:${minutes}`);
      startTime.setMinutes(startTime.getMinutes() + 28);
    }

    return timeLabels;
  }

  onLegendSelectChanged(event: any) {
    console.log('Legenda alterada:', event);
  }

  scrollLeft() {
    const container = document.querySelector('.sprayrate__tabs--content');
    container.scrollBy({ left: -300, behavior: 'smooth' });
  }

  scrollRight() {
    const container = document.querySelector('.sprayrate__tabs--content');
    container.scrollBy({ left: 300, behavior: 'smooth' });
  }
}
