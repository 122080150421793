<div class="field">
  <div class="field__label">{{ 'fields.file-name' | translate }}</div>
  <div class="field__wrap">
    <p>{{ fileName }}</p>
  </div>
</div>
<div class="field">
  <div class="field__label">{{ 'my-work.choose-terminal' | translate }}</div>
  <div class="field__wrap">
    <select class="field__input" id="selectEquipamentos" (change)="onDeviceChange($event)">
      <option value="" disabled selected>{{ 'my-work.choose-terminal' | translate }}</option>
      <option [selected]="item.deviceId === true" [value]="item.deviceId" *ngFor="let item of devices">
        {{ item.deviceId }}
      </option>
    </select>
  </div>
</div>
<input disabled type="hidden" [(ngModel)]="fileName" name="fileName" />
<div class="hidden__mobile">
  <app-button
    type="submit"
    color="primary"
    icon="agres-save"
    (btnClick)="sendFiles()"
    title="{{ 'fields.send-files-to-device' | translate }}">
  </app-button>
</div>
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="sendFiles()">
      <i class="agres-save"></i>
      <p>{{ 'fields.send-files-to-device' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
