import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'systemNumberFormat',
  pure: false,
})
export class SystemNumberFormatPipe implements PipeTransform {
  transform(
    num: number | string,
    decimalPlaces: number,
    systemUnit: string,
  ): string {
    num = Number(num).toFixed(decimalPlaces);

    switch (systemUnit) {
      case 'metric':
        num = String(num).replace('.', ',');
        break;

      case 'imperial':
        break;

      default:
        break;
    }

    return num;
  }
}
