import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { AuthorizationService } from '../services/authorization.service';
//import { MaintenanceService } from '../services/maintenance.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router,
    public translateService: TranslateService,
    public authorizationService: AuthorizationService,
  ) {}
  public token: string;
  public userLogged: string;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.token = localStorage.getItem(
      AppConstants.KEYS_LOCAL_STORAGE.ISO_ACCESS_TOKEN,
    );

    if (!this.token) this.router.navigate(['/login']);

    if (!route.data || !route.data.authorization) return true;

    const auth = this.authorizationService.gethandler();
    const guard = route.data.authorization;

    if (auth.forbids(guard.permission, guard.resource)) {
      this.translateService
        .get('global.alert.users-premium-msg')
        .subscribe((msgOnlyPremium: string) => {
          this.router.navigate(['/'], {
            queryParams: { msg: msgOnlyPremium },
          });
        });
    } else {
      return true;
    }
  }
}
