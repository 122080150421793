<app-alert></app-alert>
<div class="auth">
  <div class="auth__wrap">
    <div *ngIf="environmentName === 'DEV'">
      <div class="auth__dev">{{ 'global.dev' | translate }}</div>
    </div>
    <div *ngIf="environmentName === 'STAGE'">
      <div class="auth__stg">{{ 'global.stg' | translate }}</div>
    </div>
    <div class="auth__wrap--pic">
      <img alt="Agres" src="./assets/images/default/logo-isofarm.svg" />
    </div>
    <div class="auth__wrap--language">
      <a (click)="changeLanguage(languages_options.PT_BR)">
        <img
          src="./assets/images/languages/ptBR.png"
          alt="Português"
          [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.PT_BR) }" />
      </a>
      <a (click)="changeLanguage(languages_options.EN)">
        <img
          src="./assets/images/languages/en.png"
          alt="English"
          [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.EN) }" />
      </a>
      <a (click)="changeLanguage(languages_options.ES)">
        <img
          src="./assets/images/languages/es.png"
          alt="Spanish"
          [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.ES) }" />
      </a>
      <a (click)="changeLanguage(languages_options.IT)">
        <img
          src="./assets/images/languages/it.png"
          alt="Italian"
          [ngClass]="{ 'language-disable': translateUtils.isLanguageSelected(languages_options.IT) }" />
      </a>
    </div>
    <div class="auth__wrap--heading">{{ 'auth.title' | translate }}</div>
    <form role="form">
      <div class="field">
        <div class="field__label">
          {{ 'auth.mail' | translate }}
        </div>
        <div class="field__wrap">
          <input type="mail" class="field__input" name="mail" [(ngModel)]="usuario.email" />
        </div>
      </div>
      <div class="field">
        <div class="field__label">
          {{ 'auth.password' | translate }}
          <app-link (click)="esqueceuSenha()" title="{{ 'auth.forgot' | translate }}"> </app-link>
        </div>
        <div class="field__wrap">
          <input
            [type]="isPasswordVisible ? 'text' : 'password'"
            class="field__input"
            name="password"
            [(ngModel)]="usuario.password"
            required="true" />
          <span class="field__view">
            <app-agres-icons
              styleClass="field__view"
              [ngClass]="isPasswordVisible ? 'agres-eye' : 'agres-eye-off'"
              (click)="togglePassword()">
            </app-agres-icons>
          </span>
        </div>
      </div>
      <app-button
        type="submit"
        color="primary"
        icon="agres-action"
        size="full"
        title="{{ 'auth.sign-in' | translate }}"
        (click)="executeLogin()"
        *ngIf="!loginExecuting">
      </app-button>
      <app-button
        color="return"
        action="reload"
        icon="agres-reload"
        size="full"
        title="{{ 'auth.sign-in-executing' | translate }}"
        disabled="disabled"
        *ngIf="loginExecuting">
      </app-button>
    </form>
    <div class="auth__wrap--account">
      <p>{{ 'register.title-create' | translate }}</p>
      <strong>
        <app-link (click)="register()" title="{{ 'auth.sign-up' | translate }}"></app-link>
      </strong>
    </div>
    <div class="auth__wrap--copyright">
      <app-link link="https://agres.com.br/quem_somos/" target="_blank" title="{{ 'global.copyright' | translate }}">
      </app-link>
    </div>
  </div>
</div>
