import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  FormControlName,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { CanalAtendimentoService } from 'app/core/services/canal-atendimento.service';
import { ChangeLanguageService } from 'app/core/services/changeLanguage.service';
import { GenericValidator } from 'app/utils/generic-form-validator';
import { gitVersion } from 'assets/json/json.interfaces';
import 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import { CustomValidators } from 'ng2-validation';
import { ToastrService } from 'ngx-toastr';
import {
  fromEvent as observableFromEvent,
  merge as observableMerge,
  Observable,
} from 'rxjs';

@Component({
  selector: 'app-canal-atendimento',
  templateUrl: 'canal-atendimento.component.html',
  styleUrls: ['canal-atendimento.component.scss'],
})
export class CanalAtendimentoComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];

  @Input() ng2TelInputOptions: any;
  @Output() hasError: EventEmitter<boolean> = new EventEmitter();
  @Output() ng2TelOutput: EventEmitter<any> = new EventEmitter();
  @Output() intlTelInputObject: EventEmitter<any> = new EventEmitter();
  ngTelInput: any;

  public registerForm: UntypedFormGroup;
  public displayMessage: { [key: string]: string } = {};
  public validationMessages: { [key: string]: { [key: string]: string } };
  public genericValidator: GenericValidator;

  public btnSend = false;
  public subjects = [];

  constructor(
    public http: HttpClient,
    public fb: UntypedFormBuilder,
    public toastrService: ToastrService,
    public sanitizer: DomSanitizer,
    public translateService: TranslateService,
    public formBuilder: UntypedFormBuilder,
    public canalAtendimentoService: CanalAtendimentoService,
    public changeLanguageService: ChangeLanguageService,
  ) {
    this.changeLanguageService.currentData.subscribe(() => {
      this.changeLanguage();
    });
  }

  isInputValid(): boolean {
    return this.ngTelInput.intlTelInput('isValidNumber') ? true : false;
  }

  ngOnInit() {
    this.initializeForm();
    this.createFormValidation();
  }

  ngAfterViewInit() {
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => {
        return observableFromEvent(formControl.nativeElement, 'blur');
      },
    );
    observableMerge(...controlBlurs).subscribe((value) => {
      this.displayMessage = this.genericValidator.processMessages(
        this.registerForm,
      );
    });
  }

  initializeForm() {
    const email = new UntypedFormControl('', [
      Validators.required,
      CustomValidators.email,
    ]);

    this.registerForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(150),
          Validators.minLength(2),
        ],
      ],
      email: email,
      phoneNumber: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });

    this.listTopics();

    this.registerForm.controls.subject.patchValue(this.subjects[1].Valor);

    this.btnSend = false;
  }

  listTopics() {
    this.subjects = [
      { value: 'problemas', name: 'tutorials.subject-problems' },
      { value: 'dúvidas', name: 'tutorials.question-subject' },
      { value: 'sugestões', name: 'tutorials.subject-suggestions' },
      {
        value: 'reclamações',
        name: 'tutorials.subject-complaints',
      },
    ];
  }

  send() {
    this.btnSend = true;
    this.displayMessage = this.genericValidator.processMessages(
      this.registerForm,
    );

    if (this.registerForm.valid && this.registerForm.dirty) {
      const obj = this.montarObjForm();

      this.translateService
        .get('global.alert.send-success')
        .subscribe((res: string) => {
          this.toastrService.success(res);
        });

      this.canalAtendimentoService.sendFormAtendimento(obj).subscribe(
        () => {
          this.btnSend = false;
          this.clearForm();
        },
        () => {
          this.btnSend = false;
          this.translateService
            .get('error.500.error-occurred-try-again')
            .subscribe((resError: string) => {
              this.toastrService.error(resError);
            });
        },
      );
    } else {
      this.btnSend = false;
    }
  }

  montarObjForm() {
    const requestBody = {
      userName: this.registerForm.controls.name.value,
      userEmail: this.registerForm.controls.email.value,
      userPhone: this.registerForm.controls.phoneNumber.value,
      subject: this.registerForm.controls.subject.value,
      message: this.registerForm.controls.message.value,
      dateTime: new Date().toLocaleString(),
      selectedLanguage: window.localStorage.getItem(
        AppConstants.KEYS_LOCAL_STORAGE.ISO_LANG,
      ),
      isofarmVersion: gitVersion.version,
    };

    return requestBody;
  }

  createFormValidation() {
    this.validationMessages = {
      name: {
        required: '',
        minlength: '',
      },
      email: {
        required: '',
      },
      phoneNumber: {
        required: '',
        invalido: '',
      },
      subject: {
        required: '',
      },
      message: {
        required: '',
        minlength: '',
      },
    };

    this.populateValidationObj();

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  changeLanguage() {
    setTimeout(() => {
      this.populateValidationObj();
      this.genericValidator = new GenericValidator(this.validationMessages);
      this.displayMessage = this.genericValidator.processMessages(
        this.registerForm,
      );
    }, 0);
  }

  clearForm() {
    this.registerForm.controls.name.patchValue('');
    this.registerForm.controls.email.patchValue('');
    this.registerForm.controls.phoneNumber.patchValue('');
    this.registerForm.controls.subject.patchValue(this.subjects[1].Valor);
    this.registerForm.controls.message.patchValue('');
  }

  populateValidationObj() {
    this.translateService.get('global.name').subscribe((res: string) => {
      this.validationMessages.name.required = res;
      this.validationMessages.name.minlength = res;
    });
    this.translateService.get('global.mail').subscribe((res: string) => {
      this.validationMessages.email.required = res;
    });
    this.translateService.get('global.phone').subscribe((res: string) => {
      this.validationMessages.phoneNumber.required = res;
    });
    this.translateService.get('global.description').subscribe((res: string) => {
      this.validationMessages.message.required = res;
    });
  }
}
