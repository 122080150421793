import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-meus-chamados',
  templateUrl: './meus-chamados.component.html',
  styleUrls: ['./meus-chamados.component.css'],
})
export class MeusChamadosComponent implements OnInit {
  constructor(public translateService: TranslateService) {
    this.translateService.get('global.home').subscribe((home: string) => {
      this.translateService.get('called.title').subscribe((res: string) => {
        const title = res;
        this.bindHTMLTitle(home, title);
      });
    });
  }

  chamados = [
    {
      numero: '1',
      servico: 'Técnico',
      categoria: 'Web',
      descricao: 'Descrição 1',
      status: 'Aberto',
    },
    {
      numero: '2',
      servico: 'Técnico',
      categoria: 'Web',
      descricao: 'Descrição 2',
      status: 'Fechado',
    },
    {
      numero: '3',
      servico: 'Técnico',
      categoria: 'Modulo',
      descricao: 'Descrição 3',
      status: 'Aberto',
    },
  ];

  searchKeys = ['numero', 'servico', 'descricao', 'status', 'categoria'];
  public search;
  chamadosFilter = [];

  ngOnInit() {
    this.chamadosFilter = this.chamados;
    this.changeLanguage();
  }

  bindHTMLTitle(home, title) {
    jQuery('.breadcrumb').html(
      `<li><a href="#">${home}</a></li><li class="active"><strong>${title}</strong></li>`,
    );
  }

  searchItens() {
    this.chamadosFilter = this.filter(this.chamados);
  }

  filter(data) {
    const filter = this.search.toLowerCase();
    return !filter
      ? data.slice(0)
      : data.filter((d) => {
          return (
            Object.keys(d)
              .filter((k) => this.searchKeys.includes(k))
              .map((k) => String(d[k]))
              .join('|')
              .toLowerCase()
              .indexOf(filter) !== -1 || !filter
          );
        });
  }

  changeLanguage() {
    this.translateService.onLangChange.subscribe(
      (event: TranslationChangeEvent) => {
        this.translateService.get('global.home').subscribe((home: string) => {
          this.translateService.get('called.title').subscribe((res: string) => {
            const title = res;
            this.bindHTMLTitle(home, title);
          });
        });
      },
    );
  }
}
